import {jwtDecode} from 'jwt-decode';
import { Menus } from '../common/menuItems';

const useAuth = () => {
    try {
        const token = localStorage.getItem("accessToken");
        return !isTokenExpired(token)
    } catch (err) {
        return false;
    }
  };

const logout = () => {
    window.location.href = '/login';
    localStorage.setItem('accessToken', '');
    localStorage.setItem('USER_DATA_KEY', '');
    localStorage.setItem('perm','')
    removeAllCookies();
}

const isTokenExpired = (token) => {
    if (!token) return true; // If token is not provided, consider it expired
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return decodedToken.exp < currentTime;
  };

const isPathAccessAllowed = (path) => {
        // let {role_id} = getDecodedToken()
        // if (!Array.isArray(role_id)) {
        //     role_id = [role_id]; // Convert to array if it's not already
        // }
        const data = localStorage.perm ? JSON.parse(localStorage.perm) : []
        const currentPathID = getProcessIdForPath(path);
        if(!currentPathID) return false; // if current path is not in menu items let is pass
        // return role_id?.some(role => currentPathRoles.includes(role));
        // return currentPathRoles?.process_id?.some(id => currentPathRoles)
        let finalData = data?.filter(val => currentPathID?.includes(val.process_id) && val.read)?.length > 0
        return finalData
}

// let { permission } = getDecodedToken()
// if (!Array.isArray(permission)) {
//     permission = [permission]; // Convert to array if it's not already
// }

// const currentProcessName = getRoleIdsForPath(path);
// if(!currentProcessName?.length) return true; // if current path is not in menu items let is pass
// return permission?.some(perm => perm?.process === currentProcessName );

function getProcessIdForPath(path) {
    let currentProcessId;
    let parentMenuItemPath;
    let childMenuItemPath;
    Menus?.map(item => {
        let subItem = item?.submenu?.find(subItem => subItem?.navlink === path);
        if (subItem != undefined) parentMenuItemPath = subItem;
    });
    if (parentMenuItemPath) {
        currentProcessId = parentMenuItemPath?.process_id;
    }
    if (currentProcessId) return currentProcessId;
    // Return empty array if path is not found
    return '';
}

// for (const menuItem of Menus) {
//     if (menuItem.navlink === path) {
//         return menuItem.processName;
//     } else if (menuItem.submenu) {
//         const submenuItem = menuItem.submenu.find(item => item.navlink === path);
//         if (submenuItem) {
//             return submenuItem.processName;
//         }
//     }
// }

export function removeAllCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
  }

function getDecodedToken() {
    const token = localStorage.getItem("accessToken");
    return token ? jwtDecode(token) : ''
}

function getCurrentProcessPerm(processId) {
    const permArray = JSON.parse(localStorage.perm)
    return permArray?.filter(process => process?.process_id === processId)?.[0]
  }

export function getCookie(name) {
const cookies = document?.cookie.split(';').map(cookie => cookie.trim());
for (const cookie of cookies) {
    const [cookieName, value] = cookie.split('=');
    if (cookieName == name) {
    return value;
    }
}
return undefined;
}

export {
    useAuth,
    logout,
    isTokenExpired,
    isPathAccessAllowed,
    getDecodedToken,
    getCurrentProcessPerm,
    }