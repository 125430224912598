import React from 'react'
import { useNavigate } from "react-router-dom";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Icon from '../../component/core-component/Icon';
import { WithAuth } from '../../common/WithAuth';

function ReconciliationCard() {

    const navigate = useNavigate();
    const isMobileDevice = window.screen.width <= 900;

    return (
        <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer`}>
            <SideMenu headerName="Accounts Department"/>
            <div className="h-screen flex-1 overflow-hidden">
                <Header/>
                <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
                    <div className=" flex flex-row justify-between">
                        {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
                            Accounts Department 
                        </h1>}
                    </div>
                    <div className={`flex flex-row ${isMobileDevice && 'w-full justify-center items-center'}`}>
                        <div className={`bg-white hover:cursor-pointer commonCard ${!isMobileDevice && 'mx-4 samdProcessCards'} ${isMobileDevice && 'reconciliationCard_responsive'}`} onClick={() => navigate("/accountsDepartment/reconciliation")}>
                            <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                                <div>
                                    <Icon iconName="reconcilationIcon" width="16" height="16" />
                                </div>
                                <span className="font-bold">
                                    Reconciliation
                                </span>
                                <div onClick={() => navigate("/accountsDepartment/reconciliation")} className="cursor-pointer arrowIcon commonCardIcons">
                                    <img src="/assets/right-arrow.svg" alt="file" />
                                </div>
                            </div>
                            <div>
                                <p className="p-4">
                                    This process verifies and synchronizes transaction entries between two banks, resolving discrepancies and ensuring accurate financial records.                               
                                </p>
                                <div className="float-end px-4 mt-5">
                                    <p>
                                        Created on 14 Feb, 2024
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithAuth(ReconciliationCard);