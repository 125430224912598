import React, { useEffect, useRef, useState } from "react";
import Pagination from "./Pagination";
import "../styles/reconcilition.scss";
import { column } from "stylis";
import Icon from "./core-component/Icon";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ViewError from "./core-component/viewError";
import details from "../assets/images/fi_more-vertical.svg";
import fileIcon from "../assets/images/file-link.svg";
import fileNameLogo from "../assets/images/fileNameLogo.svg";
import { ColorRing } from "react-loader-spinner";
import renameIcon from "../assets/images/renameIcon.svg";
import deleteIcon from "../assets/images/deleteIcon.svg";
import API from "../helpers/api";
import { toast } from "react-toastify";
import { encodeData, handleErrorMessage } from "../common/commonFunction";
import { getCurrentProcessPerm } from "../helpers/auth";

export default function DataTable({
  columns,
  rowData,
  isCalledFrom,
  hidePagination,
  isErrorData,
  handleResendLink,
  getLoanStorageDocuments,
  tableLoading,
}) {
  const [viewError, setViewError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [imageData, setImageData] = useState();
  // const [readFullMessage, setReadFullMessage] = useState(false)
  const showTable = isErrorData ? (viewError ? false : true) : true;
  useEffect(() => {
    setCurrentPage(1);
  }, [rowData]);
  const [showTableState, setShowTableState] = useState(showTable);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = rowData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(rowData?.length / recordsPerPage);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [requestSubmitLoader, SetRequestSubmitLoader] = useState(tableLoading);
  const [documentName, setDocumentName] = useState("");
  const [documentNameError, setDocumentNameError] = useState("");
  const [renameDocLoading, setRenameDocLoading] = useState(false);
  const [readFullMessage, setReadFullMessage] = useState(
    Array(currentRecords?.length)?.fill(false)
  );
  const [documentData, setDocumentData] = useState({});
  const [linkView, setLinkView] = useState(false);
  const [linkDataView, setLinkDataView] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [info, setInfo] = useState("");
  const [openScreenShot, setOpenScreenShot] = useState();
  const [openScreenShotframe, setOpenScreenShotframe] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (
        showOptionsModal &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setShowOptionsModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showOptionsModal]);

  const handleLinkClick = (linkData) => {
    setLinkDataView(linkData);
    setLinkView(true);
  };

  function closeLinkModal() {
    setLinkView(false);
  }

  const navigate = useNavigate();

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }

  function openViewRequest(currentRecord) {
    let kyc_id = currentRecord?.id;
    navigate(`/viewRequest?kyc_id=${kyc_id}`);
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  function openFolderRequest(currentRecord) {
    setSelectedRow(currentRecord);
    setShowOptionsModal(!showOptionsModal);
  }

  // Function to handle closing options modal
  function handleCloseOptionsModal() {
    setShowOptionsModal(false);
    // setSelectedRow(null);
  }

  // Function to handle renaming the selected row
  async function handleRename(item) {
    // setWorkerLoading(true)
    setDocumentName(item?.document_name);
    setDocumentData(item);
    setIsOpen(true);
    handleCloseOptionsModal();
  }

  const updateDocName = (event) => {
    setDocumentName(event.target.value);
    if (event.target.value) {
      setDocumentNameError("");
    } else {
      setDocumentNameError("Document name is required");
    }
  };

  // const showFullMessage = () =>{
  //   setReadFullMessage(!readFullMessage)
  // }
  const showFullMessage = (index) => {
    const newArray = [...readFullMessage];
    newArray[index] = !newArray[index];
    setReadFullMessage(newArray);
  };

  // Function to handle deleting the selected row
  async function handleDelete(item) {
    handleCloseOptionsModal();
    const payload = { id: item?.id };
    try {
      const response = await API.apiDelete(
        `/fileStorage/file/documents`,
        "",
        payload
      );
      if (response?.status) {
        toast.success(response?.data?.message);
        getLoanStorageDocuments();
      } else {
        toast.error(response?.data?.error);
      }
      // setWorkerLoading(false)
    } catch (error) {
      // setWorkerLoading(false)
      handleErrorMessage(error);
      console.log(error);
    }
  }

  function openErrorRequest(data) {
    setShowTableState(false);
    setImageData(data);
    setViewError(false);
  }
  function openFolder(item) {
    let folder_id = item?.id;
    navigate(`/ViewFolderDetails?folder_id=${folder_id}`);
  }

  async function handleRenameSubmitFun() {
    try {
      if (documentName != "") {
        setRenameDocLoading(true);
        const item = { ...documentData };
        const payload = {
          folder_id: item?.folder_id,
          id: item?.id,
          document_name: documentName,
        };
        const response = await API.apiPut(`/fileStorage/file/documents`, {
          payload: encodeData(payload),
        });
        if (response?.status) {
          setRenameDocLoading(false);
          toast.success(response?.data?.message);
          getLoanStorageDocuments();
          closeModal();
        } else {
          toast.error(response?.data?.error);
          setRenameDocLoading(false);
        }
        // setWorkerLoading(false)
      } else {
        setDocumentNameError("Document name is required");
        setRenameDocLoading(false);
      }
    } catch (error) {
      // setWorkerLoading(false)
      handleErrorMessage(error);
      console.log(error);
    }
  }

  const handleInfoModal = (info, title) => {
    setInfo({ title, description: info });
    setInfoModalOpen(true);
  };

  const getScreenShotURL = (item) => {
    setOpenScreenShot(item.signedUrl);
  };

  const closeScreenShot = () => {
    setOpenScreenShot("");
  };

  return (
    <div
      className={`${
        !showTableState ? "shadow-none" : "reconcilitaion-main-container"
      }`}
    >
      {openScreenShot && (
        <div className="modal-content absolute rounded-lg bg-white border modalContainer createLinkErrorShot p-5">
          <div className="flex flex-row-reverse">
            <img
              src="/assets/images/closeErrorFrame.png"
              className="w-7"
              onClick={() => closeScreenShot()}
            ></img>
          </div>
          <div className="errorScreenShot">
            <img src={openScreenShot}></img>
          </div>
        </div>
      )}
      {showTableState && (
        <div className="reconcilitionTable-container">
          {currentRecords?.length > 0 ? (
            <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable shadow">
              <thead>
                <tr className="ps-2 pe-2">
                  {columns?.map((item, indx) =>
                    item?.title === "KYC Video" ? (
                      <th
                        key={`row${indx}`}
                        className="py-2 px-4"
                        style={{ width: item?.width, fontSize: "16px" }}
                      >
                        {item?.title}
                      </th>
                    ) : item?.title === "NID" ? (
                      <th
                        key={`row${indx}`}
                        className="py-2 px-4"
                        style={{ width: item?.width, fontSize: "16px" }}
                      >
                        {item?.title}
                      </th>
                    ) : (
                      <th
                        key={`row${indx}`}
                        className="py-2 px-4 capitalize"
                        style={{ width: item?.width, fontSize: "16px" }}
                      >
                        {item?.title.toLowerCase()}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {isCalledFrom === "Details"
                  ? currentRecords.map((item, index) => (
                      <tr key={`${item}_${index}`}>
                        {columns.map((column, colIdx) =>
                          column?.key === "status" ? (
                            <td
                              key={`col_${index}${colIdx}`}
                              className="py-2 ps-4 "
                              style={{ fontSize: "16px" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key] == "Failed" ? (
                                  <span
                                    className={`px-1 rounded-full tabletDebit`}
                                  >
                                    <span className="red-dot" />
                                    Failed
                                  </span>
                                ) : (
                                  <span className="rounded-full tabletCredit px-1">
                                    <span className="green-dot" />
                                    Success
                                  </span>
                                )
                              ) : (
                                <span className="text-red-500">
                                  {" "}
                                  - Missing Record
                                </span>
                              )}
                            </td>
                          ) : column?.key === "created_at" ? (
                            <td
                              className="py-2 ps-4"
                              title={moment(item?.[column?.key]).format(
                                "YYYY-MM-DD | HH:mm:ss"
                              )}
                              style={{ fontSize: "16px" }}
                            >
                              {moment(item?.[column?.key]).format(
                                "YYYY-MM-DD | HH:mm:ss"
                              )}
                            </td>
                          ) : (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key]?.length > 20 ? (
                                  `${item?.[column?.key].substring(0, 20)}...`
                                ) : (
                                  item?.[column?.key]
                                )
                              ) : (
                                <span> -</span>
                              )}
                            </td>
                          )
                        )}
                      </tr>
                    ))
                  : isCalledFrom === "ContactForm"
                  ? currentRecords.map((item, index) => (
                      <tr key={`${item}_${index}`}>
                        {columns.map((column, colIdx) =>
                          column?.key === "status" ? (
                            <td
                              key={`col_${index}${colIdx}`}
                              className="py-2 ps-4 "
                              style={{ fontSize: "16px", width: "25%" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key] == "Failed" ? (
                                  <span
                                    className={`px-1 rounded-full tabletDebit`}
                                  >
                                    <span className="red-dot" />
                                    Failed
                                  </span>
                                ) : (
                                  <span className="rounded-full tabletCredit px-1">
                                    <span className="green-dot" />
                                    Success
                                  </span>
                                )
                              ) : (
                                <span className="text-red-500">
                                  {" "}
                                  - Missing Record
                                </span>
                              )}
                            </td>
                          ) : column?.key === "created_at" ? (
                            <td
                              className="py-2 ps-4"
                              title={
                                typeof item?.[column?.key] === "object"
                                  ? JSON.stringify(item[column.key])
                                  : item[column.key]
                              }
                              style={{ fontSize: "16px", width: "25%" }}
                            >
                              {moment(item?.[column?.key]).format(
                                "YYYY-MM-DD | HH:mm:ss"
                              )}
                            </td>
                          ) : column?.key === "KYC_status" ? (
                            <td
                              key={`col_${index}${colIdx}`}
                              className="py-2 ps-4"
                              style={{ fontSize: "16px" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key] == "Pending" ? (
                                  <span
                                    className={`px-1 rounded-full tabletDebitPending`}
                                  >
                                    <span className="yellow-dot" />
                                    Pending
                                  </span>
                                ) : item?.[column?.key] == "Approved" ? (
                                  <span className="rounded-full tabletCredit px-1">
                                    <span className="green-dot" />
                                    Approved
                                  </span>
                                ) : (
                                  <span className="px-1 rounded-full tabletDebit">
                                    <span className="red-dot" />
                                    Rejected
                                  </span>
                                )
                              ) : (
                                <span className="text-red-500">
                                  {" "}
                                  - Missing Record
                                </span>
                              )}
                            </td>
                          ) : column?.key === "updated_at" ||
                            column?.key === "start_date" ? (
                            <td
                              className="py-2 ps-4"
                              title={moment(item?.[column?.key]).format(
                                "YYYY-MM-DD | HH:mm:ss"
                              )}
                              style={{ fontSize: "16px" }}
                            >
                              {moment(item?.[column?.key]).format(
                                "YYYY-MM-DD | HH:mm:ss"
                              )}
                            </td>
                          ) : column?.title === "ACTIONS" ? (
                            <td
                              className="py-2 ps-4 text-center"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <button onClick={() => openViewRequest(item)}>
                                <Icon
                                  iconName="EyeAlt"
                                  width={16}
                                  height={16}
                                />
                              </button>
                            </td>
                          ) : column?.title === "RESEND LINK" &&
                            item?.["KYC_status"] === "Pending" ? (
                            <td
                              className="py-2 ps-4 text-center flex w-full"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <button
                                className={`w-full flex justify-center items-center gap-2 text-blue-500`}
                                onClick={() => handleResendLink(item)}
                              >
                                Resend <img src="/assets/images/resend.svg" />
                              </button>
                            </td>
                          ) : column?.title === "Screenshot" ? (
                            <td
                              className="py-2 ps-4"
                              style={{ paddingLeft: "47px" }}
                              title={
                                typeof item?.[column?.key] === "object"
                                  ? JSON.stringify(item[column.key])
                                  : item[column.key]
                              }
                            >
                              {item?.image_data?.length > 0 ? (
                                <button
                                  onClick={() =>
                                    openErrorRequest(item?.image_data)
                                  }
                                >
                                  <Icon
                                    iconName="EyeAlt"
                                    width={16}
                                    height={16}
                                    title="View Screenshot"
                                  />
                                </button>
                              ) : (
                                `No Error Screenshot Found`
                              )}{" "}
                            </td>
                          ) : column?.componentSrc === "ViewFolder" ? (
                            <td
                              className="py-2 ps-4 text-center"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <button onClick={() => openViewRequest(item)}>
                                <img src={details} width={16} height={16} />
                              </button>
                            </td>
                          ) : column?.title === "FOLDER NAME" ? (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <span
                                className={`newFolder flex cursor-pointer`}
                                onClick={() => openFolder(item)}
                              >
                                <img className="me-2" src={fileNameLogo} />
                                {item?.[column?.key]}
                              </span>
                            </td>
                          ) : column?.title === "DOCUMENT NAME" ? (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <span className={`newFolder flex cursor-pointer`}>
                                <img className="me-2" src={fileIcon} />
                                {item?.[column?.key]}
                              </span>
                            </td>
                          ) : column.key === "message" ? (
                            readFullMessage[index] ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                {item?.[column?.key] ? (
                                  item?.[column?.key]
                                ) : (
                                  <span className="w-full flex justify-center emptyField">
                                    {" "}
                                    -
                                  </span>
                                )}
                                <p
                                  className="ReadMoreContactMessage"
                                  onClick={() => showFullMessage(index)}
                                >
                                  Read Less
                                </p>
                              </td>
                            ) : (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                {item?.[column?.key] ? (
                                  item?.[column?.key]?.length > 40 ? (
                                    `${item?.[column?.key].substring(0, 40)}...`
                                  ) : (
                                    item?.[column?.key]
                                  )
                                ) : (
                                  <span className="w-full flex justify-center emptyField">
                                    {" "}
                                    -
                                  </span>
                                )}
                                {item?.[column?.key]?.length > 40 && (
                                  <p
                                    className="ReadMoreContactMessage"
                                    onClick={() => showFullMessage(index)}
                                  >
                                    Read More
                                  </p>
                                )}
                              </td>
                            )
                          ) : (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key]?.length > 20 ? (
                                  `${item?.[column?.key].substring(0, 20)}...`
                                ) : (
                                  item?.[column?.key]
                                )
                              ) : (
                                <span className="w-full flex justify-center emptyField">
                                  {" "}
                                  -
                                </span>
                              )}
                            </td>
                          )
                        )}
                      </tr>
                    ))
                  : currentRecords?.length > 0
                  ? currentRecords.map((item, index) => (
                      <tr key={`${item}_${index}`}>
                        {columns.map(
                          (column, colIdx) =>
                            column?.key === "KYC_status" ? (
                              <td
                                key={`col_${index}${colIdx}`}
                                className="py-2 ps-4"
                                style={{ fontSize: "16px" }}
                              >
                                {item?.[column?.key] ? (
                                  item?.[column?.key] == "Pending" ? (
                                    <span
                                      className={`px-1 rounded-full tabletDebitPending`}
                                    >
                                      <span className="yellow-dot" />
                                      Pending
                                    </span>
                                  ) : item?.[column?.key] == "Approved" ? (
                                    <span className="rounded-full tabletCredit px-1">
                                      <span className="green-dot" />
                                      Approved
                                    </span>
                                  ) : (
                                    <span className="px-1 rounded-full tabletDebit">
                                      <span className="red-dot" />
                                      Rejected
                                    </span>
                                  )
                                ) : (
                                  <span className="text-red-500">
                                    {" "}
                                    - Missing Record
                                  </span>
                                )}
                              </td>
                            ) : [
                                "updated_at",
                                "start_date",
                                "account_data_scrapped_at",
                                "nid_verified_at",
                                "account_updated_at",
                              ]?.includes(column?.key) ? (
                                <td
                                className="py-2 ps-4"
                                title={
                                  item?.[column?.key] === "null"
                                  ? ""
                                  : moment(item?.[column?.key]).format(
                                    "YYYY-MM-DD | HH:mm:ss"
                                  )
                                }
                                style={{ fontSize: "16px" }}
                                >
                                {column?.file == "Error_logs"
                                  ? item?.[column?.key]
                                    ? moment(item?.[column?.key]).format(
                                        "YYYY-MM-DD | HH:mm:ss"
                                      )
                                    : "-"
                                  : item?.[column?.key] === "null"
                                  ? "-"
                                  : moment(item?.[column?.key]).format(
                                      "YYYY-MM-DD | HH:mm:ss"
                                    )}
                              </td>
                            ) : column?.title === "ACTIONS" ? (
                              <td
                                className="py-2 ps-4 text-center"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <button onClick={() => openViewRequest(item)}>
                                  <Icon
                                    iconName="EyeAlt"
                                    width={16}
                                    height={16}
                                  />
                                </button>
                              </td>
                            ) : column?.title === "RESEND LINK" &&
                              item?.["KYC_status"] === "Pending" ? (
                              <td
                                className="py-2 ps-4 text-center flex w-full"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <button
                                  className={`w-full flex justify-center items-center gap-2 text-blue-500`}
                                  onClick={() => handleResendLink(item)}
                                >
                                  Resend <img src="/assets/images/resend.svg" />
                                </button>
                              </td>
                            ) : column?.title === "Screenshot" ? (
                              <td
                                className="py-2 ps-4"
                                
                              >
                                {item?.image_data?.length > 0 ? (
                                  <button
                                    onClick={() =>
                                      openErrorRequest(item?.image_data)
                                    }
                                  >
                                    <Icon
                                      iconName="EyeAlt"
                                      width={16}
                                      height={16}
                                      title="view screenshot"
                                    />
                                  </button>
                                ) : (
                                  `No Error Screenshot Found`
                                )}
                              </td>
                            ) : column?.key === "created_at" ||
                              column?.key === "start_date" ? (
                              <td
                                className="py-2 ps-4"
                                title={moment(item?.[column?.key]).format(
                                  "YYYY-MM-DD | HH:mm:ss"
                                )}
                                style={{ fontSize: "16px" }}
                              >
                                {moment(item?.[column?.key]).format(
                                  "YYYY-MM-DD | HH:mm:ss"
                                )}
                              </td>
                            ) : // (column?.componentSrc === "ViewFolder") ? <td className="py-2 ps-4 options-cell" title={item?.[column?.key]} style={{ fontSize: '16px' }}><button onClick={() => openFolderRequest(item, column, index)}><img src={details} width={16} height={16} /></button> {showOptionsModal && selectedRow === item && (
                            column?.title === "RESEND LINK" &&
                              item?.["KYC_status"] === "Pending" ? (
                              <td
                                className="py-2 ps-4 text-center flex w-full"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <button
                                  className={`w-full flex justify-center items-center gap-2 text-blue-500`}
                                  onClick={() => handleResendLink(item)}
                                >
                                  Resend <img src="/assets/images/resend.svg" />
                                </button>
                              </td>
                            ) : column?.title === "Screenshot" ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                              >
                                {item?.image_data?.length > 0 ? (
                                  <button
                                    onClick={() =>
                                      openErrorRequest(item?.image_data)
                                    }
                                  >
                                    <Icon
                                      iconName="EyeAlt"
                                      width={16}
                                      height={16}
                                      title="view screenshot"
                                    />
                                  </button>
                                ) : (
                                  `No Error Screenshot Found`
                                )}
                              </td>
                            ) : column?.key === "created_at" ||
                              column?.key === "start_date" ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                {column?.file == "Error_logs"
                                  ? moment(item?.[column?.key]).format(
                                      "YYYY-MM-DD | HH:mm:ss"
                                    )
                                  : moment(item?.[column?.key]).format(
                                      "YYYY-MM-DD | HH:mm:ss"
                                    )}
                              </td>
                            ) : column?.componentSrc === "ViewFolder" ? (
                              <td
                                className="py-2 ps-4 options-cell"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <button
                                  onClick={() =>
                                    openFolderRequest(item, column, index)
                                  }
                                >
                                  <img src={details} width={16} height={16} />
                                </button>{" "}
                                {showOptionsModal && selectedRow === item && (
                                  <div className="options-modal" ref={ref}>
                                    <button
                                      className={`text-black flex ${
                                        getCurrentProcessPerm(5).delete &&
                                        "border-b"
                                      }`}
                                      onClick={() => handleRename(item)}
                                    >
                                      {" "}
                                      <img
                                        src={renameIcon}
                                        alt="Rename"
                                        className={`button-icon me-1 pt-1 ${
                                          getCurrentProcessPerm(5).delete &&
                                          "pb-2"
                                        }`}
                                      />{" "}
                                      Rename
                                    </button>
                                    {getCurrentProcessPerm(5).delete && (
                                      <button
                                        className="text-red-500 flex"
                                        onClick={() => handleDelete(item)}
                                      >
                                        {" "}
                                        <img
                                          src={deleteIcon}
                                          alt="Rename"
                                          className="button-icon me-1 pt-1"
                                        />{" "}
                                        Delete
                                      </button>
                                    )}
                                    {/* <button onClick={handleCloseOptionsModal}>Close</button> */}
                                  </div>
                                )}
                              </td>
                            ) : column?.title === "FOLDER NAME" ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <span
                                  className={`newFolder flex cursor-pointer`}
                                  onClick={() => openFolder(item)}
                                >
                                  <img className="me-2" src={fileNameLogo} />
                                  {item?.[column?.key] ? (
                                    item?.[column?.key]?.length > 20 ? (
                                      `${item?.[column?.key].substring(
                                        0,
                                        20
                                      )}...`
                                    ) : (
                                      item?.[column?.key]
                                    )
                                  ) : (
                                    <span> -</span>
                                  )}
                                </span>
                              </td>
                            ) : column?.title === "DOCUMENT NAME" ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                <span
                                  className={`newFolder flex cursor-pointer`}
                                >
                                  <img className="me-2" src={fileIcon} />
                                  <a
                                    onClick={() =>
                                      handleLinkClick(item?.document_key)
                                    }
                                  >
                                    {item?.[column?.key]}
                                  </a>
                                </span>
                              </td>
                            ) : column?.key === "User" ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]?.full_name}
                                style={{ fontSize: "16px" }}
                              >
                                <span className={`flex`}>
                                  {item?.[column?.key]?.full_name}
                                </span>
                              </td>
                            ) : column?.key === "signedUrl" ? (
                              item?.signedUrl != "No screenshot available" ? (
                                <div className="flex justify-center align-middle mt-3">
                                  <button
                                    type="button"
                                    onClick={() => getScreenShotURL(item)}
                                  >
                                    <div
                                      className="errorImageicon"
                                      title="Show Error Screenshot"
                                    >
                                      {
                                        <img src="assets/images/infoIcon.svg"></img>
                                      }
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                ""
                              )
                            ) : ["error_log", "error_message"]?.includes(
                                column?.key
                              ) ? (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                {item?.[column?.key] ? (
                                  item?.[column?.key]?.length > 25 ? (
                                    `${item?.[column?.key].substring(0, 30)}...`
                                  ) : (
                                    item?.[column?.key]
                                  )
                                ) : (
                                  <span> -</span>
                                )}
                              </td>
                            ) : (
                              <td
                                className="py-2 ps-4"
                                title={item?.[column?.key]}
                                style={{ fontSize: "16px" }}
                              >
                                {item?.[column?.key] ? (
                                  item?.[column?.key]?.length > 20 ? (
                                    `${item?.[column?.key].substring(0, 20)}...`
                                  ) : (
                                    item?.[column?.key]
                                  )
                                ) : (
                                  <span> -</span>
                                )}
                              </td>
                            ),
                          // )}</td> :
                          column?.title === "FOLDER NAME" ? (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <span
                                className={`newFolder flex cursor-pointer`}
                                onClick={() => openFolder(item)}
                              >
                                <img className="me-2" src={fileNameLogo} />
                                {item?.[column?.key] ? (
                                  item?.[column?.key]?.length > 20 ? (
                                    `${item?.[column?.key].substring(0, 20)}...`
                                  ) : (
                                    item?.[column?.key]
                                  )
                                ) : (
                                  <span> -</span>
                                )}
                              </span>
                            </td>
                          ) : column?.title === "LAST UPDATED BY" ||
                            column?.title === "CREATED BY" ||
                            column?.title === "UPLOADED BY" ? (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]?.full_name}
                              style={{ fontSize: "16px" }}
                            >
                              {item?.[column?.key] ? (
                                item?.[column?.key]?.full_name?.length > 20 ? (
                                  `${item?.[column?.key]?.full_name.substring(
                                    0,
                                    20
                                  )}...`
                                ) : (
                                  item?.[column?.key]?.full_name
                                )
                              ) : (
                                <span> -</span>
                              )}
                            </td>
                          ) : column?.title === "DOCUMENT NAME" ? (
                            <td
                              className="py-2 ps-4"
                              title={item?.[column?.key]}
                              style={{ fontSize: "16px" }}
                            >
                              <span className={`newFolder flex cursor-pointer`}>
                                <img className="me-2" src={fileIcon} />
                                <a
                                  onClick={() =>
                                    handleLinkClick(item?.document_key)
                                  }
                                >
                                  {item?.[column?.key]}
                                </a>
                              </span>
                            </td>
                          ) : (
                            <td
                              className="py-2 ps-4"
                              style={{ fontSize: "16px" }}
                            >
                              <span className="flex justify-between">
                                {item?.[column?.key] ? (
                                  item?.[column?.key]?.length > 20 ? (
                                    `${item?.[column?.key].substring(0, 20)}...`
                                  ) : (
                                    item?.[column?.key]
                                  )
                                ) : (
                                  <span> -</span>
                                )}
                                {item?.[column?.key]?.length > 20 && (
                                  <button
                                    onClick={() =>
                                      handleInfoModal(
                                        item?.[column?.key],
                                        column.title
                                      )
                                    }
                                  >
                                    <Icon
                                      iconName="info"
                                      width={16}
                                      height={16}
                                    />
                                  </button>
                                )}
                              </span>
                            </td>
                          )
                        )}
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          ) : (
            <>
              <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                  <tr className="ps-2 pe-2">
                    {columns?.map((item, indx) =>
                      item?.title === "KYC Video" ? (
                        <th
                          key={`row${indx}`}
                          className="py-2 px-4"
                          style={{ width: item?.width, fontSize: "16px" }}
                        >
                          {item?.title}
                        </th>
                      ) : item?.title === "NID" ? (
                        <th
                          key={`row${indx}`}
                          className="py-2 px-4"
                          style={{ width: item?.width, fontSize: "16px" }}
                        >
                          {item?.title}
                        </th>
                      ) : (
                        <th
                          key={`row${indx}`}
                          className="py-2 px-4 capitalize"
                          style={{ width: item?.width, fontSize: "16px" }}
                        >
                          {item?.title.toLowerCase()}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
              </table>
              <div className="flex justify-center py-5 font-semibold">
                {tableLoading ? "" : "No data found"}
              </div>
            </>
          )}
        </div>
      )}

      {!showTableState && (
        <ViewError
          imageData={imageData}
          setShowTableState={setShowTableState}
        />
      )}

      {!hidePagination && currentRecords?.length > 0 && (
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        ></Pagination>
      )}

      {isOpen && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabindex="-1"
          aria-hidden="true"
          class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
        >
          {(requestSubmitLoader || renameDocLoading) && (
            <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
              <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
              />
            </div>
          )}
          <div
            className="modal absolute"
            style={
              requestSubmitLoader || renameDocLoading
                ? { filter: "blur(1.5px)", pointerEvents: "none" }
                : null
            }
          >
            <div className="modal-content rounded-lg bg-white border modalContainer createModal p-5">
              <div className="ps-3 pe-3 pt-1">
                <p className="font-small border-b-2 pb-5 font-semibold text-lg ">
                  Update Folder
                </p>
              </div>
              <div className="modalForm">
                <div className=" flex flex-col ps-3 pe-3 mt-4">
                  <label htmlFor="document_name" className="numberRequestModal">
                    Document Name*
                  </label>
                  <input
                    type="text"
                    id="inputA"
                    name="document_name"
                    value={documentName}
                    onChange={(e) => updateDocName(e)}
                    placeholder="Enter Document Name"
                    className={`py-2 rounded outline-none ps-2 border`}
                  />
                  {documentName == "" && documentNameError != "" && (
                    <span
                      className="numberRequestModal"
                      style={{ color: "red" }}
                    >
                      {documentNameError}
                    </span>
                  )}
                </div>

                <div className="flex justify-end mt-5 mb-3">
                  <div className="flex justify-center align-middle me-2">
                    <button
                      type="button"
                      className=" border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary"
                      onClick={closeModal}
                      disabled={renameDocLoading}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex justify-center align-middle me-3">
                    <button
                      type="submit"
                      className=" relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium commonBtn custom_btn_primary py-2"
                      onClick={handleRenameSubmitFun}
                      disabled={renameDocLoading}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {linkView && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabindex="-1"
          aria-hidden="true"
          class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
        >
          {requestSubmitLoader && (
            <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
              <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
              />
            </div>
          )}
          <div
            className="modal absolute"
            style={
              requestSubmitLoader
                ? { filter: "blur(0.7px)", pointerEvents: "none" }
                : null
            }
          >
            <div className="modal-content rounded-lg bg-white border modalContainer createLinkModal p-5">
              <iframe src={linkDataView} width="100%" height="700"></iframe>
              <div className="flex justify-end align-middle me-2 mt-3">
                <button
                  type="button"
                  className=" border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2"
                  onClick={closeLinkModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {infoModalOpen && (
        <>
          <div
            id="static-modal"
            data-modal-backdrop="static"
            tabindex="-1"
            aria-hidden="true"
            class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
          >
            <div class="relative p-4 w-full max-w-2xl max-h-full">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    {info?.title}
                  </h3>
                  <button
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="static-modal"
                    onClick={() => setInfoModalOpen(false)}
                  >
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <div class="p-4 md:p-5 space-y-4">
                  {info?.description && <p>{info?.description}</p>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
