
import { useNavigate } from "react-router";
import moment from 'moment';
import DataTable from "../DataTable";
import Icon from "./Icon";
import React, { useEffect, useState } from "react";
import ViewError from '../../component/core-component/viewError';
const ErrorCard = ({apiData, handleBack, paramsQuery}) => {
  const cardData = [{...apiData,task_id: paramsQuery.id}];
  const [viewError, setViewError] = useState(false);
  const [responseScreenWidth, setResponseScreenWidth] = useState();
  const showTable = viewError ? false : true
  const [showTableState, setShowTableState] = useState(showTable)
  const [imageData, setImageData] = useState();
  const navigate = useNavigate();

  const requestColumns = [
    {
      title: 'Task id',
      key: 'task_id',
    },
    {
      title: 'Started At',
      key: 'start_date',
    },
    {
      title: 'Data Scrapped At',
      key: 'account_data_scrapped_at',
    },
    {
      title: 'NID Verified At',
      key: 'nid_verified_at',
    },
    {
      title: 'Account Updated At',
      key: 'account_updated_at',
    },
    {
      title: 'Screenshot',
      key: 'image_data',
    }
  
  ];

  function openErrorRequest(data) {
    setShowTableState(false);
    setImageData(data);
    setViewError(false);
  }



  useEffect(()=>{
    let viewportHeight = window.screen.width;
    setResponseScreenWidth(viewportHeight)
  },[])

  // const handleBack = () => {
  //   navigate('/acverify');
  // }
    return(
      responseScreenWidth > 900 ? 
      <>
         <div className="mainContainer">
            <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center mb-4" onClick={() => handleBack()}>
              <Icon iconName="IconBackBtn" width={16} height={16}/>
            </button>
            <div className="rounded-lg overflow-hidden bg-white border border-solid border-gray-border">
              <DataTable columns={requestColumns} rowData={cardData} hidePagination={true} isErrorData={true}/>
            </div>
          </div>
        </>
        :
        <>
         <div className="mainContainer savingsAccount_responsiveView">
            <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center mb-4" onClick={() => handleBack()}>
                  <Icon iconName="IconBackBtn" width={16} height={16}/>
            </button>
            </div>
          <div className="rounded overflow-hidden bg-white">
          {showTableState && <table className="min-w-full" >
              <tr className="border-b">
                <th className="py-2 px-4 text-left">
                  Task Id
                </th>
                <td className="py-2 ps-4">
                  {cardData[0]?.task_id}
                </td>
              </tr>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">
                  Started At
                </th>
                <td className="py-2 ps-4">
                  {cardData[0]?.start_date ? moment(cardData[0]?.start_date).format("YYYY-MM-DD | HH:mm:ss") : ''}
                </td>
              </tr>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">
                  Data Scraped At
                </th>
                <td className="py-2 ps-4">
                  {cardData[0]?.account_data_scrapped_at ? moment(cardData[0]?.account_data_scrapped_at).format("YYYY-MM-DD | HH:mm:ss") : ''}
                </td>
              </tr>
              <tr  className="border-b">
                <th className="py-2 px-4 text-left">
                  Nid Verified At
                </th>
                <td className="py-2 ps-4">
                  {cardData[0]?.nid_verified_at ? moment(cardData[0]?.nid_verified_at).format("YYYY-MM-DD | HH:mm:ss") : ''}
                </td>
              </tr>
              <tr  className="border-b">
                <th className="py-2 px-4 text-left">
                  Account Updated At
                </th>
                <td className="py-2 ps-4">
                  {cardData[0]?.account_updated_at ? moment(cardData[0]?.account_updated_at).format("YYYY-MM-DD | HH:mm:ss") : ''}
                </td>
              </tr>
              <tr className="border-b">
                {(cardData[0]?.image_data?.length > 0) ? <th className="py-2 px-4 text-center text-sky-700" colSpan="2" onClick={() => openErrorRequest(cardData[0]?.image_data)}>View Screenshot</th> :<th className="py-2 px-4 text-center text-gray-500" colSpan="2">No Error Screenshot Found</th>}
              </tr>
            </table>}
            {
              !showTableState && <ViewError imageData={imageData} setShowTableState={setShowTableState} />
            }
          </div>
        </>

    )

}

export default ErrorCard;

