import React, { useState, useCallback } from "react";
import SideMenu from "../../component/SideMenu";
import Breadcrumb from "../../component/Breadcrumb";
import Header from "../../component/Header";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import API from "../../helpers/api/index";
import { useLocation } from "react-router-dom";
import Icon from "../../component/core-component/Icon";
import "../../styles/ivrTable.scss";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import workflowJson from './workflowIVRData.json';
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import { WithAuth } from "../../common/WithAuth";

const IVRViewComponent = () => {
  const location = useLocation();
  const [id, setId] = useState();
  const [action, setAction] = useState();
  const [dataIvr, setDataIvr] = useState();
  const [messageData, setMessageData] = useState([]);
  const [steps, setSteps] = useState(["IVR Management"]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showIVRMenuTreeModal, setShowIVRMenuTreeModal] = useState(false);

  const closeModal = () => {
    setShowIVRMenuTreeModal(false)
   }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get("id"));
    setAction(params.get("action"));
    let currSteps = steps
    currSteps?.push(`${params.get("action")} Workflow`)
    setSteps(currSteps);
    getRequestedIvrData();
  }, []);

  const headerName = action == 'edit' ? 'Edit Workflow' : 'View Workflow';

  useEffect(() => {
    getAllMessages(dataIvr);
  }, dataIvr);

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const isMobileDevice = window.screen.width <= 900;
 
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  function setIVRMenuTreeModal(workflow_name) {
    setNodes(workflowJson?.[workflow_name]?.nodes)
    setEdges(workflowJson?.[workflow_name]?.edges)
  }
  const getRequestedIvrData = async () => {
    const params = new URLSearchParams(location.search);
    let id = params.get("id");
    try {
      const { data } = await API.apiGet(`/v1/ivr/${id}`);
      setDataIvr(data?.data?.[0]);
      setIVRMenuTreeModal(data?.data?.[0]?.workflow_name)
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  };

  const getAllMessages = (value) => {
    const allMessageData = [];
    for (const key in value) {
      if (key.startsWith("message_for_")) {
        allMessageData.push({
          label: key,
          message: dataIvr[key],
        });
      }
    }
    setMessageData(allMessageData);
  };

  const handleChange = (event) => {
    const {name, value} = event?.target;
    setMessageData((prev) => {
      let prevData = [...prev]
      prevData = prevData?.map(item => {
        if (item?.label == name) {
          item.message = value
        }
        return item;
      })
      return prevData;
    })
  };

  const handleWelcomeMessageChange = (event) =>{
    setDataIvr({ ...dataIvr, welcome_message: event.target.value });
  }

  const showModal = () => {
    setShowIVRMenuTreeModal(true)
   }

  const handleUpdate = async () => {
    let payload = {}
    messageData.forEach(item => {
      if (item.message !== "") {
        payload[item.label] = item.message;
      }
  });
    payload.welcome_message = dataIvr.welcome_message;
    try {
      const { data } = await API.apiPut(`/v1/ivr/${id}`, {payload: encodeData(payload)});
      getRequestedIvrData();
      if (data?.success) {
        toast.success("Message updated successfully");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  };

  const handleFileSelect = async(event, isDragged) => {
    const file = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    let payload = {
      ivr_workflow_name: dataIvr?.workflow_name,
      ivr_service_provider: dataIvr?.ivr_service_provider,
      id: dataIvr?.id
    }
    // const encodedPayload = encodeData(payload);
    formData.append('ivr_workflow_name', dataIvr?.workflow_name);
    formData.append('ivr_service_provider', dataIvr?.ivr_service_provider);
    formData.append('id', dataIvr?.id);
    setSelectedFile(file);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/v1/ivr/initiate/call`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
        }
      });
      const responseData = await response.json();
      if(responseData?.success){
        toast.success(responseData?.message);
      }else{
        toast.error(responseData?.error)
      }
     }
     catch(error){
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
     }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileSelect(event, true)
    // event.stopPropagation();
    // const file = event.dataTransfer.files[0];
    // setSelectedFile(file);
  };

  const handleClear = () => {
    setSelectedFile(null)
  }

  const handleCall = async() => {
    // setOpenPopup(true)
    let payload = new FormData();
      payload.append('file', selectedFile);
      payload.append('ivr_workflow_id', id);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/v1/ivr/initiate/call`, {
        method: "POST",
        body: payload,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
        }
      });
      if (response?.data?.success) {
          toast.success(response?.data?.message);
      }
      else {
          toast.error(response?.data?.message)
      }
  }
  catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
  }
  }

  return (
    <div className={`flex dark:bg-dark-background updateIvrContainer mainBodyContainer`}>
      <SideMenu headerName={headerName}/>
      <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${!isMobileDevice && 'container fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          <Breadcrumb steps={steps} activeStep={1} showBackButton={true} redirectUrl={'/updateIvrTable'} />
          { action == 'edit' && <div className="userEdit mt-3">
           <div className="p-4 flex">
              <div className="ps-2 capitalize">
                <b> {new URLSearchParams(location.search).get("action")} Workflow </b>
                <p>Translation Alert</p>
              </div>
            </div>
            {/* <div className={`flex ps-4 userDetails}`}>
                                    <div className="w-full m-2 pe-3 sellerDetails">
                                        
                                        <div className="text-black-50 labelColor">Workflow Name:</div>
                                        <div
                                            className="mt-1 me-3 userName">
                                            {dataIvr?.workflow_name}
                                        </div>
                                        
                                    </div>
                                    <div className="w-full m-2 px-2 sellerDetails">
                                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                                            Workflow Message:
                                        </div>
                                        <div className="mt-1 userName">
                                        {dataIvr?.welcome_message}
                                        </div>
                                    </div>
                                    <div className="w-full m-2 px-3 ">
                                        <div className="text-black-50 mr-2 labelColor">Status:</div>
                                        {dataIvr?.status == 'Inactive' ? <span className={`text-red text-center`}><span className="red-dot"/>Inactive</span> : <span className={`text-green-500`}><span className="text-green-500 green-dot"/>Active</span> }
                                    </div>
                                </div> */}
          </div>}
          <div className="mt-4 rounded-lg">
            <div className="mb-3 rounded-lg">
              <div className={`flex justify-between ${isMobileDevice && 'flex-col'}`}>
                {/* <div className='border border-gray-400 text-center pt-2.5  w-48 rounded  h-12 mt-12 colorBackground text-white'>
                                     {item.label}
                                 </div> */}
                {/* <div className='p-2'>
                                    <textarea
                                        name={item.label}
                                        value={item.message}
                                        onChange={(event) => handleChange(index, event)}
                                        style={{ flex: '1', minWidth: '500px' }}
                                        className="block w-full h-28 rounded-md border-0 ps-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 p-1 outline-none"
                                        disabled={action === 'view'}    
                                    />
                                 </div>  */}
                {/* <div className='mt-12'>
                                 <button
                                    type='button'
                                    name={item.label}
                                    onClick={() => handleUpdate(index, item.label)}
                                    className="ml-2 px-4 py-2 colorBackground text-white h-12  w-36 rounded  focus:outline-none disabled"
                                    disabled={action === 'view'}
                                >
                                    Update
                                </button>
                                 </div> */}
                <div className={action == 'edit' ? `ivrBox ${isMobileDevice && action == 'edit' && 'ivrBoxViewTree_responsive'}`:`ivrBoxViewTree ${isMobileDevice && action == 'view' && 'ivrBoxViewTree_responsive'}`}>
                {action === 'edit' ? <div>
                    <div className="border bg-white rounded-lg ">
                      <p className="ps-2 pe-2 pt-1 welcomeMsg ">
                        Welcome Message
                      </p>
                      <div className="ps-2 pe-2 mb-3 relative">
                        <img src="assets/images/pencill.svg " className="absolute welcomePencil"></img>
                        <textarea
                          name="welcome_message"
                          value={dataIvr?.welcome_message}
                          style={{
                            flex: "1",
                            overflow: "hidden",
                            fontSize: "11px",
                          }}
                          onChange={(event) => handleWelcomeMessageChange(event)}
                          className="block w-full rounded border-0 ps-3 h-6 pe-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 outline-none"
                          disabled={action === "view"}
                        />
                      </div>
                    </div>
                    <div className="mt-2 border bg-white rounded-lg pb-2 allMessageContainer">
                      {messageData.map(
                        (item, index) =>
                          (
                            <div className="pt-2 pe-3 flex ml-4">
                              <div
                                type="button"
                                className="colorBackground text-white focus:outline-none mt-1 indexNumber"
                                disabled={action === "view"}
                              >
                                {index==10 ? '*':index==11 ? '#' :index}
                              </div>
                              <div className="w-full ps-2 pe-2 relative">
                              <img src="assets/images/pencill.svg" className=" messagesPencil absolute"></img>
                                <textarea
                                  name={item?.label}
                                  value={item?.message}
                                  style={{ overflow: "hidden", fontSize: "11px" }}
                                  onChange={(event) => handleChange(event)}
                                  className="block indexMessage w-full ms-3 border-0 ps-2 pe-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 outline-none"
                                  disabled={action === "view"}
                                />
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <div className="flex mt-4 flex-row-reverse">
                      {
                        action === 'edit' && 
                        <button
                          type="button"
                          onClick={handleUpdate}
                          className="ml-2 px-4 py-2 colorBackground text-white h-8  w-24  rounded  focus:outline-none disabled"
                          disabled={action === "view"}
                        >
                          Save
                        </button>
                      }
                      {/* <button
                        type="button"
                        // name={item.label}
                        // onClick={() => handleUpdate(index, item.label)}
                        className="ml-2 px-4 py-2 colorBackground text-white h-8 w-20 rounded  focus:outline-none disabled"
                        disabled={action === "view"}
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                  :
                  <div>
                  <div className="flex gap-2 justify-center">
                    <div style={{ width: '100%', height: '80vh' }}>
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        style={{ border: '1px dotted gray' , backgroundColor: 'white'}}
                        className="rounded-lg overflow-hidden"
                        fitView
                        editable={false}
                        nodesDraggable={false}
                      >
                      <Controls />
                      </ReactFlow>
                    </div>
                  </div>
                  </div>
                  }
                </div>
                {/* <div> */}
                 { action === 'edit' ?  <div className={`border rounded-lg bg-white ivrBox uploadBox grid grid-cols-12 ${isMobileDevice && 'ivrResponsiveBox'}`}>
                  <div className=" messageDetailsBox col-span-7">
                    <p className="p-4 uploadCallLst font-semibold">
                      Upload Calling List
                    </p>
                    <div className={`ps-3 pt-3 pb-3 ${isMobileDevice && 'additionalInfo_responsive'}`}>
                      <div className={`flex maxSizeMb text-center ${isMobileDevice && 'uploadFileDesc_responsive'}`}>
                        <div>
                          <p className="sizeUpload">3MB</p>
                          <p className="paraUpload">Max Size</p>
                        </div>
                        <div className="border-r-2 ms-5"></div>
                        <div className="ms-5">
                          <p className="sizeUpload"> xlsv | csv</p>
                          <p className="paraUpload">File Type</p>
                        </div>
                      </div>
                      <p className="text-sm mt-3 additionalUpload">
                        +additional info about the Document Goes here
                      </p>
                    </div>
                  </div>
                  <div className="uploadExcelFileBox col-span-5">
                    <div className={`fileUploader ${isMobileDevice && 'fileUploadContainer_responsive'}`}>
                      <div className={`relative mt-7 ms-2 ${isMobileDevice && 'uploadDoc_responsive'}`} 
                         onDrop={handleDrop} onDragOver={handleDragOver} >
                        <img
                          src="assets/images/excelSheet.png"
                          className="excelImgSheet"
                        ></img>
                        <p className="dragDrop">Drag and Drop File Here</p>
                        <p className="OrText">or</p>
                        <div className="addExcel absolute">
                          <img src="assets/images/plusIcon.svg"></img>
                        </div>
                      </div>
                      <div className={`mt-3 ${isMobileDevice && 'chooseFileUploadBtn_responsive'}`}>
                      {/* <div className="">
                           
                        </div> */}
                        {!selectedFile && (
                          <label className="mt-2 chooseFileBtn rounded-lg">
                            <input
                              type="file"
                              accept=".xlsx"
                              onChange={handleFileSelect}
                              className="mt-2"
                              hidden
                            />
                            Choose file +
                          </label>
                        )}
                        {selectedFile && (
                          <p className="ms-5 text-sm relative">
                            <img src="/assets/images/cross.png" onClick={handleClear} className="cross crossBtnn absolute"/>
                            {selectedFile.name.length > 15
                              ? selectedFile.name.substring(0,10) + "..."
                              : selectedFile.name}
                          </p>
                        )}
                        {/* <button
                          type="button"
                          onClick={handleCall}
                          className={`initiateCallBtn px-4 py-2 colorBackground text-white h-8  w-28  rounded  focus:outline-none disabled`}
                          disabled={selectedFile}
                        >
                          Initiate Call
                        </button> */}
                      </div>
                    </div>
                  </div>
                  </div>:

                  <div className={`border rounded-lg bg-white ivrBoxView grid grid-cols-12 ${isMobileDevice && 'ivrResponsiveBox'}`}>
                  <div className=" messageDetailsBox col-span-7">
                    <p className="p-4 uploadCallLst font-semibold text-center">
                      Upload Calling List
                    </p>
                    <div className={`ps-3 pt-3 pb-3 ${isMobileDevice && 'additionalInfo_responsive'}`}>
                      <div className={`flex justify-center text-center ${isMobileDevice && 'uploadFileDesc_responsive'}`}>
                        <div>
                          <p className="sizeUpload">3MB</p>
                          <p className="paraUpload">Max Size</p>
                        </div>
                        <div className="border-r-2 ms-5"></div>
                        <div className="ms-5">
                          <p className="sizeUpload"> xlsv | csv</p>
                          <p className="paraUpload">File Type</p>
                        </div>
                      </div>
                      <p className="text-sm mt-3 additionalUploadView">
                        +additional info about the Document Goes here
                      </p>
                    </div>
                  </div>
                  <div className="uploadExcelFileBox col-span-5">
                    <div className={`${isMobileDevice && 'fileUploadContainer_responsive'} fileUploadView`}>
                      <div className={`relative ${isMobileDevice && 'uploadDoc_responsive'}`} 
                         onDrop={handleDrop} onDragOver={handleDragOver} >
                        <img
                          src="assets/images/excelSheet.png"
                          className="excelImgSheet"
                        ></img>
                        <p className="dragDrop">Drag and Drop File Here</p>
                        <p className="OrText">or</p>
                        <div className="addExcel absolute">
                          <img src="assets/images/plusIcon.svg"></img>
                        </div>
                      </div>
                      <div className={`mt-3 ${isMobileDevice && 'chooseFileUploadBtn_responsive'}`}>
                      {/* <div className="">
                           
                        </div> */}
                        {!selectedFile && (
                          <label className="mt-2 chooseFileBtn rounded-lg">
                            <input
                              type="file"
                              accept=".xlsx"
                              onChange={handleFileSelect}
                              className="mt-2"
                              hidden
                            />
                            Choose file +
                          </label>
                        )}
                        {selectedFile && (
                          <p className="ms-5 text-sm relative">
                            <img src="/assets/images/cross.png" onClick={handleClear} className="cross crossBtnn absolute"/>
                            {selectedFile.name.length > 15
                              ? selectedFile.name.substring(0,10) + "..."
                              : selectedFile.name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>}
              {/* </div> */}
                </div>
                  <div className={`flex justify-end ${isMobileDevice && 'mt-3'}`}>
                    {action === "edit" && <button
                      onClick={showModal}
                      className={` hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md disabled:disabledBtn custom_btn_primary commonBtn w-32 ${isMobileDevice && 'menuTreeBtn_responsive'}`}
                    >
                      Menu Tree
                    </button>}
                  </div>
            </div>
          </div>
        </div>
      </div>
      {showIVRMenuTreeModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
        <div class={`relative p-4 w-3/5 max-h-full ${isMobileDevice && 'w-full'}`}>
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                IVR MENU TREE
              </h3>
              <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 flex gap-2 justify-center">
              <div style={{ width: '100%', height: '80vh' }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                >
                  <Controls />
                  <MiniMap />
                  <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
              </div>
            </div>

            {/* <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn" onClick={handleFilter}> 
                             Download
                            </button>
                        </div> */}
          </div>
        </div>
      </div>}
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default WithAuth(IVRViewComponent);
