import React from 'react'
import mapImage from "../assets/images/worldmapnetwork.svg"
import styles from "../styles/Thankyou.module.scss"
const ThankYouScreen = () => {
    return (
        <div className={styles.thankyouscreen}>
          <img src={mapImage} alt="World Map" className={styles.mapimage} />
          <h1>Thank you for Connecting with Bank of Asia!</h1>
          <p>
            We truly appreciate your insights and participation. Looking forward to connecting with you soon!
          </p>
        </div>
      );
}

export default ThankYouScreen;