import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/transactionControl.scss";
const TransactionType = () => {
    const transactionControlHeader = [
        {
            title: "Transaction Type",
            label: "Tran. Type"
        },
        {
            title: "Code",
            label: "Code"
        },
        {
            title: "Number of daily transaction",
            label: "No. of Daily Tran."
        },
        {
            title: "Daily transaction amount",
            label: "Daily Tran. Amo..."
        },
        {
            title: "Number of monthly transaction",
            label: "No. of monthly Tran."
        },
        {
            title: "Monthly transaction amount",
            label: "Monthly Tran. Amo..."
        },
        {
            title: "Max transaction amount",
            label: "Max Tran. Amo..."
        },
        {
            title: "Request form",
            label: "Request form"
        },
        {
            title: "Branch/Point Name",
            label: "Branch/Point Na..."
        },
        {
            title: "Remarks",
            label: "Remarks"
        },
    ];
    const transactionControlData = [
        {
            type: "Cash Transaction",
            code: "Credit",
            dailyTran: "3",
            dailyTranAmt: "500000",
            monthlyTran: "20",
            monthlyTranAmt: "2000000",
            maxTranAmt: "5000000",
        },
        {
            type: "Cash Transaction",
            code: "Debit",
            dailyTran: "2",
            dailyTranAmt: "500000",
            monthlyTran: "20",
            monthlyTranAmt: "1000000",
            maxTranAmt: "5000000",
        },
        {
            type: "Transfer Transaction",
            code: "Debit",
            dailyTran: "2",
            dailyTranAmt: "250000",
            monthlyTran: "20",
            monthlyTranAmt: "1000000",
            maxTranAmt: "1500000"
        },
    ]
    const steps = ["Acount Details", "Transaction Control"];
    const isMobileDevice = window.screen.width <= 900;
    return (
        <div className={`flex dark:bg-dark-background updatePhone`}>
            {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
            <SideMenu />
            <div className="min-h-screen flex-1 overflow-hidden">
                <Header />
                <div className={`z-0 mainContainer transactionControl transactionFixedHeightContainer ${isMobileDevice ? 'p-4' : 'p-10'}`}>
                    <Breadcrumb
                        steps={steps}
                        activeStep={1}
                        showBackButton={true}
                        redirectUrl={"/ConventionalAgentBankingCard"}
                    />
                    <div class="grid grid-cols-3 gap-4 mt-2">
                        <div className="transactionDetailContainer col-span-3  rounded p-4 cardBackground">
                            <div className="flex justify-between">
                                <p className="transactionProfileText">Transaction Type & Nature</p>
                                <div>
                                    <button type="button" className="transactionClearButton rounded">Send for Approval</button>
                                    {/* <button type="button" className="transactionClearButton rounded ms-2">Clear</button> */}
                                </div>
                            </div>
                            <div className={`p-2 accoutHolderDetails mt-4 ${isMobileDevice ? 'ps-0' : 'ps-10 '}`}>
                                <div className={`flex ${isMobileDevice ? 'flex-col' : ''}`}>
                                    <div className={`${isMobileDevice ? 'w-full' : 'w-1/2'}`}>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-40 title'}`}>
                                                <p className="block text-sm font-medium leading-6 text-gray-900">Transaction Type</p>
                                            </div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}>
                                                <select className={`dropdownBackground block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}>
                                                    <option value="">Account Transfer</option>
                                                    <option value="1">Type 1</option>
                                                    <option value="2">Type 2</option>
                                                    <option value="3">Type 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-40 title'}`}>
                                                <p className="block text-sm font-medium leading-6 text-gray-900">Transaction Entry Type</p>
                                            </div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}>
                                                <select className={`dropdownBackground block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}>
                                                    <option value="">New Entry</option>
                                                    <option value="debit">Debit</option>
                                                    <option value="credit">Credit</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-40 title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Transaction No.</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="number" value={'50000'} className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}></input></div>
                                        </div>
                                    </div>

                                    <div className={`${isMobileDevice ? 'w-full' : 'w-1/2'}`}>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-40 title'}`}>
                                                <p className="block text-sm font-medium leading-6 text-gray-900">Transaction Nature</p>
                                            </div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}>
                                                <select className={`dropdownBackground block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}>
                                                    <option value="">Party Account to Party Account</option>
                                                    <option value="debit">Debit</option>
                                                    <option value="credit">Credit</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="transactionDetailContainer col-span-3  rounded p-4 cardBackground">
                            <div className="flex justify-between">
                                <p className="transactionProfileText">Transaction Detail Information</p>
                                <div>
                                    {/* <button type="button" className="transactionClearButton rounded">Clear</button> */}
                                    <button type="button" className="transactionClearButton rounded ms-2">Add List</button>
                                </div>
                            </div>
                            <div className={`p-2 accoutHolderDetails mt-4 ${isMobileDevice ? 'ps-0' : 'ps-10 '}`}>
                                <div className={`flex ${isMobileDevice ? 'flex-col' : ''}`}>
                                    <div className={`${isMobileDevice ? 'w-full' : 'w-1/2'}`}>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Debit/Credit</p></div>
                                            <div className={`${isMobileDevice ? 'w-full mt-1' : 'ms-7 w-2/4'}`}>
                                                <label>
                                                    <input type="radio" name="paymentType" value="debit" className="me-1" />
                                                    Debit
                                                </label>
                                                <label className="ms-5">
                                                    <input type="radio" name="paymentType" value="credit" className="me-1" />
                                                    Credit
                                                </label>
                                            </div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Branch Code</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="number" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}></input></div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Account Title</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="text" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}></input></div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Amount</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="number" value={'50000'} className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'ms-0 mt-1' : 'ms-2'}`}></input></div>
                                        </div>
                                    </div>

                                    <div className={`${isMobileDevice ? 'w-full' : 'w-1/2'}`}>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-36 title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Account Type</p></div>
                                            <div className={`${isMobileDevice ? 'w-full mt-1' : 'ms-6 w-2/4'}`}>
                                                <label>
                                                    <input type="radio" name="accountType" value="Party Account" className="me-1" />
                                                    Party Account
                                                </label>
                                                <label className="ms-5">
                                                    <input type="radio" name="accountType" value="GL Account" className="me-1" />
                                                    GL Account
                                                </label>
                                            </div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-36 title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Account No.</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="number" value={'007654298991'} className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'mt-1' : 'ms-2 '}`}></input></div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-36 title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Operating Balance</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="text" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'mt-1' : 'ms-2 '}`}></input></div>
                                        </div>
                                        <div className={`flex ${isMobileDevice ? 'w-full flex-col mt-4' : 'p-2'}`}>
                                            <div className={` ${isMobileDevice ? 'w-full' : 'w-36 title'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Narration</p></div>
                                            <div className={`${isMobileDevice ? 'w-full' : 'ms-4 w-2/4'}`}><input type="text" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none ps-3 ${isMobileDevice ? 'mt-1' : 'ms-2 '}`}></input></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="transactionDetailContainer col-span-3  rounded p-4 cardBackground">
                            <div className="flex justify-between">
                                <p className="transactionProfileText">Total Debit Transaction</p>
                            </div>
                            <div className="p-2 ps-10 accoutHolderDetails mt-4">
                                <div className="flex font-semibold">
                                    {/* <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Account Number :</p></div>
                        <div className=" w-2/4"><input type="number" value={1083444043425} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"></input></div> */}
                                    No Data Found
                                </div>
                                {/* <div className="flex mt-5">
                        <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Account Title :</p></div>
                        <div className=" w-2/4"><p className="ms-2">Md. Abu Salah</p></div>
                    </div>
                    <div className="flex mt-5">
                        <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Mobile No. :</p></div>
                        <div className=" w-2/4"><p className="ms-2">01735231941</p></div>
                    </div> */}
                            </div>
                        </div>

                        <div className="transactionDetailContainer col-span-3  rounded p-4 cardBackground">
                            <div className="flex justify-between">
                                <p className="transactionProfileText">Total Credit Transaction</p>
                            </div>
                            <div className="p-2 ps-10 accoutHolderDetails mt-4">
                                <div className="flex font-semibold">
                                    {/* <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Account Number :</p></div>
                        <div className=" w-2/4"><input type="number" value={1083444043425} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"></input></div> */}
                                    No Data Found
                                </div>
                                {/* <div className="flex mt-5">
                        <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Account Title :</p></div>
                        <div className=" w-2/4"><p className="ms-2">Md. Abu Salah</p></div>
                    </div>
                    <div className="flex mt-5">
                        <div className=" w-1/5 title"><p className="block text-sm font-medium leading-6 text-gray-900">Mobile No. :</p></div>
                        <div className=" w-2/4"><p className="ms-2">01735231941</p></div>
                    </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionType;
