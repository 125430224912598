// import {data} from '../dummyData'
import processIcon from "../assets/images/process.svg";
import samdIcon from "../assets/images/samdIcon.svg";
import servicesIcon from "../assets/images/services.svg";
import userIcon from "../assets/images/userIcon.svg";
import clientIcon from "../assets/images/clientIcon.svg";
import adminIcon from "../assets/images/adminIcon.svg";
import agentBankingDpmtIcon from "../assets/images/agent-bank.svg";
import accDpmtIcon from "../assets/images/acc-department.svg";
import systemErrorIcon from "../assets/images/sys-error.svg";
import auditLogsIcon from "../assets/images/audit-log.svg";
import vaultIcon from "../assets/images/vault.svg";
import supportIcon from "../assets/images/support.svg";
import taskIcon from "../assets/images/taskIcon.svg";

export const Menus = [
  {
    title: "My Task",
    process_id: [15],
    src: taskIcon,
    navlink: "/my-task",
    submenu: [
      {
        navlink: '/my-task',
        process_id: [15]
      }
    ],
    showChild: false,
    children: [],
  },
  {
    title: "Processes",
    process_id: [1, 2, 3, 4, 5],
    src: processIcon,
    navlink: "/",
    submenu: [
      {
        navlink: '/accountProcess',
        process_id: [1, 2, 3, 4, 5]
      },
      {
        navlink: '/dashboard',
        process_id: [1, 2, 3, 4, 5]
      },
      {
        navlink: '/',
        process_id: [1, 2]
      },
      {
        navlink: '/regularAccount',
        process_id: [1]
      },
      {
        navlink: '/minorAccount',
        process_id: [1]
      },
      {
        navlink: '/updatePhoneNumber',
        process_id: [2]
      },
      {
        navlink: '/viewRequest',
        process_id: [2]
      },
      {
        navlink: '/accountsDepartment',
        process_id: [3]
      },
      {
        navlink: '/accountsDepartment/reconciliation',
        process_id: [3]
      },
      {
        navlink: '/samdDepartment',
        process_id: [4, 5]
      },
      {
        navlink: '/createPortfolio',
        process_id: [4]
      },
      {
        navlink: '/writtenOfPortfolio',
        process_id: [4]
      },
      {
        navlink: '/viewPortfolio',
        process_id: [4]
      },
      {
        navlink: '/LoanStorage',
        process_id: [5]
      },
      {
        navlink: '/ViewFolderDetails',
        process_id: [5]
      },
      {
        navlink: '/InformationUpdateDashboard',
        process_id: [17]
      },
      {
        navlink: '/AccountUpdateDetails',
        process_id: [17]
      },
      {
        navlink: '/nomineeDetails',
        process_id: [17]
      },
      {
        navlink: '/nomineeQuickLinks',
        process_id: [17]
      },
      {
        navlink: '/RaiseRequestApproval',
        process_id: [17]
      },
      {
        navlink: '/ConventionalAgentBankingCard',
        process_id: [17]
      },
      {
        navlink: '/RaiseAccountRequest',
        process_id: [17]
      },
      {
        navlink: '/transactionControl',
        process_id: [17]
      },
      {
        navlink: '/transactionType',
        process_id: [17]
      },
      {
        navlink: '/SearchAccount',
        process_id: [17]
      },
      {
        navlink: '/InformationUpdate',
        process_id: [17]
      },
      {
        navlink: '/AccountTitleChange',
        process_id: [17]
      },
      {
        navlink: '/changeLimitUpdate',
        process_id: [17]
      },
      {
        navlink: '/changeSignatureCard',
        process_id: [17]
      },
      {
        navlink: '/changeProfile',
        process_id: [17]
      },
      {
        navlink: '/changePersonalInfo',
        process_id: [17]
      },
      {
        navlink: '/changeAccountDetails',
        process_id: [17]
      },
      {
        navlink: '/nomineeDetailsPage',
        process_id: [17]
      },
      {
        navlink: '/consumerFinance',
        process_id: [16]
      },
      {
        navlink: '/editConsumer',
        process_id: [16]
      },
    ],
    showChild: false,
    children: [
      {
        title: "Agent Banking Department",
        process_id: [1, 2],
        src: agentBankingDpmtIcon,
        navlink: "/",
        submenu: [
          {
            navlink: '/',
            process_id: [1, 2]
          },
          {
            navlink: '/regularAccount',
            process_id: [1]
          },
          {
            navlink: '/minorAccount',
            process_id: [1]
          },
          {
            navlink: '/updatePhoneNumber',
            process_id: [2]
          },
          {
            navlink: '/viewRequest',
            process_id: [2]
          },
          
        ]
      },
      {
        title: "Accounts Department",
        process_id: [3],
        src: accDpmtIcon,
        navlink: "/accountsDepartment",
        submenu: [
          {
            navlink: '/accountsDepartment',
            process_id: [3]
          },
          {
            navlink: '/accountsDepartment/reconciliation',
            process_id: [3]
          }
        ]
      },
      {
        title: "SAMD Department",
        process_id: [4, 5],
        src: samdIcon,
        navlink: "/samdDepartment",
        submenu: [
          {
            navlink: '/samdDepartment',
            process_id: [4, 5]
          },
          {
            navlink: '/createPortfolio',
            process_id: [4]
          },
          {
            navlink: '/writtenOfPortfolio',
            process_id: [4]
          },
          {
            navlink: '/viewPortfolio',
            process_id: [4]
          },
          {
            navlink: '/LoanStorage',
            process_id: [5]
          },
          {
            navlink: '/ViewFolderDetails',
            process_id: [5]
          }
        ]
      },
      {
        title: "Consumer Finance",
        process_id: [16],
        src: samdIcon,
        navlink: "/consumerFinance",
        submenu: [
          {
            navlink: '/consumerFinance',
            process_id: [16]
          },
          {
            navlink: '/editConsumer',
            process_id: [16]
          }
        ]
      },
      {
        title: "Information Update Process",
        process_id: [17],
        src: userIcon,
        navlink: "/InformationUpdateDashboard",
        submenu: [
          {
            navlink: '/InformationUpdateDashboard',
            process_id: [17]
          },
          {
            navlink: '/AccountUpdateDetails',
            process_id: [17]
          },
          {
            navlink: '/nomineeDetails',
            process_id: [17]
          },
          {
            navlink: '/nomineeQuickLinks',
            process_id: [17]
          },
          {
            navlink: '/RaiseRequestApproval',
            process_id: [17]
          },
          {
            navlink: '/ConventionalAgentBankingCard',
            process_id: [17]
          },
          {
            navlink: '/RaiseAccountRequest',
            process_id: [17]
          },
          {
            navlink: '/transactionControl',
            process_id: [17]
          },
          {
            navlink: '/transactionType',
            process_id: [17]
          },
          {
            navlink: '/SearchAccount',
            process_id: [17]
          },
          {
            navlink: '/InformationUpdate',
            process_id: [17]
          },
          {
            navlink: '/AccountTitleChange',
            process_id: [17]
          },
          {
            navlink: '/changeLimitUpdate',
            process_id: [17]
          },
          {
            navlink: '/changeSignatureCard',
            process_id: [17]
          },
          {
            navlink: '/changeProfile',
            process_id: [17]
          },
          {
            navlink: '/changePersonalInfo',
            process_id: [17]
          },
          {
            navlink: '/changeAccountDetails',
            process_id: [17]
          },
          {
            navlink: '/nomineeDetailsPage',
            process_id: [17]
          },
        ]
      }
    ]
  },
  {
    title: "Services",
    process_id: [8, 18],
    src: servicesIcon,
    navlink: "/services",
    submenu: [
      {
        navlink: '/videokyclist',
        process_id: [7]
      },
      {
        navlink: '/viewKYCRequest',
        process_id: [7]
      },
      {
        navlink: '/services',
        process_id: [8]
      },
      {
        navlink: '/updateIvrTable',
        process_id: [8]
      },
      {
        navlink: '/callingList',
        process_id: [8]
      },
      {
        navlink: '/ivrView',
        process_id: [8]
      },
      {
        navlink: '/initiateIVR',
        process_id: [8]
      },
      {
        navlink: '/IVRWorkflow',
        process_id: [8]
      },
      {
        navlink: '/callingDetails',
        process_id: [8]
      },
      {
        navlink: '/phoneCallDetails',
        process_id: [8]
      },
      {
        navlink: '/room',
        process_id: [18]
      },
      {
        navlink: '/CallInQueues',
        process_id: [18]
      },
      {
        navlink: '/humankyclist',
        process_id: [18]
      },
      {
        navlink: '/goLive',
        process_id: [18]
      },
    ],
    showChild: false,
    children: [],
  },
  {
    title: "Admin",
    process_id: [9, 10, 11, 12, 14],
    src: adminIcon,
    navlink: "/admin-mngment",
    submenu: [
      {
        navlink: '/admin-mngment',
        process_id: [9, 10, 11, 12, 14]
      },
      {
        navlink: '/userManagement',
        process_id: [9]
      },
      {
        navlink: '/UserEdit',
        process_id: [9]
      },
      {
        navlink: '/admin',
        process_id: [10]
      },
      {
        navlink: '/Audit',
        process_id: [11]
      },
      {
        navlink: '/UserConfig',
        process_id: [12]
      },
      {
        navlink: '/queueConfiguration',
        process_id: [14]
      },
      {
        navlink: '/queueConfigurationS02',
        process_id: [14]
      },
      {
        navlink: '/queueConfigurationS07',
        process_id: [14]
      },
      {
        navlink: '/queueConfigurationCommon',
        process_id: [14]
      },
      {
        navlink:'/logs',
        process_id:[14]
      },
      {
        navlink: '/logoAndTheme',
        process_id: [9]
      },
      {
        navlink: '/loanConfig',
        process_id: [9]
      },
      {
        navlink: '/createLoanConfig',
        process_id: [9]
      },

    ],
    showChild: false,
    children: [
      {
        title: "User Management",
        process_id: [9],
        src: userIcon,
        navlink: "/userManagement",
        submenu: [
          {
            navlink: '/UserEdit',
            process_id: [9]
          }
        ]
      },
      {
        title: "Error Logs",
        process_id: [10],
        src: systemErrorIcon,
        navlink: "/admin",
        submenu: [
          {
            navlink: '/admin',
            process_id: [10]
          }
        ]
      },
      {
        title: "Audit Trail",
        process_id: [11],
        src: auditLogsIcon,
        navlink: "/Audit",
        submenu: [
          {
            navlink: '/Audit',
            process_id: [11]
          }
        ]
      },
      {
        title: "Live Update",
        process_id: [14],
        src: auditLogsIcon,
        navlink: "/logs",
        submenu: [
          {
            navlink: '/logs',
            process_id: [14]
          }
        ]
      },
      {
        title: "Vault",
        process_id: [12],
        src: vaultIcon,
        navlink: "/UserConfig",
        submenu: [
          {
            navlink: '/UserConfig',
            process_id: [12]
          }
        ]
      },
      {
        title: "Configuration",
        process_id: [14],
        src: processIcon,
        navlink: "/queueConfiguration",
        submenu: [
          {
            navlink: '/queueConfiguration',
            process_id: [14]
          },
          {
            navlink: '/queueConfigurationS02',
            process_id: [14]
          },
          {
            navlink: '/queueConfigurationS07',
            process_id: [14]
          },
          {
            navlink: '/queueConfigurationCommon',
            process_id: [14]
          }
        ]
      },
      {
        title: "Logo & Theme",
        process_id: [9],
        src: processIcon,
        navlink: "/logoAndTheme",
        submenu: [
          {
            navlink: '/logoAndTheme',
            process_id: [9]
          }
        ]
      },
      // {
      //   title: "CRM",
      //   process_id: [9],
      //   src: processIcon,
      //   navlink: "/CRM",
      //   submenu: [
      //     {
      //       navlink: '/CRMLoanAccountDetails',
      //       process_id: [9]
      //     },
      //     {
      //       navlink: '/loanAccountsCardBoard',
      //       process_id: [9]
      //     }
      //   ],
      //   showChild:false,
      //   children: [
      //     {
      //       title: "CRM Loan Account",
      //       process_id: [9],
      //       src: userIcon,
      //       navlink: "/CRMLoanAccountDetails",
      //       submenu: [
      //         {
      //           navlink: '/CRMLoanAccountDetails',
      //           process_id: [9]
      //         }
      //       ]
      //     },
      //     {
      //       title: "CRM Dashboard",
      //       process_id: [9],
      //       src: userIcon,
      //       navlink: "/loanAccountsCardBoard",
      //       submenu: [
      //         {
      //           navlink: '/loanAccountsCardBoard',
      //           process_id: [9]
      //         }
      //       ]
      //     },
      //   ]
      // },
      // {
      //   title: "Loan Configuration",
      //   process_id: [9],
      //   src: processIcon,
      //   navlink: "/loanConfig",
      //   submenu: [
      //     {
      //       navlink: '/loanConfig',
      //       process_id: [9]
      //     },
      //     {
      //       navlink: '/createLoanConfig',
      //       process_id: [9]
      //     }
      //   ]
      // },
    ]
  },
  // {
  //   title: "CRM",
  //   process_id: [13],
  //   src: supportIcon,
  //   navlink: "/CRM",
  //   submenu: [
  //     {
  //       navlink: '/loanAccountsCardBoard',
  //       process_id: [13]
  //     },
  //     {
  //       navlink: '/CRMLoanAccountDetails',
  //       process_id: [13]
  //     }
  //   ],
  //   showChild: false,
  //   children: [
  //     {
  //       title: "Loan Accounts Cardboard",
  //       process_id: [13],
  //       src: userIcon,
  //       navlink: "/loanAccountsCardBoard",
  //       submenu: [
  //         {
  //           navlink: '/loanAccountsCardBoard',
  //           process_id: [13]
  //         },
  //         {
  //           navlink:'/CRMLoanAccountDetails',
  //           process_id: [13]
  //         }
  //       ]
  //     },
  //     {
  //       title: "CRM Dashboard",
  //       process_id: [10],
  //       src: systemErrorIcon,
  //       navlink: "/CRMdashboard",
  //       submenu: [
  //         {
  //           navlink: '/admin',
  //           process_id: [13]
  //         }
  //       ]
  //     },
  //   ]
  // },
  {
    title: "Support",
    process_id: [13],
    src: supportIcon,
    navlink: "/Contact",
    submenu: [
      {
        navlink: '/Contact',
        process_id: [13]
      }
    ],
    showChild: false,
    children: []
  },
  // { 
  //   title: "Settings", 
  //   process_id: [14], 
  //   src: userIcon, 
  //   navlink: "/userManagement", 
  //   submenu: ['/UserEdit']
  // }
];

export const getCurrentUserMenus = () => {
  const permArray = localStorage.perm ? JSON.parse(localStorage.perm) : []
  let finalItems = []
  Menus.map(menu => {
    if (menu?.children?.length == 0) {
      if (permArray?.some(permission => menu.process_id?.includes(permission?.process_id) && permission.read)) {
        finalItems?.push(menu);
      }
    }
    if (menu?.children?.length > 0) {
      let finalChildItem = [];
      if (permArray?.some(permission => menu.process_id?.includes(permission?.process_id) && permission.read)) {
        menu?.children?.map(chldItem => {
          if (permArray?.some(permission => chldItem.process_id?.includes(permission?.process_id) && permission.read)) {
            finalChildItem?.push(chldItem);
          }
        })
        menu.children = finalChildItem;
        finalItems?.push(menu);
      }
    }

  }
  );
  return finalItems;
};