import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/detailsPagee.scss";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../common/Navbar";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import API from "../../helpers/api";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import { ColorRing } from "react-loader-spinner";
import ChangePhotographModal from "../UpdateAccountModals/ChangePhotographModal";
import ChangePersonalInfoModal from "../UpdateAccountModals/ChangePersonalInfoModal";
const DetailsPagee = () => {
    const steps = ["Acount Details", "Nominee Details"];
    const navigate = useNavigate()

    const [ivrConfigCalling, setIvrConfigCalling] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = ivrConfigCalling?.slice(indexOfFirstRecord, indexOfLastRecord);
    let nPages = ivrConfigCalling?.length ? Math.ceil(ivrConfigCalling?.length / recordsPerPage) : 0;
    const [dataLoading, setDataLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [raiseRequestModal, setRaiseRequestModal] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const [requestedPage, setRequestedPage] = useState("");
    const [requestedPurpose, setRequestedPurpose] = useState("");
    const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
    const [progress1Loading, setProgress1Loading] = useState('noprogress');
    const [progress2Loading, setProgress2Loading] = useState('noprogress');
    const [data, setData] = useState(0);
    const [profileData, setProfileData] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [AccountDetails, setAccountDetails] = useState();
    const isMobileDevice = window.screen.width <= 900;
    const [fileData, setFileData] = useState({
        profileDocument: "",
        formDocument: "",
        supportDocument: "",
    });

    const [raiseRequestvalues, setRaiseRequestValues] = useState({
        bank_account_number: "",
        bank_requestPurpose: "",
        bank_accountHolderTitle: "",
        bank_requestType: "",
        bank_remarks: "",
        bank_previousValue: "",
        bank_changeValue: "",
        transaction_day: "",
        amount_day: "",
        transaction_month: "",
        amount_month: "",
        maximum_transaction: "",
        nominee_name: "",
        father_name: "",
        mother_name: "",
        nominee_dob: "",
        nominee_relation: "",
        nominee_percent: "",
        doc_type: "",
        document_nmbr: "",
        nominee_img: "",
        document_front_img: "",
        doc_back_img: "",
        account_title: "",
        personal_dob: "",
        marital_status: "",
        religion: "",
        gender: "",
        spouse_name: "",
        occupation: "",
        email: "",
        sector_code: "",
        dob: "",
        nid_number: "",
    });

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
    };

    const openSearchAccount = () => {
        setOpenAccountModal(true);
    }

    const raiseRequestUpdate = () => {
        setRaiseRequestModal(true);
        setRequestedPage("");
    }

    // console.log('data 3333', data)

    const raiserequestdata = [
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        }
    ]

    const filteredData = ivrConfigCalling?.filter(item =>
        (item?.id && item?.id?.toString()?.includes(searchQuery)) ||
        (item?.ivr_workflow_name && item?.ivr_workflow_name?.toLowerCase().toString()?.includes(searchQuery)) ||
        (item?.total_calls && item?.total_calls?.toString()?.includes(searchQuery)) ||
        (item?.uploaded_by && item?.uploaded_by?.toLowerCase()?.includes(searchQuery)) ||
        (item?.created_at && item?.created_at?.includes(searchQuery))
    );
    currentRecords = filteredData?.slice(indexOfFirstRecord, indexOfLastRecord);
    nPages = filteredData?.length ? Math.ceil(filteredData?.length / recordsPerPage) : 0;

    useEffect(() => {
        getRaiseRequestDetails();
        // getAccountDetails();
        setDataLoading(true);
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const pathname = url.pathname;
        const parts = pathname.split('/');
        const desiredPart = parts[parts.length - 1];
        setRequestedPage(desiredPart)
    }, [window.location.href]);


    const getRaiseRequestDetails = async () => {
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request`);
            setIvrConfigCalling(raiserequestdata);
            if (data?.success) {
                // setIvrConfigCalling(raiserequestdata);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                setIvrConfigCalling(raiserequestdata);
                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            setIvrConfigCalling(raiserequestdata);
            toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };


    const handleRaise = async (event) => {
        SetRequestSubmitLoader(true)
        let request_title = ''
        if (requestedPage == 'changeAccountDetails') {
            request_title = 'account_info'
        } else if (requestedPage == 'changeLimitUpdate') {
            request_title = 'transaction_limit'
        } else if (requestedPage == 'changeSignatureCard') {
            request_title = 'account_info'
        } else if (requestedPage == 'account_info') {
            request_title = 'account_info'
        } else if (requestedPage == 'changePersonalInfo') {
            request_title = 'personal_info'
        } else if (requestedPage == 'nomineeDetailsPage') {
            request_title = 'nominee_info'
        }
        if (raiseRequest()) {
            const formData = new FormData();
            let payload = {
                request_title: request_title,
                agent_banking_id: AccountDetails?.id,
                account_no: raiseRequestvalues?.bank_account_number,
                request_purpose: raiseRequestvalues.bank_requestPurpose || requestedPage,
                previous_value: raiseRequestvalues.bank_previousValue || AccountDetails.customer_photo_path || AccountDetails.signature_photo_path,
                nid_number: raiseRequestvalues.nid_number,
                dob: raiseRequestvalues.dob,
                request_update_value: raiseRequestvalues.bank_changeValue,
                bank_accountHolderTitle: raiseRequestvalues.bank_accountHolderTitle
            }
            Object.entries(raiseRequestvalues).forEach(([key, value]) => {
                if (value !== "") {
                    // formData.append(key, value);
                }
            });

            Object.entries(fileData).forEach(([key, value]) => {
                if (value !== "") {
                    formData.append(key, value);
                }
            });
            const encodedPayload = encodeData(payload);
            formData.append('metadata', encodedPayload)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/v1/agentBanking/raise/request`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                response = await response.json();
                if (response?.success) {
                    toast.success(response?.message);
                    closeModal();
                    //   getPhoneUpdateDetails();
                    SetRequestSubmitLoader(false);
                } else {
                    SetRequestSubmitLoader(false);
                    toast.error(response?.error);
                }
            } catch (error) {
                SetRequestSubmitLoader(false);
                handleErrorMessage(error);
            }
        }
        SetRequestSubmitLoader(false);
    };

    const getAccountDetails = async () => {
        const account_no = raiseRequestvalues?.bank_account_number;
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
            if (data?.success) {
                setAccountDetails(data?.data);
                setRaiseRequestValues(prevState => ({
                    ...prevState,
                    dob: data?.data?.personal_info?.DOB,
                    bank_accountHolderTitle: data?.data?.personal_info?.first_name + " " + data?.data?.personal_info?.last_name
                }));
                toast.success(data?.message);
                setDataLoading(false)
            } else {

                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };
    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const handleSearchAccount = () => {
        let errors = {};
        let flag = true;
        if (!accountNumber) {
            errors.accountNumber = "Account Number is required";
            flag = false;
        }
        setFormErrors(errors);
        if (flag) {
            getAccountDetails();
        }
    }

    const handleRequestValues = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;
        setRaiseRequestValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        let previous_value = ''
        if (name == 'bank_requestPurpose') {
            if (requestedPage === "nomineeDetailsPage") {
                if (value == 'Change Nominee Name') {
                    previous_value = AccountDetails?.nominee_info?.nominee_name
                } else if (value == 'Change Nominee Relation') {
                    previous_value = AccountDetails?.nominee_info?.relation
                } else if (value == 'Change Nominee Percent') {
                    previous_value = AccountDetails?.nominee_info?.nominee_percent
                }
                else if (value == 'Change Dob') {
                    previous_value = AccountDetails?.nominee_info?.dob
                }
                else if (value == 'Change Nominee Father Name') {
                    previous_value = AccountDetails?.nominee_info?.father_name
                }
                else if (value == 'Change Nominee Mother Name') {
                    previous_value = AccountDetails?.nominee_info?.mother_name
                }
                else if (value == 'Change Nominee Document Type') {
                    previous_value = AccountDetails?.nominee_info?.document_typy
                }
                else if (value == 'Change Nominee Document No') {
                    previous_value = AccountDetails?.nominee_info?.document_no
                }
            }
            setRaiseRequestValues((prevState) => ({
                ...prevState,
                bank_previousValue: previous_value,
            }));
        }

    }
    const raiseRequest = () => {
        let errors = {};
        if (!raiseRequestvalues?.bank_account_number) {
            errors.bank_account_number = "Account Number is required";
        }
        if (!raiseRequestvalues?.bank_requestPurpose) {
            errors.bank_requestPurpose = "Request Purpose is required";
        }
        if (!raiseRequestvalues?.bank_requestType) {
            errors.bank_requestType = "Request Type is required";
        }
        if (!raiseRequestvalues?.bank_remarks) {
            errors.bank_remarks = "Remarks are required";
        }
        if (!raiseRequestvalues?.bank_previousValue) {
            errors.bank_previousValue = "Previous Value is required";
        }
        if (!raiseRequestvalues?.bank_changeValue) {
            errors.bank_changeValue = "Change Value is required";
        }
        if (!raiseRequestvalues?.nid_number) {
            errors.nid_number = "NID Number is required";
        }
        if (!raiseRequestvalues?.dob) {
            errors.dob = "Dob is required";
        }
        if (requestedPage === "changeLimitUpdate") {
            if (requestedPurpose === "Change Transaction Day") {
                if (!raiseRequestvalues?.transaction_day) {
                    errors.transaction_day = "Transaction/day is required";
                }
            }
            if (requestedPurpose === "Change Amount Day") {
                if (!raiseRequestvalues?.amount_day) {
                    errors.amount_day = "Amount/day is required";
                }
            }
            if (requestedPurpose === "Change Transaction Month") {
                if (!raiseRequestvalues?.transaction_month) {
                    errors.transaction_month = "Transaction/month is required";
                }
            }
            if (requestedPurpose === "Change Amount Month") {
                if (!raiseRequestvalues?.amount_month) {
                    errors.amount_month = "Amount/month is required";
                }
            }
            if (requestedPurpose === "Change Maximum Transaction") {
                if (!raiseRequestvalues?.maximum_transaction) {
                    errors.maximum_transaction = "Maximum transaction is required";
                }
            }
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const closeModal = () => {
        setOpenAccountModal(false);
        setFormErrors({});
    };

    const closerequestModal = () => {
        setRaiseRequestModal(false);
        setFormErrors({});
        setRaiseRequestValues({
            bank_account_number: "",
            bank_requestPurpose: "",
            bank_accountHolderTitle: "",
            bank_requestType: "",
            bank_remarks: "",
            bank_previousValue: "",
            bank_changeValue: "",
            transaction_day: "",
            amount_day: "",
            transaction_month: "",
            amount_month: "",
            maximum_transaction: "",
            nominee_name: "",
            father_name: "",
            mother_name: "",
            nominee_dob: "",
            nominee_relation: "",
            nominee_percent: "",
            doc_type: "",
            document_nmbr: "",
            nominee_img: "",
            document_front_img: "",
            doc_back_img: "",
            account_title: "",
            personal_dob: "",
            marital_status: "",
            religion: "",
            gender: "",
            spouse_name: "",
            occupation: "",
            email: "",
            sector_code: ""
        })
    };
    // const steps = ["account details"];

    const setStatus = (status) => {
        const item = status?.split("-");
        const capitalizedParts = item?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1));
        return capitalizedParts?.join("-");
    };
    return (
        <>
            <div className={`flex dark:bg-dark-background updateIvrContainer`}>
                <SideMenu />
                <div className="flex-1 overflow-hidden">
                    <Header />
                    <Navbar></Navbar>
                    {dataLoading && <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>}
                    <div className="">
                        {/* <div className="flex justify-between"> */}
                        <div className="flex">
                        </div>
                        <div className="flex flex-row justify-between userHeading">
                            {/* <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 userManagementHeading">
                                Account Details
                            </h1> */}
                            <div className="flex">
                            </div>
                        </div>

                        <div className={`transactionFixedHeightContainer ${isMobileDevice ? 'p-4' : 'p-10'}`} style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                            <Breadcrumb
                                // steps={steps}
                                activeStep={1}
                                showBackButton={true}
                                redirectUrl={"/ConventionalAgentBankingCard"}
                            />
                            <div className="rounded-lg bg-white border p-5 mt-3 userDetailsUpdatePagee">
                                <div className='ps-3 pe-3 pt-1'>
                                    <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Raise Request</p>
                                </div>
                                <div className=''>
                                    <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                        {/* <div className="rounded border p-2 w-32 flex justify-center items-center mb-2">
                                                        <div className="rounded">
                                                            <img src="assets/images/businessman.png" width={100} height={100} className="userCapturedImage"></img>
                                                        </div>
                                                    </div> */}
                                        <div className="flex flex-wrap">
                                            <div className={`mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2 '}`}>
                                                <label htmlFor="requested_page" className='numberRequestModal mt-3'>Requested Page*</label>
                                                <select
                                                    id="requested_page"
                                                    name="requested_page"
                                                    value={requestedPage}
                                                    className={`py-2 rounded outline-none ps-2 border w-full`}
                                                    style={{ pointerEvents: 'none', appearance: 'none' }} // Adding inline styles
                                                    onChange={(e) => { }} // Disabling onChange event
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="changeLimitUpdate">Change Limit Update</option>
                                                    <option value="changeSignatureCard">Change Signature Card</option>
                                                    <option value="changeProfile">Change Photograph</option>
                                                    <option value="nomineeDetailsPage">Change Nominee Information</option>
                                                    <option value="changePersonalInfo">Change Personal Information</option>
                                                    <option value="changeAccountDetails">Change Account Details</option>
                                                </select>

                                            </div>
                                            <div className={`flex flex-col ${isMobileDevice ? 'w-full mb-2' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_requestType" className="numberRequestModal">Request Type*</label>
                                                <select
                                                    id="requestType"
                                                    name="bank_requestType"
                                                    value={raiseRequestvalues?.bank_requestType}
                                                    onChange={(e) => handleRequestValues(e)}
                                                    className={`py-2 rounded outline-none ps-2 border w-full`}
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="add">Add</option>
                                                    <option value="change">Change</option>
                                                </select>
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_requestType ? 'red' : 'inherit' }}>{formErrors?.bank_requestType}</span>
                                            </div>
                                            <div className={`flex flex-col ${isMobileDevice ? 'w-full mb-2' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_remarks" className="numberRequestModal">Request Purpose*</label>
                                                <select
                                                    id="requested_purpose"
                                                    name="bank_requestPurpose"
                                                    value={raiseRequest?.bank_requestPurpose}
                                                    onChange={handleRequestValues}
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="Change Nominee Name">Change Nominee Name</option>
                                                    {/* <option value="ChangeDocumentNo">Change Document No</option> */}
                                                    <option value="Change Nominee Percent">Change Nominee Percent</option>
                                                    <option value="Change Nominee Father Name">Change Nominee Father Name</option>
                                                    <option value="Change Nominee Mother Name">Change Nominee Mother Name</option>
                                                    <option value="Change Nominee Document No">Change Nominee Document No</option>
                                                    <option value="Change Nominee Document Type">Change Nominee Document Type</option>
                                                    <option value="Change Nominee Relation">Change Relation</option>
                                                    <option value="Change Dob">Change DOB</option>
                                                </select>
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_requestPurpose ? 'red' : 'inherit' }}>{formErrors?.bank_requestPurpose}</span>
                                            </div>
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_account_number" className="numberRequestModal">Account Number*</label>
                                                <div className="flex">
                                                    <input
                                                        type="text"
                                                        name="bank_account_number"
                                                        value={raiseRequestvalues?.bank_account_number}
                                                        placeholder="Enter Account Number"
                                                        className={`py-2 rounded outline-none ps-2 border w-11/12`}
                                                        onChange={handleRequestValues}
                                                    />
                                                    <button disabled={!raiseRequestvalues?.bank_account_number} type="submit" className=' relative border bg-white-500 rounded-md w-12 p-1 text-white font-medium py-2 h-11 ms-3' onClick={getAccountDetails}>
                                                        <img src="assets/images/Vector.svg" className="searchLogo ps-3" /></button>
                                                </div>
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_account_number ? 'red' : 'inherit' }}>{formErrors?.bank_account_number}</span>
                                            </div>
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_account_title" className="numberRequestModal">Account Holder Name*</label>
                                                <input
                                                    type="text"
                                                    name="bank_accountHolderTitle"
                                                    value={raiseRequestvalues?.bank_accountHolderTitle}
                                                    placeholder="Enter Account Holder Name"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    disabled
                                                    onChange={handleRequestValues}
                                                />
                                                {/* <span className='numberRequestModal' style={{ color: formErrors?.bank_account_number ? 'red' : 'inherit' }}>{formErrors?.bank_account_number}</span> */}
                                            </div>
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_previousValue" className="numberRequestModal">Previous Value*</label>
                                                <input
                                                    type="text"
                                                    name="bank_previousValue"
                                                    value={raiseRequestvalues?.bank_previousValue}
                                                    placeholder="Enter Previous Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    disabled
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_previousValue ? 'red' : 'inherit' }}>{formErrors?.bank_previousValue}</span>
                                            </div>
                                            {raiseRequestvalues?.bank_requestPurpose === "Change Dob" ? <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Change Value*</label>
                                                <input
                                                    type="date"
                                                    name="bank_changeValue"
                                                    value={raiseRequestvalues?.bank_changeValue}
                                                    placeholder="Enter Change Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.request_update_value ? 'red' : 'inherit' }}>{formErrors?.request_update_value}</span>
                                            </div> : <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Change Value*</label>
                                                <input
                                                    type="text"
                                                    name="bank_changeValue"
                                                    value={raiseRequestvalues?.bank_changeValue}
                                                    placeholder="Enter Change Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_changeValue ? 'red' : 'inherit' }}>{formErrors?.bank_changeValue}</span>
                                            </div>}


                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">NID Number*</label>
                                                <input
                                                    type="text"
                                                    name="nid_number"
                                                    value={raiseRequestvalues?.nid_number}
                                                    placeholder="Enter NID Number"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.nid_number ? 'red' : 'inherit' }}>{formErrors?.nid_number}</span>
                                            </div>

                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Dob*</label>
                                                <input
                                                    type="date"
                                                    name="dob"
                                                    value={raiseRequestvalues?.dob}
                                                    placeholder="Enter dob"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.dob ? 'red' : 'inherit' }}>{formErrors?.dob}</span>
                                            </div>
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_remarks" className="numberRequestModal">Remarks*</label>
                                                <input
                                                    type="text"
                                                    name="bank_remarks"
                                                    value={raiseRequestvalues?.bank_remarks}
                                                    placeholder="Enter Remarks"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_remarks ? 'red' : 'inherit' }}>{formErrors?.bank_remarks}</span>
                                            </div>
                                        </div>

                                        {(
                                            <div className='mt-3'>
                                                <ChangePersonalInfoModal></ChangePersonalInfoModal>
                                            </div>
                                        )}
                                    </div>

                                    <div className='flex justify-end mt-5 mb-3'>
                                        <div className='flex justify-center align-middle me-2'>
                                            <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary' onClick={closerequestModal}>Cancel</button>
                                        </div>
                                        <div className='flex justify-center align-middle me-3'>
                                            <button type="submit" className=' relative border rounded-md bg-blue-600 w-20 p-1 text-white font-medium custom_btn_primary commonBtn py-2' onClick={handleRaise}>
                                                Raise</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default DetailsPagee;
