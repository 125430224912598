"use-strict";

import { combineReducers, createStore } from "redux";

import UserDataReducer from "./reducers/UserData";
import QueuesDataReducer from "./reducers/QueuesData";
import UserListReducer from "./reducers/UserList";
import UserThemeReducer from "./reducers/UserTheme";
import ModalReducer from './reducers/ModalReducer'; 

const AppReducers = combineReducers({
  UserDataReducer,
  QueuesDataReducer,
  UserListReducer,
  UserThemeReducer,
  ModalReducer,
});

const rootReducer = (state, action) => {
  return AppReducers(state, action);
};

let store = createStore(rootReducer);

export default store;
