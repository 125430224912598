import React from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/initiateIvr.scss";
import Icon from "../../component/core-component/Icon"
import API from "../../helpers/api/index";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../component/Pagination";
import { DatePicker } from 'antd';
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import { WithAuth } from "../../common/WithAuth";

const InitiateIVRCoponent = () => {

  const steps = ["Services", "IVR Process Table"];
  const [showModal, setShowModal] = useState(false);
  const [accountData, setAccountData] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [openPopup ,setOpenPopup] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = accountData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = accountData?.length ? Math.ceil(accountData?.length / recordsPerPage) : 0;
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
    setValues({});
    setFormErrors({})
  };
  useEffect(() => {
    getAccountsData();
  }, []);

  const getAccountsData = async () => {
    try {
      const { data } = await API.apiGet(`/userAccount/list`);
      setAccountData(data.data);
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  };
  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
  }
  const [values, setValues] = useState({
    name: "",
    account_no: "",
    email: "",
    phone:"",
    dob: "",
    NID_number: "",
    account_type: "",
    address: "",
  });

  const handleInputChange = (event,fieldName) => {
    let name = fieldName === 'dob' ? 'dob' : event.target.name;
    let value = fieldName === 'dob' ? event : event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', 
    }));
  };

  const validateData = () => {
    const errors = {};
    if(!values?.name){
      errors.name = "Name is required"
    }if(!values?.account_no){
      errors.account_no = "Account Number is required"
    }if(!values?.email){
      errors.email = "Email is required"
    }if(!values?.phone){
      errors.phone = "Mobile Number is required"
    }else if (values.phone.length > 14) {
      errors.phone = "Maximum length is 14 digits";
    }
    if(!values?.dob){
      errors.dob = "DOB is required"
    }if(!values?.NID_number){
      errors.NID_number = "NID is required"
    }if(!values?.account_type){
      errors.account_type = "Account Type is required"
    }if(!values?.address){
      errors.address = "Address is required"
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0;
  }

  const handleBack = () => {
    navigate(`/services`);
  };

  const handleSubmit = async () => {
    if(validateData()){
    let payload = {
      ...values,
      dob: values.dob.$d
    }
    try {
      const { data } = await API.apiPost(`/userAccount`, {payload: encodeData(payload)});
      if (data?.success) {
        setShowModal(false);
        getAccountsData();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  }
  };
  const handleCall = async(item) => {
    setOpenPopup(true)
    let payload = {
    "user_id":item.id,
    "phone_number":item.phone
    }
    try {
      const { data } = await API.apiPost(`/v1/ivr/initiate/call`, {payload: encodeData(payload)})
      if (data?.success) {
          toast.success(data?.message);
      }
      else {
          toast.error(data?.message)
      }
  }
  catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
  }
  };
  return (
    <div className={`flex dark:bg-dark-background updateIvrContainer`}>
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <div className="z-0 container p-10 mainContainer">
        <Breadcrumb
              steps={steps}
              activeStep={1}
              showBackButton={true}
              redirectUrl={"/services"}
            />
          <div className=" flex flex-row justify-between userHeading p-6 mt-2">
            <div className="flex manageIvr">
              <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand ms-2 text-24 font-bold leading-30 userManagementHeading">
                IVR Management
              </h1>
            </div>
            <div>
              <button
                className={` hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded disabled:disabledBtn custom_btn_primary commonBtn`}
                onClick={() => setShowModal(true)}
              >
                Add User Account
              </button>
            </div>
            {showModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
              >
                <div class="relative p-4 w-full max-w-3xl max-h-full">
                  <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Create New Account
                      </h3>
                      <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="static-modal"
                        onClick={closeModal}
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className=" w-11/12 m-auto mt-3">
                    <div className="">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Name
                          </label>
                          <div>
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={values.name}
                              id="name"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.name ? 'red' : 'inherit' }}>{formErrors?.name}</span>
                        </div>
                      <div className="flex justify-between mt-3">
                        <div className="accDetails">
                          <label
                            htmlFor="account_no"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Account Number
                          </label>
                          <div>
                            <input
                              type="number"
                              name="account_no"
                              placeholder="Account Number"
                              value={values.account_no}
                              id="account_no"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.account_no ? 'red' : 'inherit' }}>{formErrors?.account_no}</span>
                        </div>
                        <div className="accDetails">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Email
                          </label>
                          <div>
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              id="email"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.email ? 'red' : 'inherit' }}>{formErrors?.email}</span>
                        </div>
                      </div>
                      <div className="flex justify-between mt-3">
                      <div className="accDetails">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Phone
                          </label>
                          <div>
                            <input
                              type="tel"
                              name="phone"
                              placeholder="Phone Number"
                              value={values.phone}
                              id="phone"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.phone ? 'red' : 'inherit' }}>{formErrors?.phone}</span>
                        </div>
                        <div className="accDetails">
                          <label
                            htmlFor="dob"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Dob
                          </label>
                          <div>
                            <DatePicker
                              name="dob"
                              placeholder="Dob"
                              value={values.dob}
                              id="dob"
                              onChange={(val) => handleInputChange(val,'dob')}
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.dob ? 'red' : 'inherit' }}>{formErrors?.dob}</span>
                        </div>
                      </div>
                      <div className="flex justify-between mt-3">
                        <div className="accDetails">
                          <label
                            htmlFor="NID_number"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            NID
                          </label>
                          <div>
                            <input
                              type="number"
                              name="NID_number"
                              placeholder="NID Number"
                              value={values.nid}
                              id="NID_number"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.NID_number ? 'red' : 'inherit' }}>{formErrors?.NID_number}</span>
                        </div>
                        <div className="accDetails">
                          <label
                            htmlFor="account_type"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Account Type
                          </label>
                          <div>
                            <select
                              id="account_type"
                              name="account_type"
                              value={values.account_type}
                              onChange={(e) => handleInputChange(e)}
                              className="block w-full rounded-md border-0 p-1 h-10 pe-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6 outline-none"
                            >
                              <option className="hidden">
                                Select Response
                              </option>
                              <option>S02</option>
                              <option>S07</option>
                            </select>
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.account_type ? 'red' : 'inherit' }}>{formErrors?.account_type}</span>
                        </div>
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                        >
                          Address
                        </label>
                        <div>
                          <textarea
                            name="address"
                            id="address"
                            placeholder="Address"
                            onChange={handleInputChange}
                            className="block w-full h-14 rounded-md border-0 ps-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 p-1 outline-none"
                          ></textarea>
                        </div>
                        <span className='numberRequestModal'  style={{ color: formErrors?.address ? 'red' : 'inherit' }}>{formErrors?.address}</span>
                      </div>
                    </div>
                    <div class="flex justify-end items-center p-4 md:p-5 border-gray-200 rounded-b dark:border-gray-600">
                    <button
                        data-modal-hide="static-modal"
                        type="button"
                        class="custom_btn_secondary py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        data-modal-hide="static-modal"
                        type="button"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn"
                        onClick={handleSubmit}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" mt-4">
            <table
              className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}
            >
              <thead>
                <tr className="ps-2 pe-2">
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Name
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Account Number
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    NID Number
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Phone Number
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Account Type
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Status
                  </th>
                  <th
                    className="py-3 px-4 capitalize text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRecords?.map(
                  (item, index) =>
                    (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className="p-4 text-center">{item.name}</td>
                        <td className="p-4 text-center">{item.account_no}</td>
                        <td className="p-4 text-center">{item.NID_number}</td>
                        <td className="p-4 text-center">{item.phone}</td>
                        <td className="p-4 text-center">{item.account_type}</td>
                        <td className="p-4 text-center">{item.status}</td>
                        <td className="p-4 text-center">
                          <button
                            type="button"
                            className={ item.status === 'Active' ? "notActive  px-4 py-2 rounded" :"initiateIVRButton px-4 py-2 rounded"}
                            onClick={() => handleCall(item)}
                            disabled={item.status === 'Active'}
                          >
                            Initiate IVR
                          </button>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          ></Pagination>
          {openPopup && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
              >
                <div class="relative p-4 w-4/12 max-w-3xl max-h-full">
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 pt-4">
                    <div className="text-center text-xl font-semibold pt-5">
                        IVR process is InProgress
                    </div>
                    <div className="flex justify-end items-center p-4 md:p-5 border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        data-modal-hide="static-modal"
                        type="button"
                        className="py-2.5 initiateIVRButton px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() => setOpenPopup(false)}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};
export default WithAuth(InitiateIVRCoponent);
