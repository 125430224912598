import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";
import API from "../helpers/api/index";
import { useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import MyTaskTable from "./MyTaskTable";

export default function MyTaskComponent() {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState('GridView');
    const [pendingData, setPendingData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [userData] = useSelector((Gstate) => [Gstate?.UserDataReducer?.user_data]);
    const isMobileDevice = window.screen.width <= 900;

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    

    const [activeTab, setActiveTab] = useState('My Tasks');
    const currentRecords = activeTab === 'My Tasks' ? pendingData?.slice(indexOfFirstRecord, indexOfLastRecord) : completedData?.slice(indexOfFirstRecord, indexOfLastRecord)  ;
    const nPages = Math.ceil(activeTab === 'My Tasks'? pendingData?.length / recordsPerPage : completedData?.length / recordsPerPage);
    const [columns] = useState([
        {
            title: 'Account Number',
            key: 'account number',
          },
          {
            title: 'Folder Name',
            key: 'folder name',
          },
          {
            title: 'Department Name',
            key: 'department name',
          },
          {
            title: 'Date',
            key: 'date',
          },
          {
            title: 'Status',
            key: 'status',
          },
          {
            title: 'View',
            key: 'action',
          }
    ])

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const fetchData = async () => {
        setDataLoading(true);
        if (userData) {
            try {
                const response = await API.apiGet(`/raise/task/list?email=${userData?.email}`);
                if (response?.data?.success) {
                    setDataLoading(false);
                    // Filter data where status is "Pending"
                    const filteredPendingData = response?.data?.data.filter(item => item.status === "Pending");
                    const completedPendingData = response?.data?.data.filter(item => item.status === "Completed");
                    setPendingData(filteredPendingData)
                    setCompletedData(completedPendingData)
                } else {
                    setDataLoading(false);
                }
            } catch (error) {
                setDataLoading(false);
                console.error('Error fetching data:', error);
            }
        }
    };

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    // Call fetchData when component mounts
    // useEffect(() => {
    //   fetchData();
    // }, []); // Empty dependency array ensures it only runs once on mount

    // Call fetchData whenever userData changes
    useEffect(() => {
        fetchData();
    }, [userData]);

    const handleViewMode = (mode) => {
        setViewMode(mode);
    }

    return (<>
        <div className={`z-0 container pt-2 pe-10 ${isMobileDevice && 'ps-4 pe-4'}`}>
            <div className=" flex tabContainer justify-between">
                <div className="differentTabs flex">
                    <div
                        className={`w-content p-2 text-center tabsName ${activeTab === 'My Tasks' ? "showActiveColor commmonActiveTag" : ""}`}
                    >
                        <p className="mt-2 cursor-pointer" onClick={() => handleTabClick('My Tasks')}>
                            My Tasks
                        </p>
                    </div>
                    <div
                        className={`w-48 p-2 text-center tabsName ${activeTab === 'Completed Tasks' ? "showActiveColor commmonActiveTag" : ""}`}
                    >
                        <p className="mt-2 cursor-pointer" onClick={() => handleTabClick('Completed Tasks')}>
                            Completed Tasks
                        </p>
                    </div>
                </div>
                <div className="flex gap-2">
                    <button onClick={() => handleViewMode('GridView')}><img src="assets/images/gridIcon.svg" alt="grid" className={`${viewMode === 'GridView' ? 'showActiveColor commmonActiveTag' : ''}`}/></button>
                    <button onClick={() => handleViewMode('ListView')}><img src="assets/images/listIcon.svg" alt="list" className={`${viewMode === 'ListView' ? 'showActiveColor commmonActiveTag' : ''}`}/></button>
                </div>
            </div>
        </div>
        <div className="flex flex-wrap gap-4 pt-4">
        {dataLoading ? (
                    <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>
                ) : ( <>
                    {viewMode === 'GridView' 
                        ? 
                            <>
                                {
                                    activeTab === 'My Tasks' ? (
                                        pendingData?.length > 0 ? (
                                            pendingData?.map((item, index) => (
                                                <div
                                                    key={`${item.id}-${index}`}
                                                    className={`bg-white hover:cursor-pointer mt-4 commonCard ${isMobileDevice ? 'myTaskResponsiveCard' : 'myTaskCards'}`}
                                                    onClick={() => navigate(`/ViewFolderDetails?folder_id=${item.folder_id}&folder_name=${item.FileStorage.folder_name}/mytask/${item.task_type}/task=${item.id}`)}
                                                >
                                                    <div className="flex items-center justify-between px-4 py-2 writeOfCard">
                                                        <span className="font-bold">{item.task_type}</span>
                                                        <div className="cursor-pointer arrowIcon commonCardIcons">
                                                            <img src="assets/right-arrow.svg" alt="file" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="p-4">
                                                            <b>Folder Name</b> : <span title={item.FileStorage.folder_name}>{item.FileStorage.folder_name ? item.FileStorage.folder_name?.length > 20 ? `${item.FileStorage.folder_name.substring(0, 20)}...` : item.FileStorage.folder_name : '--'}</span>
                                                        </p>
                                                        <p className="p-4">
                                                            <b>Department Name</b> : {item.department_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="noTaskDataFound flex flex-col items-center w-full">
                                                <img
                                                    src='/assets/noData.svg'
                                                    alt="no-data"
                                                    className="noDataImage"
                                                />
                                                <div className="text-xl font-semibold">
                                                    No data found
                                                </div>
                                            </div>
                                        )
                                    ) : activeTab === 'Completed Tasks' && completedData?.length > 0 ? (
                                        completedData?.map((item, index) => (
                                            <div
                                                key={`${item.id}-${index}`}
                                                className={`bg-white hover:cursor-pointer mt-4 commonCard ${isMobileDevice ? 'myTaskResponsiveCard' : 'myTaskCards'}`}
                                                onClick={() => navigate(`/ViewFolderDetails?folder_id=${item.folder_id}&folder_name=${item.FileStorage.folder_name}/mytask/${item.task_type}/task=${item.id}`)}
                                            >
                                                <div className="flex items-center justify-between px-4 py-2 writeOfCard">
                                                    <span className="font-bold">{item.task_type}</span>
                                                    <div className="cursor-pointer arrowIcon commonCardIcons">
                                                        <img src="assets/right-arrow.svg" alt="file" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="p-4">
                                                        <b>Folder Name</b> : <span title={item.FileStorage.folder_name}>{item.FileStorage.folder_name ? item.FileStorage.folder_name?.length > 20 ? `${item.FileStorage.folder_name.substring(0, 20)}...` : item.FileStorage.folder_name : '--'}</span>
                                                    </p>
                                                    <p className="p-4">
                                                        <b>Department Name</b> : {item.department_name}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : dataLoading ? (
                                        <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                            />
                                        </div>
                                    ) : (
                                        <div className="noTaskDataFound flex flex-col items-center w-full">
                                            <img
                                                src='/assets/noData.svg'
                                                alt="no-data"
                                                className="noDataImage"
                                            />
                                            <div className="text-xl font-semibold">
                                                No data found
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        :
                        (pendingData?.length && activeTab === 'My Tasks')?
                            <MyTaskTable currentRecords={ currentRecords || []} columns={columns} nPages={nPages} handlePageChange={handlePageChange} currentPage={currentPage}/>
                        :
                        (completedData?.length && activeTab === 'Completed Tasks')?
                            <MyTaskTable currentRecords={ currentRecords || []} columns={columns} nPages={nPages} handlePageChange={handlePageChange} currentPage={currentPage}/>
                        :
                        <div className="noTaskDataFound flex flex-col items-center w-full">
                            <table className="min-w-full shadow-md rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                <thead>
                                    <tr className="ps-2 pe-2">
                                    {columns.map((item, indx) =>
                                        <th key={`row${indx}`} className="py-2 px-4 border-b">
                                            {item?.title}
                                        </th>
                                    )}
                                    </tr>
                                </thead>
                            </table>
                            <div className={`bg-white w-full flex font-semibold justify-center py-5`}>No data found</div>
                        </div>
                    }
            </>
        )}
        </div>
        </>
    )
}
