import React, { useEffect, useRef, useState } from 'react';
import Pagination from "../../../component/Pagination";
import "../../../styles/reconcilition.scss";
import fileNameLogo from "../../../assets/images/fileNameLogo.svg";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';
import details from "../../../assets/images/fi_more-vertical.svg";
import renameIcon from "../../../assets/images/renameIcon.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg"
import API from '../../../helpers/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Icon from '../../../component/core-component/Icon';
import { encodeData } from '../../../common/commonFunction';
import { handleErrorMessage } from '../../../common/commonFunction';
export default function FolderListTableComponent({ columns, rowData, tableLoading, nPages, handlePageChange, currentPage, recordsPerPage, openFolder, getFolderUpdateDetails }) {
    const currentRecords = rowData;
    const [selectedRow, setSelectedRow] = useState(null);
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [folderId, setFolderId] = useState(null);
    const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [themeData, setThemeData] = useState(themeDetails?.themeDetails ? themeDetails?.themeDetails : themeDetails);
    const ref = useRef();
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const handler = (event) => {
          if (
            showOptionsModal &&
            ref.current &&
            !ref.current.contains(event.target)
          ) {
            setShowOptionsModal(false);
          }
        };
        document.addEventListener('mousedown', handler);
        return () => {
          document.removeEventListener('mousedown', handler);
        };
    }, [showOptionsModal]);

    function openFolderRequest(currentRecord) {
        setSelectedRow(currentRecord);
        setShowOptionsModal(!showOptionsModal);
    }

    async function handleRename(item) {
        setIsOpen(true);
        setFolderId(item?.id);
        setFolderName(item?.folder_name);
        setShowOptionsModal(false);
    }
    const handleRenameSubmit = async () => {
        if (validateForm()) {
            SetRequestSubmitLoader(true)
            try {
                let payload = {
                    folder_id: folderId,
                    folder_name: folderName
                }
                const response = await API.apiPut(`/processList/file/storage`, {payload: encodeData(payload)});
                if (response?.status) {
                    getFolderUpdateDetails()
                    SetRequestSubmitLoader(false)
                    setIsOpen(false)
                    toast.success(response?.data?.message)
                }
                else {
                    toast.error(response?.data?.error)
                    SetRequestSubmitLoader(false)
                }
            }
            catch (error) {
                console.log(error)
                SetRequestSubmitLoader(false)
            }
        }
    }

    async function handleDelete(item) {
        setShowOptionsModal(false);
        try {
            const response = await API.apiDelete(`/processList/file/storage?folder_id=${item?.id}`);
            if (response?.status) {
                getFolderUpdateDetails()
                toast.success(response?.data?.message)
            }
            else {
                toast.error(response?.data?.error || 'An error occurred while deleting the folder.');
            }
        }
        catch (error) {
            console.log(error);
            handleErrorMessage(error);
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        setFormErrors({})
      }

      const validateForm = () => {
        const errors = {};
        
        if (!folderName) {
          errors.folder_name = "Folder name cannot be empty"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
      }

    return (
        <div className={`${'reconcilitaion-main-container mt-5'}`}>
            <div className="reconcilitionTable-container">
                {currentRecords?.length > 0 ?
                    <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                        <thead>
                            <tr className="ps-2 pe-2">
                                {columns?.map((item, indx) =>
                                    <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                                        {item?.title}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords?.map((item, index) =>
                                <tr key={`${item}_${index}`}>
                                    {columns.map((column, colIdx) =>
                                        ['created_at', 'updated_at']?.includes(column?.key)
                                            ?
                                            <td key={`col_${index}${colIdx}`} className={`py-2 ps-4`} style={{ fontSize: '16px' }} title={item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD | HH:mm:ss') : ''}>
                                                {item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD | HH:mm:ss') : '--'}
                                            </td>
                                            :
                                            ['action']?.includes(column?.key)
                                                ?
                                                <td className="py-2 ps-4 options-cell" title={item?.[column?.key]} style={{ fontSize: '16px' }}><button onClick={() => openFolderRequest(item, column, index)}><img src={details} width={16} height={16} /></button>{showOptionsModal && selectedRow === item && (
                                                    <div className="options-modal" ref={ref}>
                                                        <button className={`text-black flex ${true && "border-b"}`} onClick={() => handleRename(item)}> <img src={renameIcon} alt="Rename" className={`button-icon me-1 pt-1 ${true && "pb-2"}`} /> Rename</button>
                                                        {true && <button className='text-red-500 flex' onClick={() => handleDelete(item)}> <img src={deleteIcon} alt="Rename" className="button-icon me-1 pt-1" /> Delete</button>}
                                                    </div>
                                                )}</td>
                                                :
                                                (column?.key == 'User')
                                                    ?
                                                    <td title={item?.User?.full_name ? item?.User?.full_name : ''}>
                                                        {item?.User?.full_name ? item?.User?.full_name : '--'}
                                                    </td>
                                                    :
                                                    (column?.key === "folder_name")
                                                        ?
                                                        <td className="py-2 ps-4" title={item?.[column?.key]} style={{ fontSize: '16px' }}>
                                                            <span className={`newFolder flex cursor-pointer`} style={{ color: themeData?.color_config?.main_color }} onClick={() => openFolder(item)}>
                                                                {/* <img className='me-2' src={fileNameLogo} /> */}
                                                                <div className='me-2 flex items-center'><Icon iconName="folderIcon" width={16} height={16} fill={themeData?.color_config?.main_color} /></div>
                                                                {item?.[column?.key] ? item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key] : '--'}
                                                            </span>
                                                        </td>
                                                        :
                                                        <td title={item?.[column?.key] ? item?.[column?.key] : ''}>
                                                            {item?.[column?.key] ? item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key] : '--'}
                                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <>
                        <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                            <thead>
                                <tr className="ps-2 pe-2">
                                    {columns?.map((item, indx) =>
                                        <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                                            {item?.title}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                        </table>
                        {tableLoading ?
                            <div className="w-full h-full flex items-center justify-center" >
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="60"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={[]}
                                />
                            </div>
                            :
                            <div className='flex justify-center py-5 font-semibold'>
                                No data found
                            </div>
                        }
                    </>
                }
            </div>
            {isOpen && (
                <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                    {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>}
                    <div className="modal absolute">
                        <div className="modal-content rounded-lg bg-white border modalContainer createModal p-5">
                            <div className='ps-3 pe-3 pt-1'>
                                <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Update Folder</p>
                            </div>
                            <div className='modalForm'>
                                <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                    <label htmlFor="document_name" className='numberRequestModal'>Folder Name*</label>
                                    <input
                                        type="text"
                                        id="inputA"
                                        name="folder_name"
                                        value={folderName}
                                        onChange={(e) => {setFolderName(e.target.value); setFormErrors({})}}
                                        placeholder="Enter Folder Name"
                                        className={`py-2 rounded outline-none ps-2  ${formErrors?.folder_name ? 'border border-red-500' : ' border'}`}
                                    />
                                    <span className='numberRequestModal' style={{ color: formErrors?.folder_name ? 'red' : 'inherit' }}>{formErrors?.folder_name}</span>
                                </div>

                                <div className='flex justify-end mt-5 mb-3'>
                                    <div className='flex justify-center align-middle me-2'>
                                        <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary' onClick={closeModal}>Cancel</button>
                                    </div>
                                    <div className='flex justify-center align-middle me-3'>
                                        <button type="submit" className=' relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2' onClick={handleRenameSubmit}>
                                            Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(!tableLoading && currentRecords?.length > 0) &&
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                ></Pagination>
            }
        </div>
    )
}