import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Navbar from "../common/Navbar";
import styles from "../styles/navbar.module.scss";
export default function InformationUpdateDashboard() {
  var navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;

  return (
    <>
      <div
        className={`flex dark:bg-dark-background dashboard-container dashboard-main`}
      >
        <SideMenu headerName="Account Information Update" />
        <div
          className={`h-screen flex-1 overflow-hidden ${
            isMobileDevice && "overflow-y-auto mainContainer_responsive"
          }`}
        >
          <Header />
          <div
            className={`z-0 p-10 mt-6 ${styles.navbar} ${
              isMobileDevice && "p-5 responsiveMainContainer"
            }`}
          >
            {!isMobileDevice && (
              <h1 className="font-bold">Account Information Update</h1>
            )}
            <div
              className="samdProcessCards commonCard mt-6 cursor-pointer bg-white"
              onClick={() => navigate(`/nomineeDetails`)}
              style={isMobileDevice ? { width: "100%" } : {}}
            >
              <div className="flex items-center justify-between px-4 py-2 writeOfCard">
                <div>
                  <img src="assets/files.svg" alt="file" />
                </div>
                <span className="font-semibold">System Home</span>
                <div className="cursor-pointer arrowIcon commonCardIcons">
                  <img src="assets/right-arrow.svg" alt="file" />
                </div>
              </div>
              <div>
                <p className="p-4">
                  The account update involves issuing a new card with updated
                  account details, such as expiration date or security code,
                  typically delivered via mail or online banking
                </p>
                <div className="text-right pb-2 px-4">
                                    <p>
                                        Created on 24 Mar, 2024
                                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
