import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import SideMenu from '../../../component/SideMenu';
import Header from '../../../component/Header';
import Breadcrumb from '../../../component/Breadcrumb';
import DataTable from '../../../component/DataTable';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import * as Actions from "../../../redux/actions";
import API from "../../../helpers/api/index";
import "../../../styles/updatePhoneNumber.scss";
import Icon from '../../../component/core-component/Icon';
import { getDecodedToken } from "../../../helpers/auth";
import { encodeData, handleErrorMessage } from '../../../common/commonFunction';
import Validation from '../../../utils/Validation';
import Pagination from '../../../component/Pagination';
import fileNameLogo from "../../../assets/images/fileNameLogo.svg";
import FolderListTableComponent from './FolderListTable';
import Select from 'react-select';
import { WithAuth } from '../../../common/WithAuth';
import moment from 'moment';
import { openingModal, closingModal } from '../../../redux/actions';
import { useSelector } from 'react-redux';

const steps = ['SAMD Department', 'Loan Document Storage'];
const phoneTableHeader = [
  {
    title: 'Folder Name',
    key: 'folder_name',
  },

  {
    title: 'Account Number',
    key: 'account_number',
  },
  {
    title: 'Account Holder',
    key: 'account_holder',
  },
  {
    title: 'Created By',
    key: 'User',
  },
  {
    title: 'Created At',
    key: 'created_at',
  },
  {
    title: 'Last Updated By',
    key: 'User',
  },
  {
    title: 'Last Updated At',
    key: 'updated_at',
  },
  {
    title: 'Action',
    key: 'action',
  },
];

const LoanStorage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    account_number: '',
    account_holder: '',
  });
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
  const [formErrors, setFormErrors] = useState({});
  const [updateFolderData, setUpdateFolderData] = useState([]);
  const [totalAccountDetails, setTotalAccountDetails] = useState('loading...');
  const [totalDocumentsCount, setTotalDocumentsCount] = useState('loading...');
  const [searchData, setSearchData] = useState()
  const [dataLoading, setDataLoading] = useState(false);
  const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false)
  const [filterData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [isOpenRaiseModal, setIsOpenRaiseModal] = useState(false);
  const [userList,setUserList]=useState([])
  var redux_dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filterData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(filterData?.length / recordsPerPage);

  const isMobileDevice = window.screen.width <= 900;
  const width = window.screen.width;

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage)
  }

  const openModal = () => {
    setIsOpen(true);
    dispatch(openingModal())
  };

  const closeModal = () => {
    setIsOpen(false);
    setValues({})
    setFormErrors({})
    dispatch(closingModal())
  };
  const validateForm = () => {
    const errors = {};
    if (!Validation.isAlphanumeric(values?.account_number)) {
      errors.account_number = "Account Number should be alphanumeric"
    }
    if (!values?.account_number) {
      errors.account_number = " Bank account number is required"
    }
    if (!values?.account_holder) {
      errors.account_holder = "Account Holder name is required"
    }  
    if (values?.account_holder?.length && !Validation.validateName(values.account_holder)){
      errors.account_holder = "Account Holder name should not contain special character"
    }
    

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async (event) => {
    SetRequestSubmitLoader(true)
    if (validateForm()) {
      const payload = values;
      try {
        const response = await API.apiPost(`processList/file/storage`, { payload: encodeData(payload) })
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          closeModal();
          getFolderUpdateDetails();
          SetRequestSubmitLoader(false);
        } else {
          SetRequestSubmitLoader(false)
          toast.error(response?.error)
        }
      }
      catch (error) {
        SetRequestSubmitLoader(false)
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.message);
      }
    }
    SetRequestSubmitLoader(false)
    event.preventDefault();
  };

  const getFolderUpdateDetails = async () => {
    try {
      const { user_id } = getDecodedToken();
      const { data } = await API.apiGet(`/processList/file/storage?id=${user_id}`)
      if (data?.success) {
        setUpdateFolderData(data?.data);
        getTotalAccountDetails();
        setFilteredData(data?.data)
        toast.success(data?.message);
        setDataLoading(false)
      } else {
        // toast.error(data?.message)
        setDataLoading(false)
        setFilteredData([]);
      }
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
      setDataLoading(false)
      setFilteredData([]);
    }
  }

  const getTotalDocumentDetails = async () => {
    try {
      const { data } = await API.apiGet(`/fileStorage/total/documents`)
      if (data?.success) {
        setTotalDocumentsCount(data?.totalDocuments);
        setDataLoading(false);
      } else {
        setTotalDocumentsCount(0);
        setDataLoading(false);
      }
    }
    catch (error) {
      setTotalDocumentsCount(0);
      setDataLoading(false);
    }
  }

  const getUserList = async () => {
    try {
      const { data } = await API.apiGet(`users/list`)
      if (data?.success) {
        setUserList(data.data)
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    }
    catch (error) {
      setDataLoading(false);
    }
  }

  const getTotalAccountDetails = async () => {
    try {
      const { user_id } = getDecodedToken();
      const { data } = await API.apiGet(`/processList/total/accounts?id=${user_id}`)
      if (data?.success) {
        setTotalAccountDetails(data?.totalAccounts);
        setDataLoading(false)
      } else {
        setTotalAccountDetails(0);
        setDataLoading(false)
      }
    }
    catch (error) {
      setTotalAccountDetails(0);
      setDataLoading(false)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase(); // Convert search text to lowercase for case-insensitive search
    const filteredSearchData = updateFolderData?.filter(item => {
      for (let key in item) {
        const value = item[key];
        if (typeof value === 'string' && value.toLowerCase().includes(searchText)) {
          return true;
        }
        if (typeof value === 'number' && value.toString().includes(searchText)) {
          return true;
        }
      }
      return false;
    });
    setSearchData(e.target.value);
    if (filteredSearchData && filteredSearchData?.length > 0) {
      setFilteredData(filteredSearchData);
    } else {
      setFilteredData([]);
    }
  }

  // const uploadFile = async () => {
  //   setIsLoading(true);
  //   const formData = new FormData();
  //   formData.append("pdf", data.pdf);
  //   formData.append("excel", data.excel);
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/ocr`, {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
  //       }
  //     });
  //     if (response.status === 200) {
  //       const {data,filePath,count} = await response.json()
  //       setReconciliationTableData(data)
  //       setMissingCount(count)
  //       if(filePath){
  //         const formData = new FormData();
  //         formData.append("path",filePath)
  //         const fileApiResponse  = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/ocr/sendfile`, {
  //         method: "POST",
  //         body: formData,
  //         headers: {
  //           'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
  //         }})
  //         const blob = await fileApiResponse.blob();
  //         let fileName = "file.xlsx"; // Default file name
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parentNode.removeChild(link);
  //         toast.success("File downloaded successfully")
  //       }
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    // return () => {
    setDataLoading(true);
    getFolderUpdateDetails();
    getTotalAccountDetails();
    getTotalDocumentDetails();
    getUserList()
    // }
  }, [])

  // const handleTableData = (item) => {
  //   return item;
  // };
  const openFolder = (item) => {
    let folder_id = item?.id;
    let folderName = item?.folder_name
    navigate(`/ViewFolderDetails?folder_id=${folder_id}&folder_name=${folderName}`)
  }

  const [formState, setFormState] = useState({ department: '', email: '' });
  const [folderFields, setFolderFields] = useState([{ folder_name: '', task_type: [] }]);
  const [raiseFormErrors, setRaiseFormErrors] = useState({});
  const departments = [
    { id: 1, name: 'SAMD' },
    { id: 1, name: 'Agent Banking' },
    { id: 1, name: 'Finance Banking' }
  ];

  const taskTypes = [
    { id: 1, name: 'SAMD_Document_upload' },
    { id: 2, name: 'SAMD_Other_upload' },
    { id: 3, name: 'HR_Document_upload' },
  ];


  const openRaiseModal = () => {
    setIsOpenRaiseModal(true);
    dispatch(openingModal())
  };

  const closeRaiseModal = () => {
    setIsOpenRaiseModal(false);
    setFormState({})
    setFolderFields([{ folder_name: '', task_type: [] ,remark:""}])
    setFormErrors({})
    setRaiseFormErrors({});
    dispatch(closingModal())
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleFolderFieldChange = (selectedOptions, index) => {
    const updatedFields = [...folderFields];
    updatedFields[index]['folder_name'] = selectedOptions.label;
    updatedFields[index]['folder_name_id'] = selectedOptions.value;
    setFolderFields(updatedFields);
    validateFolderName(index, selectedOptions.label);
  };

  const handleTaskTypeSelectChange = (selectedOptions, index) => {
    const updatedFields = [...folderFields];
    updatedFields[index]['task_type'] = selectedOptions.map((option) => option.value);
    setFolderFields(updatedFields);
    validateTaskType(index, selectedOptions);
  };
  const handleSelectEmail=(selectedOptions)=>{
    setFormState({ ...formState, ['email']: selectedOptions.value });
  }
  
  const handleAddFolderField = () => {
    setFolderFields([...folderFields, { folder_name: '', task_type: [] }]);
  };

  const handleRemoveFolderField = (index) => {
    const updatedFields = [...folderFields];
    updatedFields.splice(index, 1);
    setFolderFields(updatedFields);
  };


  const handleSubmitRaise = async () => {
    const { department, email } = formState;
    const folders = folderFields.flatMap(({ folder_name, folder_name_id, task_type, remark }) =>
        task_type.map(type => ({
            department_name: department,
            folder_id: folder_name_id,
            email: email,
            task_type: type,
            remark: remark
        }))
    );

    const errors = {};
    if (!department) {
        errors.department = 'Department is required';
    }
    if (!email) {
        errors.email = 'Email is required';
    }
    folderFields.forEach((field, index) => {
        if (!field.folder_name) {
            errors[`folder_name-${index}`] = 'Folder name is required';
        }
        if (!field.task_type.length) {
            errors[`task_type-${index}`] = 'Task type is required';
        }
        if (!field.remark) {
            errors[`remark-${index}`] = 'Remark is required';
        }
    });

    if (Object.keys(errors).length > 0) {
        setRaiseFormErrors(errors); // Set errors state to trigger rendering of error messages
    } else {
        try {
          SetRequestSubmitLoader(true);
            let payload = {
              folders
            };
            const response = await API.apiPost(`/raise/task`, {payload: encodeData(payload?.folders)});
            getUserDetails();
            toast.success(response?.data.message);
            SetRequestSubmitLoader(false);
            closeRaiseModal();
        } catch (error) {
            // Handle API call errors
            console.log('API call error:', error);
            // toast.error(error?.response?.data?.error);
            SetRequestSubmitLoader(false);
            handleErrorMessage(error);
        }
    }
};


  const storeUserData = (data) => {
    redux_dispatch({
      type: Actions.UPDATE_USER_DATA,
      payload: { user_data: { ...data } },
    });
  }
  const getUserDetails = async () => {
    try {
      const { user_id } = getDecodedToken()
      const response = await API.apiGet(`/users/profile?user_id=${user_id}`)
      if (response?.status) {
        const userProfile = response?.data?.data;
        storeUserData(userProfile)
        localStorage.setItem('perm', JSON.stringify(userProfile?.Permissions))
        sessionStorage.setItem('userData', JSON.stringify(userProfile));
      }
      else {
        toast.error(response?.message)
      }
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
    }
  }

  const validateFolderName = (index, value) => {
    if (!value) {
      setRaiseFormErrors({ ...raiseFormErrors, [`folder_name-${index}`]: 'Folder name is required' });
    } else {
      setRaiseFormErrors({ ...raiseFormErrors, [`folder_name-${index}`]: '' });
    }
  };

  const validateTaskType = (index, selectedOptions) => {
    if (!selectedOptions.length) {
      setRaiseFormErrors({ ...raiseFormErrors, [`task_type-${index}`]: 'Task type is required' });
    } else {
      setRaiseFormErrors({ ...raiseFormErrors, [`task_type-${index}`]: '' });
    }
  };

  const handleRemarkChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFields = [...folderFields];
    updatedFields[index]['remark'] = value;
    setFolderFields(updatedFields);
  };
  

  return (
    <div className={`flex dark:bg-dark-background updatePhone`}   >
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu headerName="Loan Document Storage"/>
      <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header/>
        {/*{dataLoading && <div className="absolute w-full h-full flex items-center top-25 left-12 justify-center" >
          <ColorRing
            visible={true}
            height="80"
            width="60"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={[]}
          />
        </div>} */}
        <div className={`z-0 p-10 mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'mainContainerForMobileDevice'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
          <div className="z-0">
            <Breadcrumb steps={steps} activeStep={1} showBackButton={true} redirectUrl={'/samdDepartment'} />
            <div className="flex flex-row justify-between items-center mt-4 gap-4 ">
              <div className="text-black-92  bg-white font-quicksand p-6 gap-2 rounded-lg border border-solid border-gray-border text-20 font-semibold leading-32 w-full">
                <h3 className="inline-block relative font-quicksand text-3xl mr-8 accnt-number">
                  {totalAccountDetails}
                </h3>
                <p className="text-black font-quicksand text-lg font-medium leading accnt-text">
                  Number of Folders
                </p>
              </div>

              <div className="text-black-92  bg-white font-quicksand p-6 gap-2 rounded-lg border border-solid border-gray-border text-20 font-semibold leading-32 w-full">
                <h3 className="inline-block relative font-quicksand text-3xl mr-8 document-nmber">
                  {totalDocumentsCount}
                </h3>
                <p className="text-black font-quicksand text-lg font-medium leading accnt-text">
                  Number of documents
                </p>
              </div>
            </div>

            {/* </div> */}
          </div>
          <div className={`flex py-4 ${!isMobileDevice && 'justify-end'}`}>
            <div className="flex flex-wrap flex-row-reverse responsiveButtons">
              <div className=''>
                <button
                  type="button"
                  className="createNewFolder p-3 rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn"
                  onClick={openModal}
                >
                  Create New Folder +
                </button>
              </div>
              {/* <div className="dropdown  w-60">
              <select value={selectedStatus} onChange={(e)=>handleStatus(e)} className='p-2 rounded-lg border w-11/12'>
                <option value="all" >All</option> 
                {options?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div> */}
              {!isMobileDevice && <div className='flex relative'>
                <span className={`absolute searchLogo`}>
                  <Icon iconName="searchIconBlue" height={20} width={20} fill={themeDetails?.color_config?.main_color} />
                </span>
                <input
                  type='text'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e)}
                  className='numberSearch'
                  value={searchData}
                />
              </div>}
              <div className='me-5'>
                <button
                  type="button"
                  className="raiseNewBtn raiseTaskBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn"
                  onClick={openRaiseModal}
                >
                  Raise Task +
                </button>
              </div>
            </div>

          </div>
          { isMobileDevice && <div className='flex relative mb-3'>
            <img src='assets/images/Vector.svg' className='mobileSearchLogo absolute' />
            <input
              type='text'
              placeholder='Search'
              onChange={(e) => handleSearch(e)}
              className='numberSearchResponsive'
              style={{width: 'auto'}}
              value={searchData}
            />
          </div>}
          <div>
            {isOpen && (
              <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                  <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                  />
                </div>}
                <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                  <div className={`modal-content rounded-lg bg-white border modalContainer createModal p-5 ${isMobileDevice && 'loadStorageModal'}`}>
                    <div className='ps-3 pe-3 pt-1'>
                      <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Create New Folder</p>
                    </div>
                    <div className='modalForm'>
                      <div className=' flex flex-col ps-3 pe-3 mt-4'>
                        <label htmlFor="account_number" className='numberRequestModal'>Account Number*</label>
                        <input
                          type="text"
                          id="inputA"
                          name="account_number"
                          value={Validation.alphanumeric(values.account_number)}
                          onChange={handleChange}
                          placeholder="Enter Account Number"
                          className={`py-2 rounded outline-none ps-2  ${formErrors?.account_number ? 'border border-red-500' : ' border'}`}
                        />
                        <span className='numberRequestModal' style={{ color: formErrors?.account_number ? 'red' : 'inherit' }}>{formErrors?.account_number}</span>
                      </div>

                      <div className=' flex flex-col ps-3 pe-3 mt-3'>
                        <label htmlFor="account_holder" className='numberRequestModal'>Account Holder Name*</label>
                        <input
                          type="text"
                          id="inputF"
                          name="account_holder"
                          value={values.account_holder}
                          onChange={handleChange}
                          placeholder='Enter Account Holder Name'
                          className={`py-2 rounded outline-none ps-2 ${formErrors?.account_holder ? 'border border-red-500' : ' border'}`}
                        />
                        <span className='numberRequestModal' style={{ color: formErrors?.account_holder ? 'red' : 'inherit' }}>{formErrors?.account_holder}</span>
                      </div>


                      <div className='flex justify-end mt-5 mb-3'>
                        <div className='flex justify-center align-middle me-2'>
                          <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary' onClick={closeModal}>Cancel</button>
                        </div>
                        <div className='flex justify-center align-middle me-3'>
                          <button disabled={requestSubmitLoader} type="submit" className='cursor-pointer relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2' onClick={handleSubmit}>
                            Create</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {isOpenRaiseModal && (
              <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                  <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                  />
                </div>}
                <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                  <div className={`modal-content rounded-lg bg-white border modalContainer createModal p-5 ${isMobileDevice && 'raiseTaskModal'}`}>
                    <div className='ps-3 pe-3 pt-1'>
                      <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Raise Task</p>
                    </div>
                    <div className='modalForm'>
                      <div className='flex flex-col ps-3 pe-3 mt-3'>
                        <label htmlFor="department" className='numberRequestModal'>Department Name*</label>
                        <select
                          id="department"
                          name="department"
                          value={formState.department}
                          onChange={handleInputChange}
                          onBlur={() => setRaiseFormErrors({ ...raiseFormErrors, department: '' })}
                          className={`py-2 rounded outline-none ps-2 ${formErrors?.department ? 'border border-red-500' : ' border'}`}
                        >
                          <option value="">Select Department</option>
                          {/* Populate dropdown options dynamically */}
                          {departments.map(department => (
                            <option key={department.id} value={department.name}>{department.name}</option>
                          ))}
                        </select>
                        {raiseFormErrors.department && <p className="text-red-500 text-sm">{raiseFormErrors.department}</p>}
                      </div>
                      <div className='flex flex-col ps-3 pe-3 mt-3'>
                        <label htmlFor="email" className='numberRequestModal'>Email Id*</label>
                        <Select
                            id="email"
                            name="email"
                            value={formState.email ? { value: formState.email, label: formState.email } : null}
                            onChange={(selectedOptions) => handleSelectEmail(selectedOptions)}
                            onBlur={() => setRaiseFormErrors({ ...raiseFormErrors, email: '' })}
                            options={userList.map(taskType => ({ value: taskType.email, label: taskType.email }))}
                            className={`pb-2 rounded outline-none ${formErrors?.email ? 'border border-red-500' : 'border border-0'}`}
                            placeholder="Select Email"
                        />
                        {raiseFormErrors.email && <p className="text-red-500 text-sm">{raiseFormErrors.email}</p>}
                    </div>
                      {folderFields.map((folderField, index) => (
                        <div className='p-3'>
                          <div key={index} className="border border-gray-300 rounded p-3 mt-3 relative">
                            {folderFields.length > 1 && (
                              <div onClick={() => handleRemoveFolderField(index)} className='absolute right-0 me-2 cursor-pointer pointer-events-auto'>
                                <img src='/assets/x.svg'></img>
                              </div>
                            )}
                            <div className='flex flex-col pb-3 mt-2'>
                              <label htmlFor={`department-${index}`} className='numberRequestModal'>Folder Name*</label>
                              <Select
                                id={`folder-${index}`}
                                name={`folder_name`}
                                value={folderField.folder_name ? { value: folderField.folder_name, label: folderField.folder_name } : null}
                                onChange={(selectedOptions) =>
                                  handleFolderFieldChange(selectedOptions, index)
                                }
                                onBlur={() => validateFolderName(index, folderField.folder_name)}
                                options={filterData.map(taskType => ({ value: taskType.id, label: taskType.folder_name }))}
                                className={`pb-2 rounded outline-none ${raiseFormErrors?.[`folder_name-${index}`] ? 'border border-0' : 'border border-0'}`}
                                placeholder="Select Folder Name"
                              />
                              {raiseFormErrors[`folder_name-${index}`] && <p className="text-red-500 text-sm">{raiseFormErrors[`folder_name-${index}`]}</p>}
                            </div>
                            <div className='flex flex-col'>
                              <label htmlFor={`task-type-${index}`} className='numberRequestModal'>Task Type*</label>
                              <Select
                                id={`task_type-${index}`}
                                name={`task_type-${index}`}
                                value={folderField.task_type.map(taskType => ({ value: taskType, label: taskType }))}
                                onChange={(selectedOptions) => {
                                  handleTaskTypeSelectChange(selectedOptions, index)
                                }}
                                onBlur={() => validateTaskType(index, folderField.task_type)}
                                options={taskTypes.map(taskType => ({ value: taskType.name, label: taskType.name }))}
                                isMulti
                                className={`pb-2 rounded outline-none ${raiseFormErrors?.[`task_type-${index}`] ? 'border border-0' : 'border border-0'}`}
                                placeholder="Select Task Type"
                              />
                              {raiseFormErrors[`task_type-${index}`] && <p className="text-red-500 text-sm">{raiseFormErrors[`task_type-${index}`]}</p>}
                            </div>
                            <div className=' flex flex-col mt-3 mb-2'>
                              <label htmlFor="remark" className='numberRequestModal'>Remark*</label>
                              <input
                                type="text"
                                id={`remark-${index}`}
                                name={`remark-${index}`}
                                value={folderField.remark}
                                onChange={(event) => handleRemarkChange(event, index)}
                                placeholder='Enter Remark'
                                onBlur={() => setRaiseFormErrors({ ...raiseFormErrors, [`remark-${index}`]: '' })}
                                className={`py-2 rounded outline-none ps-2 ${raiseFormErrors?.[`remark-${index}`] ? 'border border-100' : 'border'}`}
                              />
                              {raiseFormErrors[`remark-${index}`] && <p className="text-red-500 text-sm">{raiseFormErrors[`remark-${index}`]}</p>}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className={`flex mt-5 mb-3 ${isMobileDevice ? 'justify-center' : 'justify-end'}`}>
                        <div className='flex justify-center align-middle me-2'>
                          <button type="button" className={`border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary ${isMobileDevice && 'raiseTaskButton'}`} onClick={closeRaiseModal}>Cancel</button>
                        </div>
                        <div className='flex justify-center align-middle me-3'>
                          <button className={`relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2 ${isMobileDevice && 'raiseTaskButton'}`} onClick={handleSubmitRaise} disabled={requestSubmitLoader}>
                            Raise
                          </button>
                        </div>
                        <div className='flex justify-center align-middle me-3'>
                          <button className={`relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2 ${isMobileDevice && 'raiseTaskButton'}`} onClick={handleAddFolderField}>
                            Add More +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isMobileDevice ? (
            // <DataTable columns={phoneTableHeader} rowData={filterData} tableLoading={dataLoading} />
            <FolderListTableComponent
              columns={phoneTableHeader}
              rowData={currentRecords}
              tableLoading={dataLoading}
              nPages={nPages}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              openFolder={openFolder}
              getFolderUpdateDetails={getFolderUpdateDetails}
              
            />
          ) : (
            <div>
              <div>
                {currentRecords?.map((item, index) => (
                  <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Folder Name</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'} newFolder flex items-center cursor-pointer`} onClick={() => openFolder(item)}>
                          <img src={fileNameLogo} alt="folderIcon" className='mr-1' />
                          {item?.folder_name}
                        </span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Account Number</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.account_number}</span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Account Holder</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.account_holder}</span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Created By</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.User?.full_name}</span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Created At</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.created_at ? moment(item?.created_at)?.format('YYYY-MM-DD | HH:mm:ss') : '--'}</span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Last Updated By</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.User?.full_name}</span>
                      </div>
                    </div>
                    <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                      <span className="font-bold">Last Updated At</span>
                      <div className="flex items-center justify-between">
                        <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.updated_at ? moment(item?.updated_at)?.format('YYYY-MM-DD | HH:mm:ss') : '--'}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="reponsivePagination">
                <Pagination nPages={nPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default WithAuth(LoanStorage);
