import React, { useEffect, useCallback, useState, useRef } from "react";
import axios from "axios";
import Peer from "simple-peer";
import { useSocket } from "../helpers/socketProvider";
import { useLocation } from "react-router-dom";
import "../styles/roomPage.scss";
import HumanVideoKyc from "./HumanVideoKyc";
import { Spin } from "antd";
import CallInQueues from "../screen/services/humanVideoKyc/CallInQueues";
import styles from "../../src/styles/humanVideoKyc.module.scss";
import { useSelector } from "react-redux";
import Icon from "./core-component/Icon";
import API from "../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { encodeData } from "../common/commonFunction";
import videoCall from "../../src/assets/images/selectedUserProfile.jpg";
import ThankYouScreen from "./ThankYouScreen";

const RoomPage = () => {
  const socket = useSocket();
  const location = useLocation();
  const isAgent = location.state?.role === "agent";
  const roomId = location.state?.room;
  const socketId = location.state?.id;
  const markedInTime = location.state?.markedInTime;
  const [myStream, setMyStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [saveAgentRecordingLoading, setSaveAgentRecordingLoading] = useState(false);
  const [saveCustomerRecordingLoading, setSaveCustomerRecordingLoading] = useState(false);
  const [saveScreenShots, setSaveScreenShots] = useState(false);
  const [isCustomerRecording, setIsCustomerRecording] = useState(false);
  const [isRemoteAgentRecording, setIsRemoteAgentRecording] = useState(false);
  const [customerMediaRecorder, setCustomerMediaRecorder] = useState(null);
  const [remoteAgentMediaRecorder, setRemoteAgentMediaRecorder] = useState(null);
  const [customerRecordedChunks, setCustomerRecordedChunks] = useState([]);
  const [remoteAgentRecordedChunks, setRemoteAgentRecordedChunks] = useState([]);
  const [agentMediaRecorder, setAgentMediaRecorder] = useState(null);
  const [agentRecordedChunks, setAgentRecordedChunks] = useState([]);
  const [isAgentRecording, setIsAgentRecording] = useState(false);
  const [snapshots, setSnapshots] = useState([]);
  const [incomingCallModal, setIncomingCallModal] = useState(false);
  const [incomingCallData, setIncomingCallData] = useState(null);
  const [loadingIncoming, setLoadingIncoming] = useState(false);
  const [streamReady, setStreamReady] = useState(false);
  const [isCallEnded, setIsCallEnded] = useState(false);
  const [isCallRejected, setIsCallRejected] = useState(false);
  const [dataIsSubmitted, setDataIsSubmitted] = useState(false);
  const [isVideoAccess, setIsVideoAccess] = useState(true);
  const [isMicAccess, setIsMicAccess] = useState(true);
  const [peerInstance, setPeerInstance] = useState(null);
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
  const [callStats, setCallStats] = useState({call_received_at: '', call_ended_at: '', call_duration: '', call_status: 'Pending'})

  const myVideoRef = useRef();
  const remoteVideoRef = useRef();
  const canvasRef = useRef();

  const handleUserJoined = useCallback(({ email, id, room }) => {
    console.log(`Email ${email} joined room ${room}`);
  }, []);

  const markAgentLive = () => {
    let agentData = sessionStorage.getItem('userData');
    agentData = JSON.parse(agentData);
    if (agentData) {
      socket.emit("user:agentJoin", { email: agentData?.email});
    }
  }

  const saveAgentCallRecording = () => {
    const chunks = [];
    if (myStream) {
      myStream.getTracks().forEach(track => track.stop());
      setMyStream(null);
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
      setRemoteStream(null);
    }
    if (!isAgent) {
      
    } else {
      if (isAgentRecording && agentMediaRecorder) {
        agentMediaRecorder.stop();
        setIsAgentRecording(false);
        agentMediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };
      
        agentMediaRecorder.onstop = () => {
          const recordedBlob = new Blob(chunks, { type: 'video/webm' });
          handleAgentRecordingComplete(recordedBlob);
        };
      }
    }
  }

  const handleCustomerRecordingComplete = useCallback((blob) => {
    setSaveCustomerRecordingLoading(true);
    console.log('Recording complete of customer! Blob:', blob);
    let localRecordId = sessionStorage.getItem('incomingCallData');
    localRecordId = JSON.parse(localRecordId)
    const recordId = location.state.recordId ? location.state.recordId : localRecordId?.recordId;
    const formData = new FormData();
    formData.append('customer_video', blob, 'customer_video.mp4');
    formData.append('id', recordId);
    
    axios.post(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videocalling/customer/recording`, formData)
      .then(response => {
        setSaveCustomerRecordingLoading(false);
        console.log('Recording uploaded successfully:', response);
      })
      .catch(error => {
        setSaveCustomerRecordingLoading(false);
        console.error('Error uploading recording:', error);
      });
  }, []);

  const handleRemoteAgentRecordingComplete = useCallback((blob) => {
    setSaveAgentRecordingLoading(true);
    console.log('Recording complete of remote agent! Blob:', blob);
    const formData = new FormData();
    formData.append('agent_video', blob, 'agent_video.mp4');
    formData.append('id', location?.state?.recordId);

    axios.post(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videocalling/agent/recording`, formData)
      .then(response => {
        setSaveAgentRecordingLoading(false);
        sessionStorage.removeItem('incomingCallData');
        console.log('Recording uploaded successfully:', response);
      })
      .catch(error => {
        setSaveAgentRecordingLoading(false);
        console.error('Error uploading recording:', error);
      });
  }, [])

  const handleAgentRecordingComplete = useCallback((blob) => {
    setSaveAgentRecordingLoading(true);
    console.log('Recording complete of agent! Blob:', blob);
    let localRecordId = sessionStorage.getItem('incomingCallData');
    localRecordId = JSON.parse(localRecordId)
    let recordId = localRecordId?.recordId
    const formData = new FormData();
    formData.append('agent_video', blob, 'agent_video.mp4');
    formData.append('id', recordId);

    axios.post(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videocalling/agent/recording`, formData)
      .then(response => {
        setSaveAgentRecordingLoading(false);
        sessionStorage.removeItem('incomingCallData');
        console.log('Recording uploaded successfully:', response);
      })
      .catch(error => {
        setSaveAgentRecordingLoading(false);
        console.error('Error uploading recording:', error);
      });
  }, []);

  const startRemoteAgentRecording = useCallback((stream) => {
    console.log('Remote agent recording started >>>>');
    const recorder = new MediaRecorder(stream);
    const chunks = [];

    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    recorder.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: 'video/webm' });
      handleRemoteAgentRecordingComplete(recordedBlob);
    };

    recorder.start();
    setRemoteAgentMediaRecorder(recorder);
    setIsRemoteAgentRecording(true);
    setRemoteAgentRecordedChunks([]); // Clear previous chunks before starting new recording
  }, [handleRemoteAgentRecordingComplete])

  const startCustomerRecording = useCallback((stream) => {
    console.log('customer recording started >>>>');
    const recorder = new MediaRecorder(stream);
    const chunks = [];

    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    recorder.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: 'video/webm' });
      handleCustomerRecordingComplete(recordedBlob);
    };

    recorder.start();
    setCustomerMediaRecorder(recorder);
    setIsCustomerRecording(true);
    setCustomerRecordedChunks([]); // Clear previous chunks before starting new recording
  }, [handleCustomerRecordingComplete]);

  const startAgentRecording = useCallback((stream) => {
    console.log('agent recording started >>>>>');
    const recorder = new MediaRecorder(stream);
    const chunks = [];

    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    recorder.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: 'video/webm' });
      handleAgentRecordingComplete(recordedBlob);
    };

    recorder.start();
    setAgentMediaRecorder(recorder);
    setIsAgentRecording(true);
    setAgentRecordedChunks([]); // Clear previous chunks before starting new recording
  }, [handleAgentRecordingComplete]);

  const handleCallUser = useCallback(async () => {
    if (isAgent) {
      markAgentLive();
      return;
    }; // Only the customer should initiate the call

    console.log("Starting call...");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true, video: true,
      });
      setMyStream(stream);

      const peer = new Peer({ initiator: true, trickle: false, stream });

      peer.on('signal', (offer) => {
        const agentEmail = location.state.agentEmail;
        const customerEmail = location.state.customerEmail;
        const recordId = location.state.recordId;
        socket.emit("user:call", { to: agentEmail, offer, room: roomId, customerEmail, recordId });
      });

      peer.on('stream', (stream) => {
        setRemoteStream(stream);
        startRemoteAgentRecording(stream);
      });

      socket.on('call:accepted', ({ answer }) => {
        peer.signal(answer);
      });

      // startCustomerRecording(stream);
    } catch (error) {
      console.log("Error initiating call:", error);
    }
  }, [socket, startCustomerRecording, startRemoteAgentRecording, isAgent, socketId, roomId, location.state]);

  useEffect(() => {
    if (socketId) {
      handleCallUser();
    }
  }, [socketId, handleCallUser, isAgent]);

  const handleIncomingCall = useCallback(async ({ from, offer, room, customerEmail, recordId }) => {
    if (!isAgent) return; // Only the agent should receive incoming calls

    console.log("Incoming call from:", from);
    setIncomingCallData({ from, offer, room, customerEmail, recordId });
    sessionStorage.setItem('incomingCallData', JSON.stringify({ from, offer, room, customerEmail, recordId }));
    if (!incomingCallData) {
      setIncomingCallModal(true);
    }
  }, [isAgent]);

  const handleSolve = async () => {
    handleAcceptCall();
    socket.on("user:joined", handleUserJoined);
    socket.on("incoming:call", handleIncomingCall);
  }

  const toggleMicAccess = async () => {
    const newMicAccess = !isMicAccess;
    setIsMicAccess(newMicAccess);
  
    if (!myStream || !myStream.getAudioTracks().length) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: newMicAccess });
        stream.getAudioTracks().forEach(track => {
          track.enabled = newMicAccess;
        });
        const audioTrack = stream.getAudioTracks()[0];
        const combinedStream = new MediaStream([...stream.getTracks(), ...myStream.getVideoTracks()]);
        setMyStream(combinedStream);
        if (myVideoRef.current) {
          myVideoRef.current.srcObject = combinedStream;
        }
        if (newMicAccess) {
          // Add video track if not already added
          if (!peerInstance.streams[0].getAudioTracks().includes(audioTrack)) {
            peerInstance.addTrack(audioTrack, peerInstance.streams[0]);
          }
        } else {
          // Remove video track if exists
          const sender = peerInstance?.peerConnection?.getSenders()?.find(s => s.track === audioTrack);
          if (sender) {
            peerInstance.peerConnection.removeTrack(sender);
          }
        }
      } catch (err) {
        console.error("Failed to get audio media", err);
      }
    } else {
      myStream.getAudioTracks().forEach(track => {
        track.enabled = newMicAccess;
        if (newMicAccess) {
          // Add video track if not already added
          if (!peerInstance.streams[0].getAudioTracks().includes(track)) {
            peerInstance.addTrack(track, peerInstance.streams[0]);
          }
        } else {
          // Remove video track if exists
          const sender = peerInstance?.peerConnection?.getSenders()?.find(s => s.track === track);
          if (sender) {
            peerInstance.peerConnection.removeTrack(sender);
          }
        }
      });
    }
  };

  const toggleVideoAccess = async () => {
    const newVideoAccess = !isVideoAccess;
    setIsVideoAccess(newVideoAccess);
  
    if (!myStream || !myStream.getVideoTracks().length) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: newVideoAccess });
        stream.getVideoTracks().forEach(track => {
          track.enabled = newVideoAccess;
        });
        const videoTrack = stream.getVideoTracks()[0];
        const combinedStream = new MediaStream([...myStream.getAudioTracks(), ...stream.getTracks()]);
        setMyStream(combinedStream);
        if (myVideoRef.current) {
          myVideoRef.current.srcObject = combinedStream;
        }
        if (newVideoAccess) {
          // Add video track if not already added
          if (!peerInstance.streams[0].getVideoTracks().includes(videoTrack)) {
            peerInstance.addTrack(videoTrack, peerInstance.streams[0]);
          }
        } else {
          // Remove video track if exists
          const sender = peerInstance.peerConnection.getSenders().find(s => s.track === videoTrack);
          if (sender) {
            peerInstance.peerConnection.removeTrack(sender);
          }
        }
      } catch (err) {
        console.error("Failed to get video media", err);
      }
    } else {
      myStream.getVideoTracks().forEach(track => {
        track.enabled = newVideoAccess;
        if (newVideoAccess) {
          // Add video track if not already added
          if (!peerInstance.streams[0].getVideoTracks().includes(track)) {
            peerInstance.addTrack(track, peerInstance.streams[0]);
          }
        } else {
          // Remove video track if exists
          const sender = peerInstance?.peerConnection?.getSenders()?.find(s => s.track === track);
          if (sender) {
            peerInstance.peerConnection.removeTrack(sender);
          }
        }
      });
    }
  };

  const handleAcceptCall = useCallback(async () => {
    setCallStats((prev) => ({
      ...prev,
      call_received_at: new Date().toUTCString()
    }))
    setIncomingCallModal(false);
    setLoadingIncoming(true);
    let agentData = sessionStorage.getItem('userData');
    agentData = JSON.parse(agentData);
    const { from, offer, room, customerEmail } = incomingCallData;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: isMicAccess,
        video: isVideoAccess,
      });
      setMyStream(stream);

      const peer = new Peer({ initiator: false, trickle: false, stream });

      peer.on('signal', (answer) => {
        socket.emit("call:accept", { to: customerEmail, answer, roomId: room, agentEmail: agentData?.email });
      });

      peer.signal(offer);

      peer.on('stream', (stream) => {
        setRemoteStream(stream);
        startCustomerRecording(stream);
      });

      startAgentRecording(stream);
      setPeerInstance(peer);
      setStreamReady(true);
      setLoadingIncoming(false);
    } catch (error) {
      console.log("Error answering call:", error);
      setLoadingIncoming(false);
    }
  }, [socket, startAgentRecording, isAgent, socketId, incomingCallData]);

  const handleRejectCall = useCallback(async() => {
    console.log(`${isAgent ? 'agent' : 'customer'} rejecting the call`);
    setIncomingCallModal(false);
    let localIncommingData = sessionStorage.getItem('incomingCallData');
    localIncommingData = JSON.parse(localIncommingData);
    let agentData = sessionStorage.getItem('userData'); 
    agentData = JSON.parse(agentData);
    socket.emit("call:reject", { to: localIncommingData?.customerEmail, roomId: localIncommingData?.room, agentEmail: agentData?.email });
    try {
      let localRecordId = sessionStorage.getItem('incomingCallData');
      localRecordId = JSON.parse(localRecordId)
      const recordId = location?.state?.recordId ? location?.state?.recordId : localRecordId?.recordId;
      let payload = {
        id: recordId,
        callStats: {
          call_received_at: '',
          call_ended_at: '',
          call_duration: '0s',
          call_status: 'Rejected'
        }
      }
      const { data } = await API.apiPost(`videocalling/update/call/stats`, {payload: encodeData(payload)});
      if (data?.success) {
        toast.error('Call Rejected');
      }
    } catch (error) {
      console.log('error while rejecting the call >>>>', error)
    }
  }, []);

  const handleRejectedCall = useCallback(({from, roomId}) => {
    if (isCustomerRecording && customerMediaRecorder) {
      customerMediaRecorder.stop();
      setIsCustomerRecording(false);
    }

    if (myStream) {
      myStream.getTracks().forEach(track => track.stop());
      setMyStream(null);
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
      setRemoteStream(null);
    }
    setIsCallRejected(true);
    console.log(`call ended in room >>>> ${roomId} by ${from}`);
    
  }, [socket, socketId, myStream, remoteStream, isAgent])

  const handleEndCall = useCallback(() => {
    setIsCallEnded(true);
    saveAgentCallRecording();
    let agentData = sessionStorage.getItem('userData');
    agentData = JSON.parse(agentData);
    const customerEmail = location.state.customerEmail;
    const agentEmail = location.state.agentEmail;
    socket.emit("call:end", { agentEmail: isAgent ? agentData?.email : agentEmail, customerEmail: isAgent ? incomingCallData?.customerEmail : customerEmail, roomId: isAgent ? incomingCallData?.room : roomId });
    if (dataIsSubmitted) {
      setStreamReady(false);
    }
    console.log(`${isAgent ? 'Agent' : 'Customer'} ending the call`);
  }, [isCustomerRecording, customerMediaRecorder, isAgentRecording, agentMediaRecorder, myStream, remoteStream, socketId, socket]);

  const handleCapture = () => {
    const video = remoteVideoRef.current;
    const canvas = canvasRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL("image/png");
    setSnapshots((prevSnapshots) => [...prevSnapshots, dataUrl]);
  };

  const saveCallStats = async() => {
    try {
      setSaveScreenShots(true);
      let callDuration = getDuration(callStats?.call_received_at, new Date().toUTCString())
      setCallStats((prev) => ({
        ...prev,
        call_ended_at: new Date().toUTCString(),
        call_duration: callDuration,
        call_status: 'Completed'
      }))
      let payload = {
        id: isAgent ? incomingCallData?.recordId : location?.state?.recordId,
        callStats: {
          call_received_at: callStats?.call_received_at,
          call_ended_at: new Date().toUTCString(),
          call_duration: callDuration,
          call_status: 'Completed'
        }
      }
      const encodedpayload = encodeData(payload)
      const { data } = await API.apiPost(`videocalling/update/call/stats`, {payload: encodedpayload})
      if (data?.success) {
        setSaveScreenShots(false);
      } else {
        setSaveScreenShots(false);
      }
    } catch (error) {
      setSaveScreenShots(false);
      console.log('error white saving call stats >>>>', error)
    }
  }

  const handleCallEnded = useCallback(({from}) => {
    console.log('agent call ended');
    setIsCallEnded(true);
    if (isAgent) {
      saveCallStats();
    }
    if (isAgentRecording && agentMediaRecorder) {
      agentMediaRecorder.stop();
      setIsAgentRecording(false);
    }

    if (myStream) {
      myStream.getTracks().forEach(track => track.stop());
      setMyStream(null);
    }

    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
      setRemoteStream(null);
    }
    console.log(`<<<< Call Ended of agent in ${from} >>>>`);
  }, [socket, socketId, myStream, remoteStream, isAgent]);

  useEffect(() => {
    socket.on("user:joined", handleUserJoined);
    socket.on("incoming:call", handleIncomingCall);
    socket.on("call:rejected", handleRejectedCall);
    socket.on("call:ended", handleCallEnded);

    return () => {
      socket.off("user:joined", handleUserJoined);
      socket.off("incoming:call", handleIncomingCall);
      socket.off("call:rejected", handleRejectedCall);
      socket.off("call:ended", handleCallEnded);
    };
  }, [socket, handleUserJoined, handleIncomingCall, handleCallEnded, handleRejectedCall]);

  useEffect(() => {
    if (myVideoRef.current && myStream) {
      myVideoRef.current.srcObject = myStream;
    }
  }, [myStream]);
  
  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const getDuration = (startTime, endTime) => {
    const receivedAt = new Date(startTime);
    const endedAt = new Date(endTime);
    const totalTime = [];
    const duration = endedAt.getTime() - receivedAt.getTime();

    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));

    if (days > 0) {
      totalTime.push(`${days}d`);
    }
    if (hours > 0) {
      totalTime.push(`${hours}h`);
    }
    if (minutes > 0) {
      totalTime.push(`${minutes}m`);
    }
    if (seconds > 0) {
      totalTime.push(`${seconds}s`);
    }
    if (totalTime?.length > 0) {
      return totalTime.join(', ');
    } else {
      return '0s';
    }
  }
  
  return (
    <div>
      {
      incomingCallModal && isAgent &&
      (
        <div id="static-modal" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
          <div className={`relative p-4 w-1/3 max-w-2xl max-h-full`}>
            <div className={`${styles.callModal}  relative bg-white rounded-lg shadow dark:bg-gray-700`}>
              <div className="flex items-center justify-center p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Incoming call
                </h3>
                {/* <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" 
                onClick={handleRejectCall}
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button> */}
              </div>
              <div className="p-4 md:p-5 space-y-4">
                
                <div className={`flex justify-center`}>
                  <div>
                    <img className={`${styles.videoImg}`} src={videoCall}></img>
                  </div>
                </div>

                  <div className={`flex items-center`}>
                  {isMicAccess ? 
                    <div onClick={toggleMicAccess} className={`${styles.microphoneButton} flex items-center justify-center p-1`}>
                      <Icon iconName="micAllowed" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                    </div>
                    :
                    <div onClick={toggleMicAccess} className={`${styles.microphoneButton} flex items-center justify-center p-1`}>
                      <Icon iconName="micNotAllowed" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                    </div>
                  }
                  <label className={`ms-5`}>Allow Microphone Access</label>
                </div>
                <div className={`flex items-center`}>
                  {isVideoAccess ?
                    <div onClick={toggleVideoAccess} className={`${styles.videoButton} flex items-center justify-center p-1`}>
                      <Icon iconName="videoAllowed" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                    </div>
                    :
                    <div onClick={toggleVideoAccess} className={`${styles.microphoneButton} flex items-center justify-center p-1`}>
                      <Icon iconName="videoNotAllowed" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                    </div>
                  }
                  <label className={`ms-5`}>Allow Camera Access</label>
                </div>
                <div className="flex justify-center gap-5">
                  <button className="text-white bg-red-500  border px-3 py-2 rounded" 
                  onClick={handleRejectCall}
                  >
                    Reject
                  </button>
                  <button 
                  onClick={handleSolve} 
                  className="bg-green-600 text-white px-3 py-2 rounded ms-2">
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {socketId ?
        <>
          {isAgent ?
            streamReady ?
              <HumanVideoKyc
                myVideoRef={myVideoRef}
                remoteVideoRef={remoteVideoRef}
                isAgent={isAgent}
                handleCapture={handleCapture}
                snapshots={snapshots}
                canvasRef={canvasRef}
                handleEndCall={handleEndCall}
                loadingIncoming={loadingIncoming}
                streamReady={streamReady}
                setStreamReady={setStreamReady}
                isCallEnded={isCallEnded}
                isMicAccess={isMicAccess}
                isVideoAccess={isVideoAccess}
                toggleMicAccess={toggleMicAccess}
                toggleVideoAccess={toggleVideoAccess}
                incomingCallData={incomingCallData}
                setDataIsSubmitted={setDataIsSubmitted}
                saveAgentRecordingLoading={saveAgentRecordingLoading}
                saveCustomerRecordingLoading={saveCustomerRecordingLoading}
                saveScreenShots={saveScreenShots}
              />
            :
              <CallInQueues 
                markedInTime={markedInTime}
              />
            :
            !isCallEnded && !isCallRejected ?
              <div className={`room-page flex`}>
                <div className={`flex`}>
                  <Spin spinning={loadingIncoming}>
                    <div className={`customerVideoCall`}>
                      <video id="customer_video" ref={myVideoRef} autoPlay muted />
                    </div>
                  </Spin>
                  <Spin spinning={loadingIncoming}>
                    <div className={`customerVideoCall`}>
                      <video id="agent_video" ref={remoteVideoRef} autoPlay />
                    </div>
                  </Spin>
                </div>
                <div onClick={handleEndCall} className={`${styles.callCutButton} flex justify-center px-2 mt-3`}>
                  <button className={`font-bold px-3 py-3`} >
                    Hang Up
                  </button>
                </div>
              </div>
              :
              <div className={`room-page flex`}>
                {isCallRejected ?
                  <div className={`flex flex-col`}>
                    <Icon iconName="callReject" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                    <h2 className={`text-red-600 text-xl`}>Call Rejected by agent!</h2>
                  </div>
                :
                  <ThankYouScreen />
                }
              </div>
          }
        </>
        :
        !isAgent ?
          <div className={`room-page`}>
            Agent is busy, please try after some time
          </div>
          :
          <HumanVideoKyc />
      }
      <ToastContainer/>
    </div>
  );
};

export default RoomPage;
