import { DatePicker } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function CreateLoanModal({loanTypeList, showLoading, setShowModal}) {
    const navigate = useNavigate();
    const [selectedLoanType, setSelectedLoanType] = useState('');

    const handleCreateLoan = () => {
        if (selectedLoanType != '') {
            setShowModal(false);
            navigate(`/createLoanConfig?loan_id=${selectedLoanType}&page=create`);
        }
    }

    return (
        <>
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                        <div className='p-4'>
                            <label htmlFor="requested_page" className='font-medium' >Loan Type</label>
                            <select
                                id="loan_type"
                                name="loan_type"
                                value={selectedLoanType}
                                className={`py-2 rounded outline-none ps-2 border w-full mt-2`}
                                onChange={(e) => setSelectedLoanType(e.target.value)}
                            >
                                <option hidden value="">Select an Option</option>
                                {loanTypeList?.map(loanType => (
                                    <option value={loanType?.id}>{loanType?.plan_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" className="border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary" onClick={() => setShowModal(false)} >
                                Cancel
                            </button>
                            <button data-modal-hide="static-modal" type="button" className="ms-2 flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn custom_btn_primary" onClick={() => handleCreateLoan()} >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}