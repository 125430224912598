import { DatePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

const AddNewLoanEntryModal = (props) => {
    const { 
        showModal, 
        setShowModal,
        isMobileDevice,
        loanDetailsData,
        handleSubmitPayment,
        setErrors,
        errors,
        setInputFields,
        inputFields,
        addEntryLoading,
    } = props

    const handleModal = () => {
        setShowModal(false);
        setErrors({});
    }

    const getValidYears = (current) => {
        const startDate = moment(loanDetailsData?.start_date);
        const endDate = moment(loanDetailsData?.end_date);
        return startDate.year() > current.year() || endDate.year() < current.year();
      };
    
    const getValidMonths = (current) => {
        // return current && current > moment().endOf('month');
    }

    const handleChange = (e, index, name) => {
        let values = [...inputFields];
        let currentValue;
        let keyName
        if (name && (['Committed Date', 'Year', 'Month', 'Payment Date']?.includes(name))) {
            currentValue = e
            keyName = name
        } else {
        currentValue = e.target.value
        keyName = e.target.name
        }
        values[index] = { ...values[index], value: currentValue };
        setInputFields(values);
        setErrors((prev) => ({
            ...prev,
            [keyName]: ''
        }));
    };

    return (
       <>
         {showModal && (
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                {addEntryLoading && 
                <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                    <ColorRing
                        visible={true}
                        height="80"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[]}
                    />
                </div>}
                <div className="modal absolute" style={addEntryLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                    <div className={`modal-content rounded-lg bg-white border modalContainer createModalConsumer p-5 ${isMobileDevice && 'raiseTaskModal'}`}>
                        <div className='ps-3 pe-3 pt-1'>
                            <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Create Consumer Finance</p>
                        </div>
                        <div className="modalForm">
                            <div className="flex-col mt-5 mb-3">
                                <form onSubmit={handleSubmitPayment}>
                                    <div className="grid grid-cols-3 gap-4">
                                        {inputFields?.map((field, index) => (
                                            !isMobileDevice ? 
                                            <div key={index} className={`mb-1 w-full flex flex-col ${field?.name == 'Month' && 'col-span-2'} ${field?.name == 'Remark' && 'col-span-3'}`}>
                                                <h2 className="w-full mr-2 mb-1" htmlFor={field.name}>
                                                    {field.name}
                                                    {field.name!== 'Remark' && <span className="text-red-500">*</span>}
                                                </h2>
                                                {field?.type == 'date' ?
                                                    <DatePicker
                                                        id="inputC"
                                                        name={field?.name}
                                                        value={field?.value}
                                                        picker={field?.picker}
                                                        onChange={(e) => handleChange(e, index, field?.name)}
                                                        disabledDate={field?.name == 'Year' ? getValidYears : getValidMonths}
                                                        placeholder={`Select ${field.name}`}
                                                        className={`py-1 rounded outline-none ps-2 ${field?.name == 'Month' && 'w-1/2'} ${errors?.[field?.name]
                                                            ? "border border-red-500"
                                                            : " border"
                                                        }`}
                                                    />
                                                : field?.type != 'textarea' && field?.type != 'dropdown' ?
                                                    <input
                                                        type={field.type}
                                                        placeholder={`Enter a ${field.name}`}
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={(e) => handleChange(e, index)}
                                                        className="w-full border rounded-md border-input-field p-1 text-black font-medium py-2"
                                                    />
                                                    : field?.type == 'dropdown' ?
                                                    <div className="">
                                                        <select
                                                            id="requested_page"
                                                            name={field?.name}
                                                            value={field?.value}
                                                            className={`py-2 rounded outline-none ps-2 border w-full`}
                                                            onChange={(e) => handleChange(e, index)} // Disabling onChange event
                                                        >
                                                            <option hidden value="">Select an Option</option>
                                                            <option value="SS">SS (Sub Standard)</option>
                                                            <option value="DF">DF (Doubtful)</option>
                                                            <option value="SMA">SMA (Special Mention Account)</option>
                                                            <option value="UC">UC (Unclassifed)</option>
                                                            <option value="BL">BL (Bad/Loss)</option>
                                                        </select>
                                                    </div>
                                                :
                                                    <textarea 
                                                        className={`w-full border rounded-md border-input-field p-1 text-black font-medium py-2`}
                                                        type="text" 
                                                        id={field.name} 
                                                        name={field.name} 
                                                        value={field.value} 
                                                        onChange={(e) => handleChange(e, index)} 
                                                        placeholder={`Enter a ${field.name}`}
                                                    />
                                                }
                                                {errors[field.name] && (
                                                    <p className="text-red-500">{errors[field.name]}</p>
                                                )}
                                            </div>:
                                            <div key={index} className={`mb-1 w-full flex flex-col col-span-12 col-span-12`}>
                                            <h2 className="w-full mr-2 mb-1" htmlFor={field.name}>
                                                {field.name}
                                                {field.name!== 'Remark' && <span className="text-red-500">*</span>}
                                            </h2>
                                            {field?.type == 'date' ?
                                                <DatePicker
                                                    id="inputC"
                                                    name={field?.name}
                                                    value={field?.value}
                                                    picker={field?.picker}
                                                    onChange={(e) => handleChange(e, index, field?.name)}
                                                    disabledDate={field?.name == 'Year' ? getValidYears : getValidMonths}
                                                    placeholder={`Select ${field.name}`}
                                                    className={`py-1 rounded outline-none ps-2 w-full ${errors?.[field?.name]
                                                        ? "border border-red-500"
                                                        : " border"
                                                    }`}
                                                />
                                            : field?.type != 'textarea' && field?.type != 'dropdown' ?
                                                <input
                                                    type={field.type}
                                                    placeholder={`Enter a ${field.name}`}
                                                    id={field.name}
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={(e) => handleChange(e, index)}
                                                    className="w-full border rounded-md border-input-field p-1 text-black font-medium py-2"
                                                />
                                                : field?.type == 'dropdown' ?
                                                <div className="">
                                                    <select
                                                        id="requested_page"
                                                        name={field?.name}
                                                        value={field?.value}
                                                        className={`py-2 rounded outline-none ps-2 border w-full`}
                                                        onChange={(e) => handleChange(e, index)} // Disabling onChange event
                                                    >
                                                        <option hidden value="">Select an Option</option>
                                                        <option value="SS">SS (Sub Standard)</option>
                                                        <option value="DF">DF (Doubtful)</option>
                                                        <option value="SMA">SMA (Special Mention Account)</option>
                                                        <option value="UC">UC (Unclassifed)</option>
                                                        <option value="BL">BL (Bad/Loss)</option>
                                                    </select>
                                                </div>
                                            :
                                                <textarea 
                                                    className={`w-full border rounded-md border-input-field p-1 text-black font-medium py-2`}
                                                    type="text" 
                                                    id={field.name} 
                                                    name={field.name} 
                                                    value={field.value} 
                                                    onChange={(e) => handleChange(e, index)} 
                                                    placeholder={`Enter a ${field.name}`}
                                                />
                                            }
                                            {errors[field.name] && (
                                                <p className="text-red-500">{errors[field.name]}</p>
                                            )}
                                        </div>
                                        ))}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="flex justify-end w-full cunFinActionButtons">
                            <button 
                                type="button" 
                                className={`custom_btn_secondary font-bold py-2 px-4 rounded w-1/4 ${addEntryLoading ? 'disableCancleButton' : 'commonCancelButton'}`} 
                                onClick={() => handleModal()}
                                disabled={addEntryLoading}
                            >
                                Cancel
                            </button>
                            <button 
                                type="submit" 
                                onClick={(e) => handleSubmitPayment(e)} 
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/4 ms-3 commonBtn custom_btn_primary ${addEntryLoading ? 'disableSubmitButton' : ''}`}
                                disabled={addEntryLoading}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
        <ToastContainer></ToastContainer>
       </>
    )
}

export default AddNewLoanEntryModal;