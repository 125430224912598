import React, { useState } from 'react';
import Pagination from "../../component/Pagination";
import "../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';

export default function ErrorLogsTable({ columns, rowData, tableLoading, nPages, handlePageChange, currentPage, recordsPerPage }) {
  const currentRecords = rowData
  const [openScreenShot, setOpenScreenShot] = useState()
  const getScreenShotURL = (item) => {
    setOpenScreenShot(item.signedUrl)
  }

  const closeScreenShot = () => {
    setOpenScreenShot('')
  }

  return (
    <div className={`${'reconcilitaion-main-container mt-5'}`}>
        {openScreenShot && 
            <div className="modal-content absolute rounded-lg bg-white border modalContainer createLinkErrorShot p-5">
                <div className='flex flex-row-reverse'>
                    <img src="/assets/images/closeErrorFrame.png" className='w-7 cursor-pointer' onClick={()=>closeScreenShot()}></img>
                </div>
                <div className='errorScreenShot'><img src={openScreenShot}></img></div>
            </div>
        }
      <div className="reconcilitionTable-container">
        {currentRecords?.length > 0 ?
            <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
            <thead>
                <tr className="ps-2 pe-2">
                {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4" style={item.title === 'Error screenshot' ? { width: item?.width, fontSize: '16px',textAlign: 'center' } : { width: item?.width, fontSize: '16px' }}>
                    {item?.title}
                    </th>
                )}
                </tr>
            </thead>
            <tbody>
                {currentRecords?.map((item, index) =>
                <tr key={`${item}_${index}`}>
                    {columns.map((column, colIdx) => 
                        ['Date', 'created_at']?.includes(column?.title) 
                        ?
                            <td key={`col_${index}${colIdx}`} className={`py-2 ps-4`} style={{ fontSize: '16px' }} title={item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD') : ''}>
                                {item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD') : '--'}
                            </td>
                        : 
                        ['Time', 'created_at']?.includes(column?.title) ? <td key={`col_${index}${colIdx}`} className={`py-2 ps-4`} style={{ fontSize: '16px' }} title={item?.[column?.key] ? moment(item?.[column?.key])?.format('HH:mm:ss') : ''}>
                            {item?.[column?.key] ?  moment(item?.[column?.key])?.format('HH:mm:ss') : '--'}
                        </td>
                        :
                        (column?.key === "signedUrl") ? (item?.signedUrl != 'No screenshot available') ? <div className='flex justify-center mt-5'><button type='button' onClick={()=>getScreenShotURL(item)}><div className="errorImageicon" title='Show Error Screenshot'>{<img src="assets/images/infoIcon.svg"></img>}</div></button></div> : ''
                        :
                            <td title={item?.[column?.key] ? item?.[column?.key] : ''}>
                                {item?.[column?.key] ? item?.[column?.key]?.length > 30 ? `${item?.[column?.key].substring(0, 30)}...` : item?.[column?.key] : '--'}
                            </td>
                    )}
                </tr>
                )}
            </tbody>
            </table>
            :
            <>
                <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                <tr className="ps-2 pe-2">
                    {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                        {item?.title}
                    </th>
                    )}
                </tr>
                </thead>
            </table>
            {tableLoading ? 
            <div className="w-full h-full flex items-center justify-center" >
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
            </div>
            :
                <div className='flex justify-center py-5 font-semibold'>
                    No data found
                </div>
            }
            </>
        }
      </div>
        {(!tableLoading && currentRecords?.length > 0) &&
            <Pagination nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            ></Pagination>
        }
      </div>
  )
}