import React, { useState } from 'react';
import { TimePicker ,Space} from 'antd';
import { useEffect } from 'react';
import { handleErrorMessage } from '../common/commonFunction';
import API from '../helpers/api';
import moment from 'moment';

export default function ConfigurationModal({showLoading,closeModal,handleSubmit,format,time,setTime}) {
    const getTimeFromDate = (dateObj) => {
        let date = new Date(dateObj?.$d);
        return date
    }

    const handleChange = (dateRangeObj) => {
        if(!dateRangeObj) return null
        let startTime = dateRangeObj ? getTimeFromDate(dateRangeObj[0]): null;
        let endTime = dateRangeObj ? getTimeFromDate(dateRangeObj[1]): null;
        setTime({start: startTime, end: endTime})
    }

    return (
        <>
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Set Queues Uptime
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {<div className='p-4 mt-3 text-center bg-green bgSuccess text-black-600 font-semibold'>
                            <span className='me-2'>Current uptime :</span> {time?.start && time?.end ? `${moment(time?.start).format('HH:mm')} - ${moment(time?.end).format('HH:mm')} Hrs` : `--`}
                        </div>}
                        <div className="p-4 flex gap-2 justify-center">
                                <TimePicker.RangePicker
                                    name="startTime"
                                    // value={[moment(time?.start, "HH:mm"), moment(time?.end, "HH:mm")]}
                                    onChange={(val) => handleChange(val)}
                                    placeholder="From" // Placeholder for start date
                                    className="border w-full px-4 py-2 focus:outline-none focus:border-none rounded-md"
                                    format={format ? format : 'HH:mm'}
                                    style={{border:'1px solid #e7e2e2',}}
                                />
                        </div>

                        <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" className="custom_btn_primary flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn" onClick={handleSubmit}>
                                update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
