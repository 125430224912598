const alphanumeric = (val) => {
    // Regex to match any character that is NOT a letter or a digit
    const regex = /[^a-zA-Z0-9]/g;
    
    // Replace non-alphanumeric characters with an empty string
    return val?.replace(regex, '');
}

const isAlphanumeric = (val) => {
    //atleast one character must be number and alphabet
    const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    return pattern.test(val);
}

const validateName = (val) => {
   const pattern = /^[A-Za-z][A-Za-z\s]*$/;
   return pattern.test(val);
}

const validateNumericValue = (val) =>{
    const pattern = /[^\d]/;
    return pattern.test(val)
}
const Validation = {
    alphanumeric,
    isAlphanumeric,
    validateName,
    validateNumericValue,
}

export default Validation;