import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";
export default function LoanDocumentStorage() {
  var navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;

  return (
    <div onClick={() => navigate("/LoanStorage")} className={`samdProcessCards commonCard cursor-pointer bg-white ${isMobileDevice && 'responsiveSamdCard'}`}>
      <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
        <div>
          <img src="assets/files.svg" alt="file" />
        </div>
        <span className="font-semibold">
          Loan Document Storage
        </span>
        <div onClick={() => navigate("/LoanStorage")} className="cursor-pointer arrowIcon commonCardIcons">
          <img src="assets/right-arrow.svg" alt="file" />
        </div>
      </div>
      <div>
        <p className="p-4">
        This process safeguards and organizes essential documents of borrowers responsible for repaying loan amounts to the bank. It ensures secure storage and easy retrieval of vital paperwork related to loan obligations.
        </p>
      <div className="float-end px-4">
        <p>
          Created on 24 Mar, 2024
        </p>
      </div>
      </div>
    </div>
  );
}