// Info: Contains App specific Constants
"use strict";

export default {
  API_BASE_URL: process.env.REACT_APP_API_SERVICE_BASE_URL,
  WORKER_BASE_URL: process.env.REACT_APP_WORKER_API_BASE_URL,

  // Common constants
  GET_METHOD: "GET",
  POST_METHOD: "POST",
  JSON_REQUEST: "json",

  GET_TASK_LIST: "/api/tasks/accounts/verifications",
  POST_WORKER: "/start-workers",
};
