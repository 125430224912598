import React from 'react'
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import styles from "../../styles/userEdit.module.scss";
import { useEffect, useState } from "react";
import API from "../../helpers/api/index";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import DataTable from '../../component/DataTable';
import play from '../../assets/images/playBtn.svg';
import Icon from '../../component/core-component/Icon';
import VideoThumbnail from 'react-video-thumbnail';
import { set } from 'react-hook-form';
import axios from 'axios';
import FeedbackModal from "../../component/core-component/FeedbackModal";
import { getDecodedToken } from '../../helpers/auth';
import moment from 'moment';
import ViewNIDDetailModal from '../../component/ViewNIDDetailModal';
import PdfViewer from '../../component/PDFViewer';
import { encodeData, handleErrorMessage } from '../../common/commonFunction';
import { WithAuth } from '../../common/WithAuth';
import stylesreconcillition from '../../styles/reconcilition.scss'


function ViewRequest() {
    const [selectedRequestData, setSelectedRequestData] = useState();
    const [detailDataLoading, setDetailDataLoading] = useState(false);
    const [nidDetailError, setNidDetailError] = useState('');
    const [errorInNid, setErrorInNid] = useState(false);
    const [selectedAudioText, setSelectedAudioText] = useState();
    const [pdfBlob, setPdfBlob] = useState(null);
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [themeData, setThemeData] = useState(themeDetails?.themeDetails ? themeDetails?.themeDetails : themeDetails);
    const [selectedRequestPersonalData, setSelectedRequestPersonalData] = useState({
        full_name: '',
        nid: 'Uploaded NID',
        paper_form: 'Paper Form',
        audio_url: '',
    })
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [showNIDDetailModal, setShowNIDDetailModal] = useState(false);
    const [formData, setFormData] = useState({
        feedback: ''
    })
    const [isAudio, setIsAudio] = useState(false)
    const [isVideoAvilable, setIsVideoAvilable] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState({ state: false, message: '' })
    const location = useLocation();
    const navigate = useNavigate();
    const [thumbnail, setThumbnail] = useState('');
    const [faceMatchScore, setFaceMatchScore] = useState();
    const [nameMatchScore, setNameMatchScore] = useState();
    const [imgString, setImgString] = useState('');
    const [isImageAvailable, setIsImageAvailable] = useState(false);
    const [livenessScore, setLivenessScore] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [openTextModal, setOpenTextModal] = useState(false);
    const [responseScreenWidth, setResponseScreenWidth] = useState();
    const [videoModal, setVideoModal] = useState(false);
    const isMobileDevice = window.screen.width <= 900;
    const [GeoLocationModal, setGeoLocationModal] = useState(false);
    const [geoLocation,setGeoLocation] = useState(null)
    const requestColumns = [
        // {
        //   title: 'RAISED BY',
        //   key: 'raised_by',
        //   // width: '18%',
        // },
        {
            title: 'Requester',
            key: 'requestor',
            // width: '10%',
        },
        // {
        //     title: 'Account Number',
        //     key: 'account_no',
        //     // width: '18%',
        // },
        {
            title: 'Old Number',
            key: 'old_number',
            // width: '13%',
        },
        {
            title: 'New Number',
            key: 'new_number',
            // width: '13%',
        },
        {
            title: 'Verification Mobile Number',
            key: 'verification_number',
            // width: '18%',
        },
        {
            title: 'Verification Email ',
            key: 'email',
            // width: '13%',
        },
        {
            title: 'KYC Video',
            key: 'kyc_video',
            // width: '13%',
        },
        {
            title: 'Requested On',
            key: 'updated_at',
            // width: '13%',
        },
    ]
    const requestColumnsPersonal = [
        // {
        //     title: 'Full Name',
        //     key: 'full_name',
        //     // width: '18%',
        // },
        {
            title: 'NID',
            key: 'nid',
            // width: '10%',
        },
        {
            title: 'Paper Form',
            key: 'paper_form',
            // width: '18%',
        },
        {
            title: 'NID DETAILS',
            key: 'nid_details',
            // width: '13%',
        },
    ]
    const [userData] = useSelector((Gstate) => [Gstate?.UserDataReducer?.user_data])
    const [commenstData, setCommenstData] = useState([]);
    const [NIDDetailData, setNIDDetailData] = useState([]);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    function getUserID() {
        const params = new URLSearchParams(location.search);
        return params.get('kyc_id') || '';
    }

    async function getRequestDetails() {
        setDetailDataLoading(true);
        try {
            const query = {
                kyc_id: getUserID()
            }
            const { data } = await API.apiGet(`/videokyc/details`, '', query)
            if (data?.success) {
                setDetailDataLoading(false);
                setSelectedRequestData(data?.data)
                setSelectedRequestPersonalData({ ...selectedRequestPersonalData, full_name: `${data?.data?.first_name + " " + data?.data?.last_name}` })
                getLivenessRecord(data?.data);
                getSelectedUserDetails(data?.data);
            }
            else {
                // toast.error(data?.message)
                console.log("error", data?.message)
                setDetailDataLoading(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            console.log("error",error)
            setDetailDataLoading(false);
        }
    }

    async function getAudiotext() {
        try {
            setDetailDataLoading(true);
            const query = {
                kyc_id: getUserID()
            }
            const { data } = await API.apiGet(`/videokyc/fetch/audio/text`, '', query)
            if (data?.sucess || data?.success) {
                setDetailDataLoading(false);
                setSelectedAudioText(data?.data);
            }
            else {
                // toast.error(data?.message)
                console.log("error", data?.message)
                setDetailDataLoading(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            console.log("error",error)
            setDetailDataLoading(false);
        }
    }

    async function getLivenessRecord(detailData) {
        try {
            setDetailDataLoading(true);
            const query = {
                kyc_id: getUserID()
            }
            const { data } = await API.apiGet(`videokyc/liveness/records/list`, '', query)
            if (data) {
                setDetailDataLoading(false);
                const value = data?.kyc_score !== null ? data?.kyc_score?.toString() : '0'
                let parts = value?.split('.');
                let truncatedDecimal = parts?.length > 1 ? parts?.[1]?.substring(0, 2).padEnd(2, '0') : '00';
                let truncatedNumber = detailData?.kyc_video == 'Yes' ? detailData?.process_status == 'In Progress' ? 'In Queue' :`${parts?.[0] + '.' + truncatedDecimal}%` : '0.00%';
                setLivenessScore(truncatedNumber)
            }
            else {
                // toast.error(data?.message)
                console.log("error", data?.message)
                setDetailDataLoading(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            console.log("error",error)
            setDetailDataLoading(false);
        }
    }

    async function getVideoFile() {
        try {
            setDetailDataLoading(true);
            const query = {
                kyc_id: getUserID()
            }
            const response = await API.apiGet(`videokyc/fetch/video/file`, '', query)
            if (response?.data) {
                if (response?.data?.success === false) {
                    setIsVideoAvilable(false)  // api only sends success false if video is not available
                }else{
                    setDetailDataLoading(false);
                    setIsVideoAvilable(true);
                }
            }
            else {
                setIsVideoAvilable(false);
                setDetailDataLoading(false);
                // toast.error(data?.message)
            }
        }
        catch (error) {
            setIsVideoAvilable(false);
            setDetailDataLoading(false);
            handleErrorMessage(error);
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }

    }

    async function getAudioFile(){
        try{
            setDetailDataLoading(true);
            const query = {
                kyc_id: getUserID()
            }
            const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/fetch/audio/file?kyc_id=${query?.kyc_id}`, {
                responseType: 'blob',
            });
            if (response?.status == 200) {
                setDetailDataLoading(false);
                setIsAudio(true);
                let audioTag = document.getElementById('audioPlayer');
                if (audioTag) {
                    audioTag.src = window.URL.createObjectURL(response?.data);
                }
            }

        } catch (err) {
            console.log('Error fetching audio:', err)
            setIsAudio(false);
            setDetailDataLoading(false);
        }
    }


    async function getImage(){
        try{
            setDetailDataLoading(true);
            const query = {
                kyc_id: getUserID()
            }
            const response =await API.apiGet(`videokyc/fetch/nidFace/file`,'',query);
            if(response.data){
                setDetailDataLoading(false);
                setIsImageAvailable(true);
            }
            const base64String = `${response?.data?.signedUrl}`;
            setImgString(base64String);
            
        }catch (error) {
            console.log('error', error);
            setDetailDataLoading(false);
        }
    }

    const getScoreData = (detailData, value, key) => {
        const matchValue = value?.[key] !== null ? value?.[key]?.toString() : '0'
        let parts = matchValue?.split('.');
        let truncatedDecimal = parts?.length > 1 ? parts?.[1]?.substring(0, 2).padEnd(2, '0') : '00';
        let truncatedNumber = detailData?.kyc_video == 'Yes' ? detailData?.process_status == 'In Progress' ? 'In Queue' : `${parts?.[0] + '.' + truncatedDecimal}%` : '0.00%';
        if (key == 'KYC_score') setFaceMatchScore(truncatedNumber);
        if (key == 'name_score') setNameMatchScore(truncatedNumber);
    }

    const getSelectedUserDetails = async (detailData) => {
        try {
            setDetailDataLoading(true);
            const query = {
                kyc_id : getUserID()
            }            
            const response = await API.apiGet(`videokyc/getScore`,'',query)
            if (response?.status == 200) {
                setDetailDataLoading(false);
                setGeoLocation(response?.data?.kyc_score?.kyc_location);
                getScoreData(detailData, response?.data?.kyc_score, 'KYC_score');
                getScoreData(detailData, response?.data?.kyc_score, 'name_score');
            }
        }
        catch (error) {
           console.log(">>>>",error);
           setDetailDataLoading(false);
        }
    }

    const [isOpen,setIsOpen]=useState(false)
    const [pdfUrl, setPdfUrl] = useState('');
    async function handleFileDownload(value) {
        try {
            const query = {
                kyc_id: getUserID()
            }
    
            let endpoint = '';
            if (value === 'nid') {
                endpoint = 'videokyc/fetch/nid/file';
            } else if (value === 'form') {
                endpoint = 'videokyc/fetch/form/file';
            } else {
                console.error('Invalid value:', value);
                return;
            }
            const response = await API.apiGet(endpoint, '', query);
            const signedUrl = response.data.signedUrl;
            setIsOpen(true)
            setPdfUrl(signedUrl);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    }
    
    // const handleFileView = async(value) => {
    //     try {
    //         const query = {
    //             kyc_id: getUserID()
    //         };
    //         const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/fetch/${value}/file?kyc_id=${query?.kyc_id}`, {
    //             responseType: 'blob',
    //         });
    //         setPdfBlob(response.data);
    //         setShowPDFViewer(true);

    //     } catch (error) {
    //         setShowPDFViewer(false);
    //         console.error("Error downloading file:", error);
    //     }
    // }

    function getVideoUrl() {
        return `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/fetch/video/file?kyc_id=${getUserID()}`
    }

    function handleStatusButtons(status) {
        // let message = status === 'Rejected' ? "Do you want to reject this request" : "Do you want to approve this request";
        setShowAlertMessage({ state: true, status: status })
    }

    async function updateStatus(status, message) {
        try {
            const payload = {
                kyc_id: getUserID(),
                kyc_status: status,
            }
            const response = await API.apiPost(`videokyc/status/update`, { payload: encodeData(payload) })
            if (response?.data) {
                toast.success(response?.data?.message)
                getRequestDetails()
                setShowAlertMessage({ ...showAlertMessage, state: false })
            }
            else {
                setIsVideoAvilable(false)
                toast.error(response?.data?.message)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            setIsVideoAvilable(false)
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }
    }
    const openVideoInIframe = (videoUrl) => {
        setVideoModal(true);
    };

    const closeModal = () => {
        setVideoModal(false);
    };

    const value = ((parseFloat(faceMatchScore) + parseFloat(livenessScore) + parseFloat(nameMatchScore)) / 3)?.toString()
    let parts = value?.split('.');
    let truncatedDecimal = parts?.length > 1 ? parts?.[1]?.substring(0, 2)?.padEnd(2, '0') : '00';
    let truncatedNumber = parts?.[0] + '.' + truncatedDecimal;
    let cumulativeScore = selectedRequestData?.kyc_video == 'Yes' ? selectedRequestData?.process_status == 'In Progress' ? 'In Queue' : `${truncatedNumber}%` : '0.00%';
    cumulativeScore = cumulativeScore == "NaN.00%" ? '0%' : cumulativeScore;
    useEffect(() => {
        setDetailDataLoading(true);
        getRequestDetails();
        getAudiotext();
        getVideoFile()
        getAudioFile();
        getImage();
        getLivenessRecord();
        getSelectedUserDetails();
        getComments();
        getNIDDetails();
    }, [])

    useEffect(()=>{
        let viewportHeight = window.screen.width;
        setResponseScreenWidth(viewportHeight > 900)
      },[])

    const getNIDDetails = async () => {
        try {
            setDetailsLoading(true);
            const query = {
                kyc_id: getUserID(),
            }
            const response = await API.apiGet(`/videokyc/nid/details`, '', query)
            if (response?.data) {
                if (response?.data?.data?.[0]?.NID_details?.data?.length) {
                    setErrorInNid(false);
                    setNIDDetailData(response?.data?.data?.[0]?.NID_details?.data)
                } else if (response?.data?.data?.[0]?.NID_details?.error) {
                    setErrorInNid(true);
                    setNidDetailError(response?.data?.data?.[0]?.NID_details?.error);
                }
                setDetailsLoading(false);
            }
        } catch (error) {
            console.log('error', error)
            setDetailsLoading(false);
        }
    }

    const handleBack = () => {
        navigate('/updatePhoneNumber');
    }

    const handleModal = () => {
        setOpenModal(true)
    }

    const textModal = () => {
        setOpenTextModal(true);
    }

    const textcloseModal = () => {
        setOpenTextModal(false)
    }

    const getUserDataID = () => {
        const { user_id } = getDecodedToken()
        return userData?.id || user_id
    }
    const getUserProfile = async () => {
        return userData
    }

    const getComments = async () => {
        try {
            const query = {
                KYC_id: getUserID(),
                id: getUserDataID()
            }
            const response = await API.apiGet(`/videokyc/comments`, '', query)
            if (response?.data) {
                setCommenstData(response?.data?.data)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }
    }

    const sendComment = async () => {
        setLoading(true)
        try {
            const query = {
                KYC_id: getUserID(),
                id: getUserDataID()
            }
            const payload = {
                kyc_comments: comment,
                KYC_id: getUserID(),
            }
            const encodedPayload = encodeData(payload);
            const response = await API.apiPost(`/videokyc/comments`, {payload: encodedPayload}, query)
            if (response?.data) {
                getComments()
                setComment('')
                toast.success(response?.data?.message)
            }
            else {
                toast.error(response?.data?.message)
            }
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            handleErrorMessage(error);
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormData({ ...formData, [name]: value });
    }

    function getFormattedTime(dateTime) {
        let timeInSeconds = moment().diff(dateTime, 'seconds');
        if (timeInSeconds < 60) {
            return timeInSeconds + ' seconds ago';
        } else if (timeInSeconds < 3600) {
            return Math.floor(timeInSeconds / 60) + ' minutes ago';
        } else {
            return Math.floor(timeInSeconds / 3600) + ' hours ago';
        }
    }

    const viewNIDDetails = () => {
        if (selectedRequestData?.process_status == 'Done' && selectedRequestData?.kyc_video == 'Yes') {
            setShowNIDDetailModal(true);
        }
    }

    const getDynemicClass = (score) => {
        let scoreClassName = `${styles.greenZoneScore}`
        switch (true) {
            case parseFloat(score) >= 75:
                scoreClassName = `${styles.greenZoneScore}`
                break;
            case (parseFloat(score) < 75 && parseFloat(score) >= 50):
                scoreClassName = `${styles.yellowZoneScore}`
                break
            case parseFloat(score) < 50:
                scoreClassName = `${styles.redZoneScore}`
            default:
                break;
        }
        return scoreClassName;
    }
    
    const getKYCStatus = (item) => {
        switch (item?.KYC_status) {
            case 'Pending':
                return 'In Progress';
            case 'Approved':
                return 'Approved';
            case 'Rejected':
                return 'Rejected'
            default:
                break;
        }
      } 

    return (
       responseScreenWidth ?
        <>

            {/* <!-------------------------modal--------------------> */}
            {
                openModal && <FeedbackModal setOpenModal={setOpenModal} setFormData={setFormData} formData={formData} getKycID={getUserID} />
            }
            {/* <!-------------------------modal--------------------> */}

            {/* text modal */}
            {openTextModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Audio Text
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={textcloseModal}>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="p-4 md:p-5 space-y-4">
                            <div className="">
                                <div className="shadow-lg px-2 py-4">
                                    <div>
                                        {selectedAudioText}
                                    </div>
                                </div>
                                {/* will used in future */}
                                {/* <div>
                                    <input type="text" placeholder="Enter your comment here..." className="border-b border-0 p-2 w-80" />
                                </div> */}
                            </div>
                        </div>
                        {/* will used in future */}
                        {/* <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn">Send</button>
                            <button data-modal-hide="static-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={closeModal}>Cancel</button>
                        </div> */}
                    </div>
                </div>
            </div>}

            {/* text modal */}

            {pdfBlob && showPDFViewer && <PdfViewer pdfBlob={pdfBlob} setShowPDFViewer={setShowPDFViewer} />}

            {/* NID Details Modal */}
            {showNIDDetailModal &&
                <ViewNIDDetailModal
                    setShowNIDDetailModal={setShowNIDDetailModal}
                    NIDDetailData={NIDDetailData}
                    detailsLoading={detailsLoading}
                />
            }

            <div className={`flex dark:bg-dark-background reconcilitaion-main-container`}>
                <SideMenu/>
                <div className={`flex-1 ${styles.userManagement} mainBodyContainer`}>
                    <Header />

                    <div className={`overflow-y-scroll ${styles.fixedHeightCnt} ${isMobileDevice ? 'responsiveMainContainer p-5' : 'mainContainer p-10'}`}>
                        <div>
                            <div className={`${styles.userEdit}`}>
                                <div className={`${styles.accountDetails} p-4 flex justify-between items-center`}>
                                    <div className='flex'>
                                        <div className='cursor-pointer' onClick={() => handleBack()}><Icon iconName="IconBackBtn" width={16} height={16} /></div>
                                        <div className='ps-2'><b>Account Details</b></div>
                                    </div>
                                    {<div className={`flex gap-3 ${styles.accountDetailsButtonContainer}`}>
                                        {/* will used in future */}
                                        {/* <span className='flex items-center gap-2 btnText textPending'>
                                            <span className='block pendingDot' style={{borderRadius:'50%',padding:'5px', width:'10px' , height:'10px'}}/>
                                            Pending
                                        </span> */}
                                        <button className={`border border-red-600 p-2 rounded-md flex items-center gap-2 w-30 justify-center btnText h-9 textRejected ${styles.accountDetailsButtons} ${!(selectedRequestData?.KYC_status === "Pending") ? 'hidden' : ''}`} onClick={() => handleStatusButtons('Rejected')} disabled={!(selectedRequestData?.KYC_status === "Pending")}>Reject <img src="assets/x.svg" style={{ height: '15px' }} /></button>
                                        <button className={`border border-green-600 p-2 rounded-md flex items-center gap-2 w-30 h-9 justify-center btnText textApproved ${styles.accountDetailsButtons} ${!(selectedRequestData?.KYC_status === "Pending") ? 'hidden' : ''}`} onClick={() => handleStatusButtons('Approved')} disabled={!(selectedRequestData?.KYC_status === "Pending")}>Approve  <img src="assets/tick.svg" style={{ height: '13px' }} /></button>
                                    </div>
                                    }
                                </div>
                                <div className={`flex ps-4 ${styles.userDetails}`}>
                                    <div className={`w-full m-2 pe-3 ${styles?.sellerDetails}`}>

                                        <div className={`text-black-50 ${styles.labelColor}`}>First Name:</div>
                                        <div
                                            className={`mt-1 me-3  ${styles?.userName}`} >
                                            {/* {userData?.first_name && userData?.first_name?.charAt(0).toUpperCase() + userData?.first_name?.slice(1)} */}
                                            {selectedRequestData?.first_name}
                                        </div>

                                    </div>
                                    <div className={`w-full m-2 px-2 ${styles?.sellerDetails}`}>
                                        <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>
                                            Last Name:
                                        </div>
                                        <div className={`mt-1  ${styles?.userName}`}>
                                            {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                            {selectedRequestData?.last_name}
                                        </div>
                                    </div>
                                    <div className={`w-full m-2 px-3 ${styles?.sellerDetails}`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Account number:</div>
                                        <div className={`mt-1  ${styles?.userName}`}>{selectedRequestData?.account_no}</div>
                                    </div>
                                    {/* will used in future */}
                                    {/* <div className={`w-full m-2 px-3 ${styles?.sellerDetails}`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Email:</div>
                                        <div className={`mt-1  ${styles?.userName}`}>ty@io.com</div>
                                    </div> */}
                                    <div className={`w-full m-2 px-3`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>KYC Status:</div>
                                        <div className={`mt-1`}>
                                            <span className={`${selectedRequestData?.KYC_status === 'Approved' ? 'textApproved' : selectedRequestData?.KYC_status === 'Rejected' ? 'textRejected' : 'textInProgress'}`}>
                                                {getKYCStatus(selectedRequestData)}
                                            </span>
                                            {/* will used in future */}
                                            {/* {userData?.status ? userData?.status == 'Inactive' ? <span className={`text-red text-center`}><span className="red-dot"/>Inactive</span> : <span className={`text-green-500`}><span className="text-green-500 green-dot"/>Active</span> : <span className="text-red-500"> --</span> } */}
                                        </div>
                                    </div>
                                    <div className={`w-full m-2 px-3`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Geo Location:</div>
                                        <div className={`mt-1`}>
                                            <button onClick={() => setGeoLocationModal(true)} className='custom_btn_primary rounded-full w-24 border border-gray-600'>View</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <DataTable columns={requestColumns} rowData={[selectedRequestData]} hidePagination={true} />
                            </div>
                            <div className='flex'>
                                <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 w-1/5 pt-4">
                                    Comparison
                                </h1>
                                {/* will used in future */}
                                {/* <div className={`w-4/5 ${styles.cumulativeScore}`}>
                                    <div className={`ms-4 ${styles.cumulativeCard}`}>
                                        Cumulative Score : {cumulativeScore}%
                                    </div>
                                </div> */}
                            </div>
                            <div className='grid grid-cols-12 gap-4'>
                                <div className={`col-span-7 ${styles.videoPlay} rounded-2xl`}>
                                    <div className={`mt-4 p-4 py-5 flex justify-between`}>
                                        <div className='w-1/3'>
                                            <p className='font-semibold'>KYC video</p>
                                            <div className='flex mt-3'>
                                                {isVideoAvilable ? (
                                                    <div className={``}>
                                                        <div className={`relative ${styles.fixedVideoHeight}`}>
                                                            <VideoThumbnail videoUrl={getVideoUrl()}
                                                                thumbnailHandler={(thumbnail) => setThumbnail(thumbnail)}
                                                            />
                                                            <button className={`ms-3 ${styles.playBtn} cursor-pointer absolute top-0 -left-3 h-full w-full flex justify-center items-center opacity-0 hover:opacity-100`} onClick={() => openVideoInIframe(getVideoUrl())}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={'assets/images/play_media.png'} alt="Play Video" style={{ marginRight: '5px', width: '40px' }} />
                                                                </span>
                                                            </button>
                                                        </div>
                                                        {/* will used in future */}
                                                        <div className='mt-5'>
                                                            <div className='flex justify-between'>
                                                                {/* <div>
                                                                    <button className={`ms-3 ${styles.playBtn} cursor-pointer`} onClick={() => handleModal()}>
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <img src={play} alt="Play Video" style={{ marginRight: '5px' }} />
                                                                            <span>Send Feedback</span>
                                                                        </span>
                                                                    </button>
                                                                </div> */}
                                                                {/* <div>
                                                                    <button className={`ms-3 ${styles.playBtn} cursor-pointer`} onClick={() => textModal()}>
                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <img src={play} alt="Play Video" style={{ marginRight: '5px' }} />
                                                                            <span>Show Text</span>
                                                                        </span>
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                            {/* <div className={`${styles.kycVideoInfo} mt-2`}>
                                                                    <p>Uploaded at : dummy text</p>
                                                                    <p>File Type : dummy text</p>
                                                                    <p>File Size : dummy text</p>
                                                                    <p>Duration : dummy text</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='noImageDiv text-center p-1'>
                                                        No video available
                                                        <Icon iconName="noVideo" width={16} height={16} alt='no video' />
                                                    </div>
                                                    )}
                                                </div>
                                                <div id="videoContainer"></div>
                                        </div>
                                        <div className='w-1/3'>
                                                <div className=''>
                                                <p className='font-semibold flex justify-center'>Image</p>
                                                <div className={`flex mt-3 justify-center ${styles.imageCnt}`} >
                                                    {isImageAvailable ?
                                                        <img src={imgString} alt="KYC Image" width={160} />
                                                        :
                                                        <div className='noImageDiv'>
                                                            No Image
                                                            <Icon iconName="noImage" width={16} height={16} alt='no image' />
                                                        </div>
                                                    }
                                                </div>
                                                {isImageAvailable && <p className='flex justify-center py-1'>{selectedRequestPersonalData?.full_name}</p>}
                                            </div>
                                        </div>
                                        <div className='w-1/3'>
                                            <div className=''>
                                                <p className='font-semibold'>Audio</p>
                                                <div className='flex mt-3 flex-col' >
                                                    <audio id="audioPlayer" className={`audioTag ${!isAudio ? 'hidden' : ''}`} controls></audio>
                                                    <>
                                                        {/* will used in future */}
                                                        {/* <div className={`${styles.kycVideoInfo} mt-2 ms-3`}>
                                                        <p>Uploaded at : dummy text</p>
                                                        <p>File Type : dummy text</p>
                                                        <p>File Size : dummy text</p>
                                                        <p>Duration : dummy text</p>
                                                    </div> */}

                                                    </>
                                                    {!isAudio && <div className='mt-3 ms-3'>No audio available</div>}
                                                </div>
                                                <div>
                                                    <p className='mt-4 font-semibold'>Transcription</p>
                                                    <div className='text-wrap p-2 tableBorder rounded-md pb-8'>{selectedAudioText ? selectedAudioText : "No Transcription available"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {showAlertMessage?.state &&
                                                <div id="popup-modal" tabindex="-1" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                                                    <div class="relative p-4 w-full max-w-md max-h-full">
                                                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                            <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setShowAlertMessage({ ...showAlertMessage, state: false })}>
                                                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                </svg>
                                                                <span class="sr-only">Close modal</span>
                                                            </button>
                                                            <div class="p-4 md:p-5 text-center">
                                                                <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg>
                                                                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                                                    {
                                                                        showAlertMessage?.status === 'Approved' ? "Do you want to approve this request ?" : "Do you want to reject this request ?"
                                                                    }
                                                                </h3>
                                                                <button data-modal-hide="popup-modal" type="button" class={`text-white bg-red-600 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center ${showAlertMessage?.status === 'Approved' ? 'bgApproved hover:bgApproved focus:bgApproved' : 'bg-red-600 focus:ring-red-300'}`} onClick={() => updateStatus(showAlertMessage?.status)}>
                                                                    Yes, I'm sure
                                                                </button>
                                                                <button data-modal-hide="popup-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => setShowAlertMessage({ ...showAlertMessage, state: false })}>No, cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                   </div>
                                    {errorInNid && nidDetailError?.message && 
                                        <div className={`py-3 px-5 ps-7 font-bold rounded-xl mb-5 mx-5 ${styles.nidDetailError}`}>
                                            <div className={`me-2`}>
                                                <Icon iconName="cross" width={16} height={16} fill={'red'} />
                                            </div>
                                            <label>{nidDetailError?.message}</label>
                                        </div>
                                    }
                                </div>
                                <div className={`col-span-5 mt-4 flex flex-col justify-between gap-4 ${styles.viewRequestTableCompCnt}`}>
                                    <div className={`flex rounded-md flex-col tableBorder overflow-hidden ${styles.viewRequestTableComp}`}>
                                        {/* will used in future */}
                                        {/* <div className='py-4 flex gap-2'>
                                            <span className='block' style={{ width: '100px'}}>
                                                <b>
                                                    Audio Text :
                                                </b>
                                            </span>
                                            <span>
                                                {selectedAudioText?.length > 150 ? <span className='flex'>
                                                    {`${selectedAudioText}...`} <button className='iIconCnt' onClick={textModal}>i</button>
                                                </span> 
                                                : selectedAudioText
                                                }
                                            </span>
                                        </div> */}
                                        {/* <div className='flex justify-between'>
                                            <div className={`${livenessScore > 75 ? `${styles.nameCard}` : `${styles.livenessCard}`} `}>
                                                Liveness Score <br/>
                                                 {livenessScore}%
                                            </div>
                                            <div className={`${faceMatchScore > 75 ? `${styles.nameCard}` : `${styles.livenessCard}`} `}>
                                                Face Match Score <br/>
                                                {faceMatchScore}%
                                            </div>
                                            <div className={`${styles.disablecard}`}>
                                                Name Score <br/>
                                                --%
                                            </div>
                                            <div className={`${styles.cumulativeCard}`}>
                                                Cumulative Score <br/>
                                                {cumulativeScore}%
                                            </div>
                                        </div> */}
                                 
                                <table className={`min-w-full  rounded-lg border border-solid border-gray-border text-left reconcilitionTable ${styles.shadow}`}>
                                    <thead className='text-center'>
                                        <tr className="pt-3 pb-5">
                                            <th>
                                                <span className="text-gray-500 text-sm font-medium">CUMULATIVE SCORE</span>
                                            </th>
                                            <th>
                                                <span className="text-gray-500 text-sm font-medium">LIVENESS SCORE</span>
                                            </th>
                                            <th>
                                                <span className="text-gray-500 text-sm font-medium">FACE MATCH SCORE</span>
                                            </th>
                                            <th>
                                                <span className={`text-gray-500 text-sm font-medium`}>NAME SCORE</span>
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ backgroundColor: 'white' }} className='text-center'>
                                            <td>
                                            <span className={`px-4 py-2 rounded-full border border-gray-600 ${getDynemicClass(cumulativeScore)}`} title={selectedRequestData?.process_status == 'In Progress' ? 'Data scraping in queue' : 'Cumulative Score'}>{detailDataLoading ? 'Loading...' : cumulativeScore}</span>
                                            </td>
                                            <td>
                                            <span className={`px-4 py-2 rounded-full border border-gray-600 ${getDynemicClass(livenessScore)}`} title={selectedRequestData?.process_status == 'In Progress' ? 'Data scraping in queue' : 'Liveness Score'}>{detailDataLoading ? 'Loading...' : livenessScore}</span>
                                            </td>
                                            <td>
                                            <span className={`px-4 py-2 rounded-full border border-gray-600 ${getDynemicClass(faceMatchScore)}`} title={selectedRequestData?.process_status == 'In Progress' ? 'Data scraping in queue' : 'Face Match Score'}>{detailDataLoading ? 'Loading...' : faceMatchScore}</span>
                                            </td>
                                            <td>
                                            <span className={`px-4 py-2 rounded-full border border-gray-600 ${getDynemicClass(nameMatchScore)}`} title={selectedRequestData?.process_status == 'In Progress' ? 'Data scraping in queue' : 'Name Match Score'}>{detailDataLoading ? 'Loading...' : nameMatchScore}</span>
                                            </td>
                                        </tr>
                                    {/* } */}
                                    </tbody>
                                </table>

                                    </div>
                                    {/* will used in future */}
                                    {/* <DataTable columns={requestColumnsPersonal} rowData={[selectedRequestPersonalData]} hidePagination={true} /> */}
                                    <table className={`min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable ${styles.viewRequestTableComp}`}>
                                        <thead>
                                            <tr className="ps-2 pe-2">
                                                {requestColumnsPersonal?.map((item, indx) =>
                                                    <th key={`row${indx}`} className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px', backgroundColor: 'white' }}>
                                                        <span className={`${styles.scoreCardHead}`}>{item?.title}</span>
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* will used in future */}
                                            {/* { */}
                                            <tr style={{ backgroundColor: 'white' }}>
                                                {/* <td className="py-2 ps-4" title={selectedRequestPersonalData.full_name} style={{fontSize:'16px'  }}>{selectedRequestPersonalData.full_name ? selectedRequestPersonalData.full_name.length>20 ? `${selectedRequestPersonalData.full_name.substring(0,20)}...`: selectedRequestPersonalData.full_name: <span> -</span>}</td>  */}
                                                <td>
                                                    <button style={{ color: themeData?.color_config?.main_color, display: 'flex', alignItems: 'center', textDecoration: 'underline' }} onClick={()=>handleFileDownload('nid')}>
                                                        NID File
                                                        <div className='ms-2'><Icon iconName="EyeIcon" width={16} height={16} fill={themeData?.color_config?.main_color} /></div>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button style={{ color: themeData?.color_config?.main_color, display: 'flex', alignItems: 'center', textDecoration: 'underline' }} onClick={()=>handleFileDownload('form')}>
                                                        Paper Form
                                                        <div className='ms-2'><Icon iconName="EyeIcon" width={16} height={16} fill={themeData?.color_config?.main_color} /></div>
                                                    </button>
                                                </td>
                                                <td >
                                                    <button 
                                                        className={`custom_btn_primary px-1 py-1 rounded-full border border-gray-600 ${styles.viewDetailsBtn} ${selectedRequestData?.process_status != 'Done' ? styles.nidDataLoading : ''} w-full`} 
                                                        disabled={selectedRequestData?.process_status != 'Done' ? true : false} 
                                                        onClick={viewNIDDetails}
                                                        title={selectedRequestData?.process_status == 'In Progress' ? 'Data scraping in progress' : selectedRequestData?.process_status == 'Failed' ? 'No Deitals Found' : 'View nid details'}
                                                    >
                                                        {selectedRequestData?.kyc_video == 'Yes' ? selectedRequestData?.process_status == 'In Progress' ? 'In Queue' : selectedRequestData?.process_status == 'Failed' ? 'No Deitals Found' : 'View Details' : 'View Details'}
                                                    </button>
                                                </td>
                                            {/* will used in future */}
                                            {/* <td className=''>
                                                {isAudio ? <>
                                                    <audio id="audioPlayer" className={`audioTag ${!isAudio ? 'hidden' : ''}`} controls></audio>
                                                    <span className='py-4 text-center'>
                                                        {selectedAudioText}
                                                    </span>
                                                </>
                                                : <div className='mt-3 ms-3'>No audio available</div>} */}
                                                {/* </td> */}
                                            </tr>
                                            {/* } */}
                                        </tbody>
                                    </table>
                                    {/* will used in future */}
                                    {/* <div className='flex ps-4 pt-4 pb-4'>
                                        <div className='flex'>
                                            <div className={`${styles.statusComplete}`}>
                                            </div>
                                            <div className={`ps-3 ${styles.textComplete}`}>
                                                Completed
                                            </div>
                                        </div>

                                        <div className='flex ps-6'>
                                            <div className={`${styles.statusPending}`}>
                                            </div>
                                            <div className={`ps-3 ${styles.textPending}`}>
                                                Pending
                                            </div>
                                        </div>

                                        <div className='flex ps-6'>
                                            <div className={`${styles.statusRejected}`}>
                                            </div>
                                            <div className={`ps-3 ${styles.textRejected}`}>
                                                Rejected
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                            <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 w-1/5 pt-4">
                                Comments
                            </h1>
                            <div className='flex gap-4 mt-4'>
                                <div className='border border-gray-300 p-2 userCircle flex custom_btn_primary'>
                                    {userData?.first_name?.[0] + userData?.last_name?.[0]}
                                </div>
                                <div className='w-full'>
                                    <input type="text" placeholder="Enter your comment here..." className="border border-gray-200 p-2 w-full rounded-md" onChange={(e) => setComment(e.target.value)} value={comment} />
                                </div>
                                <div>
                                    <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn w-20" onClick={sendComment} disabled={loading}>
                                        {loading ?
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            : 'Post'}</button>
                                </div>
                            </div>
                            <div className='mt-4 ms-4'>
                                {commenstData?.map((data) => (<div className='flex gap-4 mt-4'>
                                    <div className='border border-gray-300 p-2 flex subUserCircle'>
                                        {data?.full_name?.split(' ')[0]?.[0] + data?.full_name?.split(' ')[1]?.[0]}
                                    </div>
                                    <div className='w-full'>
                                        <p><b>{data?.full_name}</b></p>
                                        <p className=''>{getFormattedTime(data?.['created_at'])}</p>
                                        <p className='bg-white p-2 rounded w-full'>{data?.kyc_comments}</p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {GeoLocationModal ?
                        <div
                            id="static-modal"
                            data-modal-backdrop="static"
                            tabIndex="-1"
                            aria-hidden="true"
                            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
                        >
                            <div className="modal absolute min-w-[300px]">
                            <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                                    <div className="ps-3 pe-3 pt-1 flex justify-between">
                                        <div className='w-full'>
                                            <p className="font-small pb-5 font-semibold text-lg">
                                                Geo Location
                                            </p>
                                        </div>
                                        <div>
                                            <button onClick={()=> setGeoLocationModal(false)} className={`${styles.close_button}`}>
                                                &times;
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`flex ${styles.userDetails}`}>
                                        <div className={`w-full m-2 px-2`}>
                                            <div className={`mt-1  ${styles?.userName}`}>
                                                {geoLocation?.place?.address ? geoLocation?.place?.address : 'No data available'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> : ""
                }
                {videoModal ?
                    <div
                        id="static-modal"
                        data-modal-backdrop="static"
                        tabIndex="-1"
                        aria-hidden="true"
                        className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
                    >
                        <div className="modal absolute">
                        <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                                <div className="ps-3 pe-3 pt-1 flex justify-between">
                                    <div>
                                        <p className="font-small border-b-2 pb-5 font-semibold text-lg">
                                            Video Viewer
                                        </p>
                                    </div>
                                    <div>
                                        <button onClick={closeModal} className={`${styles.close_button}`}>
                                            &times;
                                        </button>
                                    </div>
                                </div>
                                <div className="modalForm">
                                    <iframe src={getVideoUrl()} width="600" height="450"></iframe>
                                </div>
                            </div>
                        </div> 
                    </div> : ""}
            
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
                >
                    <div className="modal absolute">
                        <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                            <div className="ps-3 pe-3 pt-1 flex justify-between">
                                <div>
                                <p className="font-small border-b-2 pb-5 font-semibold text-lg">
                                    PDF Viewer
                                </p>
                                </div>
                                <div>
                                <button onClick={() => setIsOpen(false)} className={`${styles.close_button}`}>
                                    &times;
                                </button>
                                </div>
                            </div>
                            <div className="modalForm">
                                <iframe
                                    id="pdfIframe"
                                    src={pdfUrl}
                                    frameBorder="0"
                                    width="800px"
                                    height="800px"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </>
        :
        <>

        {/* <!-------------------------modal--------------------> */}
        {
            openModal && <FeedbackModal setOpenModal={setOpenModal} setFormData={setFormData} formData={formData} getKycID={getUserID} />
        }
        {/* <!-------------------------modal--------------------> */}

        {/* text modal */}
        {openTextModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
            <div class="relative p-4 w-full max-w-2xl max-h-full">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            Audio Text
                        </h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={textcloseModal}>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div class="p-4 md:p-5 space-y-4">
                        <div className="">
                            <div className="shadow-lg px-2 py-4">
                                <div>
                                    {selectedAudioText}
                                </div>
                            </div>
                            {/* will used in future */}
                            {/* <div>
                                <input type="text" placeholder="Enter your comment here..." className="border-b border-0 p-2 w-80" />
                            </div> */}
                        </div>
                    </div>
                    {/* will used in future */}
                    {/* <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn">Send</button>
                        <button data-modal-hide="static-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={closeModal}>Cancel</button>
                    </div> */}
                </div>
            </div>
        </div>}

        {isOpen && (
            <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                className="flex overflow-y-auto fixed overflow-x-hidden top-20 right-0 left-0 z-50 justify-center items-center"
            >
                <div className="modal">
                    <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                        <div className="ps-3 pe-3 pt-1 flex justify-between">
                            <div>
                            <p className="font-small border-b-2 pb-5 font-semibold text-lg">
                                PDF Viewer
                            </p>
                            </div>
                            <div>
                            <button onClick={() => setIsOpen(false)} className={`${styles.close_button}`}>
                                &times;
                            </button>
                            </div>
                        </div>
                        <div className="modalForm">
                            <iframe
                                id="pdfIframe"
                                src={pdfUrl}
                                frameBorder="0"
                                width="370px"
                                height="800px"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {videoModal &&
            <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
            >
                <div className="modal absolute">
                <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                        <div className="ps-3 pe-3 pt-1 flex justify-between">
                            <div>
                                <p className="font-small border-b-2 pb-5 font-semibold text-lg">
                                    Video Viewer
                                </p>
                            </div>
                            <div>
                                <button onClick={closeModal} className={`${styles.close_button}`}>
                                    &times;
                                </button>
                            </div>
                        </div>
                        <div className="modalForm">
                            <iframe src={getVideoUrl()} width="370" height="450"></iframe>
                        </div>
                    </div>
                </div> 
            </div>
        }

        {/* text modal */}

        {pdfBlob && showPDFViewer && <PdfViewer pdfBlob={pdfBlob} setShowPDFViewer={setShowPDFViewer} />}

        {/* NID Details Modal */}
        {showNIDDetailModal &&
            <ViewNIDDetailModal
                setShowNIDDetailModal={setShowNIDDetailModal}
                NIDDetailData={NIDDetailData}
                detailsLoading={detailsLoading}
            />
        }

        <div className={`flex dark:bg-dark-background reconcilitaion-main-container mainBodyContainer`}>
            <SideMenu headerName="Account Details"/>
            <div className={`flex-1 ${styles.userManagement} h-screen overflow-y-auto mainContainer_responsive}`}>
                <Header />

                <div className={`overflow-y-scroll ${isMobileDevice ? styles.accountDetails_container : styles.fixedHeightCnt} ${!isMobileDevice ? 'mainContainer p-10' : 'responsiveMainContainer p-2'}`}>
                    <div className='p-2'>
                        <div className={`${styles.userEdit}`}>
                            <div className={`${styles.accountDetails} p-4 flex justify-between items-center`}>
                                <div className='flex'>
                                    <div className='cursor-pointer' onClick={() => handleBack()}><Icon iconName="IconBackBtn" width={16} height={16} /></div>
                                    <div className='ps-2'><b>Account Details</b></div>
                                </div>
                                {<div className={`flex gap-3 ${styles.accountDetailsButtonContainer}`}>
                                    {/* will used in future */}
                                    {/* <span className='flex items-center gap-2 btnText textPending'>
                                        <span className='block pendingDot' style={{borderRadius:'50%',padding:'5px', width:'10px' , height:'10px'}}/>
                                        Pending
                                    </span> */}
                                    <button className={`border border-red-600 p-2 rounded-md flex items-center gap-2 w-30 justify-center btnText h-9 textRejected ${styles.accountDetailsButtons} ${!(selectedRequestData?.KYC_status === "Pending") ? 'hidden' : ''}`} onClick={() => handleStatusButtons('Rejected')} disabled={!(selectedRequestData?.KYC_status === "Pending")}>Reject <img src="assets/x.svg" style={{ height: '15px' }} /></button>
                                    <button className={`border border-green-600 p-2 rounded-md flex items-center gap-2 w-30 h-9 justify-center btnText textApproved ${styles.accountDetailsButtons} ${!(selectedRequestData?.KYC_status === "Pending") ? 'hidden' : ''}`} onClick={() => handleStatusButtons('Approved')} disabled={!(selectedRequestData?.KYC_status === "Pending")}>Approve  <img src="assets/tick.svg" style={{ height: '13px' }} /></button>
                                </div>
                                }
                            </div>
                            <div className={`flex flex-col ps-4 ${styles.userDetails}`}>
                                <div className='flex'>
                                    <div className={`w-full m-2 pe-3 ${styles?.sellerDetails}`}>
                                        <div className={`text-black-50 ${styles.labelColor}`}>First Name:</div>
                                        <div
                                            className={`mt-1 me-3  ${styles?.userName}`} >
                                            {/* {userData?.first_name && userData?.first_name?.charAt(0).toUpperCase() + userData?.first_name?.slice(1)} */}
                                            {selectedRequestData?.first_name}
                                        </div>

                                    </div>
                                    <div className={`w-full m-2 ${styles?.sellerDetails} ${styles.borderNone}`}>
                                        <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>
                                            Last Name:
                                        </div>
                                        <div className={`mt-1  ${styles?.userName}`}>
                                            {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                            {selectedRequestData?.last_name}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className={`w-full m-2 ${styles?.sellerDetails}`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Account number:</div>
                                        <div className={`mt-1  ${styles?.userName}`}>{selectedRequestData?.account_no}</div>
                                    </div>
                                    <div className={`w-full m-2 ms-1`}>
                                        <div className={`text-black-50 mr-2 ${styles.labelColor}`}>KYC Status:</div>
                                        <div className={`mt-1`}>
                                            <span className={`${selectedRequestData?.KYC_status === 'Approved' ? 'textApproved' : selectedRequestData?.KYC_status === 'Rejected' ? 'textRejected' : 'textInProgress'}`}>
                                                {getKYCStatus(selectedRequestData)}
                                            </span>
                                            {/* will used in future */}
                                            {/* {userData?.status ? userData?.status == 'Inactive' ? <span className={`text-red text-center`}><span className="red-dot"/>Inactive</span> : <span className={`text-green-500`}><span className="text-green-500 green-dot"/>Active</span> : <span className="text-red-500"> --</span> } */}
                                        </div>
                                    </div>
                                </div>
                                {/* will used in future */}
                                {/* <div className={`w-full m-2 px-3 ${styles?.sellerDetails}`}>
                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Email:</div>
                                    <div className={`mt-1  ${styles?.userName}`}>ty@io.com</div>
                                </div> */}
                            </div>
                        </div>
                        
                        <div className='mt-4 '>
                            {/* <DataTable columns={requestColumns} rowData={[selectedRequestData]} hidePagination={true} /> */}
                             <div className={` min-w-full rounded-lg ${styles.accountdetailtable}  border border-solid border-gray-border text-left bg-white`}>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>Requestor</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.requestor}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>Old Number</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.old_number}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>New Number</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.new_number}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>Verification Mobile Number</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.verification_number}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>Verification Email</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.email}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>KYC Video</span>
                                    <span className={`w-2/5`}>{selectedRequestData?.kyc_video}</span>
                                </div>
                                <div className="ps-2 pe-2 border-b flex py-2">
                                    <span className="py-2 px-4 w-3/5 font-bold" style={{fontSize: '13px' }}>Requested On</span>
                                    <span className={`w-2/5`}>{moment(selectedRequestData?.updated_at).format('YYYY-MM-DD | h:mm:ss')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex ps-1'>
                            <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 w-1/5 pt-4">
                                Comparison
                            </h1>
                            {/* will used in future */}
                            {/* <div className={`w-4/5 ${styles.cumulativeScore}`}>
                                <div className={`ms-4 ${styles.cumulativeCard}`}>
                                    Cumulative Score : {cumulativeScore}%
                                </div>
                            </div> */}
                        </div>
                        <div className=''>
                            <div className={`mt-4 p-4 py-5 rounded-2xl ${styles.videoPlay} justify-between`}>
                                <div className={`flex justify-between ${styles.kycVideoImageContainer}`}>
                                    <div className=''>
                                        <p className='font-semibold'>KYC video</p>
                                        <div className='flex mt-3'>
                                            {isVideoAvilable ? (
                                                <div className={``}>
                                                    <div className={`relative ${styles.fixedVideoHeight}`}>
                                                        <VideoThumbnail videoUrl={getVideoUrl()}
                                                            thumbnailHandler={(thumbnail) => setThumbnail(thumbnail)}
                                                        />
                                                        <button className={`ms-3 ${styles.playBtn} cursor-pointer absolute top-0 -left-3 h-full w-full flex justify-center items-center opacity-0 hover:opacity-100`} onClick={() => openVideoInIframe(getVideoUrl())}>
                                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={'assets/images/play_media.png'} alt="Play Video" style={{ marginRight: '5px', width: '40px' }} />
                                                            </span>
                                                        </button>
                                                    </div>
                                                    {/* will used in future */}
                                                    <div className='mt-5'>
                                                        <div className='flex justify-between'>
                                                            {/* <div>
                                                                <button className={`ms-3 ${styles.playBtn} cursor-pointer`} onClick={() => handleModal()}>
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={play} alt="Play Video" style={{ marginRight: '5px' }} />
                                                                        <span>Send Feedback</span>
                                                                    </span>
                                                                </button>
                                                            </div> */}
                                                            {/* <div>
                                                                <button className={`ms-3 ${styles.playBtn} cursor-pointer`} onClick={() => textModal()}>
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={play} alt="Play Video" style={{ marginRight: '5px' }} />
                                                                        <span>Show Text</span>
                                                                    </span>
                                                                </button>
                                                            </div> */}
                                                        </div>
                                                        {/* <div className={`${styles.kycVideoInfo} mt-2`}>
                                                                <p>Uploaded at : dummy text</p>
                                                                <p>File Type : dummy text</p>
                                                                <p>File Size : dummy text</p>
                                                                <p>Duration : dummy text</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='noImageDiv text-center p-1'>
                                                    No video available
                                                    <Icon iconName="noVideo" width={16} height={16} alt='no video' />
                                                </div>
                                            )}
                                        </div>
                                        <div id="videoContainer"></div>
                                    </div>
                                    <div className=''>
                                        <div className=''>
                                            <p className='font-semibold flex justify-center'>Image</p>
                                            <div className={`flex mt-3 justify-center ${styles.imageCnt}`} >
                                                {isImageAvailable ?
                                                    <img src={imgString} alt="KYC Image" width={160} />
                                                    :
                                                    <div className='noImageDiv'>
                                                        No Image
                                                        <Icon iconName="noImage" width={16} height={16} alt='no image' />
                                                    </div>
                                                }
                                            </div>
                                            {isImageAvailable && <p className='flex justify-center py-1'>{selectedRequestPersonalData?.full_name}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className=''>
                                        <div>
                                        <p className='font-semibold'>Audio</p>
                                        <div className='flex mt-3 flex-col' >
                                            <audio id="audioPlayer" className={`audioTagg ${!isAudio ? 'hidden' : ''}`} controls></audio>
                                            <>
                                                {/* will used in future */}
                                                {/* <div className={`${styles.kycVideoInfo} mt-2 ms-3`}>
                                                <p>Uploaded at : dummy text</p>
                                                <p>File Type : dummy text</p>
                                                <p>File Size : dummy text</p>
                                                <p>Duration : dummy text</p>
                                             </div> */}

                                            </>
                                            {!isAudio && <div className='mt-3 ms-3'>No audio available</div>}
                                        </div>
                                        </div>
                                        <div>
                                            <p className='mt-4 font-semibold'>Transcription</p>
                                            <div className='text-wrap p-2 tableBorder rounded-md pb-8'>{selectedAudioText ? selectedAudioText : "No Transcription available"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {showAlertMessage?.state &&
                                        <div id="popup-modal" tabindex="-1" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                                            <div class="relative p-4 w-full max-w-md max-h-full">
                                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                                    <button type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setShowAlertMessage({ ...showAlertMessage, state: false })}>
                                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                        </svg>
                                                        <span class="sr-only">Close modal</span>
                                                    </button>
                                                    <div class="p-4 md:p-5 text-center">
                                                        <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                                            {
                                                                showAlertMessage?.status === 'Approved' ? "Do you want to approve this request ?" : "Do you want to reject this request ?"
                                                            }
                                                        </h3>
                                                        <button data-modal-hide="popup-modal" type="button" class={`text-white bg-red-600 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center ${showAlertMessage?.status === 'Approved' ? 'bgApproved hover:bgApproved focus:bgApproved' : 'bg-red-600 focus:ring-red-300'}`} onClick={() => updateStatus(showAlertMessage?.status)}>
                                                            Yes, I'm sure
                                                        </button>
                                                        <button data-modal-hide="popup-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => setShowAlertMessage({ ...showAlertMessage, state: false })}>No, cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={`mt-4 flex flex-col justify-between gap-4 ${styles.viewRequestTableCompCnt}`}>
                                <div className={`flex rounded-md flex-col tableBorder overflow-hidden ${styles.viewRequestTableComp}`}>
                                    {/* will used in future */}
                                    {/* <div className='py-4 flex gap-2'>
                                        <span className='block' style={{ width: '100px'}}>
                                            <b>
                                                Audio Text :
                                            </b>
                                        </span>
                                        <span>
                                            {selectedAudioText?.length > 150 ? <span className='flex'>
                                                {`${selectedAudioText}...`} <button className='iIconCnt' onClick={textModal}>i</button>
                                            </span> 
                                            : selectedAudioText
                                            }
                                        </span>
                                    </div> */}
                                    {/* <div className='flex justify-between'>
                                        <div className={`${livenessScore > 75 ? `${styles.nameCard}` : `${styles.livenessCard}`} `}>
                                            Liveness Score <br/>
                                             {livenessScore}%
                                        </div>
                                        <div className={`${faceMatchScore > 75 ? `${styles.nameCard}` : `${styles.livenessCard}`} `}>
                                            Face Match Score <br/>
                                            {faceMatchScore}%
                                        </div>
                                        <div className={`${styles.disablecard}`}>
                                            Name Score <br/>
                                            --%
                                        </div>
                                        <div className={`${styles.cumulativeCard}`}>
                                            Cumulative Score <br/>
                                            {cumulativeScore}%
                                        </div>
                                    </div> */}

                            <div className={`min-w-full bg-white flex flex-col rounded-lg border border-solid border-gray-border text-left reconcilitionTable ${styles.shadow}`}>
                                <div className={`flex items-center p-2 border-b`}>
                                    <span className="text-gray-500 text-sm font-medium w-3/5">CUMULATIVE SCORE</span>
                                    <span className={`px-4 py-2 rounded-full border border-gray-600 w-2/5 ${getDynemicClass(cumulativeScore)}`}>{detailDataLoading ? 'Loading...' : cumulativeScore}</span>
                                </div>
                                <div className={`flex items-center p-2 border-b`}>
                                    <span className="text-gray-500 text-sm font-medium w-3/5">LIVENESS SCORE</span>
                                    <span className={`px-4 py-2 rounded-full border border-gray-600 w-2/5 ${getDynemicClass(livenessScore)}`}>{detailDataLoading ? 'Loading...' : livenessScore}</span>
                                </div>

                                <div className={`flex items-center p-2 border-b`}>
                                    <span className="text-gray-500 text-sm font-medium w-3/5">FACE MATCH SCORE</span>
                                    <span className={`px-4 py-2 rounded-full border border-gray-600 w-2/5 ${getDynemicClass(faceMatchScore)}`}>{detailDataLoading ? 'Loading...' : faceMatchScore}</span>
                                </div>
                                <div className={`flex items-center p-2`}>
                                    <span className={`text-gray-500 text-sm font-medium w-3/5`}>NAME SCORE</span>
                                    <span className={`px-4 py-2 rounded-full border border-gray-600 w-2/5 ${getDynemicClass(nameMatchScore)}`}>{detailDataLoading ? 'Loading...' : nameMatchScore}</span>
                                </div>
                            </div>

                                </div>
                                {/* will used in future */}
                                {/* <DataTable columns={requestColumnsPersonal} rowData={[selectedRequestPersonalData]} hidePagination={true} /> */}
                                <div className={`min-w-full bg-white flex rounded-lg border border-solid border-gray-border text-left reconcilitionTable`}>
                                    <div className="ps-2 pe-2 flex flex-col">
                                        {requestColumnsPersonal?.map((item, indx) =>
                                            <span className={`px-3 py-2`}>{item?.title}</span>
                                        )}
                                    </div>
                                    <div className={`flex flex-col w-3/5`}>
                                        <span className={`px-3 py-2`}>
                                            <button style={{ color: themeData?.color_config?.main_color, display: 'flex', alignItems: 'center', textDecoration: 'underline' }} onClick={()=>handleFileDownload('nid')}>
                                                NID File
                                                <div className='ms-2'><Icon iconName="EyeIcon" width={16} height={16} fill={themeData?.color_config?.main_color} /></div>
                                            </button>
                                        </span>
                                        <span className={`px-3 py-2`}>
                                            <button style={{ color: themeData?.color_config?.main_color, display: 'flex', alignItems: 'center', textDecoration: 'underline' }} onClick={()=>handleFileDownload('form')}>
                                                Paper Form
                                                <div className='ms-2'><Icon iconName="EyeIcon" width={16} height={16} fill={themeData?.color_config?.main_color} /></div>
                                            </button>
                                        </span>
                                        <span className={`px-3 py-2`}>
                                            <button className={`custom_btn_primary px-1 py-1 rounded-full border border-gray-600 ${styles.viewDetailsBtn} ${selectedRequestData?.process_status == 'In Progress' ? styles.nidDataLoading : ''} w-full`} disabled={selectedRequestData?.process_status == 'In Progress' ? true : false} onClick={viewNIDDetails} style={{ fontSize: '14px' }}>{selectedRequestData?.kyc_video == 'Yes' ? selectedRequestData?.process_status == 'In Progress' ? 'Loading...' : 'View Details' : 'View Details'}</button>
                                        </span>
                                    </div>
                                </div>
                                {/* will used in future */}
                                {/* <div className='flex ps-4 pt-4 pb-4'>
                                    <div className='flex'>
                                        <div className={`${styles.statusComplete}`}>
                                        </div>
                                        <div className={`ps-3 ${styles.textComplete}`}>
                                            Completed
                                        </div>
                                    </div>

                                    <div className='flex ps-6'>
                                        <div className={`${styles.statusPending}`}>
                                        </div>
                                        <div className={`ps-3 ${styles.textPending}`}>
                                            Pending
                                        </div>
                                    </div>

                                    <div className='flex ps-6'>
                                        <div className={`${styles.statusRejected}`}>
                                        </div>
                                        <div className={`ps-3 ${styles.textRejected}`}>
                                            Rejected
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>

                        <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 w-1/5 pt-4">
                            Comments
                        </h1>
                        <div className='flex gap-4 mt-4'>
                            <div className='border border-gray-300 p-2 userCircle flex'>
                                {userData?.first_name?.[0] + userData?.last_name?.[0]}
                            </div>
                            <div className='w-full'>
                                <input type="text" placeholder="Enter your comment here..." className="border border-gray-200 p-2 w-full rounded-md" onChange={(e) => setComment(e.target.value)} value={comment} />
                            </div>
                            <div>
                                <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn w-20" onClick={sendComment} disabled={loading}>
                                    {loading ?
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        : 'Post'}</button>
                            </div>
                        </div>
                        <div className='mt-4 ms-4'>
                            {commenstData?.map((data) => (<div className='flex gap-4 mt-4'>
                                <div className='border border-gray-300 p-2 flex subUserCircle custom_btn_primary'>
                                    {data?.full_name?.split(' ')[0]?.[0] + data?.full_name?.split(' ')[1]?.[0]}
                                </div>
                                <div className='w-full'>
                                    <p><b>{data?.full_name}</b></p>
                                    <p className=''>{getFormattedTime(data?.['created_at'])}</p>
                                    <p className='bg-white p-2 rounded w-full'>{data?.kyc_comments}</p>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ToastContainer />
    </>
    )
}

export default WithAuth(ViewRequest);
