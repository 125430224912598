import React, { useEffect, useState } from "react";
import API from "../../helpers/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
// import { ToastContainer, toast } from 'react-toastify';
// import API from "../helpers/api/index";
// import Select from 'react-select';
// import Icon from "./core-component/Icon";
// import { ColorRing } from 'react-loader-spinner';
export default function FeedbackModal({setOpenModal, setFormData, formData, getKycID}) {
    const [userData] = useSelector(( Gstate ) => [Gstate?.UserDataReducer?.user_data])
    const [commenstData, setCommenstData] = useState([]);
    const [comment, setComment] = useState('');
    const closeModal = () => {
        setOpenModal(false)
        setFormData({
            feedback: ''
        })
        // setErrors('');
    }

    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };
    const getUserID = () => {
        return userData?.id
    }
   
    const getComments = async () => {
        try {
            const query = {
                KYC_id: getKycID(),
                id: getUserID()
            }
            const response = await API.apiGet(`/videokyc/comments`, '', query)
            if (response?.data) {
                setCommenstData(response?.data?.data)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }
    }

    const sendComment = async () => {
        try {
            const query = {
                KYC_id: getKycID(),
                id: getUserID()
            }
            const payload = {
                kyc_comments: comment
            }
            const encodedPayload = encodeData(payload)
            const response = await API.apiPost(`/videokyc/comments`, {payload: encodedPayload}, query)
            if (response?.data) {
                getComments()
                toast.success(response?.data?.message)
            }
            else {
                toast.error(response?.data?.message)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            console.log(">>>>>>", error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
        }
    }
    const handleChange = (e) => {
        const {name, value} = e?.target;
        setFormData({...formData, [name]: value});
    }

    useEffect(() => {
        getComments();
    },[])
    return (
        <>
            {/* <!-- Main modal --> */}
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">
{/* 
                    {showLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                    </div>} */}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                       
                           
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Send Feedback
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="p-4 md:p-5 space-y-4">
                            <div className="">
                                {/* {fields.map((field) => (
                                    <div key={field.id} className="mb-4">
                                        <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
                                            {field.label}:
                                        </label>
                                        { field?.id === 'roles' ?
                                        <Select
                                            // isMulti
                                            name="role_id"
                                            options={roleOptions}
                                            className="basic-multi-select mt-1"
                                            classNamePrefix="select"
                                            value={formData.role_id}
                                            onChange={handleChange}
                                        />:
                                        field?.id === 'password' ? <div className="relative flex">
                                          <input
                                            type={showPassword ? 'text' : 'password'}
                                            id={field.id}
                                            name={field.name}
                                            value={formData[field.name]}
                                            placeholder={field.placeholder}
                                            onChange={handleChange}
                                            className={`mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md ${errors[field.name] ? 'border-red-500' : ''}`}
                                         />
                                         <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className=" bi bi-eye absolute right-4 top-4 focus:outline-none pass_eye"
                                        >
                                       {showPassword ? (
                                          <Icon iconName="EyeAlt" height={16} width={16}/>
                                        ) : (
                                          <Icon iconName="EyeAlt" height={16} width={16} className="absolute eyeIcon"/>
                                        )}
                                        </button>
                                        </div> 
                                        :
                                        <>
                                            <input
                                                type={field.type}
                                                id={field.id}
                                                name={field.name}
                                                value={formData[field.name]}
                                                placeholder={field.placeholder}
                                                onChange={handleChange}
                                                className={`mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md ${errors[field.name] ? 'border-red-500' : ''}`}
                                            />
                                        </>

                                        }                                                       
                                        {errors[field.name] && <p className="text-red-500 text-sm mt-1">{errors[field.name]}</p>}
                                    </div>
                                ))} */}
                                {/* <div className="mb-4">
                                    <Select
                                        isMulti
                                        name="role_id"
                                        options={colourOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={formData.role_id}
                                        onChange={handleChange}
                                    />
                                    {errors.role_id && <p className="text-red-500 text-sm mt-1">{errors.role_id}</p>}
                                </div> */}
                                {/* <div>
                                    <label className="font-bold" htmlFor="feedback">Feedback</label>
                                    <div>
                                    <textarea className="border mt-4 p-4" placeholder="Write your feedback here..." rows="10" cols="70" onChange={(e) => handleChange(e)} name="feedback" id="feedBack" value={formData?.feedback}/>
                                    </div>
                                </div> */}
                                {/* <button type="submit" className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors duration-300">Submit</button> */}
                                <div className="shadow-lg px-2 py-4">
                                    <div>
                                     {commenstData?.map((data) => (
                                         <span className="border border-red-800">{data?.kyc_comments}</span>
                                     ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <div>
                                <input type="text" placeholder="Enter your comment here..." className="border border-gray-200 p-2 w-80 rounded-md" onChange={(e) => setComment(e.target.value)} value={comment}/>
                            </div>
                            <div>
                                <button data-modal-hide="static-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 custom_btn_secondary" onClick={closeModal}>Cancel</button>
                                <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn custom_btn_primary" onClick={sendComment}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
