import { useLocation, useNavigate } from "react-router";
import bankAsiaImg from "../../assets/images/bankAsiaImg.svg";
import styles from "../../styles/allowPermissionsModal.module.scss";
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import API from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import { isTokenExpired } from "../../helpers/auth";
import genrateOtp from "../../assets/images/genrateOtp.svg";
import axios from "axios";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";

export default function OtpScreen() {

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState({ sendOtp: false, verifyOtp: false })
    const [currentStep , setCurrentStep] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();
    const [startTime, setStartTime] = useState(null);
    const [showStopWatch, setShowStopWatch] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [disableOtpBtn, setDisableOtpBtn] = useState(false)
    const remainingTime = Math.max(0, 30000 - elapsedTime);
    const seconds = Math.floor((remainingTime / 1000) % 60);

    async function getKycID() {
      const params = new URLSearchParams(location.search);
      return params.get('query') || '';
    }
    function decodeToken(token) {
      return window.atob(token);
    }
    async function handleSendOtp(type) {
        setDisableOtpBtn(true)
        if (type == 'resend') {
            startStopWatch();
        }
        setLoading({ ...loading, sendOtp: true })
        try {
            const kyc_id = await getKycID()
            const decoded_id = decodeToken(kyc_id)
            const payload = {
                kyc_id : decoded_id
            }
            const response = await API.apiPost('videokyc/generate/otp', {payload: encodeData(payload)})
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                setDisableOtpBtn(false)
                sessionStorage.setItem("otpStep", 2);
                setCurrentStep(2);
            }
            else {
                toast.error(response?.data?.error)
                setDisableOtpBtn(false)
            }
            setLoading({ ...loading, sendOtp: false })
        }
        catch (error) {
            handleErrorMessage(error);
            setLoading({ ...loading, sendOtp: false })
            setDisableOtpBtn(false)
            // console.log(error)
        }
    }
    async function verifyOtp() {
        setLoading({ ...loading, verifyOtp: true })
        try {
            const kyc_id = await getKycID()
            const decoded_id = decodeToken(kyc_id)
            const payload = {
              kyc_id : decoded_id,
              otp: otp
            }
            const response = await API.apiPost('videokyc/validate/otp', {payload: encodeData(payload)})
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                let id = await getKycID()
                navigate(`/permissionModal?query=${id}`)
                setCurrentStep(1);
                sessionStorage.setItem("otpStep", 1);
            }
            else {
                toast.error(response?.data?.message)
            }
            setLoading({ ...loading, verifyOtp: false })
        }
        catch (error) {
            handleErrorMessage(error);
            setLoading({ ...loading, verifyOtp: false })
            // toast.error(error?.response?.data?.message)
            console.log(error)
        }
    }
    useEffect(() => {
        const storedStartTime = sessionStorage.getItem('startTime');
        let isStopWatch = sessionStorage.getItem('showStopwatch');
        setShowStopWatch(JSON.parse(isStopWatch));
        if (storedStartTime) {
          setStartTime(parseInt(storedStartTime, 10));
        } else {
          const now = Date.now();
          sessionStorage.setItem('startTime', now);
          setShowStopWatch(false);
          setStartTime(now);
        }
     
        const timerInterval = setInterval(() => {
          const now = Date.now();
          const elapsed = now - startTime;
          setElapsedTime(elapsed);
          const timeLimit = 29000;
          if (elapsed >= timeLimit) {
            setShowStopWatch(false);
            sessionStorage.setItem('showStopwatch', false);
          }
        }, 1000);
     
        return () => clearInterval(timerInterval);
      }, [startTime]);

      const startStopWatch = () => {
        setShowStopWatch(true);
        setStartTime(Date.now());
        setElapsedTime(0);
        sessionStorage.setItem('startTime', Date.now());
        sessionStorage.setItem('showStopwatch', true);
      }

    useEffect(() => {
        let checkStep = sessionStorage.getItem("otpStep") || 1;
        if (checkStep) {
          checkStep = JSON.parse(checkStep);
          setCurrentStep(parseFloat(checkStep));
        } else {
          sessionStorage.setItem("otpStep", 1);
          setCurrentStep(1);
        }
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if(token){
          if(isTokenExpired(token)){
            toast('KYC session is expired');
            navigate('/linkExpired');
          }
        }else{
          navigate('/login');
        }
        
      }, []);

      async function verifyVideoUpload(){
        try {
          const kycId = await getKycID();
          const response = await API.apiGet(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/verify/upload?kyc_id=${decodeToken(kycId)}`, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });
     
          if (response.data.success) { 
            if(response.data.isVideoUploaded){
              navigate(`/linkExpired`);
            }
          }
        } catch (error) {
          handleErrorMessage(error);
        //   toast.error(error?.response?.data?.error || error?.message);
          console.error('Error sending video to backend:', error);
        }
      }

      const getUserLocationData = async() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }
      }

      function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        getIpAddres(latitude, longitude);
      }
      
      function error() {
        console.log("Unable to retrieve your location");
      }

      const getIpAddres = async(latitude, longitude) => {
        let ipAddress = await axios.get(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/get-ip`);
        saveUserLocationData(latitude, longitude, ipAddress?.data?.ip);
      }

      const saveUserLocationData = async(latitude, longitude, ipAddress) => {
        const kycId = await getKycID();
        const decoded_id = decodeToken(kycId);
        let payload = {
            kyc_id: decoded_id,
            kyc_location: {
                ip_address: ipAddress,
                geo_location: {
                    longitude: longitude,
                    latitude: latitude
                }
            }
        }
        const response = await API.apiPost('videokyc/location', {payload: encodeData(payload)})
        /**  need to confirm that 
        should we inform user about location capture or not for now adding just a console
        to check the api response.
        */
      }
    
      useEffect(() => {
       verifyVideoUpload();
       getUserLocationData();
      },[])

    return (
        <>
            <div className="p-4 min-h-screen flex items-center justify-center KycOtpScreen border border-solid border-gray-300">
                <div className={`flex justify-center ${styles.KycOtpMainCnt}`}>
                    <div className={`${styles.OtpScreenCnt} border rounded-xl shadow-md text-center w-full bg-white flex gap-2`}>
                        <div className="flex items-center">
                            <div className={`${styles.genrateOtpCnt} flex justify-center w-4/5`}>
                                <img src={genrateOtp} alt="logo" />
                            </div>
                        </div>
                        <div className={`${styles.otpScreenFormCnt} flex justify-center flex-col gap-4`}>
                            <div className="flex flex-col w-full">
                                <p className={`${styles.enterOtp}`}>
                                    { currentStep === 2 ? "Please Enter OTP" : "Generate OTP"}
                                </p>
                                <p className={`${styles.confirmPara}`}>
                                { currentStep === 2 ? "Confirm OTP shared with you through the link and proceed to verification process" : "Click Generate OTP to send a One time Password to your Registered Mobile Number." }
                                </p>
                            </div>
                            {currentStep === 2 && <div className={`pt-4 ${styles.otpInp} w-4/5`}>
                                {/* <label className={`${styles.enterHerePara}`}>Enter Here</label> */}
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    className="gap-2"
                                    inputStyle={{ border: '2px solid #11142D66', width: '50px', height: '50px', borderRadius: '10px' }}
                                    renderInput={(props) => <input type="number" className={`border ${styles.otpInp}`} {...props} />}
                                    inputType='tel'
                                />
                            </div>}

                            {currentStep === 2 ? <><div className={`pt-6 pb-2 ${styles.otpButtonCnt} flex`}>
                                    <button className={`border flex rounded-full justify-center ${ loading?.verifyOtp ? `${styles.disabledVerifyBtn}` : styles.verifyBtn} ${loading?.verifyOtp ? 'opacity-50' :''}`} onClick={verifyOtp} disabled={loading?.verifyOtp}> {loading?.verifyOtp ? 'loading...' : 'Verify OTP'}</button>
                                    {!showStopWatch ?
                                      <button className={`${styles.resendBtn} flex justify-center items-center w-4/5`} onClick={() => handleSendOtp('resend')} disabled={showStopWatch}>
                                        Resend OTP
                                      </button>
                                    :
                                      <div className={`ms-2 ${styles.resendOtpStopWatch}`}>
                                        {`Resend OTP in`}{' '}{seconds > 0 && <p className={`${styles.resendSeconds} ms-1`}>{seconds < 10 ? ` 0${seconds}s` : ` ${seconds}s`}</p>}
                                      </div>
                                    }
                                </div>
                                <div className="px-3">
                                    
                                </div>
                            </>
                            :
                                <div className={`${styles.submitBtnCnt}`}>
                                    <button  className={`${disableOtpBtn ? `${styles.disabledVerifyBtn}` : `${styles.verifyBtn}`}  flex items-center text-center justify-center rounded-full mt-10 text-white ${loading?.sendOtp ? 'opacity-50' :''}`} onClick={() => handleSendOtp('send')} disabled={loading?.sendOtp || disableOtpBtn}> {loading?.sendOtp ?'loading...' :'Generate OTP'} </button>
                                </div>
                            }
                        </div>
                        {/* : */}
                        {/* <div className={`${styles.sendOtpScreenContainer} pt-6`}>
                            <h3 className={`${styles.enterOtp}`}>
                                Genrate OTP
                            </h3>
                            <p className={`${styles.confirmPara}`}>Click Genrate to Genrate OTP to your registered mail</p>
                        </div> */}
                        
                    {/* } */}
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}