import React from 'react'
import { VideoCameraOutlined, AudioOutlined } from '@ant-design/icons';
import styles from '../styles/VideoCallScreen.module.scss';

const VideoCallInitiation = () => {
    return (
        <div className={styles.container}>
          <div className={styles.videoSection}>
            <div className={styles.videoBox}>
            <div className={styles.videoInfo}>
                <span className={styles.username}>Vishal Ulsai</span>
              </div>
              <div className={styles.videoStatus}>
                <VideoCameraOutlined className={styles.icon} />
              </div>
              <div className={styles.controls}>
              <div className={styles.videoInfo}>
                <span className={styles.connecting}>Connecting......</span>
              </div>
              <div className='' style={{display:'flex', gap:'10px'}}>
                <button className={styles.controlButton}>
                  <VideoCameraOutlined />
                </button>
                <button className={styles.controlButton}>
                  <AudioOutlined />
                </button>
              </div>
              <div style={{width:'120px'}}></div>
              </div>
            </div>
          </div>
          <div className={styles.callSection}>
            <h1 className={styles.callSectionHeader}>Initiate a Video Call</h1>
            <button className={styles.initiateButton}>Initiate Call</button>
            <button className={styles.cancelButton}>Cancel</button>
            {/* <div className={styles.linkSection}>
              <span>Copy Video Link</span>
              <a href="http://example.com" className={styles.videoLink}>
                http://lkjhgdsawertyuiopjhgfdzx
              </a>
            </div> */}
          </div>
        </div>
      );
}

export default VideoCallInitiation