import React, { useEffect } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import '../../styles/dashboard.scss';
import ConsumerFinanceModule from "./ConsumerFinanceTable";

const ConsumerFinance = () => {

  const isMobileDevice = window.screen.width <= 900; 

  useEffect(()=>{
    localStorage.setItem("activeTab", "Consumer Finance");
  },[])
  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main ${isMobileDevice && 'samdMainCotainer_responsive'}`}>
      <SideMenu headerName="Consumer Finance"/>
      <div className={`flex-1 ${!isMobileDevice && 'h-screen overflow-y-scroll'} ${isMobileDevice && 'samdCardContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-3 responsiveMainContainer'}`}>
          <div className=" flex flex-row justify-between">
            {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
              Consumer Finance
            </h1>}
          </div>
          <div className={`flex gap-6 flex-col ${isMobileDevice && 'flex-col'}`}>
          <ConsumerFinanceModule/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(ConsumerFinance);
