import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { WithAuth } from '../../common/WithAuth';
import ReconcilitionTable from "../../component/ReconcilitionTable";
import ReconciliationDetailsTable from "./ReconciliationDetailsTable";
import "../../styles/reconcilition.scss";
import DataTable from "../../component/DataTable";
import API from "../../helpers/api";
import { getCurrentProcessPerm } from "../../helpers/auth";
import Icon from "../../component/core-component/Icon";
import { handleErrorMessage } from "../../common/commonFunction";
import Pagination from "../../component/Pagination";
import Breadcrumb from "../../component/Breadcrumb";

function Reconciliation() {
  const navigate = useNavigate();
  const [progress1Loading, setProgress1Loading] = useState('noprogress');
  const [progress2Loading, setProgress2Loading] = useState('noprogress');
  const [pendingExcelLoading, setPendingExcelLoading] = useState('noprogress');
  const [data, setData] = useState(0);
  const [isLoading,setIsLoading] = useState(false);
  const [reconciliationTableData,setReconciliationTableData] = useState([])
  const [missingCount,setMissingCount] = useState(0)
  const [activeTab, setActiveTab] = useState('Reconciliation');
  const [detailsData, setDetailsData] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [matchedData, setMatchedData] = useState({
    "Pdf File Total": "loading...",
    "Pdf File Matched": "loading...",
    "Pdf File Matched %": "loading...",
    "Excel File Total": "loading...",
    Unmatched: "loading...",
    "Pending File Unmatched": "loading...",
    "Pending File Matched": "loading..."
  });
  const cardsColor = {
    "Pdf File Total": "#517DD2",
    "Pdf File Matched": "#196857",
    "Pdf File Matched %": "#196857",
    "Excel File Total": "#517DD2",
    Unmatched: "#FF0000",
    "Pending File Unmatched": "#FF0000",
    "Pending File Matched": "#196857"
  };
  const isMobileDevice = window.screen.width <= 900;
  const width = window.screen.width;
  const minWidth = 901;
  const maxWidth = 1200;

  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNpages] = useState(1);
  const [recordsPerPage] = useState(6);
  const currentRecords = detailsData;
  const [showPopup, setShowPopup] = useState(false);
  const [steps,setSteps] = useState(["Accounts Department", "Reconciliation"]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  function handlePageChange(currentPage) {
    setDetailsData([]);
    setCurrentPage(currentPage);
    getReconciliationLogDetails(currentPage);
  }

  const handleFileChangepdf = (event,isDragged) => {
    setProgress1Loading('noprogress')
    const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
    // pdf.name = ''
      if (pdf && pdf.type === "application/pdf") {
        setProgress1Loading('uploading');
        setTimeout(()=> {
          setProgress1Loading('uploaded');
          setData({ ...data, pdf });
        },3000)
      } else {
        event.target.value=''
        setData({ ...data, pdf:'' });
        toast.error("Please upload .pdf file.");
      }
  };
  const handleFileChangeexcel = (event, isDragged) => {
    setProgress2Loading('noprogress')
    const excel = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
    if (excel && excel.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setProgress2Loading('uploading');
        setTimeout(()=>{ 
          setProgress2Loading('uploaded');
          setData({ ...data, excel });
        },3000)
    }
    else{
        event.target.value=''
        setData({ ...data, excel:''});
        toast.error("Please select an .xlsx file.");
    }
  };
  const handleUploadPendingExcel = (event, isDragged) => {
    setReconciliationTableData([])
    setPendingExcelLoading('noprogress')
    const pendingExcel = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
    if (pendingExcel && pendingExcel.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setPendingExcelLoading('uploading');
        setTimeout(()=>{ 
          setPendingExcelLoading('uploaded');
          setData({ ...data, pendingExcel });
        },3000)
    }
    else{
        event.target.value = ''
        setData({ ...data, pendingExcel: ''});
        toast.error("Please select an .xlsx file.");
    }
  }
  const excelDropHandler = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    handleFileChangeexcel(e, true)
  }

  const pendingExcelDropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleUploadPendingExcel(e, true)
  }

  const pdfDropHandler = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    handleFileChangepdf(e,true)
  }
  const dragOverHandler = (e) =>{
    e.preventDefault();
    e.stopPropagation();
  }

  const handleClear = (filetype) => {
      setData({...data,[filetype]:''})
      setReconciliationTableData([])
      if(filetype==='pdf') setProgress1Loading('noprogress')
      else if (filetype == 'excel') setProgress2Loading('noprogress')
      else setPendingExcelLoading('noprogress')
  }
  const uploadFile = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdf", data.pdf);
    formData.append("excel", data.excel);
    if (data?.pendingExcel) {
      formData.append("last_month_excel", data.pendingExcel);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/ocr`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
        }
      });
      if (response.status === 200) {
        const {
          data, 
          filePath, 
          matchedFilePath, 
          count, 
          total_trans_count_file_1, 
          total_trans_count_file_2, 
          unmatched_trans_count, 
          matched_trans_count,
          unmatchedPendingFileTranactionCount,
          matchedPendingFileTranactionCount,
        } = await response.json()
        let upperVal = (matched_trans_count)*100
        let finalPer = upperVal/total_trans_count_file_1
        setMatchedData({
          "Pdf File Total": total_trans_count_file_1,
          "Pdf File Matched": matched_trans_count,
          "Pdf File Matched %": `${finalPer?.toFixed(2)}%`,
          "Excel File Total": total_trans_count_file_2,
          Unmatched: unmatched_trans_count,
          "Pending File Unmatched": unmatchedPendingFileTranactionCount,
          "Pending File Matched": matchedPendingFileTranactionCount
        })
        setReconciliationTableData(data)
        setMissingCount(count)
        if(filePath){
          const formData = new FormData();
          formData.append("path",filePath)
          const fileApiResponse  = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/ocr/sendfile`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
          }})
          const blob = await fileApiResponse.blob();
          let fileName = 'mismatched.xlsx'; // Default file name
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        if(matchedFilePath){
          const formData = new FormData();
          formData.append("path", matchedFilePath)
          const fileApiResponse  = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/ocr/sendfile`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
          }})
          const blob = await fileApiResponse.blob();
          let fileName = "matched.xlsx"; // Default file name
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const reconciliationColumns = [
    {
      title: 'Stellar',
      key: 'Stellar',
      style: { width: 'width: 70%'},
    },
    {
      title: 'Date',
      key: 'Date',
    },
    {
      title: 'Particulars',
      key: 'Particulars',
    },
    {
      title: 'DEBIT/CREDIT',
      key: 'D',
    },
    {
      title: 'Amount',
      key: 'Amount',
    },
  ]

  const DetailsColumns = [
    {
      title: 'Username',
      key: 'user_name',
      style: { width: 'width: 70%'},
    },
    {
      title: 'Pdf File',
      key: 'file_1_name',
    },
    {
      title: 'Excel File',
      key: 'file_2_name',
    },
    {
      title: 'Pdf Total Count',
      key: 'total_trans_count_file_1',
    },
    {
      title: 'Excel Total Count',
      key: 'total_trans_count_file_2',
    },
    {
      title: 'Matched Count',
      key: 'matched_trans_count'
    },
    {
      title: 'Unmatched Count',
      key: 'unmatched_trans_count'
    },
    {
      title: 'Requested At',
      key: 'created_at',
    },
    // {
    //   title: 'Updated At',
    //   key: 'updated_at',
    // },
    {
      title: 'Status',
      key: 'status',
    },
  ]

  const handleTabClick = (tab) => {
    if(tab === 'Details') {
      setSteps(['Accounts Department', 'Reconciliation', 'Details'])
    }else{
      setSteps(['Accounts Department', 'Reconciliation'])
    }
    setActiveTab(tab);
  };

  const getReconciliationLogDetails = async (currentPage) => {
    try {
      setDataLoading(true);
      // const queryParamValue = getUserID()
      const { data } = await API.apiGet(`/ocr/reconciliation-records?limit=${recordsPerPage}&skip=${currentPage}`);
      if (data?.success) {
        setDetailsData(data?.data);
        setNpages(data?.totalPageCount);
        setDataLoading(false);
      }
      else {
        toast.error(data?.message)
        setDataLoading(false)
      }
    }
    catch (error) {
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.message)
        setDataLoading(false)
    }
  }

  useEffect(()=>{
    // return () => {
      setDataLoading(true)
      getReconciliationLogDetails(1);
    // }
  },[])

  const handleBack = () => {
    navigate('/accountsDepartment');
  }

  const handleTableData = (item) => {
    if(width >= 768 && width <= 1100) {
      if(item?.length >= 36) return item?.substring(0,36)+'...';
      else return item;
    }
    else if(width >= 500 && width <= 767) {
      if(item?.length >= 20) return item?.substring(0,20)+'...';
      else return item;    
    }
    if(item?.length >= 12) return item?.substring(0,12)+'...';
    else return item;
  };

  const handlePopup = (item, index) => {
    setShowPopup(!showPopup);
  };  

  return (
    <>
      <div className={`flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part mainBodyContainer`} style={isMobileDevice ? {borderRadius: "0px"} : {borderRadius: "15px"}}>
        <SideMenu headerName="Reconciliation"/>
        <div className={`flex-1 ${isMobileDevice && 'min-h-screen h-screen overflow-y-auto mainContainer_responsive'}`}>
          <Header/>
          {/* {dataLoading && 
            <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
              <ColorRing
               visible={true}
               height="80"
               width="60"
               ariaLabel="color-ring-loading"
               wrapperStyle={{}}
               wrapperClass="color-ring-wrapper"
               colors={[]}
             />
            </div>
          } */}
          <div className={`reconcilationMainComp reconcilationFixedHeightContainer ${isMobileDevice && 'reconciliationMobileMainComp'}`}>
            { getCurrentProcessPerm(2)?.write && !isMobileDevice &&
              <div class={`flex relative ${isMobileDevice ? 'm-auto ps-0 reconciliationContainer' : 'mt-3 ms-6 ps-2'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
                {!isMobileDevice && 
                  <Breadcrumb showBackButton redirectUrl="/accountsDepartment" steps={steps} activeStep={steps?.length - 1} />
                }
              </div>
            }  
            <div className={`z-0 container ps-10 pt-2 pe-10 ${isMobileDevice && 'ps-4 pe-4'}`}>
              <div className=" flex tabContainer">
                    <div className="differentTabs flex">
                      <div
                        className={`w-content p-2 text-center tabsName ${ activeTab === 'Reconciliation' ? "showActiveColor commmonActiveTag" : ""}`}
                      >
                        <p className="mt-2 cursor-pointer text-base" onClick={() => handleTabClick('Reconciliation')}>
                        Reconciliation
                        </p>
                      </div>
                      <div
                        className={`w-24 p-2 text-center tabsName ${ activeTab === 'Details' ? "showActiveColor commmonActiveTag" : ""}`}
                      >
                        <p className="mt-2 cursor-pointer text-base" onClick={() => handleTabClick('Details')}>
                        Details
                        </p>
                      </div>
                    </div>
              </div>
            </div>

            {activeTab==='Reconciliation' && 
              <div className={`z-0 container p-10 ${isMobileDevice && 'reconMainContainer'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
                  <div className="flex gap-4 flex-col">
                    <div className={`flex gap-2 ${(width >= minWidth && width <= maxWidth ) && 'flex-col'} ${isMobileDevice && 'flex-col justify-center items-center mt-8'}`}>
                      <div className={`bg-white p-14 rounded-md w-1/2 flex flex-col items-center justify-center file-upload-container gap-4 ${(width >= minWidth && width <= maxWidth) && 'w-full'} ${isMobileDevice && 'reconciliationContainer p-8'}`}>
                        <span className="text-2xl font-semibold mb-4 text-center w-full block">
                          {progress1Loading === 'noprogress' &&  'Upload Banking Document'}
                          {progress1Loading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload PDF File </span>}
                          {progress1Loading === 'uploading' && 'Uploading Document...'}
                          {progress1Loading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="/assets/images/double_tick.svg" style={{height:'15px'}}/>Uploaded</span> }
                        </span>
                        <div className="w-60 h-40 flex flex-col items-center justify-center p-4 border-2 border-dotted" onDrop={pdfDropHandler} onDragOver={dragOverHandler}>
                          <span style={{width:'45px'}} className={!data?.pdf?.name ? 'un_selected' : ''} >
                            <img src="/assets/images/pdf.svg"></img>
                          </span>
                        { data?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={data?.pdf?.name} >{data?.pdf?.name?.length>20 ? data?.pdf?.name?.substring(0,20) + "..." : data?.pdf?.name }{!isLoading && <img src="/assets/images/cross.png" className="cross absolute" onClick={()=> handleClear('pdf')}/>}</span> }
                        { progress1Loading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext mt-2`}>Drag and Drop Files here</span> }
                        </div>

                        {progress1Loading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                          <span className="rule me-1"/> Or <span className="rule ms-1"/>
                        </p>
                        <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                          <input
                            type="file"
                            accept="application/pdf" 
                            onChange={handleFileChangepdf}
                            className="mb-4"
                            hidden
                          />
                          Upload
                          <img src="/assets/images/icon_upload.svg"/>
                        </label>
                        </>
                        }
                        { progress1Loading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                          <div
                            className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                          ></div>
                        </div>}
                      </div>
                      <div className={`bg-white p-14 rounded-md w-1/2 flex flex-col items-center justify-center file-upload-container gap-4 ${(width >= minWidth && width <= maxWidth) && 'w-full'} ${isMobileDevice && 'reconciliationContainer mt-1 p-8'}`}>
                        <span className="text-2xl font-semibold mb-4 text-center w-full block">
                          {  progress2Loading === 'noprogress' ? 'Upload Banking Document' : "" }
                          {  progress2Loading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload EXCEL File </span> }
                          {  progress2Loading === 'uploading' && 'Uploading Document... ' }
                          {  progress2Loading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="/assets/images/double_tick.svg" style={{height:'15px'}}/>Uploaded</span> }
                        </span>
                        <div className="w-60 h-40 flex flex-col items-center justify-center p-4 border-2 border-dotted" onDrop={excelDropHandler} onDragOver={dragOverHandler}>
                          <span style={{width:'55px'}} className={!data?.excel?.name ? 'un_selected' : ''}>
                            <img src="/assets/images/excel.svg"></img>
                          </span>
                        { data?.excel?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={data?.excel?.name}>{ data?.excel?.name?.length>20 ? data?.excel?.name?.substring(0,20) + "..." : data?.excel?.name }{!isLoading && <img src="/assets/images/cross.png" className="cross absolute" onClick={() => handleClear('excel')}/>}</span> }
                        { progress2Loading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext mt-2`}>Drag and Drop Files here</span>}
                        </div>
                        
                        { progress2Loading === 'noprogress' && <><p className="text-center flex w-full justify-center items-center light-grey">
                          <span className="rule me-1"/> Or <span className="rule ms-1"/>
                        </p>
                        <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center button flex justify-center custom_btn_primary commonBtn">
                          <input
                            type="file"
                            accept=".xlsx" 
                            onChange={handleFileChangeexcel}
                            className="mb-4"
                            hidden
                          />
                          Upload
                          <img src="/assets/images/icon_upload.svg"/>
                        </label>
                        </>}
                        { progress2Loading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                          <div
                            className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                          ></div>
                        </div>}
                      </div>
                      <div className={`bg-white p-14 rounded-md w-1/2 flex flex-col items-center justify-center file-upload-container gap-4 ${(width >= minWidth && width <= maxWidth) && 'w-full'} ${isMobileDevice && 'reconciliationContainer mt-1 p-8'}`}>
                        <span className="text-2xl font-semibold mb-4 text-center w-full block">
                          {  pendingExcelLoading === 'noprogress' ? 'Upload Pending Document' : "" }
                          {  pendingExcelLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload EXCEL File </span> }
                          {  pendingExcelLoading === 'uploading' && 'Uploading Document... ' }
                          {  pendingExcelLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="/assets/images/double_tick.svg" style={{height:'15px'}}/>Uploaded</span> }
                        </span>
                        <div className="w-60 h-40 flex flex-col items-center justify-center p-4 border-2 border-dotted" onDrop={pendingExcelDropHandler} onDragOver={dragOverHandler}>
                          <span style={{width:'55px'}} className={!data?.pendingExcel?.name ? 'un_selected' : ''}>
                            <img src="/assets/images/excel.svg"></img>
                          </span>
                        { data?.pendingExcel?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={data?.pendingExcel?.name}>{ data?.pendingExcel?.name?.length>20 ? data?.pendingExcel?.name?.substring(0,20) + "..." : data?.excel?.name }{!isLoading && <img src="/assets/images/cross.png" className="cross absolute" onClick={() => handleClear('pendingExcel')}/>}</span> }
                        { pendingExcelLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext mt-2`}>Drag and Drop Files here</span>}
                        </div>
                        
                        { pendingExcelLoading === 'noprogress' && <><p className="text-center flex w-full justify-center items-center light-grey">
                          <span className="rule me-1"/> Or <span className="rule ms-1"/>
                        </p>
                        <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center button flex justify-center custom_btn_primary commonBtn">
                          <input
                            type="file"
                            accept=".xlsx" 
                            onChange={handleUploadPendingExcel}
                            className="mb-4"
                            hidden
                          />
                          Upload
                          <img src="/assets/images/icon_upload.svg"/>
                        </label>
                        </>}
                        { pendingExcelLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                          <div
                            className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                          ></div>
                        </div>}
                      </div>
                    </div>
                    { !reconciliationTableData.length && <div className={`w-full text-center p-4 flex justify-center ${isMobileDevice && 'mb-4'}`}>
                      {!isLoading ? <button
                        onClick={uploadFile}
                        className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded disabled:disabledBtn custom_btn_primary uploadAndCompareBtn ${!(data.pdf && data.excel) ? 'disabledBtn' : '' }`}
                        disabled={!(data.pdf && data.excel)}
                      >
                        Upload And Compare
                      </button>
                      :
                      <ColorRing
                            visible={true}
                            height="50"
                            width="50"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                    />}
                    </div>}
                  </div>
                { reconciliationTableData?.length ? 
                <div className="py-4">
                  <div className="mb-4 flex flex-col">
                    <span className="text-xl font-bold">Compared Results</span>
                    { missingCount ? <span className="text-red-500"><img src="/assets/images/alert-info.svg" className="inline"/>{`${missingCount} Missing Records`}</span> : ''}
                  </div>
                  <ReconcilitionTable columns={reconciliationColumns} data={reconciliationTableData} matchedData={matchedData} cardsColor={cardsColor} />
                </div>: ''}
              </div>
            }
            {activeTab==='Details' && 
              <div className={`z-0 container ps-10 pt-7 pe-10 ${isMobileDevice && 'ps-4 pe-4'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
                {isMobileDevice || width <= 1200 ? (
                  <div>
                    <div>
                      {currentRecords?.map((item, index) => (
                        <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Username</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.user_name)}</span> 
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.user_name}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Pdf File</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.file_1_name)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.file_1_name}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Excel File</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.file_2_name)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.file_2_name}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Pdf Total Count</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.total_trans_count_file_1)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.total_trans_count_file_1}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Excel Total Count</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.total_trans_count_file_2)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.total_trans_count_file_2}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Matched Count</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.matched_trans_count)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.matched_trans_count}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Unmatched Count</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.unmatched_trans_count)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.unmatched_trans_count}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Requested At</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.created_at)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.created_at}</div>
                              </Popup>
                            </div>
                          </p>
                          <p className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                            <span className="font-bold">Status</span>
                            <div className="flex items-center justify-between">
                              <span className="mr-5">{handleTableData(item?.status)}</span>
                              <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.status}</div>
                              </Popup>
                            </div>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="reponsivePagination">
                      <Pagination nPages={nPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      ></Pagination>
                    </div>
                  </div>
                ) : (
                  <ReconciliationDetailsTable 
                    columns={DetailsColumns}
                    rowData={detailsData}
                    tableLoading={dataLoading}
                    nPages={nPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                  />
                )}
              </div>
            }
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
}
export default WithAuth(Reconciliation);
