import styles from "../styles/navbar.module.scss"
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

    const [showDropdown, setShowDropdown] = useState(false);
    const [adminDropdown, setAdminDropdown] = useState(false);
    const [transactionDropdown, setTransactionDropdown] = useState(false);
    const [changeRequestDropdown, setChangeRequestDropdown] = useState(false);
    const [changeTransactionLimit, setChangeTransactionLimit] = useState(false);
    const [changeAccountInfoDropdown, setChangeAccountInfoDropdown] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () =>{ 
        setMobileMenuOpen(!mobileMenuOpen)
        setTransactionDropdown(false);
        setAdminDropdown(false);
        setChangeAccountInfoDropdown(false);
        setShowDropdown(false);
    };
    const navigate = useNavigate();
    const isMobileDevice = window.screen.width <= 650;

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
        setTransactionDropdown(false);
        setAdminDropdown(false);
        setChangeAccountInfoDropdown(false);
    }

    const toggleAdminDropdown = (event) => {
        setAdminDropdown(!adminDropdown);
        setShowDropdown(false);
        setTransactionDropdown(false);
        setChangeRequestDropdown(false);
        setChangeTransactionLimit(false);
    }

    const toggleTransactionDropdown = () => {
        setTransactionDropdown(!transactionDropdown);
        setAdminDropdown(false);
        setShowDropdown(false);
    }

    const openTransactionLimit = () => {
        setChangeTransactionLimit(!changeTransactionLimit);
        setChangeRequestDropdown(false);
    }

    const openAccountInfoRequest = () => {
        setChangeAccountInfoDropdown(!changeAccountInfoDropdown);
    }

    const openAgentBanking = () => {
        navigate(`/nomineeDetails`);
    }

    const openSearchAccount = () => {
        navigate(`/SearchAccount`);
    }

    const openAuthorisationRequest = () => {
        setChangeRequestDropdown(!changeRequestDropdown);
        setChangeTransactionLimit(false);
    }

    const openCustomerLimit = () => {
        navigate(`/transactionControl`);
    }

    const openNomineeInfo = () => {
        navigate(`/nomineeDetailsPage`)
    }

    const openRaiseRequest = () => {
        navigate(`/RaiseAccountRequest`);
    }

    const openAccountTitle = () =>{
        navigate(`/AccountTitleChange`);
        setShowDropdown(false);
    }

    const openSignatureChangeRequest = () =>{
        navigate("/changeSignatureCard");
        setShowDropdown(false);
    }

    const openChangeLimitRequest = () =>{
        navigate("/changeLimitUpdate");
        setShowDropdown(false);
    }

    const openPhotgraphChangeRequest = () =>{
        navigate("/changeProfile");
        setShowDropdown(false);
    }

    const openPersonalInfoChangeRequest = () =>{
        navigate("/changePersonalInfo");
        setShowDropdown(false);
    }

    const openAccountDetailsChangeRequest = () =>{
        navigate("/changeAccountDetails");
        setShowDropdown(false);
    }


    return (
        <>
            <div className={`${styles.navbar}`}>
                <nav className="bg-white">
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-center">
                            <div className="absolute inset-y-0 right-0 flex items-center justify-end sm:hidden">
                                <button
                                    type="button"
                                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                    aria-controls="mobile-menu"
                                    aria-expanded={mobileMenuOpen}
                                    onClick={toggleMobileMenu}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <svg className={`${mobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                    <svg className={`${mobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        <div className="relative">
                                            <div onClick={toggleAdminDropdown} className="flex z-50">
                                                <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Administration</a>
                                                <img src='/assets/right-icon.svg'
                                                    height={24}
                                                    width={24}
                                                    className={`${styles.toggleMenuItems} ${adminDropdown ? styles.showMenuItems : ''}`}
                                                ></img>
                                            </div>
                                            {adminDropdown && (
                                                <div className="z-50 max-w-max border absolute top-full left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-2 rounded w-max">
                                                    <div className="relative">
                                                        <div onClick={openAuthorisationRequest} className="flex justify-between">
                                                            <a className="block cursor-pointer">Authorization</a>
                                                            <img src='/assets/right-icon.svg'
                                                                height={24}
                                                                width={24}
                                                                className={`${styles.toggleMenuItems}`}
                                                            ></img>
                                                        </div>
                                                        {changeRequestDropdown && (
                                                            <div className="z-50 max-w-max border absolute top-0 left-full bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-0 ml-2 rounded w-max">
                                                                {/* Your nested elements here */}
                                                                <a onClick={openRaiseRequest} className="block cursor-pointer">Change Request Approval</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="relative mt-2 z-50">
                                                        <div onClick={openTransactionLimit} className="flex justify-between">
                                                            <a className="block cursor-pointer">Trans Limit Change</a>
                                                            <img src='/assets/right-icon.svg'
                                                                height={24}
                                                                width={24}
                                                                className={`${styles.toggleMenuItems}`}
                                                            ></img>
                                                        </div>
                                                        {changeTransactionLimit && (
                                                            <div className="z-50 max-w-max border absolute top-0 left-full bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-0 ml-2 rounded w-max">
                                                                {/* Your nested elements here */}
                                                                <a onClick={openCustomerLimit} className="block cursor-pointer">Customer Limit Change</a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>



                                        <div
                                            className="relative"
                                            onClick={toggleTransactionDropdown}>
                                            <div className="flex">
                                                <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Transaction</a>
                                                <img src='/assets/right-icon.svg'
                                                    height={24}
                                                    width={24}
                                                    className={`${styles.toggleMenuItems} ${transactionDropdown ? styles.showMenuItems : ''}`}
                                                ></img>
                                            </div>
                                            {transactionDropdown && (
                                                <div className="max-w-max absolute border top-full left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-2 rounded w-max">
                                                    <a onClick={() => navigate("/transactionType")} className="block cursor-pointer">Transaction Type</a>
                                                    {/* <a href="#" className="block cursor-pointer">Account Title</a> */}
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className="relative"

                                        >
                                            <div className="flex" onClick={toggleDropdown}>
                                                <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Account Management</a>
                                                <img src='/assets/right-icon.svg'
                                                    height={24}
                                                    width={24}
                                                    className={`${styles.toggleMenuItems} ${showDropdown ? styles.showMenuItems : ''}`}
                                                ></img>
                                            </div>
                                            {showDropdown && (
                                                <div className=" z-50 absolute top-full border left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-2 rounded w-max">

                                                    <div className="relative">
                                                        <div onClick={openAccountInfoRequest} className="flex justify-between">
                                                            <a className="block cursor-pointer">Change A/C Info</a>
                                                            <img src='/assets/right-icon.svg'
                                                                height={24}
                                                                width={24}
                                                                className={`${styles.toggleMenuItems}`}
                                                            ></img>
                                                        </div>
                                                        {changeAccountInfoDropdown && (
                                                            <div className="max-w-max border absolute top-0 left-full bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-0 ml-2 rounded w-max">
                                                                {/* Your nested elements here */}
                                                                <a onClick={openAccountTitle} className="block cursor-pointer">A/C Title Change</a>
                                                                <a onClick={openChangeLimitRequest} className="block mt-2 cursor-pointer">Change Limit Update</a>
                                                                <a onClick={openSignatureChangeRequest} className="block mt-2 cursor-pointer">Signature Card</a>
                                                                <a onClick={openPhotgraphChangeRequest} className="block mt-2 cursor-pointer">Change Photograph</a>
                                                                <a onClick={openPersonalInfoChangeRequest} className="block mt-2 cursor-pointer">Personal Information</a>
                                                                <a onClick={openAccountDetailsChangeRequest} className="block mt-2 cursor-pointer">Account Details</a>
                                                                <a onClick={openNomineeInfo} className="block mt-2 cursor-pointer">Change Nominee Info</a>

                                                            </div>
                                                        )}
                                                    </div>
                                                    <a onClick={openSearchAccount} className="block mt-2 cursor-pointer">Account Details Report</a>
                                                    {/* <a href="#" className="block cursor-pointer">Account Title</a> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isMobileDevice ? "" :<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <div className="relative ml-3">
                                    <div
                                        className="relative"
                                    >
                                        <div onClick={openAgentBanking} className="flex justify-end">
                                            <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">System Home</a>
                                            <img src='/assets/right-icon.svg'
                                                height={24}
                                                width={24}
                                                className={`${styles.toggleMenuItems}`}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                    {mobileMenuOpen && (
                        <div className="sm:hidden  z-50 absolute w-full border left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 rounded" id="mobile-menu">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                <div className="relative">
                                    <div onClick={toggleAdminDropdown} className="flex z-50">
                                        <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Administration</a>
                                        <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems} ${adminDropdown ? styles.showMenuItems : ''}`} />
                                    </div>
                                    {adminDropdown && (
                                        <div className="z-50 max-w-max border absolute top-full left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-2 rounded w-max">
                                            <div className="relative">
                                                <div onClick={openAuthorisationRequest} className="flex justify-between">
                                                    <a className="block cursor-pointer">Authorization</a>
                                                    <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems} ${changeRequestDropdown ? styles.showMenuItems : ''}`} />
                                                </div>
                                                {changeRequestDropdown && (
                                                    <div className="z-50 max-w-max bg-white text-gray-500 text-base font-semibold py-2 mt-0 ml-2 rounded w-max">
                                                        <a onClick={openRaiseRequest} className="block cursor-pointer">Change Request Approval</a>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="relative mt-2 z-50">
                                                <div onClick={openTransactionLimit} className="flex justify-between">
                                                    <a className="block cursor-pointer">Trans Limit Change</a>
                                                    <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems} ${changeTransactionLimit ? styles.showMenuItems : ''}`} />
                                                </div>
                                                {changeTransactionLimit && (
                                                    <div className="z-50 max-w-max bg-white text-gray-500 text-base font-semibold py-2 mt-0 ml-2 rounded w-max">
                                                        <a onClick={openCustomerLimit} className="block cursor-pointer">Customer Limit Change</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="relative" onClick={toggleTransactionDropdown}>
                                    <div className="flex">
                                        <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Transaction</a>
                                        <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems} ${transactionDropdown ? styles.showMenuItems : ''}`} />
                                    </div>
                                    {transactionDropdown && (
                                        <div className="max-w-max z-50 absolute border top-full left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 mt-2 rounded w-max">
                                            <a onClick={() => navigate("/transactionType")} className="block cursor-pointer">Transaction Type</a>
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <div className="flex" onClick={toggleDropdown}>
                                        <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">Account Management</a>
                                        <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems} ${showDropdown ? styles.showMenuItems : ''}`} />
                                    </div>
                                    {showDropdown && (
                                        <div className="z-50 absolute top-full border left-0 bg-white text-gray-500 text-base font-semibold py-2 px-4 rounded w-max">
                                            <div className="relative">
                                                <div onClick={openAccountInfoRequest} className="flex justify-between">
                                                    <a className="block cursor-pointer">Change A/C Info</a>
                                                    <img src='/assets/right-icon.svg' height={24} width={24} className={`${styles.toggleMenuItems}`} />
                                                </div>
                                                {changeAccountInfoDropdown && (
                                                    <div className="max-w-max bg-white text-gray-500 text-base font-semibold py-2 mt-0 ml-2 rounded w-max">
                                                        <a onClick={openAccountTitle} className="block cursor-pointer">A/C Title Change</a>
                                                        <a onClick={openChangeLimitRequest} className="block mt-2 cursor-pointer">Change Limit Update</a>
                                                        <a onClick={openSignatureChangeRequest} className="block mt-2 cursor-pointer">Signature Card</a>
                                                        <a onClick={openPhotgraphChangeRequest} className="block mt-2 cursor-pointer">Change Photograph</a>
                                                        <a onClick={openPersonalInfoChangeRequest} className="block mt-2 cursor-pointer">Personal Information</a>
                                                        <a onClick={openAccountDetailsChangeRequest} className="block mt-2 cursor-pointer">Account Details</a>
                                                        <a onClick={openNomineeInfo} className="block mt-2 cursor-pointer">Change Nominee Info</a>
                                                    </div>
                                                )}
                                            </div>
                                            <a onClick={openSearchAccount} className="block mt-2 cursor-pointer">Account Details Report</a>
                                        </div>
                                    )}
                                </div>

                                <div className="relative ">
                                    <div
                                        className="relative"
                                    >
                                        <div onClick={openAgentBanking} className="flex">
                                            <a className="text-gray-500 cursor-pointer font-quicksand rounded-md px-3 py-2 text-base font-semibold">System Home</a>
                                            <img src='/assets/right-icon.svg'
                                                height={24}
                                                width={24}
                                                className={`${styles.toggleMenuItems}`}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </nav>


            </div>
        </>
    )
}