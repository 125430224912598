import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import { WithAuth } from '../../common/WithAuth';
import moment from 'moment';
import "../../styles/userConfig.scss";
import "../../styles/reconcilition.scss";
import API from "../../helpers/api/index";
import Icon from "../../component/core-component/Icon";
import Select from 'react-select'
import DataTable from "../../component/DataTable";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import Pagination from "../../component/Pagination";
import { useSelector } from "react-redux";

function Contact() {
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [formData, setFormData] = useState({
        request_type: "",
        message: "",
        subject:"",
    });
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('Contact');
    const [contactDetails, setContactDetails] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [filteredSubjectData, setFilteredSubjectData] = useState([]);
    const DetailsColumns = [
        {
          title: 'Request Type',
          key: 'request_type',
          width:"20%",
        },
        {
          title: 'Subject',
          key: 'subject',
          width:"20%",
        },
        {
            title: 'Message',
            key: 'message',
            width:"40%",
          },
        {
            title: 'Submitted At',
            key: 'created_at',
            width:"14%",
          }
      ]
      
    const isMobileDevice = window.screen.width <= 900;

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
  
    useEffect(() => {
        setCurrentPage(1);
    }, [filteredSubjectData || contactDetails]);
    
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredSubjectData?.length ? filteredSubjectData?.slice(indexOfFirstRecord, indexOfLastRecord) : contactDetails?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = filteredSubjectData?.length ? Math.ceil(filteredSubjectData?.length / recordsPerPage) : Math.ceil(contactDetails?.length / recordsPerPage);
    
    function handlePageChange(currentPage) {
      setCurrentPage(currentPage)
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
    };
    const [requestType, setrequestType] = useState('');

    const handleRequestChange = (event) =>{
        const { name } = event;
        setrequestType(event)
        setFormData({...formData, request_type: event?.value})
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };

    useEffect(()=>{
          getContactDetails()
      },[])

    const getContactDetails = async () => {
        try {
          const { data } = await API.apiGet(`query/list`);
          if (data?.success) {
            setContactDetails(data?.data)
          }
          else {
              toast.error(data?.message)
          }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
        }
      }  

    const options = [
        { value: 'Query', label: 'Query', name: 'request_type' },
        { value: 'Account Verification', label: 'Account Verification', name: 'request_type' },
      ]

    const validateForm = () =>{
    const errors = {};
    if (!formData?.request_type.trim()) {
        errors.request_type = " Request Type is required";
    }if (!formData?.message.trim()) {
        errors.message = " Message is required";
    }if (!formData?.subject.trim()) {
        errors.subject = " Subject is required";
      }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
    }  

    const closeModal = () => {
        setFormErrors({});
        setShowModal(false);
      };

    const handleSubmit = async (e) => {
        if(validateForm()){
        e.preventDefault();
        setIsLoading(true)
        if (formData?.message) {
            try {
                const { data } = await API.apiPost(`/notify`, {payload: encodeData(formData)})
                if (data?.success) {
                    toast.success(data?.message);
                    setFormData({ ...formData, message: "", subject:"", });
                    getContactDetails()
                    closeModal()
                }
                else {
                    toast.error(data?.message)
                }
                setIsLoading(false)
            }
            catch (error) {
                handleErrorMessage(error);
                // toast.error(error?.response?.data?.message)
                setIsLoading(false)
            }
        }
     }
    };
    const getFilteredSubjectList = (event) => {
        const value = (event?.target?.value)?.toLowerCase(); 
        let filteredData;
        if (value) {
            filteredData = contactDetails?.filter((item) => {
                const isMatch = (item?.subject)?.toLowerCase()?.includes(value);
                return isMatch
            });
            setFilteredSubjectData(filteredData);
        }else{
            setFilteredSubjectData(contactDetails);
        }
    };
    const handlekeyDown=(event)=>{
        if(event?.key===" "){
            event.preventDefault()
        }
    }

    return (
        <>
            <div className={`mainBodyContainer flex dark:bg-dark-background reconcilitaion-main-container`}>
                <SideMenu headerName="Support"/>
                <div className={`flex-1 ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    <div className={`UserFormContainer contactServices mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'contactServices_mainContainer'}`}>
                        {!isMobileDevice && <div className="pb-6 font-bold">
                            Support
                        </div>}
                        <div className="flex justify-between">
                            {/* <div className="" >
                                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 ">
                                <li className="">
                                    <a className={`inline-flex items-center justify-center pt-4 pb-2 active group cursor-pointer contactUssTab  ${activeTab === 'Contact' ? 'detailstab' : 'notActive'} ${isMobileDevice && 'supportTabs_responsive'}`}  aria-current="page" onClick={() => handleTabClick('Contact')}>
                                        Contact Us
                                    </a>
                                </li>
                                <li className="">
                                    <a className={`inline-flex items-center ms-3 justify-center pt-4 pb-2 active group cursor-pointer contactDetailTab  ${activeTab === 'Details' ? 'detailstab' : 'notActive'} ${isMobileDevice && 'supportTabs_responsive'}`} aria-current="page" onClick={() => handleTabClick('Details')}>
                                        Details
                                    </a>
                                </li>
                                </ul>
                            </div> */}
                            <div className=" flex tabContainer justify-between items-center">
                                <div className="differentTabs flex">
                                    <div
                                        className={`w-content p-2 text-center tabsName ${activeTab === 'Contact' ? "showActiveColor commmonActiveTag" : ""}`}
                                    >
                                        <p className="mt-2 cursor-pointer" onClick={() => handleTabClick('Contact')}>
                                            Contact Us
                                        </p>
                                    </div>
                                    <div
                                        className={`w-24 p-2 text-center tabsName ${activeTab === 'Details' ? "showActiveColor commmonActiveTag" : ""}`}
                                    >
                                        <p className="mt-2 cursor-pointer" onClick={() => handleTabClick('Details')}>
                                            Details
                                        </p>
                                    </div>
                                </div>
                                    {activeTab==='Details' && !isMobileDevice && <div className="flex">
                                        <div>
                                            <div className="flex relative rounded">
                                                    <img
                                                    src="assets/images/Filter.svg"
                                                    className="subjectSearchLogo absolute"
                                                    />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex relative rounded">
                                                <div className="subjectSearchLogo absolute">
                                                    <Icon iconName="searchIconBlue" width={16} height={16} fill={themeDetails?.color_config?.main_color}/>
                                                </div>
                                                <input
                                                type="text"
                                                placeholder="Search Subject"
                                                className="subjectSearch h-11 outline-none"
                                                onChange={getFilteredSubjectList}
                                                onKeyDown={handlekeyDown}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        {activeTab==='Details' && isMobileDevice &&
                        <div className={`${isMobileDevice && 'flex justify-end mt-5'}`}>
                            <div>
                                <div className="flex relative rounded">
                                    <img
                                    src="assets/images/Filter.svg"
                                    className="subjectSearchLogo absolute"
                                    />
                                </div>
                            </div>
                            <div className={`${isMobileDevice && 'w-1/2'}`}>
                                <div className="flex relative rounded">
                                                <div className="subjectSearchLogo absolute">
                                                    <Icon iconName="searchIconBlue" width={16} height={16} fill={themeDetails?.color_config?.main_color}/>
                                                </div>
                                    <input
                                    type="text"
                                    placeholder="Search Subject"
                                    className={`subjectSearch outline-none h-11 ${isMobileDevice && 'contactSearchInput_responsive'}`}
                                    onChange={getFilteredSubjectList}
                                    onKeyDown={handlekeyDown}
                                    ></input>
                                </div>
                            </div>
                        </div>}
                       {activeTab==='Contact' && 
                         <div className={`contactQuickly ${isMobileDevice && 'getStarted_container'}`}>
                            <div className="flex flex-col justify-center items-center">
                                <Icon iconName="contactUsIcon" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                <p className={`text-center mt-5 quicklyConnect ${isMobileDevice && 'supportDesc'}`}>
                                What would you like help with today? You can quickly take care
                                of most things here, or connect with us when needed.
                                </p>
                                <button
                                onClick={() => setShowModal(true)}
                                className={`flex items-center w-44 text-center justify-center custom_btn_primary commonBtn mt-5 ${isMobileDevice && 'getStartedBtn_responsive'}`}
                                >
                                Get Started
                                </button>
                            </div>
                            {showModal && (
                            <div
                                    id="static-modal"
                                    data-modal-backdrop="static"
                                    tabindex="-1"
                                    aria-hidden="true"
                                    class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
                                >
                                    <div class={`relative p-4 mt-12 modalToContact max-w-3xl max-h-full ${isMobileDevice && 'contactUsModal_responsive'}`}>
                                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                        <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Contact Us !
                                        </h3>
                                        <div onClick={closeModal} className="cursor-pointer hover:text-gray-900 hover:bg-gray-200 p-2 rounded-lg">
                                        <svg
                                        class="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                        >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                        </svg>
                                        </div>
                                        </div>
                                        <p className="ps-5 pe-5 md:ps-6 md:pe-6 yourProblem">We can't solve your Problem if you don't tell us about it.</p>
                                        <div className=" w-11/12 m-auto mt-3">
                                            <div className="mt-4 ps-2 pe-2">
                                                <label htmlFor="userName" className="mb-1">Query related to:</label>
                                                <Select
                                                    options={options}
                                                    placeholder="Request Type"
                                                    className="w-full requestDropdown h-10"
                                                    onChange={handleRequestChange}
                                                    value={requestType}
                                                />
                                                <span style={{ color: formErrors?.request_type ? 'red' : 'inherit' }}>{formErrors?.request_type}</span>
                                            </div>
                                        </div>
                                        <div className=" w-11/12 m-auto mt-3">
                                        <div className="mt-4 ps-2 pe-2">
                                            <label htmlFor="userName" className="mb-1">Subject</label>
                                            <input type="text" name="subject" value={formData.subject} onChange={handleChange}  placeholder="Message Subject" className="mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md h-10"></input>
                                            <span style={{ color: formErrors?.subject ? 'red' : 'inherit' }}>{formErrors?.subject}</span>
                                        </div>
                                        </div>
                                        <div className=" w-11/12 m-auto mt-3">
                                        <div className="mb-2 mt-4 ps-2 pe-2">
                                            <label htmlFor="message" className="mb-1">Message</label>
                                            <textarea className="h-32 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300" type="text" id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Enter Message" />
                                            <span style={{ color: formErrors?.message ? 'red' : 'inherit' }}>{formErrors?.message}</span>
                                        </div>
                                        <div className="flex justify-end pe-2">
                                        <button onClick={handleSubmit} className="flex items-center w-28 text-center justify-center custom_btn_primary commonBtn mt-5 ms-2 " disabled={isLoading}>Send{isLoading ? <ColorRing
                                            visible={true}
                                            height="25"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={[]}
                                        /> : ''}
                                        </button>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                            </div>
                            )}
                         </div>
                        }
                        {activeTab === 'Details' && <div className="mt-6">
                            {contactDetails ? (
                                !isMobileDevice ? (
                                    <DataTable columns={DetailsColumns} rowData={(filteredSubjectData && filteredSubjectData.length) && filteredSubjectData != "" ? filteredSubjectData : contactDetails} isCalledFrom={'ContactForm'} />
                                ) : (
                                    <div>
                                        {currentRecords?.map((item, index) => (
                                        <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className="font-bold">Request Type</span>
                                                <div className="flex items-center justify-between">
                                                    <span className="mr-5">{item?.request_type}</span> 
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className="font-bold">Subject</span>
                                                <div className="flex items-center justify-between">
                                                    <span className="mr-5">{item?.subject}</span> 
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className="font-bold">Message</span>
                                                <div className="flex items-center justify-between">
                                                    <span className="mr-5">{item?.message}</span> 
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className="font-bold">Submitted At</span>
                                                <div className="flex items-center justify-between">
                                                    <span className="mr-5">{moment(item?.created_at).format("YYYY-MM-DD | HH:mm:ss")}</span> 
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        <div className="reponsivePagination">
                                        <Pagination nPages={nPages}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                        ></Pagination>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="contactQuickly">
                                    <div className="flex flex-col justify-center items-center mt-10">
                                        <img src="assets/images/noContactDetail.svg" className="w-32"></img>
                                        <h3 className="font-semibold mt-3">No records found !</h3>
                                        <p className="text-center mt-2 quicklyConnect">
                                            Contact us now to get details about your queries and feedback.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>}
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(Contact);

