import React, { useState } from 'react';
import Pagination from "./Pagination";

export default function ReconcilitionTable({columns, data, matchedData, cardsColor}) {
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage);

  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
  }
  
  return (
    <>
        <div className="reconcilitionTable-container">
        <div className="flex justify-center gap-2 flex-wrap">
              {Object.keys(matchedData)?.map((card, idx) => (
                <div
                  key={`card_${idx}`}
                  className={`mb-3 flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mt-4 p-4 gap-2 w-full reconcillationCard ${idx != 0 ? 'ms-4' : ''}`}
                >
                  <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                    <h3
                      className="inline-block relative font-quicksand text-2xl mr-8"
                      style={{ color: cardsColor?.[card] }}
                    >
                      {matchedData[card]}
                    </h3>
                    <p className="text-black font-quicksand font-medium leading recnCardData">
                      {card}
                    </p>
                  </div>
                </div>
              ))}
            </div>
              <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                  <tr className="ps-2 pe-2">
                    {columns.map((item, indx) =>
                      <th  key={`row${indx}`} className="py-2 px-4">
                        {item?.title}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  { currentRecords.map((item, index)=>
                    <tr key={`${item}_${index}`}  >
                      <td  className="py-2 ps-4">{item?.Stellar ? item?.Stellar : <span className="text-red-500"> - Missing Record</span>}</td>
                      <td   className="py-2 ps-4">{item?.Date ? item?.Date : <span className="text-red-500"> - Missing Record</span>}</td>
                      <td   className="py-2 ps-4" title={item?.Particulars}>{item.Particulars ? item?.Particulars?.length>20 ? `${item?.Particulars.substring(0,20)}...`: item?.Particulars: <span className="text-red-500"> - Missing Record</span>}</td>
                      <td  className="py-2 ps-4">{item?.['C/D'] ? item?.['C/D'] == 'D' ? <span className={`px-4 rounded-full tabletDebit`}><span className="red-dot"/>Debit</span> : <span className="px-4 rounded-full tabletCredit"><span className="green-dot"/>Credit</span> : <span className="text-red-500"> - Missing Record</span> }</td>
                      <td  className="py-2 ps-4">{item?.Amount ? item?.Amount : <span className="text-red-500"> - Missing Record</span>}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

        <Pagination nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
        ></Pagination>
    </>
  )
}
