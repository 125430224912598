import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Icon from "../../component/core-component/Icon";
// import { SearchBox, FilterButton } from "../../component/core-component";
import { useNavigate } from "react-router-dom";
import { WithAuth } from '../../common/WithAuth';
import UpdatePhone from "../../component/UpdatePhone";
import UpdatePhn from "../../assets/images/mobileUpdateSvg.svg"
// import WrittenOfPortfolio from "../../component/WrittenOfPortfolio";
import '../../styles/dashboard.scss';
import { getCurrentProcessPerm } from "../../helpers/auth";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [processList, setProcessList] = useState([])
  const [containerHeight, setContainerHeight] = useState(0);
  var navigate = useNavigate();

  const isMobileDevice = window.screen.width <= 900;

  useEffect(()=>{
    setDynamicHeight();
    localStorage.setItem("activeTab", "Process");
  }, [])
  function setDynamicHeight() {
    let viewportHeight = window.screen.height;
    viewportHeight = parseInt(viewportHeight) - 150
    setContainerHeight(viewportHeight);
  }
  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer`}>
      <SideMenu headerName="Process List" />
      <div className={`h-screen flex-1 overflow-hidden ${isMobileDevice && 'overflow-y-auto mainContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          <div className=" flex flex-row justify-between ProcessListText">
            {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
              Agent Banking Department
            </h1>}
          </div>

          {/* Card 1 */}
          <div className="flex flex-row processCardsList" style={{ maxHeight: containerHeight }}>
            {getCurrentProcessPerm(1)?.read &&
              <div id="reg_saving_acc" className="samdProcessCards commonCard bg-white mx-4 hover:cursor-pointer" onClick={() => navigate("/regularAccount")}>
                <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                  <div className="titleImage">
                    {/* <Icon iconName="userAccount" width="16" height="16" /> */}
                    <img src="assets/images/manFace.png" width="50" alt="savingsAccount" />
                  </div>
                  <span className="font-bold titleHead">
                    Regular Savings Account
                  </span>
                  <div onClick={() => navigate("/regularAccount")} className="cursor-pointer arrowIcon commonCardIcons">
                    <img src="assets/right-arrow.svg" alt="file" className="arrwIcon" />
                  </div>
                </div>
                <div>
                  <p className="p-4 cardDescription">
                    Ensuring the validity and accuracy of savings account opening requests for adult clients through verification procedures.
                  </p>
                  <br />
                  <div className="float-end px-4 pb-2">
                    <p className="createdDetails">
                      Created on 04 Apr, 2024
                    </p>
                  </div>
                </div>
              </div>
            }
            {/* Card 2 */}
            {getCurrentProcessPerm(1)?.read &&
              <div id="minor_acc" className="samdProcessCards commonCard bg-white mx-4 hover:cursor-pointer" onClick={() => navigate("/minorAccount")}>
                <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                  <div className="titleImage">
                    {/* <Icon iconName="userAccount" width="16" height="16" /> */}
                    <img src="assets/images/boyFace.png" width="50" alt="minorsAccount" />
                  </div>
                  <span className="font-bold titleHead">
                    Minor Account
                  </span>
                  <div onClick={() => navigate("/minorAccount")} className="cursor-pointer arrowIcon commonCardIcons">
                    <img src="assets/right-arrow.svg" alt="file" className="arrwIcon" />
                  </div>
                </div>
                <div>
                  <p className="p-4 cardDescription">
                    Ensuring the validity and accuracy of savings account opening requests for minor clients through verification procedures
                  </p>
                  <br />
                  <div className="float-end px-4">
                    <p className="createdDetails">
                      Created on 04 Apr, 2024
                    </p>
                  </div>
                </div>
              </div>
            }
            {/* Card 3 */}
            {getCurrentProcessPerm(2)?.read &&
              <div id="update_ph_no" className="samdProcessCards commonCard bg-white mx-4 hover:cursor-pointer" onClick={() => navigate("/updatePhoneNumber")}>
                <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                  <div className="titleImage">
                    {/* <Icon iconName="phone" width="16" height="16"/> */}
                    <img src="assets/images/phoneUpdate.png" width="40" alt="updatePhone" />
                  </div>
                  <span className="font-bold titleHead">
                    Update Phone Number
                  </span>
                  <div onClick={() => navigate("/updatePhoneNumber")} className="cursor-pointer arrowIcon commonCardIcons">
                    <img src="assets/right-arrow.svg" alt="file" className="arrwIcon" />
                  </div>
                </div>
                <div>
                  <p className="p-4 cardDescription">
                    Facilitating the seamless process of requesting and processing changes to phone numbers, ensuring accurate and up-to-date contact information for clients.
                  </p>
                  <div className="float-end px-4">
                    <p className="createdDetails">
                      Created on 24 Mar, 2024
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(Dashboard);
