import moment from "moment";
import Icon from "./Icon";
import React, { useEffect, useState } from "react";
const ViewError = ({imageData, setShowTableState}) => {
    const cardData = imageData;
    const [showImg, setShowImg] = useState();
    const [openModal, setOpenModal] = useState(false)
    const [responseScreenWidth, setResponseScreenWidth] = useState();
    const handleCancel = () => {
        setShowTableState(true)
    } 
    const handleRedirect = (img) => {
        setShowImg(img);
        setOpenModal(true);
    }
    useEffect(()=>{
      let viewportHeight = window.screen.width;
      setResponseScreenWidth(viewportHeight)
    },[])
    const closeModal = () => {
        setOpenModal(false);
    }
   
    return(
      responseScreenWidth > 900 ? 
      <>
          {
            openModal &&
            <div className="bg-white">
             <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" style={{zIndex: "999"}} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
            <div className="relative p-6  bg-white w-4/5 max-h-full" style={{padding:"3.5rem", left: "120px"}}>
                <button onClick={() => closeModal()} className="absolute top-0 right-0 m-4 text-white text-lg bg-transparent border-0 m-6">
                    <Icon iconName="cross" width="16" height="16"/>
                </button>
                <img src={showImg} alt="Error Image"/>
            </div>
            </div>
            </div>
          }
          <div className=" bg-white pb-6">
          <div onClick={handleCancel} className="flex justify-end pt-4 pe-4 iconPointer">
            <Icon iconName="cross" width="24" height="24" />
          </div>
          <div className="rounded overflow-y-scroll h-svh mb-5 flex justify-evenly flex-wrap">
        {cardData && cardData?.length>0 && cardData?.map((item, index) => (
          <>
          <div className=" py-5 flex-wrap pb-5">
            <div className="px-6 py-4 w-80 shadow-lg mt-5 iconPointer">
              <div onClick={() => handleRedirect(item?.img)} className="flex justify-end pt-4">
                <Icon iconName="redirect" width="24" height="24"/>
              </div>
              <div className="font-bold text-xl mb-2 text-center">
                ATTEMPT {index + 1}
              </div>
              <div className="border border-sky-500 object-cover mt-4">
                <img src={item?.img} alt="Error Image"/>
              </div> 
              <div className="mt-5">
                <p><span className="font-bold">Updated At :</span><span>{moment(item?.updated_at).format("YYYY-MM-DD")}</span></p>
              </div>  
            </div>
          </div>
          </>
        ))
        }
          </div> 
        </div>
      </> :

      <>
      {
        openModal &&
        <div className="bg-white">
        <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" style={{zIndex: "999"}} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
        <div className="relative p-6  bg-white w-4/5 max-h-full" style={{padding:"3.5rem", left: "0px"}}>
            <button onClick={() => closeModal()} className="absolute top-0 right-0 m-4 text-white text-lg bg-transparent border-0 m-6">
                <Icon iconName="cross" width="16" height="16"/>
            </button>
            <img src={showImg} alt="Error Image"/>
        </div>
        </div>
        </div>
      }
      <div className=" bg-white pb-6">
      <div onClick={handleCancel} className="flex justify-end pt-4 pe-4 iconPointer">
        <Icon iconName="cross" width="24" height="24" />
      </div>
      <div className="rounded overflow-hidden mb-5 flex justify-evenly flex-wrap">
        {cardData && cardData?.length > 0 && cardData?.map((item, index) => (
          <>
          <div className=" py-5 flex-wrap pb-5">
            <div className="px-6 py-4 w-80 shadow-lg mt-5 iconPointer">
              <div onClick={() => handleRedirect(item?.img)} className="flex justify-end pt-4">
                <Icon iconName="redirect" width="24" height="24"/>
              </div>
              <div className="font-bold text-xl mb-2 text-center">
                ATTEMPT {index + 1}
              </div>
              <div className="border border-sky-500 object-cover mt-4">
                <img src={item?.img} alt="Error Image"/>
              </div> 
              <div className="mt-5">
                <p><span className="font-bold">Updated At :</span><span>{moment(item?.updated_at).format("YYYY-MM-DD")}</span></p>
              </div>  
            </div>
          </div>
          </>
        ))
        }
      </div> 
      </div>
      </>
      )
  
  }
  
  export default ViewError;
