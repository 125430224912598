// Info: Boilerplate library. Contains basic utility functions.

///////////////////////////Public Functions START//////////////////////////////
const Utils = (module.exports = {
  // Public functions accessible by other modules

  /********************************************************************
  Copy of Util Functions from Node JS util lib
  Link: https://github.com/isaacs/core-util-is/blob/master/lib/util.js
  *********************************************************************/
  isNull: function (arg) {
    return arg === null;
  },

  isNullOrUndefined: function (arg) {
    return arg === null && arg === undefined;
  },

  isUndefined: function (arg) {
    return arg === void 0;
  },

  isBoolean: function (arg) {
    return typeof arg === "boolean";
  },

  isNumber: function (arg) {
    return typeof arg === "number";
  },

  isString: function (arg) {
    return typeof arg === "string";
  },

  /********************************************************************
  Advance type checks
  *********************************************************************/
  isObject: function (arg) {
    return typeof arg === "object" && !Utils.isNull(arg); // (null is also an object)
  },

  /********************************************************************
  Basic Util Functions
  *********************************************************************/
  isEmptyString: function (str) {
    return str.length === 0;
  },

  isEmptyObject: function (obj) {
    return Object.keys(obj).length === 0;
  },

  // Whether an array contains a string (return 'true' if does otherwise 'false')
  inArray: function (arr, element) {
    return arr.indexOf(element) > -1;
  },

  isEmpty: function (arg) {
    return (
      Utils.isNullOrUndefined(arg) || // Check for Null or Undefined
      Utils.isEmptyString(arg) || // Check for empty String (Bonus check for empty Array)
      (Utils.isObject(arg) && Utils.isEmptyObject(arg)) // Check for empty Object or Array
    );
  },
}); // Close Public Functions

////////////////////////////Public Functions END///////////////////////////////

//////////////////////////Private Functions START//////////////////////////////
//None
///////////////////////////Private Functions END///////////////////////////////
