import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/reconcilition.scss";
import WorkerQueBoard from "../dashboard/WorkerQueBoard";
import Breadcrumb from "../../component/Breadcrumb";
import QueueCardsS07 from "../../component/QueueCardsS07";

function QueueConfiguration() {

    const isMobileDevice = window.screen.width <= 900;

    return (
        <>
            <div className={`mainBodyContainer flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part`}>
                <SideMenu headerName="Minor Account Configuration"/>
                <div className={`flex-1 ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    <div className={`z-0 container py-10 px-8 relative mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'py-5 px-5 responsiveMainContainer'}`} >
                        <Breadcrumb showBackButton redirectUrl="/queueConfiguration" steps={["Minor Account Configuration"]}/>
                        <QueueCardsS07 />
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(QueueConfiguration);
