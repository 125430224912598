import { OPENING_MODAL, CLOSING_MODAL } from '../actions';

const initialState = {
  isModalOpen: false,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPENING_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSING_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

export default ModalReducer;
