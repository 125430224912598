import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gravatar from 'react-gravatar';
import Icon from "./core-component/Icon";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { getCurrentUserMenus } from "../common/menuItems";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { logout } from "../helpers/auth";
import style from '../styles/common.module.scss'
import API from "../helpers/api";
import '../../src/index.css'



const SideMenu = ({showNavToggle, headerName, setSideBarCondition, hasProps}) => {
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth<=900);
  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('sidebarOpen')) || false); 
  const isModalOpen = useSelector(state => state.ModalReducer.isModalOpen);
  const [userData, themeDetails, themeDataLoding] = useSelector((Gstate) => [
    Gstate?.UserDataReducer?.user_data, 
    Gstate?.UserThemeReducer?.themeDetails, 
    Gstate?.UserThemeReducer?.loading
  ])
  const location = useLocation();
  var redux_dispatch = useDispatch();
  var navigate = useNavigate();
  const currentPath = "/" + location.pathname.split("/")[1];
  const [currentMenus,setCurrentMenus] = useState([])
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [logoSrc, setLogoSrc] = useState(themeDetails?.themeDetails ? themeDetails?.themeDetails?.logo : themeDetails?.logo);
  const mobileSidebarRef = useRef(null);
  const toggleBtnRef = useRef(null);
  

  const height = window.screen.height;
  
  const handleTabChange = (Menu) => {
    localStorage.setItem("activeTab", Menu?.title)
  }

  const handleResize = () => {
    if(window.innerWidth <= 900) {
      setIsMobileDevice(true);
    }
    else {
      setIsMobileDevice(false);
    }
  };

  useEffect(() => {
    const menuList = getCurrentUserMenus();
    setCurrentMenus(menuList);
    window.addEventListener('resize', handleResize);
    document.addEventListener('click',(event)=>{
      if( mobileSidebarRef.current && toggleBtnRef.current  && !mobileSidebarRef.current.contains(event.target) && !toggleBtnRef.current.contains(event.target) ) {
        
        setOpen(prev=>(prev===true)?false:prev);
      }
    })
  }, []);

  useEffect(() => {
    setLogoLoading(true);
    getLogoSignedURL();
  },[themeDetails, themeDetails?.logo])

  const getLogoSignedURL = async () => {
    setLogoLoading(true);
    let url = themeDetails?.themeDetails ? themeDetails?.themeDetails?.logo : themeDetails?.logo
    if (url) {
      setLogoSrc(url);
    }
    setLogoLoading(false);
  }

  useEffect(() => {
    let storedData = localStorage.getItem('sidebarOpen');
    setOpen(JSON.parse(storedData));
    if (hasProps) setSideBarCondition(JSON.parse(storedData));

  }, [])

  const handleMenuChilds = (menuItem) => {
    let currentMenuList = [...currentMenus]
    currentMenuList = currentMenuList?.map(item => {
      if (menuItem?.title == item?.title) {
        item.showChild = !item?.showChild
      }
      return item
    })
    setCurrentMenus(currentMenuList);
  }  

  const toggleSideBar = () => {
    setOpen(!open);
    if (hasProps) setSideBarCondition(!open);
    localStorage.setItem('sidebarOpen', !open);
  }

  const openDropdown = () => {
    setToggleDropdown(!toggleDropdown)
  };

  const onClickUpdatePassword = () => {
    setShowUpdatePasswordModal(true)
  };
  
  return (
    <>
    <div>
    
      {isMobileDevice  &&
      <div  className={`headerBar flex items-center justify-between border-opacity-[42%] z-50 dark:bg-dark-background bg-white border-dark-border ${isMobileDevice && 'responsive_headerBar'} ${showNavToggle && 'removeShadow'}`}>
        <div className={`flex gap-x-4 items-center ${!open && isMobileDevice && 'ms-3'}`}>
          <button
            ref={toggleBtnRef}
            className={`cursor-pointer -right-4 top-24 rotatedButton ${!open && "rotate-180 ms-3"}`}
            onClick={() => toggleSideBar()}
          >
            <img
              src="/assets/toggleIcon.svg"
              alt="toggle"
              style={{ height: "20px", width: "20px" }}
              className={`cursor-pointer ${open ? "rotate-[360deg]" : ''}`}
            />
          </button>
          {open &&
          (!logoLoading && !themeDataLoding) ?
            <img
              src={(themeDataLoding || logoLoading) ? '' : logoSrc ? logoSrc : "/assets/images/logo.png"}
              alt="logo"
              style={{ height: "48px", width: "70px" }}
              className={`duration-500 ms-7 ${open ? "rotate-[360deg]" : 'smallLogo'}`}
            />
            :
            ''
          }
        </div>
        <div className="flex w-full">
          <div className="ms-6 w-full">
            <span className="text-xl font-bold mr-6">{headerName}</span>
          </div>
        </div>
      </div>
      }
      <aside ref={mobileSidebarRef}
      className={`sidebar_bg   ${isModalOpen ? 'sidebarLowZindex' : 'sideBar'} ${isModalOpen ? 'z-index-50' : 'z-index-999'} ${isMobileDevice ? 'fixed' : 'relative'} ${
        open ? "openSidebar" : isMobileDevice ? 'sideBarMainContainer_responsive' : 'w-20'
      } min-h-screen p-5    duration-300 border-opacity-42 bg-white dark:bg-dark-background border-dark-border  `}
      style={{ zIndex: isModalOpen ? 49 : 999 }}
      
      >
        <div className={`${!open && 'closedSidebar'} sidebarInnerData`}>
          {!isMobileDevice ? (
            <div className={`flex gap-x-4 items-center ${!open && 'toggleButtonIcon'}`}>
              <button
                className={`cursor-pointer -right-4 top-24 rotatedButton ${!open ? "rotate-180 ms-3" : 'w-10'}`}
                onClick={() => toggleSideBar()}
              >
                <img
                  src="/assets/toggleIcon.svg"
                  alt="toggle"
                  style={{ height: "20px", width: "20px" }}
                  className={`cursor-pointer ${open ? "rotate-[360deg]" : ''}`}
                />
              </button>
              {open ?
              (!logoLoading && !themeDataLoding) ? 
              <div className="logoIcon">
                <img
                  src={(themeDataLoding || logoLoading) ? '' : logoSrc ? logoSrc : "/assets/images/logo.png"}
                  alt="logo"
                  style={{ width: '68px' }}
                  className={`duration-500 ms-7 ${open ? "rotate-[360deg]" : 'smallLogo'} object-contain`}
                />
              </div>
              :
              ''
              :
                <div style={{ height: "48px", width: "70px" }}></div>
              }
            </div>
          ) : (
            open && 
              <div className={`flex gap-x-4 items-center ${!open && 'toggleButtonIcon'}`}>
                <button
                  className={`cursor-pointer -right-4 top-24 rotatedButton ${!open ? "rotate-180 ms-3" : 'w-10'} ${isMobileDevice && 'ms-1'}`}
                  onClick={() => toggleSideBar()}
                >
                  <img
                    src="/assets/images/toggleBack.svg"
                    alt="toggle"
                    style={{ height: "20px", width: "20px" }}
                    className={`cursor-pointer ${open ? "rotate-[360deg]" : ''}`}
                  />
                </button>
                {(!logoLoading && !themeDataLoding) ?
                  <img
                    src={(themeDataLoding || logoLoading) ? '' : logoSrc ? logoSrc : "/assets/images/logo.png"}
                    alt="logo"
                    style={{ height: "48px", width: "70px" }}
                    className={`duration-500 ms-7 ${open ? "rotate-[360deg]" : 'smallLogo'} object-contain`}
                  />
                  : 
                  ''
                }
              </div> 
          )}
          {isMobileDevice && open &&
            <div className="flex items-center justify-center flex-col mt-3">
              <div className="mr-4 userProfileContainer_responsive border-0">
                {userData?.email ? <Gravatar email={userData?.email} style={{ borderRadius: "50%" }} onClick={openDropdown} className="cursor-pointer" />
                  : <div className="flex items-center justify-center " style={{ height: "48px", width: "48px", borderRadius: "50%" }} onClick={openDropdown}>{userData?.first_name?.[0]?.toUpperCase() + userData?.last_name?.[0]?.toUpperCase()}</div>}
              </div>
              <div className="flex mt-4 justify-center items-center">
                <div>
                  <p className={`text-gray-700 font-lexend text-base font-medium leading-6d cursor-pointer userName_container`} onClick={openDropdown}>
                    {(userData?.first_name && userData?.last_name) ? (userData?.first_name + " " + userData?.last_name)?.length > 15 ? (userData?.first_name + " " + userData?.last_name).substring(0, 15) + "..." : userData?.first_name + " " + userData?.last_name : ''}
                  </p>
                </div>
                <div>
                  {showUpdatePasswordModal && <UpdatePasswordModal closeModal={setShowUpdatePasswordModal} />}
                </div>
              </div>
            </div>
          }
          {((isMobileDevice && open) || !isMobileDevice) &&
            <ul className='mt-6 menuListItem_responsive' style={isMobileDevice ? { height: `${height/14}vh` } : {}}>
              {currentMenus?.map((Menu, index) => (
                <React.Fragment key={index}>
                  <li
                    key={index}
                    onClick={()=>handleTabChange(Menu)}
                    className={`rounded-md p-2 cursor-pointer hover:bg-light-white text-sm items-center gap-x-4 
                      ${Menu?.gap ? "mt-9" : "mt-2"} 
                      ${(Menu?.navlink === currentPath || Menu?.submenu?.some(item => item.navlink === currentPath))
                        ? `sideMenuColor dark:bg-secondary-background sectionSelection`
                        : "text-gray-500 dark:text-white"
                    } ${!open ? 'closedSidebarColor' : ''}`}
                  >
                    {Menu?.children?.length == 0 
                      ?
                        <NavLink
                          to={Menu?.navlink}
                          className={`flex flex-row items-center`}
                        >
                          <div className="w-5 flex justify-center">
                            <img src={Menu?.src}
                              className=""
                              height={24}  
                              width={Menu?.title == 'Services' ? 12 : 24}
                            ></img>
                          </div>
                          <span
                            className={`${
                              !open && "hidden"
                            } origin-left duration-200 font - quicksand text-sm font-semibold leading-6 ml-4`}
                          >
                            {Menu?.title}
                          </span>
                        </NavLink>
                      :
                      <div className="flex" onClick={() => handleMenuChilds(Menu)}>
                        <div className="w-7 flex">
                        <img src={Menu?.src}
                          height={24}  
                          width={Menu?.title == 'Process' ? 28 : 24}
                        ></img>
                        </div>
                        <span
                          className={`${
                            !open && "hidden"
                          } origin-left duration-200 font - quicksand text-sm font-semibold leading-6 ml-2`}
                        >
                          {Menu?.title}
                        </span>
                        {open &&
                        <div className="w-24 flex justify-end">
                          <img src='/assets/right-icon.svg'
                            className={`toggleItems ${Menu?.showChild ? 'rotateDown' : ''}`}
                            height={24}  
                            width={24}
                        ></img>
                        </div>
                        }
                      </div>
                    }
                  </li>
                  {Menu?.children?.map((chldMenu, chldIndx) => (
                  <li key={index}
                    className={`${open ? 'ms-3' : ''} rounded-md p-2 cursor-pointer hover:bg-light-white text-sm items-center gap-x-4 
                      ${chldMenu?.gap ? "mt-9" : "mt-2"} ${Menu?.showChild ? 'showChilds' : 'hideChilds'}
                      ${(chldMenu?.navlink === currentPath || chldMenu?.submenu?.some(item => item.navlink === currentPath))
                        ? `${!open ? 'closedSidebarColor' : ''} sideMenuColor dark:bg-secondary-background sectionSelection`
                        : "text-gray-500 dark:text-white"
                      }`
                    }
                  >
                    <NavLink
                      key={chldIndx}
                      to={chldMenu?.navlink}
                      className={`flex flex-row items-center`}
                    >
                      <img src={chldMenu?.src}
                      className=""
                      height={24}  
                      width={24}
                      ></img>

                      <span
                        className={`${
                          !open && "hidden"
                        } origin-left duration-200 font - quicksand text-sm font-semibold leading-6 ml-4`}
                      >
                        {chldMenu?.title}
                      </span>
                    </NavLink>
                  </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
           }
        </div>
        {isMobileDevice && open &&
          <div className={`absolute bottom-0 border-t w-full left-0 ${style.sidebarMargins}`}>
            <div className="mx-5 mt-3 logout_container">
              <p className="mb-2" onClick={onClickUpdatePassword}>Update Password</p>
              <p className="mb-1" onClick={logout}>Log Out</p>
            </div>
          </div>
        }
      </aside>
      </div>
    

  
    </>
  );
};

export default SideMenu;
