export const defaultLoadingTheme = {
  id: '',
    color_config: {
      main_color: "#ffff",
      primary_button_bg: "#000",
      primary_button_text: "#ffff",
      secondary_button_bg: "#ffff",
      secondary_button_text: "#000",
      table_header_footer: "#ffff",
      table_header_text_color: "#000",
      table_body: "#ffff",
      table_body_text_color: "#000",
      page_background: "#ffff",
      module_selection: "#ffff",
      module_selection_text: "#000",
      line_sepration: "#ffff",
      card_body_bg: "#ffff",
      card_header_bg: "#ffff",
      sidebar_module:"#ffff",
    }
  };
  