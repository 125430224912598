import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/nomineeDetails.scss";
const ConventionalAgentBanking = () => {
  const steps = ["Acount Details", "Nominee Details", "NomineeQuickLinks"];
  return (
    <div className={`flex dark:bg-dark-background updatePhone`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <div className="z-0 p-10 mainContainer nomineeDetails">
          <Breadcrumb
            steps={steps}
            activeStep={1}
            showBackButton={true}
            redirectUrl={"/AccountUpdateDetails"}
          />
          <div class="grid grid-cols-3 gap-4 mt-2">
            <div className=" col-span-3 rounded p-4 flex flex-wrap cardBackground">
                <p className="quickLinkText">Quick Links</p>
                <div className=" flex w-full justify-between mt-4 nomineeCardsContainer rounded">

                    <div className="LinksCard flex flex-col">
                        <div className="linkIcon ">
                        <img src="assets/images/plusIcon.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Customer Creation</p>
                        </div>
                    </div>

                    <div className="LinksCard  flex flex-col">
                        <div className="linkIcon ">
                        <img src="assets/images/newPen.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Change Customer Information</p>
                        </div>
                    </div>
                     
                    <div className="LinksCard  flex flex-col">
                        <div className="linkIcon ">
                        <img src="assets/images/Vector.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Bulk AML Screening</p>
                        </div>
                    </div>

                    <div className="LinksCard  flex flex-col">
                        <div className="linkIcon ">
                        <img src="assets/images/resend.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Customer Review</p>
                        </div>
                    </div>

                    <div className="LinksCard  flex flex-col">
                        <div className="linkIcon ">
                        <img src="assets/images/attach.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Change Request Approval</p>
                        </div>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConventionalAgentBanking;
