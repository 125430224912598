import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Set the worker URL for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdfBlob, setShowPDFViewer }) => {
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
    >
      <div className="relative p-4 w-2/5 max-w-2xl max-h-full">
        <div className="flex bg-white">
            <button
            type="button"
            className="rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center text-gray-400"
            data-modal-hide="static-modal"
            onClick={() => setShowPDFViewer(false)}
            >
            <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
            >
                <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
            </svg>
            </button>
        </div>
        <div className="relative shadow dark:bg-gray-700 viewPdfModal bg-white">
            <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
                {[...Array(numPages)].map((_, index) => (
                    <Page key={index + 1} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
      </div>
    </div>
    //     <div  className='nidPdfViewer'>
    //   </div>
  );
};

export default PdfViewer;
