import React, { useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { Collapse, Steps } from "antd";
import { Form, Input } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DatePicker, Select } from "antd";
import styles from "../../src/styles/crmLoanAccountDetails.module.scss";
import { Card, Descriptions, Button } from "antd";
import { toast } from "react-toastify";
import API from "../helpers/api";
import { ColorRing } from "react-loader-spinner";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const { Panel } = Collapse;
const { Step } = Steps;
const { Option } = Select;

const CRMLoanAccountDetails = () => {
  const location = useLocation();
  const { loan_account_id } = location.state;
  const [dataLoading, setDataLoading] = useState(false);
  const navigate = useNavigate();


  const details = {
    loanType: "Home Loan",
    openingDate: "12/06/2024",
    nidNo: "6289874163",
    dateOfBirth: "08/09/1856",
    expectedAmount: "TK 2000000",
  };
  const data = {
    name: "Robert William",
    dob: "29/09/1989",
    gender: "Male",
    nationality: "Bangladesh",
    residentStatus: "Resident",
    mobile: "9876543210",
    itn: "1234567890",
    address: {
      houseNumber: "12/24",
      locality: "Dehradun",
      city: "Chittagong",
      state: "Dhaka",
    },
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const [loanDetails,setLoanDetails] = useState();
  const [sectionNames,setSectionNames] = useState();

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const getCustomerLoanAccount = async() =>{
    const payload = {
      loan_account_id
    }
    try {
        const { data } = await API.apiGet(`loan/account/details`,'',payload);
        if (data?.success) {
            toast.success(data?.message);
            setLoanDetails(data?.data);
            let selectedSection = data?.data?.loan_details?.map(sec => sec?.section_name);
            setSectionNames(selectedSection);
            setDataLoading(false)
        } else {
            toast.error(data?.message);
            setDataLoading(false)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message);
        setDataLoading(false)
    }
}

const getSectionStatus = (secName) =>{
  let sectionStatus = '';
  loanDetails?.loan_details?.forEach((sec,indx)=>{
    if(sec?.section_name === secName){
      sectionStatus =  sec?.isCompleted
    }
  })
  if(sectionStatus != '' && sectionStatus != undefined){
    return true
  }
  else{
    return false
  }
}

const getAccountHolder = (fieldName) =>{
  let accountHolderData = '';
  loanDetails?.loan_details?.forEach((sec, secIndx) => {
      sec?.data?.forEach(innerItem => {
          if (innerItem?.variable === fieldName) {
              accountHolderData = innerItem
          }
      })
  })
  if (accountHolderData != '' && accountHolderData?.value !='' && accountHolderData?.value != undefined) {
      return accountHolderData?.value;
  } else {
      return '-';
  }
}

const backBtnPage =()=>{
  navigate('/loanAccountsCardBoard')
}

useEffect(()=>{
  getCustomerLoanAccount();
  setDataLoading(true);
},[])

  const accordionData = [
    {
      title: "Personal Information",
      content: ( 
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Name Of Account Holder</strong>
                <div>{getAccountHolder("name_of_account_holder")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Date of birth</strong>
                <div>{getAccountHolder("DOB")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Gender</strong>
                <div>{getAccountHolder("gender")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Nationality</strong>
                <div>{getAccountHolder("nationality")}</div>
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Resident Status</strong>
                <div>{getAccountHolder("resident_status")}</div>
              </div>
            </div>
            <div className={styles.formColumn}>
              <div className={`${styles.formColumnItem}`}>
                <strong> Mobile No. </strong>
                <div>{getAccountHolder("mobile_number")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Father Name</strong>
                <div>{getAccountHolder("fathers_name")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Mother Name</strong>
                <div>{getAccountHolder("mothers_name")}</div>
              </div>
            </div>
          </div>
          {/* <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>House Number</strong>
                <div>{data.mobile}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Locality</strong>
                <div>{data.mobile}</div>
                      </div>
                    </div>
                    <div className={`${styles.formColumn}`}>
                      <div className={`${styles.formColumnItem}`}>
                        <strong>City</strong>
                        <div>{data.mobile}</div>
                      </div>
                    </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>State</strong>
                <div>{data.mobile}</div>
              </div>
            </div>
          </div> */}
          <div>
            <h3 className="ps-2 pt-3">Present Address</h3>
            <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Present Address</strong>
                  <div>{getAccountHolder("present_address")}</div>
                </div>
              </div>
              {/* <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Locality</strong>
                  <div>{data.address.locality}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>City</strong>
                  <div>{data.address.city}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>State</strong>
                  <div>{data.address.state}</div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <h3 className="ps-2">Permanent Address</h3>
            <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Permanent Address</strong>
                  <div>{getAccountHolder("permanent_address")}</div>
                </div>
              </div>
              {/* <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Locality</strong>
                  <div>{data.address.locality}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>City</strong>
                  <div>{data.address.city}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>State</strong>
                  <div>{data.address.state}</div>
                </div>
              </div> */}
            </div>
          </div>

          <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Spouse Name</strong>
                  <div>{getAccountHolder("spouse_name")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Nationality</strong>
                  <div>{getAccountHolder("nationality")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Gender</strong>
                  <div>{getAccountHolder("gender")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Document Number</strong>
                  <div>{getAccountHolder("document_number")}</div>
                </div>
              </div>
            </div>


            <div className={`${styles.formRow} w-3/4`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${`${styles.formColumnItem}`}`}>
                  <strong>Name of companies owned </strong>
                  <div>{getAccountHolder("name_of_other_companies_owned")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Existing Accounts No. in name of business</strong>
                  <div>{getAccountHolder("existing_account_number")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Document Type</strong>
                  <div>{getAccountHolder("document_type")}</div>
                </div>
              </div>
            </div>
        </div>
      ),
    },
    {
      title: "Organizational Information",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Organization Name</strong>
                <div>{getAccountHolder("organization_name")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Address</strong>
                <div>{getAccountHolder("address")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Bank Name</strong>
                <div>{getAccountHolder("bank_name")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Nature of Business</strong>
                <div>{getAccountHolder("nature_of_business")}</div>
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Type of Ownership</strong>
                <div>{getAccountHolder("type_of_ownership")}</div>
              </div>
            </div>
            <div className={styles.formColumn}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Capital Invested in Business</strong>
                <div>{getAccountHolder("capital_invested_in_business")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Trade License Number</strong>
                <div>{getAccountHolder("trade_license_number")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Purpose of Loan</strong>
                <div>{getAccountHolder("purpose_of_loan")}</div>
              </div>
            </div>
          </div>
          <div>
            {/* <h3 className="ps-2 pt-3">Present Address</h3> */}
            <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Address</strong>
                  <div>{getAccountHolder("address")}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Trade License Validity</strong>
                  <div>{getAccountHolder("trade_license_validity")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Date of Commencement of Business</strong>
                  <div>{moment(getAccountHolder("date_of_commencement_of_business")).format("DD/MM/YYYY")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Tax Identification Number</strong>
                  <div>{getAccountHolder("tax_identification_number")}</div>
                </div>
              </div>
              <div className={`${styles.formColumn}`}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Bank Account Number</strong>
                  <div>{getAccountHolder("bank_account_number")}</div>
                </div>
              </div>
            </div>


            <div className={`${styles.formRow}`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${`${styles.formColumnItem}`}`}>
                  <strong>Annual Income of Organization</strong>
                  <div>{getAccountHolder("annual_income_of_organization")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Annual Expenditure of Organization</strong>
                  <div>{getAccountHolder("annual_expenditure_of_organization")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Fixed Assets of Organization</strong>
                  <div>{getAccountHolder("fixed_assets_of_the_organization")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Manpower Employed in Organization</strong>
                  <div>{getAccountHolder("manpower_employed_in_organization")}</div>
                </div>
              </div>
            </div>

            <div className={`${styles.formRow} w-1/2`}>
              <div className={`${styles.formColumn}`}>
                <div className={`${`${styles.formColumnItem}`}`}>
                  <strong>Cost of Inventory</strong>
                  <div>{getAccountHolder("cost_of_inventory")}</div>
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={`${styles.formColumnItem}`}>
                  <strong>Purpose of Loan</strong>
                  <div>{getAccountHolder("purpose_of_loan")}</div>
                </div>
              </div>
              
              </div>
        </div>
      ),
    },
    {
      title: "Professional Information",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Employment Type</strong>
                <div>{getAccountHolder("employment_type")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Company Name</strong>
                <div>{getAccountHolder("company_name")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Monthly Income</strong>
                <div>{getAccountHolder("monthly_income")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Additional Income</strong>
                <div>{getAccountHolder("additional_income")}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Office Address</strong>
                <div>{getAccountHolder("office_address")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Designation</strong>
                <div>{getAccountHolder("designation")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Length of Service</strong>
                <div>{getAccountHolder("length_of_service")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Existing Loan Details</strong>
                <div>{getAccountHolder("existing_loan_details")}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Existing Loan Details",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Existing Loan</strong>
                <div>{getAccountHolder("existing_loan")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Existing Card</strong>
                <div>{getAccountHolder("existing_card")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Monthly Family Expenses</strong>
                <div>{getAccountHolder("monthly_family_expenses")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Existing EMI Amount</strong>
                <div>{getAccountHolder("existing_emi_amount")}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Income & Expenditure",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Monthly Income</strong>
                <div>{getAccountHolder("monthly_income")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Additional Income</strong>
                <div>{getAccountHolder("additional_income")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Monthly Family Expenses</strong>
                <div>{getAccountHolder("monthly_family_expenses")}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Other Bank Information",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Bank Name</strong>
                <div>{getAccountHolder("bank_name")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Account Number</strong>
                <div>{getAccountHolder("acc_number")}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Guarantor Details",
      content: (
        <div className={`${styles.accountDetails} p-3`}>
          <h1 className="ps-2 pt-3 font-medium text-xl">First Guarantor</h1>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Occupation</strong>
                <div>{getAccountHolder("occupation_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Name</strong>
                <div>{getAccountHolder("name_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Company Name</strong>
                <div>{getAccountHolder("company_name_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Designation</strong>
                <div>{getAccountHolder("designation_g1")}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Permanent Address</strong>
                <div>{getAccountHolder("permanent_address_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Present Address</strong>
                <div>{getAccountHolder("present_address_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Residence Mobile Number</strong>
                <div>{getAccountHolder("residence_mobile_number_g1")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Relation With Applicant</strong>
                <div>{getAccountHolder("relation_with_applicant_g1")}</div>
              </div>
            </div>
          </div>

          <h1 className="ps-2 pt-3 font-medium text-xl">Second Guarantor</h1>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Occupation</strong>
                <div>{getAccountHolder("occupation_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Name</strong>
                <div>{getAccountHolder("name_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Company Name</strong>
                <div>{getAccountHolder("company_name_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Designation</strong>
                <div>{getAccountHolder("designation_g2")}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.formRow}`}>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Permanent Address</strong>
                <div>{getAccountHolder("permanent_address_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Present Address</strong>
                <div>{getAccountHolder("present_address_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Residence Mobile Number</strong>
                <div>{getAccountHolder("residence_mobile_number_g2")}</div>
              </div>
            </div>
            <div className={`${styles.formColumn}`}>
              <div className={`${styles.formColumnItem}`}>
                <strong>Relation With Applicant</strong>
                <div>{getAccountHolder("relation_with_applicant_g2")}</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Document Required",
      content: (
        <Form layout="vertical" className={`p-3`}>
          <Form.Item label="Document Type">
            <Input placeholder="Enter document type" />
          </Form.Item>
          <Form.Item label="Upload Document">
            <Input type="file" />
          </Form.Item>
        </Form>
      ),
    },
  ];

  const filterAccordionData = (data,sec) => {
    return data?.filter(item => sec?.includes(item?.title));
  };
  const filteredAccordionData = filterAccordionData(accordionData, sectionNames);
  return (
    <>
    {dataLoading && (
      <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
        <ColorRing
          visible={true}
          height="80"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={[]}
        />
      </div>
    )}
    <div className={`flex dark:bg-dark-background mainBodyContainer`}>
      <SideMenu />
      <div className="flex-1 ">
        <Header />
        <div className={`z-0 p-10 mainContainer fixedHeightContainer`}>
          <div className="flex justify-between items-center  mb-6">
            <h1 className="text-24 flex font-bold flex-column">
              {" "}
              <span className={`${styles.crmText} flex`}> <img className="cursor-pointer" onClick={backBtnPage} src="/assets/images/arrow-sm-left.svg"/> CRM /</span> Listing Board
            </h1>
            {/* <button className="bg-blue-500 text-white px-4 py-2 rounded">
              Download Report
            </button> */}
          </div>
          <div className={`${styles.fieldHeader}`}>
            <div className="bg-gray-50 p-4 rounded-t-lg">
              <div className={`flex ${styles.loanFieldHeader}`}>
                <div
                  className={`flex-1 font-medium ${styles.fontBold} text-black-500 uppercase`}
                >
                  Loan Type
                </div>
                <div
                  className={`flex-1 font-medium ${styles.fontBold} text-black-500 uppercase`}
                >
                  Opening Date
                </div>
                <div
                  className={`flex-1 font-medium ${styles.fontBold} text-black-500 uppercase`}
                >
                  NID No.
                </div>
                <div
                  className={`flex-1 font-medium ${styles.fontBold} text-black-500 uppercase`}
                >
                  Date of Birth
                </div>
                <div
                  className={`flex-1 font-medium ${styles.fontBold} text-black-500 uppercase`}
                >
                  Expected Amount
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded-b-lg">
              <div className="flex">
                <div className={`flex-1 ${styles.fontDetails}`}>
                  {loanDetails?.loan_type}
                </div>
                <div className={`flex-1 ${styles.fontDetails}`}>
                  {moment(loanDetails?.created_at).format('DD/MM/YYYY')}
                </div>
                <div className={`flex-1 ${styles.fontDetails}`}>
                  {getAccountHolder("nid_number")}
                </div>
                <div className={`flex-1 ${styles.fontDetails}`}>
                  {getAccountHolder("DOB") != "-" ? moment(getAccountHolder("DOB")).format('DD/MM/YYYY') : "-"}
                </div>
                <div className={`flex-1 ${styles.fontDetails}`}>
                  {details.expectedAmount}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.fieldMainContent} `}>
            <div className={`${styles.fieldMainContentItem} pt-3`}>
              <div className={`${styles.accordion} `}>
                {filteredAccordionData?.map((item, index) => (
                  <div key={index} className={`${styles.accordionItem}`}>
                    <div
                      className={`${styles.accordionTitle}`}
                      onClick={() => toggleAccordion(index)}
                    >
                      <div>
                        {" "}
                        <span className={`${styles.indexAccordion}`}>
                          {index + 1}
                        </span>{" "}
                        <span className={styles.accordionHeading}>
                          {item?.title}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <span>
                          {" "}
                          <span
                            class={`w-2.5 h-2.5 me-1 ${getSectionStatus(item?.title) === true ? styles.ellipsis : styles.ellipsisPending}  rounded-full inline-block mx-0`}
                          ></span>
                          {getSectionStatus(item?.title) === true ? "Completed" : "Pending"}
                        </span>
                      </div>
                    </div>

                      <div
                      className={`${styles.accordionContent} ${
                        activeIndex === index ? styles.show : ""
                      }`}
                    >
                        {item.content}
                      </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CRMLoanAccountDetails;