import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import styles from "../../styles/userEdit.module.scss";
import { useEffect, useState } from "react";
import API from "../../helpers/api/index";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Icon from "../../component/core-component/Icon";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import { ColorRing } from "react-loader-spinner";

export default function UserEdit(data) {
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useState({});
    const [userInfo] = useSelector(( Gstate ) => [Gstate?.UserDataReducer?.user_data])
    const [dataLoading, setDataLoading] = useState(true);
    const [showColorActive, setShowColorActive] = useState(true);
    const [isUpdating,setIsUpdating]=useState(false);
    // const [activeTab, setActiveTab] = useState('profile');

    // const [userData1] = useSelector(( Gstate ) => [Gstate?.UserListReducer])

    // const userData = {
    //     "success": true,

    // };


    // const { first_name, last_name, mobile_phone, email,status } = userData;
    const [updateData, setUpdateData] = useState({
        first_name: userData.first_name,
        last_name: userData.last_name,
        mobile_number: userData.mobile_phone,
        email: userData.email,
    });

    const isMobileDevice = window.screen.width <= 900;
    const width = window.screen.width;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdateData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleUpdate = async () => {
        setIsUpdating(true);
        // Call your update API here with updated user data
        try {
            const payload = {
                user_id: getUserID(),
                first_name: updateData.first_name,
                last_name: updateData.last_name,
                mobile_phone: updateData.mobile_number,
            }
            const { data } = await API.apiPut(`/users/profile`, { payload: encodeData(payload) })
            if (data?.success) {
                setIsUpdating(false)
                toast.success(data?.message);
                getSelectedUserDetails()
            }
            else {
                toast.error(data?.message)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
        }
    };

    // const handleUpdate = () => {
    //     fetch('your_update_api_url', {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(userData),
    //     })
    //     .then(response => {
    //         if (response.ok) {
    //             console.log('User data updated successfully.');
    //             // Handle success case as needed
    //         } else {
    //             console.error('Failed to update user data.');
    //             // Handle error case as needed
    //         }
    //     })
    //     .catch(error => {
    //         console.error('Error occurred while updating user data:', error);
    //         // Handle error case as needed
    //     });
    // };
    const [permissions, setPermissions] = useState([]);

    const handleCheckboxChange = async (index, type, permission) => {
        try {
            const updatedPermissions = [...permissions];
            if (permission.write == true && type == 'read') {
                return toast.warning('Write permission enabled!')
            }
            if (type === 'write') {
                updatedPermissions[index][type] = !updatedPermissions[index][type];
                if (updatedPermissions[index][type]) {
                    updatedPermissions[index].read = true;
                }
            } else if (type === 'read') {
                if (!updatedPermissions[index].write) {
                    updatedPermissions[index][type] = !updatedPermissions[index][type];
                }
            }
            else if (type === 'delete') {
                updatedPermissions[index][type] = !updatedPermissions[index][type];
            }

            setPermissions(updatedPermissions);
            // localStorage.setItem('perm', JSON.stringify(updatedPermissions));
            const updatedPermission = updatedPermissions[index];
            const requestData = {
                process_id: updatedPermission.process_id,
                user_id: getUserID(),
                read: updatedPermission.read,
                write: updatedPermission.write,
                delete: updatedPermission.delete
            };

            const { data } = await API.apiPut(`/users/permission/update`, { payload: encodeData(requestData) })
            if (data?.success) {
                toast.success(data?.message);
            }
            else {
                toast.error(data?.message)
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
        }

    };

    const [activeTab, setActiveTab] = useState('profile');

    const handleTabClick = (tab) => {
        setShowColorActive(true);
        setActiveTab(tab);
    };

    function getUserID() {
        const params = new URLSearchParams(location.search);
        return params.get('user_id') || '';
    }
    const getSelectedUserDetails = async () => {
        try {
            const queryParamValue = getUserID()
            const payload = {
                user_id: queryParamValue
            }
            const { data } = await API.apiGet(`/users/profile`, '', payload)
            if (data?.success) {
                setUserData(data.data)
                setInputFormData(data.data)
                toast.success(data?.message);
                setDataLoading(false);
            }
            else {
                toast.error(data?.message)
                setDataLoading(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            setDataLoading(false);
            // toast.error(error?.response?.data?.message)
        }
    }

    const setInputFormData = (userData) => {
        setUpdateData({
            first_name: userData.first_name,
            last_name: userData.last_name,
            mobile_number: userData.mobile_phone,
            email: userData.email,
        })
        setPermissions(userData.Permissions)
    }

    useEffect(() => {
        getSelectedUserDetails()
    }, [])

    const handleBack = () => {
        navigate('/userManagement')
    }

    return (
        <>
            <div className={`flex dark:bg-dark-background reconcilitaion-main-container`}>
                <SideMenu headerName="User Details"/>
                <div className={`mainBodyContainer flex-1 ${styles.userManagement} ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    {dataLoading ? (
                    <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>
                ) : (
                    <div className={`p-10 mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
                        <div className={`${styles.userEdit}`}>
                            <div className="p-4 flex">
                                <div className="cursor-pointer" onClick={() => handleBack()}>
                                    <Icon iconName="IconBackBtn" width={16} height={16} /></div>
                                <div className="ps-2">
                                    <b> User Details</b>
                                </div>
                            </div>
                            <div className={`flex ${styles.userDetails} ${isMobileDevice ? 'ps-0' : 'ps-4'} ${isMobileDevice && 'flex-col'}`}>
                                <div className={`w-full m-2 pe-3 ${styles?.sellerDetails} ${isMobileDevice && 'flex justify-between px-2'}`}>
                                    <div className={`text-black-50 ${styles.labelColor}`}>First Name:</div>
                                    <div
                                        className={`mt-1 me-3  ${styles?.userName}`} >
                                        {userData?.first_name && userData?.first_name?.charAt(0).toUpperCase() + userData?.first_name?.slice(1)}
                                    </div>

                                </div>
                                <div className={`w-full m-2 px-2 ${styles?.sellerDetails} ${isMobileDevice && 'flex justify-between px-0 pe-3'}`}>
                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>
                                        Last Name:
                                    </div>
                                    <div className={`mt-1 ${styles?.userName} ${isMobileDevice && 'me-3'}`}>
                                        {userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)}
                                    </div>
                                </div>
                                <div className={`w-full m-2 ${styles?.sellerDetails} ${isMobileDevice ? 'px-2' : 'px-3'} ${isMobileDevice && 'flex justify-between'}`}>
                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Phone number:</div>
                                    <div className={`mt-1 ${styles?.userName} ${isMobileDevice && 'me-3'}`}>{userData?.mobile_phone}</div>
                                </div>
                                <div className={`w-full m-2 ${styles?.sellerDetails}  ${isMobileDevice ? 'px-2' : 'px-3'} ${isMobileDevice && 'flex justify-between'}`}>
                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Email:</div>
                                    <div className={`mt-1  ${styles?.userName} ${isMobileDevice && 'me-3'}`}>{userData?.email}</div>
                                </div>
                                <div className={`w-full m-2 ${isMobileDevice ? 'px-2' : 'px-3'} ${isMobileDevice && 'flex justify-between'}`}>
                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Status:</div>
                                    <div className={`mt-1 ${isMobileDevice && 'me-3'}`}>
                                        {userData?.status ? userData?.status == 'Inactive' ? <span className={`text-red text-center`}><span className="red-dot" />Inactive</span> : <span className={`text-green-500`}><span className="text-green-500 green-dot" />Active</span> : <span className="text-red-500"> --</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-3">
                            <div className=" bg-white p-4 rounded-2xl">
                                <div className={`flex mt-2 tabContainer border-none ${styles.borderNone} relative`}>
                                     <div className={`differentTabs flex ${styles.borderBottom}`}>
                                        <div
                                            className={`w-24 p-2 text-center tabsName ${activeTab === 'profile' ? "showActiveColor commmonActiveTag" : ""}`}
                                        >
                                    <p onClick={() => handleTabClick('profile')} className="mt-2 cursor-pointer">
                                        Profile
                                    </p>
                                    </div>
                                    <div
                                     className={`w-24 p-2 text-center tabsName ${activeTab === 'permissions' ? "showActiveColor commmonActiveTag" : ""}`}
                                    >
                                    <p onClick={() => handleTabClick('permissions')} className="mt-2 cursor-pointer">
                                        Permissions
                                    </p>
                                </div>
                                     </div>
                                </div>
                                

                                {activeTab === 'profile' && (
                                    <div className={`pt-5 mt-2 ${styles.permissions} ${isMobileDevice && 'userProfileContainer_responsive'}`}>
                                    {
                                   isUpdating && <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
                                        <ColorRing
                                            visible={true}
                                            height="80"
                                            width="60"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={[]}
                                        />
                                    </div>
                                }
                                        <div className={`${styles.profileCard} ${isMobileDevice && 'responsive_profileCard'} `}>
                                            <div className={`flex justify-between ${isMobileDevice && 'flex-col'}`}>
                                                <div className={`${styles.firstName} ${isMobileDevice && 'nameContainer'}`}>
                                                    <label className={`${styles.profileName}`}>First Name</label>
                                                    <div>
                                                        <input
                                                            name="first_name"
                                                            value={updateData?.first_name}
                                                            onChange={handleChange}
                                                            className={`${styles.emailBox} mt-1 h-12 px-2 rounded-md border border-solid border-gray-400 bg-white focus:outline-none`}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`${styles.firstName} ${isMobileDevice && 'nameContainer mt-4'}`}>
                                                    <label className={`${styles.profileName}`}>Last Name</label>
                                                    <div>
                                                        <input
                                                            name="last_name"
                                                            value={updateData?.last_name}
                                                            onChange={handleChange}
                                                            className={` ${styles.emailBox} mt-1 h-12 p-2 rounded-md border border-solid border-gray-400 bg-white focus:outline-none`}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <label className={`${styles.profileName}`}>Mobile Number</label>
                                                <div>
                                                    <input
                                                        name="mobile_number"
                                                        value={updateData?.mobile_number}
                                                        onChange={handleChange}
                                                        className={` ${styles.emailBox} mt-1 h-12 p-2 rounded-md border border-solid border-gray-400 bg-white focus:outline-none`}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <label className={`${styles.profileName}`}>Email</label>
                                                <div>
                                                    <input
                                                        name="email"
                                                        value={updateData?.email}
                                                        onChange={handleChange}
                                                        className={` ${styles.emailBox} mt-1 h-12 p-2 rounded-md border border-solid border-gray-400 bg-white focus:outline-none`}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button
                                                    type="button"
                                                    onClick={handleUpdate}
                                                    disabled={isUpdating}
                                                    className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-6 ${styles.updateBtn} ${isUpdating ? 'cursor-not-allowed opacity-50' : ''}`}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'permissions' && (
                                    <div className={` mt-2 ${styles.permissions} ${isMobileDevice && 'userProfileContainer_responsive'}`}>
                                        {!isMobileDevice ? (
                                            <div className={`flex flex-wrap ${styles.userPermissions}`}>
                                                {permissions?.map((permission, index) => (
                                                    permission?.process_name != 'Fingerprint Match' &&
                                                    <div className={`${styles.processPermissions} ${isMobileDevice && 'userPermissionsContainer'}`} key={index}>
                                                        <div className={`${styles.processName}`}>
                                                            <p className={`${styles.accountVerification}`}>{permission?.process_name}</p>
                                                        </div>
                                                        <div className="flex">
                                                            <div className={`${styles.userManage}`}>
                                                                <input
                                                                    className={`me-2 mt-2 ${userInfo?.id == getUserID() ? `cursor-not-allowed` :`cursor-pointer`}`}
                                                                    type="checkbox"
                                                                    checked={permission?.read}
                                                                    onChange={() => handleCheckboxChange(index, 'read', permission)}  
                                                                    disabled= {userInfo?.id == getUserID()}
                                                                />
                                                                <label>Read</label>
                                                            </div>
                                                            {permission.process_name == "Loan Document Storage" && <div className={`${styles.userManage} ms-5`}>
                                                                <input
                                                                    className={`me-2 mt-2 ${userInfo?.id == getUserID() ? `cursor-not-allowed` :`cursor-pointer`}`}
                                                                    type="checkbox"
                                                                    checked={permission?.delete}
                                                                    onChange={() => handleCheckboxChange(index, 'delete', permission)}
                                                                    disabled= {userInfo?.id == getUserID()}
                                                                />
                                                                <label>Delete</label>
                                                            </div>
}
                                                        </div>
                                                        <div className={`${styles.userManage}`}>
                                                            <input
                                                                className={`me-2 mt-2 ${userInfo?.id == getUserID() ? `cursor-not-allowed` :`cursor-pointer`}`}
                                                                type="checkbox"
                                                                checked={permission?.write}
                                                                onChange={() => handleCheckboxChange(index, 'write', permission)}
                                                                disabled= {userInfo?.id == getUserID()}
                                                            />
                                                            <label>Write</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-4 gap-2">
                                                    <span className="font-bold text-sm ms-2 ">LIST</span>
                                                    <span className="font-bold text-sm text-center">READ</span>
                                                    <span className="font-bold text-sm text-center">WRITE</span>
                                                    <span className="font-bold text-sm text-center">DELETE</span>
                                                </div>
                                                {permissions?.map((permission, index) => (
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-4 gap-2">
                                                        <span className="font-bold text-sm ms-2">{permission?.process_name}</span>
                                                        <span className="text-center">
                                                            <input
                                                                className="me-2"
                                                                type="checkbox"
                                                                checked={permission?.read}
                                                                onChange={() => handleCheckboxChange(index, 'read', permission)}
                                                                disabled= {userInfo?.id == getUserID()}
                                                            />
                                                        </span>
                                                        <span className="text-center">
                                                            <input
                                                                className="me-2 mt-2"
                                                                type="checkbox"
                                                                checked={permission?.write}
                                                                onChange={() => handleCheckboxChange(index, 'write', permission)}
                                                                disabled= {userInfo?.id == getUserID()}
                                                            />
                                                        </span>
                                                        <span className="text-center">
                                                            <input
                                                                disabled={permission?.process_name !== "Loan Document Storage" || userInfo?.id == getUserID()}
                                                                className="me-2 mt-2 cursor-not-allowed"
                                                                type="checkbox"
                                                                checked={permission?.delete}
                                                                onChange={() => handleCheckboxChange(index, 'delete', permission)}
                                                            />
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )}      

                </div>
            </div>
            <ToastContainer />
        </>
    )
}