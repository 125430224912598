import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Header from './Header';
import SideMenu from './SideMenu';
import style from "../styles/loanAccountsCardBoard.module.scss";
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import API from '../helpers/api';
import { toast } from 'react-toastify';

const LoanAccountsCardBoard = () => {
    const navigate = useNavigate();
    const [openView, setOpenView] = useState(null);
    const [cardStates, setCardStates] = useState();

    const toggleView = (id) => {
        setOpenView(prevOpenView => prevOpenView === id ? null : id);
    };

    //function for handling drag end(what should happen on drop)
    // const handleDragEnd = (result) => {
    //     debugger
    //     if (!result.destination) return;
    //     const { source, destination } = result;
    //     const sourceColumnId = source.droppableId;//column id of source column
    //     const destinationColumnId = destination.droppableId;//column id of destination
    //     const sourceIndex = source.index;//index of dragged item in source column
    //     const destinationIndex = destination.index;//index of dragged item in destination column

    //     //return if source and destination are same and position is same in source column after drop
    //     if (sourceColumnId === destinationColumnId && sourceIndex === destinationIndex) return;

    //     //remove the item from source column
    //     const updatedSourceColumn = [...cardStates[sourceColumnId]];
    //     const draggedItem = updatedSourceColumn[sourceIndex];
    //     updatedSourceColumn.splice(sourceIndex, 1);
         
    //      //if source and destination columns are same
    //     if (sourceColumnId === destinationColumnId) {
    //         updatedSourceColumn.splice(destinationIndex, 0, draggedItem);
    //         //update the status of source column
    //         setCardStates({
    //             ...cardStates,
    //             [sourceColumnId]: updatedSourceColumn,
    //         });
    //     } else {
    //         //if destination column is different
    //         const updatedDestinationColumn = [...cardStates[destinationColumnId]];
    //         updatedDestinationColumn.splice(destinationIndex, 0, draggedItem);
    //         //update the state of both source and destination column
    //         setCardStates({
    //             ...cardStates,
    //             [sourceColumnId]: updatedSourceColumn,
    //             [destinationColumnId]: updatedDestinationColumn,
    //         });
    //     }
    // };
    //get the name abbr
    const nameAbbr = (name) => {
        const arr = name.split(" ");
        return arr[0][0].toUpperCase() + arr[arr?.length - 1][0].toUpperCase();
    };

    const viewLoanAccount = (item) =>{
        navigate('/CRMLoanAccountDetails', {
            state: { loan_account_id: item?.id }
        });
    }

    const getAccountHolder = (item) =>{
        let accountHolderData = '';
        item?.loan_details?.forEach((sec, secIndx) => {
            sec?.data?.forEach(innerItem => {
                if (innerItem?.variable === 'name_of_account_holder') {
                    accountHolderData = innerItem
                }
            })
        })
        if (accountHolderData != '') {
            return accountHolderData?.value;
        } else {
            return '-';
        }
    }
    

    const getLoanAccountList = async() =>{
        try {
            const { data } = await API.apiGet(`/loan/account`);
            if (data?.success) {
                toast.success(data?.message);
                setCardStates(data?.data);
                // setDataLoading(false)
            } else {

                toast.error(data?.message);
                // setDataLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            // setDataLoading(false)
        }
    }

    useEffect(()=>{
        getLoanAccountList();
    },[])

    //user card
    const UserCard = ({ item, index ,isDragDisabled }) => (
        <Draggable draggableId={item?.id?.toString()} index={index} key={item?.id} isDragDisabled={isDragDisabled}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={style.userCard}
                >
                    <div className="flex flex-col gap-12">
                        <div>
                            <div className="mb-2">Customer Id: <span>{item?.customer_id}</span></div>
                            <div className="mb-2">Customer Name: <span>{getAccountHolder(item)}</span></div>
                            <div>Loan Type: <span>{item?.loan_type}</span></div>
                        </div>
                        <div>
                            <div>Created At: <span>{moment(item?.createdAt).format('DD-MM-YYYY')}</span></div>
                        </div>
                    </div>
                    <div className={`flex justify-between flex-col items-center`}>
                        <div className="relative">
                            <MoreOutlined onClick={() => toggleView(item?.id)} />
                            {openView === item?.id && <span onClick={()=>viewLoanAccount(item)} className={style.viewText}>View</span>}
                        </div>
                        {/* <div>
                            <span className={`${style.nameAbbr} text-white`}>{getAccountHolder(item)}</span>
                        </div> */}
                    </div>
                </div>
            )}
        </Draggable>
    );

    //empty card
    const EmptyCard = () => (
        <div className={`flex items-center justify-center rounded-lg ${style.emptyCard}`} >
            <h1 className="text-xl font-bold">No Records Found</h1>
        </div>
    );

    return (
        <DragDropContext>
            <div className="flex dark:bg-dark-background mainBodyContainer">
                <SideMenu />
                <div className="flex-1">
                    <Header />
                    <div className="z-0 py-10 ps-10 mainContainer fixedHeightContainer">
                        <div>
                            <div className="flex justify-between items-center mb-6">
                                <h1 className="text-24 font-bold">
                                    <span className={`flex`}>
                                    <img src="./assets/images/arrow-sm-left.svg" alt='left-arrow' className='pe-2'/>
                                    <span style={{ color: "#000000A3" }}> CRM/ </span>
                                    <span className='ps-2'> Listing Board</span>
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className={`flex gap-5 ${style.loanAccountsmainContainer}`}>
                            {/* <Droppable droppableId="new-application">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>New Application</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates["new-application"].length > 0 ? cardStates["new-application"].map((item, index) => (
                                                <UserCard key={item.customerId} item={item} index={index} />
                                            )) : <EmptyCard />}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable> */}
                            <Droppable droppableId="in-progress">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>In Progress</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "In Progress") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "In Progress" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true} />
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="document-verification">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Document Verification</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Document Verification") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Document Verification" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true} />
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="pre-approval">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Pre Approval</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Pre Approval") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Pre Approval" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true}/>
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="physical-check">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Physical Check</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Physical Check") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Physical Check" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true}/>
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="approved">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Approved</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Approved") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Approved" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true} />
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="rejected">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Rejected</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Rejected") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Rejected" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true} />
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="aborted">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={style.loanCardContainer}
                                    >
                                        <h1 className={style.containerHeading}>Aborted</h1>
                                        <div className={style.cardsContainer}>
                                            {cardStates?.some(item => item?.status === "Aborted") ? (
                                                cardStates.map((item, index) => (
                                                    item.status === "Aborted" ? (
                                                        <UserCard key={item?.id} item={item} index={index} isDragDisabled={true} />
                                                    ) : null
                                                ))
                                            ) : (
                                                <EmptyCard />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
};

export default LoanAccountsCardBoard;