import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/nomineeDetails.scss";
import { useNavigate } from "react-router-dom";
const NomineeQuickLinks = () => {
  const steps = ["Acount Details", "Nominee Details", "Nominee Quick Links"];
  const navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;

  const changeRequestPage = () =>{
    navigate('/RaiseRequestApproval');
  }
  return (
    <div className={`flex dark:bg-dark-background updatePhone`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu headerName="Nominee Quick Links"/>
      <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header />
        <div className={`z-0 p-10 mainContainer nomineeDetails ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          <Breadcrumb
            steps={steps}
            activeStep={2}
            showBackButton={true}
            redirectUrl={"/nomineeDetails"}
          />
          <div class={`grid grid-cols-3 gap-4 mt-4 ${isMobileDevice && 'mt-8 mb-4'}`}>
            <div className={`col-span-3 rounded p-4 flex flex-wrap cardBackground ${isMobileDevice && 'responsiveCardBackground'}`}>
                <p className="quickLinkText">Quick Links</p>
                <div className={`flex w-full justify-between mt-4 nomineeCardsContainer rounded ${isMobileDevice && 'flex-col'}`}>

                    <div className={`LinksCard flex flex-col ${isMobileDevice && 'responsiveLinksCard'}`}>
                        <div className="linkIcon ">
                        <img src="assets/images/plusIcon.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Customer Creation</p>
                        </div>
                    </div>

                    <div className={`LinksCard flex flex-col ${isMobileDevice && 'responsiveLinksCard'}`}>
                        <div className="linkIcon ">
                        <img src="assets/images/newPen.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Change Customer Information</p>
                        </div>
                    </div>
                     
                    <div className={`LinksCard flex flex-col ${isMobileDevice && 'responsiveLinksCard'}`}>
                        <div className="linkIcon ">
                        <img src="assets/images/Vector.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Bulk AML Screening</p>
                        </div>
                    </div>

                    <div className={`LinksCard flex flex-col ${isMobileDevice && 'responsiveLinksCard'}`}>
                        <div className="linkIcon ">
                        <img src="assets/images/resend.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Customer Review</p>
                        </div>
                    </div>

                    <div onClick={changeRequestPage} className={`cursor-pointer LinksCard flex flex-col ${isMobileDevice && 'responsiveLinksCard'}`}>
                        <div className="linkIcon ">
                        <img src="assets/images/attach.svg" ></img>
                        </div>
                        <div className="linkText">
                        <p>Change Request Approval</p>
                        </div>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NomineeQuickLinks;
