import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/reconcilition.scss";
import WorkerQueBoard from "../dashboard/WorkerQueBoard";
import { useNavigate } from "react-router-dom";
import process from '../../assets/images/process.svg'

function QueueConfiguration() {
  let navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;
  return (
    <>
      <div className={`mainBodyContainer flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part ${isMobileDevice && 'configMainContainer_responsive'}`}>
        <SideMenu headerName="Configuration"/>
        <div className={`flex-1 h-screen overflow-y-scroll ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
          <Header/>
        <div className={`z-0 container p-10 relative mainContainer ${isMobileDevice && 'p-5 mx-auto responsiveMainContainer'}`}  >
          {!isMobileDevice && <div className="font-bold mb-8">Configuration</div>}
          <div className="flex flex-wrap gap-2">
              {/* card 1 */}
              <div className={`bg-white hover:cursor-pointer commonCard ${isMobileDevice ? 'configurationResponsiveCard' : 'configurationCard mx-4'}`} onClick={() => navigate("/queueConfigurationS02")}>
                  <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                    <div>
                      {/* <Icon iconName="userAccount" width="16" height="16" /> */}
                      <img src={process} width="50" alt="savingsAccount"/>
                    </div>
                    <span className="font-bold text-center">
                      Regular Savings Account Configuration
                    </span>
                    <div onClick={() => navigate("/queueConfigurationS02")} className="cursor-pointer arrowIcon commonCardIcons">
                      <img src="assets/right-arrow.svg" alt="file" />
                    </div>
                  </div>
                  <div>
                    <p className="p-4">
                      Supervising queues comprising tasks and workers, allowing dynamic control over play and pause functions to ensure seamless task execution and worker productivity optimization.
                    </p>
                    <br/>
                  <div className="float-end px-4 pb-2">
                    <p>
                      Created on 04 Apr, 2024
                    </p>
                  </div>
                  </div>
              </div>

              {/* card 2 */}
              <div className={`bg-white hover:cursor-pointer commonCard ${isMobileDevice ? 'configurationResponsiveCard mt-3' : 'configurationCard mx-4'}`} onClick={() => navigate("/queueConfigurationS07")}>
                  <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                    <div>
                      {/* <Icon iconName="userAccount" width="16" height="16" /> */}
                      <img src={process} width="50" alt="minorsAccount"/>
                    </div>
                    <span className="font-bold text-center">
                      Minor Account Configuration
                    </span>
                    <div onClick={() => navigate("/queueConfigurationS07")} className="cursor-pointer arrowIcon commonCardIcons">
                      <img src="assets/right-arrow.svg" alt="file" />
                    </div>
                  </div>
                  <div>
                    <p className="p-4">
                      Supervising queues comprising tasks and workers, allowing dynamic control over play and pause functions to ensure seamless task execution and worker productivity optimization.
                    </p>
                    <br/>
                  <div className="float-end px-4 pb-2">
                    <p>
                      Created on 04 Apr, 2024
                    </p>
                  </div>
                  </div>
              </div>
              
              {/* card 3 */}
              <div className={`bg-white hover:cursor-pointer commonCard ${isMobileDevice ? 'configurationResponsiveCard mt-3' : 'configurationCard mx-4'}`} onClick={() => navigate("/queueConfigurationCommon")}>
                  <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                    <div>
                      {/* <Icon iconName="userAccount" width="16" height="16" /> */}
                      <img src={process} width="50" alt="minorsAccount"/>
                    </div>
                    <span className="font-bold text-center">
                      Common Account Configuration
                    </span>
                    <div onClick={() => navigate("/queueConfigurationCommon")} className="cursor-pointer arrowIcon commonCardIcons">
                      <img src="assets/right-arrow.svg" alt="file" />
                    </div>
                  </div>
                  <div>
                    <p className="p-4">
                      Supervising queues comprising tasks and workers, allowing dynamic control over play and pause functions to ensure seamless task execution and worker productivity optimization.
                    </p>
                    <br/>
                  <div className="float-end px-4 pb-2">
                    <p>
                      Created on 04 Apr, 2024
                    </p>
                  </div>
                  </div>
              </div>
          </div>
            {/* <WorkerQueBoard/> */}
        </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
}
export default WithAuth(QueueConfiguration);
