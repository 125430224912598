import React from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import Breadcrumb from "../../../component/Breadcrumb";
import { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import API from "../../../helpers/api/index";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../../component/Pagination";
import styles from "../../../styles/ivrCallingDetails.module.scss";
import { useNavigate,useLocation } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { handleErrorMessage } from "../../../common/commonFunction";
import { WithAuth } from "../../../common/WithAuth";

// const location = useLocation();

const IVRPhoneCallDetails = () => {
    const [ivrConfigCalling, setIvrConfigCalling] = useState();
    const [showModal, setShowModal] = useState(false);
    const [initialDetails, setInitialDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const [listId,setListId] = useState("");
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = ivrConfigCalling?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = ivrConfigCalling?.length ? Math.ceil(ivrConfigCalling?.length / recordsPerPage) : 0;
    const [dataLoading, setDataLoading] = useState(false)
    const [audioData, setAudioData] = useState();
    const navigate = useNavigate();
    const isMobileDevice = window.screen.width <= 900;
    const width = window.screen.width;
   
    async function getlistID() {
        const params = new URLSearchParams(window.location.search);
        return params.get('list_id') || '';
    }

    const [searchQuery, setSearchQuery] = useState('');
    
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
    };

    const filteredData = currentRecords?.filter(item =>
        (item?.phone_number && item?.phone_number?.includes(searchQuery)) ||
        (item?.call_request_id && item?.call_request_id?.includes(searchQuery)) ||
        (item?.call_recording && item?.call_recording?.includes(searchQuery)) ||
        (item?.status && item?.status?.toLowerCase()?.includes(searchQuery)) ||
        (item?.call_duration && item?.call_duration?.includes(searchQuery))
    );
  

    useEffect(() => {
        getIvrConfigCallList();
        setDataLoading(true);
    }, []);
    const getIvrConfigCallList = async () => {
        setListId(localStorage.getItem("list_id"));
        try{
            const id = await getlistID();
            const { data } = await API.apiGet(`/v1/ivr/call/list/${id}`);  
            if(data?.success){
                await Promise.all(data?.data?.map(async item => {
                    // item.data.call_status = await getIVRStatus(item.data.call_status)
                    item.recording_signedUrl = await getAudioFile(item?.call_recording)
                    return item;
                }));
                setIvrConfigCalling(data?.data);
            //   getAudioFile(data?.data?.[0]?.data?.recording_url);
            //   setAudioData(data?.data?.[0]?.data?.recording_url);
              toast.success(data?.message);
              setDataLoading(false);
            }else{
              toast.error(data?.message)
              setDataLoading(false);
            }
           }
           catch(error){
            // handleErrorMessage(error);
            // toast.error(error?.response?.data?.message);
            setDataLoading(false);
          }
    };

    async function getAudioFile(recording_url){
        try{
            const params = {
                recording_url
            }
            const response = await API.apiGet(`v1/ivr/call/recording`,'',params);
            if (response?.status == 200) {
                // setAudioData(response?.data?.signedUrl);
                return response?.data?.signedUrl;
            } else {
                return '';
            }
        }catch (err) {
            console.log('Error fetching audio:', err)
            return '';
        }
    }

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const closeModal = () => {
        setShowModal(false);
    };
    const steps = ["services", "IVR Calling List",`${listId}`];

    const setStatus = (status) => {
        const item = status?.split("-");
        const capitalizedParts = item?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1));
        return capitalizedParts?.join("-");
    };

    // function phoneCallDetails(item){
    //     let list_id = item?.list_id;
    //     navigate(`/viewRequest?list_id=${list_id}`);
    // }

    const getIVRStatus = async (statusCode) => {
        try {
            const { data } = await API.apiGet(`v1/ivr/call/status/code/${statusCode}`)
            if (data?.success) {
                return data?.data?.call_status_message;
            } else {
                return '-';
            }
        } catch (error) {
            console.log('error in status code =====>', error)
            return '-';
        }
    }

    return (
        <>
        <div className={`flex dark:bg-dark-background updateIvrContainer mainBodyContainer`}>
            <SideMenu headerName="IVR Calling List" />
            <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                <Header/>
                <div className={`z-0 p-10 mainContainer ${!isMobileDevice && 'container'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
                    <div className={`flex justify-between ${isMobileDevice && 'flex-col'}`}>
                        <Breadcrumb
                            steps={steps}
                            activeStep={1}
                            showBackButton={true}
                            redirectUrl={"/callingDetails"}
                        />

                        {currentRecords?.length > 0 && <div className={`flex relative ${isMobileDevice && 'justify-end mt-2'}`}>
                            <img src='assets/images/Vector.svg' className={`searchLogo ${!isMobileDevice ? 'absolute' : 'relative searchLogo_responsive'}`} />
                            <input
                                type='text'
                                placeholder='Search'
                                onChange={(e) => handleSearch(e)} 
                                className={`${!isMobileDevice ? 'numberSearch' : 'callingListSearchInput'}`}
                            //   value={searchData}
                            />
                        </div>}
                    </div>
                    <div className=" mt-4">
                    {currentRecords?.length > 0 ?
                        <>
                            {!isMobileDevice ? (
                                <table
                                    className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}
                                >
                                    <thead>
                                        <tr className="ps-2 pe-2">
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Phone Number
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Call ID
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Recording Media
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Duration
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px", width: '15%' }}
                                            >
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRecords?.map((item, index) => (
                                            <tr
                                                style={{ backgroundColor: "white" }}
                                                key={index}
                                                className="border-b"
                                            >
                                                <td className={`p-4 ${styles.listItem}`}>{item?.phone_number}</td>
                                                <td title={item?.call_request_id} className={`p-4 capitalize ${styles.workflowText}`}>
                                                    {item?.call_request_id.length > 20 ? item?.call_request_id.slice(0, 19) + "..." : item?.call_request_id}
                                                </td>
                                                <td className={item?.recording_signedUrl !== null ? "text-center" : "text-start"}>
                                                    {item?.recording_signedUrl !== null ? (
                                                        <audio id="audioPlayer" src={item?.recording_signedUrl} controls></audio>
                                                    ) : (
                                                        <span>No recording found</span>
                                                    )}
                                                </td>
                                                <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.call_duration ? item?.call_duration : "-"}</td>
                                                {/* <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.data?.user_inputs ? item?.data?.user_inputs.split('_').join(', ') : "-"}</td> */}
                                                <td className={`p-4 capitalize ${styles.workflowText}`}><span title={item?.status} className={`p-2 ${styles.status}`}>
                                                    {item?.status.length > 20 ? item?.status.slice(0, 19) + "..." : item?.status}
                                                </span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div>
                                    {currentRecords?.map((item, index) => (
                                    <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                            <span className="font-bold">Phone Number</span>
                                            <div className="flex items-center justify-between">
                                                <span>{item?.data?.call_to}</span> 
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                            <span className="font-bold">Call ID</span>
                                            <div className="flex items-center justify-between">
                                                <span>{item?.data?.call_request_id.length > 15 ? item?.data?.call_request_id.slice(0, 15) + "..." : item?.data?.call_request_id}</span> 
                                                <Popup trigger={
                                                    <span className="w-3">
                                                        <img src="/assets/images/tooltip-info.png" alt="info"/>
                                                    </span>
                                                    }
                                                    className="textContainer"
                                                    position="top right">
                                                    <div className="break-all">{item?.data?.call_request_id}</div>
                                                </Popup>
                                            </div>
                                        </div>
                                        <div className={`flex justify-between border-b p-2.5 ${(width >= 550 && width <= 900) && 'flex-row'} ${width < 550 && 'flex-col' }`}>
                                            <span className="font-bold">Recording Media</span>
                                            <div className="flex items-center justify-center mt-2">
                                                <span>{item?.data?.recording_signedUrl != null ? <audio id="audioPlayer" src={item?.data?.recording_signedUrl} controls></audio> : <div>No recording found</div>}</span> 
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                            <span className="font-bold">Duration</span>
                                            <div className="flex items-center justify-between">
                                                <span>{item?.data?.call_duration}</span> 
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                            <span className="font-bold">User Input</span>
                                            <div className="flex items-center justify-between">
                                                <span>{item?.data?.call_duration}</span> 
                                            </div>
                                        </div>
                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                            <span className="font-bold">Status</span>
                                            <div className="flex items-center justify-between">
                                                <span>{item?.status}</span> 
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            )}
                        </>
                        :
                        <>
                            {!isMobileDevice ? (
                                <>
                                    <table className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}>
                                        <thead>
                                            <tr className="ps-2 pe-2">
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Phone Number
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Call ID
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Recording Media
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Duration
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    User Input
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    {dataLoading ? 
                                        <div className="bg-white flex justify-center" >
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                            />
                                        </div>
                                    :
                                        <div className="bg-white flex justify-center py-5 font-semibold" >
                                            No data found
                                        </div>
                                    }
                                </>
                            ) : (
                                <div>
                                    {dataLoading ?
                                        <div className="flex justify-center" >
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                            />
                                        </div> 
                                        : 
                                        <div className="noDataFound">
                                            <img
                                            src='/assets/noData.svg'
                                            alt="no-data"
                                            className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                            />
                                        <div className="text-xl font-semibold">
                                            No data found
                                        </div>
                                        </div>
                                    }
                                </div>
                            )}
                        </>
                    }
                    </div>
                    {!dataLoading && filteredData && filteredData?.length > 0 &&
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        ></Pagination>
                    }
                </div>
            </div>
        </div>
        <ToastContainer/>
        </>
    );
};
export default WithAuth(IVRPhoneCallDetails);
