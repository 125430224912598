import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import Breadcrumb from "../../../component/Breadcrumb";
import { WithAuth } from "../../../common/WithAuth";
import "../../../styles/humanVideoKyc.scss";
import { TbPointFilled } from "react-icons/tb";
import VideoThumbnail from 'react-video-thumbnail';

import {
  Flex,
  Input,
  Row,
  Col,
  Card,
  Upload,
  Checkbox,
  Collapse,
  Tag,
} from "antd";
import API from "../../../helpers/api";
import { useSelector } from "react-redux";
import moment from "moment";
import Icon from "../../../component/core-component/Icon";
import { ColorRing } from "react-loader-spinner";
const CallInQueues = (props) => {
  const {
    markedInTime,
  } = props
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const kyc_id = params.get('kyc_id');
  const kyc_key = params.get('kyc_key');
  const { Panel } = Collapse;
  const [callQData, setCallQData] = useState([])
  const [userData, themeDetails] = useSelector((Gstate) => [
    Gstate?.UserDataReducer?.user_data, 
    Gstate?.UserThemeReducer?.themeDetails,
  ]);
  const [completedData, setCompletedData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [signedData, setSignedData] = useState('')
  const [activeOption, setActiveOption] = useState("activeCall");
  const [currentSelectedUserIndex, setCurrentSelectedUserIndex] = useState();
  const [currentSelectedUserData, setCurrentSelectedUserData] = useState(
    null
  );
  const [currentTime, setCurrentTime] = useState(() => {
    const savedTime = localStorage.getItem('currentTime');
    return savedTime ? JSON.parse(savedTime) : 0;
  });
  const [activeStatus, setActiveStatus] = useState(() => {
    const savedRunning = localStorage.getItem('activeStatus');
    return savedRunning ? JSON.parse(savedRunning) : 'active';
  });
  const [geoLocation, setGeoLocation] = useState(null);

  useEffect(() => {
    localStorage.setItem('currentTime', JSON.stringify(currentTime));
    localStorage.setItem('activeStatus', JSON.stringify(activeStatus));
  }, [currentTime, activeStatus]);

  const activeCloseCall = (e) => {
    setActiveOption(e?.target?.name);
  };



  const fetchData = async () => {
    setDataLoading(true);
    if (userData) {
      try {
        const response = await API.apiGet(`videocalling/fetch/record`);
        if (response?.data?.success) {
          setDataLoading(false);
          const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setCompletedData(sortedData)
        } else {
          setDataLoading(false);
        }
      } catch (error) {
        setDataLoading(false);
        console.error('Error fetching data:', error);
      }
    }
  };

  const getPersonalDetail = async() =>{
    setDataLoading(true);
    try {
      const response = await API.apiGet(`videocalling/fetch/detail?id=${kyc_id}`);
      if (response?.data?.success) {
        setDataLoading(false);
        setCurrentSelectedUserData(response?.data?.data)
        setGeoLocation(response?.data?.data?.kyc_location)
        handleSelectedUser(response?.data?.data, 0);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if(kyc_key === "detailPage"){
      getPersonalDetail();
    }
    else{
      fetchData();
    }
  }, [userData]);

  const getMergeVideo = async(item, index) => {
    try {
      const response = await API.apiPost(`/videocalling/merged/video`, {id: item?.id});
      if (response?.data?.success) {
        const response = await API.apiGet(`videocalling/fetch/document?id=${item?.id}`);
        if (response?.data?.success) {
          setSignedData(response?.data);
          setDataLoading(false);
        } else {
          setDataLoading(false);
        }
      } 
    } catch (error) {
      setDataLoading(false);
      console.log('error merging video', error);
    }
  } 

  const handleSelectedUser = async (item, index) => {
    setDataLoading(true);
    setSignedData('');
    setCurrentSelectedUserIndex(index);
    setCurrentSelectedUserData(item);
    try {
      const response = await API.apiGet(`videocalling/fetch/document?id=${item?.id}`);
      if (response?.data?.success) {
        setSignedData(response?.data)
        if (response?.data?.mergedVideoUrl?.signedUrl == null) {
          getMergeVideo(item, index);
        } else {
          setDataLoading(false);
        }
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      console.error('Error fetching video data:', error);
    }

  };

  const handleBreak = (event) => {
    setActiveStatus(event?.target?.value);
  }

  useEffect(() => {
    let interval;
    if (activeStatus == 'active') {
      interval = setInterval(() => {
        setCurrentTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
      localStorage.removeItem('currentTime');
      localStorage.removeItem('activeStatus');
    }
  }, [activeStatus]);

  const formatTime = (time) => {
    const totalTime = [];
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);
    if (getHours > 0) {
      totalTime?.push(`${getHours}h`)
    } 
    const minutes = Math.floor(time / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    if (getMinutes > 0) {
      totalTime?.push(`${getMinutes}m`)
    }
    const getSeconds = `0${time % 60}`.slice(-2);
    if (getSeconds > 0) {
      totalTime?.push(`${getSeconds}s`)
    }
    if (totalTime?.length > 0) {
      return totalTime?.join(':');
    } else {
      return '0s';
    }
  };

  const steps = [
    "Services",
    "In-Person Verification",
    ...(kyc_key !== "detailPage" ? ["Go Live"] : []),
    kyc_key === "detailPage" ? "Call Details" : "Completed Calls"
  ];



  return (
    <>
      <div
        className={`flex dark:bg-dark-background updatePhone mainBodyContainer`}
      >
        <SideMenu />
        <div className="min-h-screen flex-1 overflow-hidden">
          <Header />
          <div className="z-0 p-10 mainContainer" style={dataLoading ? { filter: 'blur(2px)', pointerEvents: 'none' } : null}>
          {dataLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
            <ColorRing
              visible={true}
              height="80"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={[]}
          />
      </div>}
            <div className={`flex justify-between items-center`}>
              <Breadcrumb
                steps={steps}
                activeStep={3}
                showBackButton={true}
                redirectUrl={kyc_key === "detailPage" ? "/humankyclist" : "/goLive"}
              />
              {
                kyc_key !== "detailPage" &&
                <div className={`flex items-center`}>
                <div className="flex me-2 font-semibold"><span className="w-max me-1">{`Marked In At: `}</span><span className="w-max"> {markedInTime}</span></div>
                <div className={`flex me-2 font-semibold ${activeStatus == 'active' ? 'agentIsActive' : 'agentInBreak'}`}><span className={`w-max`}>Active From: {formatTime(currentTime)}</span></div>
                <select
                  id="mark_live"
                  name="mark_live"
                  value={activeStatus}
                  className={`py-2 rounded outline-none ps-2 border w-full markActiveDropdown ${activeStatus == 'active' ? 'agentIsActive' : 'agentInBreak'}`}
                  onChange={(e) => {handleBreak(e)}}
              >
                  <option value="break">Break</option>
                  <option value="active">Active</option>
              </select>
              </div>
              }
            </div>
            <div className="mt-3 fixedHeight bg-white overflow-y-scroll overflow-x-hidden p-5 rounded ">
              <div className="flex justify-between">
                
                {/* <button onClick={clearIntervalAndCallFunction}>stop</button> */}
              </div>
              <div className="flex">
                {currentSelectedUserData != null || kyc_key === "detailPage" ? 
                  <div className={`${kyc_key === "detailPage" ? "w-full" : "w-3/5"} p-2`}>
                    <div className="selectedUserProfile">
                      <p className="selectedUserName">
                        {currentSelectedUserData?.first_name}{" "} {currentSelectedUserData?.last_name}
                      </p>
                      <p className={`selectedUserStatus flex items-center ${currentSelectedUserData?.status === 'Pending' ? 'pendingStatus' : currentSelectedUserData?.status === 'Completed' ? 'text-green-600' : 'rejectedStatus text-red-600'}`}>
                        <p className={`border me-1  ${currentSelectedUserData?.status === 'Pending' ? 'selectedUserStatusBullet' :
                          currentSelectedUserData?.status === 'Completed' ? 'completedStatusBullet text-green-700' : 'rejectedStatusBullet text-red-600'}`}></p>
                        {currentSelectedUserData?.status}
                      </p>
                    </div>
                    <div className="flex mt-4 rounded-lg bg-white selectedUserAdditionalInfo">
                      <div className={`${kyc_key === "detailPage" ? "w-2/12" : "w-1/4"} p-2`}>
                        <img src="assets/images/selectedUserProfile.jpg" />
                      </div>
                      <div className="w-3/4 flex-column flex items-center">
                        <div className={`p-4`}>
                          <div className="">
                            <p>Date of Birth : {currentSelectedUserData?.DOB}</p>
                          </div>
                          <div className="">
                            <p>Contact No. : {currentSelectedUserData?.verification_number}</p>
                          </div>
                          <div className="">
                            <p>Email ID : {currentSelectedUserData?.email}</p>
                          </div>
                          {/* <div className="">
                        <p>Address : {currentSelectedUserData.address}</p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 p-3">
                      <p className="flex callSummary">
                        Call Summary
                        {/* <p className="ms-2 noOfQuestion">05/05</p> */}
                      </p>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-lg">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  {/* <div className="w-8 h-8 items-center rounded-md questionNumber flex justify-center">
                                <p>1</p>
                              </div> */}
                                  <div className="ms-2 questionList">
                                    <p className="">Call Status</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >
                            <Row>
                              <Col span={6} className="ps-4">
                                <div>
                                  <p className="text-md font-medium pb-1">Call received at</p>
                                  <p className="text-gray-500 text-sm"> {currentSelectedUserData?.call_stats?.call_received_at ? moment(currentSelectedUserData?.call_stats?.call_received_at).format('DD MMM YYYY hh:mm:ssa') : '-'}</p>
                                </div>
                              </Col>
                              <Col span={6} className="ps-4">
                                <div>
                                  <p className="text-md font-medium pb-1">Call ended at</p>
                                  <p className="text-gray-500 text-sm">{currentSelectedUserData?.call_stats?.call_ended_at ? moment(currentSelectedUserData?.call_stats?.call_ended_at).format('DD MMM YYYY hh:mm:ssa') : '-'}</p>
                                </div>
                              </Col>
                              <Col span={6} className="ps-4">
                                <div>
                                  <p className="text-md font-medium pb-1">Call Duration </p>
                                  <p className="text-gray-500 text-sm">{currentSelectedUserData?.call_stats?.call_duration ? currentSelectedUserData?.call_stats?.call_duration : '-'}</p>
                                </div>
                              </Col>
                              <Col span={6} className="ps-4">
                                <div>
                                  <p className="text-md font-medium pb-1">Call Status</p>
                                  <p className={`font-medium flex items-center text-sm mt-2 ${currentSelectedUserData?.call_stats?.call_status === 'Completed' ? 'completedStatusBullet text-green-700' : currentSelectedUserData?.call_stats?.call_status === 'Rejected' ? 'text-red-600' : 'text-yellow-600'}`}><p className="ms-2 ">{currentSelectedUserData?.call_stats?.call_status ? currentSelectedUserData?.call_stats?.call_status : '-'}</p></p>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                      </div>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-md">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  <div className="ms-2 questionList">
                                    <p>Call Transcription</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >
                            <div className="border border-dashed border-2 border-slate-400 rounded-lg p-3">
                              <Row justify="space-between">
                                <Col span={12} className="p-2">
                                  <div className="gutter-row rounded-lg">
                                    <p>{currentSelectedUserData?.customer_video_text ? currentSelectedUserData?.customer_video_text : 'No Text available'}</p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-md">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  {/* <div className="w-8 h-8 items-center rounded-md questionNumber flex justify-center">
                                <p>2</p>
                              </div> */}
                                  <div className="ms-2 questionList">
                                    <p>Questionnaire</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >
                            {currentSelectedUserData?.questionnaire?.length != 0 ? currentSelectedUserData?.questionnaire?.map((ques) => <Row key={ques.id} className="mb-2">
                              <Col span={1} className="">
                                <TbPointFilled size={28} color="#898686" />
                              </Col>
                              <Col span={20} className="flex flex-col">
                                <p>Question:  {ques?.question}</p>
                              </Col>
                              <Col span={3} className="flex justify-end items-center">
                                <p className={`text-white rounded-3xl px-4 py-1 m-0 text-sm  ${ques.status === 'Pass' ? 'bg-green-700' : 'bg-red-500'}`}>{ques.status}</p>
                              </Col>
                            </Row>) : <div className="flex justify-center"><b>No Data Found</b></div>}

                          </Panel>
                        </Collapse>
                      </div>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-md">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  <div className="ms-2 questionList">
                                    <p>Captured Screenshots</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >
                            {(signedData == '' && dataLoading) ?
                              <div className="flex border border-dashed border-2 border-slate-400 videoSection rounded-lg p-3">
                                <div className={`flex justify-center flex-col items-center rounded pb-4 ms-2 w-full`}>
                                  <label className={`text-lg font-semibold`}>Loading...</label>
                                </div>
                              </div>
                              :
                              <div className="border border-dashed border-2 border-slate-400 rounded-lg p-3">
                                <Row justify={signedData?.screenshotUrl?.length != 0 ? "space-between" : "center"}>
                                  {signedData?.screenshotUrl?.length != 0 ? signedData?.screenshotUrl?.map((item) => <Col span={12} className="p-2">
                                    <div className="h-full gutter-row  rounded-lg">
                                      <img src={item?.signedUrl} className="h-44" />
                                    </div>
                                  </Col>) : <div className="flex justify-center"><b>No Screenshot Found</b></div>}
                                  {/* <Col span={12} className="p-2">
                                <div className="h-44 gutter-row  border border-dashed border-2 border-slate-400 rounded-lg"></div>
                              </Col> */}
                                </Row>
                              </div>
                            }
                          </Panel>
                        </Collapse>
                      </div>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-md">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  <div className="ms-2 questionList">
                                    <p>Call Recording</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >{(signedData == '' && dataLoading) ?
                            <div className="flex border border-dashed border-2 border-slate-400 videoSection rounded-lg p-3">
                              <div className={`flex justify-center flex-col items-center border-2 rounded pb-4 w-1/2`}>
                                <label className={`text-lg font-semibold`}>Loading...</label>
                              </div>
                              <div className={`flex justify-center flex-col items-center border-2 rounded pb-4 ms-2 w-1/2`}>
                                <label className={`text-lg font-semibold`}>Loading...</label>
                              </div>
                            </div>
                            :
                            <div className="border border-dashed border-2 border-slate-400 videoSection rounded-lg p-3">
                              <Row justify="space-between">
                                <Col span={12} className="p-2">
                                  <div className="h-52 gutter-row rounded-lg">
                                    {/* <label className={`text-lg font-semibold ms-1 mb-1`}>Agent Video</label> */}
                                    {signedData?.mergedVideoUrl?.signedUrl ?
                                      <video controls className="h-full w-full gutter-row rounded-lg">
                                        <source
                                          src={signedData?.mergedVideoUrl?.signedUrl}
                                          type="video/webm"
                                        />
                                      </video>
                                      :
                                      <div className={`flex h-full justify-center flex-col items-center border-2 rounded pb-4`}>
                                        <div className={`videoNotAvailable`}>
                                          <Icon iconName="noVideoIcon" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                        </div>
                                        <label className={`text-lg font-semibold`}>No agent video</label>
                                      </div>
                                    }

                                  </div>
                                </Col>
                                {/* <Col span={12} className="p-2">
                                  <div className="h-44 gutter-row rounded-lg">
                                  <label className={`text-lg font-semibold ms-1 mb-1`}>Customer Video</label>
                                    {signedData?.customerVideoUrl?.signedUrl ?
                                      <video controls className="h-full w-full gutter-row  rounded-lg">
                                        <source
                                          src={signedData?.customerVideoUrl?.signedUrl}
                                          type="video/webm"
                                        />
                                      </video>
                                      :
                                      <div className={`flex h-full justify-center flex-col items-center border-2 rounded pb-4 ms-2`}>
                                        <div className={`videoNotAvailable`}>
                                          <Icon iconName="noVideoIcon" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                        </div>
                                        <label className={`text-lg font-semibold`}>No Customer video</label>
                                      </div>
                                    }
                                  </div>
                                </Col> */}
                              </Row>
                            </div>
                            }
                          </Panel>
                        </Collapse>
                      </div>
                      <div className="container mt-3 pt-2 border rounded-lg shadow-md">
                        <Collapse ghost>
                          <Panel
                            header={
                              <div className="panel-header">
                                <div className="panel-title">
                                  <div className="ms-2 questionList">
                                    <p>Geo Location</p>
                                  </div>
                                  <div className={`ms-2 fetchStatus `}>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            }
                            showArrow={false}
                          >{(signedData == '' && dataLoading) ?
                            <div className="flex border border-dashed border-2 border-slate-400 videoSection rounded-lg p-3">
                              <div className={`flex justify-center flex-col items-center border-2 rounded pb-4 w-1/2`}>
                                <label className={`text-lg font-semibold`}>Loading...</label>
                              </div>
                              <div className={`flex justify-center flex-col items-center border-2 rounded pb-4 ms-2 w-1/2`}>
                                <label className={`text-lg font-semibold`}>Loading...</label>
                              </div>
                            </div>
                            :
                            <div className="border border-dashed border-2 border-slate-400 rounded-lg p-3">
                              <Row justify="space-between">
                                {geoLocation?.place?.address ? geoLocation?.place?.address : 'No data available'}
                              </Row>
                            </div>
                            }
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div> 
                :
                  <div className="w-3/5 p-2 h-full flex justify-center items-center mt-5 pt-5 flex-col ...">
                    <div className="">
                      <img src="assets/images/userQcall.svg" />
                    </div>
                    <p className="p-5 center text-lg text-slate-600 text-center w-11/12">
                      Welcome! Select a completed call to view the details. Your Customer information will appear here once you start a call. Let’s get ready for a productive day!
                    </p>
                  </div>
                }

                {kyc_key != "detailPage" && <div className="w-2/5 p-2">
                  <div className="p-4 callInQueue ">
                    <p className="font-semibold text-2xl py-3">Completed Calls</p>
                    {/* <div className="flex mt-3">
                  <button
                    className={`queueBtn ${
                      activeOption === "activeCall" ? "activeBackground" : ""
                    }`}
                    name="activeCall"
                    onClick={activeCloseCall}
                  >
                    Call In Queue
                  </button>
                  <button
                    className={`ms-4 queueBtn ${
                      activeOption === "closedCall" ? "activeBackground" : ""
                    }`}
                    name="closedCall"
                    onClick={activeCloseCall}
                  >
                    Closed Call
                  </button>
                </div> */}
                  </div>

                  <div className="p-2 ">
                    {completedData?.length > 0 ? completedData?.map((item, index) => (
                      <div
                        className={`border rounded-lg bg-white mt-2 userInfoContainer ${index === currentSelectedUserIndex ? "activeBorder" : ""
                          }`}
                        key={index}
                        onClick={() => handleSelectedUser(item, index)}
                      >
                        <div className="flex p-3">
                          <p className="w-1/2 QueueUserName">{item?.first_name}{' '}{item?.last_name}</p>
                          <p className={`w-1/2 flex justify-end items-center QueueUserStatus ${item.status === 'Pending' ? 'pendingStatus' : item.status === 'Completed' ? 'text-green-600' : 'rejectedStatus text-red-600'}`}>
                            <p className={`border me-1 ${item.status === 'Pending' ? 'pendingStatusBullet' : item.status === 'Completed' ? 'completedStatusBullet text-green-600' : 'rejectedStatusBullet text-red-600'}`}></p>
                            {item?.status}
                          </p>
                        </div>
                        <div className="flex-col ps-3 pb-3 w-full">
                          <div className="w-full">
                            <p className="flex w-1/2 userPersonalInfo me-2 ">
                              <img
                                className="me-1"
                                src="assets/images/CalendarGoLive.svg"
                              />
                              {item?.DOB ? moment(item?.DOB).format(
                                "DD/MM/YYYY"
                              ) : '-'}
                            </p>
                            <p className="flex w-1/2 userPersonalInfo me-2">
                              <img
                                className="me-1"
                                src="assets/images/PhoneGoLive.svg"
                              />
                              {item?.verification_number}
                            </p>
                          </div>
                          <div>
                            <p className="flex w-full userPersonalInfo mailBreak">
                              <img
                                className="me-1"
                                src="assets/images/MailGoLive.svg"
                              />

                              {item?.email}

                            </p>
                          </div>
                        </div>
                      </div>
                    )) : <div className="flex justify-center items-center w-full h-44">
                      {!dataLoading && <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-headset-off ms-2" width="60" height="60" viewBox="0 0 24 24" stroke-width="1.5" stroke="#a3a3a3" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 14v-3c0 -1.953 .7 -3.742 1.862 -5.13m2.182 -1.825a8 8 0 0 1 11.956 6.955v3" />
                          <path d="M18 19c0 1.657 -2.686 3 -6 3" />
                          <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
                          <path d="M16.169 12.18c.253 -.115 .534 -.18 .831 -.18h1a2 2 0 0 1 2 2v2m-1.183 2.826c-.25 .112 -.526 .174 -.817 .174h-1a2 2 0 0 1 -2 -2v-2" />
                          <path d="M3 3l18 18" />
                        </svg>
                        No Call Yet!

                      </div>}
                    </div>}
                  </div>

                  {/* <div className="p-2">
                <div>
                  <p className="flex boldNotes mb-3">
                    <img
                      className="boldCalendar me-1"
                      src="assets/images/CalendarBold.svg"
                    />
                    Notes
                  </p>
                  <div className="text-area-container mt-1">
                    <textarea
                      className="custom-textarea"
                      placeholder="Write something here....."
                    ></textarea>
                  </div>
                  <button className="follow-up-button mt-3">Follow up</button>
                  <div className="mt-3">
                    <p className="flex boldNotes">
                      <img
                        className="boldCalendar me-1"
                        src="assets/images/strom.svg"
                      />
                      Actions
                    </p>
                    <button className="mail-button mt-3">
                      <img
                        src="assets/images/whiteMail.svg"
                        alt=""
                        className="mail-icon"
                      />
                      Mail
                    </button>
                  </div>
                </div>
              </div> */}
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

  export default WithAuth(CallInQueues);
