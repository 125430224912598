import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/userConfig.scss";
import "../../styles/reconcilition.scss";
import API from "../../helpers/api/index";
// import Icon from "../../component/core-component/Icon";
// import Pagination from "../../component/Pagination";
import Pagination from "../../common/PaginationComponent";
import Icon from "../../component/core-component/Icon";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { handleErrorMessage } from "../../common/commonFunction";
import moment from "moment";
import AuditTrailDownloadModal from "./AuditTrailDownloadModal";

const initialPaginationState = {
    activePage: 1,
    skip: 0,
    limitPerPage: 6,
    paginatedData: [],
    empData: [],
    list: [],
};

const initialFilterData = {
    startDate: "",
    endDate: "",
  }

function Audit() {
    const [formData, setFormData] = useState();
    const [tableDataLoading, setTableDataLoading] = useState();
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [filterData, setFilterData] = useState({ ...initialFilterData })
    const [pagination, setPagination] = useState(initialPaginationState);
    const { activePage, skip, limitPerPage, empData, list, paginatedData } = pagination;
    const isMobileDevice = window.screen.width <= 900;

    const getAuditDetails = async () => {
        try {
            setTableDataLoading(true);
            const response = await API.apiGet('/auditTrails');
            if (response?.data?.success) {
                setFormData(response.data.data);
                setTableDataLoading(false);
            } else {
                setTableDataLoading(false);
            }
        }
        catch (err) {
            setTableDataLoading(false);
            handleErrorMessage(err);
            // toast.error(err?.response?.data?.message)
        }
    }
    useEffect(() => {
        getAuditDetails();
    }, []);

    useEffect(() => {
        let page = 1
        let skipRecords = (page - 1) * limitPerPage;
        const to = limitPerPage * page;
        setPagination((prev) => ({ ...prev, activePage: 1, list: formData, paginatedData: formData?.slice(skipRecords, to) }))
    }, [formData?.length]);

    const onPageChange = (page) => {
        var skipRecords = (page - 1) * limitPerPage;
        const to = limitPerPage * page;
        setPagination((prev) => ({
            ...prev,
            activePage: page,
            skip: JSON.parse(skipRecords),
            paginatedData: formData?.slice(skipRecords, to),
            empData: formData?.slice(skipRecords, to),
        }));
    };

    function customDateTimeFormat(date) {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        const formattedDateTime = new Date(date).toLocaleString('en-GB', options);
        return formattedDateTime.replace(',', ' |');
    }

    const convertJsonToExcel = (auditTrailData) => {
        const jsonData = auditTrailData?.map(item => {
            item.created_at = moment(item.created_at).format('YYYY-MM-DD, HH:mm')
            item.updated_at = moment(item.updated_at).format('YYYY-MM-DD, HH:mm')
            return item;
        });
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(excelBlob, 'audit.csv');
    };

    const handleAuditData = (item) => {
        const width = window.screen.width;
        if(width <= 400) {
            if(item?.length >= 10) return item?.substring(0,10) + '...'
            else return item;
        }
        else if(width <= 600) {
            if(item?.length >= 18) return item?.substring(0,18) + '...'
            else return item;
        }
        else return item
    };
    
    const handleFilter = async () => {
        try {
          const payload = {
            startDate: filterData.startDate ? moment(filterData.startDate.$d).format('YYYY-MM-DD') : '',
            endDate: filterData.endDate ? moment(filterData.endDate.$d).format('YYYY-MM-DD') : '',
            clientTimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
          }
          const response = await API.apiGet(`/auditTrails/download`, '', payload)
          if (response?.data.success === true) {
            await convertJsonToExcel(response.data.data)
            toast.success("File downloaded successfully")
            setShowDownloadModal(false)
            setFilterData(initialFilterData);
          }
          else {
            toast.error(response?.data?.error || "Both startDate and endDate are required.")
          }
    
        }
        catch (error) {
          handleErrorMessage(error);
          toast.error(error?.response?.data?.error)
        }
      }

      const closeModal = () => {
        setShowDownloadModal(false);
        setFilterData(initialFilterData);
      }

    return (
        <>
            <div className={`flex dark:bg-dark-background`}>
                <SideMenu headerName="Audit Trail"/>
                <div className={`mainBodyContainer flex-1 userManagement ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    {showDownloadModal && <AuditTrailDownloadModal showModal={showDownloadModal} closeModal={closeModal} handleFilter={handleFilter} setFilterData={setFilterData} filterData={filterData} />}
                    <div className={`tableBackground heightTable mainContainer ${!isMobileDevice && 'fixedHeightContainer'}`}>
                        <div >
                            {/* <div className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 px-5 py-5">Audit Trail</div> */}
                            <div className={`UserFormContainer min-h-full heightTable ${isMobileDevice && 'auditTableResponsive_container'}`}>
                                <div className={`flex pb-5 items-center ${isMobileDevice ? 'justify-end' : 'justify-between'}`}>
                                    {!isMobileDevice && <div className="font-bold">Audit Trail</div>}
                                    <button id="download_audit_trail" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center commonBtn custom_btn_primary" onClick={() => setShowDownloadModal(true)}>
                                        <span className="pe-2 ms-2">Download Audit Trail</span>
                                        <Icon iconName="Download" width={16} height={16} />
                                    </button>
                                </div>

                                {!isMobileDevice ? (
                                    <div className="reconcilitionTable-container reconcilitaion-main-container">
                                    {paginatedData?.length > 0 ?
                                        <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable bg-white boxShadow">
                                            <thead>
                                                <tr className="headBg">
                                                    <th className="py-4 px-4 border-b text-left ">
                                                        Email Id
                                                    </th>
                                                    <th className="py-4 px-4 border-b text-left ">Action</th>
                                                    <th className="py-4 px-4 border-b text-left">
                                                        Date and Time
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData?.map((item, index) => (
                                                    <tr key={index} >
                                                        <td className="py-4 px-4 border-b text-blue-500">
                                                            {item?.email}
                                                        </td>
                                                        <td className="py-4 px-4 border-b">{item?.message}</td>
                                                        <td className="py-4 px-4 border-b">{item?.created_at ? moment(item?.created_at).format('YYYY-MM-DD | HH:mm') : '-'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                            <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable boxShadow">
                                                <thead>
                                                    <tr className="headBg">
                                                        <th className="py-4 px-4 border-b text-left ">
                                                            Email Id
                                                        </th>
                                                        <th className="py-4 px-4 border-b text-left ">Action</th>
                                                        <th className="py-4 px-4 border-b text-left">
                                                            Date and Time
                                                        </th>

                                                    </tr>
                                                </thead>
                                            </table>
                                        {tableDataLoading ? 
                                            <div className="bg-white flex items-center justify-center rounded-b-md" >
                                                <ColorRing
                                                visible={true}
                                                height="80"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                                />
                                            </div>
                                            :
                                            <div className="bg-white flex justify-center py-5 rounded-b-md font-semibold">
                                                No data found
                                            </div>
                                            }
                                        </>
                                    }
                                    </div>
                                ) : (
                                    <div>
                                        {paginatedData?.map((item, index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Email Id</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5 break-all text-blue-500">{handleAuditData(item?.email)}</span> 
                                                        <Popup trigger={
                                                            <span className="w-3">
                                                                <img src="/assets/images/tooltip-info.png" alt="info"/>
                                                            </span>
                                                            }
                                                            className="textContainer"
                                                            position="top right">
                                                            <div className="break-all">{item?.email}</div>
                                                        </Popup>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Action</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5 break-all">{handleAuditData(item?.message)}</span> 
                                                        <Popup trigger={
                                                            <span className="w-3">
                                                                <img src="/assets/images/tooltip-info.png" alt="info"/>
                                                            </span>
                                                            }
                                                            className="textContainer"
                                                            position="top right">
                                                            <div className="break-all">{item?.message}</div>
                                                        </Popup>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Date and Time</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5 break-all">{handleAuditData(item?.created_at ? moment(item?.created_at).format('YYYY-MM-DD | HH:MM') : '-')}</span> 
                                                        <Popup trigger={
                                                            <span className="w-3">
                                                                <img src="/assets/images/tooltip-info.png" alt="info"/>
                                                            </span>
                                                            }
                                                            className="textContainer"
                                                            position="top right">
                                                            <div className="break-all">{item?.created_at ? moment(item?.created_at).format('YYYY-MM-DD | HH:MM') : '-'}</div>
                                                        </Popup>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )} 
                                {!tableDataLoading && paginatedData?.length > 0 &&
                                    <div className="flex justify-end pt-4">
                                        <Pagination
                                            currentPage={activePage}
                                            list={list}
                                            skip={skip}
                                            limitPerPage={limitPerPage}
                                            onPageChange={onPageChange}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default WithAuth(Audit);
