import {
    Image,
} from "../../component/core-component";
import React, { useState, useEffect } from "react";
import API from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { backgroundImg } from "../../assets/Icons";
import { ColorRing } from 'react-loader-spinner';
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import styles from "../../styles/verifyEmail.module.scss";

const VerifyEmail = () => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleChange = (e) => {
        let {value} = e?.target;
        setData(value);
    }
    const handleResetPassword = async() => {
        let payload = {
            email: data
        }
        const encodedPayload = encodeData(payload);
        setLoading(true);
        try{
            const response = await API.apiPost(`/users/forget/password`,{payload: encodedPayload});
            if(response?.status == 200) {
                navigate(`/checkEmail?query=${data}`);
                setLoading(false);
            }
        } catch(err) {
            handleErrorMessage(err);
            setLoading(false);
        }
    }
    // console.log("document",document);
    // let encodedSvg = encodeURIComponent(backgroundImg);
    // let svgBackground = `url('data:image/svg+xml;utf8,${encodedSvg}')`;
    // document.getElementById("background").style.backgroundColor = "red";
return(
    <>
    <div className="flex justify-center items-center h-full px-5 backImage" style={{height:"100vh"}} id="background">
        <div className={`px-5 w-2/6 ${styles.verifyEmailContainer}`}>
            <div className="flex justify-center pb-6">
        <Image
            source={"/assets/images/logo.svg"}
            height={70}
            width={70}
            alt="Logo"
          />
          </div> 
          <div className={`${styles.verifyEmailTextContainer}`}>
            <h1 className="font-bold text-3xl text-center sm:pt-6 pt-2">Verify Email</h1>
            <div className="pt-4 ">Enter the Email address associated with your account and we'll send you a link to reset password.</div>
        <div className="py-4">
            <label>Email</label>
            <input 
            className="mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md" 
            onChange={handleChange}
            name="email"
            value={data}
            placeholder="Enter email"/>
        </div>
        <div className="text-center font-bold flex justify-center">
            <button className="btn relative flex items-center justify-center w-40 h-12 bg-blue-500 text-white font-bold py-2 px-4 rounded" type="submit" onClick={handleResetPassword}>
            {
                !loading ? `Continue` : (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center" style={{background: "#ffffff9c"}} >Continue
                <ColorRing
                 visible={true}
                 height="40"
                 width="40"
                 ariaLabel="color-ring-loading"
                 wrapperStyle={{ position: 'absolute', inset: "3px 115px" }}
                 wrapperClass="color-ring-wrapper"
                 colors={['#ffffff','#ffffff', '#ffffff', '#ffffff','#ffffff']}
                />
                </div>
                )
            }
            {/* <span className={loading ? "opacity-0" : "opacity-100"}>
                Continue
            </span> */}
        </button></div>
        </div>

    </div>
    </div>
    <ToastContainer></ToastContainer>
    </>
)
}

export default VerifyEmail;