import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

.uploadLogoBox {
  border-color: ${({ theme }) => theme?.color_config?.main_color} !important;
}

.commonCard {
  background: ${({ theme }) => theme?.color_config?.card_body_bg} !important;
  box-shadow: 6px 6px 7px #e7e3e3 !important;
}

.commonCardHeader {
  background: ${({ theme }) => theme?.color_config?.card_header_bg} !important;
  mix-blend-mode: multiply !important;
}
  
.commonTableHeader {
  background: ${({ theme }) => theme?.color_config?.table_header_footer} !important;
  color: ${({ theme }) => theme?.color_config?.table_header_text_color} !important;
}

.commonCardLeftBorder {
  border-left-color: ${({ theme }) => theme?.color_config?.main_color} !important;
}

.commmonActiveTag {
  color: ${({ theme }) => theme?.color_config?.main_color} !important;
  border-color: ${({ theme }) => theme?.color_config?.main_color} !important;
}

  th {
    background: ${({ theme }) => theme?.color_config?.table_header_footer}!important;
    color: ${({ theme }) => theme?.color_config?.table_header_text_color}!important;
  }

  td {
    background: ${({ theme }) => theme?.color_config?.table_body} !important;
    color: ${({ theme }) => theme?.color_config?.table_body_text_color} !important;
  }

  .custom_btn_primary, .ant-btn-primary, .ant-btn-primary:hover {
    background: ${({ theme }) => theme?.color_config?.primary_button_bg} !important;
    color: ${({ theme }) => theme?.color_config?.primary_button_text} !important;
    border-color: ${({ theme }) => theme?.color_config?.primary_button_text} !important;
  }

  .custom_btn_primary:hover {
    background: ${({ theme }) => theme?.color_config?.primary_button_bg} !important;
    color: ${({ theme }) => theme?.color_config?.primary_button_text} !important;
    border-color: ${({ theme }) => theme?.color_config?.primary_button_text} !important;
  }  

  .custom_btn_secondary, .ant-btn-default, .ant-btn-default:hover {
    background: ${({ theme }) => theme?.color_config?.secondary_button_bg} !important;
    color: ${({ theme }) => theme?.color_config?.secondary_button_text} !important;
    border-color: ${({ theme }) => theme?.color_config?.secondary_button_text} !important;
  }

  .custom_btn_secondary:hover {
    background: ${({ theme }) => theme?.color_config?.secondary_button_bg} !important;
    color: ${({ theme }) => theme?.color_config?.secondary_button_text} !important;
    border-color: ${({ theme }) => theme?.color_config?.secondary_button_text} !important;
  }  

  .sectionSelection {
    background-color: ${({ theme }) => theme?.color_config?.module_selection} !important;
    color:${({ theme }) => theme?.color_config?.module_selection_text} !important;
  }

  .sidebar_bg {
    background-color: ${({ theme }) => theme?.color_config?.sidebar_module} !important;
    color: ${({ theme }) => theme?.color_config?.text_menu_icon} !important;
  }

  .commonCardIcons {
    background-color: ${({ theme }) => theme?.color_config?.main_color} !important;
  }

  .tableIcons {
    color: ${({ theme }) => theme?.color_config?.main_color} !important;
  }

  .logoParentdiv .ant-card .ant-card-head{
    background-color: ${({ theme }) => theme?.color_config?.main_color} !important;
    color: white;
  }

  .mainBodyContainer {
    background-color: ${({ theme }) => theme?.color_config?.page_background} !important;
  }

  .uploadChildBox{
    border-color:${({ theme }) => theme?.color_config?.main_color} !important;
  }

  .commonCardName {
    color: ${({ theme }) => theme?.color_config?.main_color} !important;
  }

  .commonCardCards {
    border-left:4px solid ${({ theme }) => theme?.color_config?.main_color} !important;
  }
`;