import React, { useEffect, useState } from 'react';
import Pagination from "../../component/Pagination";
import "../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';
import Icon from '../../component/core-component/Icon';
import { useNavigate } from 'react-router-dom';

export default function UpdatePhoneNumberTable(props) {
    const { 
        columns, 
        rowData, 
        tableLoading, 
        nPages, 
        handlePageChange, 
        currentPage, 
        recordsPerPage,
        handleResendLink,
        responseScreenWidth
    } = props
    
  const [currentRecords, setCurrentRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      if (rowData && rowData.length > 0) {
          setCurrentRecords(rowData);
      } else {
          setCurrentRecords([]);
      }
  }, [rowData]);

  function openViewRequest(currentRecord) {
    let kyc_id = currentRecord?.id
    navigate(`/viewRequest?kyc_id=${kyc_id}`)
  }

  const getStatusColor = (item) => {
    switch (item?.KYC_status) {
        case 'Pending':
            return 'tabletDebitInProgress';
        case 'Approved':
            return 'tabletCredit';
        case 'Rejected':
            return 'tabletDebit';
        default:
            break;
    }
  }

  const getStatusDot = (item) => {
    switch (item?.KYC_status) {
        case 'Pending':
            return 'progress-dot';
        case 'Approved':
            return 'green-dot';
        case 'Rejected':
            return 'red-dot'
        default:
            break;
    }
  }

  const getKYCStatus = (item) => {
    switch (item?.KYC_status) {
        case 'Pending':
            return 'In Progress';
        case 'Approved':
            return 'Approved';
        case 'Rejected':
            return 'Rejected'
        default:
            break;
    }
  } 

  return (
    responseScreenWidth ?
     <div className={`${'reconcilitaion-main-container mt-5'}`}>
      <div className="reconcilitionTable-container">
        {!tableLoading && currentRecords?.length > 0 ?
            <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
            <thead>
                <tr className="ps-2 pe-2">
                {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                    {item?.title}
                    </th>
                )}
                </tr>
            </thead>
            <tbody>
                {currentRecords?.map((item, index) =>
                <tr key={`${item}_${index}`}>
                {columns.map((column, colIdx) => 
                    (column?.key === "resend_link" && item?.['KYC_status'] === 'Pending') ? 
                        <td className="py-2 ps-4 text-center flex w-full" title={item?.[column?.key]} style={{ fontSize: '16px' }}>
                            <button className={`w-full flex justify-center items-center gap-2 text-blue-500`} onClick={() => handleResendLink(item)}>
                                Resend
                                <img src='/assets/images/resend.svg' />
                            </button>
                        </td>
                    :
                    (column?.key === 'KYC_status') 
                    ? 
                    <td key={`col_${index}${colIdx}`} className="py-2 ps-4" style={{ fontSize: '16px' }}>
                        {item?.[column?.key] ? 
                            <div className={`px-1 flex h-1/2 rounded-full ${getStatusColor(item)}`}>
                                <span className={`${getStatusDot(item)}`} />
                                <span>{getKYCStatus(item)}</span>
                            </div>
                            :
                            '--'
                        }
                    </td>
                    :
                        (column?.key === "actions") ? <td className="py-2 ps-4 text-center" title={item?.[column?.key]} style={{ fontSize: '16px' }}><button onClick={() => openViewRequest(item)}><Icon iconName="EyeAlt" width={16} height={16} /></button></td>
                    :
                        <td title={item?.[column?.key] ? item?.[column?.key] : ''}>
                            {item?.[column?.key] ? item?.[column?.key]?.length > 30 ? `${item?.[column?.key].substring(0, 30)}...` : item?.[column?.key] : '--'}
                        </td>
                )}
                </tr>
                )}
            </tbody>
            </table>
            :
            <>
                <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                <tr className="ps-2 pe-2">
                    {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                        {item?.title}
                    </th>
                    )}
                </tr>
                </thead>
            </table>
            {tableLoading ? 
            <div className="w-full h-full flex items-center justify-center" >
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
            </div>
            :
                <div className='flex justify-center py-5 font-semibold'>
                    No data found
                </div>
            }
            </>
        }
      </div>
        {(!tableLoading && currentRecords?.length > 0) &&
            <Pagination 
                nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            ></Pagination>
        }
      </div>
      :
      <div className={`${'mt-5 reconcilitaion-main-container'}`}>
      <div className="reconcilitionTable-container">
        {!tableLoading && currentRecords?.length > 0 ?
            currentRecords?.map((item,index)=>
                <table className="min-w-full mt-2 rounded-lg border border-solid border-gray-border text-left bg-white mt-5">
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                              Account Number
                            </th>
                            <td>
                            {item?.account_no || '--'}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               Full Name
                            </th>
                            <td>
                            {item?.full_name || '--'}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               Old Number
                            </th>
                            <td>
                            {item?. old_number || '--'}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               New Number
                            </th>
                            <td>
                            {item?.new_number || '--'}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               KYC Video
                            </th>
                            <td>
                            {item?.kyc_video}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               Requestor
                            </th>
                            <td>
                            {item?.requestor}
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               Status
                            </th>
                            <td  className="py-2 ps-4" style={{ fontSize: '16px' }}>
                                {item?.KYC_status ? 
                                    <div className={`px-1 flex h-1/2 rounded-full ${getStatusColor(item)}`}>
                                        <span className={`${getStatusDot(item)}`} />
                                        <span>{getKYCStatus(item)}</span>
                                    </div>
                                    :
                                    '--'
                                }
                            </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                               Resend Link
                            </th>
                            <td className="py-2 ps-4 text-center flex w-full" style={{ fontSize: '16px' }}>
                            <button className={`w-full flex justify-center items-center gap-2 text-blue-500`} onClick={() => handleResendLink(item)}>
                                Resend
                                <img src='/assets/images/resend.svg' />
                            </button>
                        </td>
                        </tr>
                        <tr className="ps-2 pe-2 border-b">
                            <th className="py-2 ps-4 text-center text-sky-700" colSpan="2"  style={{ fontSize: '16px' }}><button onClick={() => openViewRequest(item)}>View Details</button></th>
                        </tr>
                </table>
            )
            :
            <>
            {tableLoading ? 
            <div className="w-full h-full flex items-center justify-center" >
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
            </div>
            :   
            <div className="noDataFound">
                <img
                    src='/assets/noData.svg'
                    alt="no-data"
                    className={`noDataImage noDataIcon'}`}
                />
                <div className="text-xl font-semibold">
                    No data found
                </div>
            </div>
            }
            </>
        }
      </div>
        {(!tableLoading && currentRecords?.length > 0) &&
            <Pagination 
                nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            ></Pagination>
        }
      </div>
  )
}