import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Pagination from "../../component/Pagination";
import "../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';

export default function DataTable({ columns, rowData, tableLoading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = rowData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(rowData?.length / recordsPerPage);
  const isMobileDevice = window.screen.width <= 900;

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage)
  }

  const getStatusKeyColor = (value) => {
    let statusColorClass = ''
    if (value === 'Active') {
        statusColorClass = 'greenStatusColor'
    } else if (value === 'InActive') {
        statusColorClass = 'redStatusColor'
    }
    return statusColorClass;
  }

  const handleVaultData = (item) => {
    const width = window.screen.width;
    if(width <= 400) {
      if(item?.length >= 12) return item?.substring(0,12) + '...'
      else return item;
    }
    else if(width <= 600) {
      if(item?.length >= 15) return item?.substring(0,15) + '...'
      else return item;
    }
    else if(width <= 800) {
      if(item?.length >= 22) return item?.substring(0,22) + '...'
      else return item;
    }
    else return item;
  };

  return (
    <div className={`${'reconcilitaion-main-container mt-5'} ${isMobileDevice && 'newKeyPassDetailsContainer_responsive'}`}>
      <div className={`reconcilitionTable-container ${isMobileDevice && 'responsiveReconciliationTable'}`}>

        {!isMobileDevice ? (
          currentRecords?.length > 0 ?
          <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
            <thead>
              <tr className="ps-2 pe-2">
                {columns?.map((item, indx) =>
                  <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                    {item?.title}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) =>
                <tr key={`${item}_${index}`}>
                  {columns.map((column, colIdx) => 
                      <td key={`col_${index}${colIdx}`} className={`py-2 ps-4`} style={{ fontSize: '16px' }}>
                          {['created_at', 'updated_at']?.includes(column?.key)
                          ?
                          item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD | HH:mm:ss') : '--'
                          : 
                          (column?.key == 'status') 
                          ?
                          <div className={`rounded-full ${getStatusKeyColor(item?.[column?.key])}`}>
                              <span className={`${getStatusKeyColor(item?.[column?.key]) === 'greenStatusColor' ? 'green-dot' : 'red-dot'}`}></span>
                              {item?.[column?.key] ? item?.[column?.key] : '--'}
                          </div>
                          :
                          item?.[column?.key] ? item?.[column?.key] : '--'
                          }
                      </td>  
                  )}
                </tr>
              )}
            </tbody>
          </table>
          :
          <>
          <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
            <thead>
              <tr className="ps-2 pe-2">
                {columns?.map((item, indx) =>
                  <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                    {item?.title}
                  </th>
                )}
              </tr>
            </thead>
          </table>
          {tableLoading ? 
            <div className="z-10 flex items-center justify-center" > 
              <ColorRing
              visible={true}
              height="80"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={[]}
              />
            </div>
            :
            <div className='flex justify-center py-5 font-semibold'>
              No data found
            </div>
          }
          </>
          
        ) : (
          <div>
            {currentRecords?.length > 0 
            ? 
              currentRecords?.map((item, index) => (
              <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Name</span>
                  <div className="flex items-center justify-between">
                    <span className="mr-5">{handleVaultData(item?.name)}</span> 
                    <Popup trigger={
                        <span className="w-3">
                          <img src="/assets/images/tooltip-info.png" alt="info"/>
                        </span>
                      }
                      className="textContainer"
                      position="top right">
                      <div className='break-all'>{item?.name}</div>
                    </Popup>
                  </div>
                </div>
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Username</span>
                  <div className="flex items-center justify-between">
                    <span className="mr-5">{handleVaultData(item?.userID)}</span> 
                    <Popup trigger={
                        <span className="w-3">
                          <img src="/assets/images/tooltip-info.png" alt="info"/>
                        </span>
                      }
                      className="textContainer"
                      position="top right">
                      <div className='break-all'>{item?.userID}</div>
                    </Popup>
                  </div>
                </div>
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Submitted At</span>
                  <div className="flex items-center justify-between">
                    <span className="mr-5">{handleVaultData(item?.created_at)}</span> 
                    <Popup trigger={
                        <span className="w-3">
                          <img src="/assets/images/tooltip-info.png" alt="info"/>
                        </span>
                      }
                      className="textContainer"
                      position="top right">
                      <div className='break-all'>{item?.created_at}</div>
                    </Popup>
                  </div>
                </div>
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Updated On</span>
                  <div className="flex items-center justify-between">
                    <span className="mr-5">{handleVaultData(item?.updated_at)}</span> 
                    <Popup trigger={
                        <span className="w-3">
                          <img src="/assets/images/tooltip-info.png" alt="info"/>
                        </span>
                      }
                      className="textContainer"
                      position="top right">
                      <div className='break-all'>{item?.updated_at}</div>
                    </Popup>
                  </div>
                </div>
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Expiry Date</span>
                  <div className="flex items-center justify-between">
                    <span className="mr-5">{item?.validity_period ? handleVaultData(item?.validity_period) : '--'}</span> 
                    {item?.validity_period && <Popup trigger={
                        <span className="w-3">
                          <img src="/assets/images/tooltip-info.png" alt="info"/>
                        </span>
                      }
                      className="textContainer"
                      position="top right">
                      <div className='break-all'>{item?.validity_period}</div>
                    </Popup>
                    }
                  </div>
                </div>
                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                  <span className="font-bold">Status</span>
                  <div className="flex items-center justify-between">
                    <div className={`rounded-full ${getStatusKeyColor(item?.status)}`}>
                      <span className={`${getStatusKeyColor(item?.status) === 'greenStatusColor' ? 'green-dot' : 'red-dot'}`}></span>
                      {item?.status ? item?.status : '--'}
                    </div>
                  </div>
                </div>
              </div>
            ))
            :
            tableLoading ? 
              <div className="z-10 flex items-center justify-center" > 
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
              </div>
              :
              <div className='flex justify-center py-5 font-semibold'>
                No data found
              </div>
            }
          </div>
        )}
      </div>
      {!tableLoading && currentRecords?.length > 0 &&
      <Pagination nPages={nPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      ></Pagination>
      }
    </div>
  )
}