// Breadcrumb.js
import React from "react";
import Icon from "../../component/core-component/Icon";
import { useNavigate } from "react-router";
import { useState} from "react";
import { toast} from "react-toastify";
import "../../styles/infoChangeRequest.scss"

const UpdateSignatureCardModal = ({ setSignatureData , signatureData}) => {
    const [signatureCardLoading, setSignatureCardLoading] = useState('noprogress');
    // const [signatureData, setSignatureData] = useState(0);
    const [isSignatureLoading, setIsSignatureLoading] = useState(false);
    const isMobileDevice = window.screen.width <= 900;
    const handleUpdateSignatureCard = (event, isDragged) => {
        setSignatureCardLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setSignatureCardLoading('uploading');
            setTimeout(() => {
                setSignatureCardLoading('uploaded');
                setSignatureData({ ...signatureData, pdf });
            }, 3000)
        } else {
            event.target.value = ''
            setSignatureData({ ...signatureData, pdf: '' });
            toast.error("Please select an image.");
        }
    };

    const signatureDropHandler = (e) => {
                e.preventDefault();
        e.stopPropagation();
        handleUpdateSignatureCard(e, true)
    }
    const signatureDragOverHandler = (e) => {
                e.preventDefault();
        e.stopPropagation();
    }

    const handleClearSignature = (filetype) => {
        setSignatureData({ ...signatureData, [filetype]: '' })
        setSignatureCardLoading('noprogress')
    }

    return (
        <div className="mt-5">
            <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2  ${isMobileDevice ? '': 'uploadCard'}`}>
                <span className="text-2xl font-semibold mb-4 text-center w-full block">
                    {signatureCardLoading === 'noprogress' && 'Upload New Signature'}
                    {signatureCardLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                    {signatureCardLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Signature...</span>}
                    {signatureCardLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Signature Card Uploaded</span>}
                </span>

                <div className="w-60 flex justify-center" onDrop={signatureDropHandler} onDragOver={signatureDragOverHandler}>
                    <span style={{ width: '45px' }} className={!signatureData?.pdf?.name ? 'un_selected' : ''} >
                        <img src="assets/images/pdf.svg"></img>
                    </span>
                </div>

                {signatureData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={signatureData?.pdf?.name} >{signatureData?.pdf?.name?.length > 14 ? signatureData?.pdf?.name?.substring(0, 14) + "..." : signatureData?.pdf?.name}{!isSignatureLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearSignature('pdf')} />}</span>}
                {signatureCardLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                {signatureCardLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                    {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                </p>
                    <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpdateSignatureCard}
                            className="mb-4"
                            hidden
                            multiple
                        />
                        Upload
                        <img src="assets/images/icon_upload.svg" />
                    </label>
                </>
                }
                {signatureCardLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                    <div
                        className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                    ></div>
                </div>}
            </div>
        </div>
    );
};

export default UpdateSignatureCardModal;
