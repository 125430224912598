import React, { useEffect, useState } from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import { Collapse } from "antd";
import styles from '../../../styles/loanConfig.module.scss';
import Icon from "../../../component/core-component/Icon";
import { useNavigate } from "react-router-dom";
import { encodeData, handleErrorMessage } from "../../../common/commonFunction";
import { toast, ToastContainer } from "react-toastify";
import API from "../../../helpers/api";
import { ColorRing } from "react-loader-spinner";
const { Panel } = Collapse;

const ConfigInputsFieldsComponent = () => {
  const navigate = useNavigate()
  const isMobileDevice = window.screen.width <= 900;
  const [selectedLoanType, setSelectedLoanType] = useState();
  const [planDataLoading, setPlanDataLoading] = useState(false);
  const [planDataSaveLoading, setPlanDataSaveLoading] = useState(false);
  const [allFieldDisabled, setAllFieldDisabled] = useState(false);

  const getCurrentLoanData = async (currentLoanId, currentPage, loanStatus) => {
    try {
      setPlanDataLoading(true);
      if (currentPage == 'create') {
        const { data } = await API.apiGet(`loan/type/details?loanType_id=${currentLoanId}`);
        if (data.success) {
          let currentLoanData = data.data;
          currentLoanData?.details?.forEach((section) => {
            section.select_all = false;
            section?.data?.map(field => {
              field.used_in_form = false;
              field.required = false;
            });
          });
          setSelectedLoanType(currentLoanData);
          setPlanDataLoading(false);
        }
      } else if (currentPage == 'edit' || currentPage == 'view') {
        const { data } = await API.apiGet(`plan/details?loan_type_id=${currentLoanId}&status=${loanStatus}`);
        let currentLoanData = data.data;
        setSelectedLoanType(currentLoanData);
        setPlanDataLoading(false);
        if (currentPage == 'view') {
          setAllFieldDisabled(true);
        }
      }
    } catch (error) {
      console.log('error', error)
      setPlanDataLoading(false);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let currentLoanId;
    let loanStatus;
    let currentPage = params.get('page');
    if (currentPage == 'create') {
      currentLoanId = params.get('loan_id');
    } else if (currentPage == 'edit' || currentPage == 'view') {
      currentLoanId = params.get('loan_type_id');
      loanStatus = params.get('status');
    }
    getCurrentLoanData(currentLoanId, currentPage, loanStatus);
  }, []);

  const handleInputChecked = (event, section, field) => {
    const { checked, name } = event.target;
    let currentLoanData = { ...selectedLoanType }
    let finalSectionData = section?.data?.map(item => {
      if (item?.variable == field?.variable) {
        item.used_in_form = checked;
        item.required = checked;
      }
      return item;
    })
    currentLoanData?.details?.map(sectionData => {
      if (sectionData?.section_name == section?.section_name) {
        sectionData.data = finalSectionData
      }
      return sectionData;
    })
    setSelectedLoanType(currentLoanData);
  }

  const handleBack = () => {
    navigate('/loanConfig');
  }

  const handleInputData = (event) => {
    const { value, name } = event.target;
    setSelectedLoanType((prev) => ({
      ...prev,
      [name]: value
    }));
  }

  const handleSelectAll = (event, section) => {
    const { checked, name } = event.target;
    let currentLoanData = { ...selectedLoanType }
    currentLoanData?.details?.map(sectionData => {
      if (sectionData?.section_name == section?.section_name) {
        sectionData.select_all = checked
        sectionData?.data?.map(field => {
          field.used_in_form = checked
          field.required = checked
          return field;
        });
      }
      return sectionData;
    })
    setSelectedLoanType(currentLoanData);
  }

  const getExtraField = (section) => (
    <div className="flex justify-center items-center" onClick={(e) => e.stopPropagation()}>
      <input disabled={allFieldDisabled} onChange={(e) => handleSelectAll(e, section)} type="checkbox" id={`checkbox-${section?.section_name}`} className="cursor-pointer" checked={section?.select_all} />
      <label className="font-bold bg-gray rounded px-2 cursor-pointer" htmlFor={`checkbox-${section?.section_name}`} >Select all</label>
    </div>
  )

  const handleSaveLoan = async (status) => {
    try {
      setPlanDataSaveLoading(true);
      const params = new URLSearchParams(window.location.search);
      selectedLoanType.loan_type_id = params.get('loan_id') || params.get('loan_type_id');
      selectedLoanType.plan_id = params.get('plan_id');
      selectedLoanType.status = status
      const encodedPayload = encodeData(selectedLoanType)
      const { data } = await API.apiPost(`plan`, { payload: encodedPayload })
      if (data.success) {
        setPlanDataSaveLoading(false);
        toast.success('Loan saved in draft successfully');
        navigate('/loanConfig');
      }
    } catch (error) {
      handleErrorMessage(error);
      console.log('error', error);
      setPlanDataSaveLoading(false);
    }
  }

  return (
    <>
      {(planDataLoading || planDataSaveLoading) &&
        <div className={`${styles.loaderSpinner}`}>
          <ColorRing
            visible={true}
            height="80"
            width="60"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={[]}
          />
        </div>
      }
      <div className={`flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part mainBodyContainer ${(planDataLoading || planDataSaveLoading) ? styles.disbaledComponent : ''}`}>
        <SideMenu headerName="Loan Configuration" />
        <div className={`flex-1 ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
          <Header />
          <div className={`z-0 p-10 relative mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
            <div className={`${styles.loanDetails} bg-white rounded px-10 h-20 grid grid-cols-3`}>
              <div className="flex items-center py-3 pe-10 border-e-2">
                <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center" onClick={() => handleBack()}>
                  <Icon iconName="IconBackBtn" width={16} height={16} />
                </button>
                <label className="font-bold mx-4">Loan Type:</label>
                {selectedLoanType?.plan_name ? selectedLoanType?.plan_name : selectedLoanType?.LoanType?.plan_name}
              </div>
              <div className="flex pe-10 border-e-2 py-3 items-center justify-center">
                <label className="font-bold ms-2">Loan Version:</label>
                <input
                  name='version'
                  type="number"
                  value={selectedLoanType?.version}
                  className="border-2 ms-2 rounded px-2 py-1"
                  onChange={(e) => handleInputData(e)}
                  disabled={allFieldDisabled}
                />
              </div>
              <div className="flex items-center justify-center">
                <label className="font-bold">Rate of interest(%):</label>
                <input
                  name='rate_of_interest'
                  type="number"
                  value={selectedLoanType?.rate_of_interest}
                  className="border-2 w-1/2 ms-2 rounded px-2 py-1"
                  onChange={(e) => handleInputData(e)}
                  disabled={allFieldDisabled}
                />
              </div>
            </div>
            {selectedLoanType?.details?.map((item, index) => (
              <div className="rounded-3 my-2">
                <Collapse className="bg-white">
                  <Panel
                    className="py-3 px-2"
                    header={item?.section_name}
                    key={`panel-${index}`}
                    showArrow={true}
                    extra={getExtraField(item)}
                  >
                    <div className="grid grid-cols-4">
                      {item?.data?.map((field, fldIndx) => (
                        <div key={fldIndx} className="py-3">
                          <input disabled={allFieldDisabled} onChange={(e) => handleInputChecked(e, item, field)} name={field?.variable} checked={field?.used_in_form} type="checkbox" id={`checkbox-${index}-${fldIndx}`} className="cursor-pointer ms-2" />
                          <label className="ms-2 cursor-pointer" htmlFor={`checkbox-${index}-${fldIndx}`}>{field?.label}</label>
                        </div>
                      ))}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            ))}
            {!allFieldDisabled &&
              <div className="float-end mt-10">
                <button className="custom_btn_secondary border rounded px-4 py-2 me-3 commonBtn" onClick={() => handleSaveLoan('INACTIVE')}>Save as draft</button>
                <button className="custom_btn_primary border rounded px-4 py-2 commonBtn" onClick={() => handleSaveLoan('ACTIVE')}>Publish</button>
              </div>
            }
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default ConfigInputsFieldsComponent;