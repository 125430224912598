import React from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import Breadcrumb from "../../../component/Breadcrumb";
import { useState, useEffect } from "react";
import API from "../../../helpers/api/index";
import { toast } from "react-toastify";
import Pagination from "../../../component/Pagination";
import { handleErrorMessage } from "../../../common/commonFunction";
import { WithAuth } from "../../../common/WithAuth";

const IVRCallingList = () => {
  const [ivrConfigCalling, setIvrConfigCalling] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initialDetails, setInitialDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = ivrConfigCalling?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = ivrConfigCalling?.length ? Math.ceil(ivrConfigCalling?.length / recordsPerPage) : 0;

  useEffect(() => {
    getIvrConfigCallList();
  }, []);
  const getIvrConfigCallList = async () => {
    try {
      const { data } = await API.apiGet(`/v1/ivr/call/logs/list`);
      setIvrConfigCalling(data?.data);
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  };
  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
  }
  const openCallingList = (item) => {
    setShowModal(true);
    setInitialDetails(item?.input_details);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const steps = ["services", "IVR Calling List"];

  const setStatus = (status) => {
    const item = status?.split("-");
    const capitalizedParts = item?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1));
    return capitalizedParts?.join("-");
  };

  return (
    <div className={`flex dark:bg-dark-background updateIvrContainer`}>
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <div className="z-0 container p-10 mainContainer">
        <Breadcrumb
              steps={steps}
              activeStep={1}
              showBackButton={true}
              redirectUrl={"/services"}
            />
          <div className=" mt-4">
            <table
              className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}
            >
              <thead>
                <tr className="ps-2 pe-2">
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    S.No
                  </th>
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    User
                  </th>
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    Workflow Name
                  </th>
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    Phone Number
                  </th>
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    Status
                  </th>
                  <th
                    className="py-3 px-4 capitalize "
                    style={{ fontSize: "16px" }}
                  >
                    Response
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRecords?.map((item, index) => (
                  <tr
                    style={{ backgroundColor: "white" }}
                    key={index}
                    className="border-b"
                  >
                    <td className="p-4">{item.sr_number}</td>
                    <td className="p-4 capitalize">
                      {(item.user_name)}
                    </td>
                    <td className="p-4">{item?.workflow_name}</td>
                    <td className="p-4">{item?.phone_number}</td>
                    <td className="p-4">{setStatus(item?.status)}</td>
                    <td className="p-4">
                      <button onClick={() => openCallingList(item)}>
                        <div className="actionImages">
                          <img
                            src="/assets/images/infoIcon.svg"
                            className="infoIcon"
                          ></img>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          ></Pagination>
          {showModal && (
            <div
              id="static-modal"
              data-modal-backdrop="static"
              tabindex="-1"
              aria-hidden="true"
              class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
            >
              <div class="relative p-4 w-2/5 max-w-2xl max-h-full">
                <div class="relative createWorkflowModal rounded-lg shadow dark:bg-gray-700">
                  <div class="flex items-center justify-between ps-4 pe-4 pt-4 rounded-t dark:border-gray-600">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                     IVR Responses
                    </h3>
                    <button
                      type="button"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="static-modal"
                      onClick={closeModal}
                    >
                      <svg
                        class="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 initiatedDetails">
                    <table
                      className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}
                    >
                      <thead>
                        <tr className="ps-2 pe-2">
                          <th
                            className="py-3 px-4 capitalize w-1/4"
                            style={{ fontSize: "16px" }}
                          >
                            Initiated By
                          </th>
                          <th
                            className="py-3 px-4 capitalize "
                            style={{ fontSize: "16px" }}
                          >
                            Response
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {initialDetails && initialDetails?.map((item, index) => (
                          <tr
                            style={{ backgroundColor: "white" }}
                            key={index}
                            className="border-b"
                          >
                            <td className="p-4 capitalize">{(item.initiated_by)}</td>
                            <td className="p-4">{item.value}</td>
                          </tr>
                        ))}
                        {!initialDetails &&
                          <tr
                            style={{ backgroundColor: "white" }}
                            className="border-b"
                          >
                            <td className="p-4 text-center font-semibold" colSpan="2">No Data Found</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default WithAuth(IVRCallingList);
