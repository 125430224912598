import {
    Image,
} from "../../component/core-component";

const checkEmail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('query');
    return (
        <>
        <div className="flex justify-center items-center flex-col backImage" style={{height:"100vh"}}>
        <div className="flex justify-center pb-6 flex-col">
        <Image
            source={"/assets/images/logo.svg"}
            height={70}
            width={70}
            alt="Logo"
          />
          </div> 
            <div className="sm:mt-6 sm:py-6 sm:w-96 w-100 text-center mx-auto">
                <div className="text-3xl font-bold">Check your Email</div>
                <div className="pt-4">We've sent a link to reset your password to {email}</div>
            </div>
        </div>
        </>
    )
}

export default checkEmail;