import axios from 'axios';
const HOST = process.env.REACT_APP_API_SERVICE_BASE_URL;
const VERSION = '/api/';
const API = HOST + VERSION;

// Create an instance of Axios with default headers
const axiosInstance = axios.create({
  baseURL: API,
  headers: {
      'Content-Type': 'application/json',
    // Add your token to the Authorization header
    // Assuming the token is stored in localStorage or sessionStorage
    'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
  }
});

export default axiosInstance;