import { toast } from 'react-toastify';
import { Base64 } from "js-base64";

export function formatDateToIso(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const handleErrorMessage = (err) => {
  if (
    err.response &&
    err.response.data &&
    (err.response.data.success === false || err.response.data.status === false) &&
    err.response.data.message
  ) {
    toast.error(err?.response?.data?.message);
  } else {
    toast.error(err?.response?.data?.error ? err?.response?.data?.error : err?.message);
  }
};

export function handleValidNumberInput(e) {
  if ([69, 189, 187, 91, 86]?.includes(e.keyCode) || ["Minus"]?.includes(e?.code) || ["NumpadSubtract"]?.includes(e?.code)) {
    e.preventDefault();
  }
}

// Function to encrypt data 
export const encodeData = (payload) => {
  try {
    let dataString = Base64.btoa(encodeURI(JSON.stringify(payload)));
    return dataString;
  } catch (error) {
    return null;
  }
};

export const decodeData = (token) => {
  try {
    let payload = JSON.parse(decodeURI(Base64.atob(token)));
    return payload;
  } catch (error) {
    return null;
  }
};