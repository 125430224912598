import React, {useEffect, useState } from "react";
import { ColorRing } from 'react-loader-spinner';
import styles from "../styles/userEdit.module.scss";

export default function ViewNIDDetailModal(props) {
    const { 
        setShowNIDDetailModal, 
        NIDDetailData,
        detailsLoading, 
    } = props;
    const [showPopup, setShowPopup] = useState(false)
    const [responseScreenWidth, setResponseScreenWidth] = useState();
    const closeModal = () => {
        setShowNIDDetailModal(false);
    }
    useEffect(()=>{
        let viewportHeight = window.screen.width;
        setResponseScreenWidth(viewportHeight)
      },[])

    const copyRequestId = (requestId) => {
        setShowPopup(true);
        const tempInput = document.createElement('input');
        tempInput.value = requestId;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setTimeout(() => {
            setShowPopup(false);
        }, 1000);
    }

    return (
        responseScreenWidth > 900 ?
         <>
            {/* <!-- Main modal --> */}
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">

                    {detailsLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                    </div>}
                    <div className={`${window.screen.width > 900 ? styles.customModal :styles.respCustomModal} mainContainer relative rounded-lg shadow dark:bg-gray-700`}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                NID Details
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {NIDDetailData && NIDDetailData?.length > 0 ? 
                            NIDDetailData?.map((field) => (
                            <div key={field.id}>
                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div key={field.request_id}>
                                                <div className="flex flex-col">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Request Id:
                                                    </label>
                                                    <span className="flex">{field?.id} <span className="ms-2 cursor-pointer" data-tooltip-id="copied-tooltip" onClick={() => copyRequestId(field?.id)}><img src="/assets/clone.svg" alt="copy"></img></span></span>
                                                    {showPopup &&
                                                        <div className={`popup ${showPopup ? 'active' : ''}`}><p>Copied to clipboard</p></div>
                                                    }
                                                </div>
                                                <div className="flex justify-between mt-4">
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Name (English):
                                                        </label>
                                                        <span className="flex">{field?.nameEN}</span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Name (Bangla):
                                                        </label>
                                                        <span className="flex">{field?.nameBN}</span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Date of Birth:
                                                        </label>
                                                        <span className="flex">{field?.dob}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between mt-4">
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Father Name
                                                        </label>
                                                        <span className="flex">{field?.fatherName}</span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Mother Name
                                                        </label>
                                                        <span className="flex">{field?.motherName}</span>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Spouse Name
                                                        </label>
                                                        <span className="flex">{field?.spouseName || '--'}</span>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div>
                                            <div className="flex flex-col">
                                                <label className={`${styles.nidDetailsLabels}`}>
                                                    Present address:
                                                </label>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Division
                                                    </label>
                                                    <span className="flex">{field?.present_address?.division}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        District
                                                    </label>
                                                    <span className="flex">{field?.present_address?.district}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        RMO
                                                    </label>
                                                    <span className="flex">{field?.present_address?.rmo}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        City
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.city || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Upozila
                                                    </label>
                                                    <span className="flex">{field?.present_address?.upozila}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Union ward
                                                    </label>
                                                    <span className="flex">{field?.present_address?.union_ward || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Moholla
                                                    </label>
                                                    <span className="flex">{field?.present_address?.moholla}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional moholla
                                                    </label>
                                                    <span className="flex">{field?.present_address?.additional_moholla || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Ward for union porishod
                                                    </label>
                                                    <span className="flex">{field?.present_address?.ward_for_union_porishod}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Village
                                                    </label>
                                                    <span className="flex">{field?.present_address?.village || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional village road
                                                    </label>
                                                    <span className="flex">{field?.present_address?.additional_village_road || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        House no
                                                    </label>
                                                    <span className="flex">{field?.present_address?.house_no || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Post office
                                                    </label>
                                                    <span className="flex">{field?.present_address?.post_office || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Postal code
                                                    </label>
                                                    <span className="flex">{field?.present_address?.postal_code || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Region
                                                    </label>
                                                    <span className="flex">{field?.present_address?.region || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div>
                                            <div className="flex flex-col">
                                                <label className={`${styles.nidDetailsLabels}`}>
                                                    Permanent address:
                                                </label>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Division
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.division}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        District
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.district}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        RMO
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.rmo}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        City
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.city || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Upozila
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.upozila}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Union ward
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.union_ward || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Moholla
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.moholla}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional moholla
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.additional_moholla || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Ward for union porishod
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.ward_for_union_porishod}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Village
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.village || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional village road
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.additional_village_road || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        House no
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.house_no || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.presentAddressDetails} grid grid-cols-12 gap-4 mt-4`}>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Post office
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.post_office || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Postal code
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.postal_code || '--'}</span>
                                                </div>
                                                <div className="flex flex-col col-span-3 ms-5">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Region
                                                    </label>
                                                    <span className="flex">{field?.permanent_address?.region || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                <div className="p-5">
                                        <div key={field.request_id}>
                                            <div className="flex justify-between mt-4">
                                                <div className="flex flex-col">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Blood group:
                                                    </label>
                                                    <span className="flex">{field?.blood_group || '--'}</span>
                                                </div>
                                                <div className="flex flex-col">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        National id:
                                                    </label>
                                                    <span className="flex">{field?.national_id || '--'}</span>
                                                </div>
                                                <div className="flex flex-col">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        PIN:
                                                    </label>
                                                    <span className="flex">{field?.pin || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) :
                            (!detailsLoading && <div className="flex justify-center py-4 font-semibold">No Data found</div>)
                        }
                    </div>
                </div>
            </div>
        </>
        :
        <>
            {/* <!-- Main modal --> */}
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">

                    {detailsLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                    </div>}
                    <div className={`${window.screen.width > 900 ? styles.customModal :styles.respCustomModal} mainContainer relative rounded-lg shadow dark:bg-gray-700`}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                NID Details
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {NIDDetailData && NIDDetailData?.length > 0 ? 
                            NIDDetailData?.map((field) => (
                            <div key={field.id}>
                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div key={field.request_id}>
                                                <div className="flex flex-col">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Request Id:
                                                    </label>
                                                    <span className="flex">{field?.id} <span className="ms-2 cursor-pointer" data-tooltip-id="copied-tooltip" onClick={() => copyRequestId(field?.id)}><img src="/assets/clone.svg" alt="copy"></img></span></span>
                                                    {showPopup &&
                                                        <div className={`popup ${showPopup ? 'active' : ''}`}><p>Copied to clipboard</p></div>
                                                    }
                                                </div>
                                                <div className="flex justify-between flex-col mt-4">
                                                    <div className="flex mb-3">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Name (English):
                                                        </label>
                                                        <span className="flex ms-2">{field?.nameEN}</span>
                                                    </div>
                                                    <div className="flex mb-3">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Name (Bangla):
                                                        </label>
                                                        <span className="flex ms-2">{field?.nameBN}</span>
                                                    </div>
                                                    <div className="flex">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Date of Birth:
                                                        </label>
                                                        <span className="flex ms-2">{field?.dob}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between mt-4 flex-col">
                                                    <div className="flex mb-3">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Father Name:
                                                        </label>
                                                        <span className="flex ms-2">{field?.fatherName}</span>
                                                    </div>
                                                    <div className="flex mb-3">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Mother Name:
                                                        </label>
                                                        <span className="flex ms-2">{field?.motherName}</span>
                                                    </div>
                                                    <div className="flex">
                                                        <label className={`${styles.nidDetailsLabels}`}>
                                                            Spouse Name:
                                                        </label>
                                                        <span className="flex ms-2">{field?.spouseName || '--'}</span>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div>
                                            <div className="flex flex-col">
                                                <label className={`${styles.nidDetailsLabels}`}>
                                                    Present address:
                                                </label>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Division:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.division}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        District:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.district}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        RMO:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.rmo}</span>
                                                </div>
                                            </div>
                                            <div className={`gap-4 mt-4`}>
                                                <div className="flex col-span-4 mb-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        City:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.city || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4 mb-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Upozila:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.upozila}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Union ward:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.union_ward || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={` grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Moholla:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.moholla}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional moholla:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.additional_moholla || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Ward for union porishod:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.ward_for_union_porishod}</span>
                                                </div>
                                            </div>
                                            <div className={` grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Village:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.village || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional village road:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.additional_village_road || '--'}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        House no:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.house_no || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Post office:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.post_office || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Postal code:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.postal_code || '--'}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Region:
                                                    </label>
                                                    <span className="flex ms-2">{field?.present_address?.region || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                    <div className="p-5">
                                        <div>
                                            <div className="flex flex-col">
                                                <label className={`${styles.nidDetailsLabels}`}>
                                                    Permanent address:
                                                </label>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Division:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.division}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        District:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.district}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        RMO:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.rmo}</span>
                                                </div>
                                            </div>
                                            <div className={` grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        City:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.city || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Upozila:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.upozila}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Union ward:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.union_ward || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Moholla:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.moholla}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional moholla:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.additional_moholla || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Ward for union porishod:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.ward_for_union_porishod}</span>
                                                </div>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Village:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.village || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Additional village road:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.additional_village_road || '--'}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        House no:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.house_no || '--'}</span>
                                                </div>
                                            </div>
                                            <div className={`grid gap-4 mt-4`}>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Post office:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.post_office || '--'}</span>
                                                </div>
                                                <div className="flex col-span-4">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Postal code:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.postal_code || '--'}</span>
                                                </div>
                                                <div className="flex col-span-3">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Region:
                                                    </label>
                                                    <span className="flex ms-2">{field?.permanent_address?.region || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-4 bg-white rounded">
                                <div className="p-5">
                                        <div key={field.request_id}>
                                            <div className="flex flex-col justify-between mt-4">
                                                <div className="flex">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        Blood group:
                                                    </label>
                                                    <span className="flex ms-2 mb-3">{field?.blood_group || '--'}</span>
                                                </div>
                                                <div className="flex">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        National id:
                                                    </label>
                                                    <span className="flex ms-2 mb-3">{field?.national_id || '--'}</span>
                                                </div>
                                                <div className="flex">
                                                    <label className={`${styles.nidDetailsLabels}`}>
                                                        PIN:
                                                    </label>
                                                    <span className="flex ms-2">{field?.pin || '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) :
                            (!detailsLoading && <div className="flex justify-center py-4 font-semibold">No Data found</div>)
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
