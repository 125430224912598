import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";
export default function IVRMain() {

  let navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;

  return (
    // <div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-5 flex flex-row justify-between items-center self-stretch rounded-lg border border-solid border-gray-border bg-white mb-4 p-6 accountBox">
    //   <div className="text-black-92 font-quicksand text-20 font-semibold leading-32">
    //     IVR Table
    //   </div>
    //   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"></div>
    //   <div
    //     onClick={() => navigate("/updateIvrTable")}
    //     className="cursor-pointer"
    //   >
    //     <Icon iconName="ChevronRight" height={16} width={16} />
    //   </div>
    // </div>
    <>
     {/* Card 1 */}
     <div className={`samdProcessCards bg-white hover:cursor-pointer commonCard ${!isMobileDevice ? 'samdProcessCards mx-4 ' : 'serviceMainCard_responsive'}`} onClick={() => navigate("/IVRWorkflow")}>
                <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                  <div>
                    <Icon iconName="userAccount" width="12" height="16"/>
                  </div>
                  <span className="font-bold text-base">
                      IVR
                  </span>
                  <div onClick={() => navigate("/updateIvrTable")} className="cursor-pointer arrowIcon commonCardIcons">
                    <img src="assets/right-arrow.svg" alt="file" />
                  </div>
                </div>
             <div>
              <p className="p-4 text-sm">
              Elevate your customer service with our IVR Process, your central hub for managing various request types efficiently. Seamlessly navigate through workflows and calling lists within the IVR interface, enabling streamlined call placement, workflow creation, and detailed calling list viewing.
              </p>
              <div className="float-end px-4 pb-3 text-sm">
        <p>
          Created on 24 Mar, 2024
        </p>
      </div>
      </div>
    </div>
    {/* Card 1 */}
    </>
  );
}
