import { WithAuth } from '../../common/WithAuth';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { isTokenExpired } from '../../helpers/auth';
import { useLocation,useNavigate } from "react-router-dom";
import { BsRecord2 } from "react-icons/bs";
import { PiRecordFill } from "react-icons/pi";
import { FaStopwatch20, FaStopCircle, FaPlay } from "react-icons/fa";
import { RiCameraSwitchLine } from "react-icons/ri";
import { IoMdVideocam } from "react-icons/io";
import { MdFileUpload } from "react-icons/md";
import { toast } from 'react-toastify';
import kycImgLoader from "../../assets/images/kycImg.svg"
import styles from "../../styles/userEdit.module.scss";
import Webcam from "react-webcam";
import { FACING_MODE_USER, FACING_MODE_ENVIRONMENT } from "../../constent/constent";
import { encodeData, handleErrorMessage } from '../../common/commonFunction';

const videoConstraints = {
  facingMode: FACING_MODE_USER
};

function VideoRecognition() {
  const [recording, setRecording] = useState(false);
  const [showVideoPreview, setShowVideoPreview] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [uploadDisbale, setUploadDisbale] = useState(false);
  const [uploadRecording, setUploadRecording] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [stream, setStream] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null); // State to store recorded video blob
  const [elapsedTime, setElapsedTime] = useState(0); // State to keep track of elapsed recording time
  const [tokenExpired, setTokenExpired] = useState(false);
  const [kycId, setkycId] = useState();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const timerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const isMobileDevice = window.innerWidth <= 768;
  const [isStart, setIsStart] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [currentActive, setCurrentActive] = useState(1);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const location = useLocation();

  async function setInitialData() {
    setIsRecordingStarted(true);
    let kycID = window?.location?.href?.split('=')?.[1]
    setkycId(kycID);
    // Get user media when the component mounts
    navigator?.mediaDevices?.getUserMedia({ video: true, audio: true })
      .then((userStream) => {
        setStream(userStream);
        videoRef.current.srcObject = userStream;
        videoRef.current.video.play();
      })
      .catch((err) => console.error('Error accessing media devices: ', err));
 
    return () => {
      // Clean up the stream when the component unmounts
      if (stream && isMounted) {
        stream?.getTracks()?.forEach(track => track?.stop());
      }
    };
  }
 
  useEffect(() => {
    let timer;
    if (recording) {
      // Start timer when recording starts
      timer = setInterval(() => {
        setElapsedTime(prevTime => prevTime - 1);
      }, 1000); // Update elapsed time every second
    } else {
      // Reset timer when recording stops
      setElapsedTime(20);
    }
    return () => clearInterval(timer); // Clear timer on component unmount or when recording stops
  }, [recording]);

  useEffect(() => {
    return () => setIsMounted(false);
  }, []);
 
  const startRecording = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (stream) {
      setOpenPreview(false);
      setIsStart(false);
      // Clear the previous recorded chunks
      chunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.start();
      videoRef.current.video.play();
      setRecording(true);
      setShowPreview(false);
      timerRef.current = setTimeout(stopRecording, 20000); // Automatically stop recording after 20 seconds
    }
  };
 
  const stopRecording = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsStart(true);
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setShowPreview(true);
      setRecording(false);
      setUploadRecording(true)
      videoRef.current.video.pause();
      setCurrentActive(2);
    }
  };
  
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
 
  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
      setRecordedBlob(new Blob(chunksRef.current, { type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' }));
    }
  };
 
  const handlePreview = () => {
    // Stop recording if it's currently active
    if (recording) {
      stopRecording();
    }
    setOpenPreview(true);
    // Create object URL for previewing the recorded video
    if (recordedBlob) {
      const recordedUrl = URL.createObjectURL(recordedBlob);
      setShowVideoPreview(recordedUrl);
      // window.open(recordedUrl, '_blank'); // Open the recorded video in a new tab
    } else {
      console.error('No recorded video available to preview.');
    }
  };
  async function getKycID() {
    const params = new URLSearchParams(location.search);
    return params.get('query') || '';
}
  function decodeToken(token) {
    return window.atob(token);
  }
 
  const handleRecordingComplete = async (videoBlob) => {
    const formData = new FormData();
    const kycId = await getKycID();
    formData.append('video', videoBlob, 'recorded_video.mp4'); 
    let payload = {
      kyc_id: decodeToken(kycId)
    }
    formData.append('payload', encodeData(payload));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/upload/video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
 
      if (response.data.success) { 
        console.log('Video uploaded successfully!');
        setIsFileUploaded(true);
        setCurrentActive(3);
        // navigate(`/VideoKyc`); 
        // toast.success('Video uploaded successfully!')
        setShowPreview(false);
        setUploadDisbale(false);
      } else {
        setUploadDisbale(false);
        toast.error(response.data.error)
        console.error('Error uploading video:', response.data.error); 
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.error || error?.message);
      setUploadDisbale(false);
      console.error('Error sending video to backend:', error);
    }
  };
 
  const handleUpload = () => {
    if (stream) {
      let allTracks = stream.getTracks()
      allTracks.forEach(track => track.stop());
    }
    if (recordedBlob) {
      setUploadDisbale(true);
      handleRecordingComplete(recordedBlob); 
    } else {
      console.error('No recorded video to upload.');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const expired = isTokenExpired();
      setTokenExpired(expired);
    }, 60000); // 60,000 milliseconds = 1 minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);
  
  async function verifyVideoUpload(){
    try {
      const kycId = await getKycID();
      const response = await axios(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc/verify/upload?kyc_id=${decodeToken(kycId)}`, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
 
      if (response.data.success) { 
        if(response.data.isVideoUploaded){
          navigate(`/linkExpired`);
        }
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.error || error?.message);
      console.error('Error sending video to backend:', error);
    }
  }

  useEffect(() => {
   verifyVideoUpload()
  },[])

  // if (tokenExpired) {
  //   return <p>Token is expired or invalid. Please log in again.</p>;
  // }

  const switchCamera = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  const handleRecording = () => {
    setInitialData();
  };

  useEffect(() => {
    update(currentActive);
  }, [currentActive]);

  const update = (currentActive) => {
    const stepCircles = document.querySelectorAll(".circle");
    const progress = document.getElementById("progress");

    if (!progress) {
      // Exit early if progress element is not found
      return;
    }

    stepCircles.forEach((circle, i) => {
      if (i < currentActive) {
        circle.classList.add("active");
      } else {
        circle.classList.remove("active");
      }
    });

    const activeCircles = document.querySelectorAll(".active");
    progress.style.width =
      ((activeCircles.length - 1) / (stepCircles.length - 1)) * 90 + "%";
  };

  useEffect(()=>{
    if(isRecordingStarted && !isFileUploaded) {
      const unloadCallback = (event) => {      
          const e = event || window.event;
          e.preventDefault();
          if (e) {
            e.returnValue = "Are you sure you want to leave? Your recording progress will be lost.";
          }
          return '';       
      };
      window.addEventListener("beforeunload", unloadCallback);
      return () => {
        //cleanup function
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
  },[isRecordingStarted, isFileUploaded])
 
  return (
    <div className={`${styles.videoRecog_container} flex justify-center items-center`}>
      {!isFileUploaded &&
        (!isRecordingStarted ? (
          <div className={`flex w-9/12 bg-white ${styles.generateOtpContainer}`}>
            <div className='w-1/2 flex justify-center items-center p-12 kycMainLogo'>
              <img src="assets/images/videoRecogImg.png" alt="kyc icon" />
            </div>
            <div className='w-1/2 flex flex-col p-7 justify-center kycDescription'>
              <span className='text-5xl font-bold videoKycHeading'>Video KYC</span>
              <div className='font-medium text-gray-500 kycDescription_container'>
                <p className='w-4/5 mt-3 text-lg'>Video KYC is an additional mode through which users can complete the KYC for their accounts within just a few minutes.</p>
              </div>
              <button className={`flex justify-center items-center mt-12 border bg-[#00b6de] text-white text-lg font-medium py-5 generateOtpButton ${styles.generateOtpBtn}`} onClick={handleRecording}>
                <img src="assets/images/recordingLogo.svg" alt="recording" style={{ width: "25px" }} />
                <span className='ms-2'>Start Recording</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="kycVideoRecorder_mainContainer flex justify-center items-center">
            {isMobileDevice && <span className='text-3xl mb-2 w-full text-white font-bold ps-5'>Video KYC</span>}
            <div className='w-11/12 bg-white recorderContainer p-5'>
              {!isMobileDevice && <span className='text-4xl font-bold ps-5 videoKycHeadingNew'>Video KYC</span>}
              <div className='flex px-2 pt-3 videoRecorderContainer'>
                {uploadDisbale && <div className="flex justify-center absolute" style={{ top: "35%", left: "48%", zIndex: 9 }}>
                  <div>
                    <img className={`${styles.rotateImg}`} src={kycImgLoader}></img>
                    <p className="flex justify-center text-white" >Uploading....</p>
                  </div>
                </div>}
                <div className="relative">
                  {showPreview && !openPreview &&
                    <div className='w-full h-full absolute z-10 flex justify-center items-center videoUploadStatus'>
                      <div className='flex flex-col items-center justify-center uploadedVideoContainer'>
                        <img src="assets/images/fileUploadIcon.svg" alt="upload" />
                        <p className='w-1/2 text-gray-300'>Your Video has been recorded Successfully, Click on "Upload" to Submit for Verification.</p>
                      </div>
                    </div>
                  }
                  <div className='videoKycContainer flex flex-col items-center'>
                    {openPreview &&
                      <video controls autoPlay className='videoInMobile showRecoder'>
                        <source src={showVideoPreview} type="video/mp4" />
                      </video>
                    }
                    {isMobileDevice && !recording && !openPreview &&
                      <div className='ms-3 flex p-2 rounded mt-1 actionButtons absolute z-10 left-0'>
                        <button className='flex switchCamera' onClick={switchCamera}>
                          <RiCameraSwitchLine className='cursor-pointer me-1' size={25} />
                        </button>
                      </div>
                    }
                    {
                      isRecordingStarted && 
                      <Webcam
                        audio={true}
                        muted
                        ref={videoRef}
                        screenshotFormat="image/jpeg"
                        mirrored={facingMode == 'user' ? true : false}
                        videoConstraints={{
                          ...videoConstraints,
                          facingMode
                        }}
                        style={{ opacity: uploadDisbale ? 0.3 : '' }}
                        className={`${openPreview ? 'hideRecoder' : 'showRecoder'} ${isMobileDevice && !openPreview && 'videoInMobile'}`}
                      />
                    }
                    <div className={`flex justify-between absolute bg-white p-1.5 z-20 ${styles.kycAction_btn} ${openPreview && 'opacity-50 mb-5'}`}>
                      <button onClick={startRecording} disabled={recording || uploadDisbale} className={`flex justify-center items-center py-2 px-5 ${styles.startRecordingBtn} ${recording || uploadDisbale ? 'disableButton' : styles.recordingBtn_onEnable}`}>
                        {!isStart ? (
                          <>
                            <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 12.5L23.221 10.5674C24.2124 9.97254 24.7081 9.67511 25.116 9.71313C25.4717 9.74628 25.7961 9.93 26.0076 10.2179C26.25 10.5481 26.25 11.1262 26.25 12.2824V17.7176C26.25 18.8738 26.25 19.4519 26.0076 19.7821C25.7961 20.07 25.4717 20.2537 25.116 20.2869C24.7081 20.3249 24.2124 20.0275 23.221 19.4326L20 17.5M7.75 22.5H16C17.4001 22.5 18.1002 22.5 18.635 22.2275C19.1054 21.9878 19.4878 21.6054 19.7275 21.135C20 20.6002 20 19.9001 20 18.5V11.5C20 10.0999 20 9.3998 19.7275 8.86502C19.4878 8.39462 19.1054 8.01217 18.635 7.77248C18.1002 7.5 17.4001 7.5 16 7.5H7.75C6.34987 7.5 5.6498 7.5 5.11502 7.77248C4.64462 8.01217 4.26217 8.39462 4.02248 8.86502C3.75 9.3998 3.75 10.0999 3.75 11.5V18.5C3.75 19.9001 3.75 20.6002 4.02248 21.135C4.26217 21.6054 4.64462 21.9878 5.11502 22.2275C5.6498 22.5 6.34987 22.5 7.75 22.5Z"  stroke={recording ? '#ffffff' : '#00B6DE'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className='ms-2 font-bold'>Start Recording</span>
                          </>
                        ) : (
                          <>
                            <img src="assets/images/reload.svg" alt="reload" />
                            <span className='ms-2 font-bold'>Record Again</span>
                          </>
                        )}
                      </button>
                      <button onClick={stopRecording} disabled={!recording} className={`ms-2 flex justify-center items-center py-2 px-5 ${styles.stopRecordingBtn} ${!recording ? 'disableButton' : styles.enableStopBtn}`}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5 12.5C21.5 17.4706 17.4706 21.5 12.5 21.5C7.52944 21.5 3.5 17.4706 3.5 12.5C3.5 7.52944 7.52944 3.5 12.5 3.5C17.4706 3.5 21.5 7.52944 21.5 12.5Z" stroke={!recording ? '#ffffff' : '#00B6DE'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.5 11.1C9.5 10.5399 9.5 10.2599 9.60899 10.046C9.70487 9.85785 9.85785 9.70487 10.046 9.60899C10.2599 9.5 10.5399 9.5 11.1 9.5H13.9C14.4601 9.5 14.7401 9.5 14.954 9.60899C15.1422 9.70487 15.2951 9.85785 15.391 10.046C15.5 10.2599 15.5 10.5399 15.5 11.1V13.9C15.5 14.4601 15.5 14.7401 15.391 14.954C15.2951 15.1422 15.1422 15.2951 14.954 15.391C14.7401 15.5 14.4601 15.5 13.9 15.5H11.1C10.5399 15.5 10.2599 15.5 10.046 15.391C9.85785 15.2951 9.70487 15.1422 9.60899 14.954C9.5 14.7401 9.5 14.4601 9.5 13.9V11.1Z" stroke={!recording ? '#ffffff' : '#00B6DE'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span className='ms-2 font-bold'>Stop</span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* {recording &&
                  <div className='recordingProgress flex'>
                    <PiRecordFill size={25} className='recordingDot' />
                  </div>
                } */}

                <div className={`flex justify-center items-center px-10 pv-10 w-1/2 kycStepsContainer ${isMobileDevice ? 'videoButtonsInMobile' : ''}`}>
                  <div className={`${styles.preview_uploadBtn_Container}`}>
                    <span className='text-4xl font-bold stepsHeader'>{!isStart ? 'Step: 1 Record' : 'Step: 2 Upload'}</span>
                    <p className='text-lg w-10/12 mt-2 stepsDescription'>Video KYC is an additional mode through which users can complete the KYC for their accounts within just a  few minutes.</p>
                    <div className={`flex w-2/3 mt-8 ${styles.preview_uploadBtn}`}>
                      <button onClick={handlePreview} disabled={!showPreview || uploadDisbale} className={`p-2.5 flex justify-center items-center ${styles.previewButton} ${!showPreview || uploadDisbale ? 'disableButton' : styles.enableButton}`}>
                        <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6582 9.78663C18.098 10.6865 18.8178 11.1364 19.0647 11.7124C19.2803 12.2155 19.2803 12.785 19.0647 13.288C18.8178 13.8641 18.098 14.314 16.6582 15.2138L9.896 19.4402C8.29805 20.4389 7.49907 20.9383 6.83973 20.8853C6.26501 20.8391 5.73818 20.5471 5.3944 20.0842C5 19.5532 5 18.611 5 16.7266V8.27381C5 6.38944 5 5.44725 5.3944 4.91622C5.73818 4.45334 6.26501 4.16135 6.83973 4.11515C7.49907 4.06214 8.29805 4.5615 9.896 5.56022L16.6582 9.78663Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                        </svg>
                        <span className='ms-2 font-medium'>Preview</span>
                      </button>
                      <button onClick={handleUpload} disabled={!uploadRecording || uploadDisbale} className={`p-2.5 ms-7 flex justify-center items-center ${styles.uploadButton} ${!uploadRecording || uploadDisbale ? 'disableButton' : styles.enableButton}`}>
                        <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 17.5H17.01M15.6 14.5H18C18.9319 14.5 19.3978 14.5 19.7654 14.6522C20.2554 14.8552 20.6448 15.2446 20.8478 15.7346C21 16.1022 21 16.5681 21 17.5C21 18.4319 21 18.8978 20.8478 19.2654C20.6448 19.7554 20.2554 20.1448 19.7654 20.3478C19.3978 20.5 18.9319 20.5 18 20.5H6C5.06812 20.5 4.60218 20.5 4.23463 20.3478C3.74458 20.1448 3.35523 19.7554 3.15224 19.2654C3 18.8978 3 18.4319 3 17.5C3 16.5681 3 16.1022 3.15224 15.7346C3.35523 15.2446 3.74458 14.8552 4.23463 14.6522C4.60218 14.5 5.06812 14.5 6 14.5H8.4M12 15.5V4.5M12 4.5L15 7.5M12 4.5L9 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span className='ms-2 font-medium'>Upload</span>
                      </button>
                    </div>
                    <div className="progressBar_mainContainer absolute">
                      <div className="progress-container flex">
                        <div className="progress" id="progress"> </div>
                        <div>
                          <span className="circle active">1</span>
                          <span className='record'>Record</span>
                        </div>
                        <div>
                          <span className="circle">2</span>
                          <span className='upload'>Upload</span>
                        </div>
                        <div>
                          <span className="circle">
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.6665 9.27786L8.71779 11.5001L13.3332 6.50008M1.6665 9.00008L5.83317 1.7832H14.1665L18.3332 9.00008L14.1665 16.217H5.83317L1.6665 9.00008Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </span>
                          <span className='verify'>Verify</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
       }
      {
        isFileUploaded && 
        <div className='w-9/12 bg-white recorderContainer p-5 flex flex-col justify-center items-center'>
          <img src="assets/images/thankyou.png" alt="thankYou" className={`${styles.thankYouIcon}`} />
          <div className='flex flex-col justify-center items-center'>
            <span className='text-4xl font-bold thankYou_header'>Thank You!</span>
            <span className='text-lg text-center thankYou_description' style={{width: "48%"}}>
              Your Video KYC verification Request has been raised, You will Receive a notification via SMS once your Video KYC process has been successfully completed.
            </span>
          </div>
          <div className="progressBar_mainContainer thankyouProgress_mainContainer mt-6">
            <div className="progress-container thankyouProgress_container flex">
              <div className="progress" id="progress"> </div>
              <div>
                <span className="circle active" style={{background: "#00B6DE"}}>1</span>
                <span className='thankYou_status'>Record</span>
              </div>
              <div>
                <span className="circle" style={{background: "#00B6DE"}}>2</span>
                <span className='thankYou_status'>Upload</span>
              </div>
              <div>
                <span className="circle" style={{background: "#00B6DE"}}>
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.6665 9.27786L8.71779 11.5001L13.3332 6.50008M1.6665 9.00008L5.83317 1.7832H14.1665L18.3332 9.00008L14.1665 16.217H5.83317L1.6665 9.00008Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                <span className='thankYou_status'>Verify</span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
 
export default VideoRecognition;