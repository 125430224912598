import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import SideMenu from '../../../component/SideMenu';
import Header from '../../../component/Header';
import Breadcrumb from '../../../component/Breadcrumb';
import DataTable from '../../../component/DataTable';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import API from "../../../helpers/api/index";
import "../../../styles/updatePhoneNumber.scss";
import Icon from '../../../component/core-component/Icon';
import { Select } from 'antd';
import moment from 'moment';
import crossIcon from "../../../assets/images/crossIconsvg.svg";
import debounce from 'lodash/debounce';
import { encodeData, handleErrorMessage } from '../../../common/commonFunction';
import Pagination from "../../../component/Pagination";
import deleteIcon from "../../../assets/images/deleteIcon.svg"
import renameIcon from "../../../assets/images/renameIcon.svg";
import details from "../../../assets/images/fi_more-vertical.svg";
import * as Actions from "../../../redux/actions";
import { getDecodedToken } from "../../../helpers/auth";
import Validation from '../../../utils/Validation'
import { WithAuth } from '../../../common/WithAuth';
import FolderDetailsTable from './FolderDetailsTable';

const { Option } = Select;

const phoneTableHeader = [
    {
        title: 'Document Name',
        key: 'document_name',
        // width: '18%',
    },

    {
        title: 'Document Type',
        key: 'document_type',
        // width: '13%',
    },
    {
        title: 'Uploaded By',
        key: 'User',
        // width: '13%',
    },
    {
        title: 'Uploaded At',
        key: 'uploaded_at',
        // width: '13%',
    },
    // {
    //   title: 'RESEND LINK',
    //   key: 'resend_link',
    // },
    {
        title: 'Action',
        componentSrc: 'ViewFolder',
        key: 'action',
    },
];

const ViewFolderDetails = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [breadcrumbSteps, setBreadcrumbSteps] = useState(['Process List', 'Loan Document Storage' ])
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [values, setValues] = useState({
        document_name: '',
        document_type: '',
        document_tag: '',
        tags: [] 
    });
    const [tagValues, setTagValues] = useState({
        document_name: '',
        document_type: '',
        document_tag:'',
        tags: []
    });
    const [formErrors, setFormErrors] = useState({});
    const [data, setData] = useState({pdf: ''});
    const [updateLoanData, setUpdateLoanData] = useState([]);
    const [updateTags,setUpdateTags] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [searchData, setSearchData] = useState()
    const [dataLoading, setDataLoading] = useState(false)
    const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
    const [inputTag, setInputTag] = useState('');
    const [tagArray, setTagArray] = useState([]);
    const [statData, setStatData] = useState({ "Total": 'loading...', "Approved": "loading...", "Rejected": "loading...", "Pending": "loading..." })
    const [backUrl, setBackUrl] = useState(false)
    const [documentTypeByMyTask, setDocumentTypeByMyTask] = useState('')
    const [myTaskId, setMyTaskId] = useState(null)
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [documentData, setDocumentData] = useState({});
    const [documentName, setDocumentName] = useState("");
    const [documentNameError, setDocumentNameError] = useState("");
    const [progress1Loading, setProgress1Loading] = useState('noprogress');
    const [dataOne, setDataOne] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [filterData, setFilteredData] = useState([]);
    const [myTask, setMyTask] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const isMobileDevice = window.screen.width <= 900;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filterData ? filterData?.slice(indexOfFirstRecord, indexOfLastRecord) : []?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(filterData ? filterData?.length / recordsPerPage : [].length / recordsPerPage);
    const [userData] = useSelector((Gstate) => [Gstate?.UserDataReducer?.user_data]);
    const [linkView, setLinkView] = useState(false);
    const [linkDataView, setLinkDataView] = useState(null);
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    var redux_dispatch = useDispatch();
    const ref = useRef();
    const buttonRef = useRef();

    const openModal = () => {
        setIsOpen(true);
        // setModalOpacity(!modalOpacity)
    };

    useEffect(() => {
        const handler = (event) => {
          if (
            showOptionsModal &&
            ref.current &&
            !ref.current.contains(event.target)
          ) {
            setShowOptionsModal(false);
          }
        };
        document.addEventListener('mousedown', handler);
        return () => {
          document.removeEventListener('mousedown', handler);
        };
      }, [showOptionsModal]);

    function getFolderId() {
        const params = new URLSearchParams(window.location.search);
        const folderName = params.get('folder_name');
        const folderNameParts = folderName.split('/');
        if (!breadcrumbSteps?.includes(folderNameParts?.[0])) {
            setBreadcrumbSteps([...breadcrumbSteps, folderNameParts?.[0]]);
        }
        const folder_id = params.get('folder_id');
        if (folder_id) {
            if (folderNameParts.length > 1) {
                const [, ...folderIdParts] = folderNameParts;
                setBackUrl(folderIdParts.join('/'));
                setDocumentTypeByMyTask(folderIdParts[1]);
                const taskIdParts = folderIdParts[2].split('=');
                setMyTaskId(parseInt(taskIdParts[1], 10));
                setTagValues(prevState => ({
                    ...prevState,
                    document_type: folderIdParts[1]
                }));
            } else {
                setBackUrl('');
                setDocumentTypeByMyTask("");
                setMyTaskId("");
            }
            return folder_id;
        }
        return '';
    }
    
    const handleLinkClick = (linkData) => {
        setLinkDataView(linkData);
        setLinkView(true);
    };

    function closeLinkModal() {
        setLinkView(false);
    }

    const closeModal = () => {
        setIsOpen(false);
        setValues({});
        setInputTag('');
        setTagValues({
            document_name: '',
            document_type: documentTypeByMyTask ? documentTypeByMyTask : "",
            document_tag:'',
            tags: []
        });
        setFormErrors({});
        setData('');
        setProgress1Loading('noprogress')
        handleClear('pdf')
    };
    const validateForm = () => {
        const errors = {};
        if (!tagValues?.document_name) {
            errors.document_name = "Document name is required"
        }
        if (!Validation.validateName(tagValues?.document_name) && tagValues?.document_name?.length) {
            errors.document_name  = "Document name should not contain any special character";
          }
        if (!tagValues?.document_type) {
            errors.document_type = "Document Type is required"
        }
        if (data?.pdf == '') {
            errors.pdf = "PDF file is required"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const storeUserData = (data) => {
        redux_dispatch({
          type: Actions.UPDATE_USER_DATA,
          payload: { user_data: { ...data } },
        });
      }
      const getUserDetails = async () => {
        try {
          const { user_id } = getDecodedToken()
          const response = await API.apiGet(`/users/profile?user_id=${user_id}`)
          if (response?.status) {
            const userProfile = response?.data?.data;
            storeUserData(userProfile)
            localStorage.setItem('perm', JSON.stringify(userProfile?.Permissions))
            sessionStorage.setItem('userData', JSON.stringify(userProfile));
          }
          else {
            toast.error(response?.message)
          }
        }
        catch (error) {
          handleErrorMessage(error);
        }
      }

    const handleFileUpload = async (event) => {
        SetRequestSubmitLoader(true)
        if (validateForm()) {
            const formData = new FormData();
            let finalPayload = {
                document_name: tagValues?.document_name,
                document_type: tagValues?.document_type,
                folder_id: getFolderId()
            }
            if (tagValues?.tags !== undefined) {
                finalPayload.document_tag = tagValues.tags
            }
            const encodedData = encodeData(finalPayload);
            formData.append("metadata", encodedData);
            formData.append("Document", data?.pdf);
            try {
                let response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/fileStorage/file/documents`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                response = await response.json();
                if (response?.success) {
                    toast.success(response?.message);
                    if (myTaskId){
                        let payload = {
                            taskId: myTaskId
                        }
                        await API.apiPost(`/raise/task/update`, {payload: encodeData(payload)});
                        getUserDetails()
                        getMyTaskList()
                    }
                    closeModal();
                    getLoanStorageDocuments()
                    SetRequestSubmitLoader(false)
                } else {
                    SetRequestSubmitLoader(false)
                    toast.error(response?.message);
                }
            }
            catch (error) {
                SetRequestSubmitLoader(false)
                handleErrorMessage(error);
                // toast.error(error?.response?.data?.message)
            }
        }
        SetRequestSubmitLoader(false)
        event.preventDefault();
    };

    function removeTag(tag) {
        // const updatedTags=[...tagValues?.tags];
        // updatedTags.splice(index,1);

        let newTags = tagValues?.tags;
        newTags = newTags.filter(itemTag => itemTag != tag);
        setTagValues({...tagValues, tags: newTags})

        // setTagValues(prevState =>({
        //     ...prevState,
        //     tags:updatedTags
        // }));
    }


    const getLoanStorageDocuments = async (tag) => {
        setDataLoading(true)
        try{
            const query = {
                folder_id: parseInt(getFolderId()),
                tag: tag || ''
            }
            const { data } = await API.apiGet(`fileStorage/file/documents`,'',query);   
            if(data?.success && data?.data){
                let fileData = data?.data;
                setFilteredData(data?.data);
                fileData = fileData?.map(item => {
                    item.uploaded_at = moment(item?.uploaded_at).format('YYYY-MM-DD');
                    return item;
                })

              setUpdateLoanData(fileData);
            //   toast.success(data?.message);
              setDataLoading(false)
            }else{
            //   toast.error(data?.message)
              setFilteredData([]);
              setDataLoading(false)
            }
           }
           catch(error){
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setDataLoading(false)
          }
    }

    const getTagsData = async() => {
        try{
            const { data } = await API.apiGet(`fileStorage/tags`);
            if(data?.success){
                let fileData = data?.data;
                setUpdateTags(fileData);
              toast.success(data?.message);
              setDataLoading(false)
            }else{
              toast.error(data?.message)
              setDataLoading(false)
            }
           }
           catch(error){
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setDataLoading(false)
          }
    }

    const getDocumentsTagsOnCreate = async (value) => {
        if(value != "") {
            setInputTag(value)
        }
        tagsListData();
    }
    const tagsListData = debounce(getTagsData, 500);

    const handleFocusOut = () => {
        const checkButtonClicked = (event) => {
            if (event.target != buttonRef.current) {
                setInputTag('');
            }
        }
        document.body.addEventListener('click', checkButtonClicked)
    };

    const getDocumentsTags = async(value) => {
        if(value != "") {
            setInputTag(value)
        } else {
            setInputTag('');
        }
        try{
            const { data } = await API.apiGet(`fileStorage/tags`);
            if(data?.success){
                let fileData = data?.data;
                setUpdateTags(fileData);
              toast.success(data?.message);
              setDataLoading(false)
            }else{
              toast.error(data?.message)
              setDataLoading(false)
            }
           }
           catch(error){
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setDataLoading(false)
          }
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name == "tags") {
            if(value != "") {
                const newTags = tagValues.tags;
                newTags.add(value);
                setTagValues({...tagValues, tags: newTags});
                setTagArray([...tagValues?.tags])
            }
        } else {
            setTagValues((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleAddTag = () => {
        handleFilterOptionTag(inputTag, true, false);
    };

    const handleFileChangepdf = (event, isDragged) => {
        setProgress1Loading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf && pdf.type === "application/pdf") {
            setProgress1Loading('uploading');
            setTimeout(() => {
                setProgress1Loading('uploaded');
                setData({ ...data, pdf });
            }, 3000)
        } else {
            event.target.value = ''
            setData({ ...data, pdf: '' });
            toast.error("Please select an .pdf file.");
        }
    };

    const pdfDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFileChangepdf(e, true)
    }
    const dragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleClear = (filetype) => {
        setData({ ...data, [filetype]: '' })
        setProgress1Loading('noprogress')
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [filterData]);


    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }
    
    const handleSearch = (e) => {
        const searchText = e.target.value.toLowerCase(); // Convert search text to lowercase for case-insensitive search
        const filteredData = updateLoanData?.filter(item => {
          for (let key in item) {
            const value = item[key];
            if (typeof value === 'string' && value.toLowerCase().includes(searchText)) {
              return true;
            }
            if (typeof value === 'number' && value.toString().includes(searchText)) {
              return true;
            }
          }
          return false;
        });
        setSearchData(e.target.value);
        setFilteredData(filteredData);
      }

      const handleFilterTag = (selectedValue) => {
        const selectedOption = selectedValue;
        getLoanStorageDocuments(selectedValue)
        setSelectedValue(selectedOption);
    };
    
    const handleFilterOptionTag = (currentOption, isAddClicked, isEnterClicked) => {
        const selectedVal = currentOption;
        if(selectedVal != "" && selectedOption == '') {
            const newTag = tagValues?.tags;
            if (newTag?.includes(selectedVal)) {
                toast.error('Tag is already exists');
                return;
            } else {
                newTag.push(selectedVal);
                if (!updateTags?.includes(currentOption)) setUpdateTags([...updateTags, currentOption]);
                setTagValues({...tagValues, tags: newTag})
            }
        }
        setInputTag("");
        setSelectedOption('');  
    };

    const handleFilterTagClear = () => {
        setDataLoading(true)
        getLoanStorageDocuments()
        setSelectedValue(null);
    }
    const handleFilterOptionTagClear = () => {
        if (inputTag != '') {
            setDataLoading(true)
            getLoanStorageDocuments()
            setSelectedOption('');
            setInputTag('');
        }
    }
    const debouncedFilterTag = debounce(getDocumentsTags, 500);

    useEffect(() => {
        // return () => {
        setDataLoading(true)
        getLoanStorageDocuments() 
        getMyTaskList()  
        // }
    }, []);
    
    const handleEnterTag = (e) => {
        try{
            if(e.code === "Enter" || e.key === "Enter") {
                let value = e.target.value.toLowerCase();
                e.preventDefault();
                let isMatch = false;
                updateTags?.filter(item => {
                    if (item?.toLowerCase()?.includes(value)) {
                        isMatch = true
                    }
                })
                if (!isMatch) {
                    handleFilterOptionTag(e.target.value, false, true);
                }
            }
        } catch(err) {
            console.log(err);
        }
    }

    const openFolderRequest = (currentRecord) => {
        setSelectedRow(currentRecord);
        setShowOptionsModal(!showOptionsModal);
    }

    const handleRename = async (item) => {
        setShowOptionsModal(false);
        setTagValues(prevState => ({
            ...prevState,
            document_name: item?.document_name
        }));
        setDocumentData(item)
        setDocumentName(item?.document_name);
        setIsUpdateModalOpen(true);
    }

    const handleDelete = async (item) => {
        const payload = { id: item?.id }
        try {
            const response = await API.apiDelete(`/fileStorage/file/documents`, '', payload);
          if (response?.status) {
            setShowOptionsModal(false);
            toast.success(response?.data?.message)
            getLoanStorageDocuments();
          }
          else {
            toast.error(response?.data?.error)
          }
          // setWorkerLoading(false)
        }
        catch (error) {
          // setWorkerLoading(false)
          handleErrorMessage(error);
          console.log(error)
        }
    }

    const updateDocName = (event) => {
        if (event.target.value) {
            setDocumentName(event.target.value);
            setDocumentNameError('');
        } else {
            setDocumentNameError("Document name is required");
        }
    }

    const handleRenameSubmit = async () => {
        try {
            if (documentName != '') {
                setDataLoading(true);
                const item = { ...documentData }
                const payload = {
                    folder_id: item?.folder_id,
                    id: item?.id,
                    document_name: documentName
                }
                const response = await API.apiPut(`/fileStorage/file/documents`, {payload: encodeData(payload)})
                if (response?.status) {
                    setDataLoading(false);
                    toast.success(response?.data?.message);
                    getLoanStorageDocuments();
                    closeUpdateModal();
                }
                else {
                    toast.error(response?.data?.error)
                    setDataLoading(false);
                }
                // setWorkerLoading(false)    
            } else {
                setDocumentNameError('Document name required');
            }
        }
        catch (error) {
          // setWorkerLoading(false)
          handleErrorMessage(error);
          setDataLoading(false);
          console.log(error)
        }
    }

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
    }
    
    const getMyTaskList = async () => {
        setDataLoading(true);
        try {
          const { data } = await API.apiGet(`/raise/task/list?email=${userData?.email}`);
          if (data?.success) {
            let fileData = data?.data;
            fileData = fileData?.filter((item) => item.status === "Pending");
            fileData = fileData.length > 0 ? true :false
            setMyTask(fileData);
            setDataLoading(false);
          } else {
            setDataLoading(false);
          }
        } catch (error) {
          handleErrorMessage(error);
          setDataLoading(false);
        }
      }
    return (
        <div className={`flex dark:bg-dark-background updatePhone`}   >
            {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
            <SideMenu headerName="Loan Storage Document"/>
            <div className={`h-screen flex-1 overflow-y-auto ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                <Header/>
                {/* {dataLoading && <div className="absolute w-full h-full flex items-center top-0 left-0 border border-red-500 justify-center" >
                    <ColorRing
                        visible={true}
                        height="80"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[]}
                    />
                </div>} */}
                <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
                {isUpdateModalOpen && (
                                <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                                {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                                    <ColorRing
                                    visible={true}
                                    height="80"
                                    width="60"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={[]}
                                    />
                                </div>}
                                <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                                    <div className={`modal-content rounded-lg bg-white border modalContainer createModal p-5 ${isMobileDevice && 'responsiveUserModal'}`}>
                                    <div className='ps-3 pe-3 pt-1'>
                                        <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Update Folder</p>
                                    </div>
                                    <div className='modalForm'>
                                        <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                        <label htmlFor="document_name" className='numberRequestModal'>Document Name*</label>
                                        <input
                                            type="text"
                                            id="inputA"
                                            name="document_name"
                                            value={documentName}
                                            onChange={(e) => updateDocName(e)}
                                            placeholder="Enter Document Name"
                                            className={`py-2 rounded outline-none ps-2 border`}
                                        />
                                        {documentName == '' && documentNameError != '' && <span className='numberRequestModal' style={{ color: 'red' }}>{documentNameError}</span>}
                                        </div>

                                        <div className='flex justify-end mt-5 mb-3'>
                                        <div className='flex justify-center align-middle me-2'>
                                            <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2' onClick={closeUpdateModal}>Cancel</button>
                                        </div>
                                        <div className='flex justify-center align-middle me-3'>
                                            <button type="submit" className=' relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2' onClick={handleRenameSubmit}>
                                            Update</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            )}
                    {linkView && (
                        <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                        {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                        </div>}
                        <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                            <div className="modal-content rounded-lg bg-white border modalContainer createLinkModal p-5">
                            <iframe src={linkDataView} width="100%" height="700"></iframe>
                            <div className='flex justify-end align-middle me-2 mt-3'>
                                <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2' onClick={closeLinkModal}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    )}
                    <div className="z-0">
                        <Breadcrumb steps={breadcrumbSteps} activeStep={breadcrumbSteps?.length - 1} showBackButton={true} redirectUrl={backUrl ? (myTask ? '/my-task' : '/') : '/LoanStorage'} />
                    </div>
                    {!isMobileDevice ? (
                        <div className='flex justify-end py-4'>
                            <div className="flex flex-row-reverse ">
                                <div>
                                    <button
                                        type="button"
                                        className="raiseNewBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn"
                                        onClick={openModal}
                                    >
                                        Upload New Document +
                                    </button>
                                </div>
                                <div className='flex relative'>
                                    <span className={`absolute searchLogo`}>
                                        <Icon iconName="searchIconBlue" height={20} width={20} fill={themeDetails?.color_config?.main_color} />
                                    </span>
                                    <input 
                                    type='text' 
                                    placeholder='Search' 
                                    onChange={(e) => handleSearch(e)} 
                                    value={searchData} 
                                    className='numberSearch'>
                                    </input>
                                </div>
                                <div className='flex relative me-6 antdSelect'>
                                    <img src='assets/images/Vector.svg' className='searchLogo absolute' />
                                    <Select
                                        className='tagSelector'
                                        placeholder="Search Tags"
                                        showSearch
                                        value={selectedValue || undefined}
                                        onSelect={handleFilterTag}
                                        onSearch={debouncedFilterTag}
                                        allowClear
                                        onClear={handleFilterTagClear}
                                        onChange={(value) => { 
                                            if (!value) {
                                                setSelectedOption('');
                                            }
                                        }}
                                        options={inputTag && updateTags.map((option, index) => ({
                                            value: option,
                                            label: option,
                                            key: index,
                                        }))}
                                    
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                       <div className="py-4">
                            <div className='grid grid-cols-2 gap-2'>
                                <div className='flex relative me-6'>
                                    <img src='assets/images/Vector.svg' className='searchLogo absolute' />
                                    <Select
                                        className='tagSelectorResponsive'
                                        placeholder="Search Tags"
                                        showSearch
                                        value={selectedValue || undefined}
                                        onSelect={handleFilterTag}
                                        onSearch={debouncedFilterTag}
                                        allowClear
                                        onClear={handleFilterTagClear}
                                        onChange={(value) => { 
                                            if (!value) {
                                                setSelectedOption('');
                                            }
                                        }}
                                        options={inputTag && updateTags.map((option, index) => ({
                                            value: option,
                                            label: option,
                                            key: index,
                                        }))}
                                    />
                                </div>
                                <div className='flex relative'>
                                    <img src='assets/images/Vector.svg' className='searchLogo absolute' />
                                    <input 
                                    type='text' 
                                    placeholder='Search' 
                                    onChange={(e) => handleSearch(e)} 
                                    value={searchData} 
                                    className='numberSearchResponsive'>
                                    </input>
                                </div>
                            </div>
                            <div className='flex justify-end mt-4'>
                                <button
                                    type="button"
                                    className="raiseNewBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn"
                                    onClick={openModal}
                                >
                                    Upload New Document +
                                </button>
                            </div>
                       </div>
                    )}
                    <div>
                        {isOpen && (
                            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                                {requestSubmitLoader && <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10" >
                                    <ColorRing
                                        visible={true}
                                        height="80"
                                        width="60"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={[]}
                                    />
                                </div>}
                                <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                                    <div className={`modal-content rounded-lg bg-white border modalContainer createModal p-5 ${isMobileDevice && 'uploadNewDoc_responsive'}`}>
                                        <div className='ps-3 pe-3 pt-1'>
                                            <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Upload New Document</p>
                                        </div>
                                        <div className='modalForm'>
                                            <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                                <label htmlFor="document_name" className='numberRequestModal'>Document Name*</label>
                                                <input
                                                    type="text"
                                                    id="documentName"
                                                    name="document_name"
                                                    value={tagValues?.document_name}
                                                    onChange={handleChange}
                                                    placeholder="Enter Document Name"
                                                    className={`py-2 rounded outline-none ps-2  ${formErrors?.document_name ? 'border border-red-500' : ' border'}`}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.document_name ? 'red' : 'inherit' }}>{formErrors?.document_name}</span>
                                            </div>

                                            <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                                <label htmlFor="document_name" className='numberRequestModal'>Document Type*</label>
                                                <input
                                                    type="text"
                                                    id="documentType"
                                                    name="document_type"
                                                    value={documentTypeByMyTask ? documentTypeByMyTask : tagValues?.document_type}
                                                    onChange={handleChange}
                                                    placeholder="Enter Document Type"
                                                    className={`py-2 rounded outline-none ps-2  ${formErrors?.document_type ? 'border border-red-500' : ' border'}`}
                                                    disabled={documentTypeByMyTask}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.document_type ? 'red' : 'inherit' }}>{formErrors?.document_type}</span>
                                                {![...tagValues?.tags]?.length ?<div>No tags added</div> 
                                                :
                                                <div className='flex flex-wrap'>
                                                    {/* Display tags */}
                                                    
                                                    {[...tagValues?.tags]?.map((tag, index) => (
                                                        <div className={`relative flex capsuleTag`} key={index}>{tag} <img width="20" height="20" src={crossIcon} onClick={() => removeTag(tag)} className="capsuleIcon absolute cursor-pointer" /></div>
                                                    ))}
                                                </div>}
                                            </div>
                                            
                                            <div className='flex flex-col ps-3 pe-3 mt-4'>
                                                <label htmlFor="documentTag" className='numberRequestModal'>Document Tags</label>
                                                {/* <input
                                                    type="text"
                                                    id="documentTag"
                                                    name="document_tag"
                                                    value={values?.document_tag}
                                                    onChange={handleChange}
                                                    placeholder="Add New Tag"
                                                    className={`py-2 rounded outline-none ps-2 ${formErrors.document_tag ? 'border border-red-500' : 'border'}`}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.document_tag ? 'red' : 'inherit' }}>{formErrors?.document_tag}</span>
                                                <button className={values?.document_tag ? "addTagBtn" : "addTagBtnDisable"} disabled={!values?.document_tag} onClick={handleAddTag}>Add Tag</button> */}
                                                <div className='flex antdSelect'>
                                                <Select
                                                    className='inputTagSelect h-10'
                                                    placeholder="Enter Document Tags"
                                                    showSearch
                                                    value={selectedOption || inputTag || undefined}
                                                    onSelect={(e) => handleFilterOptionTag(e, false, false)}
                                                    onKeyDown={handleEnterTag}
                                                    onSearch={getDocumentsTagsOnCreate}
                                                    allowClear
                                                    onClear={handleFilterOptionTagClear}
                                                    onBlur={handleFocusOut}
                                                    onChange={(value) => {
                                                        if (!value) {
                                                            setSelectedValue(null);
                                                        }
                                                    }}
                                                    options={inputTag && updateTags.map((option, index) => ({
                                                        value: option,
                                                        label: option,
                                                        key: index,
                                                    }))}

                                                />
                                                <button className={inputTag ? "addTagBtn ms-2" : "addTagBtnDisable ms-2"} disabled={!inputTag} onClick={handleAddTag}>Add New</button>
                                            </div>
                                            </div>
                                            {/* <div>
                                                <button>Add Tag</button>
                                            </div> */}

                                            <div className='p-3 mt-3'>
                                                <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center border-dashed border-2 gap-2 ${formErrors?.pdf && 'border-red-500'}`}>
                                                    <span className="text-2xl font-semibold mb-4 text-center w-full block">
                                                        {progress1Loading === 'noprogress' && 'Upload New Document'}
                                                        {progress1Loading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload PDF File </span>}
                                                        {progress1Loading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Document...</span>}
                                                        {progress1Loading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Uploaded</span>}
                                                    </span>

                                                    <div className="w-60 flex justify-center" onDrop={pdfDropHandler} onDragOver={dragOverHandler}>
                                                        <span style={{ width: '45px' }} className={!data?.pdf?.name ? 'un_selected' : ''} >
                                                            <img src="assets/images/pdf.svg"></img>
                                                        </span>
                                                    </div>

                                                    {data?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={data?.pdf?.name} >{data?.pdf?.name?.length > 14 ? data?.pdf?.name?.substring(0, 14) + "..." : data?.pdf?.name}{!isLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClear('pdf')} />}</span>}
                                                    {progress1Loading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                                                    {progress1Loading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                                                        {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                                                    </p>
                                                        <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                                                            <input
                                                                type="file"
                                                                accept="application/pdf"
                                                                onChange={handleFileChangepdf}
                                                                className="mb-4"
                                                                hidden
                                                            />
                                                            Upload
                                                            <img src="assets/images/icon_upload.svg" />
                                                        </label>
                                                    </>
                                                    }
                                                    {progress1Loading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                                                        <div
                                                            className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                                                        ></div>
                                                    </div>}
                                                </div>
                                                    <span className='numberRequestModal' style={{ color: formErrors?.pdf ? 'red' : 'inherit' }}>{formErrors?.pdf}</span>
                                            </div>

                                            <div className='flex justify-end mt-3 mb-1'>
                                                <div className='flex justify-center align-middle me-2'>
                                                    <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2' onClick={closeModal}>Cancel</button>
                                                </div>
                                                <div className='flex justify-center align-middle me-3'>
                                                    <button disabled={requestSubmitLoader} type="submit" className=' relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2' onClick={handleFileUpload}>
                                                        Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!isMobileDevice ? (
                        <FolderDetailsTable 
                            columns={phoneTableHeader} 
                            rowData={filterData} 
                            tableLoading={dataLoading} 
                            nPages={nPages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            getLoanStorageDocuments={getLoanStorageDocuments} 
                            handleRename={handleRename}
                            handleLinkClick={handleLinkClick}
                            handleDelete={handleDelete}
                            openFolderRequest={openFolderRequest}
                            setShowOptionsModal={setShowOptionsModal}
                            showOptionsModal={showOptionsModal}
                            selectedRow={selectedRow}
                            ref={ref}
                        />
                        // <DataTable columns={phoneTableHeader} rowData={filterData} getLoanStorageDocuments={getLoanStorageDocuments} tableLoading={dataLoading}/>
                    ) : (
                        <div>
                            {currentRecords?.length > 0 ? (
                                <>
                                    <div>
                                        {currentRecords?.map((item, index) => (
                                            <div key={`block_${index}`} className="mt-3 border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Document Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.document_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Document Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.document_type}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Uploaded By</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.User?.full_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Uploaded At</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.uploaded_at}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Action</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>
                                                            <div className="py-2 options-cell" style={{ fontSize: '16px' }}>
                                                                <button onClick={() => openFolderRequest(item, index)}>
                                                                    <img src={details} width={16} height={16} />
                                                                </button>
                                                                {showOptionsModal && selectedRow === item && (
                                                                    <div className="options-modal" ref={ref}>
                                                                        <button className='text-black border-b flex' onClick={() => handleRename(item)}> <img src={renameIcon} alt="Rename" className="button-icon me-1 pt-1 pb-2" /> Rename</button>
                                                                        <button className='text-red-500 flex' onClick={() => handleDelete(item)}> <img src={deleteIcon} alt="Rename" className="button-icon me-1 pt-1" /> Delete</button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="reponsivePagination">
                                        <Pagination 
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                        ></Pagination>
                                    </div>
                                </>
                            ) : (
                                <div className="noDataFound">
                                    <img
                                        src='/assets/noData.svg'
                                        alt="no-data"
                                        className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                    />
                                    <div className="text-xl font-semibold">
                                        No data found
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </div>
    );
};

export default WithAuth(ViewFolderDetails);