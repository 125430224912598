import { useEffect, useState } from "react";
import Header from "../../component/Header";
import SideMenu from "../../component/SideMenu";
import ErrorCard from "../../component/core-component/ErrorCard";
import API from "../../helpers/api";
import { useLocation } from 'react-router-dom';


const AccountDetail = ({querry, handleBack}) => {
    const [apiData, setApiData] = useState({
        task_id: '',
        start_date: '',
        account_data_scrapped_at: '',
        nid_verified_at: '',
        account_updated_at: '',
        image_data: '',
    });
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [paramsQuery, setParamsQuery] = useState();
    const [error, setError] = useState()

    async function handleErrorDetail() {
            // const params = new URLSearchParams(window.location.search);
            // let query ={
            //     task_type:params.get('task_type'),
            //     id: params?.get('id')
            // } 
            // setParamsQuery(query)
        try {
            const response = await API.apiGet(`/screenshot/task/errors`,'',{id: querry?.id, task_type: querry?.task_type})
            let taskErrorResponse = response?.data?.data;
            if(response?.status === 200) {
                setApiData((prev) => (
                    {
                        ...prev,
                        start_date: taskErrorResponse?.start_date ? taskErrorResponse?.start_date : querry?.startDate,
                        task_id: taskErrorResponse?.task_id,
                        image_data: taskErrorResponse?.image_data
                    }
                )
                );
                setLoading(true);
            }
            else{
                setApiData([])
            }
        }
        catch (error) {
            console.log(error)
            setError("404")
            setLoading(true)
        }
    }

    const getAccountDetails = async() => {
        try {
            const response = await API.apiGet(`/tasks/${querry?.id}`)
            if(response?.data?.status === true) {
                let taskData = response?.data?.data?.task_data?.process_status
                setApiData((prev) => ({
                  ...prev,
                  account_data_scrapped_at: taskData?.account_data_scrapped_at,
                  nid_verified_at: taskData?.nid_verified_at,
                  account_updated_at: taskData?.account_updated_at
                }))
            }
        }
        catch (error) {
            console.log('error', error);
            setError("404");
            setLoading(true);
        }  
    }

    useEffect(() => {
        handleErrorDetail();
        getAccountDetails();
    }, [])

     
    return (
        <>
            <div>
                <div className="overflow-y-scroll h-screen mt-5 p-8 pt-6">
                    <div className="">
                        <div>{loading &&
                            <ErrorCard apiData={apiData} paramsQuery={querry} error={error} handleBack={handleBack}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountDetail;