// Breadcrumb.js
import React from "react";
import Icon from "../../component/core-component/Icon";
import { useNavigate } from "react-router";
import { useState} from "react";
import { toast} from "react-toastify";
import "../../styles/infoChangeRequest.scss"
const UpdateLimitModal = () => {
  const [progress1Loading, setProgress1Loading] = useState("noprogress");
  const [paperForm, setPaperForm] = useState(0);
  const [paperFormLoading, setPaperFormLoading] = useState(false);
  const [progress2Loading, setProgress2Loading] = useState("noprogress");
  const [cashWithdrawn, setCashWithdrawn] = useState(0);
  const [cashWithdrawnLoading, setCashWithdrawnLoading] = useState(false);
  const isMobileDevice = window.screen.width <= 900;
  const handlePaperForm = (event, isDragged) => {
    setProgress1Loading("noprogress");
    const pdf = isDragged
      ? event.dataTransfer?.files[0]
      : event.target.files[0];
    if (pdf) {
        setProgress1Loading("uploading");
      setTimeout(() => {
        setProgress1Loading("uploaded");
        setPaperForm({ ...paperForm, pdf });
      }, 3000);
    } else {
      event.target.value = "";
      setPaperForm({ ...paperForm, pdf: "" });
      toast.error("Please select an image.");
    }
  };
  const paperFormDropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handlePaperForm(e, true);
  };
  const paperFormDragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClearPaperForm = (filetype) => {
    setPaperForm({ ...paperForm, [filetype]: "" });
    setProgress1Loading("noprogress");
  };

  const handleCashWithdrawn= (event, isDragged) => {
    setProgress2Loading("noprogress");
    const pdf = isDragged
      ? event.dataTransfer?.files[0]
      : event.target.files[0];
    if (pdf) {
        setProgress2Loading("uploading");
      setTimeout(() => {
        setProgress2Loading("uploaded");
        setCashWithdrawn({ ...cashWithdrawn, pdf });
      }, 3000);
    } else {
      event.target.value = "";
      setCashWithdrawn({ ...cashWithdrawn, pdf: "" });
      toast.error("Please select an image.");
    }
  };

  const cashWithdrawnDropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleCashWithdrawn(e, true);
  };
  const cashWithdrawnDragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClearCashWithdrawn = (filetype) => {
    setCashWithdrawn({ ...cashWithdrawn, [filetype]: "" });
    setProgress2Loading("noprogress");
  };

  return (
    <div className={`mt-5 flex justify-between ${isMobileDevice ? 'flex-wrap' : ''}`}>
      <div
        className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 ${isMobileDevice ? 'w-full' : 'limitDataFileUploader uploadCard'}`}
      >
        <span className="text-2xl font-semibold mb-4 text-center w-full block">
          {progress1Loading === "noprogress" && "Upload Paper Form"}
          {progress1Loading === "noprogress" && (
            <span className="text-center text-sm w-full block mt-1 dragtext">
              {" "}
              Upload in a Image Format{" "}
            </span>
          )}
          {progress1Loading === "uploading" && (
            <span className="flex w-full justify-center items-center gap-2">
              Uploading Paper Form...
            </span>
          )}
          {progress1Loading === "uploaded" && (
            <span className="flex w-full justify-center items-center gap-2">
              <img
                src="assets/images/double_tick.svg"
                style={{ height: "15px" }}
              />
              Paper Form Uploaded
            </span>
          )}
        </span>

        <div
          className="w-60 flex justify-center"
          onDrop={paperFormDropHandler}
          onDragOver={paperFormDragOverHandler}
        >
          <span
            style={{ width: "45px" }}
            className={!paperForm?.pdf?.name ? "un_selected" : ""}
          >
            <img src="assets/images/pdf.svg"></img>
          </span>
        </div>

        {paperForm?.pdf?.name && (
          <span
            className="text-center text-sm w-full block mt-1 blue-color relative"
            title={paperForm?.pdf?.name}
          >
            {paperForm?.pdf?.name?.length > 14
              ? paperForm?.pdf?.name?.substring(0, 14) + "..."
              : paperForm?.pdf?.name}
            {!paperFormLoading && (
              <img
                src="/assets/images/cross.png"
                className="crossIconFolder absolute cursor-pointer"
                onClick={() => handleClearPaperForm("pdf")}
              />
            )}
          </span>
        )}
        {progress1Loading === "noprogress" && (
          <span className={`text-center text-sm w-full block mt-1 dragtext`}>
            Drag and Drop Files here
          </span>
        )}

        {progress1Loading === "noprogress" && (
          <>
            {" "}
            <p className="text-center flex w-full justify-center items-center light-grey">
              {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
            </p>
            <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
              <input
                type="file"
                accept="image/*"
                onChange={handlePaperForm}
                className="mb-4"
                hidden
                multiple
              />
              Upload
              <img src="assets/images/icon_upload.svg" />
            </label>
          </>
        )}
        {progress1Loading === "uploading" && (
          <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
            <div className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"></div>
          </div>
        )}
      </div>
      <div
        className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 ${isMobileDevice ? 'w-full mt-6' : 'limitDataFileUploader uploadCard'}`}
      >
        <span className="text-2xl font-semibold mb-4 text-center w-full block">
          {progress2Loading === "noprogress" && "Upload Supporting Documents"}
          {progress2Loading === "noprogress" && (
            <span className="text-center text-sm w-full block mt-1 dragtext">
              {" "}
              Upload in a Image Format{" "}
            </span>
          )}
          {progress2Loading === "uploading" && (
            <span className="flex w-full justify-center items-center gap-2">
              Uploading Supporting Documents...
            </span>
          )}
          {progress2Loading === "uploaded" && (
            <span className="flex w-full justify-center items-center gap-2">
              <img
                src="assets/images/double_tick.svg"
                style={{ height: "15px" }}
              />
              Supporting Documents Uploaded
            </span>
          )}
        </span>

        <div
          className="w-60 flex justify-center"
          onDrop={cashWithdrawnDropHandler}
          onDragOver={cashWithdrawnDragOverHandler}
        >
          <span
            style={{ width: "45px" }}
            className={!cashWithdrawn?.pdf?.name ? "un_selected" : ""}
          >
            <img src="assets/images/pdf.svg"></img>
          </span>
        </div>

        {cashWithdrawn?.pdf?.name && (
          <span
            className="text-center text-sm w-full block mt-1 blue-color relative"
            title={cashWithdrawn?.pdf?.name}
          >
            {cashWithdrawn?.pdf?.name?.length > 14
              ? cashWithdrawn?.pdf?.name?.substring(0, 14) + "..."
              : cashWithdrawn?.pdf?.name}
            {!cashWithdrawnLoading && (
              <img
                src="/assets/images/cross.png"
                className="crossIconFolder absolute cursor-pointer"
                onClick={() => handleClearCashWithdrawn("pdf")}
              />
            )}
          </span>
        )}
        {progress2Loading === "noprogress" && (
          <span className={`text-center text-sm w-full block mt-1 dragtext`}>
            Drag and Drop Files here
          </span>
        )}

        {progress2Loading === "noprogress" && (
          <>
            {" "}
            <p className="text-center flex w-full justify-center items-center light-grey">
              {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
            </p>
            <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
              <input
                type="file"
                accept="image/*"
                onChange={handleCashWithdrawn}
                className="mb-4"
                hidden
                multiple
              />
              Upload
              <img src="assets/images/icon_upload.svg" />
            </label>
          </>
        )}
        {progress2Loading === "uploading" && (
          <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
            <div className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateLimitModal;
