import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import API from "../helpers/api/index";
// import Select from 'react-select';
import Icon from "./core-component/Icon";
import { ColorRing } from 'react-loader-spinner';
import styles from '../styles/allowPermissionsModal.module.scss';
import camera from '../assets/images/camera.svg';
import microPhone from '../assets/images/microphone.svg';
import memoImg from '../assets/images/memo-check.svg';
import { useLocation, useNavigate } from "react-router";
import { Select, Space } from "antd";


export default function AllowPermissionModal() {

const [checkToggle,setCheckToggle] = useState(false);
const [selectedLanguage, setSelectedLanguage] = useState('Bangla');
const navigate = useNavigate();
const location = useLocation();
function checkedBtn(){
    setCheckToggle(!checkToggle);
}
async function getKycID() {
    const params = new URLSearchParams(location.search);
    return params.get('query') || '';
}
async function continueToKYC() {
            let id = await getKycID()
            navigate(`/record?query=${id}`)
}
function handleCancel() {
    navigate(`/otpScreen`)
}

function handleChangeLanguage(val) {
    setSelectedLanguage(val)
}

    return(
        <div className="min-h-screen instructionScreen w-full p-4 flex items-center justify-center">
            <div className={` ${styles.instructionScreenCnt} p-8 border shadow-lg rounded-lg bg-white`}>
                <div className="border-b border-gray-300 pb-3">
                    <h1 className={`${styles.allowPara}`}>
                        Allow All Permissions
                    </h1>
                    <p className={`${styles.kindlyPara}`}>
                        Before commencing the video KYC process, kindly grant all necessary permissions and carefully review all provided instructions.
                    </p>
                    {/* <p className={`${styles.kindlyPara}`}>
                        
                    </p>
                    <p className={`${styles.kindlyPara}`}>
                        carefully review all provided instructions.
                    </p> */}
                </div>

                <div className="pt-4">
                    <div className="flex">
                        <div className={`rounded-full ${styles.cameraIcon}`}>
                            <img src={camera}></img>
                        </div>
                        <div className="ps-4">
                            <p className={`${styles.cameraHead}`}>Allow Camera Access</p>
                            <p className={`${styles.cameraPara}`}>AIW would like to access your camera for further KYC process</p>
                            {/* <p className={`${styles.cameraPara}`}>KYC process</p> */}
                        </div>
                    </div>
                </div>

                <div className="pt-4 border-b border-gray-300 pb-3">
                    <div className="flex">
                        <div className={`rounded-full ${styles.cameraIcon}`}>
                            <img src={microPhone} />
                        </div>
                        <div className="ps-4">
                            <p className={`${styles.cameraHead}`}>Allow Microphone Access</p>
                            <p className={`${styles.cameraPara}`}>AIW would like to access your microphone for further KYC process</p>
                            {/* <p className={`${styles.cameraPara}`}></p> */}
                        </div>
                    </div>
                </div>

                <div className="pt-4">
                    <div className="">
                        <div className="flex justify-between">
                            <h3 className={`${styles.instructionHead}`}>
                                Instructions
                            </h3>
                            <Space wrap>
                                <Select 
                                    defaultValue={'Bangla'}
                                    style={{
                                        width: 120,
                                        outline: 'none',
                                    }}
                                    options={
                                        [
                                            { value: 'Bangla', label: <span className="flex gap-2"><img width={15} src="/assets/images/language.svg"/>Bangla</span> },
                                            { value: 'English', label: <span className="flex gap-2"><img width={15} src="/assets/images/language.svg"/>English</span> },
                                        ]} 
                                    onChange={handleChangeLanguage}
                                />
                            </Space>
                        </div>
                        {/* <h3 className={`${styles.instructionHead}`}>
                            proceeding for KYC
                        </h3> */}
                        <div className="flex">
                            <div className="w-1/3 flex justify-center">
                                <img src={memoImg}/>
                            </div>
                            { selectedLanguage === 'Bangla' ?
                            <div className="ps-2 pt-3 leading-7">
                                    <p className={`${styles.banglaInstructions}`}>
                                        1. "শুরু করতে": ভিডিও রেকর্ডিং শুরু করতে স্টার্ট বোতামে ক্লিক করুন।
                                    </p>
                                    {/* <p className={`ps-4 ${styles.banglaInstructions}`}>
                                        বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.banglaInstructions}`}>
                                    2. "বন্ধ করতে": ভিডিও রেকর্ডিং শেষ করতে স্টপ বোতামে ক্লিক করুন।
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.banglaInstructions}`}>
                                        3. "প্রিভিউ করার জন্য": রেকর্ড করা ভিডিও আবার দেখতে চেলে প্রিভিউ বোতামে ক্লিক করুন।
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        দেখতে চেলে প্রিভিউ বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.banglaInstructions}`}>
                                    4. "আপলোড করতে": ভিডিওটি জমা দিতে এবং আপলোড করতে আপলোড বোতামে ক্লিক করুন
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        আপলোড করতে আপলোড বোতামে ক্লিক করুন
                                    </p> */}
                                    <p className={`${styles.banglaInstructions}`}>
                                    5. "রিটেক করতে": আরেকটি ভিডিও রেকর্ডিং নিতে রিটেক বোতামে ক্লিক করুন।
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        রিটেক বোতামে ক্লিক করুন।
                                    </p> */}
                            </div>
                            :
                            <div className="ps-2 pt-3 leading-7">
                                    <p className={`${styles.englishInstructions}`}>
                                        1. "To start": Click on the start button to begin video recording.
                                    </p>
                                    {/* <p className={`ps-4 ${styles.banglaInstructions}`}>
                                        বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.englishInstructions}`}>
                                        2. "To stop": Click on the stop button to end video recording.
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.englishInstructions}`}>
                                        3. "To preview": Click on the preview button to see the recorded video again.
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        দেখতে চেলে প্রিভিউ বোতামে ক্লিক করুন।
                                    </p> */}
                                    <p className={`${styles.englishInstructions}`}>
                                        4. "To upload": Click on the upload button to submit the video and upload it.
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        আপলোড করতে আপলোড বোতামে ক্লিক করুন
                                    </p> */}
                                    <p className={`${styles.englishInstructions}`}>
                                        5. "To retake": Click on the "Record Again" button to capture another video recording.
                                    </p>
                                    {/* <p className={` ps-4 ${styles.banglaInstructions}`}>
                                        রিটেক বোতামে ক্লিক করুন।
                                    </p> */}
                            </div>
                            }
                        </div>

                            <div className="ps-2 pt-4">
                                <input id="checkbtn" type="checkbox" onClick={checkedBtn}></input>
                                <label for="checkbtn" className="ms-3">I have read all the instructions above</label>
                            </div>

                            <div className="flex pt-6 ps-2">
                                <button className={`${styles.cancelBtn}`} onClick={handleCancel}>Cancel</button>
                                <button className={`${styles.continueBtn} ms-3 ${checkToggle ? '' : styles.disabled}`} disabled={!checkToggle} onClick={continueToKYC}>Continue to KYC</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
