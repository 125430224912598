// Import necessary libraries and styles
import React, { useEffect, useState } from "react";
import {
  CustomTextInput,
  Image,
  PrimaryCta,
} from "../../component/core-component";
import WebStorage from "../../common/webStorage";
import { USER_DATA_KEY } from "../../common/webStorageKeys";
import { useForm } from "react-hook-form";
import CarouselComponent from "../../component/CarouselComponent";
import { Router, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ERRORLIST from "../../common/errorList";
import { ToastContainer, toast } from 'react-toastify';
import API from "../../helpers/api/index";
import { getCurrentUserMenus } from "../../common/menuItems";
import { getCookie, getDecodedToken } from "../../helpers/auth";
import styles from "../../styles/common.module.scss"
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import { Button } from "antd";
import axios from "axios";
import bankAsiaLogo from "../../assets/images/bankAsiaLogo.svg";
import bankingImg from "../../assets/images/bankingImg.svg"
import { defaultTheme } from "../../theme/defultTheme";
import _ from "lodash";
import ServicesDownPopup from "../../component/ServicesDownPopup";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email(ERRORLIST.EMAIL_REQUIRED.message)
    .required("Please enter an email"),
  password: yup
    .string()
    .required("Please enter a password")
    // check minimum characters
    .min(8, "Password must have at least 8 characters")
    // different error messages for different requirements
    .matches(/[0-9]/, "Password must have at least 1 digit")
    .matches(/[a-z]/, "Password must have at least 1 lowercase")
    .matches(/[A-Z]/, "Password must have at least 1 uppercase")
    .matches(/[!@#$%^&*()_+\-=\[\]{};:'",.<>\/\?|\\]/, "Password must have at least 1 spacial character"),
});

const LoginScreen = function (props) {
  const data = {
    slides: [
      // {
      //   src: "/assets/images/loginImg/1684959023100.jpeg",
      //   alt: "Image 1 for carousel",
      // },
      // {
      //   src: "/assets/images/loginImg/gettyimages-1368593225.jpg",
      //   alt: "Image 1 for carousel",
      // },
      // {
      //   src: "/assets/images/loginImg/digital-banking-FinTech-Red-Hat-1.jpg",
      //   alt: "Image 2 for carousel",
      // },
      // {
      //   src: "/assets/images/loginImg/Digital-banking-scaled.jpg",
      //   alt: "Image 3 for carousel",
      // },
      {
        src: "/assets/images/loginImg/digital_banks_2-scaled.jpg",
        alt: "Image 4 for carousel",
      },
    ],
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const [ssoUserToken, setSSOUserToken] = useState('');
  const [ssoUserData, setSSOUserData] = useState('');
  const [responseScreenWidth, setResponseScreenWidth] = useState();
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);

  useEffect(() => {
    let viewportHeight = window.screen.width;
    setResponseScreenWidth(viewportHeight > 850)

    const urlParams = new URLSearchParams(window.location.search);
    let userDataFromParams = urlParams.get('userData');
    let tokenFromParams = urlParams.get('token');
    let userNotfound = urlParams.get('userNotfound');
    if (userNotfound) {
      toast.error('User not found');
      return;
    }
    if(tokenFromParams) {
      setSSOUserToken(tokenFromParams);
    }
    if(userDataFromParams) {
      userDataFromParams = JSON.parse((userDataFromParams));
      setSSOUserData(userDataFromParams);
      let payload = {
        email: userDataFromParams?.mail || userDataFromParams?._json?.email,
        isSSOLogin: true
      }
      handleSuccessLogin(payload)
    }
    const handleResize = () => {
      let viewportHeight = window.innerWidth > 850;
      setResponseScreenWidth(viewportHeight);
    }
    window.addEventListener('resize', handleResize);
  }, [])

  var navigate = useNavigate();
  var redux_dispatch = useDispatch();

  const isMobileDevice = window.screen.width < 768;

  async function getUserDetails() {
    try {
      const { user_id } = getDecodedToken()
      const response = await API.apiGet(`/users/profile?user_id=${user_id}`)
      if (response?.status) {
        const userProfile = response?.data?.data;
        redux_dispatch({
          type: Actions.UPDATE_USER_DATA,
          payload: { user_data: { ...userProfile } },
        });
        localStorage.setItem('perm', JSON.stringify(userProfile?.Permissions));
        sessionStorage.setItem('userData', JSON.stringify(userProfile));
        localStorage.setItem('sidebarOpen', true);
      }
      else {
        toast.error(response?.message)
      }
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
    }
  }

  function loginWithGoogle() {
    // navigate('/auth/google')
    window.open(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/auth/google`,"_self")
}
  async function microsoftAuth() {
    try {
      // window.open('http://localhost:8000/api/users/auth/microsoft',"self")
      window.location.href = `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/users/auth/microsoft`;
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
    }
  }


  const handleSuccessLogin = async (userData) => {
    try {
    let payload = userData
    payload.last_login_location = {
      ip_address: '',
    }
      let ipAddress = await axios.get(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/get-ip`);
      if (ipAddress) {
        payload.last_login_location.ip_address = ipAddress?.data?.ip;
      }
      const encodedPayload = encodeData(payload);
      const {data} = await API.apiPost(`/users`, {payload: encodedPayload})
      if (data.success) {
        redux_dispatch({
          type: Actions.UPDATE_USER_DATA,
          payload: { user_data: data?.userData },
        });
        let token = data?.token
        WebStorage.saveDataInWebStorage(USER_DATA_KEY, JSON.stringify(data?.userData));
        WebStorage.saveDataInWebStorage('accessToken',token)
        await getUserDetails();
        let menus = getCurrentUserMenus();
        getThemeDeatils();
        if (menus?.[0].title == 'My Task') {
          let routingMenu = menus?.find(item => item?.title == 'Processes')
          if (!_.isEmpty(routingMenu)) {
            navigate(routingMenu?.children?.[0]?.navlink);
          } else {
            if (menus?.[0]?.children?.length > 0) {
              navigate(menus?.[0]?.children?.[0]?.navlink);
            } else {
              navigate(menus?.[0]?.navlink);
            }
          }
        } else {
          if (menus?.[0]?.children?.length > 0) {
            navigate(menus?.[0]?.children?.[0]?.navlink);
          } else {
            navigate(menus?.[0]?.navlink);
          }
        }
      }
    } catch (error) {
      handleErrorMessage(error);
      console.error("Error fetching data:", error);
    }
  }

  const getlogo = async(themeData) => {
    try {
        if (themeData?.logo) {
            const { data } = await API.apiGet(`/theme/logo?logoURL=${themeData?.logo}&theme_id=${themeData?.id}`);
            if (data.success) {
              redux_dispatch({
                type: Actions.SET_USER_THEME,
                payload: { themeDetails: { ...themeData, logo: data.data } },
              });
              redux_dispatch({type: Actions.GET_TEHME_DATA});
            }
        }
    } catch (error) {
        console.log('error', error);
    }
  }

  const getThemeDeatils = async() => {
    try {
        // setThemeLoading(true);
        const { data } = await API.apiGet(`/theme/details`);
        if (data?.success) {
            // setThemeLoading(false);
            let themeDetails = data.data != null ? data.data : defaultTheme
            getlogo(themeDetails);
            redux_dispatch({
              type: Actions.SET_USER_THEME,
              payload: { themeDetails: { ...data.data } },
            });
        } else {
            // setThemeLoading(false);
            redux_dispatch({
                type: Actions.SET_USER_THEME,
                payload: { themeDetails: { ...defaultTheme } },
            });
        }
    } catch (error) {
        // setThemeLoading(false);
        console.log('error in get theme deatils >>>>', error)
    }
  }

  const handleLogin = async (userData) => {
    handleSuccessLogin(userData);
  };

  const handleForgotPassword = () => {
    navigate('/verifyEmail');
  }

  return (
    <div className={`flex items-center w-full h-screen bg-gradient-to-r from-[#F7FBFF] to-[#F7FFFD] dark:bg-dark-background overflow-scroll ${styles.mainLoginCompnent}`}>
      <div className={`flex-1 flex items-center justify-center w-1/2 ${styles.LogInInput}`}>
        <div className={`w-full ${styles.inputOuter} ${isMobileDevice && 'p-5'}`}>
          <div className="flex items-center justify-center">
            <Image
                source={"/assets/images/logo.png"}
                height={123}
                width={179}
                alt="Logo"
              />
          </div>
          <h1 className={`text-black font-quicksand text-3xl font-bold mt-6 text-center ${styles.uhoversparkle}`} style={{color: "#441151"}}>
            Login to your account
          </h1>
          
          <form onSubmit={handleSubmit(handleLogin)} className="mt-4">
            <CustomTextInput
              register={register}
              label="Email"
              name="email"
              placeholder="Enter email"
              errors={errors}
            />
            <div className="mt-6">
              <CustomTextInput
                register={register}
                label="Password"
                name="password"
                placeholder="Enter password"
                type="password"
                errors={errors}
              />
            </div>
            <div className={`underline text-end pt-2 cursor-pointer text-blue-500 ${styles.forgetPasswordLink}`} onClick={handleForgotPassword}>Forgot Password?</div>
            <div className="mt-6 continueLogin">
            {/* <button><a href='/users/auth/google'>Login With Google</a></button> */}
              <PrimaryCta
                text="Log In"
                labelClassName=""
                type="Submit"
                buttonClassName="w-full"
              />
            </div>
          </form>
            <div className={`flex mt-4 ${styles.socialSigninButtons}`}>
              <Button className={`w-full me-1 flex justify-center py-4.5 items-center ${styles.googleButton}`} loading={false} onClick={ loginWithGoogle } >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                  <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                </svg>
                <span className='button_text ms-2'>Continue with Google </span>
              </Button>
              <Button className={`${styles.microsoftLoginBtn} ms-1 w-full flex justify-center py-4.5 items-center`} loading={false} onClick={microsoftAuth}>
                <img className="w-5 h-5" src="/assets/images/Microsoft_Logo.png"/>
                <span className='button_text ms-2'>Continue with Microsoft</span>
              </Button>
            </div>
          <div className={`text-center sm:py-5 py-7 sm:mb-0 mb-3 flex flex-col items-center ${styles.poweredByLogo}`}>
            <p className="text-sm">
              Powered By
            </p>
            <Image
            source={"/assets/images/logo.png"}
            height={70}
            width={70}
            alt="Logo"
          /> 
          </div>
        </div>
      </div>
     {responseScreenWidth && <div className={`flex flex-col w-1/2 relative ${styles.bankAsia}`}>
        {/* <CarouselComponent data={data.slides} /> */}
        <div>
          <div className='flex justify-end'>
            <img src={bankAsiaLogo}></img>
          </div>
          <div className="p-6">
            <div>
              <span className={`${styles.onlinePara}`}>Smart</span>
              <span className={`${styles.bankingPara}`}>Banking Portal </span>
            </div>
            {/* <div>
              <span className={`${styles.welcomePara}`}>Welcome,</span>
              <span className={`${styles.bankAsiaPara}`}> </span>
            </div> */}
            <div className={`${styles.designPara}`}>
              Welcome, Bank Asia Agents!
            </div>
            <div className={`${styles.attractivePara}`}>
              Securely access your portal to ease banking experience.
            </div>
          </div>
          <div className={`${styles.bankingLogo}`}>
            <img className="w-full" src={bankingImg}></img>
          </div>
        </div>
      </div>}
      <ToastContainer/>
    </div>
  );
};

export default LoginScreen;
