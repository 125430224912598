import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";
export default function WrittenOfPortfolio() {
  var navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;

  return (
    <div onClick={() => navigate("/writtenOfPortfolio")} className={`cursor-pointer commonCard samdProcessCards bg-white pb-3 ${isMobileDevice && 'responsiveSamdCard'}`}>
      <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
        <div>
          <img src="assets/files.svg" alt="file" />
        </div>
        <span className="font-semibold">
          Written Off Portfolio
        </span>
        <div onClick={() => navigate("/writtenOfPortfolio")} className="cursor-pointer arrowIcon commonCardIcons">
          <img src="assets/right-arrow.svg" alt="file" />
        </div>
      </div>
      <div>
        <p className="p-4">
        This process aims at maintaining accurate ledger transactions by formally acknowledging and recording bad loans within the financial portfolio.
        </p>
      <div className="float-end px-4 createdDate">
        <p>
          Created on 24 Mar, 2024
        </p>
      </div>
      </div>
    </div>
  );
}