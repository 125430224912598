import React, { useEffect, useRef, useState } from "react";
import Icon from "./Icon.js";
import Styles from "./Style.js";
import { NavLink } from "react-router-dom";
import { logout } from "../../helpers/auth.js";
import styles from "../../styles/common.module.scss";
//import Theme from '../../resources/themes/light.js'

export const Image = function (props) {
  return (
    <div>
      <img
        className={props.className}
        onClick={props.onClick}
        style={props.style}
        alt={props.alt}
        src={props.source}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export const CustomTextInput = function (props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {props.label && (
        <label className="text-black font-quicksand text-base font-medium leading-6">
          {props.label}
        </label>
      )}
      <div className="flex flex-col relative w-full">
        <div className="relative flex flex-col">
          <input
            {...props}
            className={`w-full h-12 p-2 border-b ${styles.inputBackground} focus:outline-none bg-white`}
            name={props.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
            type={showPassword ? "text" : props.type} // Toggle between 'password' and 'text'
            {...props.register(props.name)}
          />
          <span className={`${styles.borderSpan}`}></span>
          {props.type === "password" && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className=" bi bi-eye absolute top-1/2 right-4 ml-2 transform -translate-y-1/2 focus:outline-none"
            >
              {showPassword ? (
                <Icon iconName="EyeAlt" height={16} width={16} />
              ) : (
                <Icon iconName="EyeAlt" height={16} width={16} />
              )}
            </button>
          )}
        </div>
        {props.errors[props.name] && (
          <p className="text-red-500">{props.errors[props.name].message}</p>
        )}
      </div>
    </div>
  );
};

export const PrimaryCta = function (props) {
  const buttonClasses = `py-2 px-6 rounded-lg text-white ${
    props.buttonClassName || ""
  } ${styles.loginBtn} text-white  hover:bg-opacity-75 focus:outline-none focus:ring focus:border-blue-300 lg:min-w-36`;

  const labelClasses = `${
    props.labelClassName || ""
  } text-white text-center lining-nums proportional-nums font-quicksand text-lg font-bold leading-6 tracking-wider`;

  return (
    <button
      className={buttonClasses}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {!props.isLoading ? (
        <div className="flex justify-center">
          {props.iconName && (
            <div style={{ marginTop: "4px", marginRight: "6px" }}>
              <Icon iconName={props.iconName} height={12} width={12} />
            </div>
          )}

          <p className={labelClasses}>{props.text}</p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 2,
            color: "white",
            fontSize: 15,
          }}
        >
          <i className="fa fa-circle-o-notch fa-spin"></i> hi...
        </div>
      )}
    </button>
  );
};

export const LinkCta = function (props) {
  return (
    <button onClick={props.onClick}>
      <div>
        <h6 className="text-blue-800 text-inter text-base font-normal leading-[175%]">
          {props.text}
        </h6>
      </div>
    </button>
  );
};

export const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  useEffect(()=>{
    document.addEventListener( "click" , (event) => {
      if(dropDownRef.current && props.dropDownToggleRef  &&  !dropDownRef.current.contains(event.target) && !props.dropDownToggleRef.current.contains(event.target) ) {
        setIsOpen(prev=>(prev===true)?false:prev);
        props.setToggleDropdown(prev=>(prev===true)?false:prev);
      }
    } )
  },[]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleUpdatePasswordClick = () => {
    props.setShowModal(true)
    toggleDropdown()
  }

  return (
    <div className="relative" ref={dropDownRef}>
      <button onClick={toggleDropdown} className="flex justify-center">
        {props.text && props.text}
        {props.iconName && (
          <div className={`duration-500 ${(isOpen || props.openDropdown) && "rotate-[180deg]"}`}>
            <Icon iconName={props.iconName} height={12} width={12} />
          </div>
        )}
      </button>

      {(isOpen || props.openDropdown) && (
        <div className="z-10 absolute flex mt-5 bg-white p-4 gap-10 border-t border-l border-r border-b border-solid border-gray-300 rounded-b-6 right-0">
          {/* Dropdown content */}
          <ul>
            <li>
              <NavLink
                onClick={logout}
                className="flex flex-1 items-center py-2 min-w-48"
              >
                <div className="px-2">
                  <Icon iconName="LogOut" height={16} width={16} />
                </div>

                <span className="font-quicksand font-semibold text-sm leading-5 ml-2.5">
                  Log Out
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={handleUpdatePasswordClick}
                className="flex flex-1 items-center py-2 min-w-48"
              >
                <div className="px-2">
                  <Icon iconName="key" height={16} width={16} />
                </div>

                <span className="font-quicksand font-semibold text-sm leading-5 ml-2.5">
                  Update Password
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export const CustomCheckBox = function (props) {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={props.id}
        checked={props.checked}
        onChange={props.onChange}
        className={Styles.CustomCheckBox}
      />
      {props.label && (
        <label htmlFor={props.id} className="text-gray-700">
          {props.label}
        </label>
      )}
    </div>
  );
};

export const ToggleSwitch = (props) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-black dark:text-white">Dark</span>
      <label
        htmlFor="toggle-switch"
        className="flex items-center toggle-label w-12 h-6 bg-blue-500 rounded-full cursor-pointer relative"
      >
        <input
          type="checkbox"
          id="toggle-switch"
          className="hidden"
          checked={props.checked}
          onChange={props.onChange}
        />
        <div
          className={`w-5 h-5 rounded-full bg-white absolute transition-transform duration-300 ${
            props.checked
              ? "transform translate-x-7"
              : "transform translate-x-0"
          }`}
        ></div>
      </label>
      <span className="text-black  dark:text-white">Light</span>
    </div>
  );
};

export const SearchBox = ({ placeholder, onChange }) => {
  return (
    <div className="flex items-center">
      <div className="relative flex items-center border border-solid border-gray-400 rounded-[10px] w-full">
        <div className="absolute left-0 px-3 flex items-center pointer-events-none">
          <Icon iconName="SearchIcon" height={24} width={24} />
        </div>
        <input
          type="text"
          placeholder={placeholder}
          className="py-3 px-[14px] w-full rounded-[10px] focus:outline-none ml-8"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const FilterButton = ({ label, onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center border border-solid border-gray-400 rounded-[10px] py-3 px-[14px] w-32 focus:outline-none ${
        active ? "text-white" : " text-gray-700"
      }`}
    >
      <div className="relative flex items-center justify-center">
        <div className="pointer-events-none">
          <Icon iconName="FilterIcon" height={24} width={24} />
        </div>
        <span className="ml-2 text-[#111314EB] font-lining-nums font-proportional-nums font-quicksand text-16 font-normal font-semibold leading-24 tracking-wide">
          {label}
        </span>
      </div>
    </button>
  );
};
