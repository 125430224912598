"use-strict";

import * as Actions from "../actions";

const UserData = (state = null, action) => {
  if (action.type === Actions.UPDATE_USER_DATA) {
    state = {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default UserData;
