import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { WithAuth } from '../../common/WithAuth';
import "../../styles/userManagement.scss";
import "../../styles/reconcilition.scss";
import "../../styles/consumerFinance.scss";
import styles from '../../styles/consumerFinanceTable.module.scss'
import API from "../../helpers/api/index";
import { getCurrentProcessPerm } from "../../helpers/auth";
import { useDispatch } from 'react-redux';
import { SET_USER_LIST } from '../../redux/actions';
import Icon from "../../component/core-component/Icon";
import Pagination from "../../component/Pagination";
import { DatePicker } from "antd";
import Select from 'react-select';
import moment from "moment";
import { encodeData, handleValidNumberInput } from "../../common/commonFunction";
import Validation from "../../utils/Validation";
import modalUserImg from "../../../src/assets/images/modalCallUser.svg"

const initialData = [
    { name: 'Product Name', value: '', type: 'text' },
    { name: 'Branch Name', value: '', type: 'text' },
    { name: 'Division', value: '', type: 'text' },
    { name: 'Account Number', value: '', type: 'text' },
    { name: 'Start Date', value: '', type: 'date' },
    { name: 'End Date', value: '', type: 'date' },
    { name: 'First Due Date', value: '', type: 'date' },
    { name: 'Borrower Name', value: '', type: 'text' },
    { name: 'Principal Amount', value: '', type: 'number' },
    { name: 'Total Installment', value: '', type: 'number' },
    { name: 'Area', value: '', type: 'text' },
    { name: 'Location', value: '', type: 'text' },
    { name: 'Zone', value: '', type: 'text' },
    { name: 'Authority Approving Loan', value: '', type: 'text' },
    { name: 'Guarantor Name', value: '', type: 'text' },
    { name: 'Interest Rate', value: '', type: 'number' },
    { name: 'Phone Number', value: '', type: 'text' },
    { name: 'Guarantor Address', value: '', type: 'textarea' },
    { name: 'Borrower Address', value: '', type: 'textarea' },
    { name: 'Additional Address', value: '', type: 'textarea' },
    { name: 'Phone Number', value: '', type: 'number' },
]

function ConsumerFinanceTable() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [defaulterData, setDefaulterData] = useState([]);
    const [activeTab, setActiveTab] = useState('All Details');
    const [steps, setSteps] = useState(["Consumer Finance", "All Details"]);
    const [uploadFilesLoading, setUploadFilesLoading] = useState(false);
    const [createLoanRecordLoading, setCreateLoanRecordLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false)
    const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
    const [inputFields, setInputFields] = useState(initialData);
    const [selectedItems, setSelectedItems] = useState([]);
    const [columns, setColumns] = useState([
        {
            title: 'Account Number',
            key: 'account_number',
            style: { width: '15%' },
        },
        {
            title: 'Product Name',
            key: 'product_name',
            style: { width: '18%' },
        },
        {
            title: 'Branch Name',
            key: 'branch_name',
            style: { width: '15%' },
        },
        {
            title: 'Start Date',
            key: 'start_date',
            style: { width: '18%' },
        },
        {
            title: 'Expiry',
            key: 'end_date',
            style: { width: '18%' },
        },
        {
            title: 'Status',
            key: 'status',
            style: { width: '18%' }
        },
    ])

    const [defaulterColumn, setDefaulterColumn] = useState([
        {
            title: 'Select All',
            key: 'select',
            style: { width: '15%' },
        },
        {
            title: 'Account Number',
            key: 'account_number',
            style: { width: '15%' },
        },
        {
            title: 'Product Name',
            key: 'product_name',
            style: { width: '18%' },
        },
        {
            title: 'Branch Name',
            key: 'branch_name',
            style: { width: '15%' },
        },
        {
            title: 'Start Date',
            key: 'start_date',
            style: { width: '18%' },
        },
        {
            title: 'Expiry',
            key: 'end_date',
            style: { width: '18%' },
        },
        {
            title: 'Call Status',
            key: 'call_status',
            style: { width: '18%' }
        },
    ])




    const amountType = [
        { label: 'Existing User', value: 'existing_user', defaulter: false },
        { label: 'Defaulter', value: 'defaulter', defaulter: true }
    ];

    const [selectedOptionValue, setSelectedOptionValue] = useState(amountType[0]?.value || '');

    const handleDropdownChange = (selectedOption) => {
        const value = selectedOption?.value;
        setSelectedOptionValue(value);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formDoc, setFormDoc] = useState();
    const [progressLoading, setprogressLoading] = useState("noprogress");

    const handleTabClick = (tab) => {
        setCurrentPage(1);
        if (tab === 'Defaulter List') {
            setSteps(['Consumer Finance', 'Defaulter List'])
        } else {
            setSteps(['Consumer Finance', 'All Details'])
        }
        setActiveTab(tab);
    };

    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 900);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    useEffect(() => {
        setCurrentPage(1);
        const handleResize = () => {
            let windowCurrentWidth = window.innerWidth <= 900
            setIsMobileDevice(windowCurrentWidth);
        }
        window.addEventListener('resize', handleResize);
    }, [])

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data?.length / recordsPerPage);
    const defaulterRecords = defaulterData?.slice(indexOfFirstRecord, indexOfLastRecord);
    const dPages = Math.ceil(defaulterData?.length / recordsPerPage);
    const navigate = useNavigate();

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const fetchData = async () => {
        try {
            const response = await API.apiGet('loan/list');
            setData(response?.data?.data);
            const filteredDefaulters = response?.data?.data?.filter(record => record?.defaulter);
            setDefaulterData(filteredDefaulters);
            setDataLoading(false)
        } catch (error) {
            setDataLoading(false)
        }
    };

    const handleCallModal = async () => {
        setDataLoading(true);
        if (selectedItems?.length > 0) {
            try {
                const { data } = await API.apiPost('/loan/initiate/call', { payload: selectedItems });
                toast.success(data?.message);
                setDataLoading(false);
                setShowConfirmationModal(true);
            } catch (error) {
                setDataLoading(false);
                if (error?.response && error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message)
                } else {
                    console.log('error', error)
                }
            }
        }
        else {
            setDataLoading(false);
            toast.error("Please select any row to place call");
        }
    }


    const [showModal, setShowModal] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    useEffect(() => {
        setDataLoading(true)
        if (getCurrentProcessPerm(9)?.write)
            if (!columns?.some(item => item?.title == 'Details')) {
                setColumns([...columns, {
                    title: 'Details',
                    key: 'actions',
                    style: { width: '20%' },
                },])
            }
        if (!defaulterColumn?.some(item => item?.title == 'Details')) {
            setDefaulterColumn([...defaulterColumn, {
                title: 'Details',
                key: 'actions',
                style: { width: '20%' },
            },])
        }
        fetchData();
    }, [])

    const viewConsumer = (data) => {
        navigate(`/editConsumer?id=${data.id}`);
    }

    const [errors, setErrors] = useState({});

    const handleChange = (e, index, name) => {
        let values = [...inputFields];
        let currentValue;
        let keyName
        if (name && (name == 'Start Date' || name == 'End Date' || name == 'First Due Date')) {
            currentValue = e
            keyName = name
        } else {
            currentValue = e.target.value
            keyName = e.target.name
        }
        if (keyName == 'Interest Rate' && currentValue) {
            if (parseFloat(currentValue) <= 100) {
                values[index] = { ...values[index], value: currentValue };
            }
        } else {
            values[index] = { ...values[index], value: currentValue };
        }
        setInputFields(values);
        setErrors((prev) => ({
            ...prev,
            [keyName]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        inputFields.forEach((field) => {
            let value = field.value;
            if (typeof value === 'string') {
                value = value.trim();
            }
            if (!value) {
                errors[field.name] = `${field.name} is required`;
            } else if (['Guarantor Name']?.includes(field?.name)) {
                let isInValidName = !Validation?.validateName(value);
                if (isInValidName) {
                    errors[field.name] = 'Please enter a valid name'
                }
            }
        });
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setCreateLoanRecordLoading(true);
            let endDate = inputFields.find((field) => field.name === 'End Date').value
            endDate = moment(endDate.$d).format('YYYY-MM-DD');

            let startDate = inputFields.find((field) => field.name === 'Start Date').value
            startDate = moment(startDate.$d).format('YYYY-MM-DD');

            let firstDueDate = inputFields.find((field) => field.name === 'First Due Date').value
            firstDueDate = moment(startDate.$d).format('YYYY-MM-DD');
            const payload = {
                product_name: inputFields.find((field) => field.name === 'Product Name').value,
                branch_name: inputFields.find((field) => field.name === 'Branch Name').value,
                division: inputFields.find((field) => field.name === 'Division').value,
                account_number: inputFields.find((field) => field.name === 'Account Number').value,
                start_date: startDate,
                end_date: endDate,
                first_due_date: firstDueDate,
                borrower_name: inputFields.find((field) => field.name === 'Borrower Name').value,
                guarantor_name: inputFields.find((field) => field.name === 'Guarantor Name').value,
                installment_size: inputFields.find((field) => field.name === 'Principal Amount').value,
                installment_total: inputFields.find((field) => field.name === 'Total Installment').value,
                guarantor_address: inputFields.find((field) => field.name === 'Guarantor Address').value,
                borrower_address: inputFields.find((field) => field.name === 'Borrower Address').value,
                base_address: inputFields.find((field) => field.name === 'Additional Address').value,
                phone_number: inputFields.find((field) => field.name === 'Phone Number').value,
                area: inputFields.find((field) => field.name === 'Area').value,
                location: inputFields.find((field) => field.name === 'Location').value,
                zone: inputFields.find((field) => field.name === 'Zone').value,
                approved_by: inputFields.find((field) => field.name === 'Authority Approving Loan').value,
                interest_rate: inputFields.find((field) => field.name === 'Interest Rate').value,
                phone_number: inputFields.find((field) => field.name === 'Phone Number').value,
                defaulter: false
            };
            try {
                const { data } = await API.apiPost('/loan/create', { payload: encodeData(payload) });
                setCreateLoanRecordLoading(false);
                toast.success(data.message);
                fetchData();
                setShowModal(false)
                setInputFields(initialData);
            } catch (error) {
                setCreateLoanRecordLoading(false);
                if (error.response && error.response.data.message) {
                    toast.error(error.response.data.message)
                } else {
                    console.log('error', error)
                }
            }
        }
    };

    const handleModal = () => {
        // setShowModal(false);
        setErrors({});
        setInputFields(initialData);
    }

    const handleCloseModal = () => {
        setShowUploadModal(false);
        setShowModal(false);
        setErrors({});
        setInputFields(initialData);
    }

    const handleCloseCallModal = () => {
        setShowConfirmationModal(false);
    }

    const getCurrentDate = (current) => {
        let endDate = inputFields.find((field) => field.name === 'End Date').value
        if (endDate) {
            return current && current > endDate
        } else {
            return current && current > moment().endOf('day')
        }
    };

    const disablePreviousDate = (current) => {
        let startDate = inputFields.find((field) => field.name === 'Start Date').value
        if (startDate) {
            return current && current < startDate
        }
    }

    const getDueDateDisable = (current) => {
        let startDate = inputFields.find((field) => field.name === 'Start Date').value
        let endDate = inputFields.find((field) => field.name === 'End Date').value
        if (startDate && !endDate) {
            return current < startDate
        } else if (endDate && !startDate) {
            return current > endDate
        } else if (endDate && startDate) {
            return endDate < current || startDate > current
        }

    }

    const handleFileChange = (event) => {
        const files = event.target.files;
        setFormDoc(files?.[0]);
        setprogressLoading("noprogress");

        if (files?.length) {
            setprogressLoading("uploading");
            setprogressLoading("uploaded");
        }
        else {
            event.target.value = "";
            setFormDoc("");
        }
    }

    const handleClear = () => {
        setFormDoc("");
        setprogressLoading("noprogress");
    };

    const sendInfo = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectedItems?.length === defaulterData?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(defaulterData);
        }
    };

    const isAllSelected = selectedItems?.length === defaulterData?.length;

    const handleFileChangeExcel = async (event) => {
        setUploadFilesLoading(true);
        const file = formDoc;
        // Check if the file is an Excel file
        if (file && (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
            const formData = new FormData();
            formData.append('file', file);


            const selectedOption = amountType.find(option => option.value === selectedOptionValue);
            const defaulter = selectedOption ? selectedOption.defaulter : false;

            formData.append('defaulter', defaulter.toString());

            const response = await fetch(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/loan/bulk/upload`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                fetchData();
                toast.success("File uploaded successfully.");
            } else {
                toast.error("Failed to upload the file.");
            }
        } else {
            event.target.value = '';
            toast.error("Please upload an Excel file (.xlsx or .xls).");
            setUploadFilesLoading(false);
            return;

        }

        setShowUploadModal(false);
        handleModal();
    };

    return (
        <>  
            <div className={`flex flex-col dark:bg-dark-background reconcilitaion-main-container`}>
                <div className=" flex tabContainer">
                    <div className="w-5/6 flex">
                        <div
                            className={`w-content w-40 p-2 text-center tabsName ${activeTab === 'All Details' ? "showActiveColor commmonActiveTag" : ""}`}
                        >
                            <p className="mt-2 cursor-pointer text-base" onClick={() => handleTabClick('All Details')}>
                                All Details
                            </p>
                        </div>
                        <div
                            className={`w-24 p-2 w-40 text-center tabsName ${activeTab === 'Defaulter List' ? "showActiveColor commmonActiveTag" : ""}`}
                        >
                            <p className="mt-2 cursor-pointer text-base" onClick={() => handleTabClick('Defaulter List')}>
                                Defaulter List
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`flex justify-end mt-5`}>
                    <div>
                        <label onClick={() => setShowUploadModal(true)}
                            className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center commonBtn custom_btn_primary">
                            Upload
                            <img width={isMobileDevice ? "16" : ""} src="/assets/images/icon_upload.svg" alt="upload icon" />
                        </label>

                    </div>
                    <div className="ms-2">
                        <label
                            className={`cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center commonBtn custom_btn_primary`}
                            onClick={() => setShowModal(true)}
                        >
                            Create
                        </label>
                    </div>

                </div>

                {showUploadModal && (
                    <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex   overflow-y-auto overflow-x-hidden fixed  top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                            <div className={`modal      absolute`} style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                                <div className={`modal-content flex flex-col ${styles.defaulterModal} justify-center w-2/4   rounded-lg bg-white border modalContainer p-5 ${isMobileDevice && 'raiseTaskModal'}`}>
                                    <div className='ps-3 pe-3 pt-1'>
                                        <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Defaulter Details  </p>
                                    </div>
                                    <div className="modalForm flex flex-col  min-h-64">
                                        <div className=" flex flex-col w-full mt-5 justify-center items-center mb-3">
                                            <form onSubmit={handleSubmit} className="w-5/6">
                                                <div className="mb-4 w-full  ">

                                                    <label htmlFor="" className="block  text-sm font-medium text-gray-700">
                                                        Type
                                                    </label>
                                                    <Select
                                                        name=""
                                                        value={amountType.find(option => option.value === selectedOptionValue)}
                                                        onChange={handleDropdownChange}
                                                        options={amountType}
                                                        className={`py-2 rounded-lg focus:outline-none focus:border-blue-500 ${styles.typeDropdown}`}
                                                        classNamePrefix="select"
                                                    // onChange={}
                                                    />
                                                    <div className="flex flex-col w-full ">
                                                        {uploadFilesLoading &&
                                                            <div className={`flex items-center justify-center absolute ${styles.loader}`}>
                                                                <ColorRing
                                                                    visible={true}
                                                                    height="80"
                                                                    width="60"
                                                                    ariaLabel="color-ring-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="color-ring-wrapper"
                                                                    colors={[]}
                                                                />
                                                            </div>

                                                        }
                                                        <div
                                                            className={`paperFormCard mt-4 pb-3 border-dashed border-2 bg-white rounded-md flex flex-col items-center justify-center file-upload-container gap-4 `}
                                                        >
                                                            <p className=" ps-4 pt-2 pe-4 uploadSectionFont text-center w-60">
                                                                Upload Scanned Excel of Paper Form
                                                            </p>
                                                            {formDoc?.name && (
                                                                <span className="text-center text-sm w-full block mt-1 blue-color relative">
                                                                    {formDoc?.name?.length > 20
                                                                        ? formDoc?.name?.substring(0, 14) + "..."
                                                                        : formDoc?.name}
                                                                    {!isLoading && (
                                                                        <img
                                                                            src="/assets/images/cross.png"
                                                                            className="cross absolute removeTheFileFirst"
                                                                            onClick={() =>
                                                                                handleClear()
                                                                            }
                                                                        />
                                                                    )}
                                                                </span>
                                                            )}
                                                            {progressLoading === "noprogress" && (
                                                                <span className="text-center uploadSectionFont w-full block mt-1 text-sky-700">
                                                                    Drag and Drop File here
                                                                </span>
                                                            )}

                                                            {progressLoading === "noprogress" && (
                                                                <>
                                                                    <label className="custom_btn_primary cursor-pointer bg-blue-500 hover:bg-blue-600 text-white uploadSectionFont font-semibold py-1 px-2 rounded w-32 text-center flex button justify-center commonBtn">
                                                                        <input
                                                                            type="file"
                                                                            //   accept="application/pdf"
                                                                            onChange={(e) =>
                                                                                handleFileChange(
                                                                                    e,
                                                                                    false,
                                                                                )
                                                                            }
                                                                            className="custom_btn_primary mb-4 commonBtn"
                                                                            hidden
                                                                            name="paperForm"
                                                                        />
                                                                        Upload
                                                                        <img
                                                                            className="uploadModalLogos"
                                                                            src="assets/images/icon_upload.svg"
                                                                        />
                                                                    </label>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="flex justify-end w-full cunFinActionButtons">
                                        <button
                                            type="button"
                                            className={`custom_btn_secondary font-bold py-2 px-4 rounded w-1/4 ${createLoanRecordLoading ? 'disableCancleButton' : 'commonCancelButton'}`}
                                            onClick={() => handleCloseModal()}
                                            disabled={createLoanRecordLoading}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={(e) => handleFileChangeExcel(e)}
                                            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded w-1/4 ms-3 custom_btn_primary commonBtn ${createLoanRecordLoading ? 'disableSubmitButton flex items-center' : 'py-3'}`}
                                            disabled={createLoanRecordLoading}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                )}

                {showModal && (
                    <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
                        <div className="modal absolute" style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                            <div className={`modal-content rounded-lg bg-white border modalContainer createModalConsumer p-5 ${isMobileDevice && 'raiseTaskModal'}`}>
                                <div className='ps-3 pe-3 pt-1'>
                                    <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Create Consumer Finance</p>
                                </div>
                                <div className="modalForm">
                                    <div className="flex-col mt-5 mb-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className={`grid ${isMobileDevice ? "grid-cols-1" : "grid-cols-2"} gap-4`}>
                                                {inputFields?.map((field, index) => (
                                                    <div key={index} className="mb-1 w-full flex flex-col">
                                                        <h2 className="w-full mr-2 mb-1" htmlFor={field.name}>
                                                            {field.name}
                                                        </h2>
                                                        {field?.type == 'date' ?
                                                            <DatePicker
                                                                id="inputC"
                                                                name={field?.name}
                                                                value={field?.value}
                                                                onChange={(e) => handleChange(e, index, field?.name)}
                                                                disabledDate={field?.name == 'Start Date' ? getCurrentDate : field?.name == 'First Due Date' ? getDueDateDisable : disablePreviousDate}
                                                                placeholder={`Enter ${field.name}`}
                                                                className={`py-2 rounded outline-none ps-2 ${errors?.[field?.name]
                                                                    ? "border border-red-500"
                                                                    : " border"
                                                                    }`}
                                                            />
                                                            : field?.type != 'textarea' ?
                                                                <input
                                                                    type={field.type}
                                                                    placeholder={`Enter a ${field.name}`}
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={field.value}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    min="0"
                                                                    max={field?.name == 'Interest Rate' ? '100' : ''}
                                                                    onKeyDown={(e) => field?.type == 'number' && handleValidNumberInput(e)}
                                                                    className="w-full border rounded-md border-input-field p-1 text-black font-medium py-2"
                                                                />
                                                                :
                                                                <textarea
                                                                    className="w-full border rounded-md border-input-field p-1 text-black font-medium py-2"
                                                                    type="text"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={field.value}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    placeholder={`Enter a ${field.name}`}
                                                                />
                                                        }
                                                        {errors[field.name] && (
                                                            <p className="text-red-500">{errors[field.name]}</p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex justify-end w-full cunFinActionButtons">
                                    <button
                                        type="button"
                                        className={`custom_btn_secondary font-bold py-2 ${isMobileDevice ? "px-0" : "px-4"}  rounded w-1/4 ${createLoanRecordLoading ? 'disableCancleButton' : 'commonCancelButton'}`}
                                        onClick={() => handleCloseModal()}
                                        disabled={createLoanRecordLoading}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold ${isMobileDevice ? "px-0" : "px-4"} rounded w-1/4 ms-3 custom_btn_primary commonBtn ${createLoanRecordLoading ? 'disableSubmitButton flex items-center' : 'py-3'}`}
                                        disabled={createLoanRecordLoading}
                                    >
                                        {createLoanRecordLoading &&
                                            <ColorRing
                                                visible={true}
                                                height="50"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                            />

                                        }
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {activeTab === 'All Details' && <div className="mt-3">

                    <div className={`${!isMobileDevice ? "reconcilitionTable-container":""} mt-3`}>
                        {currentRecords?.length > 0 ?
                            !isMobileDevice ?
                            (<table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                <thead>
                                    <tr className="ps-2 pe-2">
                                        {columns.map((item, indx) =>
                                            <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                {item?.title.toLowerCase()}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRecords?.map((item, index) =>
                                        <tr className='ps-2 pe-2' key={`${item}_${index}`}>
                                            {columns.map((column, colIdx) => column?.key === 'status' ?
                                                <td key={`col_${index}${colIdx}`} className="py-2 ps-4" style={column?.style}>{item?.[column?.key] ? item?.[column?.key] == 'Inactive' ? <span className={`px-1 rounded-full tabletDebit text-center`}><span className="red-dot" />Inactive</span> : <span className="rounded-full tabletCredit px-1 text-center"><span className="green-dot" />Active</span> : <span className="text-red-500"> - Missing Record</span>}</td>
                                                : column?.key === 'actions' ? <td className="py-2 ps-4" style={column?.style}>
                                                    <span className="flex justify-center items-center">
                                                        <button title="View Details" onClick={() => viewConsumer(item)} >
                                                            <Icon iconName="EyeAlt" width={16} height={16} />
                                                        </button>
                                                    </span>
                                                </td>
                                                    : ['start_date', 'end_date']?.includes(column?.key) ?
                                                        <td className="py-2 ps-4" title={item?.[column?.key] ? moment(item?.[column?.key]).format('YYYY-MM-DD | HH:mm') : ''} style={column?.style} >{item?.[column?.key] ? moment(item?.[column?.key]).format('YYYY-MM-DD | HH:mm') : <span> -</span>}</td>
                                                        : <td className="py-2 ps-4" title={item?.[column?.key]} style={column?.style} >{item?.[column?.key] ? item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key] : <span> -</span>}</td>
                                            )
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>):
                            <div>
                            {currentRecords?.length > 0 ? (
                                <>
                                    <div>
                                        {currentRecords?.map((item, index) => (
                                            <div key={`block_${index}`} className="mt-3 border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Account Number</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.account_number}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Product Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.product_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Branch name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.branch_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Start Date</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.start_date ? moment(item?.start_date).format('YYYY-MM-DD | HH:mm') : <span> -</span>}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Expiry</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.end_date ? moment(item?.end_date).format('YYYY-MM-DD | HH:mm') : <span> -</span>}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Status</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.status ? item?.status == 'Inactive' ? <span className={`px-1 rounded-full tabletDebit text-center`}><span className="red-dot" />Inactive</span> : <span className="rounded-full tabletCredit px-1 text-center"><span className="green-dot" />Active</span> : <span className="text-red-500"> - Missing Record</span>}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Action</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>
                                                            <button title="View Details" onClick={() => viewConsumer(item)} >
                                                                <Icon iconName="EyeAlt" width={16} height={16} />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="reponsivePagination">
                                        {isMobileDevice && <Pagination 
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                        ></Pagination>}
                                    </div>
                                </>
                            ) : (
                                <div className="noDataFound">
                                    <img
                                        src='/assets/noData.svg'
                                        alt="no-data"
                                        className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                    />
                                    <div className="text-xl font-semibold">
                                        No data found
                                    </div>
                                </div>
                            )}
                            </div>
                            :
                            <>
                                {!isMobileDevice ? <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                    <thead>
                                        <tr className="ps-2 pe-2">
                                            {columns.map((item, indx) =>
                                                <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                    {item?.title.toLowerCase()}
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                </table>:""}
                                {dataLoading ?
                                    <div className="flex items-center justify-center" >
                                        <ColorRing
                                            visible={true}
                                            height="80"
                                            width="60"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={[]}
                                        />
                                    </div>
                                    :
                                    !isMobileDevice ? <div className='flex justify-center py-5 font-semibold'>
                                        No data found
                                    </div>:""
                                }
                            </>
                        }
                    </div>
                    {currentRecords?.length > 0 && !isMobileDevice  &&
                        <div className="reponsivePagination mt-4">
                            <Pagination nPages={nPages}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                            ></Pagination>
                        </div>
                    }

                </div>}

                {activeTab === 'Defaulter List' &&

                    <div className="mt-3">
                        <div className={`${!isMobileDevice ? "reconcilitionTable-container":""} mt-3`}>
                            {defaulterRecords?.length > 0 ?
                                !isMobileDevice ? 
                                (<table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                    <thead>
                                        <tr className="ps-2 pe-2">
                                            {defaulterColumn.map((item, indx) =>
                                                item?.key === "select" ? 
                                                <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                    <span className="flex">
                                                        <input className="me-2" type="checkbox" onChange={handleSelectAllChange} checked={isAllSelected}></input>{item?.title.toLowerCase()} 
                                                    </span>
                                                </th> :
                                                    <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                        {item?.title.toLowerCase()}
                                                    </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {defaulterRecords?.map((item, index) =>
                                            <tr className='ps-2 pe-2' key={`${item}_${index}`}>
                                                {defaulterColumn.map((column, colIdx) => {
                                                     if (column?.key === 'actions') {
                                                        return (
                                                            <td className="py-2 ps-4" style={column?.style}>
                                                                <span className="flex justify-center items-center">
                                                                    <button title="View Details">
                                                                        <Icon iconName="EyeAlt" width={16} height={16} />
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        );
                                                    } else if (column?.key === 'call_status') {
                                                        return (
                                                            <td className="py-2 ps-4" style={column?.style}>
                                                                <span className ={`${item?.[column?.key] === "completed" ? styles.completedStatus :""} ${item?.[column?.key] === "busy" ? styles.busyStatus :""}  ${item?.[column?.key] === "no-answer" ? styles.noAnswerStatus :""} flex justify-center items-center`}>
                                                                    {item?.[column?.key] ? item?.[column?.key] : "-" }
                                                                </span>
                                                            </td>
                                                        );
                                                    }
                                                    else if (['start_date', 'end_date']?.includes(column?.key)) {
                                                        return (
                                                            <td className="py-2 ps-4" title={item?.[column?.key] ? moment(item?.[column?.key]).format('YYYY-MM-DD | HH:mm') : ''} style={column?.style}>
                                                                {item?.[column?.key] ? moment(item?.[column?.key]).format('YYYY-MM-DD | HH:mm') : <span> -</span>}
                                                            </td>
                                                        );
                                                    } else if (column?.key === 'select') {
                                                        return (
                                                            <td className="py-2 ps-4" style={column?.style}>
                                                                <span className="flex">
                                                                    <input type="checkbox" onChange={() => sendInfo(item)} checked={selectedItems?.includes(item)}></input>
                                                                </span>
                                                            </td>
                                                        );
                                                    } 
                                                    
                                                    else {
                                                        return (
                                                            <td className="py-2 ps-4" title={item?.[column?.key]} style={column?.style}>
                                                                {item?.[column?.key] ? (item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key]) : <span> -</span>}
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>):
                                <div>
                                {defaulterRecords?.length > 0 ? (
                                    <>
                                        <div>
                                           <span className="ps-4"> Select All <input className="ms-2" type="checkbox" onChange={handleSelectAllChange} checked={isAllSelected}></input></span>
                                            {defaulterRecords?.map((item, index) => (
                                                <div key={`block_${index}`} className="mt-3 border bg-white mb-4 rounded-lg">
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Select</span>
                                                        <div className="flex items-center justify-between">
                                                        <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>
                                                            <input type="checkbox" onChange={() => sendInfo(item)} checked={selectedItems?.includes(item)}></input>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Account Number</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.account_number}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Product Name</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.product_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Branch name</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.branch_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Start Date</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.start_date ? moment(item?.start_date).format('YYYY-MM-DD | HH:mm') : <span> -</span>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Expiry</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.end_date ? moment(item?.end_date).format('YYYY-MM-DD | HH:mm') : <span> -</span>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Status</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.status ? item?.status == 'Inactive' ? <span className={`px-1 rounded-full tabletDebit text-center`}><span className="red-dot" />Inactive</span> : <span className="rounded-full tabletCredit px-1 text-center"><span className="green-dot" />Active</span> : <span className="text-red-500"> - Missing Record</span>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Action</span>
                                                        <div className="flex items-center justify-between">
                                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>
                                                                <button title="View Details" >
                                                                    <Icon iconName="EyeAlt" width={16} height={16} />
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="reponsivePagination">
                                            {isMobileDevice && <Pagination 
                                                nPages={dPages}
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                            ></Pagination>}
                                        </div>
                                    </>
                                ) : (
                                    <div className="noDataFound">
                                        <img
                                            src='/assets/noData.svg'
                                            alt="no-data"
                                            className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                        />
                                        <div className="text-xl font-semibold">
                                            No data found
                                        </div>
                                    </div>
                                )}
                                </div>
                                :
                                <>
                                    {!isMobileDevice ? <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                        <thead>
                                            <tr className="ps-2 pe-2">
                                                {columns.map((item, indx) =>
                                                    <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                        {item?.title.toLowerCase()}
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                    </table>:""}
                                    {dataLoading ?
                                        <div className="flex items-center justify-center" >
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="60"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={[]}
                                            />
                                        </div>
                                        :
                                        !isMobileDevice ? <div className='flex justify-center py-5 font-semibold'>
                                            No data found
                                        </div>:""
                                    }
                                </>
                            }
                        </div>

                        {defaulterRecords?.length > 0 && !isMobileDevice ?
                            <div className="reponsivePagination mt-4">
                                <Pagination nPages={dPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                ></Pagination>
                            </div>:""
                        }

                        {defaulterRecords?.length > 0 &&
                            <div className="mt-2 flex justify-end">
                                <button className={`bg-blue-500 mb-0 hover:bg-blue-700 text-white font-bold px-4 rounded ms-3 custom_btn_primary commonBtn ${createLoanRecordLoading ? 'disableSubmitButton flex items-center' : 'py-3'}`}
                                    onClick={handleCallModal}
                                >
                                    Initiate Call
                                </button>
                            </div>
                        }

                        {showConfirmationModal && (
                            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                                <div className={`modal min-h-36 absolute`} style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                                    <div className={`modal-content flex  row flex-col gap-y-8 items-center border border-black-500 ${styles.initiateCallModal} justify-center w-full rounded-lg bg-white border modalContainer p-5 ${isMobileDevice && 'raiseTaskModal'}`}>

                                        <div className="flex w-full justify-evenly">
                                            <div>
                                                <img src={modalUserImg}></img>
                                            </div>

                                            <div className="pt-10">
                                                <div>
                                                    <span className={`${styles.totalText}`}>Total Number of calls -</span>
                                                    <span className={`${styles.totalCall}`}>24</span>
                                                </div>
                                                <div className="flex justify-between pt-5">
                                                    <button className="custom_btn_secondary font-bold py-2 px-5 rounded commonCancelButton">
                                                        Pause
                                                    </button>
                                                    <button className="custom_btn_primary commonBtn px-5 rounded py-2">
                                                        Resume
                                                    </button>
                                                </div>
                                                <div className="pt-5">
                                                    <button onClick={handleCloseCallModal} className={`${styles.endBtn} text-white px-5 py-2 w-full`}>
                                                        End Call
                                                    </button>
                                                </div>
                                            </div>

                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(ConsumerFinanceTable);
