import React, { useEffect, useState } from 'react';
import Pagination from "../../component/Pagination";
import "../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';

export default function UpdatePhoneNumberTable(props) {
    const { 
        columns, 
        rowData, 
        tableLoading, 
        nPages, 
        handlePageChange, 
        currentPage, 
        recordsPerPage,
    } = props
    
  const [currentRecords, setCurrentRecords] = useState([]);

  useEffect(() => {
      if (rowData && rowData.length > 0) {
          setCurrentRecords(rowData);
      } else {
          setCurrentRecords([]);
      }
  }, [rowData]);

  return (
    <div className={`${'reconcilitaion-main-container mt-5'}`}>
      <div className="reconcilitionTable-container">
        {!tableLoading && currentRecords?.length > 0 ?
            <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
            <thead>
                <tr className="ps-2 pe-2">
                {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4 capitalize" style={{ width: item?.width, fontSize: '16px' }}>
                    {item?.title}
                    </th>
                )}
                </tr>
            </thead>
            <tbody>
                {currentRecords?.map((item, index) =>
                <tr key={`${item}_${index}`}>
                    {columns?.map((column, colIdx) => 
                    column?.key === 'status' ?
                        <td key={`col_${index}${colIdx}`} className="py-2 ps-4 " style={{ fontSize: '16px' }}>
                            {item?.[column?.key] ? item?.[column?.key] == 'Failed' ? <span className={`px-1 rounded-full tabletDebit`}><span className="red-dot" />Failed</span> : <span className="rounded-full tabletCredit px-1"><span className="green-dot" />Success</span> : <span className="text-red-500"> - Missing Record</span>}
                        </td>
                    :
                    (column?.key === 'created_at') ? 
                        <td className="py-2 ps-4" title={moment(item?.[column?.key]).format("YYYY-MM-DD | HH:mm:ss")} style={{ fontSize: '16px' }}>
                            {moment(item?.[column?.key]).format("YYYY-MM-DD | HH:mm:ss")}
                        </td> 
                    :
                        <td title={item?.[column?.key] ? item?.[column?.key] : ''}>
                            {item?.[column?.key] ? item?.[column?.key]?.length > 30 ? `${item?.[column?.key].substring(0, 30)}...` : item?.[column?.key] : '--'}
                        </td>
                    )}
                </tr>
                )}
            </tbody>
            </table>
            :
            <>
                <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                <tr className="ps-2 pe-2">
                    {columns?.map((item, indx) =>
                    <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                        {item?.title}
                    </th>
                    )}
                </tr>
                </thead>
            </table>
            {tableLoading ? 
            <div className="w-full h-full flex items-center justify-center" >
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
            </div>
            :
                <div className='flex justify-center py-5 font-semibold'>
                    No data found
                </div>
            }
            </>
        }
      </div>
        {(!tableLoading && currentRecords?.length > 0) &&
            <Pagination 
                nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            ></Pagination>
        }
      </div>
  )
}