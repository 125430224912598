import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider, useDispatch, useSelector, } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from 'antd';
import { GlobalStyles } from '../src/theme/GlobalStyled';
import antdtheme from "./utils/theme/theme";
import API from "./helpers/api";
import * as Actions from "./redux/actions";
import { defaultLoadingTheme } from "./theme/defaultLoadingTheme";
import { defaultTheme } from "./theme/defultTheme";
import { getDecodedToken } from "./helpers/auth";
import { SocketProvider } from "./helpers/socketProvider";

const ThemedApp = () => {
  const dispatch = useDispatch()
  const [themeLoading, setThemeLoading] = useState(false);
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
  const [seletedTheme, setSelectedTheme] = useState(themeDetails);

  useEffect(() => {
    if (getDecodedToken()) {
      getThemeDeatils();
    }
  }, []);

  useEffect(() => {
    setSelectedTheme(themeDetails);
  }, [themeDetails]);

useEffect(() => {
  if (themeLoading) {
    dispatch({
      type: Actions.SET_USER_THEME,
      payload: { themeDetails: { ...defaultLoadingTheme } },
    });
    setSelectedTheme(defaultLoadingTheme);
  }
}, [themeLoading])

const getlogo = async(themeData) => {
  try {
      dispatch({type: Actions.REQUST_TEHME_DATA})
      if (themeData?.logo) {
          const { data } = await API.apiGet(`/theme/logo?logoURL=${themeData?.logo}&theme_id=${themeData?.id}`);
          if (data.success) {
            dispatch({
              type: Actions.SET_USER_THEME,
              payload: { themeDetails: { ...themeData, logo: data.data } },
            });
            dispatch({type: Actions.GET_TEHME_DATA});
          }
      }
  } catch (error) {
      console.log('error', error);
  }
}

  const getThemeDeatils = async() => {
    try {
        dispatch({type: Actions.REQUST_TEHME_DATA})
        setThemeLoading(true);
        const { data } = await API.apiGet(`/theme/details`);
        if (data?.success) {
          let themeDetails = data.data != null ? data.data : defaultTheme
            getlogo(themeDetails);
            setThemeLoading(false);
            dispatch({
              type: Actions.SET_USER_THEME,
              payload: { themeDetails: { ...themeDetails } },
            });
            setSelectedTheme(themeDetails);
        } else {
            setThemeLoading(false);
            dispatch({
                type: Actions.SET_USER_THEME,
                payload: { themeDetails: { ...defaultTheme } },
            });
            setSelectedTheme(defaultTheme);
        }
    } catch (error) {
        setThemeLoading(false);
        console.log('error in get theme deatils >>>>', error)
    }
  }

  return (
    <ConfigProvider theme={antdtheme}>
      <ThemeProvider theme={seletedTheme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
    <SocketProvider>
      <ThemedApp />
      </SocketProvider>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
