import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/detailsPagee.scss";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../common/Navbar";
const DetailsPagee = () => {
  const steps = ["Acount Details", "Nominee Details"];
  const navigate = useNavigate()
  const transactionControlHeader = [
    {
      title: "Nominee Id",
      label:"Nominee Id"
    },
    {
      title: "Nominee Name",
      label:"Nominee Name"
    },
    {
      title: "Father Name",
      label:"Father Name"
    },
    {
      title: "Mother Name",
      label:"Mother Name"
    },
    {
      title: "Date of Birth",
      label:"Date of Birth"
    },
    {
      title: "Relation",
      label:"Relation"
    },
    {
      title: "Nom Percent",
      label:"Nom Percent"
    },
    {
      title: "Document Type",
      label:"Document Type"
    },
    {
      title: "Document No",
      label:"Document No"
    },
];
const transactionControlData = [
    {
        id:"-",
        name:"Sheikh Yousuf",
        fname:"Sheikh Korim",
        Mname:"Sahera Khatun",
        dob:"-",
        relation:"HUS",
        nomPercent:"100",
        docType:"-",
        docNo:"-"
    },
    {
        id:"-",
        name:"Sheikh Yousuf",
        fname:"Sheikh Korim",
        Mname:"Sahera Khatun",
        dob:"-",
        relation:"HUS",
        nomPercent:"100",
        docType:"-",
        docNo:"-"
    },
    {
        id:"-",
        name:"Sheikh Yousuf",
        fname:"Sheikh Korim",
        Mname:"Sahera Khatun",
        dob:"-",
        relation:"HUS",
        nomPercent:"100",
        docType:"-",
        docNo:"-"
    },
]
const NomineeImageHeader = [
    {
      title: "Log Id",
      label:"Log Id"
    },
    {
      title: "Col Name",
      label:"Col Name"
    },
    {
      title: "New Image",
      label:"New Image"
    },
    {
      title: "Old Image",
      label:"Old Image"
    },
];
const NomineeImageData = [
    {
        id:"7251646",
        colName:"Nominee Image",
    },
    {
        id:"7251647",
        colName:"Nominee Image",
    },
    {
        id:"7251648",
        colName:"Nominee Image",
    },
]
  return (
    <div className={`flex dark:bg-dark-background updatePhone`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <Navbar />
        <div className="z-0 p-10 detailsPagee nomineeDetailsFixedHeightContainer">
          <Breadcrumb
            steps={steps}
            activeStep={1}
            showBackButton={true}
            redirectUrl={"/ConventionalAgentBankingCard"}
          />
          <div class="grid grid-cols-3 gap-4 mt-2 ">
            <div className=" col-span-3 rounded p-4 flex flex-wrap cardBackground">
            <p className="quickLinkText">Detail Report About Aproval</p>
            <div className="mt-4 nomineeCardsContainer rounded p-2">
            <p className="quickLinkText">Nominee Information</p>
            <div className="mt-3">
                <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                 <thead>
                 <tr className="tableHeadColor">
                 {transactionControlHeader.map((item)=>
                    <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                 )}
                 </tr>
                 </thead>
                 <tbody>
                    {transactionControlData.map((item)=>
                     <tr className="border-b">
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.id}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.name}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.fname}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.Mname}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.dob}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.relation}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.nomPercent}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.docType}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.docNo}</td>
                     </tr>
                    )}
                 </tbody>
                </table>
            </div>
            </div>
            <div className="mt-4 nomineeCardsContainer rounded p-2 w-full">
            <p className="quickLinkText">Nominee Image Information</p>
            <div className="mt-3">
                <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                 <thead>
                 <tr className="tableHeadColor">
                 {NomineeImageHeader.map((item)=>
                    <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                 )}
                 </tr>
                 </thead>
                 <tbody>
                    {NomineeImageData.map((item)=>
                     <tr className="border-b">
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.id}</td>
                         <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.colName}</td>
                         <td className="py-2 ps-4 "><img src="assets/images/businessman.png" className="userCapturedImage border"></img></td>
                         <td className="py-2 ps-4 "><img src="assets/images/businessman.png" className="userCapturedImage border"></img></td>
                     </tr>
                    )}
                 </tbody>
                </table>
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPagee;
