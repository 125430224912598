import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import MyTask from "../../component/MyTask";
import '../../styles/dashboard.scss';

const MyTaskComponent = () => {

  const isMobileDevice = window.screen.width <= 900;
    
  useEffect(()=>{
    localStorage.setItem("activeTab", "Services");
  },[])

  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer`}>
      <SideMenu headerName="My Task List"/>
      <div className="h-screen flex-1 overflow-y-scroll">
        <Header />
        <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          <div className=" flex flex-row justify-between">
            {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-2">
              My Task List
            </h1>}
          </div>
          <div className="flex justify-evenly flex-col">
            <MyTask/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(MyTaskComponent);