import SideMenu from "../../component/SideMenu";
import Navbar from "../../common/Navbar";
import Header from "../../component/Header";
import styles from "../../styles/informationUpdate.module.scss";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import debounce from "lodash/debounce";
import { ColorRing } from "react-loader-spinner";
import API from "../../helpers/api";
import Breadcrumb from "../../component/Breadcrumb";
import { encodeData } from "../../common/commonFunction";

export default function AccountTitleChange() {
  const [accountNumber, setAccountNumber] = useState("");
  const [newAccountTitle, setNewAccountTitle] = useState("");
  const [nidNumber, setNidNumber] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [AccountDetails, setAccountDetails] = useState();
  const [errors, setErrors] = useState({});
  const isMobileDevice = window.screen.width <= 900;

  const getAccountDetails = async () => {
    setDataLoading(true);
    const account_no = accountNumber;
    try {
      const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
      if (data?.success) {
        setAccountDetails(data?.data);
        toast.success(data?.message);
        setDataLoading(false);
      } else {
        toast.error(data?.message);
        setDataLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDataLoading(false);
    }
  };

  const handleChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    if (name === "account_number") {
      setAccountNumber(value);
      setErrors({
        ...errors,
        [name]: "",
      });
    } else if (name === "nid_number") {
      setNidNumber(value);
      setErrors({
        ...errors,
        [name]: "",
      });
    } else if (name === "new_account_title") {
      setNewAccountTitle(value);
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const accountTitleChange = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const payload = {
        nid_number: nidNumber,
        dob: AccountDetails?.personal_info?.DOB,
        account_no: AccountDetails?.account_info?.account_no,
        request_title: "account_info",
        request_purpose: "account_title",
        request_update_value: newAccountTitle,
        previous_value: AccountDetails?.account_info?.account_title,
        agent_banking_id: parseInt(AccountDetails?.id),
        bank_accountHolderTitle:
          AccountDetails?.personal_info?.first_name +
          "" +
          AccountDetails?.personal_info?.last_name,
        status: "pending",
      };
      try {
        const response = await API.apiPost(`/v1/agentBanking/raise/request`, {
          payload: encodeData(payload),
        });
        if (response?.status) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      }
    } else {
      setErrors(errors);
    }
  };

  const validateForm = () => {
    setErrors({});
    let errors = {};

    const validAccountNumberRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/;
    const validAccountTitleRegex = /^[a-zA-Z\s]+$/;

    if (!accountNumber) {
      errors["account_number"] = "Please enter an account number";
    } else if (!validAccountNumberRegex.test(accountNumber)) {
      errors["account_number"] = "Account number must be alphanumeric";
    }

    if (!nidNumber) {
      errors["nid_number"] = "Please enter NID Number";
    }

    if (!newAccountTitle) {
      errors["new_account_title"] = "Please enter account title";
    } else if (!validAccountTitleRegex.test(newAccountTitle)) {
      errors["new_account_title"] = "Account Title cannot contain number";
    }

    return errors;
  };

  const handleClearData = () => {
    setAccountDetails("");
    setNewAccountTitle("");
    setNidNumber("");
    setAccountNumber("");
  };
  return (
    <>
      <div
        className={`flex dark:bg-dark-background dashboard-container dashboard-main`}
      >
        <SideMenu />
        <div className="flex-1 h-screen overflow-y-scroll">
          <Header />
          {dataLoading && (
            <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
              <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
              />
            </div>
          )}
          <Navbar />
          <div className={`z-0 ${isMobileDevice ? "p-4" : "p-10"}`}>
            <Breadcrumb
              showBackButton={true}
              redirectUrl={"/ConventionalAgentBankingCard"}
            />
            <div className={`p-3 mt-4 ${styles.searchCard}`}>
              <div className="flex justify-between">
                <div>
                  <h1 className="text-xl font-semibold">Filter</h1>
                </div>
                <div className="flex justify-center align-middle me-3">
                  {/* <button type="submit" className=' relative border rounded-md bg-blue-600 w-20 p-1 text-white font-medium commonBtn py-2' onClick={handleClearData}>
                                        Clear</button> */}
                  <button
                    type="submit"
                    className=" relative border rounded-md bg-blue-600 w-36 p-1 text-white font-medium   custom_btn_primary py-2 h-11 ms-3"
                    onClick={accountTitleChange}
                  >
                    Raise Request
                  </button>
                </div>
              </div>
              <div
                className={`flex justify-start pe-3 mt-4 ${
                  isMobileDevice ? "ms-0" : "ms-6 ps-6 p-6"
                }`}
              >
                <div
                  className={`flex  ${
                    isMobileDevice ? "w-full" : "justify-center"
                  }`}
                >
                  {!isMobileDevice ? (
                    <table>
                      <tbody>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="account_number"
                              className="numberRequestModal"
                            >
                              * Account Number :{" "}
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              id="account_number"
                              name="account_number"
                              value={accountNumber}
                              // onChange={(e) => setAccountNumber(e?.target?.value)}
                              onChange={handleChange}
                              placeholder="Enter Account Number"
                              className={`ms-6 py-2 rounded outline-none ps-2 border w-80`}
                            />
                            <button
                              disabled={!accountNumber}
                              type="submit"
                              className=" relative border rounded-md bg-blue-600 w-32 p-1 text-white font-medium commonBtn custom_btn_primary py-2 h-11 ms-6"
                              onClick={getAccountDetails}
                            >
                              Show Details
                            </button>
                            {errors["account_number"] && (
                              <p className="text-red-500 text-sm mt-1 ms-7">
                                {errors["account_number"]}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="account_title"
                              className="numberRequestModal flex justify-end mt-4"
                            >
                              * Present Account Title :{" "}
                            </label>
                          </th>
                          <td className="text-left text-blue-500 ps-6 pt-4">
                            {AccountDetails?.account_info?.account_title}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="custome_name"
                              className="numberRequestModal flex justify-end mt-6"
                            >
                              * Customer Name :{" "}
                            </label>
                          </th>
                          <td className="text-left text-blue-500 ps-6 pt-6">
                            {AccountDetails?.account_operator?.customer_name}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="customer_code"
                              className="numberRequestModal flex justify-end mt-6"
                            >
                              * Customer Code :{" "}
                            </label>
                          </th>
                          <td className="text-left text-blue-500 ps-6 pt-6">
                            {AccountDetails?.account_operator?.customer_id}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="customer_mobile"
                              className="numberRequestModal flex justify-end mt-6"
                            >
                              * Customer Mobile No :{" "}
                            </label>
                          </th>
                          <td className="text-left text-blue-500 ps-6 pt-6">
                            {AccountDetails?.contact_info?.mobile_no}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="new_account_title"
                              className="numberRequestModal flex justify-end mt-6"
                            >
                              * NID Number:{" "}
                            </label>
                          </th>
                          <td className= {`${styles.accountDetailsHeader} text-left pt-6`}>
                            <input
                              type="number"
                              id="nid_number"
                              name="nid_number"
                              value={nidNumber}
                              // onChange={(e) => {
                              //     setNidNumber(e?.target?.value);
                              // }}
                              onChange={handleChange}
                              placeholder="Enter NID Number"
                              className={`ms-6 py-2 rounded outline-none ps-2 border w-80`}
                            />
                            {errors["nid_number"] && (
                              <p className="text-red-500 text-sm mt-1 ms-7">
                                {errors["nid_number"]}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className={`${styles.accountDetailsHeader} text-right`}>
                            <label
                              htmlFor="new_account_title"
                              className="numberRequestModal flex justify-end mt-6"
                            >
                              * New Account Title :{" "}
                            </label>
                          </th>
                          <td className="text-left pt-6">
                            <input
                              type="text"
                              id="new_account_title"
                              name="new_account_title"
                              value={newAccountTitle}
                              // onChange={(e) => {
                              //     setNewAccountTitle(e?.target?.value);
                              // }}
                              onChange={handleChange}
                              placeholder="Enter Account Title"
                              className={`ms-6 py-2 rounded outline-none ps-2 border w-80`}
                            />
                            {errors["new_account_title"] && (
                              <p className="text-red-500 text-sm mt-1 ms-7">
                                {errors["new_account_title"]}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className={`p-2  accoutHolderDetails ${
                        isMobileDevice ? "ps-0 w-full" : "ps-10"
                      }`}
                    >
                      <div className={`flex ${isMobileDevice && "flex-wrap"}`}>
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-full" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            Account Number :
                          </p>
                        </div>
                        <div
                          className={`${
                            isMobileDevice ? "w-full mt-2" : "w-2/4"
                          }`}
                        >
                          <input
                            type="text"
                            id="account_number"
                            name="account_number"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e?.target?.value)}
                            placeholder="Enter Account Number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"
                          ></input>
                        </div>
                        <button
                          type="submit"
                          className={`relative border rounded-md bg-blue-600 w-32 p-1 text-white font-medium commonBtn custom_btn_primary py-1 h-9 ${
                            isMobileDevice ? "ms-2 mt-2" : "ms-6"
                          }`}
                          onClick={getAccountDetails}
                        >
                          Show Details
                        </button>
                      </div>
                      <div className="flex mt-5">
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-1/2" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            Present A/C Title :
                          </p>
                        </div>
                        <div
                          className={`${isMobileDevice ? "w-1/2" : "w-2/4 "}`}
                        >
                          <p className="ms-2 text-blue-500">
                            {AccountDetails?.account_info?.account_title}
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-5">
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-1/2" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            Customer Name :
                          </p>
                        </div>
                        <div
                          className={`${isMobileDevice ? "w-1/2" : "w-2/4 "}`}
                        >
                          <p className="ms-2 text-blue-500">
                            {AccountDetails?.account_operator?.customer_name}
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-5">
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-1/2" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            Customer Code :
                          </p>
                        </div>
                        <div
                          className={`${isMobileDevice ? "w-1/2" : "w-2/4 "}`}
                        >
                          <p className="ms-2 text-blue-500">
                            {AccountDetails?.account_operator?.customer_id}
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-5">
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-1/2" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            Customer Mobile No :
                          </p>
                        </div>
                        <div
                          className={`${isMobileDevice ? "w-1/2" : "w-2/4 "}`}
                        >
                          <p className="ms-2 text-blue-500">
                            {AccountDetails?.contact_info?.mobile_no}
                          </p>
                        </div>
                      </div>
                      <div className={`flex ${isMobileDevice && "flex-wrap"}`}>
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-full mt-3" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            NID Number :
                          </p>
                        </div>
                        <div
                          className={`${
                            isMobileDevice ? "w-full mt-2" : "w-2/4"
                          }`}
                        >
                          <input
                            type="text"
                            id="new_account_title"
                            name="new_account_title"
                            value={nidNumber}
                            onChange={(e) => {
                              setNidNumber(e?.target?.value);
                            }}
                            placeholder="Enter NID Number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"
                          ></input>
                        </div>
                      </div>
                      <div className={`flex ${isMobileDevice && "flex-wrap"}`}>
                        <div
                          className={`${
                            isMobileDevice ? "ps-2 w-full mt-3" : "title w-1/5"
                          }`}
                        >
                          <p className="block text-sm font-medium leading-6 text-gray-900">
                            New Account Title :
                          </p>
                        </div>
                        <div
                          className={`${
                            isMobileDevice ? "w-full mt-2" : "w-2/4"
                          }`}
                        >
                          <input
                            type="text"
                            id="new_account_title"
                            name="new_account_title"
                            value={newAccountTitle}
                            onChange={(e) => {
                              setNewAccountTitle(e?.target?.value);
                            }}
                            placeholder="Enter Account Title"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"
                          ></input>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
}
