import React, { useEffect } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import WrittenOfPortfolio from "../../component/WrittenOfPortfolio";
import LoanDocumentStorage from "../../component/LoanDocumentStorage";
import '../../styles/dashboard.scss';
import { getCurrentProcessPerm } from "../../helpers/auth";

const SAMDDepartment = () => {

  const isMobileDevice = window.screen.width <= 900; 

  useEffect(()=>{
    localStorage.setItem("activeTab", "SAMD Department");
  },[])
  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer ${isMobileDevice && 'samdMainCotainer_responsive'}`}>
      <SideMenu headerName="SAMD Department"/>
      <div className={`flex-1 h-screen ${!isMobileDevice && 'overflow-hidden'} ${isMobileDevice && 'overflow-y-auto mainContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-6 responsiveMainContainer'}`}>
          <div className=" flex flex-row justify-between">
            {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
              SAMD Department
            </h1>}
          </div>
          <div className={`flex gap-6 ${isMobileDevice && 'flex-col'}`}>
          {getCurrentProcessPerm(4)?.read && <WrittenOfPortfolio/>}
          {getCurrentProcessPerm(5)?.read && <LoanDocumentStorage/>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(SAMDDepartment);
