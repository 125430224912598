import React from "react";
import * as svg from "../../assets/Icons";

var SvgIcon;

const Icon = function (props) {
  SvgIcon = svg[props.iconName];

  const { height, width, ...rest } = props;

  return (
    <SvgIcon
      {...rest}
      className={props.className}
      fill={props.fill}
      width={width}
      height={height}
    />
  );
};

export default Icon;
