import React, { useState, useEffect } from "react";
import styles from "../styles/common.module.scss"
const CarouselComponent = ({ data }) => {
  const [slide, setSlide] = useState(0);

  // const nextSlide = () => {
  //   setSlide(slide === data.length - 1 ? 0 : slide + 1);
  // };

  // // const prevSlide = () => {
  // //   setSlide(slide === 0 ? data.length - 1 : slide - 1);
  // // };
  const nextSlide = () => {
    setSlide((slide + 1) % data.length);
  };

  const prevSlide = () => {
    setSlide((slide - 1 + data.length) % data.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [slide, data.length]);

  return (
    <div className="relative flex justify-center items-center h-fit " style={{height: '100vh'}}>
      {data.map((item, idx) => {
        return (
          <img
            src={item.src}
            alt={item.alt}
            key={idx}
            className={`${styles.sliderImg} ${idx === slide ? styles.active : styles.hidden}`}
          />
        );
      })}
      {/* <span className="flex absolute bottom-4">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx
                  ? "bg-white h-2 w-12 rounded-2xl border-none outline-none shadow-md cursor-pointer mx-1"
                  : " bg-gray-700 h-2 w-12 rounded-2xl border-none outline-none shadow-md cursor-pointer mx-1"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span> */}
    </div>
  );
};

export default CarouselComponent;
