import React, { useState } from 'react';
import Pagination from "../../../component/Pagination";
import "../../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';
import Icon from '../../../component/core-component/Icon';
import styles from '../../../styles/loanConfig.module.scss';
import "../../../styles/writtenOfPortfolio.scss";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ErrorLogsTable({ rowData, tableLoading, nPages, handlePageChange, currentPage, recordsPerPage, isMobileDevice }) {
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const navigate = useNavigate();
    const currentRecords = rowData

    const editLoanConfig = (loanItem) => {
        navigate(`/createLoanConfig?loan_type_id=${loanItem?.loan_type_id}&plan_id=${loanItem?.id}&status=${loanItem?.status}&page=edit`);
    }

    const viewLoanConfig = (loanItem) => {
        navigate(`/createLoanConfig?loan_type_id=${loanItem?.loan_type_id}&status=${loanItem?.status}&page=view`);
    }

  return (
    <div className={`writtenOfPortfolio`}>
      <div className="">
        {currentRecords?.length > 0 ?
            currentRecords?.map((item, index) => (
                <div className="mt-3">
                  <div className={`borrowersBackgroundCard commonCardLeftBorder border-l-2 bg-white h-20 rounded ${isMobileDevice && 'h-full'}`}>
                    <div className={`borrowersDetailsCard h-20 border rounded ${isMobileDevice && 'h-full'}`}>
                      <div className={`flex ps-4 ${styles.userBorrowDetails} grid grid-cols-12 bg-white ${isMobileDevice && 'responsiveBorrowerDetails'}`}>
                        <div className={`w-full m-2 borrowersName col-span-2 ${isMobileDevice && 'flex justify-between'}`}>
                          <div className="text-black-50 labelColor">
                            {/* Loan Name: */}
                          </div>
                          <div className="mt-1 me-3 font-bold" title={item?.plan_name}>
                            {item?.LoanType?.plan_name}
                          </div>
                        </div>
  
                        <div className={`w-full m-2 col-span-2 ${isMobileDevice && 'flex justify-between'}`}>
                          <div className="text-black-50 text-nowrap mr-2 labelColor">
                            Loan type:
                          </div>
                          <div className="mt-1 userBorrowInfo" title={item?.account_number}>
                            {item?.LoanType?.type}
                          </div>
                        </div>
  
                        <div className={`w-full m-2 col-span-2 ${isMobileDevice && 'flex justify-between'}`}>
                          <div className="text-black-50 text-nowrap mr-2 labelColor">
                            Vsersion:
                          </div>
                          <div className="mt-1 userBorrowInfo" title={item.loan_amount ? parseFloat(item.loan_amount)?.toFixed(2) : ''}>
                            {item.version}
                          </div>
                        </div>
  
                        <div className={`w-full m-2 col-span-2 ${isMobileDevice && 'flex justify-between'}`}>
                          <div className="text-black-50 text-nowrap mr-2 labelColor">
                            Created on:
                          </div>
                          <div className="mt-1 userBorrowInfo" title={item.created_at ? moment(item.created_at).format('YYYY-MM-DD') : ''}>
                            {item.created_at ? moment(item.created_at).format('YYYY-MM-DD | HH:mm') : '--'}
                          </div>
                        </div>
  
                        <div className={`w-full m-2 px-2 col-span-3 ${isMobileDevice && 'flex justify-between branchRow'}`}>
                          <div className="text-black-50 text-nowrap mr-2 labelColor">
                            Updated on:
                          </div>
                          <div className="mt-1 userBorrowInfo" title={item.created_at ? moment(item.updated_at).format('YYYY-MM-DD') : ''}>
                            {item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD | HH:mm') : '--'}
                          </div>
                        </div>
  
                        <div className={`w-full m-2 col-span-1 ${isMobileDevice && 'flex justify-between'}`}>
                          <div className="text-black-50 mr-2 labelColor">
                            {/* Action: */}
                          </div>
                          <div className="ms-2 mt-2 flex">
                              {item?.status == 'INACTIVE' ?
                                <button 
                                    title="View Details" 
                                    onClick={() => editLoanConfig(item)}
                                    >
                                        <Icon iconName="Edit" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                </button>
                                :
                                    <button 
                                        title="View Details" 
                                        onClick={() => viewLoanConfig(item)}
                                    >
                                        <Icon iconName="EyeAlt" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                    </button>
                                }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            :
            <>
                {tableLoading ? 
                <div className="w-full h-full flex items-center justify-center" >
                    <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                    />
                </div>
            :
                <div className="noDataFound">
                    <img
                    src='/assets/noData.svg'
                    alt="no-data"
                    className="noDataImage"
                    />
                    <div className="text-xl font-semibold">
                        No data found
                    </div>
                </div>
            }
            </>
        }
      </div>
        {(!tableLoading && currentRecords?.length > 0) &&
            <Pagination nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            ></Pagination>
        }
      </div>
  )
}