import React from "react";
import { ToastContainer } from "react-toastify";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../../styles/writtenOfPortfolio.scss";
import Icon from "../../../component/core-component/Icon";
import API from "../../../helpers/api";
import moment from "moment";
import { ColorRing } from "react-loader-spinner";
import { WithAuth } from '../../../common/WithAuth';
import Pagination from "../../../component/Pagination";
import { DatePicker } from "antd";
import { encodeData } from "../../../common/commonFunction";
import { closingModal, openingModal } from "../../../redux/actions";
import { useDispatch } from 'react-redux';

import { useSelector } from "react-redux";
import Breadcrumb from "../../../component/Breadcrumb";
const initialData = { created_at: moment(), portfolio_type: 'New' }
const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WrittenOfPortfolio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rawData, setRawData] = useState([]);
  const [totalBorrowersList, setTotalBorrowersList] = useState([]);
  const [showColorActive, setShowColorActive] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [searchedBorrowerData, setSearchedBorrowerData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [nPages, setNpages] = useState(1);
  const [openCreatePortfolioModal, setOpenCreatePortfolioModal] = useState(false);
  const [portfolioCreationData, setPortfolioCreationData] = useState(initialData);
  const [portfolioError, setPortfolioError] = useState({created_at_error: '', portfolio_type_error: ''});
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 1024)
  // const isMobileDevice = window.screen.width <= 900;
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
  const steps = ["SAMD Department", "Written off Portfolio" ,showColorActive ? "Active" : "Closed"];

  useEffect(() => {
    setCurrentPage(1);
    const handleResize = () => {
      let windowCurrentWidth = window.innerWidth <= 1024
      setIsMobileDevice(windowCurrentWidth);
    }
    window.addEventListener('resize', handleResize);
  }, [])

  const currentRecords = rawData

  function handlePageChange(currentPage) {
    setRawData([]);
    setCurrentPage(currentPage);
    getData(currentPage);
  }

  useEffect(() => {
    setListLoading(true);
    let currentTab = localStorage.getItem('showActiveTab')
    if (currentTab) {
      setShowColorActive(JSON.parse(currentTab));
    } else {
      localStorage.setItem('showActiveTab', true);
    }
    getData(1);
  }, []);

  const getData = async (currentPage) => {
    try {
      setListLoading(true);
      let currentTab = localStorage.getItem('showActiveTab')
      currentTab = JSON.parse(currentTab)
      let status = currentTab ? 'Active' : 'Closed'
      const response = await API.apiGet(`/loans/write/off/portfolio?limit=${recordsPerPage}&skip=${currentPage}&status=${status}`);
      if (response?.data?.success) {
        setTotalBorrowersList(response?.data?.data);
        setNpages(response?.data?.totalPageCount);
        setRawData(response?.data?.data);
        setListLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setListLoading(false);
    }
  };

  const handleActiveClick = () => {
    setRawData([]);
    localStorage.setItem('showActiveTab', true);
    setShowColorActive(true);
    setSearchedBorrowerData('');
    setCurrentPage(1);
    getData(1);
  };

  const handleCloseClick = () => {
    setRawData([]);
    localStorage.setItem('showActiveTab', false);
    setShowColorActive(false);
    setSearchedBorrowerData('');
    setCurrentPage(1);
    getData(1);
  };

  const ViewPortfolio = (id) => {
    navigate(`/viewPortfolio?id=${id}`);
  };

  const handleBack = () => {
    navigate('/samdDepartment');
    localStorage.removeItem('showActiveTab');
  };

  const getFilteredBorrowerList = (event) => {
    setCurrentPage(1);
    let value = (event?.target?.value);
    setSearchedBorrowerData(value);
    let filteredData;
    if (value) {
      value = value.toLowerCase();
      filteredData = totalBorrowersList?.filter((item) => {
        const isMatch = (item?.borrower_name?.toLowerCase())?.includes(value) || (item?.account_number?.toLowerCase())?.includes(value) || (item?.key_person?.toLowerCase())?.includes(value) || (item?.branch?.toLowerCase())?.includes(value);
        const isStatusMatch = showColorActive ? item?.status === 'Active' : item?.status === 'Closed';
        return isMatch && isStatusMatch;
      });
    } else {
      filteredData = totalBorrowersList?.filter((item) => showColorActive ? item?.status === 'Active' : item?.status === 'Closed');
    }
    setRawData(filteredData);
  };

  const setData = (item, key) => {
    let data = item?.[key];
    if (data) {
      return data?.length > 6 ? `${data?.substring(0, 4)}...` : data;
    } else {
      return '--';
    }
  };

  const getTabelData = (data) => {
    let finalValue = '--'
    if (data) {
      if (data?.length > 20) {
        finalValue = `${data?.substring(0, 15)}...`
      } else {
        finalValue = data
      }
    }
    return finalValue;
  }

  const addNewPortfolio = () => {
    setOpenCreatePortfolioModal(true);
    dispatch(openingModal())
  }

  const closeCreatePortfolioModal = () => {
    setOpenCreatePortfolioModal(false);
    setPortfolioCreationData(initialData);
    dispatch(closingModal())
    resetErrors()
  }

  useEffect(() => {
    if (portfolioCreationData.portfolio_type === 'New') {
      setPortfolioCreationData(prev => ({
        ...prev,
        created_at: moment()
      }));
    }
  }, [portfolioCreationData?.portfolio_type, openCreatePortfolioModal]);

  const handlePortfolioData = (event, dateFiled) => {
    if (dateFiled) {
      setPortfolioCreationData(prev => ({
        ...prev,
        created_at: event
      }))
      setPortfolioError(prev => ({
        ...prev,
        created_at_error: ''
      }));
    } else {
      setPortfolioCreationData(prev => ({
        ...prev,
        [event.target.name]: event.target.value
      }))
      if (event?.target?.name == 'portfolio_type') {
        setPortfolioCreationData(prev => ({
          ...prev,
          created_at: event.target.value == 'New' ? moment() : ''
        }))
        setPortfolioError(prev => ({
          ...prev,
          created_at_error: ''
        }));
      }
      setPortfolioError(prev => ({
        ...prev,
        portfolio_type_error: ''
      }));
    }
  }

  const getCurrentDate = (current ) => {
    return current && current > moment().endOf('day')
  }; 

  const resetErrors = () => {
    setPortfolioError({
      created_at_error: '',
      portfolio_type_error: '',
    });
  };


  const createNewPortfolio = () => {
    resetErrors();
    if (portfolioCreationData?.created_at != '' && portfolioCreationData?.portfolio_type != '') {
      navigate(`/createPortfolio?portfolio_data=${encodeData(portfolioCreationData)}`)
    } else {
      if (portfolioCreationData?.created_at == '') {
        setPortfolioError(prev => ({
          ...prev,
          created_at_error: 'Please select a date'
        }))
      } else if (portfolioCreationData?.portfolio_type == '') {
        setPortfolioError(prev => ({
          ...prev,
          portfolio_type_error: 'Please select portfolio type'
        }))
      }
    }
  }

  return (
    <div className="flex dark:bg-dark-background writtenOfPortfolio mainBodyContainer">
      {openCreatePortfolioModal &&
        <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" style={listLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Create Portfolio
                </h3>
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeCreatePortfolioModal}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-4 md:p-5 space-y-4">
                <div className="">
                  <div className={`flex gap-14`}>
                    <div className={`flex`}>
                      <input
                        id="existing_port"
                        type="radio"
                        value='Existing'
                        onChange={(e) => handlePortfolioData(e)}
                        name='portfolio_type'
                        className={`me-2`}
                        checked={portfolioCreationData?.portfolio_type == 'Existing'}
                      />
                      <label htmlFor="existing_port" className={`cursor-pointer font-medium`} >Existing Account</label>
                    </div>

                    <div className={`flex`}>
                      <input
                        id="new_port"
                        type="radio"
                        value='New'
                        onChange={(e) => handlePortfolioData(e)}
                        name='portfolio_type'
                        className={`me-2`}
                        checked={portfolioCreationData?.portfolio_type == 'New'}
                      />
                      <label htmlFor="new_port" className={`cursor-pointer font-medium`} >New Account</label>
                    </div>
                  </div>
                  <div className={`flex flex-col mt-3`}>
                    <label className={`text-sm font-semibold mb-1 ms-1`}>Created On</label>
                    <DatePicker
                      id="createdAt"
                      name="created_at"
                      format='YYYY-MM-DD, hh:mm A'
                      value={portfolioCreationData.created_at}
                      showTime
                      onChange={(e) => handlePortfolioData(e, true)}
                      disabledDate={getCurrentDate}
                      placeholder="Select Date"
                      className={`py-1 rounded outline-none ps-2`}
                      disabled={portfolioCreationData?.portfolio_type == 'New'}
                    />
                    {portfolioError?.created_at_error != '' && <span className={`text-red-500`}>{portfolioError?.created_at_error}</span>}
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="static-modal"
                  type="button"
                  className="py-2.5 px-5 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 custom_btn_secondary"
                  onClick={closeCreatePortfolioModal}
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="static-modal"
                  type="button"
                  className="ms-3 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center commonBtn custom_btn_primary"
                  onClick={() => createNewPortfolio()}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <SideMenu headerName="Written Off Portfolio" />
      <div className={`min-h-screen flex-1 overflow-hidden samdDefaultHeight ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header />
        {listLoading &&
          <div className="absolute flex items-center top-1/2 loadingComponent justify-center" >
            <ColorRing
              visible={true}
              height="80"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={[]}
            />
          </div>}
        <div className={`z-0 pt-10 px-10 pb-2 ${listLoading ? 'blurContent' : ''} mainContainer ${isMobileDevice && 'mainContainerForMobileDevice'}`}>

          <div className="flex items-center">
            <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center" onClick={() => handleBack()}>
              <Icon iconName="IconBackBtn" width={16} height={16} />
            </button>
            <div className="ms-2">
              <Breadcrumb steps={steps} activeStep={2} />
            </div>
          </div>

          <div className={`flex justify-end items-center ${isMobileDevice && 'mt-5 justify-between'}`}>
            <div className={`searchButton flex flex-row-reverse me-4 ${isMobileDevice && 'responsiveSearchButton'}`}>
              <div className="flex relative rounded">
                <span className="searchLogo absolute">
                  <Icon iconName="searchIconBlue" height={20} width={20} fill={themeDetails?.color_config?.main_color}/>    
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="numberSearch h-11"
                  onChange={getFilteredBorrowerList}
                  value={searchedBorrowerData}
                ></input>
              </div>
            </div>
            <div className="">
              <button
                type="button"
                className={`createPortFolioBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn ${isMobileDevice && 'responsiveCreatePortfolioBtn'}`}
                onClick={() =>{  dispatch(openingModal());addNewPortfolio();}}
              >
                Create Portfolio
              </button>
            </div>
          </div>

          <div className=" flex mt-2 tabContainer">
            <div className="differentTabs flex">
              <div
                className={`w-24 p-2 text-center tabsName ${showColorActive ? "showActiveColor commmonActiveTag" : ""
                  }`}
              >
                <p onClick={handleActiveClick} className="mt-2 cursor-pointer">
                  Active
                </p>
              </div>
              <div
                className={`w-24 p-2 text-center tabsName ${showColorActive ? "" : "showActiveColor commmonActiveTag"
                  }`}
              >
                <p onClick={handleCloseClick} className="mt-2 cursor-pointer">
                  Closed
                </p>
              </div>
            </div>
          </div>

          {currentRecords?.length > 0 ?
            currentRecords?.map((item, index) => (
              <div className="mt-3">
                <div className={`borrowersBackgroundCard commonCardLeftBorder h-20 rounded ${isMobileDevice && 'h-full'}`}>
                  <div className={`borrowersDetailsCard h-20 border rounded ${isMobileDevice && 'h-full'}`}>
                    <div className={`flex ps-4 userBorrowDetails ${isMobileDevice && 'responsiveBorrowerDetails'}`}>
                      <div className={`w-full m-2 borrowersName ${isMobileDevice && 'flex justify-between'}`}>
                        <div className="text-black-50 labelColor">
                          Name:
                        </div>
                        <div className="mt-1 me-3 userBorrowInfo" title={item?.borrower_name}>
                          {setData(item, 'borrower_name')}
                        </div>
                      </div>

                      <div className={`w-full m-2 ${isMobileDevice && 'flex justify-between'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Account Number:
                        </div>
                        <div className="mt-1 userBorrowInfo" title={item?.account_number}>
                          {/* {item.account_number ? item?.account_number : '--'} */}
                          {getTabelData(item.account_number)}
                        </div>
                      </div>

                      <div className={`w-full m-2 ms-8 marginStartZero ${isMobileDevice && 'flex justify-between'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Loan Amount:
                        </div>
                        <div className="mt-1 userBorrowInfo" title={item.loan_amount ? parseFloat(item.loan_amount)?.toFixed(2) : ''}>
                          {item.loan_amount ? parseFloat(item.loan_amount)?.toFixed(2) : '--'}
                        </div>
                      </div>

                      <div className={`w-full m-2 ms-8 marginStartZero ${isMobileDevice && 'flex justify-between'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Key Person:
                        </div>
                        <div className="mt-1 userBorrowInfo" title={item?.key_person}>
                          {setData(item, 'key_person')}
                        </div>
                      </div>

                      <div className={`w-full m-2 ms-8 px-2 marginStartZero ${isMobileDevice && 'flex justify-between branchRow'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Branch:
                        </div>
                        <div className="mt-1 userBorrowInfo" title={item?.branch}>
                          {setData(item, 'branch')}
                        </div>
                      </div>

                      <div className={`w-full m-2 px-2 ${isMobileDevice && 'flex justify-between branchRow'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Existing Account:
                        </div>
                        <div className="mt-1 userBorrowInfo" title={item?.existing_account}>
                          {item?.existing_account ? 'Yes' : 'No'}
                        </div>
                      </div>

                      <div className={`w-full m-2 ${isMobileDevice && 'flex justify-between createdOnRow'}`}>
                        <div className="text-black-50 text-nowrap mr-2 labelColor">
                          Created On:
                        </div>
                        <div className="mt-1 userBorrowInfo">
                          {item.created_at ? moment.utc(item.created_at).tz(clientTimeZone).format('YYYY-MM-DD, hh:mm A') : '--'}
                        </div>
                      </div>

                      <div className={`w-full m-2 flex flex-col jusitfy-center items-center eyeIcon ${isMobileDevice && 'flex justify-between'}`}>
                        <div className="text-black-50 mr-2 labelColor">
                          View:
                        </div>
                        <div className="mt-2">
                          <button title="View Details" onClick={() => ViewPortfolio(item?.id)}>
                            <Icon iconName="EyeAlt" width={16} height={16} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            :
            !listLoading &&
            <div className="noDataFound">
              <img
                src='/assets/noData.svg'
                alt="no-data"
                className="noDataImage"
              />
              <div className="text-xl font-semibold">
                No data found
              </div>
            </div>
          }

          {!listLoading && currentRecords?.length > 0 &&
            <Pagination nPages={nPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            ></Pagination>}
        </div>

      </div>
      <ToastContainer />
    </div>
  );
};
export default WithAuth(WrittenOfPortfolio);