// Breadcrumb.js
import React from "react";
import SideMenu from "../component/SideMenu";
import Header from "../component/Header";

const AccessDeniedComponent = () => {

  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main`}>
      <SideMenu headerName="" />
      <div className="h-screen flex-1 overflow-hidden">
        <Header />
        <div className="px-7 flex justify-center items-center h-full">
          <div className="flex flex-col mt-4 px-5 py-3 accessDeniedComponent justify-center leading-8">
            <img className="mt-3" src="/assets/images/access-denied.svg" alt="access denied" />
            <span className="font-semibold text-3xl mt-2">Access Denied</span>
            <span className="deniedMsg font-semibold mt-5">You don't have the permission for this particular module/feature</span>
            <span className="deniedMsg font-semibold">Please contact your administrator</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDeniedComponent;
