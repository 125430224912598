import React from "react";
import Switch from '@mui/joy/Switch';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/ivrTable.scss";
import Icon from "../../component/core-component/Icon";
import API from "../../helpers/api/index";
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../component/Pagination";
import moment from "moment";
import { ColorRing } from 'react-loader-spinner';
import debounce from 'lodash/debounce';
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import Validation from "../../utils/Validation"
import { WithAuth } from "../../common/WithAuth";
import { closingModal, openingModal } from "../../redux/actions";
import { useDispatch, useSelector } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
const UpdateIvrTable = () => {

  const dispatch = useDispatch();
  const steps = ["Services","IVR", "Workflow"];
  const [showModal, setShowModal] = useState(false);
  const [ivrConfigData, setIvrConfigData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [inputs, setInputs] = useState([{ response: "", message: "" }]);
  const [formErrors, setFormErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = ivrConfigData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = ivrConfigData?.length ? Math.ceil(ivrConfigData?.length / recordsPerPage) : 0;
  const [isWorkflowCreated, setIsWorkflowCreated] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth<=900);
  const [isView,setIsView]=useState(null)
  const moreOutlinedRefs = useRef([]);
  const openViewRef = useRef(null);
  const handleResize = () => {
    if(window.innerWidth <= 900) {
      setIsMobileDevice(true);
    }
    else {
      setIsMobileDevice(false);
    }
  };

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
  },[]);
  
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const closeModal = () => {
    setShowModal(false);
    setValues({});
    setInputs([{ response: "", message: "" }])
    setFormErrors({});
    dispatch(closingModal())
  };
  useEffect(() => {
    getIvrConfigurationData();
    setDataLoading(true);
  }, []);

  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
  }

  const getIvrConfigurationData = async () => {
    try {
      const { data } = await API.apiGet(`/v1/ivr`);
      setIvrConfigData(data.data?.filter(item => item?.status == 'Active'));
      if (data?.success) {
        toast.success(data?.message);
        setDataLoading(false);
      } else {
        toast.error(data?.message);
        setDataLoading(false);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
      setDataLoading(false);
    }
  };

  const debounceClick = () => {
    setDeleteBtn(true);
    const debouncedDeleteIvr = debounce(() => deleteIvr(), 5000);
    debouncedDeleteIvr(); // Call the debounced function
}

  const [values, setValues] = useState({
    workflowName: "",
    welcomeMessage: "",
    ivr_service_provider: "",
    predefined: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(event.target.id == "predefined") {
      if(!event.target.checked) {}
      setValues((prevValues) => ({
        ...prevValues,
        'predefined': event.target.checked
      }))
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        'predefined': '', 
      }));
    }
    else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '', 
      }));
    }
  };

  const handleResponseChange = (index, e) => {
    const newInputs = [...inputs];
    newInputs[index].response = e.target.value;
    setInputs(newInputs);
  };

  const handleResponseInput = (index, e) => {
    const newInputs = [...inputs];
    newInputs[index].message = e.target.value;
    setInputs(newInputs);
  };

  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const addInput = () => {
    setInputs([...inputs, { response: '', message: '' }]);
  };

  const validateData = () => {
    const errors = {};
    if(!values?.workflowName) {
      errors.workflowName = "WorkFlow name is required"
    }
    // if(values?.workflowName?.length && !Validation?.validateName(values?.workflowName)){
    //   errors.workflowName = "WorkFlow name should not contain special character"
    // }
    if(!values?.welcomeMessage) {
      errors.welcomeMessage = "Welcome Message is required"
    } 
    // if(values?.welcomeMessage?.length && !Validation?.validateName(values?.welcomeMessage)){
    //   errors.welcomeMessage = "Welcome Message should not contain special character"
    // }
    
    if(!values?.ivr_service_provider) {
      errors.ivr_service_provider = "IVR Service Provider is required"
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async () => {
    setIsWorkflowCreated(true);
    if(validateData()){
      let payload = {};
      inputs.forEach(item => {
        for (let key in item) {
          if (key.startsWith("message")) {
              if (!['*', '#']?.includes(item.response)) {
                  payload[`${key}_for_${item.response}`] = item[key];
              } else {
                  if (item?.response == '*') {
                      payload[`${key}_for_star`] = item[key]
                  } 
                  if (item?.response == '#') {
                      payload[`${key}_for_hash`] = item[key]
                  }
              }
          }
        }
      });
      payload['workflow_name'] = values?.workflowName;
      payload['welcome_message'] = values?.welcomeMessage;
      payload['ivr_service_provider'] = values?.ivr_service_provider;
      payload['predefined'] = values?.predefined;
      let finalPayload ={
        payload: encodeData(payload)
      }
      try {
        const { data } = await API.apiPost(`/v1/ivr`, finalPayload);
        if (data?.success) {
          setShowModal(false);
          getIvrConfigurationData();
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } catch (error) {
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.message);
      }
    }
    setIsWorkflowCreated(false);
  };

  function openViewIvr(currentRecord) {
    let id = currentRecord?.id;
    navigate(`/ivrView?action=view&id=${id}`);
  }

  function openEditIvr(currentRecord) {
    let id = currentRecord?.id;
    navigate(`/ivrView?action=edit&id=${id}`);
  }

  const deleteIvr = async() => {
    let currentRecord = deleteItem;
    let id = currentRecord?.id;
    try {
      const { data } = await API.apiDelete(`/v1/ivr/${id}`);
      getIvrConfigurationData();
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
    setDeleteBtn(false)
    setDeleteItem(null);
    setShowDeleteModal(false);
  };

  const deleteConfirm = (item) => {
    if(item.predefined===true){
      return;
    }
    else{
      setShowDeleteModal(true);
      setDeleteItem(item)
    }
  }

  const handleBack = () => {
    navigate(`/services`);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeleteItem(null)
  }

//   const removeInput = (index) => {
//     const newInputs = [...inputs];
//     newInputs.splice(index, 1);
//     setInputs(newInputs);
//   };

  const handleNewWorkflow = () => {
    setShowModal(true);
    setValues({
      workflowName: "",
      welcomeMessage: "",
      ivr_service_provider: "",
      predefined: false,
    });
    const emptyInput = inputs?.map(obj => ({ ...obj, response: "", message: "" }));
    setInputs(emptyInput);
    dispatch(openingModal())
  };
  const toggleView = (index) => {
    setIsView(prevIndex => (prevIndex === index ? null : index));
  };
  const handleOutsideClick = (e) => {
    if (
        moreOutlinedRefs.current.every(ref => ref && !ref.contains(e.target)) &&
        openViewRef.current &&
        !openViewRef.current.contains(e.target)
    ) {
        setIsView(null);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
        document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  
  return (
    <>
    {
      showDeleteModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ">
        <div class="relative p-4 w-full max-w-2xl max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" >        
                        <div class="items-center p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                              Delete
                            </h3>
                            <p className="pt-6 font-bold">Are your sure you want to delete ?</p>
                            <div className="pt-6 pb-3 flex justify-end">
                              <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" type="button" onClick={() => handleDeleteCancel()}>Cancel</button>
                              <button className={`${deleteBtn ? `ms-3 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed` : `ms-3 text-white font-bold py-2 px-4 rounded`}`} type="button" style={{backgroundColor:"#02839f"}} onClick={debounceClick} disabled={deleteBtn}>Delete</button>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            }
    <div className={`flex dark:bg-dark-background updateIvrContainer mainBodyContainer`}>
      <SideMenu headerName="Workflow"/>
      <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header />
        <div className={`z-0 p-10 mainContainer ${!isMobileDevice && 'container fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
        <Breadcrumb
              steps={steps}
              activeStep={2}
              showBackButton={true}
              redirectUrl={"/IVRWorkflow"}
            />
          <div className={`flex flex-row justify-between userHeading py-3 ${isMobileDevice && 'workflowHeader_container'}`}>
          <div className="flex manageIvr">
            {/* {!isMobileDevice && 
              <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand ms-2 text-24 font-bold leading-30 userManagementHeading">
                Workflow Management
              </h1>
            } */}
            </div>
            <div>
              <button
                className={` hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded disabled:disabledBtn custom_btn_primary commonBtn`}
                onClick={handleNewWorkflow}
              >
                Create New Workflow +
              </button>
            </div>
            {showModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
              >
                <div class={`relative p-4 w-2/5 max-w-2xl max-h-full ${isMobileDevice && 'createNewWorkflowResponsiveModal'}`}>
                  <div class="relative createWorkflowModal rounded-lg shadow dark:bg-gray-700">
                    <div class="flex items-center justify-between ps-4 pe-4 pt-4 rounded-t dark:border-gray-600">
                      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                        Create New Workflow
                      </h3>
                      <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="static-modal"
                        onClick={closeModal}
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className=" w-11/12 m-auto mt-3 modal">
                      <div className="bg-white rounded-lg p-3">
                        <div>
                          <label
                            htmlFor="workflowName"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Workflow name
                          </label>
                          <div>
                            <input
                              type="text"
                              name="workflowName"
                              placeholder="Workflow name"
                              value={values.workflowName}
                              id="workflowName"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.workflowName ? 'red' : 'inherit' }}>{formErrors?.workflowName}</span>
                        </div>
                        <div className="mt-3">
                          <label
                            htmlFor="welcomeMessage"
                            className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                          >
                            Welcome Message
                          </label>
                          <div>
                            <textarea
                              name="welcomeMessage"
                              id="welcomeMessage"
                              placeholder="Welcome Message..."
                              onChange={handleInputChange}
                              className="block w-full h-16 rounded-md border-0 ps-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 p-1 outline-none"
                            ></textarea>
                          </div>
                          <span className='numberRequestModal'  style={{ color: formErrors?.welcomeMessage ? 'red' : 'inherit' }}>{formErrors?.welcomeMessage}</span>
                        </div>
                        <div className="mt-3">
                          <div>
                            <label
                              htmlFor="ivr_service_provider"
                              className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                            >
                              IVR Service Provider
                            </label>
                            <select
                              name="ivr_service_provider"
                              id="ivr_service_provider"
                              placeholder="Select service provider"
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 ps-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 p-1 outline-none"
                            >
                              <option value="" hidden>Select service provider</option>
                              <option value="twilio">Twilio</option>
                              <option value="amber it">Amber IT</option>
                            </select>
                            <span className='numberRequestModal'  style={{ color: formErrors?.ivr_service_provider ? 'red' : 'inherit' }}>{formErrors?.ivr_service_provider}</span>
                          </div>
                          <div className="mt-3">
                            <label
                              htmlFor="predefined"
                              className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                            >
                              Predefined 
                            </label>
                            <Switch
                              // checked={isChecked}
                              id="predefined"
                              name="predefined"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {inputs?.map((input, index) => (
                          <div
                            key={index}
                            className="mt-3 bg-white p-4 ps-3 pe-3 rounded-lg"
                          >
                            <div className="flex relative">
                              <div>
                                <label
                                  htmlFor={`response${index}`}
                                  className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                                >
                                  User Input
                                </label>
                                <div>
                                  <select
                                    id={`response${index}`}
                                    name="response"
                                    value={input.response}
                                    onChange={(e) =>
                                      handleResponseChange(index, e)
                                    }
                                    className="block rounded-md border-0 p-1 h-7 pe-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6 outline-none"
                                  >
                                    <option className="hidden">
                                      Select Response
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>0</option>
                                    <option>*</option>
                                    <option>#</option>
                                  </select>
                                </div>
                              </div>
                              <div className="absolute right-0">
                                <img
                                  src="assets/images/minusIcon.svg"
                                  alt="minus icon"
                                  onClick={() => removeInput(index)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            <label
                              htmlFor={`message${index}`}
                              className="block text-sm font-medium font-semibold leading-6 text-gray-900"
                            >
                              Message
                            </label>
                            <textarea
                              id={`message${index}`}
                              name="message"
                              value={input.message}
                              onChange={(e) => handleResponseInput(index, e)}
                              placeholder="Response Message..."
                              className="block w-full h-16 rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 outline-none"
                            />
                          </div>
                        ))}
                        <div className="mt-3 ms-5 me-5 ">
                          {/* <button
                            onClick={addInput}
                            className="border border-blue-800 rounded-md w-28 text-sm p-1 text-blue-800"
                          >
                            +Add Input
                          </button> */}
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-end items-center p-4 md:p-5 border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        data-modal-hide="static-modal"
                        type="button"
                        class="custom_btn_secondary py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        data-modal-hide="static-modal"
                        type="button"
                        className="text-white items-center h-10 ms-3 flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn"
                        onClick={handleSubmit}
                        disabled={isWorkflowCreated}
                      >
                        {
                          isWorkflowCreated && 
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['white', 'white', 'white', 'white', 'white', 'white']}
                          />
                        }
                        Create  
                      </button>
                      <button
                            onClick={addInput}
                            className="text-white items-center h-10 ms-3 flex bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn"
                          >
                            Add Input
                          </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" mt-4">
          {!currentRecords ? 
            <>
              <table
              className={`min-w-full border-solid border-gray-border text-left ivrTable`}
              >
              <thead>
                <tr className="ps-2 pe-2">
                  <th
                    className="py-3 px-4 capitalize w-1/4"
                    style={{ fontSize: "16px" }}
                  >
                    Workflow Name
                  </th>
                  <th
                    className="py-3 px-4 capitalize w-1/4"
                    style={{ fontSize: "16px" }}
                  >
                    Workflow Message
                  </th>
                  <th
                    className="py-3 px-4 capitalize w-1/5"
                    style={{ fontSize: "16px" }}
                  >
                    Created On
                  </th>
                  <th
                    className="py-3 px-4 capitalize w-1/5"
                    style={{ fontSize: "16px" }}
                  >
                    Updated On
                  </th>
                  <th
                    className="py-3 px-4 capitalize w-1/4"
                    style={{ fontSize: "16px" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              </table>
            {dataLoading ? 
              <div className="bg-white flex items-center top-1/2 left-1/2 justify-center" >
                <ColorRing
                  visible={true}
                  height="80"
                  width="60"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[]}
                />
              </div>
              :
              <div className="bg-white flex justify-center py-5 font-semibold" >
                No data found
              </div>
            }
            </>
            :
            <>
              {!isMobileDevice ? (
                <table
                  className={`min-w-full border-solid border-gray-border text-left ivrTable`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className="py-3 px-4 capitalize w-1/4"
                        style={{ fontSize: "16px" }}
                      >
                        Workflow Name
                      </th>
                      <th
                        className="py-3 px-4 capitalize w-1/4"
                        style={{ fontSize: "16px" }}
                      >
                        Workflow Message
                      </th>
                      <th
                        className="py-3 px-4 capitalize w-1/5"
                        style={{ fontSize: "16px" }}
                      >
                        Created On
                      </th>
                      <th
                        className="py-3 px-4 capitalize w-1/5"
                        style={{ fontSize: "16px" }}
                      >
                        Updated On
                      </th>
                      <th
                        className="py-3 px-4 capitalize w-1/4"
                        style={{ fontSize: "16px" }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords?.map(
                      (item, index) =>
                      (
                        <tr style={{ backgroundColor: "white" }} key={index} className="border-b">
                          <td className="p-4">{item.workflow_name}</td>
                          <td className="p-4">{item.welcome_message}</td>
                          <td className="p-4">
                            {moment(item.created_at).format("YYYY-MM-DD | HH:mm:ss")}
                          </td>
                          <td className="p-4">
                            {moment(item.updated_at).format("YYYY-MM-DD | HH:mm:ss")}
                          </td>
                          <td className="p-4 relative text-center">
                                <MoreOutlined
                                  ref={el => { moreOutlinedRefs.current[index] = el;}}
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleView(index);
                                  }}
                                />
                              {isView===index && (
                                  <div className="openView" ref={openViewRef}>
                                    <button onClick={() => openViewIvr(item)} className="flex justify-around py-0.5 items-center text-black border-b">
                                      <div className="actionImages">
                                        <img src="/assets/images/newEye.svg" alt="view" />
                                      </div>
                                      <span>View</span>
                                    </button>
                                    <button onClick={() => openEditIvr(item)} className="flex justify-around py-0.5 items-center text-black border-b">
                                      <div className="actionImages">
                                        <img src="/assets/images/newPen.svg" alt="edit" />
                                      </div>
                                      <span>Edit</span>
                                    </button>             
                                    {item.predefined===false && <button onClick={() => deleteConfirm(item)} className="flex justify-around py-0.5 items-center text-red-500">
                                      <div className={`${item.predefined === true ? 'disabledButton' : ''} actionImages`} title="Delete workflow">
                                        <img src="/assets/images/newTrash.svg" alt="delete" />
                                      </div>
                                      <span>Delete</span>
                                    </button> }
                                    {/* <button onClick={() => deleteConfirm(item)} className="flex justify-around items-center">
                                      <div className={`${item.predefined === true ? 'disabledButton' : ''} actionImages`} title="Delete workflow">
                                        <img src="/assets/images/newTrash.svg" alt="delete" />
                                      </div>
                                      <span>Delete</span>
                                    </button> */}
                                  </div>
                                )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <div>
                  {currentRecords?.map((item, index) => (
                    <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                      <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                        <span className="font-bold">Workflow Name</span>
                        <div className="flex items-center justify-between">
                          <span>{item?.workflow_name}</span> 
                        </div>
                      </div>
                      <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                        <span className="font-bold">Workflow Message</span>
                        <div className="flex items-center justify-between">
                          <span>{item?.welcome_message}</span> 
                        </div>
                      </div>
                      <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                        <span className="font-bold">Created On</span>
                        <div className="flex items-center justify-between">
                          <span>{moment(item.created_at).format("YYYY-MM-DD | HH:mm:ss")}</span> 
                        </div>
                      </div>
                      <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                        <span className="font-bold">Updated On</span>
                        <div className="flex items-center justify-between">
                          <span>{moment(item.updated_at).format("YYYY-MM-DD | HH:mm:ss")}</span> 
                        </div>
                      </div>
                      <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                        <span className="font-bold">Actions</span>
                        <div className="flex items-center justify-between">
                          <div>
                            <button onClick={() => openViewIvr(item)}>
                              <div className="actionImages"><img src="/assets/images/newEye.svg"></img></div>
                            </button>
                            <button onClick={() => openEditIvr(item)}>
                              <div className="actionImages"><img src="/assets/images/newPen.svg"></img></div>
                            </button>
                            <button onClick={() => deleteConfirm(item)}>
                              <div className="actionImages"><img src="/assets/images/newTrash.svg"></img></div>
                            </button>
                          </div> 
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          }
          </div>
          {!dataLoading && currentRecords && currentRecords?.length > 0 &&
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          ></Pagination>
          }
        </div>
      </div>
    </div>
    <ToastContainer></ToastContainer>
    </>
  );
};
export default WithAuth(UpdateIvrTable);
