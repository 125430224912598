// Navigation
// import { ToastContainer } from "react-toastify";
import RootNavigator from "./navigation/RootNavigation";

const App = function (props) {
  return (
    <>
      <RootNavigator />
      {/* <ToastContainer autoClose={3000} /> */}
    </>
  );
};

export default App;
