import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/reconcilition.scss";
import WorkerQueBoard from "../dashboard/WorkerQueBoard";
import Breadcrumb from "../../component/Breadcrumb";
import QueueCardsCommon from "../../component/QueueCardsCommon";
import ConfigurationModal from "../../component/ConfigurationModal";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import API from "../../helpers/api";
import moment from 'moment-timezone';

function QueueConfiguration() {

    const isMobileDevice = window.screen.width <= 900;
    const [showConfigurationModal,setShowConfigurationModal] = useState(false)
    const [time, setTime] = useState({
        start: null,
        end: null,
    })
    const [showLoading, setShowLoading] = useState(false);

    const handleQueueConfigurations = () => {
        setShowConfigurationModal(!showConfigurationModal);
    }
    function getClientTimeZone() {
        return moment.tz.guess();
    }

    function formatDateTime(dateTime) {
        let timeZone = getClientTimeZone()
        return moment(dateTime).tz(timeZone).format()
    }

    const handleDurationUpdate = async () => {
        setShowLoading(true)
        const payload = {
            startTime: formatDateTime(time.start),
            endTime: formatDateTime(time.end),
            timeZone: getClientTimeZone()
        }
        try {
            const response = await API.apiPut('/configureQueues/queue/schedule', {payload: encodeData(payload)})
            if (response?.data?.success) {
                toast.success(response?.data?.message) 
                setShowConfigurationModal(false)
            }
            else {
                toast.error(response?.data?.error)
            }
            setShowLoading(false)
        }
        catch (error) {
            setShowLoading(false)
            console.log(error)
            handleErrorMessage(error);
        }
    }

    const getQueueUpDuration = async () => {
        try {
            const clientTimeZone = getClientTimeZone();
            const response = await API.apiGet(`/configureQueues/queue/schedule`,'',{timeZone: clientTimeZone})
            if (response?.data?.success) {
                // setShowNoData(false)
                setTime({start: response?.data?.scheduleTime?.startTime, end: response?.data?.scheduleTime?.endTime})
            }
            else {
                // setShowNoData(true)
            }
        }
        catch (error) {
            // setShowNoData(true)
            handleErrorMessage(error);
        }
    }

    useEffect(() => {
        getQueueUpDuration();
    },[])

    return (
        <>
            <div className={`mainBodyContainer flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part`}>
                <SideMenu headerName="Minor Account Configuration"/>
                <div className="flex-1">
                    <Header/>
                    <div className={`z-0 container p-10 relative mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'} fixedHeightContainer`} >
                       <div className="flex justify-between">
                            <Breadcrumb showBackButton redirectUrl="/queueConfiguration" steps={["Common Account Configuration"]}/>
                            <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center" onClick={handleQueueConfigurations}>
                                <img src="/assets/images/configureSetting.svg" alt="configureSettings"/>
                            </button>
                       </div>
                        <QueueCardsCommon />
                        {showConfigurationModal && <ConfigurationModal loading={false} closeModal={handleQueueConfigurations} handleSubmit={handleDurationUpdate} time={time} setTime={setTime} showLoading={showLoading}/>}
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(QueueConfiguration);
