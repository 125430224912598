import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/reconcilition.scss";
import DataTable from "../../component/DataTable";
import Pagination from "../../component/Pagination";
import API from "../../helpers/api";
import { handleErrorMessage } from "../../common/commonFunction";
import ErrorLogsTable from "./ErrorLogsTable";
import ErrorLogDownloadModal from "./errorLogDownloadModal";
import { formatDateToIso } from "../../common/commonFunction";
import CustomDateRangeInput from "../../component/DateRange";
import moment from "moment";

const initialFilterData = {
  startDate: "",
  endDate: "",
}
function AdminScreen() {
  const [errorList, setErrorList] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = errorList?.slice(indexOfFirstRecord, indexOfLastRecord);
  const [nPages, setNpages] = useState(1);
  const isMobileDevice = window.screen.width <= 900;
  const width = window.screen.width;
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false)
  const [filterData, setFilterData] = useState({ ...initialFilterData })
  const [filterDate, setFilterDate] = useState({ startDate: "", endDate: "" });
  const [datesData, setDateData] = useState([]);
  const [statusComplete, setStatusComplete] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [isWaiting, setIsWaiting] = useState({
    "birth_status": false,
    "bank_status": false,
    "nid_status": false
  });

  const handleDiagnosis = async () => {
    setShowDiagnosisModal(true);
    setStatusComplete(false)
    setIsWaiting({
      "birth_status": false,
      "bank_status": false,
      "nid_status": false
    })
    setStatusData({
      "birth_status": false,
      "bank_status": false,
      "nid_status": false
    })
    try {
      const { data } = await API.apiGet(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/v1/workers/verify-status`);
      setStatusData(data.data);
    } catch (error) {
      toast.error('An error occurred');
    }
  };

  useEffect(() => {
    if (statusData) {
      const birthTimeout = setTimeout(() => {
        setIsWaiting(prevState => ({
          ...prevState,
          "birth_status": true
        }));
      }, 5000);

      const bankTimeout = setTimeout(() => {
        setIsWaiting(prevState => ({
          ...prevState,
          "bank_status": true
        }));
      }, 10000);

      const nidTimeout = setTimeout(() => {
        setIsWaiting(prevState => ({
          ...prevState,
          "nid_status": true
        }));
        setStatusComplete(true)
      }, 15000);

      return () => {
        clearTimeout(birthTimeout);
        clearTimeout(bankTimeout);
        clearTimeout(nidTimeout);
      };
    }
  }, [statusData]);

  const DetailsColumns = [
    {
      title: 'Date',
      key: 'created_at',
      width: '10%',
    },
    {
      title: 'Time',
      key: 'created_at',
    },
    // {
    //   title: 'Processes Names',
    //   key: 'processes',
    // },
    {
      title: 'Source',
      key: 'plateform_and_source',
    },
    {
      title: 'Reason',
      key: 'error_message',
      width: '20%',
    },
    // {
    //   title: 'Error Source',
    //   key: 'error_source',
    // },
    {
      title: 'Error Log',
      key: 'error_log',
      width: '20%',
    },
    // {
    //   title: 'Updated At',
    //   key: 'updated_at',
    //   file: "Error_logs"
    // },
    {
      title: 'Error screenshot',
      key: 'signedUrl',
    },
  ]

  const [openScreenShot, setOpenScreenShot] = useState()

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
  }

  const formatDateData = (data) => {
    data.forEach(entry => {
      entry.created_at = formatDate(entry?.created_at);
      // entry.updated_at = formatDate(entry?.updated_at);
    });
    return data
  }
  const getErrorList = async (currentPage) => {
    try {
      setDataLoading(true);
      const { data } = await API.apiGet(`/admin/error_logs?limit=6&skip=${currentPage}`);
      if (data?.success) {
        // const list = formatDateData(data?.data);
        let errorList = data?.data;
        errorList = errorList?.map(item => {
          item['plateform_and_source'] = `${item?.error_platform}/${item?.error_source}`
          return item;
        })
        setNpages(data?.totalPageCount);
        setErrorList(errorList);
        setDataLoading(false);
      }
      else {
        handleErrorMessage(data?.message);
        toast.error(data?.message)
        setDataLoading(false)
      }
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
      setDataLoading(false)
    }
  }

  function handlePageChange(currentPage) {
    setErrorList([]);
    setCurrentPage(currentPage);
    if (datesData?.length > 0) {
      handleSubmit(datesData, currentPage);
    } else {
      getErrorList(currentPage);
    }
  }

  useEffect(() => {
    setDataLoading(true);
    getErrorList(1);
    setCurrentPage(1);
  }, [])

  const handleErrorData = (item) => {
    if (width <= 300) {
      return truncateText(item, 15);
    } else if (width <= 600) {
      return truncateText(item, 22);
    } else if (width <= 900) {
      return truncateText(item, 40);
    } else {
      return item;
    }
  };

  const truncateText = (text, maxLength) => {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const getScreenShotURL = (item) => {
    setOpenScreenShot(item.signedUrl)
  };

  const closeScreenShot = () => {
    setOpenScreenShot('')
  };

  async function downloadCsvFile(csvData) {
    const blob = new Blob([csvData], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.csv');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
  const handleFilter = async () => {
    try {
      const payload = {
        startDate: filterData.startDate ? moment(filterData.startDate.$d).format('YYYY-MM-DD') : '',
        endDate: filterData.endDate ? moment(filterData.endDate.$d).format('YYYY-MM-DD') : '',
        clientTimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const response = await API.apiGet(`/admin/error_logs/download`, '', payload)
      if (response.status === 200) {
        await downloadCsvFile(response.data)
        toast.success("File downloaded successfully")
        setShowDownloadModal(false)
      }
      else {
        toast.error(response?.data?.error || "Both startDate and endDate are required.")
      }

    }
    catch (error) {
      handleErrorMessage(error);
      toast.error(error?.response?.data?.error)
    }
  }

  const formatToISOStringInTimeZone = (date, timeZoneOffset) => {
    const adjustedDate = moment(date).add(timeZoneOffset, "minutes");
    return adjustedDate.toISOString();
  };

  const handleSubmit = (dates, currentPage) => {
    setDateData(dates);
    if (!dates) {
      handleDateRangeSubmit({
        startDate: "",
        endDate: "",
        currentPage: 1
      });
    }
    if (!dates || dates.length !== 2) return;
    const timeZoneOffset = 1440;
    const [startDate, endDate] = dates;
    const formattedStartDate = formatToISOStringInTimeZone(
      startDate?.$d,
      timeZoneOffset
    );
    const formattedEndDate = formatToISOStringInTimeZone(
      endDate?.$d,
      timeZoneOffset
    );

    handleDateRangeSubmit({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      currentPage
    });
  };

  const handleDateRangeSubmit = (ranges) => {
    setFilterDate((prev) => ({
      ...prev,
      startDate: ranges?.startDate ? ranges?.startDate : '',
      endDate: ranges?.endDate ? ranges?.endDate : '',
    }));
    setErrorList([]);
    getErrorLogsWithRange(ranges);
  };

  const getErrorLogsWithRange = async (ranges) => {
    try {
      setDataLoading(true);
      let params = {  
        startDate: ranges?.startDate,
        endDate: ranges?.endDate,
      };
      const { data } = await API.apiGet(`/admin/error_logs/date/range?limit=6&skip=${ranges?.currentPage}`, '', params);
      if (data?.success) {
        let errorList = data?.data;
        errorList = errorList?.map(item => {
          item['plateform_and_source'] = `${item?.error_platform}/${item?.error_source}`
          return item;
        })
        setNpages(data?.totalPageCount);
        setErrorList(errorList);
        setDataLoading(false);
      }
      else {
        handleErrorMessage(data?.message);
        toast.error(data?.message)
        setDataLoading(false)
      }
    }
    catch (error) {
      handleErrorMessage(error);
      setDataLoading(false)
    }
  }

  return (
    <>
      <div className={`flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part mainBodyContainer`}>
        <SideMenu headerName="Error Logs" />
        <div className={`flex-1 ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
          <Header />
          <div className={`z-0 p-10 relative mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`} >
            {/* {dataLoading && <div className="absolute w-full h-full flex items-center top-0 left-0 justify-center" >
                <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
                />
            </div>} */}
            <div className="flex justify-between">
              {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
                Error Logs
              </h1>}
              <div className="flex justify-between">
                <div className={`me-3`}>
                  <CustomDateRangeInput
                    handleDateSubmit={handleDateRangeSubmit}
                    data={filterDate}
                    handleSubmit={(dates) => handleSubmit(dates, currentPage)}
                    datesData={datesData}
                  />
                </div>
                <button
                  id="diagnose_data"
                  onClick={() => handleDiagnosis()}
                  className="bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn custom_btn_primary h-10 rounded-md flex gap-2 items-center downloadReportResp"
                >
                  Diagnose now
                </button>
                <button
                  id="download_error_log"
                  onClick={() => setShowDownloadModal(true)}
                  className="ms-3 bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn custom_btn_primary h-10 rounded-md flex gap-2 items-center downloadReportResp"
                >
                  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                  Download Report
                </button>
              </div>
            </div>
            {!isMobileDevice ? (<>
              <ErrorLogsTable
                columns={DetailsColumns}
                rowData={errorList}
                tableLoading={dataLoading}
                nPages={nPages}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
              />
            </>

            ) : (
              <div>
                {currentRecords?.map((item, index) => (
                  <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                      <span className="font-bold">Error Message</span>
                      <div className="flex items-center justify-between">
                        <span className="mr-5">{handleErrorData(item?.error_message)}</span>
                        <Popup trigger={
                          <span className="w-3">
                            <img src="/assets/images/tooltip-info.png" alt="info" />
                          </span>
                        }
                          className="textContainer"
                          position="top right">
                          <div>{item?.error_message}</div>
                        </Popup>
                      </div>
                    </div>
                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                      <span className="font-bold">Platform and Source</span>
                      <div className="flex items-center justify-between">
                        <span className="mr-5">{handleErrorData(item?.plateform_and_source)}</span>
                        <Popup trigger={
                          <span className="w-3">
                            <img src="/assets/images/tooltip-info.png" alt="info" />
                          </span>
                        }
                          className="textContainer"
                          position="top right">
                          <div>{item?.plateform_and_source}</div>
                        </Popup>
                      </div>
                    </div>
                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                      <span className="font-bold">Error Log</span>
                      <div className="flex items-center justify-between">
                        <span className="mr-5">{handleErrorData(item?.error_log)}</span>
                        <Popup trigger={
                          <span className="w-3">
                            <img src="/assets/images/tooltip-info.png" alt="info" />
                          </span>
                        }
                          className="textContainer"
                          position="top right">
                          <div className="break-words">{item?.error_log}</div>
                        </Popup>
                      </div>
                    </div>
                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                      <span className="font-bold">Created At</span>
                      <div className="flex items-center justify-between">
                        <span className="mr-5">{handleErrorData(item?.created_at)}</span>
                        <Popup trigger={
                          <span className="w-3">
                            <img src="/assets/images/tooltip-info.png" alt="info" />
                          </span>
                        }
                          className="textContainer"
                          position="top right">
                          <div>{item?.created_at}</div>
                        </Popup>
                      </div>
                    </div>
                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                      <span className="font-bold">Error ScreenShot</span>
                      <div className="flex items-center justify-between">
                        <span className="mr-5">
                          <button type='button' onClick={() => getScreenShotURL(item)}>
                            <div className="errorImageicon" title='Show Error Screenshot'>
                              {<img src="assets/images/infoIcon.svg"></img>}
                            </div>
                          </button>
                        </span>
                        {/* <div className='flex justify-center align-middle mt-3'><button type='button' onClick={()=>getScreenShotURL(item)}><div className="errorImageicon" title='Show Error Screenshot'>{<img src="assets/images/infoIcon.svg"></img>}</div></button></div>  */}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="reponsivePagination">
                  <Pagination nPages={nPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  ></Pagination>
                </div>

                {openScreenShot &&
                  <div className={`modal-content absolute rounded-lg bg-white border modalContainer createLinkErrorShot p-5 ${isMobileDevice && 'errorScreenshotContainer'}`}>
                    <div className='flex flex-row-reverse'>
                      <img src="/assets/images/closeErrorFrame.png" className='w-7' onClick={() => closeScreenShot()}></img>
                    </div>
                    <div className='errorScreenShot'><img src={openScreenShot}></img></div>
                  </div>}
              </div>
            )}
            {showDiagnosisModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full bg-black/50"
              >
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Diagnosis Status</h3>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="static-modal"
                        onClick={() => setShowDiagnosisModal(false)}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="p-5 md:p-5 space-y-4 flex justify-center">
                      <div className="mt-6">
                        <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
                          <li className="mb-10 ms-6">
                            {!isWaiting['birth_status'] ? <span className="absolute flex items-center justify-center w-12 h-12 bg-yellow-200 rounded-full -start-6 ring-4 ring-white dark:ring-gray-900 dark:bg-yellow-900">
                              <svg
                                className="w-5 h-5 text-yellow-500 dark:text-yellow-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" fill="currentColor" />
                              </svg>
                            </span> :
                              <span className={`absolute flex items-center justify-center w-12 h-12 rounded-full -start-6 ring-4 ring-white ${statusData['birth_status'] ? 'bg-green-200 dark:bg-green-900' : 'bg-red-200 dark:bg-red-900'}`}>
                                <svg
                                  className={`w-5 h-5 text-${statusData['birth_status'] ? 'green' : 'red'}-500 dark:text-${statusData['birth_status'] ? 'green' : 'red'}-400`}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  {statusData['birth_status'] ? (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  ) : (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 1l14 14m0-14L1 15"
                                    />
                                  )}
                                </svg>
                              </span>
                            }
                            <div className="ms-3">
                              <h3 className="font-medium leading-tight text-lg">Birth Portal</h3>
                              <p className="text-sm" style={{ color: !isWaiting['birth_status'] ? "" : statusData['birth_status'] ? 'green' : 'red' }}>
                                {!isWaiting['birth_status']  ? `Troubleshooting Birth Portal...` : statusData['birth_status'] ? `Passed` :  `Failed`}
                              </p>

                            </div>
                          </li>
                          <li className="mb-10 ms-6">
                            {!isWaiting['bank_status'] ? <span className="absolute flex items-center justify-center w-12 h-12 bg-yellow-200 rounded-full -start-6 ring-4 ring-white dark:ring-gray-900 dark:bg-yellow-900">
                              <svg
                                className="w-5 h-5 text-yellow-500 dark:text-yellow-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" fill="currentColor" />
                              </svg>
                            </span> :
                              <span className={`absolute flex items-center justify-center w-12 h-12 rounded-full -start-6 ring-4 ring-white ${statusData['bank_status'] ? 'bg-green-200 dark:bg-green-900' : 'bg-red-200 dark:bg-red-900'}`}>
                                <svg
                                  className={`w-5 h-5 text-${statusData['bank_status'] ? 'green' : 'red'}-500 dark:text-${statusData['bank_status'] ? 'green' : 'red'}-400`}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  {statusData['bank_status'] ? (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  ) : (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 1l14 14m0-14L1 15"
                                    />
                                  )}
                                </svg>
                              </span>
                            }
                            <div className="ms-3">
                              <h3 className="font-medium leading-tight text-lg">Bank Asia Portal</h3>
                              <p className="text-sm" style={{ color: !isWaiting['bank_status'] ? "" : statusData['bank_status'] ? 'green' : 'red' }}>{!isWaiting['bank_status']  ? `Troubleshooting Bank Asia Portal...` : statusData['bank_status'] ? `Passed` :  `Failed`}</p>
                            </div>
                          </li>
                          <li className="mb-10 ms-6">
                            {!isWaiting['nid_status'] ? <span className="absolute flex items-center justify-center w-12 h-12 bg-yellow-200 rounded-full -start-6 ring-4 ring-white dark:ring-gray-900 dark:bg-yellow-900">
                              <svg
                                className="w-5 h-5 text-yellow-500 dark:text-yellow-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" fill="currentColor" />
                              </svg>
                            </span> :
                              <span className={`absolute flex items-center justify-center w-12 h-12 rounded-full -start-6 ring-4 ring-white ${statusData['nid_status'] ? 'bg-green-200 dark:bg-green-900' : 'bg-red-200 dark:bg-red-900'}`}>
                                <svg
                                  className={`w-5 h-5 text-${statusData['nid_status'] ? 'green' : 'red'}-500 dark:text-${statusData['nid_status'] ? 'green' : 'red'}-400`}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  {statusData['nid_status'] ? (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  ) : (
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 1l14 14m0-14L1 15"
                                    />
                                  )}
                                </svg>
                              </span>
                            }
                            <div className="ms-3">
                              <h3 className="font-medium leading-tight text-lg">NID Portal</h3>
                              <p className="text-sm" style={{ color: !isWaiting['nid_status'] ? "" : statusData['nid_status'] ? 'green' : 'red' }}>{!isWaiting['nid_status']  ? `Troubleshooting NID Portal...` : statusData['nid_status'] ? `Passed` :  `Failed`}</p>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="flex justify-center p-5">
                      <div>
                        {statusComplete ? (
                          <strong>Diagnosis Completed !</strong>
                        ) : (
                          <strong>Diagnosis Running...</strong>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showDownloadModal && <ErrorLogDownloadModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} handleFilter={handleFilter} setFilterData={setFilterData} filterData={filterData} />}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
}
export default WithAuth(AdminScreen);
