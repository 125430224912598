import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/userManagement.scss";
import "../../styles/reconcilition.scss";
import "../../styles/consumerFinance.scss";
import Breadcrumb from '../../component/Breadcrumb';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import styles from "../../styles/userEdit.module.scss";
import Pagination from "../../component/Pagination";
import API from "../../helpers/api";
import { ColorRing } from "react-loader-spinner";
import EMICalculator from "./EMICalculator";
import AddNewLoanEntryModal from "./AddNewEntryModal";
import moment from "moment";
import { DatePicker } from "antd";
import { encodeData } from "../../common/commonFunction";
let initialDetails = [
    { name: 'Year', value: '', type: 'date', picker: 'year' },
    { name: 'Month', value: '', type: 'date', picker: 'month' },
    { name: 'Team Lead Name', value: '', type: 'text' },
    { name: 'Dealing Officer', value: '', type: 'text' },
    { name: 'Committed Date', value: '', type: 'date' },
    { name: 'Payment Date', value: '', type: 'date' },
    { name: 'Recovery CL Status', value: '', type: 'dropdown' },
    { name: 'Payment amount', value: '', type: 'number' },
    { name: 'Remark', value: '', type: 'textarea' },
]

function ConsumerFinanceManagement() {
    const [dataLoading, setDataLoading] = useState(false);
    const [addEntryLoading, setAddEntryLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loanDetailsData, setLoanDetailsData] = useState();
    const [filterYear, setFilterYear] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const [activeTab, setActiveTab] = useState('details');
    const [steps, setSteps] = useState(["Consumer Finance", "Update Details"]);
    const [errors, setErrors] = useState({});
    const [columns, setColumns] = useState([
        {
            title: 'Month',
            key: 'month',
            style: { width: '10%' },
        },
        {
            title: 'Year',
            key: 'year',
            style: { width: '10%' },
        },
        {
            title: 'Principal Paid',
            key: 'principal_paid',
            style: { width: '12%' },
        },
        {
            title: 'Interest Charged',
            key: 'interest_charged',
            style: { width: '15%' },
        },
        {
            title: 'Minimum Due Amount',
            key: 'total_payment',
            style: { width: '18%' },
        },
        {
            title: 'Paid Amount',
            key: 'payment_amount',
            style: { width: '12%' },
        },
        {
            title: 'Balance',
            key: 'balance',
            style: { width: '12%' },
        },
        {
            title: 'Payment Status',
            key: 'installment_status',
            style: { width: '18%' },
        },
    ])
    const [inputFields, setInputFields] = useState(initialDetails);
    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 900);
    const [breadcrumbSteps, setBreadcrumbSteps] = useState(['Consumer Finance', 'Update Details'])
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = paymentDetails?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(paymentDetails?.length / recordsPerPage);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [actualData, setActualData] = useState()
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    useEffect(() => {
        setCurrentPage(1);
        const handleResize = () => {
          let windowCurrentWidth = window.innerWidth <= 900
          setIsMobileDevice(windowCurrentWidth);
        }
        window.addEventListener('resize', handleResize);
      }, [])

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const getUserDetails = async (loanId) => {
        try {
            setDataLoading(true);
            const response = await API.apiGet(`loan/details?loan_id=${loanId}`);
            if (response?.data?.success) {
                setLoanDetailsData(response?.data?.data);
                setDataLoading(false);
            } else {
                setDataLoading(false);
            }
        } catch (error) {
            console.log("error---->>>>>>>>", error);
            setDataLoading(false);
        }
    }

    const getPaymentDetails = async (loanId) => {
        try {
            setDataLoading(true);
            const response = await API.apiGet(`loan/payment/list?id=${loanId}`);
            if (response?.data?.success) {
                setPaymentDetails(response?.data?.data);
                setActualData(response?.data?.data);
                setDataLoading(false);
            } else {
                setDataLoading(false);
            }
        } catch (error) {
            console.log("error---->>>>>>>>", error);
            setDataLoading(false);
        }
    }

    const getLoanId = () => {
        let params = new URLSearchParams(window.location.search);
        let currentAcId = params.get('id')
        return currentAcId;
    }

    useEffect(() => {
        let loanId = getLoanId();
        getUserDetails(loanId);
        getPaymentDetails(loanId);
    }, [])

    const handleTabClick = (tab) => {
        if (tab === 'details') {
            setSteps(['Consumer Finance', 'Update Details'])
        } else {
            setSteps(['Consumer Finance', 'Update Details', 'EMI Calculator'])
        }
        setActiveTab(tab);
    };

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        const errors = {};
        inputFields.forEach((field) => {
            if (!field.value) {
                if (field?.name != 'Remark') {
                    errors[field.name] = `${field.name} is required`;
                }
            }
        });
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setAddEntryLoading(true);
            let committedDate = inputFields.find((field) => field.name === 'Committed Date').value
            committedDate = new Date(committedDate.$d);

            let paymentDate = inputFields.find((field) => field.name === 'Payment Date').value
            paymentDate = new Date(paymentDate.$d);

            let monthIndx = inputFields.find((field) => field.name === 'Month').value
            monthIndx = monthIndx.month();

            let selectedMonth = moment().month(monthIndx).format('MMM');
            const payload = {
                id: getLoanId(),
                year: inputFields.find((field) => field.name === 'Year').value?.year(),
                month: selectedMonth,
                team_lead: inputFields.find((field) => field.name === 'Team Lead Name').value,
                dealing_officer: inputFields.find((field) => field.name === 'Dealing Officer').value,
                committed_date: committedDate,
                payment_date: paymentDate,
                recovery_cl_status: inputFields.find((field) => field.name === 'Recovery CL Status').value,
                payment_amount: inputFields.find((field) => field.name === 'Payment amount').value,
                remark: inputFields.find((field) => field.name === 'Remark').value,
            };
            try {
                const { data } = await API.apiPost('/loan/update/balance', { payload: encodeData(payload) });
                if (data.success) {
                    toast.success(data.message);
                    setInputFields(initialDetails);
                    setAddEntryLoading(false);
                    getPaymentDetails(getLoanId());
                    setShowModal(false);
                }
            } catch (error) {
                setAddEntryLoading(false);
                if (error.response && error.response.data.message) {
                    toast.error(error.response.data.message)
                } else {
                    console.log('error', error)
                }
            }
        }
    };

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setSelectedMonth(selectedMonth);
    
        let data = actualData;
    
        if (selectedMonth && selectedMonth !== "All") {
            if (selectedYear) {
                const filterData = data?.filter(item => 
                    item?.month?.toLowerCase() === selectedMonth.toLowerCase() &&
                    item?.year === selectedYear
                );
                setPaymentDetails(filterData);
            } else {
                const filterData = data?.filter(item => 
                    item?.month?.toLowerCase() === selectedMonth.toLowerCase()
                );
                setPaymentDetails(filterData);
            }
        } else if (selectedMonth === "All" && selectedYear) {
            const filterData = data?.filter(item => 
                item?.year === selectedYear
            );
            setPaymentDetails(filterData);
        } else {
            setPaymentDetails(actualData);
        }
    };
    
    const handleYearChange = (current) => {
        const currentYear = current?.year();
        setFilterYear(current);
        setSelectedYear(currentYear);
    
        let data = actualData;
    
        if (currentYear) {
            if (selectedMonth && selectedMonth !== "All") {
                const filterData = data?.filter(item => 
                    item?.year === currentYear &&
                    item?.month?.toLowerCase() === selectedMonth.toLowerCase()
                );
                setPaymentDetails(filterData);
            } else {
                const filterData = data?.filter(item => 
                    item?.year === currentYear
                );
                setPaymentDetails(filterData);
            }
        } else {
            if (selectedMonth && selectedMonth !== "All") {
                const filterData = data?.filter(item => 
                    item?.month?.toLowerCase() === selectedMonth.toLowerCase()
                );
                setPaymentDetails(filterData);
            } else {
                setPaymentDetails(actualData);
            }
        }
    };
    

    const getValidYears = (current) => {
        const startDate = moment(loanDetailsData?.start_date);
        const endDate = moment(loanDetailsData?.end_date);
        return startDate.year() > current.year() || endDate.year() < current.year();
    };

    return (
        <>
            <div className={`flex dark:bg-dark-background dashboard-container dashboard-main ${isMobileDevice && 'samdMainCotainer_responsive'}`}>
                <SideMenu headerName="Edit Consumer Finance" />
                <div className={`flex-1 ${!isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'} ${isMobileDevice && 'samdCardContainer_responsive'}`}>
                    <Header />
                    <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-6 responsiveMainContainer'}`}>
                        <Breadcrumb steps={steps} activeStep={steps?.length - 1} showBackButton={true} redirectUrl={'/consumerFinance'} />
                        <div className="flex mt-4 tabContainer">
                            <div className="differentTabs flex">
                                <div
                                    className={`p-2 text-center tabsName ${activeTab === 'details' ? "showActiveColor" : ""}`}
                                >
                                    <p onClick={() => handleTabClick("details")} className="mt-2 cursor-pointer">
                                        Details
                                    </p>
                                </div>
                                <div
                                    className={`p-2 ms-2 text-center tabsName ${activeTab === 'emiCalculator' ? "showActiveColor" : ""}`}
                                >
                                    <p onClick={() => handleTabClick("emiCalculator")} className="mt-2 cursor-pointer">
                                        EMI Calculator
                                    </p>
                                </div>
                            </div>
                        </div>
                        {activeTab == 'details' ? (
                            <>
                                {showModal &&
                                    <AddNewLoanEntryModal
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        isMobileDevice={isMobileDevice}
                                        loanDetailsData={loanDetailsData}
                                        handleSubmitPayment={handleSubmitPayment}
                                        setErrors={setErrors}
                                        errors={errors}
                                        setInputFields={setInputFields}
                                        inputFields={inputFields}
                                        addEntryLoading={addEntryLoading}
                                    />
                                }
                                <div className="flex justify-end mt-5">
                                <select className="me-2 w-28 p-2 rounded border border-gray" id="months" name="months" value={selectedMonth} onChange={handleMonthChange}>
                                    <option value="All">All</option>
                                    {months.map((month, index) => (
                                    <option key={index} value={month.toLowerCase()}>
                                        {month}
                                    </option>
                                    ))}
                                </select>
                                <DatePicker
                                         id="inputC"
                                         name="Year selector"
                                         value={filterYear}
                                         picker="year"
                                         onChange={(e) => handleYearChange(e)}
                                         disabledDate={getValidYears}
                                         placeholder="Select Year"
                                         className={`py-1 rounded outline-none ps-2 me-2`}
                                     />
                                    <button
                                        className={`bg-blue-500 rounded-lg hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded disabled:disabledBtn uploadAndCompareBtn custom_btn_primary commonBtn`}
                                        onClick={() => setShowModal(true)}
                                    >
                                        Add Details
                                    </button>
                                </div>
                                <div className={`${styles.userEdit} mt-4 responsiveConsumerDetailsCard`}>
                                    <div className={`${styles.accountDetails} p-4 flex justify-between items-center`}>
                                        <div className='flex'>
                                            <div className='ps-2'><b>Basic Details</b></div>
                                        </div>
                                    </div>
                                    {!isMobileDevice ?
                                        <div>
                                            <div className={`flex ps-4 ${styles.userDetails} flex-wrap`}>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Borrower Name:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.borrower_name}</div>
                                                </div>
                                                <div className={`m-2 px-3 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Account number:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.account_number}</div>
                                                </div>
                                                <div className={`m-2 pe-3 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 ${styles.labelColor}`}>Product Name:</div>
                                                    <div className={`mt-1 me-3  ${styles?.userName}`}>{loanDetailsData?.product_name}</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Rate of interest:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.interest_rate}%</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Approving Authority:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.approved_by}</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails} ${styles.detailsLastData}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Principal Amount:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.installment_size}</div>
                                                </div>
                                            </div>
                                            <div className={`flex ps-4 ${styles.userDetails} flex-wrap`}>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>customer Code:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.customer_code}</div>
                                                </div>
                                                <div className={`m-2 px-3 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 mr-2 ${styles.labelColor}`}>Borrowers Address:</div>
                                                    <div className={`mt-1  ${styles?.userName}`} title={loanDetailsData?.borrower_address}>{loanDetailsData?.borrower_address?.length > 20 ? loanDetailsData?.borrower_address?.substring(0, 20) + '...' : loanDetailsData?.borrower_address}</div>
                                                </div>
                                                <div className={`m-2 pe-3 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 ${styles.labelColor}`}>Guarantor Name:</div>
                                                    <div className={`mt-1 me-3  ${styles?.userName}`}>{loanDetailsData?.guarantor_name}</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Expiry Date:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.end_date ? moment(loanDetailsData?.end_date)?.format('YYYY-MM-DD') : '-'}</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Total Installment:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.installment_total}</div>
                                                </div>
                                                <div className={`m-2 px-2 ${styles?.sellerDetails} ${styles.detailsLastData}`}>
                                                    <div className={`text-black-50 text-nowrap mr-2 ${styles.labelColor}`}>Branch Name:</div>
                                                    <div className={`mt-1  ${styles?.userName}`}>{loanDetailsData?.branch_name}</div>
                                                </div>
                                            </div>
                                        </div> :
                                    <div>
                                        <div className="mt-3 border-b bg-white mb-4 rounded-lg">
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 ">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Borrower Name:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.borrower_name}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Account Number:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.account_number}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Product Name:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.product_name}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Rate of Interest:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.interest_rate}%</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Approving Authority:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.approved_by}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Principal Amount:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.installment_size}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Customer Code:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.customer_code}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Borrowers Address:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.borrower_address}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Guarantor Name:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.guarantor_name}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Expiry Date:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.end_date ? moment(loanDetailsData?.end_date)?.format('YYYY-MM-DD') : '-'}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Total Installment:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.installment_total}</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Branch Name:</span>
                                                <div className="flex items-center justify-between">
                                                    <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{loanDetailsData?.branch_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                </div>}
                                </div>
                                <div className={`reconcilitionTable-container mt-3 ${isMobileDevice ? "":"paymentTable"} center-block fix-width scroll-inner`}>
                                    {!isMobileDevice ? currentRecords?.length > 0 ?
                                        <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                            <thead>
                                                <tr className="ps-2 pe-2">
                                                    {columns.map((item, indx) =>
                                                        <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                            {item?.title.toLowerCase()}
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentRecords?.map((item, index) =>
                                                    <tr className='ps-2 pe-2' key={`${item}_${index}`}>
                                                        {columns.map((column, colIdx) =>
                                                            column?.key === 'loan_paid_to_date' ?
                                                                <td className="py-2 ps-4" title={item?.[column?.key]} style={column?.style} >
                                                                    {item?.[column?.key] ? `${item?.[column?.key]} %` : <span> -</span>}
                                                                </td>
                                                                :
                                                                <td className="py-2 ps-4" title={item?.[column?.key]} style={column?.style} >
                                                                    {item?.[column?.key] ? item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key] : <span> -</span>}
                                                                </td>
                                                        )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                           <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                                                <thead>
                                                    <tr className="ps-2 pe-2">
                                                        {columns.map((item, indx) =>
                                                            <th key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                                                                {item?.title.toLowerCase()}
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                            </table>
                                            {dataLoading ?
                                                <div className="flex items-center justify-center" >
                                                    <ColorRing
                                                        visible={true}
                                                        height="80"
                                                        width="60"
                                                        ariaLabel="color-ring-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="color-ring-wrapper"
                                                        colors={[]}
                                                    />
                                                </div>
                                                :
                                                <div className='flex justify-center py-5 font-semibold'>
                                                    No data found
                                                </div>
                                            }
                                        </>
                                    :<div>
                                    {currentRecords?.length > 0 ? (
                                        <>
                                            <div>
                                                {currentRecords?.map((item, index) => (
                                                    <div key={`block_${index}`} className="mt-3 border bg-white mb-4 rounded-lg">
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Month</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.month}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Year</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.year}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Principal Paid</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.principal_paid}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Interest Charged</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.interest_charged}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Minimum Due Amount</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.total_payment}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Paid Amount</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.payment_amount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Balance</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.balance}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                            <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Payment Status</span>
                                                            <div className="flex items-center justify-between">
                                                                <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.installment_status}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="reponsivePagination">
                                                {isMobileDevice && <Pagination 
                                                    nPages={nPages}
                                                    currentPage={currentPage}
                                                    handlePageChange={handlePageChange}
                                                ></Pagination>}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="noDataFound">
                                            <img
                                                src='/assets/noData.svg'
                                                alt="no-data"
                                                className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                            />
                                            <div className="text-xl font-semibold">
                                                No data found
                                            </div>
                                        </div>
                                    )}
                                    </div>}
                                </div>
                                {currentRecords?.length > 0 && !isMobileDevice ?
                                    <div className="reponsivePagination mt-4">
                                        <Pagination nPages={nPages}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                        ></Pagination>
                                    </div>
                                :""}
                            </>
                        ) : (
                            <EMICalculator isMobileDevice={isMobileDevice} />
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(ConsumerFinanceManagement);
