import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import styles from "../../styles/informationUpdate.module.scss";
import printImg from "../../assets/images/print.svg";
import editImg from "../../assets/images/edit.svg";
import accountImg from "../../assets/images/accountImg.svg";
import signatureCard from "../../assets/images/signaturecard.svg";
import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import API from "../../helpers/api/index";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumb from "../../component/Breadcrumb";
import moment from "moment";

const InformationUpdate = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openSignatureModal, setSignatureModal] = useState(false);
  const [editPersonalModal, setEditPersonalModal] = useState(false);
  const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
  const [AccountDetails, setAccountDetails] = useState();
  const [AccountTable, setAccountTable] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(6);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // let currentRecords = AccountDetails?.slice(indexOfFirstRecord, indexOfLastRecord);
  // let nPages = AccountDetails?.length ? Math.ceil(AccountDetails?.length / recordsPerPage) : 0;

  function getAccountNumber() {
    const params = new URLSearchParams(window.location.search);
    return params.get("account_no") || "";
  }

  const editAccountInfo = () => {
    setOpenEditModal(true);
  };

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const closePersonalModal = () => {
    setEditPersonalModal(false);
  };

  const openImageModal = () => {
    setOpenImgModal(true);
  };

  const closeImgModal = () => {
    setOpenImgModal(false);
  };

  const openSignModal = () => {
    setSignatureModal(true);
  };

  const closeSignModal = () => {
    setSignatureModal(false);
  };

  const editPersonalInfo = () => {
    setEditPersonalModal(true);
  };

  useEffect(() => {
    getAccountDetails();
    setDataLoading(true);
  }, []);

  const getAccountDetails = async () => {
    const account_no = getAccountNumber();
    try {
      const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
      if (data?.success) {
        setAccountDetails(data?.data);
        setAccountTable([data?.data]);
        toast.success(data?.message);
        setDataLoading(false);
      } else {
        toast.error(data?.message);
        setDataLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDataLoading(false);
    }
  };

  return (
    <>
      <div className={`flex dark:bg-dark-background updatePhone`}>
        <SideMenu />
        <div
          className={`flex-1 overflow-y-scroll ${styles.raiseRequestContainer}`}
        >
          <Header />
          {dataLoading && (
            <div className="absolute w-full h-full flex items-center top-25 left-12 justify-center">
              <ColorRing
                visible={true}
                height="80"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
              />
            </div>
          )}
          <div
            className="z-0 container p-10 mainContainer fixedHeightContainer"
            style={{ opacity: dataLoading ? 0.5 : 1 }}
          >
            <div className="z-0 container">
              <div className="flex justify-between">
                <div className="flex gap-3">
                  <Breadcrumb
                    showBackButton={true}
                    redirectUrl={"/SearchAccount"}
                  />
                  <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                    Account Information
                  </h1>
                </div>
                <div className="flex">
                  <div>
                    <button
                      className={`text-sm flex justify-center align-middle me-4 ${styles.printBtn}`}
                    >
                      <img className="me-2" src={printImg}></img>Print
                    </button>
                  </div>
                  {/* <div>
                                        <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Account Information</button>
                                    </div> */}
                </div>
              </div>
            </div>
            <div
              className={`flex flex-wrap justify-evenly ${styles.accountInfocard}`}
            >
              <div className={`flex justify-center ${styles.accountInfoCol}`}>
                {/* {Object?.keys(AccountDetails?.account_info)?.map((item,index)=>{ <table> */}
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">Account No:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_no}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Open Date:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.open_date
                          ? moment(
                              AccountDetails?.account_info?.open_date
                            ).format("DD-MM-YYYY")
                          : "--"}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">
                        Customer ID Open Agent Name:
                      </td>
                      <th className="text-left ps-3">
                        {
                          AccountDetails?.account_info
                            ?.customer_id_open_agent_name
                        }
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Sector Code:</td>
                      <th
                        title={AccountDetails?.account_info?.sector_code}
                        className="text-left ps-3"
                      >
                        {AccountDetails?.account_info?.sector_code.length > 20
                          ? AccountDetails?.account_info?.sector_code.slice(
                              0,
                              20
                            ) + "..."
                          : AccountDetails?.account_info?.sector_code}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">NFC Print Status:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.nfc_print_status}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Account Open Agent Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_open_agent_name}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Referral Employee ID:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.referral_employee_id}
                      </th>
                    </tr>
                  </tbody>
                </table>
                {/* })} */}
              </div>
              <div className={`flex justify-center ${styles.accountInfoCol}`}>
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">Account Type:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_type}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Currency:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.currency}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">
                        Customer ID Open Agent Name:
                      </td>
                      <th className="text-left ps-3">
                        {
                          AccountDetails?.account_info
                            ?.customer_id_open_agent_name
                        }
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">AML Status:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.aml_status}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Operating Instructions:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.operating_instructions}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Account Open Booth Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_open_booth_name}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={`flex justify-center ${styles.accountImgCol}`}>
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">Account Title:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_title}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Account Nature:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_nature}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Customer ID Create By:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.customer_id_create_by}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">AMl Token No:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.aml_token_no}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Company Type:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.company_type}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Account Open By:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_open_by}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={`flex justify-center ${styles.accountImgCol}`}>
                <img
                  width="250"
                  height="224"
                  className="cursor-pointer"
                  onClick={openImageModal}
                  src={AccountDetails?.customer_photo_path}
                ></img>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Personal Information
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editPersonalInfo}><img className='me-2' src={editImg}></img>Edit Personal Information</button>
                                </div>
                            </div> */}
            </div>
            <div
              className={`flex flex-wrap justify-evenly ${styles.accountInfocard}`}
            >
              <div className={`flex justify-center ${styles.peronalInfoCol}`}>
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">First Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.account_info?.account_title}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Father Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.father_name}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Date of Birth:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.DOB
                          ? moment(AccountDetails?.personal_info?.DOB).format(
                              "DD-MM-YYYY"
                            )
                          : "--"}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Nationality:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.nationality}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Occupation:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.occupation}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Marital Status:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.marital_status}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={`flex justify-evenly ${styles.signatureInfoCol}`}>
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">Last Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.last_name}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Mother Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.mother_name}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Gender:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.gender}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Religion:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.religion}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">No of Children:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.no_of_children}
                      </th>
                    </tr>
                    <tr>
                      <td className="text-right">Spouse Name:</td>
                      <th className="text-left ps-3">
                        {AccountDetails?.personal_info?.spouse_name}
                      </th>
                    </tr>

                    {/* <tr>
                                            <td className='text-right'>
                                                Address:
                                            </td>
                                            <th className='text-left ps-3'>
                                                Dhaka ,Bangladesh
                                            </th>
                                        </tr> */}
                  </tbody>
                </table>
                <img
                  width="244"
                  height="224"
                  onClick={openSignModal}
                  src={AccountDetails?.signature_photo_path}
                ></img>
              </div>
              {/* <div className={`flex justify-center ${styles.accountInfoCol}`}>
                                
                            </div> */}
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Contact Information
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Contact Information</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Mobile Number
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Alternate Mobile Number
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Mail ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.contact_info?.mobile_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.contact_info?.alternative_mobile_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.contact_info?.email}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Account Operator
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Account Operator</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Customer ID
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Customer Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Customer DOB
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.account_operator?.customer_id}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.account_operator?.customer_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.account_operator?.customer_dob
                            ? moment(
                                AccountDetails?.account_operator?.customer_dob
                              ).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Related Documents
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Related Documents</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Type
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document No
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Issue Date
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Issue Place
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Expiry Date
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Front Image
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Back Image
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        NID Verify
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.related_documents?.document_type}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.related_documents?.document_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.related_documents?.issue_date
                            ? moment(
                                AccountDetails?.related_documents?.issue_date
                              ).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.related_documents?.issue_place}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.related_documents?.expire_date
                            ? moment(
                                AccountDetails?.related_documents?.expire_date
                              ).format("DD-MM-YYYY")
                            : "--"}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img
                            src={item?.related_documents?.doc_front_img}
                          ></img>
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img
                            src={item?.related_documents?.doc_back_img}
                          ></img>
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img src={item?.related_documents?.doc_nid_img}></img>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Address Details
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Address Details</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Address Description
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        District Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Upazilla Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Union Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Post Code
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Village Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Word No
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Road No
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Holding No
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.address_details?.address_description}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.district_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.upazilla_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.union_name?.length === 0
                            ? "-"
                            : item?.address_details?.union_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.post_code?.length === 0
                            ? "-"
                            : item?.address_details?.post_code}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.village_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.word_no?.length === 0
                            ? "-"
                            : item?.address_details?.word_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.road_no?.length === 0
                            ? "-"
                            : item?.address_details?.road_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.address_details?.holding_no?.length === 0
                            ? "-"
                            : item?.address_details?.holding_no}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Nominee Information
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Nominee Information</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Nominee Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Father Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Mother Name
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        DOB
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Relation
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Nominee Percent(%)
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Type
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document No
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Nominee Image
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Front Image
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Document Back Image
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.nominee_info?.nominee_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.father_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.mother_name}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.dob
                            ? moment(AccountDetails?.nominee_info?.dob).format(
                                "DD-MM-YYYY"
                              )
                            : "--"}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.relation}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.nominee_percent}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.document_typy}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.nominee_info?.document_no}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img src={item?.nominee_info?.nominee_img}></img>
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img
                            src={item?.nominee_info?.doc_front_img_path}
                          ></img>
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          <img
                            src={item?.nominee_info?.doc_back_img_path}
                          ></img>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <h1 className={`font-semibold ${styles.accountInfoHeading}`}>
                Transaction Limit
              </h1>
              {/* <div className='flex'>
                                <div>
                                    <button className={`${styles.editBtn} text-white text-sm flex justify-center align-middle`} onClick={editAccountInfo}><img className='me-2' src={editImg}></img>Edit Transaction Limit</button>
                                </div>
                            </div> */}
            </div>
            <div className={`${styles.accountInfoTable}`}>
              <div className=" mt-4">
                <table
                  className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.contactInfo}`}
                >
                  <thead>
                    <tr className="ps-2 pe-2">
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Transaction Limit
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Debit/Credit
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Transaction/Day
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Amount/Day
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Transactions/Month
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Transaction Amount/Month
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Maximum Transaction
                      </th>
                      <th
                        className=" capitalize py-3 px-4 "
                        style={{ fontSize: "16px" }}
                      >
                        Amount
                      </th>
                      {/* <th
                                        className=" capitalize py-3 px-4 "
                                        style={{ fontSize: "16px" }}
                                    >
                                        Last Update By
                                    </th> */}
                      {/* <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Last Update Date
                                            </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {AccountTable?.map((item, index) => (
                      <tr
                        style={{ backgroundColor: "white" }}
                        // key={index}
                        className="border-b"
                      >
                        <td className={`p-4 ${styles.listItem}`}>
                          {item?.transaction_limit?.transaction_type}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.debit_or_credit}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.no_of_transaction_day}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.transaction_amount_day}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.no_of_transaction_month}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.transaction_amount_month}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.max_trans_amount}
                        </td>
                        <td className={`p-4 capitalize ${styles.workflowText}`}>
                          {item?.transaction_limit?.amount}
                        </td>
                        {/* <td className={`p-4 capitalize ${styles.workflowText}`}>12-04-12</td> */}
                        {/* <td className={`p-4 capitalize ${styles.workflowText}`}>12-04-13</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {openImgModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
              >
                {requestSubmitLoader && (
                  <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[]}
                    />
                  </div>
                )}
                <div
                  className="modal absolute"
                  style={
                    requestSubmitLoader
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div className="modal-content rounded-lg bg-white border modalContainer createModal p-1 pb-5">
                    <div class="flex items-center justify-between rounded-t dark:border-gray-600">
                      <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="static-modal"
                        onClick={closeImgModal}
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="modalForm">
                      <div className=" flex flex-col ps-3 pe-3 mt-4">
                        <img src={AccountDetails?.customer_photo_path}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {openSignatureModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
              >
                {requestSubmitLoader && (
                  <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[]}
                    />
                  </div>
                )}
                <div
                  className="modal absolute"
                  style={
                    requestSubmitLoader
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div className="modal-content rounded-lg bg-white border modalContainer createModal p-1 pb-5">
                    <div class="flex items-center justify-between rounded-t dark:border-gray-600">
                      <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="static-modal"
                        onClick={closeSignModal}
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="modalForm">
                      <div className=" flex flex-col ps-3 pe-3 mt-4">
                        <img src={AccountDetails?.signature_photo_path}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {editPersonalModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
              >
                {requestSubmitLoader && (
                  <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[]}
                    />
                  </div>
                )}
                <div
                  className="modal absolute"
                  style={
                    requestSubmitLoader
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div className="modal-content rounded-lg bg-white border modalContainer createModal p-5">
                    <div className="ps-3 pe-3 pt-1">
                      <p className="font-small border-b-2 pb-5 font-semibold text-lg ">
                        Update Personal Details
                      </p>
                    </div>
                    <div className="modalForm">
                      <div className=" flex flex-col ps-3 pe-3 mt-4">
                        <label
                          htmlFor="account_title"
                          className="numberRequestModal"
                        >
                          Account Title*
                        </label>
                        <input
                          type="text"
                          id="account_title"
                          name="account_title"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Account Title"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="dob"
                          className="numberRequestModal mt-3"
                        >
                          DOB*
                        </label>
                        <input
                          type="text"
                          id="dob"
                          name="dob"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Customer Address"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="email"
                          className="numberRequestModal mt-3"
                        >
                          Enter Email*
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Email"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="marital_status"
                          className="numberRequestModal mt-3"
                        >
                          Enter Marital Status*
                        </label>
                        <input
                          type="text"
                          id="marital_status"
                          name="marital_status"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Marital Status"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="religion"
                          className="numberRequestModal mt-3"
                        >
                          Enter Religion*
                        </label>
                        <input
                          type="text"
                          id="religion"
                          name="religion"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Religion"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="gender"
                          className="numberRequestModal mt-3"
                        >
                          Enter Gender*
                        </label>
                        <input
                          type="text"
                          id="gender"
                          name="gender"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Gender"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="spouse_name"
                          className="numberRequestModal mt-3"
                        >
                          Spouse Name*
                        </label>
                        <input
                          type="text"
                          id="spouse_name"
                          name="spouse_name"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Spouse Name"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="occupation"
                          className="numberRequestModal mt-3"
                        >
                          Occupation*
                        </label>
                        <input
                          type="text"
                          id="occupation"
                          name="occupation"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Gender"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                      </div>

                      <div className="flex justify-end mt-5 mb-3">
                        <div className="flex justify-center align-middle me-2">
                          <button
                            type="button"
                            className="custom_btn_secondary border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2"
                            onClick={closePersonalModal}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex justify-center align-middle me-3">
                          <button
                            type="submit"
                            className="relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {openEditModal && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
              >
                {requestSubmitLoader && (
                  <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[]}
                    />
                  </div>
                )}
                <div
                  className="modal absolute"
                  style={
                    requestSubmitLoader
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div className="modal-content rounded-lg bg-white border modalContainer createModal p-5">
                    <div className="ps-3 pe-3 pt-1">
                      <p className="font-small border-b-2 pb-5 font-semibold text-lg ">
                        Update Account Details
                      </p>
                    </div>
                    <div className="modalForm">
                      <div className=" flex flex-col ps-3 pe-3 mt-4">
                        <label
                          htmlFor="account_title"
                          className="numberRequestModal"
                        >
                          Enter Account Title*
                        </label>
                        <input
                          type="text"
                          id="account_title"
                          name="account_number"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Account Title"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="account_number"
                          className="numberRequestModal mt-3"
                        >
                          Enter Customer Address*
                        </label>
                        <input
                          type="text"
                          id="customer_address"
                          name="customer_address"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Customer Address"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="email"
                          className="numberRequestModal mt-3"
                        >
                          Enter Email*
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Email"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="marital_status"
                          className="numberRequestModal mt-3"
                        >
                          Enter Marital Status*
                        </label>
                        <input
                          type="text"
                          id="marital_status"
                          name="marital_status"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Marital Status"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="religion"
                          className="numberRequestModal mt-3"
                        >
                          Enter Religion*
                        </label>
                        <input
                          type="text"
                          id="religion"
                          name="religion"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Religion"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                        <label
                          htmlFor="gender"
                          className="numberRequestModal mt-3"
                        >
                          Enter Gender*
                        </label>
                        <input
                          type="text"
                          id="gender"
                          name="gender"
                          // value={accountNumber}
                          // onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Gender"
                          className={`py-2 rounded outline-none ps-2 border`}
                        />
                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                      </div>

                      <div className="flex justify-end mt-5 mb-3">
                        <div className="flex justify-center align-middle me-2">
                          <button
                            type="button"
                            className="custom_btn_secondary border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex justify-center align-middle me-3">
                          <button
                            type="submit"
                            className="custom_btn_primary relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium commonBtn py-2"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default InformationUpdate;
