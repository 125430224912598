import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/transactionControl.scss";
import Navbar from "../../common/Navbar";
import { useState } from "react";
import API from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import styles from "../../styles/informationUpdate.module.scss";
const TransactionControl = () => {
  const transactionControlHeader = [
    {
      title: "Transaction Type",
      label: "Tran. Type"
    },
    {
      title: "Code",
      label: "Code"
    },
    {
      title: "Number of daily transaction",
      label: "No. of Daily Tran."
    },
    {
      title: "Daily transaction amount",
      label: "Daily Tran. Amo..."
    },
    {
      title: "Number of monthly transaction",
      label: "No. of monthly Tran."
    },
    {
      title: "Monthly transaction amount",
      label: "Monthly Tran. Amo..."
    },
    {
      title: "Max transaction amount",
      label: "Max Tran. Amo..."
    },
    {
      title: "Request form",
      label: "Request form"
    },
    {
      title: "Branch/Point Name",
      label: "Branch/Point Na..."
    },
    {
      title: "Remarks",
      label: "Remarks"
    },
  ];
  const transactionControlData = [
    {
      type: "Cash Transaction",
      code: "Credit",
      dailyTran: "3",
      dailyTranAmt: "500000",
      monthlyTran: "20",
      monthlyTranAmt: "2000000",
      maxTranAmt: "5000000",
    },
    {
      type: "Cash Transaction",
      code: "Debit",
      dailyTran: "2",
      dailyTranAmt: "500000",
      monthlyTran: "20",
      monthlyTranAmt: "1000000",
      maxTranAmt: "5000000",
    },
    {
      type: "Transfer Transaction",
      code: "Debit",
      dailyTran: "2",
      dailyTranAmt: "250000",
      monthlyTran: "20",
      monthlyTranAmt: "1000000",
      maxTranAmt: "1500000"
    },
  ]
  const steps = ["Acount Details", "Transaction Control"];
  const [accountDetails, setAccountDetails] = useState();
  const [accountTable,setAccountTable] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const isMobileDevice = window.screen.width <= 900;

  const getAccountDetails = async () => {
    const account_no = accountNumber;
    try {
        const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
        if (data?.success) {
            setAccountDetails(data?.data);
            setAccountTable([data?.data]);
            toast.success(data?.message);
            setDataLoading(false)
        } else {
            
            toast.error(data?.message);
            setDataLoading(false)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message);
        setDataLoading(false)
    }
};
  return (
    <div className={`flex dark:bg-dark-background updatePhone`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <Navbar />
        <div className={`z-0 transactionControl transactionFixedHeightContainer ${isMobileDevice ? 'p-4' : 'p-10'}`}>
          <Breadcrumb
            steps={steps}
            activeStep={1}
            showBackButton={true}
            redirectUrl={"/ConventionalAgentBankingCard"}
          />
          <div class={`flex gap-4 mt-2 ${isMobileDevice && 'flex-wrap'}`}>
            <div className={`transactionDetailContainer rounded p-4 cardBackground ${isMobileDevice ? 'w-full' : 'w-3/5'}`}>
              <div className="flex justify-between">
                <p className="transactionProfileText">Transaction Profile</p>
                {/* <button type="button" className="transactionClearButton rounded">Clear</button> */}
              </div>
              <div className={`p-2  accoutHolderDetails mt-4 ${isMobileDevice ? 'ps-0' :'ps-10'}`}>
                <div className={`flex ${isMobileDevice && 'flex-wrap'}`}>
                  <div className={`${isMobileDevice ? 'ps-2 w-full' : 'title w-1/5'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Account Number :</p></div>
                  <div className={`${isMobileDevice ? 'w-full mt-2' : 'w-2/4'}`}><input type="text" id="account_number"
                    name="account_number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e?.target?.value)}
                    placeholder="Enter Account Number"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 ms-2 outline-none ps-3"></input>
                  </div>
                  <button type="submit" className={`relative border rounded-md bg-blue-600 w-32 p-1 text-white font-medium custom_btn_primary commonBtn py-1 h-9 ${isMobileDevice ? 'ms-2 mt-2' : 'ms-6'}`} onClick={getAccountDetails}>
                    Show Details</button>
                </div>
                <div className="flex mt-5">
                  <div className={`${isMobileDevice ? 'ps-2 w-1/2' : 'title w-1/5'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Account Title :</p></div>
                  <div className={`${isMobileDevice ? 'w-1/2' : 'w-2/4 '}`}><p className="ms-2 text-blue-500">{accountDetails?.account_info?.account_title}</p></div>
                </div>
                <div className="flex mt-5">
                  <div className={`${isMobileDevice ? 'ps-2 w-1/2' : 'title w-1/5'}`}><p className="block text-sm font-medium leading-6 text-gray-900">Mobile No. :</p></div>
                  <div className={`${isMobileDevice ? 'w-1/2' : 'w-2/4 '}`}><p className="ms-2 text-blue-500">{accountDetails?.contact_info?.mobile_no}</p></div>
                </div>
              </div>
            </div>
            <div className={`transactionDetailContainer w-2/5 rounded p-4 cardBackground ${isMobileDevice && 'w-full'}`}>
              <div className="flex justify-between">
                <p className="transactionProfileText">Request Photo</p>
                <button type="button" className="transactionClearButton rounded">Capture</button>
              </div>
            </div>
          </div>

          <div className="mt-3 transactionDetailContainer rounded p-4 cardBackground">
            <div>
              <p className="transactionProfileText">Customer Limit Update</p>
            </div>
            <div className="relative mt-3 flex">
              <div>
                <img
                  src="assets/images/Vector.svg"
                  className="imgSearch absolute"
                />
                <input
                  type="text"
                  placeholder="Search"
                  //   onChange={(e) => handleSearch(e)}
                  className="searchAnyColumn border"
                ></input>
              </div>
              <div>
                <button className="transactionClearButton rounded">Submit</button>
              </div>
            </div>
            
            <div className="mt-4 transactionTable">
            {!isMobileDevice ? (
                                <table
                                    className={`min-w-full transactionDetailContainer text-left reconcilitionTable`}
                                >
                                    <thead>
                                        <tr className="ps-2 pe-2 tableHeadColor">
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Transaction Limit
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Debit/Credit
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Transaction/Day
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Amount/Day
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Transactions/Month
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Transaction Amount/Month
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Maximum Transaction
                                            </th>
                                            <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Amount
                                            </th>
                                            {/* <th
                                        className=" capitalize py-3 px-4 "
                                        style={{ fontSize: "16px" }}
                                    >
                                        Last Update By
                                    </th> */}
                                            {/* <th
                                                className=" capitalize py-3 px-4 "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Last Update Date
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {accountTable?.map((item, index) => (
                                        <tr
                                            style={{ backgroundColor: "#ffffff" }}
                                            // key={index}
                                            className="border-b"
                                        >
                                            <td className={`p-4`}>{item?.transaction_limit?.transaction_type}</td>
                                            <td className={`p-4 capitalize`}>
                                                {item?.transaction_limit?.debit_or_credit}
                                            </td>
                                            <td className={`p-4 capitalize `}>{item?.transaction_limit?.no_of_transaction_day}</td>
                                            <td className={`p-4 capitalize`}>{item?.transaction_limit?.transaction_amount_day}</td>
                                            <td className={`p-4 capitalize`}>{item?.transaction_limit?.no_of_transaction_month}</td>
                                            <td className={`p-4 capitalize `}>{item?.transaction_limit?.transaction_amount_month}</td>
                                            <td className={`p-4 capitalize `}>{item?.transaction_limit?.max_trans_amount}</td>
                                            <td className={`p-4 capitalize `}>{item?.transaction_limit?.amount}</td>
                                            {/* <td className={`p-4 capitalize ${styles.workflowText}`}>12-04-12</td> */}
                                            {/* <td className={`p-4 capitalize ${styles.workflowText}`}>12-04-13</td> */}
                                        </tr>
                                         ))}
                                    </tbody>
                                </table>):(
                                    <div>
                                        {accountTable?.map((item, index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Transaction/Day</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.no_of_transaction_day} className="mr-5">{item?.transaction_limit?.no_of_transaction_day}</span>
                                                    </div>
                                                </div>
                                                <div className="flex hidden justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Transaction Amount/Day</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.transaction_amount_day} className="mr-5">{item?.transaction_limit?.transaction_amount_day}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Transaction/Month</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.no_of_transaction_month} className={`mr-5 ${styles.wrapWords}`}>{item?.transaction_limit?.no_of_transaction_month}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Transaction Amount/Month</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.transaction_amount_month} className={`mr-5 ${styles.wrapWords}`}>{item?.transaction_limit?.transaction_amount_month}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Maximun Transaction Amount</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.max_trans_amount} className={`mr-5 ${styles.wrapWords}`}>{item?.transaction_limit?.max_trans_amount}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className={`font-bold ${styles.wrapWords}`}>Amount</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.transaction_limit?.amount} className={`mr-5 ${styles.wrapWords}`}>{item?.transaction_limit?.amount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {accountTable ? "" : <div className='flex justify-center py-5 font-semibold'>No data found</div>}
                            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TransactionControl;
