import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Utils from "../common/utils";
// import ReportScreen from "../screen/dashboard/ReportScreen";
import WebStorage from "../common/webStorage";
import { USER_DATA_KEY } from "../common/webStorageKeys";

// screen added
import LoginScreen from "../screen/authentication/LoginScreen";
import VerifyEmail from "../screen/authentication/verifyEmail";
import Dashboard from "../screen/dashboard/Dashboard";
import AccountVerification from "../screen/dashboard/AccountVerification";
import PhoneNumberUpdate from "../screen/dashboard/updatePhoneNumber";
import WrittenOfPortfolio from "../screen/samdDepartment/writtenOfPortfolio/writtenOfPortfolio";
import NewPortfolio from "../screen/samdDepartment/writtenOfPortfolio/newPortfolio"
import ViewPortfolio from "../screen/samdDepartment/writtenOfPortfolio/viewPortfolio";
import WorkerQueBoard from "../screen/dashboard/WorkerQueBoard";
import Reconciliation from "../screen/reconciliation/Reconciliation";
import AccountsDepartment from "../screen/reconciliation/ReconciliationCard";
import VideoRecognition from "../screen/videoRecognition/videoRecognition";
import UserConfig from "../screen/userConfig/UserConfig";
import Contact from "../screen/contact/Contact";
import AmlScreen from "../screen/dashboard/AmlScreen";
import UserManagement from "../screen/userManagement/UserManagement";
import Audit from "../screen/auditTrail/Audit";
import UserEdit from "../screen/userManagement/UserEdit";
import TokenExpiredPage from "../screen/linkExpire/LinkExpire";
import ViewRequest from "../screen/userManagement/ViewRequest";
import VideoKyc from "../screen/videoRecognition/VideoKyc";
import PermissionModal from "../component/AllowPermissionModal";
import OtpScreen from "../screen/videoRecognition/OtpScreen"
// import AccountDetail from "../screen/dashboard/AccountDetail";
import AccountDetail from "../screen/dashboard/AccountDetail";
import IvrConfiguration from "../screen/services/ivrConfiguration/IVRConfuguration";
import IvrTableUpdate from "../screen/services/UpdateIVRTable";
import IvrView from "../screen/services/IVRViewComponent";
import IvrCallingList from "../screen/services/ivrCallingList/IVRCallingList";
import IvrCallingDetails from "../screen/services/ivrCallingList/IVRCallingDetails";
import IvrPhoneCallDetails from "../screen/services/ivrCallingList/IVRPhoneCallDetails";
import IVRWorkflow from "../screen/services/IVRWorkflow";
import IVRMain from "../component/IVRMain";
import ServiceCompoent from "../screen/services/index";
import MyTaskCompoent from "../screen/myTask/index";
import InitiateIVRCoponent from "../screen/services/InitiateIVR";
import Admin from "../screen/admin/admin";
import SAMDDepartment from "../screen/samdDepartment/index";
import LoanStorage from "../screen/samdDepartment/loneDocumentStorage/LoanStorage";
import ViewFolderDetails from "../screen/samdDepartment/loneDocumentStorage/ViewFolderDetails"
import RegularSavingAccount from "../screen/dashboard/SavingAccount";
import MinorsAccount from "../screen/dashboard/MinorsAccount";
import QueueConfiguration from "../screen/admin/QueueConfiguration";
import AccountUpdateDetails from "../component/AccountUpdateDetails";
import InformationUpdate from "../screen/dashboard/InformationUpdate";
import TransactionControl from "../screen/transactionControl/TransactionControl";
import NomineeDetails from "../screen/nomineeDetails/NomineeDetails";
import NomineeQuickLinks from "../screen/nomineeDetails/CustomerAquisition";
import ConventionalAgentBanking from "../screen/nomineeDetails/ConventionalAgentBanking";
import DetailsPagee from "../screen/nomineeDetails/DetailsPagee";
import NomineeDetailsPage from "../screen/nomineeDetails/NomineeDetailsPage";
import InformationUpdateDashboard from "../component/InformationUpdateDashboard";
import SearchAccount from "../screen/accountDetails/SearchAccount";
import RaiseAccountRequest from "../screen/accountDetails/RaiseAccountRequest";
import RaiseRequestApproval from "../screen/accountDetails/RaiseRequestApproval";
import CheckEmail from "../screen/authentication/checkEmail";
import ResetPassword from "../screen/authentication/ResetPassword";
import TransactionType from "../screen/transactionControl/TransactionType";
import AccountTitleChange from "../screen/accountDetails/AccountTitleChange";
import ConventionalAgentBankingCard from "../screen/accountDetails/ConventionalAgentBankingCard";
import QueueConfigurationS02 from "../screen/admin/QueueConfigurationS02";
import QueueConfigurationS07 from "../screen/admin/QueueConfigurationS07";
import ConsumerFinance from "../screen/consumerFinance/index";
import QueueConfigurationCommon from "../screen/admin/QueueConfigurationCommon";
import EditViewConsumerFinance from "../screen/consumerFinance/EditViewConsumerFinance";
import LogoAndThemeComponent from "../screen/LogoAndTheme";
import Logs from "../screen/serverLiveLogs/ServerLiveLogs";
import VideoKYCListComponent from "../screen/services/videoKYCComponent/VideoKYCList";
import ViewKYCRequest from "../screen/services/videoKYCComponent/ViewKYCRequest";
import { CRMDashboard } from "../component/CRMDashboard";
import HumanVideoKycList from "../screen/services/humanVideoKyc/HumanVideoKycList";
import GoLive from "../screen/services/humanVideoKyc/GoLive";
import CallInQueues from "../screen/services/humanVideoKyc/CallInQueues";
import LoanConfigurationComponent from "../screen/admin/loanConfiguration";
import ConfigInputsFieldsComponent from "../screen/admin/loanConfiguration/ConfigInputFields";
import LoanAccountsCardBoard from "../component/LoanAccountsCardBoard";
import LobbyScreen from "../component/Lobby";
import RoomPage from "../component/Room";
import HumanVideoKyc from "../component/HumanVideoKyc";
import CRMLoanAccountDetails from "../component/CRMLoanAccountDetails";
import LiveVideoCallingFeature from "../component/LiveVideoCallingFeature";
import VideoCallInitiation from "../component/VideoCallInitiation";
import ThankYouScreen from "../component/ThankYouScreen";
import CRMVerifyDocument from "../component/CRMVerifyDocument";
import CRMCreditScore from "../component/CRMCreditScore"

const RootNavigator = function (props) {
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });
  // console.log(
  //   "local storage data",
  //   WebStorage.getDataFromWebStorage(USER_DATA_KEY)
  // );
  function determineLoginStatus() {
    // if (
    //   !Utils.isNull(redux_selector.UserData) &&
    //   !Utils.isNull(redux_selector.UserData.user_data) &&
    //   redux_selector.UserData.user_data !== undefined
    // ) {
      return <Dashboard />;
   
  }

  return (
    <Routes>
      <Route absolute path="/" element={<Dashboard />} />
      <Route path="/samdDepartment" element={<SAMDDepartment />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/verifyEmail" element={<VerifyEmail/>}/>
      {/* <Route path="/reports" element={<ReportScreen />} /> */}
      {/* <Route path="/acverify" element={<AccountVerification />} /> */}
      <Route path="/accountsDepartment" element={<AccountsDepartment />} />
      <Route path="/accountsDepartment/reconciliation" element={<Reconciliation />} />
      <Route path="/UserConfig" element={<UserConfig />} />
      <Route path="/Contact" element={<Contact/>} />
      <Route path="/Audit"   element={<Audit/>}/>
      <Route path="/queueConfiguration" element={<QueueConfiguration/>}/>
      <Route path="/aml" element={<AmlScreen/>}/>
      <Route path="/updatePhoneNumber" element={<PhoneNumberUpdate/>}/>
      <Route path="/record" element={<VideoRecognition/>}/>
      <Route path="/UserManagement" element={<UserManagement/>}/>
      <Route path="/UserEdit" element={<UserEdit/>}/>
      <Route path="/linkExpired" element={<TokenExpiredPage/>}/>
      <Route path="/viewRequest" element={<ViewRequest/>}/>
      <Route path="/videoKyc" element={<VideoKyc/>}/>
      <Route path="/permissionModal" element={<PermissionModal/>}/>
      <Route path="/kycOtpScreen" element={<OtpScreen/>}/>
      <Route path="/regularAccount" element={<RegularSavingAccount/>}/>
      <Route path="/minorAccount" element={<MinorsAccount/>}/>
      <Route path="/AccountDetail" element={<AccountDetail/>}/>
      <Route path="/ivrConfiguration" element={<IvrConfiguration/>}/>
      <Route path="/services" element={<ServiceCompoent/>}/>
      <Route path="/my-task" element={<MyTaskCompoent/>}/>
      <Route path="/updateIvrTable" element={<IvrTableUpdate/>}/>
      <Route path="/ivrView" element={<IvrView/>}/>
      <Route path="/callingList" element={<IvrCallingList/>}/>
      <Route path="/callingDetails" element={<IvrCallingDetails/>}/>
      <Route path="/phoneCallDetails" element={<IvrPhoneCallDetails/>}/>
      <Route path="/initiateIVR" element={<InitiateIVRCoponent/>}/>
      <Route path="/admin" element={<Admin/>}/>
      <Route path="/writtenOfPortfolio" element={<WrittenOfPortfolio/>}/>
      <Route path="/createPortfolio" element={<NewPortfolio/>}/>
      <Route path="/viewPortfolio" element={<ViewPortfolio/>}/>
      <Route path="/LoanStorage" element={<LoanStorage/>}/>
      <Route path="/ViewFolderDetails" element={<ViewFolderDetails/>}/>
      <Route path="/IVRWorkflow" element={<IVRWorkflow/>}/>
      <Route path="/IVRMain" element={<IVRMain/>}/>
      <Route path="/AccountUpdateDetails" element={<AccountUpdateDetails/>}/>
      <Route path="/InformationUpdate" element={<InformationUpdate/>}/>
      <Route path="/transactionControl" element={<TransactionControl/>}/>
      <Route path="/nomineeDetails" element={<NomineeDetails/>}/>
      <Route path="/nomineeQuickLinks" element={<NomineeQuickLinks/>}/>
      <Route path="/conventionalAgentBanking" element={<ConventionalAgentBanking/>}/>
      <Route path="/detailPage" element={<DetailsPagee/>}/>
      <Route path="/InformationUpdateDashboard" element={<InformationUpdateDashboard/>}/>
      <Route path="/SearchAccount" element={<SearchAccount/>}/>
      <Route path="/RaiseAccountRequest" element={<RaiseAccountRequest/>}/>
      <Route path="/RaiseRequestApproval" element={<RaiseRequestApproval/>}/>
      <Route path="checkEmail" element={<CheckEmail/>}/>
      <Route path="/reset/password" element={<ResetPassword/>}/>
      <Route path="/transactionType" element={<TransactionType/>}/>
      <Route path="/AccountTitleChange" element={<AccountTitleChange/>}/>
      <Route path="/ConventionalAgentBankingCard" element={<ConventionalAgentBankingCard/>}/>
      <Route path="/queueConfigurationS02" element={<QueueConfigurationS02/>}/>
      <Route path="/queueConfigurationS07" element={<QueueConfigurationS07/>}/>
      <Route path="/queueConfigurationCommon" element={<QueueConfigurationCommon/>}/>
      <Route path="/changeSignatureCard" element={<AccountUpdateDetails/>}/>
      <Route path="/changeLimitUpdate" element={<AccountUpdateDetails/>}/>
      <Route path="/changeProfile" element={<AccountUpdateDetails/>}/>
      <Route path="/changePersonalInfo" element={<AccountUpdateDetails/>}/>
      <Route path="/changeAccountDetails" element={<AccountUpdateDetails/>}/>
      <Route path="/consumerFinance" element={<ConsumerFinance/>}/>
      <Route path="/editConsumer" element={<EditViewConsumerFinance/>}/>
      <Route path="/nomineeDetailsPage" element={<NomineeDetailsPage/>}/>
      <Route path="/logoAndTheme" element={<LogoAndThemeComponent/>}/>
      <Route path="/logs" element={<Logs/>}/>
      <Route path="/videokyclist" element={<VideoKYCListComponent/>}/>
      <Route path="/viewKYCRequest" element={<ViewKYCRequest/>}/>
      <Route path="/CRMDashboard" element={<CRMDashboard/>}/>
      <Route path="/humankyclist" element={<HumanVideoKycList/>}/>
      <Route path="/goLive" element={<GoLive/>}/>
      <Route path="/callInQueues" element={<CallInQueues/>}/>
      <Route path="/loanConfig" element={<LoanConfigurationComponent/>}/>
      <Route path="/createLoanConfig" element={<ConfigInputsFieldsComponent/>}/>
      <Route path="/loanAccountsCardBoard" element={<LoanAccountsCardBoard/>}/>
      <Route path="/lobby" element={<LobbyScreen />} />
      <Route path="/room" element={<RoomPage />} />
      <Route path="/HumanVideoKyc" element={<HumanVideoKyc/>}/>
      <Route path="/CRMLoanAccountDetails" element={<CRMLoanAccountDetails/>}/>
      <Route path="/LiveVideoCallingFeature" element={<LiveVideoCallingFeature/>}/>
      <Route path="/VideoCallInitiation" element={<VideoCallInitiation/>} />
      <Route path="/ThankYou" element={<ThankYouScreen/>} />
      <Route path="/CRMVerifyDocument" element={<CRMVerifyDocument/>} />
      <Route path="/CRMCreditScore" element={<CRMCreditScore/>} />
    </Routes>
  );
};

export default RootNavigator;