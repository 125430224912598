import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { WithAuth } from '../../common/WithAuth';
import "../../styles/userManagement.scss";
import API from "../../helpers/api/index";
import Icon from "../../component/core-component/Icon";
import CreateUserModal from "../../component/CreateUserModal";
import UserManagementTable from "../../component/UserManagementTable"
import { getCurrentProcessPerm } from "../../helpers/auth";
import { useDispatch } from 'react-redux';
import { SET_USER_LIST } from '../../redux/actions';
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import Pagination from "../../component/Pagination";
import Validation from "../../utils/Validation";
import { openingModal , closingModal } from "../../redux/actions";

function UserManagement() {
    const dispatch = useDispatch();
    const [initialData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        mobile_phone:'',
        role_id: '',
    });
    const [data,setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false);
    const [columns,setColumns] = useState([
        // {
        //     title: '#',
        //     key: 'index_no',
        //     style: { width: '20%',textAlign: 'center'},
        // },
        // {
        //     title: 'ID NUMBER',
        //     key: 'id',
        //     // style: { width: 'width: 70%'},
        // },
        {
            title: 'FIRST NAME',
            key: 'first_name',
            style: { width: '20%'},
        },
        {
            title: 'LAST NAME',
            key: 'last_name',
            style: { width: '20%'},
        },
        {
            title: 'EMAIL',
            key: 'email',
            style: { width: '20%'},
        },
        {
            title: 'PHONE',
            key: 'mobile_phone',
            style: { width: '20%'},
        },


        // {
        //     title: 'CLIENTS ASSIGNED',
        //     key: 'clients_assigned',
        // },
        {
            title: 'ROLE',
            key: 'role_id',
            style: { width: '20%'},
        },
        {
            title: 'STATUS',
            key: 'status',
            style: { width: '20%',textAlign: 'center'}
        },
    ])
    const isMobileDevice = window.screen.width <= 900;
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data?.length / recordsPerPage);
    const navigate = useNavigate();

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const formatUserListData = (listData) =>{
        listData?.forEach((data,idx) => {
                data.role_id =  data?.role_id === 2 ? 'SYSTEM ADMIN' : 'SUPER ADMIN';
                data.index_no = idx + 1 ;
                // data.name = data?.first_name;
            })
        return listData
    }
    const fetchData = async () => {
        try {
          const response = await API.apiGet('users/list');
          setData(formatUserListData(response?.data?.data));
          dispatch({type: SET_USER_LIST, payload: response?.data?.data});
          setDataLoading(false)
        } catch (error) {
         console.log("error---->>>>>>>>",error);
         setDataLoading(false)
        }
      };

    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false)
    
    const handleFocusOut = (e) => {
        let name = e.target.name;
        let value =  e.target.value;
        setFormData({
            ...formData,
            [name]: value?.trim(),
        });

        setErrors({
            ...errors,
            [name]: '',
        });
    }

    const handleChange = (e, phoneName) => {
        let name;
        let value;
        if(phoneName==="mobile_phone"){
            name = !e.target ? 'mobile_phone' : e.target.name;
            value =  !e.target ? `+${e}`: e.target.value;
        }
        else{
        name = !e.target ? 'role_id' : e.target.name;
        value =  !e.target ? e : e.target.value;
    }
        
        setFormData({
            ...formData,
            [name]: value,
        });

        // Remove error message when user starts typing in the current field
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        setShowLoading(true)
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            let role = formData?.role_id?.value;
            let payload = {
                ...formData, 
                role_id: role
            }
            const encodedPayload = encodeData(payload);
            try {
                const { data } = await API.apiPost(`/users/profile`, { payload: encodedPayload });
                if (data?.success) {
                    setShowModal(false);
                    setFormData(initialData);
                    fetchData();
                    toast.success(data?.message);
                    setShowLoading(false)
                }
                else {
                    toast.error(data?.message)
                    setShowLoading(false)
                }
            }
            catch (error) {
                handleErrorMessage(error);
                // toast.error(error?.response?.data?.message)
                setShowLoading(false)
            }
        } else {
            setErrors(errors);
            setShowLoading(false)
        }
    };

    const checkPassword = (fieldValue) => {
        // Check length
        if (fieldValue?.length < 8) {
            return "Password length should be at least 8 characters.";
        }
    
        // Check for at least one uppercase letter
        if (!/[A-Z]/.test(fieldValue)) {
            return "Password should contain at least one uppercase letter.";
        }
    
        // Check for at least one lowercase letter
        if (!/[a-z]/.test(fieldValue)) {
           return "Password should contain at least one lowercase letter.";
        }
    
        // Check for at least one digit
        if (!/\d/.test(fieldValue)) {
            return "Password should contain at least one digit.";
        }
        // // If all conditions are met, set success message
        // setValidationResult("Password meets all criteria.");
      };

    const validateForm = (data) => {
        let errors = {};
        const validationRules = {
            first_name: {name: 'first_name', required: true, message: 'First name is required', invalidNameMsg: 'Please enter a valid first name!' },
            last_name: {name: 'last_name', required: true, message: 'Last name is required', invalidNameMsg: 'Please enter a valid last name!' },
            email: { required: true, pattern: /\S+@\S+\.\S+/, message: 'Email address is invalid' },
            password: {name:'Password', required: true, pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, minLength: 8, message: 'Password must be at least 8 characters long' },
            role_id: {name: 'role_id', required: true, message: 'Role is required' },
            mobile_phone:{name: 'mobile_phone', required: true, message: 'Mobile Number is required' },
            // Add more fields and validation rules here
        };

        Object.keys(validationRules).forEach(fieldName => {
            const rule = validationRules[fieldName];
            const fieldValue = data[fieldName];
            // Check if field is required and not empty
            if (rule.required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))) {
                errors[fieldName] = rule.message;
            } else if (rule.pattern) {
                if(rule?.name && rule?.name === "Password") {
                    let errorString = checkPassword(fieldValue);
                    if(errorString?.length)
                    {
                        errors["password"] = errorString;
                    }
                } else {
                    // Check if field value matches the pattern (for both single and multi-select fields)
                    if (Array.isArray(fieldValue)) {
                        if (!fieldValue.every(value => rule.pattern.test(value))) {
                            errors[fieldName] = rule.message;
                        }
                    } else if (!rule.pattern.test(fieldValue)) {
                        errors[fieldName] = rule.message;
                    }
                }
            } else if (['first_name', 'last_name']?.includes(rule?.name)) {
                let isInValidName = !Validation?.validateName(fieldValue);
                if (isInValidName) {
                    errors[fieldName] = rule?.invalidNameMsg
                }
            }
        });
        

        return errors;
    };

    // Field configurations
    const fields = [
        { label: 'First Name', id: 'firstName', name: 'first_name', type: 'text' , placeholder: "Enter First Name"},
        { label: 'Last Name', id: 'lastName', name: 'last_name', type: 'text' ,placeholder: "Enter Last Name"},
        { label: 'Email Address', id: 'email', name: 'email', type: 'email' ,placeholder: "Enter Email"},
        { label: 'Password', id: 'password', name: 'password', type: 'password',placeholder: "Enter Password" },
        { label: 'Phone Number', id: 'phone', name: 'mobile_phone', type: 'number',placeholder: "Enter Mobile Number"},
        { label: 'Assign Roles', id: 'roles', name: 'role_id', type: 'text'},
    ];

    const roleOptions = [
        { label: 'SUPER ADMIN', value: 1 },
        { label: 'SYSTEM ADMIN', value: 2 }
    ];

    useEffect(()=>{
        setDataLoading(true)
        if(getCurrentProcessPerm(9)?.write)
            setColumns([...columns, {
                title: 'ACTIONS',
                key: 'actions',
                style: { width: '20%'},
            },])
        fetchData();
    },[])

    const editUser = (data) => {
        navigate(`/UserEdit?user_id=${data?.id}`)
    };

    const getStatusKeyColor = (value) => {
        let statusColorClass = ''
        if (value === 'Active') {
            statusColorClass = 'greenStatusColor'
        } else if (value === 'InActive') {
            statusColorClass = 'redStatusColor'
        }
        return statusColorClass;
    };

    return (
        <>
            <div className={`flex dark:bg-dark-background reconcilitaion-main-container`}>
                <SideMenu headerName="User Management"/>
                <div className={`flex-1 userManagement mainBodyContainer ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header />
                    {/* {dataLoading && <div className="absolute w-full h-full flex items-center top-25 left-12 justify-center" >
                        <ColorRing
                        visible={true}
                        height="80"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[]}
                        />
                    </div>} */}
                    <div className={`z-0 container mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'px-5 py-7 max-w-full responsiveMainContainer'}`} style={{ opacity: dataLoading ? 0.5 : 1 }}>
                        <div className={`flex flex-row justify-between userHeading pt-10 px-10 ${isMobileDevice && 'responsiveUserHeading'}`}>
                            {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 userManagementHeading">
                                User Management
                            </h1>}
                            { getCurrentProcessPerm(5)?.write && <div>
                                <button
                                    className={`bg-blue-500 rounded-lg hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded disabled:disabledBtn uploadAndCompareBtn custom_btn_primary commonBtn`}
                                    onClick={()=> {setShowModal(true); dispatch(openingModal()) }}
                                >
                                    Create New User +
                                </button>
                            </div>}
                        </div>
                        {isMobileDevice ? (
                            <div>
                                {currentRecords?.map((item, index) => (
                                <div key={`block_${index}`} className="mt-3 border bg-white mb-4 rounded-lg">
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>First Name</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Last Name</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Email</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.email}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Phone</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.mobile_phone}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Role</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.role_id}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Status</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>{item?.status}</span>
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`}>Status</span>
                                        <div className="flex items-center justify-between">
                                            <div className={`rounded-full ${getStatusKeyColor(item?.status)}`}>
                                                <span className={`${getStatusKeyColor(item?.status) === 'greenStatusColor' ? 'green-dot' : 'red-dot'}`}></span>
                                                {item?.status ? item?.status : '--'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center border-b p-2.5">
                                        <span className={`font-bold ${isMobileDevice && 'ms-2'}`} onClick={()=>{editUser(item)}} style={{color: "#517dd2"}}>View Details</span>
                                        {/* <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'userTableData'}`}>
                                                <svg onClick={()=>{editUser(item)}}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={20} className='cursor-pointer'><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                                ))}
                                <div className="reponsivePagination">
                                    <Pagination nPages={nPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    ></Pagination>
                                </div>
                            </div>
                        ) : (
                            <UserManagementTable 
                                columns={columns} 
                                rowData={data} 
                                isUserMangementTable={true} 
                                tableLodaing={dataLoading}
                                showActions={getCurrentProcessPerm(5)?.write}
                            />
                        )}
                        {
                           showModal &&  
                            <CreateUserModal 
                                formData={formData} 
                                setFormData={setFormData} 
                                errors={errors} 
                                setErrors={setErrors} 
                                fields={fields} 
                                roleOptions={roleOptions} 
                                handleChange={handleChange} 
                                handleSubmit={handleSubmit} 
                                setShowModal={setShowModal} 
                                initialData={initialData} 
                                showLoading={showLoading}
                                handleFocusOut={handleFocusOut}
                            />
                        }
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(UserManagement);
