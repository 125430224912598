"use-strict";

///////////////////////////Private Functions START//////////////////////////////

let WebStorage = {
  /********************************************************************
    Save string in local storage

    @param key {String} - a constant which will be used as identifier while retriving or editing value
    @param value {String} - string what we will saving in async storage

    *********************************************************************/

  saveDataInWebStorage: function (key, value) {
    return localStorage.setItem(key, value);
  },

  /********************************************************************
    Get data from local storage

    @param key {String} - a constant which will be used as identifier while retriving or editing value

    *********************************************************************/

  getDataFromWebStorage: function (key) {
    return localStorage.getItem(key);
  },

  /********************************************************************
    Delete data from local storage

    @param key {String} - a constant which will be used as identifier while retriving or editing value

    *********************************************************************/

  clearDataFromWebStorage: function (key, value) {
    return localStorage.removeItem(key);
  },
};

export default WebStorage;

////////////////////////////Private Functions END///////////////////////////////
