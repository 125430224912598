import React, { useState } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { toast } from 'react-toastify';

export default function ({isOpenNewPassKeyModal, onClose, handleSubmit, onFormDataChange, createKeyPassLoader, closeCreateKeyPassModal}) {

    const [isValidityChecked, setValidityCheck] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const isMobileDevice = window.screen.width <= 900; 

    const handleCloseModal = () => {
        onClose();
        setFormData({});
    };

    const toggleChecked = () => {
        setValidityCheck(!isValidityChecked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            "limitedValidity": !isValidityChecked
        }));
        onFormDataChange((prevFormData) => ({
            ...prevFormData,
            "limitedValidity": !isValidityChecked
        })); 
    };

    // const isAllFieldsValid = () => {
    //     const validationErrors = {};
        
    //     const fieldsToCheck = [
    //         { field: 'name', message: "Please enter name" },
    //         { field: 'userName', message: "Please enter username" },
    //         { field: 'password', message: "Please enter password" },
    //         { field: 'validityPeriod', message: "Please select the validity period" },
    //         { field: 'portal', message: "Please select the portal" },
    //     ];
    //     fieldsToCheck?.forEach(({ field, message }) => {
    //         if (!formData[field]) {
    //             if(field == 'validityPeriod' && !isValidityChecked) {
    //                 return {};
    //             }
    //             else validationErrors[field] = message;
    //         }
    //     });
    
    //     setErrors(validationErrors);
    //     return Object.keys(validationErrors)?.length === 0;
    // }

    const isAllFieldsValid = () => {
        const validationErrors = {};
    
        const fieldsToCheck = [
            { field: 'name', message: "Please enter name" },
            { field: 'userName', message: "Please enter username" },
            { field: 'password', message: "Please enter password" },
            { field: 'validityPeriod', message: "Please select the validity period" },
            { field: 'portal', message: "Please select the portal" },
        ];
    
        fieldsToCheck.forEach(({ field, message }) => {
            if (field === 'validityPeriod' && !isValidityChecked) {
                return {};
            }
    
            if (!formData[field] || formData[field].trim() === '') {
                validationErrors[field] = message;
            }
        });
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event?.target;
        // if(name == "termsAndConditions") setIsChecked(!isChecked);
        if(!isValidityChecked) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                limitedValidity: false,
            }));
            onFormDataChange((prevFormData) => ({
                ...prevFormData,
                limitedValidity: false,
            })); 
        }

        // if(name == 'termsAndConditions') {
        //     setFormData((prevFormData) => ({
        //         ...prevFormData,
        //         [name]: event?.target?.checked
        //     }))
        // }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }))
        onFormDataChange((prevFormData) => ({
            ...prevFormData,
            [name]: value
        })); 

        setErrors((prevState) => ({
            ...prevState,
            [name]: '',
          }));
    };

    const handleCreateKeyPass = () => {
        if(isAllFieldsValid()) handleSubmit(); 
    }

  return (
    <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden={!isOpenNewPassKeyModal} className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-14 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ${isOpenNewPassKeyModal ? '' : 'hidden'} ${isMobileDevice && 'newKeyPassModalContainer_responsive'}`}>
        <div className={`relative p-4 newKeyPassModal ${isMobileDevice && 'newKeyPassModal_responsive'}`}>
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex rounded-t">
                    <div className={`newKeyPassLogo border-r-2 ${isMobileDevice && 'hidden'}`}>
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white p-3 ml-4 mt-2">
                            New Key Pass
                        </h2>  
                        <div className='text-center h-5/6 flex flex-col justify-center items-center'>
                            <img src="/assets/images/vault_logo.svg" alt="vault_logo" />
                            <span className="vault_textMsg vault_logoCaption">A manager for safekeeping all the Credentials of different websites/platforms used across the process </span>
                        </div>
                    </div>
                    <div className={`flex newKeyPassInputFields flex-col ${isMobileDevice && 'newKeyPassInputFields_responsive'}`}>
                        <div className={`${isMobileDevice && 'flex'}`}>
                            {isMobileDevice && 
                                <span className='text-xl font-semibold text-gray-900 dark:text-white p-3 ml-4 mt-2'>New Key Pass</span>
                            }
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white float-right m-4" onClick={handleCloseModal} data-modal-hide="static-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className='px-10 py-3'>
                            <div>
                                <div className='flex flex-col'>
                                    <label className="font-medium">Name</label>
                                    <input className='p-2 border-2 rounded-lg' type="text" id="name" name="name" placeholder="Name" onChange={handleChange}/>
                                    {errors?.name && <p className="text-red-500 text-sm mt-1">{errors?.name}</p>}
                                </div>
                                <div className='flex flex-col mt-9'>
                                    <label className="font-medium">Username</label>
                                    <input className='p-2 border-2 rounded-lg' type="text" id="userName" name="userName" placeholder="Username" onChange={handleChange}/>
                                    {errors?.userName && <p className="text-red-500 text-sm mt-1">{errors?.userName}</p>}
                                </div>
                                <div className='flex flex-col mt-9'>
                                    <label className="font-medium">Password</label>
                                    <input className='p-2 border-2 rounded-lg' type="password" id="password" name="password" placeholder="Password" onChange={handleChange}/>
                                    {errors?.password && <p className="text-red-500 text-sm mt-1">{errors?.password}</p>}
                                </div>
                                <div className="flex items-center mt-7">
                                    <label className="font-medium">
                                       <span> Limited Validity </span>
                                    </label>
                                    <input
                                        id="toggle"
                                        type="checkbox"
                                        className="hidden"
                                        checked={isValidityChecked}
                                        onChange={toggleChecked}
                                    />
                                    <label
                                        htmlFor="toggle"
                                        className={`relative w-10 h-6 ml-2 transition-colors duration-200 ease-in-out rounded-full ${
                                            isValidityChecked ? 'bg-[#02839F]' : 'bg-gray-400'
                                        }`}
                                    >
                                        <span
                                            className={`absolute left-1 top-1 w-4 h-4 transition duration-200 ease-in-out transform ${
                                                isValidityChecked ? 'translate-x-full bg-white' : 'bg-gray-100'
                                            } rounded-full`}
                                        />
                                    </label>
                                </div>
                                <div className="mt-7">
                                    {isValidityChecked && (
                                        <div>
                                            <label className="font-medium">Select Expiry Date</label>
                                            <input
                                             type="date"
                                             placeholder="select date"
                                             id="validityPeriod"
                                             name="validityPeriod"
                                             className="block w-full rounded-lg border px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                                             onChange={handleChange}
                                            />
                                            {errors?.validityPeriod && isValidityChecked && (
                                                <p className="text-red-500 text-sm mt-1">{errors?.validityPeriod}</p>
                                            )}                                        
                                        </div>
                                    )}
                                </div>
                                {/* <div className="mt-10 flex items-center">
                                    <input type="checkbox" className="h-4 w-4" checked={isChecked} onChange={handleChange} name="termsAndConditions"/>
                                    <span className="ml-5 font-medium">I accept the Terms & Conditions applied.</span>
                                </div>
                                {errors?.termsAndConditions && <p className="text-red-500 text-sm mt-1">{errors?.termsAndConditions}</p>} */}
                                <div className="mt-10 flex flex-col">
                                    <label className="font-medium">Portal</label>
                                    <select 
                                    onChange={handleChange} 
                                    id="portal"
                                    name="portal"
                                    className="block w-full rounded-lg border px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none">
                                        <option hidden>Select Portal</option>
                                        <option value="Birth Registration">Birth Registration</option>
                                        <option value="NID">NID</option>
                                        <option value="Bank Asia">Bank Asia</option>
                                    </select>
                                </div>
                                {errors?.portal && <p className="text-red-500 text-sm mt-1">{errors?.portal}</p>}
                            </div>
                            
                            <div className="flex justify-end items-center p-4 md:p-5 mt-8 rounded-b dark:border-gray-600">
                                <button data-modal-hide="static-modal" type="button" className={`text-white h-10 flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn ${createKeyPassLoader && 'bg-gray-400 cursor-not-allowed'}`} onClick={handleCreateKeyPass} disabled={createKeyPassLoader}>
                                    <div className="flex items-center">
                                        Create Key-Pass
                                        {createKeyPassLoader && 
                                            <ColorRing
                                            visible={true}
                                            height="30"
                                            width="30"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={['#ffffff','#ffffff','#ffffff','#ffffff','#ffffff']}
                                            />
                                        }
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
