import { useState } from "react";
import {
    Image,
} from "../../component/core-component";
import API from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner';
import { encodeData } from "../../common/commonFunction";


const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState()
    const [formData, setFormData] = useState(
        {
            newPassword: "",
            confirmPassword: ""
        }
    )
    const [showErrors, setShowErrors] = useState(false)
    const handleChange = (e) => {
        setError();
        let {name, value} = e?.target;
        setFormData((prev) => ({...prev, [name]: value}));
        let {newPassword, confirmPassword} = formData;
        if(formData["newPassword"]) {
            if(name == "confirmPassword") {
                if(! (newPassword === value ) && value != "")
        {
            setShowErrors(true);
        }
        if(value == "") {
            setShowErrors(false)
        }
        if(newPassword === value && value != "")
        {
            setShowErrors(false)
        }
            }
            if(name == "newPassword") {
                let {newPassword, confirmPassword} = formData;
                if(confirmPassword) {
                    if(newPassword !== confirmPassword) {
                        setShowErrors(true)
                    }
                }
            
            }
        }
    }
    const validate = () => {
        let {newPassword, confirmPassword} = formData;
        if(newPassword !== confirmPassword) {
            if(newPassword == "") {
                setError("New Password is empty");
            }
            else if(confirmPassword == "") {
                setError("Confirm Password is empty");
            }
            else {
                setError("Confirm Password do not matches with New password");
            }
            
            setShowErrors(true)
        }
        return ((newPassword === confirmPassword) ? true : false);
        
    }
    const handleReset = async() => {
        setLoading(true)
        let params = new URLSearchParams(window.location.search);
        let {newPassword, confirmPassword} = formData;
        try {
            
            let payload = {
                id: params.get("query"),
                newPassword: formData?.newPassword
            }
            const encodedPayload = encodeData(payload);
            if(validate()) {
                if(newPassword !== "" && newPassword === confirmPassword){
                    const response = await API.apiPost(`/users/update/password`,{payload: encodedPayload});
                    toast.success(response?.data?.message);
                    navigate('/login')
                }
                
            }
            setLoading(false);
    
        } catch(err) {
            toast.error(err?.message);
            setLoading(false);
        }
    }

    const handleMatchPassword = () => {
        let {newPassword, confirmPassword} = formData;
        if(!newPassword.includes(confirmPassword))
        {
            setShowErrors(true);
        }
    }
return(
    <>
    <div className="flex justify-center items-center flex-col backImage" style={{height:"100vh"}}>
    <div className="flex justify-center pb-6 flex-col">
        <Image
            source={"/assets/images/logo.svg"}
            height={70}
            width={70}
            alt="Logo"
          />
          </div> 
          <div>
            <div className="text-3xl font-bold">Reset Password</div>
            <div className="py-3 w-96">
                <label>New Password</label>
                <input className="mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md" name="newPassword" type="password" onChange={handleChange}/>
            </div>
            <div className="py-3">
                <label>Confirm New Password</label>
                <input className="mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md" name="confirmPassword" type="password" onChange={handleChange} />
                <div>
                {showErrors && <span className="text-red-500 text-sm" >{error}</span>}
                </div>
            </div>
          </div>
          <div className="text-center font-bold flex justify-center">
            <button className="btn relative flex items-center justify-center w-40 h-12 bg-blue-500 text-white font-bold py-2 px-4 rounded cursor-pointer" type="submit" onClick={handleReset}>
            {
                !loading ? `Continue` : (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center" style={{background: "#ffffff9c"}} >Continue
                <ColorRing
                 visible={true}
                 height="40"
                 width="40"
                 ariaLabel="color-ring-loading"
                 wrapperStyle={{ position: 'absolute', inset: "3px 115px" }}
                 wrapperClass="color-ring-wrapper"
                 colors={['#ffffff','#ffffff', '#ffffff', '#ffffff','#ffffff']}
                />
                </div>
                )
            }
        </button></div>

    </div>
    <ToastContainer></ToastContainer>
    </>
)
}

export default ResetPassword;