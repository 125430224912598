// Breadcrumb.js
import React from "react";
import Icon from "../../component/core-component/Icon";
import { useNavigate } from "react-router";
import { useState} from "react";
import { toast} from "react-toastify";
import "../../styles/infoChangeRequest.scss"

const ChangePhotographModal = ({ setFileData, fileData }) => {
    const [profilePictureLoading, setProfilePictureLoading] = useState('noprogress');
    const [profileData, setProfileData] = useState(0);
    const [isProfileLoading, setIsProfileLoading] = useState(false);

    const [signatureCardLoading, setSignatureCardLoading] = useState('noprogress');
    const [signatureData, setSignatureData] = useState(0);
    const [isSignatureLoading, setIsSignatureLoading] = useState(false);

    const [formLoading, setFormLoading] = useState('noprogress');
    const [formData, setFormData] = useState(0);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const isMobileDevice = window.screen.width <= 900;

    const handleUpdateProfileCard = (event, isDragged) => {
        setProfilePictureLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setProfilePictureLoading('uploading');
            setTimeout(() => {
                setProfilePictureLoading('uploaded');
                setProfileData({ ...profileData, pdf });
                setFileData({ ...fileData, profileDocument: pdf })
            }, 3000)
        } else {
            event.target.value = ''
            setProfileData({ ...profileData, pdf: '' });
            toast.error("Please select an image.");
        }
    };
    const profileDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleUpdateProfileCard(e, true)
    }
    const profileDragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleClearProfile = (filetype) => {
        setProfileData({ ...profileData, [filetype]: '' })
        setProfilePictureLoading('noprogress')
    }

    const handleUpdateSignatureCard = (event, isDragged) => {
        setSignatureCardLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setSignatureCardLoading('uploading');
            setTimeout(() => {
                setSignatureCardLoading('uploaded');
                setSignatureData({ ...signatureData, pdf });
                setFileData({ ...fileData, supportDocument: pdf })
            }, 3000)
        } else {
            event.target.value = ''
            setSignatureData({ ...signatureData, pdf: '' });
            toast.error("Please select an image.");
        }
    };
    const signatureDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleUpdateSignatureCard(e, true)
    }
    const signatureDragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleClearSignature = (filetype) => {
        setSignatureData({ ...signatureData, [filetype]: '' })
        setSignatureCardLoading('noprogress')
    }

    const handleUpdateFormData = (event, isDragged) => {
        setFormLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setFormLoading('uploading');
            setTimeout(() => {
                setFormLoading('uploaded');
                setFormData({ ...formData, pdf });
                setFileData({ ...fileData, formDocument: pdf })
            }, 3000)
        } else {
            event.target.value = ''
            setFormData({ ...formData, pdf: '' });
            toast.error("Please select an image.");
        }
    };
    const formDataDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleUpdateFormData(e, true)
    }
    const formDataDragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleClearFormData = (filetype) => {
        setFormData({ ...formData, [filetype]: '' })
        setFormLoading('noprogress')
    }

  return (
    <div className="flex flex-wrap justify-between">
        <div className={`${isMobileDevice ? 'w-full' : 'uploaderFileCard'}`}>
            <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 ${isMobileDevice ? '' : 'uploadCard'}`}>
                    <span className="text-2xl font-semibold mb-4 text-center w-full block">
                        {profilePictureLoading === 'noprogress' && 'Upload New Photograph'}
                        {profilePictureLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                        {profilePictureLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Photograph...</span>}
                        {profilePictureLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Photograph Uploaded</span>}
                    </span>

                    <div className="w-60 flex justify-center" onDrop={profileDropHandler} onDragOver={profileDragOverHandler}>
                        <span style={{ width: '45px' }} className={!profileData?.pdf?.name ? 'un_selected' : ''} >
                            <img src="assets/images/pdf.svg"></img>
                        </span>
                    </div>

                    {profileData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={profileData?.pdf?.name} >{profileData?.pdf?.name?.length > 14 ? profileData?.pdf?.name?.substring(0, 14) + "..." : profileData?.pdf?.name}{!isProfileLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearProfile('pdf')} />}</span>}
                    {profilePictureLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                    {profilePictureLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                        {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                    </p>
                        <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleUpdateProfileCard}
                                className="mb-4"
                                hidden
                            />
                            Upload
                            <img src="assets/images/icon_upload.svg" />
                        </label>
                    </>
                    }
                    {profilePictureLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                        <div
                            className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                        ></div>
                    </div>}
                </div>
        </div>
        <div className={`${isMobileDevice ? 'w-full mt-4' : 'uploaderFileCard'}`}>
        <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 ${isMobileDevice ? '' : 'uploadCard'}`}>
            <span className="text-2xl font-semibold mb-4 text-center w-full block">
                {signatureCardLoading === 'noprogress' && 'Upload Supporting Document'}
                {signatureCardLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                {signatureCardLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Supporting Doc...</span>}
                {signatureCardLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Supporting Doc. Uploaded</span>}
            </span>

            <div className="w-60 flex justify-center" onDrop={signatureDropHandler} onDragOver={signatureDragOverHandler}>
                <span style={{ width: '45px' }} className={!signatureData?.pdf?.name ? 'un_selected' : ''} >
                    <img src="assets/images/pdf.svg"></img>
                </span>
            </div>

            {signatureData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={signatureData?.pdf?.name} >{signatureData?.pdf?.name?.length > 14 ? signatureData?.pdf?.name?.substring(0, 14) + "..." : signatureData?.pdf?.name}{!isSignatureLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearSignature('pdf')} />}</span>}
            {signatureCardLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

            {signatureCardLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
            </p>
                <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleUpdateSignatureCard}
                        className="mb-4"
                        hidden
                        multiple
                    />
                    Upload
                    <img src="assets/images/icon_upload.svg" />
                </label>
            </>
            }
            {signatureCardLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                <div
                    className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                ></div>
            </div>}
        </div>
        </div>
        <div className={`${isMobileDevice ? 'w-full mt-4' : 'uploaderFileCard'}`}>
        <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 ${isMobileDevice ? '' : 'uploadCard'}`}>
                <span className="text-2xl font-semibold mb-4 text-center w-full block">
                    {formLoading === 'noprogress' && 'Upload Paper Form'}
                    {formLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                    {formLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Paper Form...</span>}
                    {formLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Paper Form Uploaded</span>}
                </span>

                <div className="w-60 flex justify-center" onDrop={formDataDropHandler} onDragOver={formDataDragOverHandler}>
                    <span style={{ width: '45px' }} className={!formData?.pdf?.name ? 'un_selected' : ''} >
                        <img src="assets/images/pdf.svg"></img>
                    </span>
                </div>

                {formData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={formData?.pdf?.name} >{formData?.pdf?.name?.length > 14 ? formData?.pdf?.name?.substring(0, 14) + "..." : formData?.pdf?.name}{!isFormLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearFormData('pdf')} />}</span>}
                {formLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                {formLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                    {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                </p>
                    <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpdateFormData}
                            className="mb-4"
                            hidden
                        />
                        Upload
                        <img src="assets/images/icon_upload.svg" />
                    </label>
                </>
                }
                {formLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                    <div
                        className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                    ></div>
                </div>}
        </div>
        </div>
    </div>
  );
};

export default ChangePhotographModal;
