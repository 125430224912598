import Icon from '../component/core-component/Icon';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import styles from '../../src/styles/common.module.scss';
const PaginationComponent = ({ currentPage, skip, list, onPageChange, limitPerPage, loading }) => {
  return (
    <ul className={`pagination ${styles.paginationContainer}`}>
      {list && list.length &&
        <Pagination
          onChange={onPageChange}
          current={currentPage}
          total={list.length}
          pageSize={limitPerPage}
          showLessItems
          locale={localeInfo}
          nextIcon={<Icon iconName="IconForward" width={16} height={16}/>}
          prevIcon={<Icon iconName="IconBackward" width={16} height={16}/>}
        />
       }
    </ul>
  );
};
export default PaginationComponent;