import { Table } from 'antd'
import React from 'react'

export const CRMTable = () => {
    const dataSource = [
        {
          key: '1',
          name: 'John Brown',
          amount: 'TK 20000',
          duration: '1 Month'
        },
        {
            key: '2',
            name: 'John Brown',
            amount: 'TK 20000',
            duration: '1 Month'
          },
          {
            key: '3',
            name: 'John Brown',
            amount: 'TK 20000',
            duration: '1 Month'
          },
          {
            key: '4',
            name: 'John Brown',
            amount: 'TK 10000',
            duration: '23 Days'
          },
          {
            key: '5',
            name: 'John Brown',
            amount: 'TK 20000',
            duration: '1 Month'
          },
      ];
      
      const columns = [
        {
            title: 'Sr.NO',
            dataIndex: 'sr.no',
            key: 'sr.no',
            render: (text, record, index) => `0${index + 1}`,
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Duration',
          dataIndex: 'duration',
          key: 'duration',
        },
      ];
  return (
    <div>
        <Table dataSource = {dataSource} columns = {columns}   pagination={{ pageSize: 5 }}  />
    </div>
  )
}
