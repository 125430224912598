import React from "react";
import SideMenu from "../component/SideMenu";
import Header from "../component/Header";
import Breadcrumb from "../component/Breadcrumb";
import { useState, useEffect } from "react";
import API from "../helpers/api/index";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../component/Pagination";
import styles from "../styles/ivrCallingDetails.module.scss";
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { encodeData, handleErrorMessage } from "../common/commonFunction"
import UpdateLimitModal from "../screen/UpdateAccountModals/UpdateLimitModal";
import UpdateSignatureCardModal from "../screen/UpdateAccountModals/UpdateSignatureCardModal";
import ChangePhotographModal from "../screen/UpdateAccountModals/ChangePhotographModal";
import ChangePersonalInfoModal from "../screen/UpdateAccountModals/ChangePersonalInfoModal";
import ChangeNomineeInfoModal from "../screen/UpdateAccountModals/ChangeNomineeInfoModal";
import ChangeAccountDetails from "../screen/UpdateAccountModals/ChangeAccountDetails";
import Navbar from "../common/Navbar";
import searchIcon from "../assets/images/searchIcon.png"
const AccountUpdateDetails = () => {
    const [ivrConfigCalling, setIvrConfigCalling] = useState();
    const [showModal, setShowModal] = useState(false);
    const [initialDetails, setInitialDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = ivrConfigCalling?.slice(indexOfFirstRecord, indexOfLastRecord);
    let nPages = ivrConfigCalling?.length ? Math.ceil(ivrConfigCalling?.length / recordsPerPage) : 0;
    const [dataLoading, setDataLoading] = useState(false)
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('');
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [raiseRequestModal, setRaiseRequestModal] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const [requestedPage, setRequestedPage] = useState("");
    const [requestedPurpose, setRequestedPurpose] = useState("");
    const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
    const [progress1Loading, setProgress1Loading] = useState('noprogress');
    const [progress2Loading, setProgress2Loading] = useState('noprogress');
    const [data, setData] = useState(0);
    const [profileData, setProfileData] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [AccountDetails, setAccountDetails] = useState();
    const [signatureData, setSignatureData] = useState(0);
    const isMobileDevice = window.screen.width <= 900;
    const [fileData, setFileData] = useState({
        profileDocument: "",
        formDocument: "",
        supportDocument: "",
    });
    const [raiseRequestvalues, setRaiseRequestValues] = useState({
        bank_account_number: "",
        bank_requestPurpose: "",
        bank_accountHolderTitle: "",
        bank_requestType: "",
        bank_remarks: "",
        bank_previousValue: "",
        bank_changeValue: "",
        transaction_day: "",
        amount_day: "",
        transaction_month: "",
        amount_month: "",
        maximum_transaction: "",
        nominee_name: "",
        father_name: "",
        mother_name: "",
        nominee_dob: "",
        nominee_relation: "",
        nominee_percent: "",
        doc_type: "",
        document_nmbr: "",
        nominee_img: "",
        document_front_img: "",
        doc_back_img: "",
        account_title: "",
        personal_dob: "",
        marital_status: "",
        religion: "",
        gender: "",
        spouse_name: "",
        occupation: "",
        email: "",
        sector_code: "",
        nid_number: "",
        dob: "",
    });

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
    };

    const openSearchAccount = () => {
        setOpenAccountModal(true);
    }

    const raiseRequestUpdate = () => {
        setRaiseRequestModal(true);
        setRequestedPage("");
    }

    const raiserequestdata = [
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            account_nmbr: "876875555757",
            request_page: "change signature",
            // requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            // request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        }
    ]

    const filteredData = ivrConfigCalling?.filter(item =>
        (item?.id && item?.id?.toString()?.includes(searchQuery)) ||
        (item?.ivr_workflow_name && item?.ivr_workflow_name?.toLowerCase().toString()?.includes(searchQuery)) ||
        (item?.total_calls && item?.total_calls?.toString()?.includes(searchQuery)) ||
        (item?.uploaded_by && item?.uploaded_by?.toLowerCase()?.includes(searchQuery)) ||
        (item?.created_at && item?.created_at?.includes(searchQuery))
    );
    currentRecords = filteredData?.slice(indexOfFirstRecord, indexOfLastRecord);
    nPages = filteredData?.length ? Math.ceil(filteredData?.length / recordsPerPage) : 0;

    useEffect(() => {
        getRaiseRequestDetails();
        // getAccountDetails();
        setDataLoading(true);
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const pathname = url.pathname;
        const parts = pathname.split('/');
        const desiredPart = parts[parts.length - 1];
        setRequestedPage(desiredPart)
    }, [window.location.href]);


    const getRaiseRequestDetails = async () => {
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request`);
            setIvrConfigCalling(raiserequestdata);
            if (data?.success) {
                // setIvrConfigCalling(raiserequestdata);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                setIvrConfigCalling(raiserequestdata);
                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            setIvrConfigCalling(raiserequestdata);
            toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };


    const handleRaise = async (event) => {
        SetRequestSubmitLoader(true)
        let request_title = ''
        if (requestedPage == 'changeAccountDetails') {
            request_title = 'account_info'
        } else if (requestedPage == 'changeLimitUpdate') {
            request_title = 'transaction_limit'
        } else if (requestedPage == 'changeSignatureCard') {
            request_title = 'account_info'
        } else if (requestedPage == 'account_info') {
            request_title = 'account_info'
        } else if (requestedPage == 'changePersonalInfo') {
            request_title = 'personal_info'
            if (raiseRequestvalues.bank_requestPurpose == 'Change Email') {
                request_title = 'contact_info'
            }
        } else if (requestedPage == 'nomineeDetailsPage') {
            request_title = 'nominee_info'
        } else if (requestedPage == 'changeProfile') {
            request_title = 'account_info'
        } else if (requestedPage == 'changeSignatureCard') {
            request_title = 'account_info'
        }
        if (raiseRequest()) {
            const formData = new FormData();
            formData.append("signature_photo_path", signatureData.pdf)
            let finalPayload = {
                request_title: request_title,
                agent_banking_id: AccountDetails?.id,
                customer_photo_path: fileData.profileDocument
            }
            Object.entries(raiseRequestvalues).forEach(([key, value]) => {
                if (value !== "") {
                    finalPayload[key] = value
                }
            });
            const encodedPayload = encodeData(finalPayload)
            formData.append('metadata', encodedPayload);
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/v1/agentBanking/raise/request`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                response = await response.json();
                if (response?.success) {
                    toast.success(response?.message);
                    closeModal();
                    //   getPhoneUpdateDetails();
                    SetRequestSubmitLoader(false);
                } else {
                    SetRequestSubmitLoader(false);
                    toast.error(response?.error);
                }
            } catch (error) {
                SetRequestSubmitLoader(false);
                handleErrorMessage(error);
            }
        }
        SetRequestSubmitLoader(false);
    };

    const getAccountDetails = async () => {
        const account_no = raiseRequestvalues?.bank_account_number;
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
            if (data?.success) {
                setAccountDetails(data?.data);
                setRaiseRequestValues(prevState => ({
                    ...prevState,
                    dob: data?.data?.personal_info?.DOB,
                    bank_accountHolderTitle: data?.data?.personal_info?.first_name + " " + data?.data?.personal_info?.last_name
                }));
                toast.success(data?.message);
                setDataLoading(false)
            } else {

                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };
    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const handleSearchAccount = () => {
        let errors = {};
        let flag = true;
        if (!accountNumber) {
            errors.accountNumber = "Account Number is required";
            flag = false;
        }
        setFormErrors(errors);
        if (flag) {
            getAccountDetails();
        }
    }

    const handleRequestValues = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;

        setRaiseRequestValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        let previous_value = ''
        if (name == 'bank_requestPurpose') {
            if (requestedPage === "changeLimitUpdate") {
                if (value == 'Change Transaction Day') {
                    previous_value = AccountDetails?.transaction_limit?.no_of_transaction_day
                } else if (value == 'Change Amount Day') {
                    previous_value = AccountDetails?.transaction_limit?.transaction_amount_day
                } else if (value == 'Change Transaction Month') {
                    previous_value = AccountDetails?.transaction_limit?.no_of_transaction_month
                } else if (value == 'Change Amount Month') {
                    previous_value = AccountDetails?.transaction_limit?.transaction_amount_month
                } else if (value == 'Change Maximum Transaction') {
                    previous_value = AccountDetails?.transaction_limit?.max_trans_amount
                }
            }

            if (requestedPage === "changeAccountDetails") {
                if (value == 'Change Account Title') {
                    previous_value = AccountDetails?.account_info?.account_title
                } else if (value == 'Change Sector Code') {
                    previous_value = AccountDetails?.account_info?.sector_code
                }
            }
            if (requestedPage === "changeNomineeInfo") {
                if (value == 'Change Nominee Name') {
                    previous_value = AccountDetails?.nominee_info?.nominee_name
                } else if (value == 'Change Nominee Dob') {
                    previous_value = AccountDetails?.nominee_info?.nominee_dob
                } else if (value == 'Change Nominee Relation') {
                    previous_value = AccountDetails?.nominee_info?.nominee_relation
                } else if (value == 'Change Nominee Percent') {
                    previous_value = AccountDetails?.nominee_info?.nominee_percent
                } else if (value == 'Change Nominee Image') {
                    previous_value = AccountDetails?.nominee_info?.nominee_img
                }
            }

            if (requestedPage === "changePersonalInfo") {
                if (value == 'Change Personal Dob') {
                    previous_value = AccountDetails?.personal_info?.DOB
                } else if (value == 'Change Marital Status') {
                    previous_value = AccountDetails?.personal_info?.marital_status
                } else if (value == 'Change Religion') {
                    previous_value = AccountDetails?.personal_info?.religion
                } else if (value == 'Change Gender') {
                    previous_value = AccountDetails?.personal_info?.gender
                } else if (value == 'Change Email') {
                    previous_value = AccountDetails?.contact_info?.email
                }
                else if (value == 'Change Occupation') {
                    previous_value = AccountDetails?.personal_info?.occupation
                }
                else if (value == 'Change Spouse Name') {
                    previous_value = AccountDetails?.personal_info?.spouse_name
                } else if (value == 'Change Account Title') {
                    previous_value = AccountDetails?.account_info?.account_title
                }
            }
            if (requestedPage === 'changeProfile') {
                previous_value = 'ChangePhoto'
            }
            if (requestedPage === 'changeSignatureCard') {
                previous_value = 'SignatureCard'
            }
            setRaiseRequestValues((prevState) => ({
                ...prevState,
                bank_previousValue: previous_value,
            }));
        }
    }
    const raiseRequest = () => {
        let errors = {};
        if (!raiseRequestvalues?.bank_account_number) {
            errors.bank_account_number = "Account Number is required";
        }
        if (!raiseRequestvalues?.bank_requestPurpose) {
            errors.bank_requestPurpose = "Request Purpose is required";
        }
        if (!raiseRequestvalues?.bank_requestType) {
            errors.bank_requestType = "Request Type is required";
        }
        if (!raiseRequestvalues?.bank_remarks) {
            errors.bank_remarks = "Remarks are required";
        }
        if (!raiseRequestvalues?.bank_previousValue) {
            errors.bank_previousValue = "Previous Value is required";
        }
        if (!raiseRequestvalues?.bank_changeValue) {
            errors.bank_changeValue = "Change Value is required";
        }
        if (!raiseRequestvalues?.nid_number) {
            errors.nid_number = "Nid Number is required";
        }
        if (!raiseRequestvalues?.dob) {
            errors.dob = "Dob is required";
        }
        if (requestedPage === "changeLimitUpdate") {
            if (requestedPurpose === "Change Transaction Day") {
                if (!raiseRequestvalues?.transaction_day) {
                    errors.transaction_day = "Transaction/day is required";
                }
            }
            if (requestedPurpose === "Change Amount Day") {
                if (!raiseRequestvalues?.amount_day) {
                    errors.amount_day = "Amount/day is required";
                }
            }
            if (requestedPurpose === "Change Transaction Month") {
                if (!raiseRequestvalues?.transaction_month) {
                    errors.transaction_month = "Transaction/month is required";
                }
            }
            if (requestedPurpose === "Change Amount Month") {
                if (!raiseRequestvalues?.amount_month) {
                    errors.amount_month = "Amount/month is required";
                }
            }
            if (requestedPurpose === "Change Maximum Transaction") {
                if (!raiseRequestvalues?.maximum_transaction) {
                    errors.maximum_transaction = "Maximum transaction is required";
                }
            }
        }
        if (requestedPage === "changeNomineeInfo") {
            if (requestedPurpose === "Change Nominee Name") {
                if (!raiseRequestvalues?.nominee_name) {
                    errors.nominee_name = "nominee name is required";
                }
            }
            if (requestedPurpose === "Change Father Name") {
                if (!raiseRequestvalues?.father_name) {
                    errors.father_name = "father name is required";
                }
            }
            if (requestedPurpose === "Change Mother Name") {
                if (!raiseRequestvalues?.mother_name) {
                    errors.mother_name = "mother name is required";
                }
            }
            if (requestedPurpose === "Change Nominee Dob") {
                if (!raiseRequestvalues?.nominee_dob) {
                    errors.nominee_dob = "Nominee DOB is required";
                }
            }
            if (requestedPurpose === "Change Nominee Relation") {
                if (!raiseRequestvalues?.nominee_relation) {
                    errors.nominee_relation = "Nominee Relation is required";
                }
            }
            if (requestedPurpose === "Change Nominee Percent") {
                if (!raiseRequestvalues?.nominee_percent) {
                    errors.nominee_percent = "Nominee Percent is required";
                }
            }
            if (requestedPurpose === "Change Document Type") {
                if (!raiseRequestvalues?.doc_type) {
                    errors.doc_type = "Document Type is required";
                }
            }
            if (requestedPurpose === "Change Document Number") {
                if (!raiseRequestvalues?.document_nmbr) {
                    errors.document_nmbr = "Document Number is required";
                }
            }
            if (requestedPurpose === "Change Nominee Image") {
                if (!raiseRequestvalues?.nominee_img) {
                    errors.nominee_img = "Nominee Image is required";
                }
            }
            if (requestedPurpose === "Change Document Front Image") {
                if (!raiseRequestvalues?.document_front_img) {
                    errors.document_front_img = "Document Front Image is required";
                }
            }
            if (requestedPurpose === "Change Document Back Image") {
                if (!raiseRequestvalues?.doc_back_img) {
                    errors.doc_back_img = "Document Back Image is required";
                }
            }
        }

        if (requestedPage === "changePersonalInfo") {
            if (requestedPurpose === "Change Personal Dob") {
                if (!raiseRequestvalues?.personal_dob) {
                    errors.personal_dob = "DOB is required";
                }
            }
            if (requestedPurpose === "Change Marital Status") {
                if (!raiseRequestvalues?.marital_status) {
                    errors.marital_status = "Marital Status is required";
                }
            }
            if (requestedPurpose === "Change Religion") {
                if (!raiseRequestvalues?.religion) {
                    errors.religion = "Religion is required";
                }
            }
            if (requestedPurpose === "Change Gender") {
                if (!raiseRequestvalues?.gender) {
                    errors.gender = "Gender is required";
                }
            }
            if (requestedPurpose === "Change Spouse Name") {
                if (!raiseRequestvalues?.spouse_name) {
                    errors.spouse_name = "Spouse Name is required";
                }
            }
            if (requestedPurpose === "Change Occupation") {
                if (!raiseRequestvalues?.occupation) {
                    errors.occupation = "Occupation is required";
                }
            }
            if (requestedPurpose === "Change Email") {
                if (!raiseRequestvalues?.email) {
                    errors.email = "Email is required";
                }
            }
        }

        if (requestedPage === "changeAccountDetails") {
            if (requestedPurpose === "Change Account Title") {
                if (!raiseRequestvalues?.account_title) {
                    errors.account_title = "Account Holder Name is required";
                }
            }
            if (requestedPurpose === "Change Sector Code") {
                if (!raiseRequestvalues?.sector_code) {
                    errors.sector_code = "Sector Code is required";
                }
            }
        }
        if (requestedPage === "changeSignatureCard" || requestedPage === "changeProfile") {
            errors = {}
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const closeModal = () => {
        setOpenAccountModal(false);
        setFormErrors({});
    };

    const closerequestModal = () => {
        setRaiseRequestModal(false);
        setFormErrors({});
        setRaiseRequestValues({
            bank_account_number: "",
            bank_requestPurpose: "",
            bank_accountHolderTitle: "",
            bank_requestType: "",
            bank_remarks: "",
            bank_previousValue: "",
            bank_changeValue: "",
            transaction_day: "",
            amount_day: "",
            transaction_month: "",
            amount_month: "",
            maximum_transaction: "",
            nominee_name: "",
            father_name: "",
            mother_name: "",
            nominee_dob: "",
            nominee_relation: "",
            nominee_percent: "",
            doc_type: "",
            document_nmbr: "",
            nominee_img: "",
            document_front_img: "",
            doc_back_img: "",
            account_title: "",
            personal_dob: "",
            marital_status: "",
            religion: "",
            gender: "",
            spouse_name: "",
            occupation: "",
            email: "",
            sector_code: "",
            nid_number: "",
            dob: "",
        })
    };
    // const steps = ["account details"];

    const setStatus = (status) => {
        const item = status?.split("-");
        const capitalizedParts = item?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1));
        return capitalizedParts?.join("-");
    };


    return (
        <>
            <div className={`flex dark:bg-dark-background updateIvrContainer`}>
                <SideMenu />
                <div className="flex-1 overflow-hidden">
                    <Header />
                    <Navbar></Navbar>
                    {dataLoading && <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>}
                    <div className="">
                        {/* <div className="flex justify-between"> */}
                        <div className="flex">
                        </div>
                        <div className="flex flex-row justify-between userHeading">
                            {/* <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 userManagementHeading">
                                Account Details
                            </h1> */}
                            <div className="flex">
                                {/* <button
                                    type="button"
                                    className="raiseNewBtn me-4 rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle commonBtn" onClick={openSearchAccount}>
                                    Search Account Number
                                </button> */}
                                {/* <button
                                    type="button"
                                    className="raiseNewBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle commonBtn" onClick={raiseRequestUpdate}>
                                    Raise Request +
                                </button> */}
                            </div>
                        </div>

                        <div className={`transactionFixedHeightContainer ${isMobileDevice ? 'p-4' : 'p-10'}`} style={requestSubmitLoader ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                            <Breadcrumb
                                // steps={steps}
                                activeStep={1}
                                showBackButton={true}
                                redirectUrl={"/ConventionalAgentBankingCard"}
                            />
                            <div className="rounded-lg bg-white border p-5 mt-3 userDetailsUpdatePagee">
                                <div className='ps-3 pe-3 pt-1'>
                                    <p className='font-small border-b-2 pb-5 font-semibold text-lg '>Raise Request</p>
                                </div>
                                <div className=''>
                                    <div className=' flex flex-col ps-3 pe-3 mt-4'>
                                        <div className="flex flex-wrap">
                                            <div className={`mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="requested_page" className='numberRequestModal mt-3'>Requested Page*</label>
                                                <select
                                                    id="requested_page"
                                                    name="requested_page"
                                                    value={requestedPage}
                                                    className={`py-2 rounded outline-none ps-2 border w-full`}
                                                    style={{ pointerEvents: 'none', appearance: 'none' }}
                                                    onChange={(e) => { }}
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="changeLimitUpdate">Change Limit Update</option>
                                                    <option value="changeSignatureCard">Change Signature Card</option>
                                                    <option value="changeProfile">Change Photograph</option>
                                                    <option value="changeNomineeInfo">Change Nominee Information</option>
                                                    <option value="changePersonalInfo">Change Personal Information</option>
                                                    <option value="changeAccountDetails">Change Account Details</option>
                                                </select>

                                            </div>
                                            <div className={`flex flex-col ${isMobileDevice ? 'w-full mb-2' : 'w-1/2  ps-2'}`}>
                                                <label htmlFor="bank_requestType" className="numberRequestModal">Request Type*</label>
                                                <select
                                                    id="requestType"
                                                    name="bank_requestType"
                                                    value={raiseRequestvalues?.bank_requestType}
                                                    onChange={(e) => handleRequestValues(e)}
                                                    className={`py-2 rounded outline-none ps-2 border w-full`}
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="add">Add</option>
                                                    <option value="change">Change</option>
                                                </select>
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_requestType ? 'red' : 'inherit' }}>{formErrors?.bank_requestType}</span>
                                            </div>
                                            {requestedPage === "changeLimitUpdate" && (
                                                <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                    <label htmlFor="requested_purpose" className='numberRequestModal'>Requested Purpose*</label>
                                                    <select
                                                        id="requested_purpose"
                                                        name="bank_requestPurpose"
                                                        value={raiseRequest?.bank_requestPurpose}
                                                        onChange={handleRequestValues}
                                                        className={`py-2 rounded outline-none ps-2 border`}
                                                    >
                                                        <option hidden value="">Select an Option</option>
                                                        <option value="Change Transaction Day">Change Transaction/Day</option>
                                                        <option value="Change Amount Day">Change Amount/Day</option>
                                                        <option value="Change Transaction Month">Change Transaction/Month</option>
                                                        <option value="Change Amount Month">Change Amount/Month</option>
                                                        <option value="Change Maximum Transaction">Change Maximum Transaction</option>
                                                    </select>
                                                </div>)}
                                            {requestedPage === "changePersonalInfo" && (
                                                <div className={`flex flex-col mb-2  ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>

                                                    <label htmlFor="requested_purpose" className='numberRequestModal'>Requested Purpose*</label>
                                                    <select
                                                        id="requested_purpose"
                                                        name="bank_requestPurpose"
                                                        value={raiseRequest?.bank_requestPurpose}
                                                        onChange={handleRequestValues}
                                                        className={`py-2 rounded outline-none ps-2 border`}
                                                    >
                                                        <option hidden value="">Select an Option</option>
                                                        <option value="Change Account Title">Change First Name</option>
                                                        <option value="Change Personal Dob">Change DOB</option>
                                                        <option value="Change Marital Status">Change Marital Status</option>
                                                        <option value="Change Religion">Change Religion</option>
                                                        <option value="Change Gender">Change Gender</option>
                                                        <option value="Change Spouse Name">Change Spouse Name</option>
                                                        <option value="Change Occupation">Change Occupation</option>
                                                        <option value="Change Email">Change Email</option>
                                                    </select>
                                                </div>)}

                                                {requestedPage === "changeSignatureCard" && (
                                                <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                    <label htmlFor="bank_requestPurpose" className="numberRequestModal">Request Purpose*</label>
                                                    <input
                                                        type="text"
                                                        name="bank_requestPurpose"
                                                        value='SignatureCard'
                                                        placeholder="Enter Request Purpose"
                                                        disabled
                                                        className={`py-2 rounded outline-none ps-2 border`}
                                                        onChange={handleRequestValues}
                                                    />
                                                    <span className='numberRequestModal' style={{ color: formErrors?.bank_requestPurpose ? 'red' : 'inherit' }}>{formErrors?.bank_requestPurpose}</span>
                                                </div>)}

                                                {requestedPage === "changeProfile" && (
                                            
                                                <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                    <label htmlFor="bank_requestPurpose" className="numberRequestModal">Request Purpose*</label>
                                                    <input
                                                        type="text"
                                                        name="bank_requestPurpose"
                                                        value="ChangePhoto"
                                                        disabled
                                                        placeholder="Enter Request Purpose"
                                                        className={`py-2 rounded outline-none ps-2 border`}
                                                        onChange={handleRequestValues}
                                                    />
                                                    <span className='numberRequestModal' style={{ color: formErrors?.bank_requestPurpose ? 'red' : 'inherit' }}>{formErrors?.bank_requestPurpose}</span>
                                                </div>)}

                                                {requestedPage === "changeAccountDetails" && (
                                                <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                    <label htmlFor="requested_purpose" className='numberRequestModal'>Requested Purpose*</label>
                                                    <select
                                                        id="requested_purpose"
                                                        name="bank_requestPurpose"
                                                        value={raiseRequest?.bank_requestPurpose}
                                                        onChange={handleRequestValues}
                                                        className={`py-2 rounded outline-none ps-2 border`}
                                                    >
                                                    <option hidden value="">Select an Option</option>
                                                    {/* <option value="changeAccountTitle">Change Account Holder Name</option> */}
                                                    <option value="Change Sector Code">Change Sector Code</option>
                                                    </select>
                                                </div>)}
                                            <div className={`flex flex-col ${isMobileDevice ? 'w-full mb-2' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_account_number" className="numberRequestModal">Account Number*</label>
                                                <div className="flex">
                                                    <input
                                                        type="text"
                                                        name="bank_account_number"
                                                        value={raiseRequestvalues?.bank_account_number}
                                                        placeholder="Enter Account Number"
                                                        className={`py-2 rounded outline-none ps-2 border w-11/12`}
                                                        onChange={handleRequestValues}
                                                    />
                                                    <button disabled={!raiseRequestvalues?.bank_account_number} type="submit" className=' relative border bg-white-500 rounded-md w-12 p-1 text-white font-medium py-2 h-11 ms-3' onClick={getAccountDetails}>
                                                        <img src="assets/images/Vector.svg" className="searchLogo ps-3" /></button>
                                                </div>
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_account_number ? 'red' : 'inherit' }}>{formErrors?.bank_account_number}</span>
                                            </div>
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                <label htmlFor="bank_account_title" className="numberRequestModal">Account Holder Name*</label>
                                                <input
                                                    type="text"
                                                    name="bank_accountHolderTitle"
                                                    value={raiseRequestvalues?.bank_accountHolderTitle}
                                                    placeholder="Enter Account Holder Name"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    disabled
                                                    onChange={handleRequestValues}
                                                />
                                                {/* <span className='numberRequestModal' style={{ color: formErrors?.bank_account_number ? 'red' : 'inherit' }}>{formErrors?.bank_account_number}</span> */}
                                            </div>
                                            {requestedPage === "changeSignatureCard" || requestedPage === "changeProfile" ? "" : <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  ps-2'}`}>
                                                <label htmlFor="bank_previousValue" className="numberRequestModal">Previous Value*</label>
                                                <input
                                                    type="text"
                                                    name="bank_previousValue"
                                                    value={raiseRequestvalues?.bank_previousValue}
                                                    placeholder="Enter Previous Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                    disabled
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_previousValue ? 'red' : 'inherit' }}>{formErrors?.bank_previousValue}</span>
                                            </div>}
                                            {requestedPage === "changeSignatureCard" || requestedPage === "changeProfile" || raiseRequestvalues?.bank_requestPurpose === "Change Personal Dob"  ? "" : <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Change Value*</label>
                                                <input
                                                    type="text"
                                                    name="bank_changeValue"
                                                    value={raiseRequestvalues?.bank_changeValue}
                                                    placeholder="Enter Change Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                    disabled={requestedPage === "changeSignatureCard" || requestedPage === "changeProfile"}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.request_update_value ? 'red' : 'inherit' }}>{formErrors?.request_update_value}</span>
                                            </div>}
                                            {raiseRequestvalues?.bank_requestPurpose === "Change Personal Dob" ? <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Change Value*</label>
                                                <input
                                                    type="date"
                                                    name="bank_changeValue"
                                                    value={raiseRequestvalues?.bank_changeValue}
                                                    placeholder="Enter Change Value"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.request_update_value ? 'red' : 'inherit' }}>{formErrors?.request_update_value}</span>
                                            </div>:""}
                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2 ps-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">NID Number*</label>
                                                <input
                                                    type="text"
                                                    name="nid_number"
                                                    value={raiseRequestvalues?.nid_number}
                                                    placeholder="Enter NID Number"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.nid_number ? 'red' : 'inherit' }}>{formErrors?.nid_number}</span>
                                            </div>

                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  pe-2'}`}>
                                                <label htmlFor="bank_changeValue" className="numberRequestModal">Dob*</label>
                                                <input
                                                    type="date"
                                                    name="dob"
                                                    value={raiseRequestvalues?.dob}
                                                    placeholder="Enter dob"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                    disabled
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.dob ? 'red' : 'inherit' }}>{formErrors?.dob}</span>
                                            </div>

                                            <div className={`flex flex-col mb-2 ${isMobileDevice ? 'w-full' : 'w-1/2  ps-2'}`}>
                                                <label htmlFor="bank_remarks" className="numberRequestModal">Remarks*</label>
                                                <input
                                                    type="text"
                                                    name="bank_remarks"
                                                    value={raiseRequestvalues?.bank_remarks}
                                                    placeholder="Enter Remarks"
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                    onChange={handleRequestValues}
                                                />
                                                <span className='numberRequestModal' style={{ color: formErrors?.bank_remarks ? 'red' : 'inherit' }}>{formErrors?.bank_remarks}</span>
                                            </div>
                                        </div>


                                        {requestedPage === "changeLimitUpdate" && (
                                            <div className=' flex flex-col mt-4'>
                                                <UpdateLimitModal></UpdateLimitModal>
                                                {requestedPurpose === "Change Transaction Day" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="transaction_day" className='numberRequestModal'>Transaction/Day*</label>
                                                        <input
                                                            type="text"
                                                            name="transaction_day"
                                                            value={raiseRequestvalues?.transaction_day}
                                                            placeholder="Enter transaction/day"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.transaction_day ? 'red' : 'inherit' }}>{formErrors?.transaction_day}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Amount Day" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="amount_day" className='numberRequestModal'>Amount/Day*</label>
                                                        <input
                                                            type="text"
                                                            name="amount_day"
                                                            value={raiseRequestvalues?.amount_day}
                                                            placeholder="Enter amount/day"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.amount_day ? 'red' : 'inherit' }}>{formErrors?.amount_day}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Transaction Month" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="transaction_month" className='numberRequestModal'>Transaction/Month*</label>
                                                        <input
                                                            type="text"
                                                            name="transaction_month"
                                                            value={raiseRequestvalues?.transaction_month}
                                                            placeholder="Enter transaction/month"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.transaction_month ? 'red' : 'inherit' }}>{formErrors?.transaction_month}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Amount Month" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="amount_month" className='numberRequestModal'>Amount/Month*</label>
                                                        <input
                                                            type="text"
                                                            name="amount_month"
                                                            value={raiseRequestvalues?.amount_month}
                                                            placeholder="Enter amount/month"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.amount_month ? 'red' : 'inherit' }}>{formErrors?.amount_month}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Maximum Transaction" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="maximum_transaction" className='numberRequestModal'>Maximum Transaction Amount*</label>
                                                        <input
                                                            type="text"
                                                            name="maximum_transaction"
                                                            value={raiseRequestvalues?.maximum_transaction}
                                                            placeholder="Enter maximum transaction amount"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.maximum_transaction ? 'red' : 'inherit' }}>{formErrors?.maximum_transaction}</span>
                                                    </div>)}
                                            </div>

                                        )}
                                        {requestedPage === "changeSignatureCard" && (
                                            <div className='mt-3'>

                                                <UpdateSignatureCardModal setSignatureData={setSignatureData} signatureData={signatureData} />
                                            </div>
                                        )}
                                        {requestedPage === "changeProfile" && (
                                            <div className='mt-3'>
                                                <ChangePhotographModal setFileData={setFileData} fileData={fileData} />
                                            </div>
                                        )}
                                        {requestedPage === "changeNomineeInfo" && (
                                            <div className=' flex flex-col mt-4'>
                                                <label htmlFor="requested_purpose" className='numberRequestModal'>Requested Purpose*</label>
                                                <select
                                                    id="requested_purpose"
                                                    name="requested_purpose"
                                                    value={raiseRequestvalues?.requested_purpose}
                                                    onChange={(e) => setRequestedPurpose(e.target.value)}
                                                    className={`py-2 rounded outline-none ps-2 border`}
                                                >
                                                    <option hidden value="">Select an Option</option>
                                                    <option value="Change Nominee Name">Change Nominee Name</option>
                                                    <option value="Change Father Name">Change Father Name</option>
                                                    <option value="Change Mother Name">Change Mother Name</option>
                                                    <option value="Change Nominee Dob">Change Nominee DOB</option>
                                                    <option value="Change Nominee Relation">Change Nominee Relation</option>
                                                    <option value="Change Nominee Percent">Change Nominee Percent</option>
                                                    <option value="Change Document Type">Change Document Type</option>
                                                    <option value="Change Document Number">Change Document Number</option>
                                                    <option value="Change Nominee Image">Change Nominee Image</option>
                                                    <option value="Change Document Front Image">Change Document Front Image</option>
                                                    <option value="Change Document Back Image">Change Document Back Image</option>
                                                </select>
                                                <ChangeNomineeInfoModal></ChangeNomineeInfoModal>
                                                {requestedPurpose === "Change Nominee Name" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="nominee_name" className='numberRequestModal'>Nominee Name*</label>
                                                        <input
                                                            type="text"
                                                            name="nominee_name"
                                                            placeholder="Enter Nominee Name"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.nominee_name ? 'red' : 'inherit' }}>{formErrors?.nominee_name}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Father Name" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="father_name" className='numberRequestModal'>Father Name*</label>
                                                        <input
                                                            type="text"
                                                            name="father_name"
                                                            placeholder="Enter Father Name"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.father_name ? 'red' : 'inherit' }}>{formErrors?.father_name}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Mother Name" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="mother_name" className='numberRequestModal'>Mother Name*</label>
                                                        <input
                                                            type="text"
                                                            name="mother_name"
                                                            placeholder="Enter Mother Name"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.mother_name ? 'red' : 'inherit' }}>{formErrors?.mother_name}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Nominee Dob" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="nominee_dob" className='numberRequestModal'>DOB*</label>
                                                        <input
                                                            type="date"
                                                            name="nominee_dob"
                                                            placeholder="Enter DOB"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.nominee_dob ? 'red' : 'inherit' }}>{formErrors?.nominee_dob}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Nominee Relation" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="nominee_relation" className='numberRequestModal'>Relation*</label>
                                                        <input
                                                            type="text"
                                                            name="nominee_relation"
                                                            placeholder="Enter Relation"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.nominee_relation ? 'red' : 'inherit' }}>{formErrors?.nominee_relation}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Nominee Percent" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="nominee_percent" className='numberRequestModal'>Nominee Percent*</label>
                                                        <input
                                                            type="text"
                                                            name="nominee_percent"
                                                            placeholder="Enter Nominee Percent"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.nominee_percent ? 'red' : 'inherit' }}>{formErrors?.nominee_percent}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Document Type" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="doc_type" className='numberRequestModal'>Document Type*</label>
                                                        <input
                                                            type="text"
                                                            name="doc_type"
                                                            placeholder="Enter Document Type"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.doc_type ? 'red' : 'inherit' }}>{formErrors?.doc_type}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Document Number" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="document_nmbr" className='numberRequestModal'>Document No*</label>
                                                        <input
                                                            type="text"
                                                            name="document_nmbr"
                                                            placeholder="Enter Document number"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.document_nmbr ? 'red' : 'inherit' }}>{formErrors?.document_nmbr}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Nominee Image" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="nominee_img" className='numberRequestModal'>Nominee Image*</label>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name="nominee_img"
                                                            // placeholder="Enter Document Type"
                                                            className={`mt-1 rounded outline-none ps-2`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.nominee_img ? 'red' : 'inherit' }}>{formErrors?.nominee_img}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Document Front Image" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="document_front_img" className='numberRequestModal'>Document Front Image*</label>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name="document_front_img"
                                                            // placeholder="Enter Document number"
                                                            className={`mt-1 rounded outline-none ps-2`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.document_front_img ? 'red' : 'inherit' }}>{formErrors?.document_front_img}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Document Back Image" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="doc_back_img" className='numberRequestModal'>Documnet Back Image*</label>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name="doc_back_img"
                                                            // placeholder="Enter Document Type"
                                                            className={`mt-1 rounded outline-none ps-2`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.doc_back_img ? 'red' : 'inherit' }}>{formErrors?.doc_back_img}</span>
                                                    </div>)}
                                            </div>
                                        )}
                                        {requestedPage === "changePersonalInfo" && (
                                            <div className=' flex flex-col mt-4'>
                                                <ChangePersonalInfoModal></ChangePersonalInfoModal>

                                                {requestedPurpose === "Change Personal Dob" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="personal_dob" className='numberRequestModal'>DOB*</label>
                                                        <input
                                                            type="date"
                                                            name="personal_dob"
                                                            placeholder="Enter DOB"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.personal_dob ? 'red' : 'inherit' }}>{formErrors?.personal_dob}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Marital Status" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="marital_status" className='numberRequestModal'>Marital Status*</label>
                                                        <input
                                                            type="text"
                                                            name="marital_status"
                                                            placeholder="Enter Marital Status"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.marital_status ? 'red' : 'inherit' }}>{formErrors?.marital_status}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Religion" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="religion" className='numberRequestModal'>Religion*</label>
                                                        <input
                                                            type="text"
                                                            name="religion"
                                                            placeholder="Enter Religion"
                                                            className={`rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.religion ? 'red' : 'inherit' }}>{formErrors?.religion}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Gender" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="gender" className='numberRequestModal'>Gender*</label>
                                                        <input
                                                            type="text"
                                                            name="gender"
                                                            placeholder="Enter Gender"
                                                            className={`py-1 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.gender ? 'red' : 'inherit' }}>{formErrors?.gender}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Spouse Name" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="spouse_name" className='numberRequestModal'>Spouse Name*</label>
                                                        <input
                                                            type="text"
                                                            name="spouse_name"
                                                            placeholder="Enter Spouse Name"
                                                            className={`py-1 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.spouse_name ? 'red' : 'inherit' }}>{formErrors?.spouse_name}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Occupation" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="occupation" className='numberRequestModal'>Occupation*</label>
                                                        <input
                                                            type="text"
                                                            name="occupation"
                                                            placeholder="Enter Occupation"
                                                            className={`py-1 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.occupation ? 'red' : 'inherit' }}>{formErrors?.occupation}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Email" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="email" className='numberRequestModal'>Email*</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            className={`py-1 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.email ? 'red' : 'inherit' }}>{formErrors?.email}</span>
                                                    </div>)}
                                            </div>
                                        )}


                                        {requestedPage === "changeAccountDetails" && (
                                            <div className=' flex flex-col mt-4'>
                                                <UpdateLimitModal></UpdateLimitModal>
                                                {requestedPurpose === "Change Account Title" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="account_title" className='numberRequestModal'>Account Holder Name*</label>
                                                        <input
                                                            type="text"
                                                            name="account_title"
                                                            placeholder="Enter Account Holder Name"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.account_title ? 'red' : 'inherit' }}>{formErrors?.account_title}</span>
                                                    </div>)}

                                                {requestedPurpose === "Change Sector Code" && (
                                                    <div className=' flex flex-col mt-4'>
                                                        <label htmlFor="sector_code" className='numberRequestModal'>Sector Code*</label>
                                                        <input
                                                            type="text"
                                                            name="sector_code"
                                                            placeholder="Enter Sector Code"
                                                            className={`py-2 rounded outline-none ps-2 border`}
                                                            onChange={handleRequestValues}
                                                        />
                                                        <span className='numberRequestModal' style={{ color: formErrors?.sector_code ? 'red' : 'inherit' }}>{formErrors?.sector_code}</span>
                                                    </div>)}
                                            </div>
                                        )}
                                        {/* <span className='numberRequestModal' style={{ color: formErrors?.bankAccountNumber ? 'red' : 'inherit' }}>{formErrors?.bankAccountNumber}</span> */}
                                    </div>

                                    <div className='flex justify-end mt-5 mb-3'>
                                        <div className='flex justify-center align-middle me-2'>
                                            <button type="button" className=' border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary' onClick={closerequestModal}>Cancel</button>
                                        </div>
                                        <div className='flex justify-center align-middle me-3'>
                                            <button type="submit" className=' relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium commonBtn py-2 custom_btn_primary' onClick={handleRaise}>
                                                Raise</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}

                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};
export default AccountUpdateDetails;
