import axios from "./instance";
import * as auth from '../auth';

class API {
  // eslint-disable-next-line lines-around-comment
  /**
   * auth2 login api
   * @param {string} url String
   * @param {object} paiyload Object
   * @param {object} action Object e.g {type: 'AUTH', dispatch: function(){} }
   * @returns {Promise<void>} void
   */


  static apiPost = async (key, args, query) => {
    if (query) {
      const config = {
        params: query
      };
      return axios.post(key, args, config);
    }
    return axios.post(key, args);
  };

  static apiGet = async (key, args,params) => {
    if(params){
      const config = {
        params: params 
      };
      return axios.get(key, config);
    }
    if (typeof args === 'string') {
      return axios.get(key + args);
    }
    return axios.get(key);
  };
  
  static apiPut = async (key, args, query) => {
    if (query) {
      const config = {
        params: query
      };
      return axios.put(key, args, config);
    }
    else if (typeof args === 'string') {
      return axios.put(key + args);
    }
    return axios.put(key,args);
  };

  static apiDelete = async (key, args, params) => {
    if (params) {
      const config = {
        params: params
      };
      return axios.delete(key, config);
    }
    if (typeof args === 'string') {
      return axios.delete(key + args);
    }
    return axios.delete(key);
  };

}

export default API;

// # interceptors
axios.interceptors.request.use(
  (configs) => {
    configs.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use( 
  (response) => {
    // const loading = false;
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // eslint-disable-next-line no-console
      auth.logout();
    }
    return Promise.reject(error);
  }
);

