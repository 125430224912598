import Navbar from "../../common/Navbar"
import styles from "../../styles/informationUpdate.module.scss";
import Header from "../../component/Header";
import SideMenu from "../../component/SideMenu";
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { toast,ToastContainer } from "react-toastify";
import API from "../../helpers/api";
import Breadcrumb from "../../component/Breadcrumb";

export default function SearchAccount() {
    const [accountNumber, setAccountNumber] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [dataLoading, setDataLoading] = useState(false)
    const navigate = useNavigate();

    const getAccountDetails = async () => {
        const account_no = accountNumber;
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/${account_no}`);
            if (data?.success) {
                toast.success(data?.message);
                if(account_no){
                    navigate(`/InformationUpdate?account_no=${account_no}`)
                }
                setDataLoading(false)
            } else {
                
                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };

    const handleSearchAccount = () => {
        
        let errors = {};
        let flag = true;
        if (!accountNumber) {
            errors.accountNumber = "Account Number is required";
            flag = false;
        }
        if (flag) {
            setDataLoading(true);
            getAccountDetails();
        }
        setFormErrors(errors);
    }
   

    return (
        <>
            <div className={`flex dark:bg-dark-background dashboard-container dashboard-main`}>
                <SideMenu />
                <div className="h-screen flex-1 overflow-hidden">
                    <Header />
                    {dataLoading && <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>}
                    <Navbar />
                    <div className="z-0 p-10" style={{ opacity: dataLoading ? 0.1 : 1 }}>
                        <Breadcrumb
                            showBackButton={true}
                            redirectUrl={"/ConventionalAgentBankingCard"}
                        />
                        <div className={`p-3 mt-3 ${styles.searchCard}`}>
                            <div className="flex justify-between">
                                <div>
                                    <h1 className="text-xl font-semibold">Parameter</h1>
                                </div>
                                {/* <div className='flex justify-center align-middle me-3'>
                                    <button type="submit" className=' relative border rounded-md bg-blue-600 w-20 p-1 text-white font-medium commonBtn py-2'>
                                        Clear</button>
                                </div> */}
                            </div>
                            <div className='flex p-10 justify-evenly ps-3 pe-3 mt-4'>
                                <div className="">
                                    <label htmlFor="account_number" className='numberRequestModal'>* Account Number : </label>
                                    <input
                                        type="text"
                                        id="account_number"
                                        name="account_number"
                                        value={accountNumber}
                                        onChange={(e) => {setAccountNumber(e?.target?.value);
                                        setFormErrors({})}}
                                        placeholder="Enter Account Number"
                                        className={`ms-6 py-2 rounded outline-none ps-2 border w-80`}
                                    />
                                    <div className="text-end">
                                        <span className='numberRequestModal' style={{ color: formErrors?.accountNumber ? 'red' : 'inherit' }}>{formErrors?.accountNumber}</span>
                                    </div>
                                </div>
                                <div className='flex justify-end mb-3'>
                                    <div className='flex justify-center align-middle me-3'>
                                        <button type="submit" className=' relative border rounded-md bg-blue-600 w-32 p-1 text-white font-medium commonBtn custom_btn_primary py-2 h-11' onClick={handleSearchAccount}>
                                            Show Details</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    )
}