import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { openingModal, closingModal } from '../../redux/actions';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Icon from "../../component/core-component/Icon";
import Breadcrumb from "../../component/Breadcrumb";
import CONST from "../../common/const";
import Functions from "../../common/functions";
import { LinkCta, PrimaryCta } from "../../component/core-component";
import { ColorRing } from "react-loader-spinner";
import { WithAuth } from "../../common/WithAuth";
import Pagination from "../../component/Pagination";
import WorkerQueBoard from "./WorkerQueBoard";
import API from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/accountVerification.scss";
import styles from "../../styles/common.module.scss";
import { getCurrentProcessPerm } from "../../helpers/auth";
import { DatePicker } from "antd";
import debounce from "lodash/debounce";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import { useNavigate } from "react-router";
import { useNavigate } from "react-router-dom";
import CustomDateRangeInput from "../../component/DateRange";
import { formatDateToIso, handleErrorMessage } from "../../common/commonFunction";
import moment from "moment";
import AccountDetail from "./AccountDetail";
import { BarChart } from '@mui/x-charts/BarChart';
import fullScreenIcon from '../../assets/images/expand.svg'

const AccountVerification = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => state.ModalReducer.isModalOpen);
  const initialFilterData = {
    startDate: "",
    endDate: "",
  };
  const initialStatsData = {
    approved: "loading...",
    processed: "loading...",
    rejected: "loading...",
    failed: "loading...",
  };
  const noDataStatus = {
    approved: "0",
    processed: "0",
    rejected: "0",
    failed: "0",
  };

  const initialDateErrors = {startDateError: '', endDateError: ''};
  const steps = ["Agent Banking Department", "Regular Savings Account"];
  // const [isLoading, setIsLoading] = useState(false);
  const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
  var navigate = useNavigate();
  const [searchId, setSearchId] = useState("");
  const [accOpen, setAccOpen] = useState([]);
  const [scOpen, setScOpen] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [filteredData, setFilteredData] = useState(accOpen);
  const [accountTableData, setAccountTableData] = useState([]);
  const [workerPorcess, setWorkerProcess] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [tabKey, setTabKey] = useState("first");
  const [tab, setTab] = useState("S02");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDate, setFilterDate] = useState({ startDate: "", endDate: "" });
  const [applyFilter, setApplyFilter] = useState(1);
  const [recordsPerPage] = useState(6);
  const [nPages, setNpages] = useState(1);
  const [accPages, setAccpages] = useState(1);
  const [viewProcess, setViewProcess] = useState(false);
  const [filterData, setFilterData] = useState({ ...initialFilterData });
  const [accDetails, setAccDetails] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [totalDetailsCount, setTotalDetailsCount] = useState(1);
  const [reportStatusCards, setReportStatusCards] = useState({
    ...initialStatsData,
  });
  const [responseScreenWidth, setResponseScreenWidth] = useState(window.innerWidth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showDataLoading, setShowDataLoading] = useState(true);
  const [showReportsDownloadModal, setShowReportsDownloadModal] =
    useState(false);
  const [querry, setQuerry] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [datesData, setDateData] = useState([]);
  const [selectedSortOptions, setSelectedSortOptions] = useState();
  const [searchFilterData, setSearchFilterData] = useState({});
  const [openChart,setOpenChart] = useState(false);
  const [approvedValues, setApprovedValues] = useState([]);
  const [chartsDays, setChartsDays] = useState([
    {dayLabel: 'Monday', dayKey: 'Mon', date: ''},
    {dayLabel: 'Tuesday', dayKey: 'Tue', date: ''},
    {dayLabel: 'Wednesday', dayKey: 'Wed', date: ''},
    {dayLabel: 'Thursday', dayKey: 'Thu', date: ''},
    {dayLabel: 'Friday', dayKey: 'Fri', date: ''},
    {dayLabel: 'Saturday', dayKey: 'Sat', date: ''},
    {dayLabel: 'Sunday', dayKey: 'Sun', date: ''},
  ]);
  const [failedValues, setFailedValues] = useState([]);
  const [rejectedValues, setRejectedValues] = useState([]);
  const [processValues, setProcessValues] = useState([]);
  const [accountStatusValue, setAccountStatusValue] = useState(null);
  const [oldPaylod, setOldPaylod] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);
  const [chartWidth, setChartWidth] = useState(parseInt(window.innerWidth)/3.3);
  const sortByDropdownRef = useRef(null);
  const [dateErrors, setDateErrors] = useState(initialDateErrors);

  const handleDetail = (item) => {
    setQuerry({ id: item?.id, task_type: 1, startDate: item?.task_data?.process_status?.account_listed_at });
    // navigate(`/AccountDetail?id=${item?.id}&task_type=${task_type}`);
  };


  const cardsLabel = {
    approved: "Approved",
    processed: "Processed",
    rejected: "Rejected",
    failed: "Failed",
  };
  const cardsColor = {
    approved: "#517DD2",
    processed: "#196857",
    rejected: "#f8aa50",
    failed: "#FF0000"
  };

  useEffect(() => {
      const intervalId = setInterval(() => {
        if (searchFilterData.search == '' || !searchFilterData?.search) {
          getTaskList(currentPage, recordsPerPage, filterDate, '', selectedSortOptions, accountStatusValue);
          getReportStatus(tab, filterDate);
        }
      }, 5000);
      return () => clearInterval(intervalId);
  }, [currentPage, tab, applyFilter, selectedSortOptions, accountStatusValue]);

  async function getTaskList(page, pageSize, dates, account_number, option, accountStatusValue) {
    setCurrentPage(page);
    setShowDataLoading(true);
    var params = {
      skip: page,
      limit: pageSize,
      account_type: "S02",
      status: accountStatusValue,
      start_date: dates ? dates.startDate : filterDate?.startDate,
      end_date: dates ? dates.endDate : filterDate?.endDate,
      account_number: account_number ? account_number : '',
    };
    if (option) {
      params.sortBy = option === "low to high" ? "ASC" : option === "high to low" ? "DESC" : ""
    }
    setOldPaylod(params)
    Functions.handleAPI(
      CONST.GET_TASK_LIST,
      CONST.GET_METHOD,
      params,
      getTaskListSuccess,
      getTaskListFail,
      progressCallBack,
      token
    );
  }
  function getTaskListSuccess(response) {
    setShowDataLoading(false);
    setAccountData(response?.data);
    setFilteredData(response?.data);
    setNpages(response?.pageInfo?.totalPageCount || response?.data?.length);
  }
  function getTaskListFail(error) {
    setAccountData([]);
    setShowDataLoading(false);
    setNpages(0);
    // clearIntervalAndCallFunction()
    console.log("error", error);
  }

  function progressCallBack(status) {
    // setIsLoading(status);
  }

  function openChartModal(){
    setOpenChart(true);
  }

  function closeChartModal(){
    setOpenChart(false);
  }

  useEffect(() => {
    getDetailsInfo();
  }, [currentDetailsPage]);

  useEffect(()=>{
    const handleResize = () => {
      let viewportHeight = window.innerWidth;
      let chartNewWidth = parseInt(viewportHeight)/3.3
      setChartWidth(chartNewWidth);
      setResponseScreenWidth(viewportHeight)
    }
    window.addEventListener('resize', handleResize);
    document.addEventListener('click',(event)=>{
      if(sortByDropdownRef.current && !sortByDropdownRef.current.contains(event.target)) {
        setShowDropdown(prev=>(prev==true)?false:prev);
      }
    })
  },[])

  function handleCheckStatus(status, value) {
    if (status === "success" && value === "accountListStatus") {
      return (
        <div className="rRight">
          <img src="assets/images/rright.svg" alt="right" />
        </div>
      )
    } else if (status === "success") {
      return (
        <div className="rRight">
          <img src="assets/images/rright.svg" alt="success" />
        </div>
      )
    } else if (status === "failed") {
      return (
        <div className="xMark">
          <img src="assets/images/xmark.svg" alt="cross" />
        </div>
      )
    } else if (status === "mismatched") {
      return (
        <div className="verificationNotStarted">
          <img src="assets/images/esclation.svg" alt="mismatch" />
        </div>
      )
    } else if (status === "pending") {
      return (
        <div className="esclation">
          <img src="assets/images/esclation.svg" alt="pending" />
        </div>
      )
    } else {
      return (
        <div className="verificationNotStarted"></div>
      )
    }
  }

  function handleDataScrapStatus(status, date, value){
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(status === "success" && value === "accountListStatus"){
      return (
        <div className="flex">
          <div className="rRight"><img src="assets/images/rright.svg" alt="success" /></div>
          <div className="ms-3">
            <p>Data listed successfully</p>
            <span>{date === "null" ? "--" : moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "success"){
      return (
        <div className="flex">
          <div className="rRight"><img src="assets/images/rright.svg"></img></div>
          <div className="ms-3">
            <p>Data has been Scraped successfully</p>
            <span>{date === "null" ? "--" : moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "failed"){
      return (
        <div className="flex">
          <div className="xMark"><img src="assets/images/xmark.svg"></img></div>
          <div className="ms-3">
            <p>Data Scrapping failed</p>
            <span>{date === "null" ? "--":moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "pending"){
      return (
        <div className="flex">
          <div className="esclation"><img src="assets/images/esclation.svg"></img></div>
          <div className="ms-3">
            <p>Data Scrapping Pending</p>
            <span>{date === "null" ? "--":moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else{
      return (<div className="flex">
        <div className="verificationNotStarted"></div>
        <div className="ms-3"><p>-</p></div>
        </div>)
     }
  }

  function handleNidVerficationStatus(status, date){
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(status === "success"){
      return (
        <div className="flex">
          <div className="rRight"><img src="assets/images/rright.svg"></img></div>
          <div className="ms-3">
            <p>Nid verification successfully completed</p>
            <span>{date === "null" ? "--" : moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "failed"){
      return(
        <div className="flex">
          <div className="xMark"><img src="assets/images/xmark.svg"></img></div>
          <div className="ms-3">
            <p>Nid verification is failed</p>
            <span>{date === "null" ? "--" : moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "mismatched"){
      return(
        <div className="flex">
          <div className="verificationNotStarted"><img src="assets/images/esclation.svg"></img></div>
          <div className="ms-3">
            <p>Verification Data mismatched</p>
            <span>{date === "null" ? "--": moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "pending"){
        return (
          <div className="flex">
            <div className="esclation"><img src="assets/images/esclation.svg"></img></div>
            <div className="ms-3">
              <p>Verification not started</p>
              <span>{date === "null" ? "--": moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
            </div>
          </div>
        )
     }else{
      return (
        <div className="flex">
          <div className="verificationNotStarted"></div>
          <div className="ms-3"><p>-</p></div>
        </div>
      )
     }
     
  }

  function handleAccountUpdateStatus(status, date){
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(status === "success"){
      return (
        <div className="flex">
          <div className="rRight"><img src="assets/images/rright.svg"></img></div>
          <div className="ms-3">
            <p>Account Updated successfully</p>
            <span>{date === "null" ? "--": moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "failed"){
      return (
        <div className="flex">
          <div className="xMark"><img src="assets/images/xmark.svg"></img></div>
          <div className="ms-3">
            <p>Account Updation failed</p>
            <span>{date === "null" ? "--" :moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else if(status === "pending"){
      return (
        <div className="flex">
          <div className="esclation"><img src="assets/images/esclation.svg"></img></div>
          <div className="ms-3">
            <p>Account Updation is pending</p>
            <span>{date === "null" ? "--" :moment.utc(date).tz(clientTimeZone).format('DD/MM/YYYY, hh:mm A')}</span>
          </div>
        </div>
      )
     }else{
      return (
        <div className="flex">
          <div className="verificationNotStarted"></div>
          <div className="ms-3"><p>-</p></div>
        </div>
      )
     }
  }

  function tableRow(accountData) {
    return accountData?.map((item, index) => {
      return (
        <tr key={item.id}>
          <td className="py-2 px-4 border-b">
            {/* {item?.relative_task_id || item?.task_data?.task_id} */}
            {item?.task_data?.account_number}
          </td>
          <td className="py-2 px-4 border-b">
            {item?.task_data?.name ? item?.task_data?.name : "-"}
          </td>
          <td className="py-2 px-4 border-b">
            <div className="flex">
              <Popup
                trigger={
                <div className="flex">
                  <div>
                    {handleCheckStatus(item?.task_data?.process_status?.account_listed_status, "accountListStatus")}
                  </div>
                  <div className="ms-4">
                    {handleCheckStatus(item?.task_data?.process_status?.account_data_scrap_status, "")}
                  </div>
                  <div className="ms-4">
                    { item?.task_data?.operators?.[0]?.nid_verification_status === "success" ? handleCheckStatus(item?.task_data?.operators?.[0]?.nid_verification_status, "") : handleCheckStatus(item?.task_data?.process_status?.nid_verification_status, "")}
                  </div>
                  <div className="ms-4">
                    {handleCheckStatus(item?.task_data?.process_status?.account_update_status, "")}
                  </div>
                </div>
              }
                position={`right center`}
                on="hover"
                // mouseEnterDelay={200}
                // mouseLeaveDelay={300}
                contentStyle={{ padding: '20px'}}
                keepTooltipInside={true}
              >
                <div>
                  <div className="ms-3">
                    {handleDataScrapStatus(item?.task_data?.process_status?.account_listed_status, item?.task_data?.process_status?.account_listed_at, "accountListStatus")}
                  </div>

                  <div className="mt-3 ms-3">
                    {handleDataScrapStatus(item?.task_data?.process_status?.account_data_scrap_status, item?.task_data?.process_status?.account_data_scrapped_at, "")}
                  </div>

                  <div className="mt-3 ms-3">
                    {item?.task_data?.operators?.[0]?.nid_verification_status === "success" ? handleNidVerficationStatus(item?.task_data?.operators?.[0]?.nid_verification_status, item?.task_data?.operators?.[0]?.nid_verified_at, "") : handleNidVerficationStatus(item?.task_data?.process_status?.nid_verification_status, item?.task_data?.process_status?.nid_verified_at, "")}
                  </div>
                  
                  <div className="flex mt-3 ms-3">
                    {handleAccountUpdateStatus(item?.task_data?.process_status?.account_update_status, item?.task_data?.process_status?.account_updated_at, "")}
                  </div>
                </div>
              </Popup>
            </div>
          </td>
          <td className="py-2 px-4 border-b">
            {item?.created_at
                ? new Date(item.created_at).toLocaleString("en-GB")
                : "-"}
          </td>
          <td className="py-2 px-4 border-b">
            {item?.task_status}
          </td>
          {/* <td className="py-2 px-4 border-b ">{item?.task_result}</td> */}

          {/* <td className="py-2 px-4 border-b">{item?.task_result} ({item?.task_status})</td> */}
          <td className="border-b text-center">
            {item?.task_result === "Failed" && (
              <span className={`px-2 py-1 rounded-full tabletRejected`}>
                <span className="red-dot" />
                {item?.task_result}
              </span>
            )}
            {(item?.task_result === "Process") && (
              <span className={`px-2 py-1 rounded-full tabletPending`}>
                <span className="yellow-dot" />
                Processing
              </span>
            )}
            {item?.task_result === "Processed" && (
              <span className={`px-2 py-1 rounded-full tabletApproved`}>
                <span className="green-dot" />
                {item?.task_result}
              </span>
            )}
          </td>
          <td className="border-b text-center">
            {item?.account_status == 2 && (
              <span className={`px-2 py-1 rounded-full tabletRejected`}>
                <span className="red-dot" />
                Rejected
              </span>
            )}
            {(item?.account_status == 1) && (
              <span className={`px-2 py-1 rounded-full tabletApproved`}>
                <span className="green-dot" />
                Approved
              </span>
            )}
            {item?.account_status == 3 && (
              <span className={`px-2 py-1 rounded-full tabletRejected`}>
                <span className="red-dot" />
                Failed
              </span>
            )}
            {![1, 2, 3]?.includes(item?.account_status) && (
              <span className={`px-2 py-1 rounded-full tabletPending`}>
                <span className="yellow-dot" />
                Pending
              </span>
            )}
          </td>
          <td
            className="py-2 px-6 text-center border-b iconPointer"
            onClick={() => handleDetail(item)}
          >
            <Icon iconName="EyeAlt"/>
          </td>
        </tr>
      );
    });
  }
  function savingAccountData(accountData) {
    return accountData?.map((item) => {
      return (
        <div className="min-w-full rounded-lg border border-solid border-gray-border min-h-20 mt-5 bg-white">
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Account Number: </span>
            <span className="py-2 px-4 border-b w-1/2">
              {item?.task_data?.account_number}
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Name</span>
            <span className="py-2 px-4 border-b w-1/2">
              {item?.task_data?.name ? item?.task_data?.name : "-"}
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Verify/Process Status</span>
            <span className="py-2 px-4 border-b w-1/2">
              <div className="flex">
                <Popup
                  trigger={
                    <div className="flex">
                      <div className="">
                        {handleCheckStatus(item?.task_data?.process_status?.account_listed_status, "accountListStatus")}
                      </div>
                      <div className="ms-4">
                        {handleCheckStatus(item?.task_data?.process_status?.account_data_scrap_status, "")}
                      </div>
                      <div className="ms-4">
                        {handleCheckStatus(item?.task_data?.process_status?.nid_verification_status, "")}
                      </div>
                      <div className="ms-4">
                        {handleCheckStatus(item?.task_data?.process_status?.account_update_status, "")}
                      </div>
                    </div>
                  }
                  position="bottom righ"
                  on="click"
                  // mouseEnterDelay={200}
                  // mouseLeaveDelay={300}
                  contentStyle={{ padding: '20px', }}
                >
                  <div>
                    <div className="ms-3">
                      {handleDataScrapStatus(item?.task_data?.process_status?.account_listed_status, item?.task_data?.process_status?.account_listed_at, "accountListStatus")}
                    </div>

                    <div className="mt-3 ms-3">
                      {handleDataScrapStatus(item?.task_data?.process_status?.account_data_scrap_status, item?.task_data?.process_status?.account_data_scrapped_at, "")}
                    </div>

                    <div className="mt-3 ms-3">
                      {handleNidVerficationStatus(item?.task_data?.process_status?.nid_verification_status, item?.task_data?.process_status?.nid_verified_at, "")}
                    </div>

                    <div className="flex mt-3 ms-3">
                      {handleAccountUpdateStatus(item?.task_data?.process_status?.account_update_status, item?.task_data?.process_status?.account_updated_at, "")}
                    </div>
                  </div>
                </Popup>
              </div>
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold"> Initiated Date</span>
            <span className="py-2 px-4 border-b w-1/2">
              {item?.created_at
                ? new Date(item.created_at).toLocaleString("en-GB")
                : "-"}
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Remark</span>
            <span className="py-2 px-4 border-b w-1/2">
              {item?.task_status}
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Account Status</span>
            <span className="border-b w-1/2 flex items-center">
              {item?.task_result === "Failed" && (
                <span className={`px-3 py-1 rounded-full tabletRejected`}>
                  <span className="red-dot" />
                  {item?.task_result}
                </span>
              )}
              {item?.task_result === "Process" && (
                <span className={`px-3 py-1 rounded-full tabletApproved`}>
                  <span className="green-dot" />
                  {item?.task_result}
                </span>
              )}
              {item?.task_result === "Processed" && (
                <span className={`px-3 py-1 rounded-full tabletPending`}>
                  <span className="yellow-dot" />
                  {item?.task_result}
                </span>
              )}
            </span>
          </div>
          <div className={`flex w-full`}>
            <span className="py-2 px-4 border-b text-left w-1/2 font-semibold">Process Status</span>
            <span className="border-b w-1/2 flex items-center">
              {item?.account_status == 2 && (
                <span className={`px-3 py-1 rounded-full tabletRejected`}>
                  <span className="red-dot" />
                  Rejected
                </span>
              )}
              {item?.account_status == 1 && (
                <span className={`px-3 py-1 rounded-full tabletApproved`}>
                  <span className="green-dot" />
                  Approved
                </span>
              )}
              {item?.account_status == 3 && (
                <span className={`px-3 py-1 rounded-full tabletRejected`}>
                  <span className="red-dot" />
                  Failed
                </span>
              )}
              {![1, 2, 3]?.includes(item?.account_status) && (
                <span className={`px-3 py-1 rounded-full tabletPending`}>
                  <span className="yellow-dot" />
                  Pending
                </span>
              )}
            </span>
          </div>
          <div className={`flex w-full justify-center`}>
            <span className="py-2 px-4 border-b text-center font-semibold" style={{color: themeDetails?.color_config?.main_color}} colSpan="2" onClick={() => handleDetail(item)}>View Account Details</span>
          </div>
        </div>
      );
    });
  }
  function tableDetailsRow(accDetails) {
    return accDetails?.map((item) => {
      return (
        <tr>
          <td className="py-2 px-4 border-b">
            {item?.user_name ? item?.user_name : "-"}
          </td>
          <td className="py-2 px-4 border-b">
            {item?.timestamp
              ? new Date(item?.timestamp).toLocaleString("en-GB")
              : "-"}
          </td>
          <td className="py-2 px-4 border-b">
            {item?.status === "success" ? (
              <span className="rounded-full tabletCredit px-3 py-1">
                {" "}
                <span className="green-dot"></span>Success
              </span>
            ) : (
              <span className="rounded-full tabletDebit px-3 py-1">
                <span className="red-dot"></span>Failed
              </span>
            )}
          </td>
          <td className="py-2 px-4 border-b">
            {item?.details ? item?.details : "-"}
          </td>
        </tr>
      );
    });
  }

  function handlePageChange(currentPage) {
    setShowDataLoading(true);
    setAccountData([]);
    getTaskList(currentPage, recordsPerPage, filterDate, searchFilterData?.search, selectedSortOptions, accountStatusValue);
  }

  function handleDateChange(value, name) {
    setFilterData({ ...filterData, [name]: value });
    setDateErrors(prev => ({
      ...prev,
      startDateError: name == 'startDate' ? '' : dateErrors?.startDateError,
      endDateError: name == 'endDate' ? '' : dateErrors?.endDateError
    }))
  }

  async function handleFilter() {
    try {
      if (filterData?.startDate == '' || filterData?.endDate == '') {
        setDateErrors(prev => ({
          ...prev,
          startDateError: filterData?.startDate == '' ? 'Start Date is required' : '',
          endDateError: filterData?.endDate == '' ? 'End Date is required' : ''
        }))
      } else {
        setShowLoading(true);
          const payload = {
            startDate: formatDateToIso(filterData.startDate.$d),
            endDate: formatDateToIso(filterData.endDate.$d),
            account_type: tab,
            clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
          const response = await API.apiGet(`/report/download-report`, '',payload)
          if(response.status === 200) {
            await downloadCsvFile(response.data)
            toast.success("File downloaded successfully")
            closeModal()
          }
          else 
            toast.error(response?.data?.error || "Both startDate and endDate are required.")
          setShowLoading(false)
      }
    }
    catch (error) {
      setShowLoading(false)
      handleErrorMessage(error);
        // toast.error(error?.response?.data?.error)
    }
  }

  async function downloadCsvFile(csvData) {
    const blob = new Blob([csvData], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  async function getReportStatus(accountType, ranges) {
    let params = {
      accountType: accountType || tab,
      start_date: ranges ? ranges.startDate : filterDate?.startDate,
      end_date: ranges ? ranges.endDate : filterDate?.endDate,
    };
    try {
      const response = await API.apiGet(`tasks/list/status`, "", params);
      if (response.status === 200) {
        setReportStatusCards({
          approved: response?.data?.approved,
          processed: response?.data?.processed,
          rejected: response?.data?.rejected,
          failed: response?.data?.failed
        });
      } else {
        setReportStatusCards({ ...noDataStatus });
        toast.error(response?.data?.error);
      }
    } catch (error) {
      setReportStatusCards({ ...noDataStatus });
      if (!localStorage.getItem("errorStateS02")) {
        localStorage.setItem("errorStateS02", error?.response?.data?.error);
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.error);
      }
    }
  }

  useEffect(()=>{
    getChartReportData();
  },[])

  async function getChartReportData(accountType){
    const approved = [];
    const failed = [];
    const reject = [];
    const process = [];
    let chartDaysData = chartsDays
    let params = {
      accountType: accountType || tab,
      time_weekly:1
    }
    try{
      const response = await API.apiGet(`tasks/list/status/weekly`,'',params)
      if( response.status === 200 ){
        for (let day in response?.data) {
          approved.push(response?.data[day].approved);
          failed.push(response?.data[day].failed);
          reject.push(response?.data[day].rejected);
          process.push(response?.data[day].process);
        }
        const updatedChartDaysData = chartDaysData.map(item => {
          return {
            ...item,
            date: response?.data?.[item.dayLabel]?.date
          };
        });
        updatedChartDaysData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        setApprovedValues(approved);
        setFailedValues(failed);
        setProcessValues(process);
        setRejectedValues(reject);
        setChartsDays(updatedChartDaysData);
      }else{
        toast.error(response?.data?.error)
      }
    }catch (error) {  
          // handleErrorMessage(error);
          console.log("error",error);
    }
  }

  const handleSearchFilterChange = (e) => {
    const searchValue = e.target.value.trim();
    setSearchFilterData({ ...searchFilterData, search: searchValue });
    if (searchValue == '') {
      getTaskList(1, recordsPerPage, filterData, searchValue, selectedSortOptions, accountStatusValue);
    }
  };

  const handleSearch = async() => {
    setShowDataLoading(true)
    getTaskList(1, recordsPerPage, filterData, searchFilterData.search, selectedSortOptions, accountStatusValue);
  //   try{
  //     const response = await API.apiGet(`/tasks/filter/accounts?accountNumber=${searchFilterData.search}&accountType=${`S02`}`);
  //     if (response?.status == 200) {
  //       setAccountData(response?.data?.data);
  //       setShowDataLoading(false);
  //     } else {
  //       setShowDataLoading(false);
  //       setAccountData([]);
  //     }
  // } catch(err) {
  //   handleErrorMessage(err);
  //   setShowDataLoading(false);
  //   setAccountData([]);
  // }
    setApplyFilter(applyFilter + 1);
  };

  const handleClear = () => {
    setShowDataLoading(true);
    setSearchFilterData({ ...searchFilterData, search: "" });
    getTaskList(1, recordsPerPage, filterData, "", selectedSortOptions, accountStatusValue);
    setApplyFilter(applyFilter + 1);
  };

  async function getDetailsInfo() {
    let params = {
      skip: currentDetailsPage,
      limit: 6,
    };
    try {
      setDetailsData([]);
      const response = await API.apiGet(
        "/tasks/account_ver_log/details",
        "",
        params
      );
      if (response?.status === 200) {
        setDetailsData(response?.data?.data);
        // let totalPage = Math.ceil(response?.data?.data?.length)
        setTotalDetailsCount(
          response?.data?.pageInfo?.totalPageCount ||
            response?.data?.data?.length
        );
      } else {
        handleErrorMessage(response?.data?.error);
        // toast.error(response?.data?.error);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.error);
    }
  }

  const closeModal = () => {
    setShowReportsDownloadModal(false);
    setFilterData(initialFilterData);
    setDateErrors(initialDateErrors);
    dispatch(closingModal());
  };

  const showModal = () => {
    setShowReportsDownloadModal(true);
    dispatch(openingModal());
  };

  const handleDateRangeSubmit = async (ranges) => {
    setReportStatusCards({ ...initialStatsData });
    setShowDataLoading(true);
    localStorage.removeItem("errorStateS02");
    setAccountData([]);
    setFilterDate(ranges);
    getTaskList(1, recordsPerPage, ranges, searchFilterData?.search, selectedSortOptions, accountStatusValue);
    getReportStatus(tab, ranges);
    setApplyFilter(applyFilter + 1);
  };

  const getCurrentDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleBack = () => {
    setQuerry("");
  };

  const formatToISOStringInTimeZone = (date, timeZoneOffset) => {
    const adjustedDate = moment(date).add(timeZoneOffset, "minutes");
    return adjustedDate.toISOString();
  };

  const handleSubmit = (dates) => {
    setDateData(dates);
    if (!dates) {
      handleDateRangeSubmit({
        startDate: "",
        endDate: "",
      });
    }
    if (!dates || dates.length !== 2) return; // Ensure both start and end dates are available
    const timeZoneOffset = 1440; // Offset in minutes
    const [startDate, endDate] = dates;
    const formattedStartDate = formatToISOStringInTimeZone(
      startDate?.$d,
      timeZoneOffset
    );
    const formattedEndDate = formatToISOStringInTimeZone(
      endDate?.$d,
      timeZoneOffset
    );

    handleDateRangeSubmit({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    if (selectedSortOptions === option) {
      setSelectedSortOptions('');
      getTaskList(1, recordsPerPage, filterDate, searchFilterData?.search, '', accountStatusValue);
      setShowDropdown(!showDropdown);
    } else {
      setSelectedSortOptions(option);
      getTaskList(1, recordsPerPage, filterDate, searchFilterData?.search, option, accountStatusValue);
      setShowDropdown(!showDropdown);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
     handleSearch();
    }
  };

  const handleChange=(event, card)=> {
    setCurrentPage(1);
    setShowDataLoading(true)
    setAccountData([]);
    let count=''
    if (card == 'processed'){
      count = 0
    }else if (card == 'approved'){
      count = 1
    }else if (card == 'failed'){
      count = 3
    }else{
      count = 2
    }
    if (event.target.id != 'removeFilter') {
      setSelectedCard(card);
      setAccountStatusValue(count);
      oldPaylod.status = count
      oldPaylod.skip = 1
    } else {
      oldPaylod.status = ''
      oldPaylod.skip = 1
      setSelectedCard('');
      setAccountStatusValue(null);
    }
    Functions.handleAPI(
      CONST.GET_TASK_LIST,
      CONST.GET_METHOD,
      oldPaylod,
      getTaskListSuccess,
      getTaskListFail,
      progressCallBack,
      token
    );
  }

  const removeStatusFilter = () => {
    setSelectedCard('');
    setAccountStatusValue(null);
  }

  const getChartDays = () => {
    return (
      chartsDays?.map(item => (
        `${item.dayKey}\n${item.date}`
      ))
    )
  }

  return (
    responseScreenWidth > 900 ?
    <div className="flex dark:bg-dark-background account-verification accountVerifyMain mainBodyContainer">
      <SideMenu/>
      <div className="min-h-screen flex-1">
        <Header/>
        {!querry?.id ? (
          <div className="z-0 p-8 pt-10 mainContainer fixedHeightContainer">
            <div className="flex justify-between">
              <Breadcrumb
                steps={steps}
                activeStep={1}
                showBackButton={true}
                redirectUrl={"/"}
              />
              {/* <button onClick={clearIntervalAndCallFunction}>stop</button> */}
            </div>
            
            <div className="flex justify-between gap-2 cardsInResponse">
              <div className="flex flex-col justify-start gap-2 w-7/12">
                <div className="flex justify-between gap-2 cardsInResponse flex-wrap" style={{ opacity: !accountData?.length ? 0.5 : 1 }}>
                  {Object.keys(reportStatusCards)?.map((card, idx) => (
                    <div
                      key={`card_${idx}`}
                      className={`flex cursor-pointer flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mt-4 p-3 gap-2 w-full accountVerificationCardAccount ${
                        selectedCard === card ? `${styles.selected}` : ''
                      }`}
                      onClick={(event) => handleChange(event, card)} 
                      title={`${selectedCard != card ? `Filter ${cardsLabel[card]} data` : ''}`}
                    >
                      <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                        <div className="flex flex-col">
                          <div className="flex justify-between items-center">
                            <p
                              className="inline-block relative font-quicksand text-2xl font-extrabold mr-2 curser-pointer"
                              style={{ color: cardsColor?.[card] }}
                            >
                              {reportStatusCards[card]}
                            </p>
                            {reportStatusCards[card] != "loading..." && (selectedCard === card ? 
                              <img 
                                src="assets/images/clearFilter.svg" 
                                alt="Remove filter" id="removeFilter" 
                                className={`${styles.removeFilterIcon}`} 
                                onClick={() => removeStatusFilter()} 
                                title={`Remove filter`}
                              />
                              :
                              <img 
                                src="assets/images/filter-icon.svg" 
                                alt="Apply filter" id="applyFilter" 
                                className={`${styles.removeFilterIcon}`} 
                                onClick={(event) => handleChange(event, card)}
                                title={`Apply filter`}
                              />)
                            }
                          </div>
                          {reportStatusCards[card] != "loading..." && <div>
                              <p className="text-black font-quicksand text-base font-medium leading mt-3 cursor-pointer" value={accountStatusValue}
                                onClick={(event) => handleChange(event, card)}>
                                {cardsLabel[card]}
                              </p>
                          </div>}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-8 relative flex justify-between flex-wrap-reverse gap-2">
                  <div ref={sortByDropdownRef}>
                    <div className=" flex">
                      <div className="flex justify-between rounded-full sortingStatusData p-2 sortingStatusData cursor-pointer" onClick={toggleDropdown}>
                        <img src="assets/images/logoFilter.svg"></img>
                        <p className="cursor-default">Sort by</p>
                        <img src="assets/images/sortUp.svg" className={`${showDropdown ? 'rotate-180' : ''} smoothTransition`}></img>
                      </div>
                      {/* <div className="flex">
                      {selectedSortOptions.length > 0 && selectedSortOptions.map((item)=>
                        <div className="flex justify-between rounded-full p-2 sortSelectedOptions ms-2">
                          <p id="item" className="flex">{item}<img src="assets/images/clearTheSort.svg" className="ms-2" onClick={() => handleClearSort(item)}/></p>
                        </div>
                      )}
                      </div> */}
                      {/* <div>
                      {selectedSortOptions.length > 0 && <div className="flex justify-between rounded-full p-2 ms-2 clearDataSort" onClick={() => handleOptionClick('Clear Data')}>
                        <p>Clear All</p>
                      </div>}
                      </div> */}
                    </div>
                    {showDropdown && (
                      <div className="dropdown-content w-60 rounded-lg absolute mt-1 z-50"  >
                        <p className={`border-b p-3 flex cursor-pointer rounded-t-lg ${selectedSortOptions === "low to high" ? 'activeSortOption' : ''}`} onClick={() => handleOptionClick('low to high')}>{selectedSortOptions === "low to high" && <img src="assets/images/tickit.svg" className="me-3"/>}Low to High</p>
                        <p className={`border-b p-3 flex cursor-pointer ${selectedSortOptions === "high to low" ? 'activeSortOption' : ''}`}  onClick={() => handleOptionClick('high to low')}>{selectedSortOptions === "high to low" && <img src="assets/images/tickit.svg" className="me-3"/>}High to Low</p>
                      </div>
                    )}
                  </div>
                  {/* SEARCH FUNCTION  */}
                  <div className="searchButton gap-4 flex flex-row-reverse ">
                      <div className="flex relative rounded-lg bg-white">
                        <div className="relative text-sm">
                          <input
                            type="text"
                            placeholder="Search Account No."
                            className={`px-4 h-10 border-e-2  outline-none   searchAccountInp`}
                            onChange={handleSearchFilterChange}
                            onKeyDown={handleKeyPress}
                            value={searchFilterData?.search}
                          ></input>
                          {searchFilterData?.search && (
                            <button
                              className="absolute right-0 top-0 h-full pe-2 flex items-center justify-center opacity-50 hover:opacity-100"
                              onClick={handleClear}
                            >
                              <img src="assets/images/cross.png" width={15} />
                            </button>
                          )}
                        </div>
                        <button className="p-2 searchBtn" onClick={handleSearch}>
                          <Icon iconName="searchIconBlue" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                        </button>
                      </div>
                      <div className="flex items-center gap-4 h-10 text-sm">
                    <CustomDateRangeInput
                      className="border border-none px-4 focus:outline-none focus:border-blue-500 "
                      handleDateSubmit={handleDateRangeSubmit}
                      data={filterDate}
                      handleSubmit={handleSubmit}
                      datesData={datesData}
                    />
                    <button
                      onClick={showModal}
                      className="custom_btn_primary bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn h-10 rounded-md flex gap-2 items-center justify-center downloadBtn"
                    >
                      {/* <svg
                        class="fill-current w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                      </svg> */}
                      Download Report
                      <Icon iconName="Download" width={16} height={16} />
                    </button>
                  </div> 
                  </div>
                </div>
              </div>
              <div id="chart" className="flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white gap-2 mt-4 w-full accountVerificationCardAccount">
                <BarChart
                  xAxis={[{ scaleType: 'band', data: getChartDays() }]}
                  series={[
                    { data: approvedValues, stack: 'A', label: 'Approved',color:'#517dd2' },
                    { data: processValues, stack: 'A', label: 'Processed',color:'#196857' },
                    { data: rejectedValues, stack: 'A', label: 'Rejected',color:'#f8aa50' },
                    { data: failedValues, stack: 'A', label: 'Failed',color:'#ff0000' },
                    // { data: [10, 6, 5, 8, 9], label: 'Series C1' },
                  ]}
                    width={chartWidth}
                    height={350}
                    />
                  <div className="pt-3 pe-2">
                    <img title="expand" onClick={openChartModal} className="cursor-pointer" width="15" src={fullScreenIcon}></img>
                  </div>
              </div>
            </div>
              {showReportsDownloadModal && (
                <div
                  id="static-modal"
                  data-modal-backdrop="static"
                  tabindex="-1"
                  aria-hidden="true"
                  class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
                >
                  <div class="relative p-4 w-full max-w-2xl max-h-full">
                    <div
                      class="relative bg-white rounded-lg shadow dark:bg-gray-700"
                      style={
                        showLoading
                          ? { filter: "blur(0.7px)", pointerEvents: "none" }
                          : null
                      }
                    >
                      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Download Report
                        </h3>
                        <button
                          type="button"
                          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-hide="static-modal"
                          onClick={closeModal}
                        >
                          <svg
                            class="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span class="sr-only">Close modal</span>
                        </button>
                      </div>
                      <div className="p-4 flex gap-2 justify-center">
                        <div className={`flex flex-col w-full`}>
                          <DatePicker
                            name="startDate"
                            value={filterData?.startDate}
                            onChange={(val) => handleDateChange(val, "startDate")}
                            placeholder="From" // Placeholder for start date
                            className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                            disabledDate={getCurrentDate}
                          />
                          {dateErrors?.startDateError != '' && <span className={`text-red-500 ms-1`}>{dateErrors?.startDateError}</span>}
                        </div>
                        <div className={`flex flex-col w-full`}>
                          <DatePicker
                            name="endDate"
                            value={filterData?.endDate}
                            onChange={(val) => handleDateChange(val, "endDate")}
                            placeholder="To" // Placeholder for end date
                            className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                            disabledDate={getCurrentDate}
                          />
                          {dateErrors?.endDateError != '' && <span className={`text-red-500 ms-1`}>{dateErrors?.endDateError}</span>}
                        </div>
                      </div>

                      <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button
                          data-modal-hide="static-modal"
                          type="button"
                          class="custom_btn_primary flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn"
                          onClick={handleFilter}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {openChart && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">
                  <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Weekly Status Report
                      </h3>
                      <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeChartModal}>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>
                    </div>
                    <BarChart
                      xAxis={[{ scaleType: 'band', data: getChartDays() }]}
                      series={[
                        { data: approvedValues, stack: 'A', label: 'Approved', color: '#517dd2' },
                        { data: processValues, stack: 'A', label: 'Processed', color: '#196857' },
                        { data: rejectedValues, stack: 'A', label: 'Rejected', color: '#ff0000' },
                        { data: failedValues, stack: 'A', label: 'Failed', color: '#f8aa50' },
                        // { data: [10, 6, 5, 8, 9], label: 'Series C1' },
                      ]}
                      width={650}
                      height={350}
                    />
                  </div>
                </div>
              </div>}

              <div className="overflow-x-auto mt-4 flex justify-center flex-col items-center relative">
                {accountData?.length == 0 ?
                  <>
                    <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-20 accounttable">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b text-left">
                            Account Number
                          </th>
                          <th className="py-2 px-4 border-b text-left">Name</th>
                          <th className="py-2 px-4 border-b text-left">
                            Verify/Process Status
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Initiated Date
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Remark
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Account Status
                          </th>
                          <th className="py-2 px-4 border-b text-left">Process Status</th>
                          <th className="py-2 px-4 border-b text-left">View</th>

                          {/* <th className="py-2 px-4 border-b text-left">Action</th> */}
                        </tr>
                      </thead>
                    </table>
                    {showDataLoading ? (
                      <div
                        className="flex py-5 flex bg-white w-full justify-center"
                      >
                        <ColorRing
                          visible={true}
                          height="50"
                          width="50"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={[]}
                        />
                      </div>
                    )
                      :
                      <div className="py-5 font-semibold flex bg-white w-full justify-center">
                        No data found
                      </div>
                    }
                  </>
                  :
                  <>
                    <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-32 accounttable">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b text-left">
                            Account Number
                          </th>
                          <th className="py-2 px-4 border-b text-left">Name</th>
                          <th className="py-2 px-4 border-b text-left">
                            Verify/Process Status
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Initiated Date
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Remark
                          </th>
                          <th className="py-2 px-4 border-b text-left">
                            Account Status
                          </th>
                          <th className="py-2 px-4 border-b text-left">Process Status</th>
                          <th className="py-2 px-4 border-b text-left">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableRow(accountData)}</tbody>
                    </table>
                    {accountData?.length > 0 &&
                      <div className="w-full flex justify-end">
                        <Pagination
                          nPages={nPages}
                          currentPage={currentPage}
                          handlePageChange={handlePageChange}
                        ></Pagination>
                      </div>
                    }
                  </>
                }
              </div>
          </div>
        ) : (
          <AccountDetail querry={querry} handleBack={handleBack} />
        )}
      </div>
      <ToastContainer></ToastContainer>
    </div>
    :
    <div className="flex dark:bg-dark-background account-verification accountVerifyMain mainBodyContainer">
      {openChart && 
        <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
          <div class="relative p-4 w-full max-w-2xl max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
              <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                  Weekly Status Report
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeChartModal}>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <BarChart
                xAxis={[{ scaleType: 'band', data: getChartDays() }]}
                series={[
                  { data: approvedValues, stack: 'A', label: 'Approved', color: '#517dd2' },
                  { data: processValues, stack: 'A', label: 'Processed', color: '#196857' },
                  { data: rejectedValues, stack: 'A', label: 'Rejected', color: '#ff0000' },
                  { data: failedValues, stack: 'A', label: 'Failed', color: '#f8aa50' },
                  // { data: [10, 6, 5, 8, 9], label: 'Series C1' },
                ]}
                height={350}
              />
            </div>
          </div>
        </div>
      }
    <SideMenu headerName="Regular Savings Account"/>
    <div className="min-h-screen flex-1 h-screen overflow-y-auto mainContainer_responsive">
      <Header />
      {!querry?.id ? (
        <div className="z-0 p-10 mainContainer minorAccountResponsive savingAccR">
          <div className="flex justify-between">
            {/* <button onClick={clearIntervalAndCallFunction}>stop</button> */}
             <div className="flex items-center gap-2 h-10">
              {/* <div className="searchButton flex flex-row-reverse">
                <div className="flex relative rounded overflow-hidden bg-white">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search Account No."
                      className="px-4 h-10 border-e-2  outline-none"
                      onChange={handleSearchFilterChange}
                      value={searchFilterData?.search}
                    ></input>
                    {searchFilterData?.search && (
                      <button
                        className="absolute right-0 top-0 h-full pe-2 flex items-center justify-center opacity-50 hover:opacity-100"
                        onClick={handleClear}
                      >
                        <img src="assets/images/cross.png" width={15} />
                      </button>
                    )}
                  </div>
                  <button className="p-2 searchBtn" onClick={handleSearch}>
                    <img
                      src="assets/images/Vector.svg"
                      className="searchLogo"
                    />
                  </button>
                </div>
              </div> */}
              <CustomDateRangeInput
                className="border border-none px-4 focus:outline-none focus:border-blue-500 "
                handleDateSubmit={handleDateRangeSubmit}
                data={filterDate}
                handleSubmit={handleSubmit}
                datesData={datesData}
              />
              <button
                onClick={showModal}
                className="bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn h-10 rounded-md flex gap-2 items-center "
              >
                Report
                <Icon iconName="Download" width={16} height={16} />
              </button>
            </div> 
          </div>
          <div
            className="flex justify-between gap-2 cardsInResponse"
            style={{ opacity: !accountData?.length ? 0.5 : 1 }}
          >
            {Object.keys(reportStatusCards)?.map((card, idx) => (
              <div
                key={`card_${idx}`}
                className={`flex cursor-pointer flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mt-4 p-4 gap-2 w-full accountVerificationCardAccount ${
                  selectedCard === card ? `${styles.selected}` : ''
                }`}
                onClick={(event) => handleChange(event, card)} 
                title={`${selectedCard != card ? `Filter ${cardsLabel[card]} data` : ''}`}
              >
                <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center">
                      <p
                        className="inline-block relative font-quicksand text-3xl font-extrabold mr-2 curser-pointer"
                        style={{ color: cardsColor?.[card] }}
                      >
                        {reportStatusCards[card]}
                      </p>
                      {reportStatusCards[card] != "loading..." && (selectedCard === card ? 
                        <img 
                          src="assets/images/clearFilter.svg" 
                          alt="Remove filter" id="removeFilter" 
                          className={`${styles.removeFilterIcon}`} 
                          onClick={() => removeStatusFilter()} 
                          title={`Remove filter`}
                        />
                        : 
                        <img 
                          src="assets/images/filter-icon.svg" 
                          alt="Apply filter" id="applyFilter" 
                          className={`${styles.removeFilterIcon}`} 
                          onClick={(event) => handleChange(event, card)}
                          title={`Apply filter`}
                        />)
                      }
                    </div>
                    {reportStatusCards[card] != "loading..." && <div>
                        <p className="text-black font-quicksand text-lg font-medium leading cursor-pointer" value={accountStatusValue}
                          onClick={(event) => handleChange(event, card)}>
                          {cardsLabel[card]}
                        </p>
                    </div>}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="responsiveChart flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white gap-2 mt-4 w-full">
                <BarChart
                  xAxis={[{ scaleType: 'band', data: getChartDays() }]}
                  series={[
                    { data: approvedValues, stack: 'A', label: 'Approved',color:'#517dd2' },
                    { data: processValues, stack: 'A', label: 'Processed',color:'#196857' },
                    { data: rejectedValues, stack: 'A', label: 'Rejected',color:'#ff0000' },
                    { data: failedValues, stack: 'A', label: 'Failed',color:'#f8aa50' },
                    // { data: [10, 6, 5, 8, 9], label: 'Series C1' },
                  ]}
                    width={650}
                    height={350}
                  />

                  {/* <div className="pt-3 pe-2">
                    <img title="expand" onClick={openChartModal} className="cursor-pointer" width="45" height="30" src={fullScreenIcon}></img>
                  </div> */}
              </div>
          <div className="mt-4 relative flex justify-between">
            <div ref={sortByDropdownRef}>
              <div className=" flex">
                <div className="flex justify-between rounded-full sortingStatusData p-2 cursor-pointer" onClick={toggleDropdown}>
                  <img className="cursor-pointer" src="assets/images/logoFilter.svg"></img>
                  <p className="cursor-default">Sort by</p>
                  <img src="assets/images/sortUp.svg" className={`${showDropdown ? 'rotate-180' : ''} smoothTransition`}></img>
                </div>
                {/* <div className="flex">
                {selectedSortOptions.length > 0 && selectedSortOptions.map((item)=>
                  <div className="flex justify-between rounded-full p-2 sortSelectedOptions ms-2">
                    <p id="item" className="flex">{item}<img src="assets/images/clearTheSort.svg" className="ms-2" onClick={() => handleClearSort(item)}/></p>
                  </div>
                )}
                </div> */}
                {/* <div>
                {selectedSortOptions.length > 0 && <div className="flex justify-between rounded-full p-2 ms-2 clearDataSort" onClick={() => handleOptionClick('Clear Data')}>
                  <p>Clear All</p>
                </div>}
                </div> */}
              </div>
              {showDropdown && (
                <div className="dropdown-content w-60 rounded-lg absolute mt-1 z-50">
                  <p className={`border-b p-3 flex cursor-pointer rounded-t-lg ${selectedSortOptions === "low to high" ? 'activeSortOption' : ''}`} onClick={() => handleOptionClick('low to high')}>{selectedSortOptions === "low to high" && <img src="assets/images/tickit.svg" className="me-3"/>}Low to High</p>
                  <p className={`border-b p-3 flex cursor-pointer  ${selectedSortOptions === "high to low" ? 'activeSortOption' : ''}`}  onClick={() => handleOptionClick('high to low')}>{selectedSortOptions === "high to low" && <img src="assets/images/tickit.svg" className="me-3"/>}High to Low</p>
                </div>
              )}
            </div>
            {/* SEARCH FUNCTION  */}
            <div className="searchButton flex flex-row-reverse">
                <div className="flex relative rounded overflow-hidden bg-white">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search Account No."
                      className="px-4 h-10 border-e-2  outline-none searchAccountNum"
                      onChange={handleSearchFilterChange}
                      value={searchFilterData?.search}
                    ></input>
                    {searchFilterData?.search && (
                      <button
                        className="absolute right-0 top-0 h-full pe-2 flex items-center justify-center opacity-50 hover:opacity-100"
                        onClick={handleClear}
                      >
                        <img src="assets/images/cross.png" width={15} />
                      </button>
                    )}
                  </div>
                  <button className="p-2 searchBtn" onClick={handleSearch}>
                    <Icon iconName="searchIconBlue" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                  </button>
                </div>
            </div>
          </div>

          {showReportsDownloadModal && (
            <div
              id="static-modal"
              data-modal-backdrop="static"
              tabindex="-1"
              aria-hidden="true"
              class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full"
            >
              <div class="relative p-4 w-full max-w-2xl max-h-full">
                <div
                  class="relative bg-white rounded-lg shadow dark:bg-gray-700"
                  style={
                    showLoading
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                      Download Report
                    </h3>
                    <button
                      type="button"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="static-modal"
                      onClick={closeModal}
                    >
                      <svg
                        class="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4 flex gap-2 justify-center">
                    <DatePicker
                      name="startDate"
                      value={filterData?.startDate}
                      onChange={(val) => handleDateChange(val, "startDate")}
                      placeholder="From" // Placeholder for start date
                      className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                      disabledDate={getCurrentDate}
                    />
                    <DatePicker
                      name="endDate"
                      value={filterData?.endDate}
                      onChange={(val) => handleDateChange(val, "endDate")}
                      placeholder="To" // Placeholder for end date
                      className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                      disabledDate={getCurrentDate}
                    />
                  </div>

                  <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      data-modal-hide="static-modal"
                      type="button"
                      class="custom_btn_primary flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn"
                      onClick={handleFilter}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="overflow-x-auto mt-4 flex justify-center flex-col items-center relative">
            {accountData?.length == 0 ? 
            <>
              {showDataLoading ? (
                <div
                  className="flex py-5"
                >
                  <ColorRing
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                  />
                </div>
              )
              : 
              <div className="noDataFound">
                <img
                    src='/assets/noData.svg'
                    alt="no-data"
                    className={`noDataImage noDataIcon'}`}
                />
                <div className="text-xl font-semibold">
                    No data found
                </div>
              </div>
              }
            </>
              : 
            <>
              {savingAccountData(accountData)}
              {accountData?.length > 0 &&
              <>
                {/* <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-20 accounttable bg-white">
                  <thead>
                      <tr><th className="py-2 px-4 border-b text-left">Account Number</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left">Name</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left">Verify/Process Status</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left"> Initiated Date</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left">Remark</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left"> Account Status</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left">Process Status</th></tr>
                      <tr><th className="py-2 px-4 border-b text-left">Actions</th>
                      <th className="py-2 px-4 border-b text-left">Action</th>
                    </tr>
                  </thead>
                </table> */}
                <div className="w-full flex justify-end">
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  ></Pagination>
                </div>
              </>
              }
            </>
            }
          </div>
        </div>
      ) : (
        <AccountDetail querry={querry} handleBack={handleBack} />
      )}
    </div>
    <ToastContainer></ToastContainer>
  </div>
  );
};
export default WithAuth(AccountVerification);
