import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import API from '../helpers/api';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';

export const CRMDonutChart = ({ }) => {

  const [loanStatusCount,setLoanStatusCount] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const getLoanAccountList = async() =>{
    try {
        const { data } = await API.apiGet(`/loan/account/status/count`);
        if (data?.success) {
            toast.success(data?.message);
            setLoanStatusCount(data?.data);
            const updatedFilteredData = [
              getAccountCount(data?.data, "Document Verification"),
              getAccountCount(data?.data,"Physical Check"),
              getAccountCount(data?.data,"Pre Approval")
            ];
            setFilteredData(updatedFilteredData);
            setDataLoading(false);
        } else {
            toast.error(data?.message);
            setDataLoading(false)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message);
        setDataLoading(false)
    }
}

useEffect(()=>{
  getLoanAccountList();
  setDataLoading(true)
  // getAccountCount();
},[])

const getAccountCount = (data, status) => {
  let statusCount = data?.find((count) => count?.status === status);
  return statusCount ? parseFloat(statusCount?.count) : 0;
};

  const filterStatuses = ["Document Verification", "Physical Check", "Pre Approval"];
  const data = [
    {
        name:"Document Upload",
        count:56,
        color:"#02839F",
    },
    // {
    //     name:"Verification",
    //     count:40,
    //     color:"#975FE4",
    // },
    {
        name:"Pre-Approval",
        count:15,
        color:"#4ECB73",
    },
    {
        name:"Physical Check",
        count:30,
        color:"#1890FF",
    }
  ];
  const labelName = data.map((item)=>item.name);
  const colors = data.map((item)=>item.color);
  const values = data.map((item)=>item.count);
  const [options,setOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    animations: {
      enabled: false 
    },
    title: {
      text: "Statistics of active Applications",
      margin: 30,
      style: {
        fontWeight:  "500",
        fontFamily: '"Roboto", sans-serif',
        color: "#333333",
        fontSize: "14px",
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show:true,
            name: {
              show: false,
            },
            total: {
              show: true,
              showAlways: true,
            }
          }
        }
      }
    },
    legend: {
      horizontalAlign: "center",
      position: "right",
      fontSize: "14px",
    },
    labels:[...filterStatuses],
    colors:["#02839F","#4ECB73","#1890FF"],
  });
  return (
    <>
    {dataLoading && (
      <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
        <ColorRing
          visible={true}
          height="80"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={[]}
        />
      </div>
    )}
    <Chart type='donut' series={filteredData} height="500px" options={options} />
    </>
  )
}
