import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ColorRing } from 'react-loader-spinner';
import { WithAuth } from '../../common/WithAuth';
import "../../styles/userConfig.scss";
import API from "../../helpers/api/index";
import NewKeyPassModal from "./NewKeyPassModal";
import VaultDetailsTable from "./VaultDetailsTable";
import { encodeData, handleErrorMessage } from "../../common/commonFunction";
import Icon from "../../component/core-component/Icon";
import { useSelector } from "react-redux";

const DetailsColumns = [
    {
      title: 'Name',
      key: 'name',
      width:"20%",
    },
    {
      title: 'Username',
      key: 'userID',
      width:"20%",
    },
    {
        title: 'portal',
        key: 'portal',
        width:"20%",
      },
    {
        title: 'Submitted At',
        key: 'created_at',
        width:"20%",
    },
    {
        title: 'Updated On',
        key: 'updated_at',
        width:"20%",
    },
    {
        title: 'Expiry Date',
        key: 'validity_period',
        width:"20%",
    },
    {
        title: 'Status',
        key: 'status',
        width:"20%",
    },
  ]

function UserConfig() {
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [existingKeyPass, setExistingKeyPass] = useState([]);
    const [activeTab, setActiveTab] = useState('newKey');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubjectData, setFilteredSubjectData] = useState([]);
    const [isAddUserClicked, setIsAddUserClicked] = useState(false)
    const [isOpenNewPassKeyModal, setIsOpenNewPassKeyModal] = useState(false);
    const [createKeyPassLoader, setCreateKeyPassLoader] = useState(false);
    const [userListLoading, setUserListLoading] = useState(false);
    const isMobileDevice = window.screen.width <= 900; 

    const handleFormDataChange = (newFormData) => {
        setFormData(newFormData);
    };

    const getFilteredSubjectList = (event) => {
        const value = (event?.target?.value)?.toLowerCase(); 
        setSearchQuery(value);
        let filteredData;
        if (value) {
            filteredData = existingKeyPass?.filter((item) => {
                const isMatch = (item?.name)?.toLowerCase()?.includes(value) || (item?.userID)?.toLowerCase()?.includes(value);
                return isMatch
            });
        }
        setFilteredSubjectData(filteredData);
      };

    const handleSubmit = async () => {
        let payload = {
            payload: encodeData(formData)
        }
        try {
            setCreateKeyPassLoader(true);
            const { data } = await API.apiPost(`/bankAsia/create/keypass`, payload)
            if (data?.success) {
                toast.success( data?.message );
                getUserList();
                setIsAddUserClicked(false)
                handleCancel()
                setCreateKeyPassLoader(false);
                setIsOpenNewPassKeyModal(false);
            }
            else {
                toast.error(data?.message)
                setCreateKeyPassLoader(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setCreateKeyPassLoader(false);
        }
    };

    const getUserList = async () => {
        try {
            setUserListLoading(true);
            const response = await API.apiGet(`/bankAsia/getprofile`)
            if (response?.status) { 
                let apiData = response?.data.data
                if (apiData.length>0) {
                    apiData = apiData?.map(item => {
                        if (item.limited_validity) {
                            if (new Date(item.validity_period) < new Date()) {
                                item.status = 'InActive'
                            } else {
                                item.status = 'Active'
                            }
                        } else {
                            item.status = 'Active'
                        }
                        return item;
                    })
                }
                setExistingKeyPass(apiData);
                setUserListLoading(false);
            }
            else {
                toast.error(response?.message)
                setUserListLoading(false);
            }
        }
        catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setUserListLoading(false);
        }
    }

    useEffect(() => {
        getUserList();
    }, [])


    const handleCancel = (e) => {
        e?.preventDefault()
        setIsAddUserClicked(false);
        setErrors({})
    };

    const handleCreateKeyPassModal = () => {
        setIsOpenNewPassKeyModal(true);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className={`flex dark:bg-dark-background reconcilitaion-main-container`}>
                <SideMenu headerName="Vault"/>
                <div className={`mainBodyContainer flex-1 UserFormMainContainer ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    <div className="absolute z-10 flex items-center top-1/2 commonLoader justify-center" >
                        {userListLoading && 
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                        }
                    </div>
                    <div className={`z-0 container pt-10 px-10 pb-2 UserFormContainer ${!isMobileDevice && 'fixedHeightContainer'} ${userListLoading ? 'blurContent' : ''} mainContainer ${isMobileDevice && 'vaultMainContainer_responsive'}`} >
                        <div className="pb-6 font-bold">
                            Vault
                        </div>
                        <div className={`flex justify-between ${isMobileDevice && 'vaultTabs_container'}`} >
                            <div className=" flex justify-between items-center tabContainer">
                            <div className="differentTabs flex">
                                <div
                                className={`w-content p-2 text-center tabsName ${ activeTab === 'newKey' ? "showActiveColor commmonActiveTag" : ""}`}
                                >
                                <p className="mt-2 text-base cursor-pointer" onClick={() => handleTabClick('newKey')}>
                                    New Key-Pass
                                </p>
                                </div>
                                <div
                                className={`w-24 p-2 text-center tabsName ${ activeTab === 'Details' ? "showActiveColor commmonActiveTag" : ""}`}
                                >
                                <p className="mt-2 text-base cursor-pointer" onClick={() => handleTabClick('Details')}>
                                    Details
                                </p>
                                </div>
                            </div>
                            {activeTab==='Details' && !isMobileDevice && 
                            <div className="flex">
                                <div>
                                    <div className="flex relative rounded">
                                        <img
                                        src="assets/images/Filter.svg"
                                        className="subjectSearchLogo absolute"
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flex relative rounded">
                                        <img
                                        src="assets/images/Vector.svg"
                                        className="subjectSearchLogo absolute"
                                        />
                                        <input
                                        type="text"
                                        placeholder="Search Subject"
                                        className="subjectSearch h-11 outline-none"
                                        onChange={getFilteredSubjectList}
                                        ></input>
                                    </div>
                                </div>
                            </div>}
                            </div>
                        </div>
                        {activeTab == 'Details' && isMobileDevice &&
                        <div className="flex mt-4 justify-end mr-5">
                            <div>
                                <div className="flex relative rounded">
                                    <img
                                    src="assets/images/Filter.svg"
                                    className="subjectSearchLogo absolute"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div className="flex relative rounded">
                                    <img
                                    src="assets/images/Vector.svg"
                                    className="subjectSearchLogo absolute"
                                    />
                                    <input
                                    type="text"
                                    placeholder="Search Subject"
                                    className="subjectSearch h-11"
                                    onChange={getFilteredSubjectList}
                                    ></input>
                                </div>
                            </div>
                        </div>}
                        {activeTab === 'newKey' &&
                            <div className={`flex flex-col justify-center items-center vault_container ${isMobileDevice && 'mt-6 responsive_vaultContainer'}`}>
                                <div className={`${isMobileDevice && 'flex justify-between'}`}>
                                    <Icon iconName="vaultLogo" width={16} height={16} fill={themeDetails?.color_config?.main_color} />
                                    {/* <img src='/assets/images/user.svg' className={`${isMobileDevice && 'w-9/12 m-auto'}`} />   */}
                                </div>
                                <div className="flex flex-col text-center">
                                    <span className="vaultLogin_heading">Vault Login</span>
                                    <span className={`vault_textMsg ${isMobileDevice && 'responsiveVaultTxtContainer'}`}>A manager for safekeeping all the Credentials of different websites/platforms used across the process </span>
                                    <div className="flex">
                                        <button className="m-auto mt-7 text-white h-10 flex justify-center items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn createKeyPass_btn" onClick={handleCreateKeyPassModal}>Create Key-Pass</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {activeTab==='Details' &&
                        (existingKeyPass?.length > 0 ?
                            <VaultDetailsTable columns={DetailsColumns} rowData={searchQuery != "" ? filteredSubjectData : existingKeyPass} tableLoading={userListLoading} />
                        :
                            <div className="mt-6">
                                <div className="flex flex-col justify-center items-center mt-10">
                                    <img src="assets/images/noContactDetail.svg" className="w-32"></img>
                                    <h3 className="font-semibold mt-3">No records found !</h3>
                                    <p className="text-center mt-2 quicklyConnect">
                                        Contact us now to get details about your queries and feedback.
                                    </p>
                                </div>
                            </div>)
                        }
                    </div>
                    {isOpenNewPassKeyModal && <NewKeyPassModal isOpenNewPassKeyModal={isOpenNewPassKeyModal} onClose={() => setIsOpenNewPassKeyModal(false)} handleSubmit={handleSubmit} onFormDataChange={handleFormDataChange} setNewKeyPassData={setFormData} createKeyPassLoader={createKeyPassLoader}/>}
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    );
}
export default WithAuth(UserConfig);
