"use-strict";

import * as Actions from "../actions";

const QueuesDataReducer = (state = {}, action) => {
  if (action.type === Actions.SET_QUE_DATA) {
    state = {
        ...state,
        ...action.payload,
    };
  }

  return state;
};

export default QueuesDataReducer;
