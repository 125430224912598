import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Breadcrumb from "../../component/Breadcrumb";
import "../../styles/nomineeDetails.scss";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
const NomineeDetails = () => {
  const steps = ["Acount Details", "Nominee Details"];
  const navigate = useNavigate()
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth<=900);
  const handleResize = () => {
    if(window.innerWidth <= 900) {
      setIsMobileDevice(true);
    }
    else {
      setIsMobileDevice(false);
    }
  };
  useEffect(()=>{
    window.addEventListener('resize', handleResize);
  },[]);

  return (
    <div className={`flex dark:bg-dark-background updatePhone`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu headerName="Nominee Details"/>
      <div className={`min-h-screen flex-1 fixedHeightContainer ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
        <Header />
        <div className={`z-0 p-10 mainContainer nomineeDetails ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          <Breadcrumb
            steps={steps}
            activeStep={1}
            showBackButton={true}
            redirectUrl={"/InformationUpdateDashboard"}
          />
          <div class={`grid grid-cols-3 gap-4 mt-2 ${isMobileDevice && 'nomineeResponsiveContainer'}`}>
            <div className={`col-span-1 rounded rounded ${isMobileDevice ? 'pt-4' : 'p-4'}`}>
                <div className="transactionDetailContainer rounded">
                 <div className="rounded">
                    <div className="userDetails cardBackground rounded">
                        <img src="assets/images/businessman.png" className="userCapturedImage"></img>
                        <p className="userIdentity">Shantanu Saha</p>
                        <p className="userIdentity">Bangladesh</p>
                    </div>
                 </div>
                 <div>
                    <div className="flex justify-between">
                     <div className="userInfoCard">
                        <p>Today Date</p>
                        <p>06-MAR-24</p>
                     </div>
                     <div className="userInfoCard">
                        <p>IP Address</p>
                        <p>10.88.14.14</p>
                     </div>
                    </div>
                    <div className="flex justify-between lowerUserDetails">
                     <div className="userInfoCard rounded-bl">
                        <p>Last Login</p>
                        <p>06-MAR-24</p>
                     </div>
                     <div className="userInfoCard rounded-br">
                        <p>Total Login Today</p>
                        <p>5</p>
                     </div>
                    </div>
                 </div>
                </div>
            </div>
            <div className={`col-span-2 rounded flex flex-wrap ${isMobileDevice ? 'pt-4 justify-between' : 'p-4'}`}>
                <div className={`transactionDetailContainer nomineeCards rounded cursor-pointer ${isMobileDevice && 'nomineeResponsiveCard'}`} onClick={() => navigate("/nomineeQuickLinks")}>
                    <div>
                        <div className="customerAcquisition rounded mt-5">
                            <img src="assets/images/handShake.png" ></img>
                        </div>
                    </div>
                    <div className={`mt-10 ${isMobileDevice && 'px-3 mt-4'}`}><p className="word-break">Customer Acquisition</p></div>
                </div>
                <div className={`transactionDetailContainer nomineeCards ms-3 rounded cursor-pointer ${isMobileDevice && 'nomineeResponsiveCard'}`} onClick={() => navigate("/ConventionalAgentBankingCard")}>
                    <div>
                        <div className="customerAcquisition rounded mt-5">
                            <img src="assets/images/handShake.png" ></img>
                        </div>
                    </div>
                    <div className={`mt-10 ${isMobileDevice && 'px-3 mt-4'} word-break`}><p>Conventional Agent Banking</p></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NomineeDetails;
