import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import API from "../helpers/api/index";
import Select from 'react-select';
import Icon from "./core-component/Icon";
import { ColorRing } from 'react-loader-spinner';
import { encodeData, handleErrorMessage } from "../common/commonFunction";

export default function UpdatePasswordModal({closeModal}) {
    const initialData = {
        existing_password: '',
        new_password: '',
        retype_password: ''
    };
    const [showPassword, setShowPassword] = useState({
        existing_password: false,
        new_password: false,
        retype_password: false,
    });
    const [showLoading, setShowLoading] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    
    const fields = [
        { label: 'Current Password', id: 'existing_password', name: 'existing_password', type: 'password',placeholder: "Enter Current Password" },
        { label: 'New Password', id: 'new_password', name: 'new_password', type: 'password',placeholder: "Enter New Password" },
        { label: 'Confirm Password', id: 'retype_password', name: 'retype_password', type: 'password',placeholder: "Confirm Password" },
    ];
    const handleChange = (e) => {
        let name;
        let value;
        name = !e.target ? 'role_id' : e.target.name;
        value =  !e.target ? e : e.target.value
        setFormData({
            ...formData,
            [name]: value,
        });

        // Remove error message when user starts typing in the current field
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        setShowLoading(true)
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            let payload = {
                oldPassword: formData.existing_password,
                password: formData.new_password
            }
            const encodedPayload = encodeData(payload);
            try {
                const { data } = await API.apiPost(`/users/change/password`, {payload: encodedPayload})
                if (data?.success) {
                    toast.success(data?.message);
                    setShowLoading(false)
                    setTimeout(() => {
                        closeModal(false);
                    }, 1000);
                }
                else {
                    toast.error(data?.message)
                    setShowLoading(false)
                }
            }
            catch (error) {
                handleErrorMessage(error);
                setShowLoading(false)
            }
        } else {
            setErrors(errors);
            setShowLoading(false)
        }
    };

    const checkPassword = (fieldValue) => {
        // Check length
        if (fieldValue?.length < 8) {
            return "Password length should be at least 8 characters.";
        }
    
        // Check for at least one uppercase letter
        if (!/[A-Z]/.test(fieldValue)) {
            return "Password should contain at least one uppercase letter.";
        }
    
        // Check for at least one lowercase letter
        if (!/[a-z]/.test(fieldValue)) {
           return "Password should contain at least one lowercase letter.";
        }
    
        // Check for at least one digit
        if (!/\d/.test(fieldValue)) {
            return "Password should contain at least one digit.";
        }
        // // If all conditions are met, set success message
        // setValidationResult("Password meets all criteria.");
      };

      const validatePassword = (password) => {
        let messages;
    
        // Check length
        if (password.length < 8) {
            messages = 'Password must be at least 8 characters long';
        }
    
        // Check for uppercase letter
        else if (!/[A-Z]/.test(password)) {
            messages = 'Password must contain at least one uppercase letter';
        }
    
        // Check for lowercase letter
        else if (!/[a-z]/.test(password)) {
            messages = 'Password must contain at least one lowercase letter';
        }
    
        // Check for digit
        else if (!/\d/.test(password)) {
            messages = 'Password must contain at least one digit';
        }
    
        // Check for special character
        else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            messages = 'Password must contain at least one special character';
        }
    
        return messages;
    }

    const validateForm = (data) => {
        setErrors({});
        let errors = {};
        const validationRules = {
            existing_password: {name:'existing_password', required: true, pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, minLength: 8, message: 'Password must be at least 8 characters long' },
            new_password: {name:'new_password', required: true, pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, minLength: 8, message: 'Password must be at least 8 characters long' },
            retype_password: {name:'retype_password', required: true, pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/, minLength: 8, message: 'Password must be at least 8 characters long' },
        };

        Object.keys(validationRules).forEach(fieldName => {
            const rule = validationRules[fieldName];
            const fieldValue = data[fieldName];
            // Check if field is required and not empty
            if (rule.required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))) {
                errors[fieldName] = 'This field is required.'
            } else if (rule.pattern) {
                if(rule?.name)
                {
                    let errorString = validatePassword(fieldValue);
                    if(errorString)
                    {
                        errors[fieldName] = errorString;
                    }
                }
                else{
                // Check if field value matches the pattern (for both single and multi-select fields)
                if (Array.isArray(fieldValue)) {
                    if (!fieldValue.every(value => rule.pattern.test(value))) {
                        errors[fieldName] = rule.message;
                    }
                } else if (!rule.pattern.test(fieldValue)) {
                    errors[fieldName] = rule.message;
                }
            }
            }
            if (fieldValue) {
                if(data?.new_password !== data?.retype_password) {
                    errors["retype_password"] = "Password does not match";
                }
                // if(data?.existing_password === data?.new_password) {
                //     errors["new_password"] = "New password cannot be same as old password";
                // }
            }
        });

        return errors;
    };

    const togglePasswordVisibility = (name) => {
        setShowPassword({...showPassword, [name]: !showPassword[name]});
    };
   

    return (
        <>
            {/* <!-- Main modal --> */}
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">

                    {showLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
                            <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                            />
                    </div>}
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                    
                       
                           
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Update Password
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={()=>closeModal(false)}>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="p-4 md:p-5 space-y-4">
                            <div className="">
                                {fields.map((field) => (
                                    <div key={field.id} className="mb-4">
                                        <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">
                                            {field.label}
                                        </label>
                                        <div className="relative flex">
                                          <input
                                            type={showPassword?.[field.name] ? 'text' : 'password'}
                                            id={field.id}
                                            name={field.name}
                                            value={formData[field.name]}
                                            placeholder={field.placeholder}
                                            onChange={handleChange}
                                            className={`mt-1 p-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md ${errors[field.name] ? 'border-red-500' : ''}`}
                                         />
                                         <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility(field?.name)}
                                            className=" bi bi-eye absolute right-4 top-4 focus:outline-none pass_eye"
                                        >
                                            {showPassword?.[field.name] ? (
                                                <Icon iconName="EyeAlt" height={16} width={16}/>
                                                ) : (
                                                <Icon iconName="EyeAlt" height={16} width={16} className="absolute eyeIcon"/>
                                                )}
                                        </button>
                                        </div> 
                                        {errors[field.name] && <p className="text-red-500 text-sm mt-1">{errors[field.name]}</p>}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 custom_btn_secondary" onClick={()=>closeModal(false)}>Cancel</button>
                            <button data-modal-hide="static-modal" type="button" class="ms-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn custom_btn_primary" onClick={handleSubmit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    )
}
