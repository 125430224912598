import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import { WithAuth } from "../../../common/WithAuth";
import Breadcrumb from "../../../component/Breadcrumb";
import { useSocket } from "../../../helpers/socketProvider";
import "../../../styles/humanVideoKyc.scss";

const GoLive = () => {
  const [presentDate, setPresentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const steps = ["Services", "In-Person Verification", "Go Live"];
  const navigate = useNavigate();
  const socket = useSocket();

  const updateDateTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    setPresentDate(`${day}-${month}-${year}`);
    setCurrentTime(strTime);
    return strTime;
  };

  useEffect(() => {
    updateDateTime(); // Set initial date and time

    const intervalId = setInterval(updateDateTime, 1000); // Update date and time every second

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const markAgentLive = () => {
    let agentData = sessionStorage.getItem('userData');
    agentData = JSON.parse(agentData);
    if (agentData) {
      socket.emit("user:agentJoin", { email: agentData?.email});
    }
  }

  const handleAgentJoined = useCallback((data) => {
    let agentData = sessionStorage.getItem('userData');
    agentData = JSON.parse(agentData);
    navigate(`/room`, { state: { id: data.id, role: 'agent', room: data.room, agentEmail: agentData?.email, markedInTime: updateDateTime() } });
  }, [navigate]);

  useEffect(() => {
    socket.on("user:joined", handleAgentJoined);
    return () => {
      socket.off("user:joined", handleAgentJoined);
    };
  }, [socket, handleAgentJoined]);

  return (
    <div className={`flex dark:bg-dark-background updatePhone mainBodyContainer`}>
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <div className="z-0 p-10 mainContainer fixedHeightContainer">
          <Breadcrumb
            steps={steps}
            activeStep={2}
            showBackButton={true}
            redirectUrl={"/humankyclist"}
          />
          <div className="">
            <div className="userReadyForVerification rounded p-4">
              <div className="flex mb-4 dateTimeContainer rounded p-2">
                <div className="w-1/2 dateTime text-center">{currentTime}</div>
                <div className="w-1/2 dateTime text-center">{presentDate}</div>
              </div>
              <button type="button" className="border flex w-full justify-around items-center readyButton" onClick={() => markAgentLive()}>
                <img src="assets/images/iconUserReady.png" className="UserReady" alt="User Ready" />
                Ready
                <img src="assets/images/readyDoubleArrow.png" className="goLiveDoubleArrow" alt="Go Live" />
              </button>
            </div>
            <div className="mt-5">
              <img src="assets/images/humanVideoGoLive.svg" className="humanVideoGoLive" alt="Go Live" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(GoLive);