import React, { useEffect, useRef, useState } from "react";
// import Icon from "./core-component/Icon";
import { Dropdown } from "./core-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import * as Actions from "../redux/actions";
import WebStorage from "../common/webStorage";
import { USER_DATA_KEY } from "../common/webStorageKeys";
import API from "../helpers/api";
import { toast } from "react-toastify";
import Gravatar from 'react-gravatar';
import { getDecodedToken } from "../helpers/auth";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { handleErrorMessage } from "../common/commonFunction";
import { getCurrentUserMenus } from "../common/menuItems";

const Header = ({headerName}) => {
  // const [darkModeTheme, setDarkModeTheme] = useState(true);
  var redux_dispatch = useDispatch();
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth<=900);

  var navigate = useNavigate();
  // const userData = localStorage.getItem("USER_DATA_KEY") ? JSON.parse(localStorage.getItem("USER_DATA_KEY")) : ""
  const [userData] = useSelector((Gstate) => [Gstate?.UserDataReducer?.user_data])
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const [showNavToggle, setShowNavToggle] = useState(false)
  const [currentMenus,setCurrentMenus] = useState([])
  const dropDownToggleRef = useRef(null);
  const location = useLocation();
  const currentPath = "/" + location.pathname.split("/")[1];


  useEffect(() => {
    const menuList = getCurrentUserMenus();
    setCurrentMenus(menuList);
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if(window.innerWidth <= 900) {
      setIsMobileDevice(true);
    }
    else {
      setIsMobileDevice(false);
    }
  };

  const handleMenuChilds = (menuItem) => {
    let currentMenuList = [...currentMenus]
    currentMenuList = currentMenuList?.map(item => {
      if (menuItem?.title == item?.title) {
        item.showChild = !item?.showChild
      }
      return item
    })
    setCurrentMenus(currentMenuList);
  }  

  const handleTabChange = (Menu) => {
    localStorage.setItem("activeTab", Menu?.title)
  }

  function handleLogout() {
    redux_dispatch({
      type: Actions.UPDATE_USER_DATA,
      payload: { user_data: null },
    });
    WebStorage.clearDataFromWebStorage(USER_DATA_KEY);
    navigate("/");
    console.log("logout");
  }

  const storeUserData = (data) => {
    redux_dispatch({
      type: Actions.UPDATE_USER_DATA,
      payload: { user_data: { ...data } },
    });
  }
  const getUserDetails = async () => {
    try {
      const { user_id } = getDecodedToken()
      const response = await API.apiGet(`/users/profile?user_id=${user_id}`)
      if (response?.status) {
        const userProfile = response?.data?.data;
        storeUserData(userProfile)
        localStorage.setItem('perm', JSON.stringify(userProfile?.Permissions))
        sessionStorage.setItem('userData', JSON.stringify(userProfile));
      }
      else {
        toast.error(response?.message)
      }
    }
    catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message)
    }
  }
  const openDropdown = () => {
    setToggleDropdown(!toggleDropdown)
  }

  const onClickUpdatePassword = () => {
    setShowUpdatePasswordModal(true)
  }

  const handleToggle = () => {
    setShowNavToggle(!showNavToggle);
  }

  useEffect(() => {
    if(!sessionStorage?.userData) getUserDetails();
    else storeUserData(JSON.parse(sessionStorage?.userData));
  }, [])

  return (
    <>
      {!isMobileDevice && (
        <div className="headerBar flex items-center justify-end px-8 py-4 border-opacity-[42%] z-40 dark:bg-dark-background bg-white border-dark-border">
          <div className="flex items-center">
            <div className="mr-4">
              {/* <ToggleSwitch checked={darkModeTheme} onChange={toggleTheme} /> */}
            </div>
            {/* <div className="mr-4">
              <Icon iconName={"Bell"} height={12} width={12} />
            </div> */}
            <div className="mr-4">
              {/* <Image
                source={"/assets/images/userImage.png"}
                height={48}
                width={48}
                alt="user image"
              /> */}
              {userData?.email ? <Gravatar email={userData?.email} style={{ borderRadius: "50%" }} onClick={openDropdown} className="cursor-pointer" />
                : <div className="border border-solid border-gray-border flex items-center justify-center " style={{ height: "48px", width: "48px", borderRadius: "50%" }} onClick={openDropdown}>{userData?.first_name?.[0]?.toUpperCase() + userData?.last_name?.[0]?.toUpperCase()}</div>}
            </div>
            <div>
              <p className="text-gray-700 font-lexend text-base font-medium leading-6d cursor-pointer" ref={dropDownToggleRef} onClick={openDropdown}>
                {(userData?.first_name && userData?.last_name) ? (userData?.first_name + " " + userData?.last_name)?.length > 15 ? (userData?.first_name + " " + userData?.last_name).substring(0, 15) + "..." : userData?.first_name + " " + userData?.last_name : ''}
              </p>
            </div>
            <div>
              <Dropdown
                iconName={"AngleDown"}
                openDropdown={toggleDropdown}
                setToggleDropdown={setToggleDropdown}
                dropDownToggleRef = {dropDownToggleRef}
                onClickLogout={() => handleLogout()}
                onClickUpdatePassword={onClickUpdatePassword}
                setShowModal={setShowUpdatePasswordModal}
              />
              {showUpdatePasswordModal && <UpdatePasswordModal closeModal={setShowUpdatePasswordModal} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
