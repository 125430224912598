import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import styles from "../../../styles/userEdit.module.scss"
import API from '../../../helpers/api';
import { ColorRing } from "react-loader-spinner";
import { encodeData, handleErrorMessage, handleValidNumberInput } from "../../../common/commonFunction";
import { DatePicker } from "antd";
import moment from "moment";

const initialRowData = {
    particulars: '',
    amount: '',
    amountType: '',
    remarks: '',
    existing_or_new_transaction: 'New',
    created_at: moment()
}

export default function AddNewRowModal(props) {
    const {
        setShowModal, 
        onDataChange, 
        setListLoading,
        setPortfolioData,
        recordsPerPage,
        currentPage,
        setNpages
    } = props

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const portfolio_id = params.get('id');
    const portfolioHeaders = ['Date', 'Particulars', 'Debit', 'Credit', 'Balance', 'Initial', 'Remarks']
    const [newRowData, setNewRowData] = useState(initialRowData)
    const [error, setError] = useState({});
    const amountType = [
        { label: 'Debit', value: 'debit' },
        { label: 'Credit', value: 'credit' }
    ];
    const [addLoader, setAddLoader] = useState(false)

    const closeModal = () => {
        setShowModal(false)
        setNewRowData(initialRowData)
    }

    const handleNewRowData = (event, dateField) => {
        if (dateField) {
            setNewRowData((prevState) => ({
                ...prevState,
                created_at: event,
            }));
        } else {
            let { name, value } = event?.target;
            if (name == 'existing_or_new_transaction') {
                setNewRowData((prevState) => ({
                    ...prevState,
                    created_at: value == 'New' ? moment() : '',
                }));
            }
            if (name === "amount") {
                value = Math.abs(value);
                setNewRowData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
            else {
                setNewRowData((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
            setError((prevState) => ({
                ...prevState,
                [name]: '',
            }));
        }
    }

    const handleAmountType = (event) => {
        setNewRowData((prevState) => ({
            ...prevState,
            amountType: event?.value
        }));
        setError((prevState) => ({
            ...prevState,
            "amountType": '',
        }));
    }

    const isAllFieldsValid = () => {
        const validationErrors = {};
        
        const fieldsToCheck = [
            { field: 'particulars', message: "Please enter particulars" },
            { field: 'amount', message: "Please enter amount" },
            { field: 'amountType', message: "Please select amount type" },
            { field: 'remarks', message: "Please enter remarks" },
            { field: 'created_at', message: "Please select date" },
        ];
    
        fieldsToCheck?.forEach(({ field, message }) => {
            if (field == 'amount' && parseFloat(newRowData[field]) == 0) {
                validationErrors[field] = 'Amount can not be zero'
            } else if (newRowData[field] == '') {
                validationErrors[field] = message;
            }
        });
    
        setError(validationErrors);
    
        return Object.keys(validationErrors)?.length === 0;
    };

    const addNewRow = async () => {
        if(isAllFieldsValid()) {
            let payload = {
                portfolio_id: portfolio_id,
                transaction_type: newRowData?.amountType,
                transaction_amount: newRowData?.amount,
                particulars: newRowData?.particulars,
                remark: newRowData?.remarks,
                existing_transaction: newRowData?.existing_or_new_transaction == 'New' ? false : true
            }
            if (newRowData?.existing_or_new_transaction == 'Existing') {
                payload.created_at = newRowData?.created_at
            }
            try {
                setAddLoader(true);
                const data = await API.apiPost('/loans/write/off/portfolio/ledger', {payload: encodeData(payload)})
                if(data?.data?.success) {
                    toast.success(data?.data?.message)
                    setShowModal(false)
                    getPortfolioDetails(1);
                    setAddLoader(false);
                }
            } catch (err) {
                handleErrorMessage(err);
                // toast.error(err?.response?.data?.message)
                setAddLoader(false);
            }
        }
    };

    const getPortfolioDetails = async () => {
        let id = parseInt(portfolio_id)
        try {
            setListLoading(true);
            const data = await API.apiGet(`loans/write/off/portfolio/ledger/${id}?limit=${recordsPerPage}&skip=${currentPage}`)
            if(data?.data?.success) {
                setPortfolioData([]);
                onDataChange(data?.data?.data);
                setNpages(data?.data?.totalPageCount);
                setListLoading(false);
            }
        } catch (error) {
            handleErrorMessage(error);
            setListLoading(false);
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));
    
        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const getCurrentDate = (current ) => {
        return current && current > moment().endOf('day')
      };  

    return (
        <>
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div className={`relative p-4 w-full max-w-2xl max-h-full`} style={addLoader ? { filter: 'blur(3px)', pointerEvents: 'none' } : null}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Add New Entry
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {addLoader &&
                            <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                                <ColorRing
                                visible={true}
                                height="80"
                                width="60"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={[]}
                                />
                            </div>
                        }
                        <div className="p-4 md:p-5 space-y-4">
                            <div className="flex justify-between w-100">
                                <div className="mb-4 w-full ">
                                    <label htmlFor="particulars" className="block text-sm font-medium text-gray-700">
                                        Particulars
                                    </label>
                                    <input
                                        type="text"
                                        name="particulars"
                                        placeholder="Particulars"
                                        onChange={handleNewRowData}
                                        className="border px-4 py-2 mt-2 w-full rounded-lg focus:outline-none focus:border-blue-500"
                                    />
                                    {error?.particulars && <p className="text-red-500 text-sm mt-1">{error?.particulars}</p>}
                                </div>
                            </div>
                            <div className="flex justify-between w-100">
                                <div className="mb-4 w-full mr-2">
                                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                        Amount
                                    </label>
                                    <input
                                        type="number"
                                        name="amount"
                                        placeholder="Amount"
                                        min="0"
                                        onPaste={preventPasteNegative}
                                        onKeyDown={handleValidNumberInput}
                                        onChange={handleNewRowData}
                                        className="border px-4 py-2 mt-2 w-full rounded-lg focus:outline-none focus:border-blue-500"
                                    />
                                    {error?.amount && <p className="text-red-500 text-sm mt-1">{error?.amount}</p>}
                                </div>
                                <div className="mb-4 w-full ml-2">
                                    <label htmlFor="amountType" className="block text-sm font-medium text-gray-700">
                                        Type
                                    </label>
                                    <Select
                                        name="amountType"
                                        options={amountType}
                                        className={`py-2 rounded-lg focus:outline-none focus:border-blue-500 ${styles.typeDropdown}`}
                                        classNamePrefix="select"
                                        onChange={handleAmountType}
                                    />
                                    {error?.amountType && <p className="text-red-500 text-sm mt-1">{error?.amountType}</p>}
                                </div>
                            </div>
                            <div className="">
                                <div className={`flex justify-start`}>
                                    <div className={`flex`}>
                                        <input
                                            id="existing_tran"
                                            type="radio"
                                            value='Existing'
                                            onChange={(e) => handleNewRowData(e)}
                                            name='existing_or_new_transaction'
                                            className={`me-2`}
                                            checked={newRowData?.existing_or_new_transaction == 'Existing'}
                                        />
                                        <label htmlFor="existing_tran" className={`cursor-pointer font-medium`} >Existing Transaction</label>
                                    </div>

                                    <div className={`flex ms-10`}>
                                        <input
                                            id="new_tran"
                                            type="radio"
                                            value='New'
                                            onChange={(e) => handleNewRowData(e)}
                                            name='existing_or_new_transaction'
                                            className={`me-2`}
                                            checked={newRowData?.existing_or_new_transaction == 'New'}
                                        />
                                        <label htmlFor="new_tran" className={`cursor-pointer font-medium`} >New Transaction</label>
                                    </div>

                                </div>
                                <div className={`flex flex-col mt-5`}>
                                    <label className={`text-sm mb-1 ms-1`}>Created On</label>
                                    <DatePicker
                                        id="createdAt"
                                        name="created_at"
                                        value={newRowData?.created_at}
                                        onChange={(e) => handleNewRowData(e, true)}
                                        disabledDate={getCurrentDate}
                                        placeholder="Select Date"
                                        className={`py-1 rounded outline-none ps-2`}
                                        disabled={newRowData?.existing_or_new_transaction == 'New'}
                                    />
                                    {error?.created_at && <p className="text-red-500 text-sm mt-1">{error?.created_at}</p>}
                                </div>
                            </div>
                            <div className="flex justify-between w-100">
                                <div className="mb-4 w-full">
                                    <label htmlFor="remarks" className="block text-sm font-medium text-gray-700">
                                        Remarks
                                    </label>
                                    <textarea
                                        name="remarks"
                                        placeholder="Remarks"
                                        onChange={handleNewRowData}
                                        className="border px-4 py-2 mt-2 w-full rounded-lg focus:outline-none focus:border-blue-500"
                                    />
                                    {error?.remarks && <p className="text-red-500 text-sm mt-1">{error?.remarks}</p>}
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" className={`custom_btn_secondary py-2.5 h-10 px-4 me-3 rounded text-center flex items-center ${addLoader ? `${styles.disableAddBtn} disableCancleButton` : 'commonCancelButton'}`} onClick={closeModal} disabled={addLoader}>Cancel</button>
                            <button data-modal-hide="static-modal" type="button" className={`text-white h-10 flex items-center rounded px-7 py-2.5 text-center custom_btn_primary commonBtn ${addLoader && styles.disableAddBtn}`} onClick={addNewRow} disabled={addLoader}>
                                <div className="flex items-center">
                                    Add Entry
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}
