// Info: Configuration file (Errors)
"use strict";

module.exports = {
  // Export configration as key-value Set

  DATABASE_WRITE_FAILED: {
    code: "BRAND_DATABASE_WRITE_FAILED",
    message: "Faied to write into Brand database",
  },

  EMAIL_REQUIRED: {
    code: "EMAIL_REQUIRED",
    message: "Please enter a valid Email",
  },
};
