import {React, useState, useEffect} from 'react'
import { Slider } from 'material-ui-slider';
import { Doughnut, Pie, Bar, Line, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import "../../styles/consumerFinance.scss";

export default function (props) {
    const { 
        isMobileDevice
    } = props

    const [loanAmount, setLoanAmount] = useState(1000);
    const [interestRate, setInterestRate] = useState(1);
    const [loanTenure, setLoanTenure] = useState(10);
    const [chartData, setChartData] = useState(null);
    const [chartType, setChartType] = useState('doughnut');
  
    const calculateEMI = () => {
      const principalAmount = loanAmount;
      const rateOfInterest = interestRate / 100 / 12;
      const tenureInMonths = loanTenure * 12;
      const emi = (principalAmount * rateOfInterest * Math.pow(1 + rateOfInterest, tenureInMonths)) / (Math.pow(1 + rateOfInterest, tenureInMonths) - 1);
      const totalInterest = emi * tenureInMonths - principalAmount;
      const totalAmount = principalAmount + totalInterest;
  
      return {
        emi: emi ? parseFloat(emi)?.toFixed(2) : null,
        totalInterest: totalInterest ? parseFloat(totalInterest)?.toFixed(2) : null,
        totalAmount: totalAmount ? parseFloat(totalAmount)?.toFixed(2) : null,
      };
    };
  
    const { emi, totalInterest, totalAmount } = calculateEMI();

    const getEmiAmounts = (item, inputType, event) => {
        let value = inputType == 'input' ? event?.target?.value ? parseFloat(event?.target?.value) : null : event ? parseFloat(event) : null 
        if(item == 'loan') setLoanAmount(value);
        if(item == 'interest') {
            if (value <= 100) {
                setInterestRate(value);
            }
        }
        if(item == 'tenure') setLoanTenure(value);
    }

    
    useEffect(() => {
        const principal = parseFloat(loanAmount);
        const interest = parseFloat(totalInterest);
        const chartData = {
            labels: ['Principal Amount', 'Interest Amount'],
            datasets: [{
                data: [principal, interest],
                backgroundColor: ['#02839F', '#ddecf0'], // Set colors for principal and interest
                hoverOffset: 4,
            }],
        };
        setChartData(chartData);
    }, [loanAmount, totalInterest]);

    const renderChart = () => {
        switch (chartType) {
            case 'doughnut':
                return chartData && <Doughnut data={chartData} />;
            case 'pie':
                return chartData && <Pie data={chartData} />;
            case 'bar': 
                // return chartData && <Bar data={chartData} />;
            case 'line': 
                // return chartData && <Line data={chartData} />;
            case 'polarArea': 
                return chartData && <PolarArea data={chartData} />;
            case 'radar': 
                // return chartData && <Radar data={chartData} />;
            // case 'scatter': 
            //     return chartData && <Scatter data={chartData} />;
            // case 'bubble': 
            //     return chartData && <Bubble data={chartData} />;
            default:
                return null;
        }
    };

  return (
    <div className={`mt-10 p-5 bg-white rounded-xl ${isMobileDevice ? "flex-col" :"flex"} `}>
        <div className={`calculationMainContainer ${isMobileDevice ? "w-full" : "w-1/2"} `}>
            <div className='mt-3'>
                <div className='flex justify-between'>
                    <label>Loan amount</label>
                    <input 
                        type='number'
                        value={loanAmount}
                        className='amountContainer me-5'
                        onChange={(e) => getEmiAmounts('loan', 'input', e)}
                    />
                </div>
                <Slider 
                defaultValue={1000} 
                value={loanAmount}
                aria-label="Default" 
                valueLabelDisplay="auto" 
                color="#02839F"
                min={1000}
                max={loanAmount + 100000}
                onChange={(event) => getEmiAmounts('loan', 'slider', event)}
                className="emiSliderContainer"
                />
            </div>
            <div className='mt-5'>
                <div className='flex justify-between'>
                    <label>Rate of Interest (p.a)</label>
                    <div>
                    <input 
                        type='number'
                        value={interestRate}
                        className='amountContainer me-2'
                        onChange={(e) => getEmiAmounts('interest', 'input', e)}
                    />
                    %
                    </div>
                </div>
                <Slider 
                defaultValue={1} 
                value={interestRate}
                aria-label="Default" 
                valueLabelDisplay="auto" 
                color="#02839F"
                min={1}
                max={100}
                decimals={1}
                onChange={(event) => getEmiAmounts('interest', 'slider', event)}
                className="emiSliderContainer"
                />
            </div>
            <div className='mt-5'>
                <div className='flex justify-between'>
                    <label>Loan tenure</label>
                    <input 
                        type='number'
                        value={loanTenure}
                        className='amountContainer me-5'
                        onChange={(e) => getEmiAmounts('tenure', 'input', e)}
                    />
                </div>
                <Slider
                value={loanTenure} 
                defaultValue={10} 
                aria-label="Default" 
                valueLabelDisplay="auto" 
                color="#02839F"
                min={1}
                max={50}
                onChange={(event) => getEmiAmounts('tenure', 'slider', event)}
                className="emiSliderContainer"
                />
            </div>
            <div className=''>
                <div className='flex justify-between mt-5'>
                    <label>Monthly EMI</label>
                    <span>{emi || 0}</span>
                </div>
                <div className='flex justify-between mt-4'>
                    <label>Principal amount</label>
                    <span>{loanAmount || 0}</span>
                </div>
                <div className='flex justify-between mt-4'>
                    <label>Total interest</label>
                    <span>{totalInterest || 0}</span>
                </div>
                <div className='flex justify-between mt-4'>
                    <label>Total amount</label>
                    <span>{totalAmount || 0}</span>
                </div>
            </div>
        </div>
        <div className={`calculatorGraphContainer  ${isMobileDevice ? "w-full mt-4" : "w-1/2"} flex justify-center`}>
            <div className='emiChartContainer' style={isMobileDevice ? { height: '300px' } :{ height: '460px' } }>
                {chartData && <Doughnut data={chartData} />}
                {/* <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
                    <option value="doughnut">Doughnut Chart</option>
                    <option value="pie">Pie Chart</option>
                    <option value="bar">Bar Chart</option>
                    <option value="line">Line Chart</option>
                    <option value="polarArea">PolarArea Chart</option>
                    <option value="radar">Radar Chart</option>
                    <option value="scatter">Scatter Chart</option>
                    <option value="bubble">Bubble Chart</option>
                </select>
                {renderChart()} */}
            </div>
        </div>
    </div>
  )
}
