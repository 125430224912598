import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import IVRTable from "../../component/IVRTableComponent";
import IVRMain from "../../component/IVRMain";
import IVRCalling from "../../component/IVRCallingComponent";
import IVRProcessComponent from "../../component/IVRProgressList"
import '../../styles/dashboard.scss';
import styles from '../../styles/services.module.scss'
import VideoKYCCard from "../../component/VideoKYCCard";
import HumanKYCCard from "../../component/HumanKYCCard";
import { getCurrentProcessPerm } from "../../helpers/auth";
const ServicesComponent = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth<=900);
  const handleResize = () => {
    if(window.innerWidth <= 900) {
      setIsMobileDevice(true);
    } 
    else {
      setIsMobileDevice(false);
    }
  };
  useEffect(()=>{
    window.addEventListener('resize', handleResize);
  },[]);

  
    
  useEffect(()=>{
    localStorage.setItem("activeTab", "Services");
  },[])

  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer`}>
      <SideMenu headerName="Service List"/>
      <div className={`h-screen flex-1 overflow-y-auto ${isMobileDevice && 'overflow-y-auto mainContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${isMobileDevice &&  'flex justify-center  p-5  responsiveMainContainer'}`}>
          {!isMobileDevice && <div className=" flex flex-row justify-between">
            <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
              Services
            </h1>
          </div>}
          <div className={`flex flex-wrap gap-2 justify-start ${styles.cardsContainer} ${isMobileDevice && 'flex gap-5 w-4/5' }`}>
          <IVRMain/>
          <VideoKYCCard/>
          {getCurrentProcessPerm(18)?.write && <HumanKYCCard/>}
          {/* <IVRCalling/> */}
          {/* <IVRProcessComponent /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(ServicesComponent);
