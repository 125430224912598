"use-strict";

export const UPDATE_APP_DATA = "UPDATE_APP_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_QUE_DATA = "SET_QUE_DATA";
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_USER_THEME = 'SET_USER_THEME';
export const REQUST_TEHME_DATA = 'REQUST_TEHME_DATA';
export const GET_TEHME_DATA = 'GET_TEHME_DATA';

//
export const OPENING_MODAL = 'OPENING_MODAL';
export const CLOSING_MODAL = 'CLOSING_MODAL';

export const openingModal = () => ({
  type: OPENING_MODAL,
});

export const closingModal = () => ({
  type: CLOSING_MODAL,
});