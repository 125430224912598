import React from 'react'
import { WithAuth } from '../../../common/WithAuth';
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import { useState } from 'react';
 const IVRConfiguration = () => {

    const [values, setValues] = useState({
        workflowName: '',
        welcomeMessage: '',
        response: '',
        responses: {}
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
          ...prevValues,
          [name]: value
        }));
      };

      const handleResponseChange = (event) => {
        const { name, value } = event.target;
        const { response, responses } = values;
        const updatedResponses = {
          ...responses,
        };
    
        setValues(prevValues => ({
          ...prevValues,
          [name]: value,
          responses: updatedResponses
        }));
      };

      const handleResponseInput = (event) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
          ...prevValues,
          responses: {
            ...prevValues.responses,
            [name]: value
          }
        }));
      };
     
    const handleSubmit = () =>{
        // console.log("values3333",values)
    }
  return (
    <>
      <div className={`flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part`}>
        <SideMenu />
        <div className="flex-1">
          <Header />
          <div className='min-h-screen flex-1 overflow-hidden'>
          <div className="z-0 container p-10 ">
            <form>

                <div className="space-y-12">
                    <div className="border-gray-900/10 pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-y-8">
                            <div>
                                <label htmlFor="workflowName" className="block text-sm font-medium font-semibold leading-6 text-gray-900">
                                    Workflow name
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        name="workflowName"
                                        placeholder='Workflow name'
                                        value={values.workflowName}
                                        id="workflowName"
                                        onChange={handleInputChange}
                                        className="block w-3/4 rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="welcomeMessage" className="block text-sm font-medium font-semibold leading-6 text-gray-900">
                                    Welcome Message
                                </label>
                                <div>
                                    <textarea
                                        name="welcomeMessage"
                                        id="welcomeMessage"
                                        placeholder='Welcome Message...'
                                        onChange={handleInputChange}
                                        className="block w-3/4 h-28 rounded-md border-0 ps-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 p-1 outline-none"
                                    ></textarea>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="response" className="block text-sm font-medium font-semibold leading-6 text-gray-900">
                                    Response
                                </label>
                                <div>
                                    <select
                                        id="response"
                                        name="response"
                                        onChange={handleResponseChange}
                                        className="block w-full rounded-md border-0 py-2.5 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6 outline-none"
                                    >
                                        <option className='hidden'>Select Response</option>    
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>0</option>
                                        <option>*</option>
                                        <option>#</option>
                                    </select>
                                </div>
                            </div>
                            {values.response && (
                                <div>
                                <label htmlFor="message" className="block text-sm font-medium font-semibold leading-6 text-gray-900">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name={`message_for_${values.response}`} 
                                    value={values.responses[`message_for_${values.response}`] || ''}
                                    onChange={handleResponseInput}
                                    placeholder='Response Message...'
                                    className="block w-3/4 h-28 rounded-md border-0 ps-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 outline-none"
                                />
                                </div>
                            )}
                        </div>
                    </div>
                </div>


                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                    </button>
                    <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSubmit}
                    >
                    Save
                    </button>
                </div>

            </form>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default WithAuth(IVRConfiguration);