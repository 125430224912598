import SideMenu from "../../component/SideMenu";
import Navbar from "../../common/Navbar";
import Header from "../../component/Header";
import styles from "../../styles/informationUpdate.module.scss";
import Breadcrumb from "../../component/Breadcrumb";

export default function ConventionalAgentBankingCard() {

   
    return (
        <>
            <div className={`flex dark:bg-dark-background dashboard-container dashboard-main`}>
                <SideMenu />
                <div className="flex-1 overflow-hidden">
                    <Header />
                    <Navbar />
                    <div className="z-0 p-10 mt-6">
                        <Breadcrumb
                            showBackButton={true}
                            redirectUrl={"/nomineeDetails"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}