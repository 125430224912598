import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./core-component/Icon";


export default function HumanKYCCard() {

    let navigate = useNavigate();
    const isMobileDevice = window.screen.width <= 900;

    return (
        <>
            {/* Card 1 */}
            <div className={`samdProcessCards bg-white hover:cursor-pointer commonCard ${!isMobileDevice ? 'samdProcessCards mx-4 ' : 'serviceMainCard_responsive'}`} onClick={() => navigate("/humankyclist")}>
                <div className="flex items-center justify-between px-4 py-2 writeOfCard commonCardHeader">
                    <div>
                        <Icon iconName="userAccount" width="16" height="16" />
                    </div>
                    <span className="font-bold text-base">
                        In-Person Verification 
                    </span>
                    <div onClick={() => navigate("/humankyclist")} className="cursor-pointer arrowIcon commonCardIcons">
                        <img src="assets/right-arrow.svg" alt="file" />
                    </div>
                </div>
                <div>
                    <p className="p-4 text-sm">
                        This innovative method involves conducting a live, face-to-face video interview with the customer, during which they must present their identification documents for verification. The process allows banks to compare the customer's live image with the photograph on their ID.
                    </p>
                    <div className="float-end px-4 mt-5 pb-3 text-sm">
                        <p>
                            Created on 18 June, 2024
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 1 */}
        </>
    );
}
