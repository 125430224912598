import React, { useEffect, useState } from "react";
import { Flex, Input, Row, Col, Card, Upload, Checkbox, Collapse } from "antd";
import moment from "moment";
import * as Actions from "../../redux/actions";
import ImgCrop from 'antd-img-crop';
import { encodeData, handleErrorMessage } from '../../common/commonFunction';
import { defaultTheme } from "../../theme/defultTheme";
import API from "../../helpers/api";
import { useDispatch, useSelector } from "react-redux";
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { ToastContainer, toast } from "react-toastify";
import styles from '../../styles/theme.module.scss';
import Pagination from "../../component/Pagination";
import { ColorRing } from 'react-loader-spinner';


const data = [{
    name: 'John Deo',
    ticketsProgress: '+10%',
    age: '24',
    percent: '40'
},
{
    name: 'Gaurav bagouli',
    ticketsProgress: '+10%',
    age: '25',
    percent: '90'
},
{
    name: 'Neeraj Verma',
    ticketsProgress: '+10%',
    age: '25',
    percent: '70'
},
{
    name: 'Mahesh ',
    ticketsProgress: '+10%',
    age: '28',
    percent: '66'
},]

const LogoAndThemeComponent = () => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [defaultCheck, setDefaultCheck] = useState(false);
    const [themeLoading, setThemeLoading] = useState(false);
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [selectedColors, setSelectedColors] = useState(themeDetails?.themeDetails ? themeDetails?.themeDetails : themeDetails);

    useEffect(() => {
        getThemeDeatils();
    }, []);

    useEffect(() => {
        if (!themeLoading) {
            setSelectedColors(themeDetails?.themeDetails ? themeDetails?.themeDetails : themeDetails)
        }
    }, [themeLoading])

    const getlogo = async(themeDetails) => {
        try {
            if (themeDetails?.logo) {
                const { data } = await API.apiGet(`/theme/logo?logoURL=${themeDetails?.logo}&theme_id=${selectedColors?.id}`);
                if (data.success) {
                    dispatch({
                        type: Actions.SET_USER_THEME,
                        payload: { themeDetails: { ...themeDetails, logo: data.data } },
                    });
                    dispatch({type: Actions.GET_TEHME_DATA});
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const getThemeDeatils = async() => {
        try {
            setThemeLoading(true);
            dispatch({type: Actions.REQUST_TEHME_DATA})
            const { data } = await API.apiGet(`/theme/details`);
            if (data?.success) {
                let themeDetails = data.data != null ? data.data : defaultTheme
                getlogo(themeDetails);
                setThemeLoading(false);
                dispatch({
                    type: Actions.SET_USER_THEME,
                    payload: { themeDetails: { ...themeDetails } },
                });
            } else {
                setThemeLoading(false);
                dispatch({
                    type: Actions.SET_USER_THEME,
                    payload: { themeDetails: { ...defaultTheme } },
                });
            }
        } catch (error) {
            setThemeLoading(false);
            console.log('error in get theme deatils >>>>', error)
        }
    }

    const saveSettings = () => {
        setThemeLoading(true);
        API.apiPost("/theme", { payload: encodeData(selectedColors) })
            .then((response) => {
                if (response.data && response.data.success === true) {
                    getThemeDeatils();
                    toast.success(response.data.message);
                }
            })
            .catch((err) => {
                setThemeLoading(false);
                handleErrorMessage(err);
            });


            { <div className="absolute w-full h-full flex items-center justify-center z-10" >
                <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                />
            </div>}

            
    };

    const handleColorChange = (color, hex, key) => {
        const value = color.toHexString()
        setSelectedColors((prevState) => {
            const updatedState = {
                ...prevState,
                color_config: {
                    ...prevState.color_config,
                    [key]: value,
                },
            };
            dispatch({
                type: Actions.SET_USER_THEME,
                payload: { themeDetails: { ...updatedState } },
            });
            return updatedState;
        });
    };

    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     setPreviewImage(file.url || file.preview);
    //     setPreviewOpen(true);
    // };

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(5);

    const onChangeCheckbox = (e) => {
        setDefaultCheck(e.target.checked);
        if (e.target.checked) {
            let payload = themeDetails
            payload.color_config = defaultTheme.color_config;
            payload.logo=null;
            const defaultColorConfig = defaultTheme.color_config;
            setSelectedColors((prevState) => {
                const updatedState = {
                    ...prevState,
                    color_config: {
                        ...defaultColorConfig
                    }
                };
                return updatedState;
            });
            API.apiPost("/theme", { payload: encodeData(payload) })
                .then((response) => {
                if (response.data && response.data.success === true) {
                    getThemeDeatils();
                    getlogo(themeDetails);
                    toast.success(response.data.message);
                }
            })
            .catch((err) => {
                setThemeLoading(false);
                handleErrorMessage(err);
            });
        }
    };

        
    const handleInputChange = (e) => {
        const{name,value}=e.target;
        setSelectedColors((prevState) => {
            const updatedState = {
                ...prevState,
                color_config: {
                    ...prevState.color_config,
                    [name]: value,
                },
            };
            dispatch({
                type: Actions.SET_USER_THEME,
                payload: { themeDetails: { ...updatedState } },
              });
            return updatedState;
        });  
    }

    const customRequest = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);
        let payload = {
            theme_id: selectedColors?.id
        }
        const encodedPayload = encodeData(payload)
        formData.append('metadata', encodedPayload);
        try {
            if (file) {
                let response = await fetch(
                    `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/theme/logo`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                );
                response = await response.json();
                if (response?.success) {
                    getlogo({logo: file.name});
                    toast.success(response?.message);
                    onSuccess('done');
                }
            }
        } catch (error) {
            onError('error');
        }
    };

    const handleChange = ({ fileList }) => setFileList(fileList);

    const genExtra = (value) => (
        <div className="flex justify-center align-items-center ">
            <div className="mx-3 fw-bold bg-gray colorText rounded px-2">{value}</div>
            <div className="p-3 rounded border-2" style={{ background: `${value}` }}>
            </div>
        </div>)

    return (
        <>
            <div className={`flex dark:bg-dark-background updatePhone mainBodyContainer`}>
                <SideMenu />
                <div className="h-screen flex-1 overflow-y-scroll">
                    <Header/>
                    <div className={`${styles.logoParentdiv}  px-6`}>
                    {themeLoading && (
                      <div className="absolute w-full h-full loader  inset-1/2  flex items-center z-10">
                        <ColorRing
                        visible={true}
                        height="80"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[]}
                        />
                       </div>
                         )}
                            <Row gutter={16}>
                                <Col className="ThemeBox">
                                    <Card title="Theme Admin" className="">
                                        <div className="uploadLogoBox border-2 p-4 border-dashed rounded-lg h-24">
                                            <div className="bg-white flex justify-center">
                                                <Flex>
                                                    <ImgCrop rotationSlider>
                                                    <Upload
                                                        onChange={handleChange}
                                                        fileList={fileList}
                                                        customRequest={customRequest}
                                                        className='support_fileUpload'
                                                    >
                                                        {fileList.length < 1 && <> <div className="text-center flex justify-center">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill={themeDetails?.color_config?.main_color} xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.25 10.2742V4.25C19.25 4.05108 19.1709 3.86032 19.0303 3.71967L16.0303 0.719668C15.8897 0.57902 15.6989 0.5 15.5 0.5H5C3.75736 0.5 2.75 1.50736 2.75 2.75V5.75C1.50736 5.75 0.5 6.75733 0.5 8V19.25C0.5 20.4927 1.50736 21.5 2.75 21.5H18.5C20.1568 21.5 21.5 20.1568 21.5 18.5V12.8572C21.5 11.5384 20.5209 10.4484 19.25 10.2742ZM5 2C4.58579 2 4.25 2.33579 4.25 2.75V5.75H6.10102C6.66665 5.75 7.21152 5.96304 7.62718 6.34669L9.20278 7.8011C9.3413 7.92898 9.52295 8 9.7115 8H14.75C15.9927 8 17 9.00733 17 10.25V18.5C17.4142 18.5 17.75 18.1642 17.75 17.75V5H15.5C15.0858 5 14.75 4.66421 14.75 4.25V2H5ZM10.7803 13.4697C11.0732 13.7626 11.0732 14.2374 10.7803 14.5303C10.4874 14.8232 10.0126 14.8232 9.71968 14.5303L9.5 14.3106V17C9.5 17.4142 9.16422 17.75 8.75 17.75C8.33577 17.75 8 17.4142 8 17V14.3106L7.78032 14.5303C7.48745 14.8232 7.01255 14.8232 6.71968 14.5303C6.42678 14.2374 6.42678 13.7626 6.71968 13.4697L8.21968 11.9697C8.3603 11.8291 8.5511 11.75 8.75 11.75C8.9489 11.75 9.1397 11.8291 9.28032 11.9697L10.7803 13.4697Z" />
                                                            </svg>
                                                        </div>
                                                            <span className="fw-semibold uploadtext ms-3"> {'Upload Your Logo File'}</span>
                                                            <div className="fw-semibold ">{fileList.length < 1 && '.jpg, .png, .ai, .svg & others'}</div></>}
                                                        </Upload>
                                                    </ImgCrop>
                                                </Flex>
                                            </div>
                                        </div>

                                        <div className="my-3 mx-3 ">
                                            <Checkbox onChange={onChangeCheckbox} className="fw-semibold">Use Default Settings</Checkbox>
                                        </div>

                                        <div className="DesignBox">
                                            <div className="mt-2 ">
                                            <div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '1',
                                                                label: 'Main Color',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.main_color} onChange={(value, type) => handleColorChange(value, type, "main_color")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.main_color} onChange={(e)=>handleInputChange(e)} name="main_color" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.main_color}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.main_color),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Primay Button',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.primary_button_bg} onChange={(value, type) => handleColorChange(value, type, "primary_button_bg")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.primary_button_bg} onChange={(e)=>handleInputChange(e)} name="primary_button_bg" />
                                                                        <div className="p-3 rounded border" style={{ background: `${selectedColors?.color_config?.primary_button_bg}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.primary_button_bg),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Primay Button Text',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.primary_button_text} onChange={(value, type) => handleColorChange(value, type, "primary_button_text")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.primary_button_text} onChange={(e)=>handleInputChange(e)} name="primary_button_text" />
                                                                        <div className="p-3 rounded border" style={{ background: `${selectedColors?.color_config?.primary_button_text}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.primary_button_text),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Secondary Button',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.secondary_button_bg} onChange={(value, type) => handleColorChange(value, type, "secondary_button_bg")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.secondary_button_bg} onChange={(e)=>handleInputChange(e)} name="secondary_button_bg" />
                                                                        <div className="p-3 rounded border" style={{ background: `${selectedColors?.color_config?.secondary_button_bg}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.secondary_button_bg),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Secondary Button Text',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.secondary_button_text} onChange={(value, type) => handleColorChange(value, type, "secondary_button_text")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.secondary_button_text} onChange={(e)=>handleInputChange(e)} name="secondary_button_text" />
                                                                        <div className="p-3 rounded border" style={{ background: `${selectedColors?.color_config?.secondary_button_text}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.secondary_button_text),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Table Header',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.table_header_footer} onChange={(value, type) => handleColorChange(value, type, "table_header_footer")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.table_header_footer} onChange={(e)=>handleInputChange(e)} name="table_header_footer" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.table_header_footer}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.table_header_footer),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Table Header Text',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.table_header_text_color} onChange={(value, type) => handleColorChange(value, type, "table_header_text_color")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.table_header_text_color} onChange={(e)=>handleInputChange(e)} name="table_header_text_color" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.table_header_text_color}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.table_header_text_color),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Table Body',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.table_body} onChange={(value, type) => handleColorChange(value, type, "table_body")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.table_body} onChange={(e)=>handleInputChange(e)} name="table_body" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.table_body}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.table_body),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '2 bg-white',
                                                                label: 'Table Body Text',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.table_body_text_color} onChange={(value, type) => handleColorChange(value, type, "table_body_text_color")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.table_body_text_color} onChange={(e)=>handleInputChange(e)} name="table_body_text_color" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.table_body_text_color}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.table_body_text_color),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '3',
                                                                label: 'Section Selection',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.module_selection} onChange={(value, type) => handleColorChange(value, type, "module_selection")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.module_selection} onChange={(e)=>handleInputChange(e)} name="module_selection" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.module_selection}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.module_selection),
                                                                size: "small",
                                                            },

                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '3',
                                                                label: 'Section Selection Text',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.module_selection_text} onChange={(value, type) => handleColorChange(value, type, "module_selection_text")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.module_selection_text} onChange={(e)=>handleInputChange(e)} name="module_selection_text" />
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.module_selection_text}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.module_selection_text),
                                                                size: "small",
                                                            },

                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '4',
                                                                label: 'Sidebar',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.sidebar_module} onChange={(value, type) => handleColorChange(value, type, "sidebar_module")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.sidebar_module} onChange={(e)=>handleInputChange(e)} name="sidebar_module"/>
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.sidebar_module}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.sidebar_module),
                                                                size: "small",
                                                            },

                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '4',
                                                                label: 'Page Background',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.page_background} onChange={(value, type) => handleColorChange(value, type, "page_background")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.page_background} onChange={(e)=>handleInputChange(e)} name="page_background"/>
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.page_background}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.page_background),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '9',
                                                                label: 'Card Body',
                                                                showArrow: false,
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.card_body_bg} onChange={(value, type) => handleColorChange(value, type, "card_body_bg")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.card_body_bg} onChange={(e)=>handleInputChange(e)} name="card_body_bg"/>
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.card_body_bg}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.card_body_bg),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="rounded-3 my-2 bg-white">
                                                    <Collapse
                                                        items={[
                                                            {
                                                                key: '8',
                                                                label: 'Card Header',
                                                                showArrow: false,
                                                                className: "",
                                                                children: <div className="customize_color_container">
                                                                    <ColorPicker value={selectedColors?.color_config?.card_header_bg} onChange={(value, type) => handleColorChange(value, type, "card_header_bg")} />
                                                                    <div className="flex justify-between mt-1">
                                                                        <Input className="me-4 rounded-4 fw-bold" value={selectedColors?.color_config?.card_header_bg} onChange={(e)=>handleInputChange(e)} name="card_header_bg"/>
                                                                        <div className="p-3 rounded border-2" style={{ background: `${selectedColors?.color_config?.card_header_bg}` }}>
                                                                        </div>
                                                                    </div>
                                                                </div>,
                                                                extra: genExtra(selectedColors?.color_config?.card_header_bg),
                                                                size: "small",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-between mt-3 float-end">
                                            <button className="custom_btn custom_btn_primary commonBtn h-10 px-4 rounded" onClick={saveSettings}>Save</button>
                                        </div>

                                    </Card>
                                </Col>
                                <Col className="PreviewBox" xs={24} sm={24} md={16}>

                                    <Card title="Table Preview" >
                                    <div className=' reconcilitaion-main-container mt-4 mx-10'>
                                        <div className="reconcilitionTable-container">
                                            <table className="min-w-full rounded-lg border-2 border-solid border-gray-border text-left reconcilitionTable">
                                                <thead className="commonTableHeader">
                                                <tr className="ps-2 pe-2">
                                                    <th className="py-2 px-4 capitalize">
                                                        First Name
                                                    </th>
                                                    <th className="py-2 px-4 capitalize">
                                                        Last Name
                                                    </th>
                                                    <th className="py-2 px-4 capitalize">
                                                        EMail
                                                    </th>
                                                    <th className="py-2 px-4 capitalize">
                                                        Created At
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='ps-2 pe-2'>
                                                        <td className="py-2 ps-4">Test</td>
                                                        <td className="py-2 ps-4">User</td>
                                                        <td className="py-2 ps-4">dummy@example.com</td>
                                                        <td className="py-2 ps-4">20 May, 2024</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination
                                            nPages={1}
                                            currentPage={1}
                                            // handlePageChange={handlePageChange}
                                        ></Pagination>
                                    </div>
                                    </Card>
                                    <Card title="Components View" className="mt-3">
                                        <button className="custom_btn_secondary commonBtn border rounded p-2">Secondary Button</button>
                                        <button className="custom_btn_primary commonBtn border-2 rounded p-2 ms-2">Primary Button</button>
                                        <Card className="m-2">
                                            {/* <h2>Why we use it?</h2> */}
                                            <p>This is the dummy card data to check the theme preview</p>
                                        </Card>
                                    </Card>
                                </Col>
                            </Row >
                    </div >
                </div>
                <ToastContainer></ToastContainer>
            </div> 
        </>
    )
}

export default LogoAndThemeComponent;