import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import React, { Children, useEffect, useState } from 'react'
import API from '../../helpers/api'
import ProcessCards from "../../component/ProcessCards";
import LineChart from "../../component/LineChart";
import DataTable from "../../component/DataTable";

const AmlScreen = () => {
  const [toggleLogs, setToggleLogs] = useState(true)
  const Amlcolumns = [
    {
      title: 'Token No',
      key: 'token_no',
      // style: { width: 'width: 70%'},
    },
    {
      title: 'Branch Code',
      key: 'branch_code',
    },
    {
      title: 'Process Date',
      key: 'process_date',
    },
    {
      title: 'Customer Id',
      key: 'customer_id',
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
    },
    {
      title: 'Highest Score',
      key: 'highest_score',
    },
    {
      title: 'Status',
      key: 'status',
    },
  ]
  const AmlData = [
    {
      token_no : 12345678,
      branch_code : 'ad',
      process_date : "12/Aug/2023",
      customer_id : 12,
      customer_name : 'abc',
      highest_score : '100',
      status : 'Approved'
    },
    {
      token_no : 12345678,
      branch_code : 'ad',
      process_date : "12/Aug/2023",
      customer_id : 12,
      customer_name : 'abc',
      highest_score : '100',
      status : 'Pending'
    }
  ]
  return (
    <div className="flex dark:bg-dark-background">
        <div className="z-0 w-full">
          <div className="flex gap-4">
            <div className="flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mb-4 p-4 gap-2 w-full">
              <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-1/2">
                <h3 className="inline-block relative text-blue-500 font-quicksand text-3xl font-semibold mr-8">
                  2436
                </h3>
                <p className="text-black text-opacity-60 font-quicksand text-lg font-medium leading">
                  Number of Request Approved
                </p>
              </div>
              {/* <div>
                <LineChart/>
              </div> */}
            </div>

            <div className="flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mb-4 p-4 gap-2 w-full">
              <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-1/2">
                <h3 className="inline-block relative text-blue-500 font-quicksand text-3xl font-semibold mr-8">
                  465
                </h3>
                <p className="text-black text-opacity-60 font-quicksand text-lg font-medium leading">
                  Number of times error occured
                </p>
              </div>
            </div>
          </div>
          <div className="w-content flex gap-2">
            <div className={`rounded-full bg-blue border border-solid border-gray-border py-2 px-4 ${toggleLogs ? 'bg-blue-600 text-white' : ''}`}>
              <button onClick={() => setToggleLogs(true)}>All Logs</button>
            </div>
            <div className={`rounded-full bg-blue border border-solid border-gray-border py-2 px-4 text-dark ${!toggleLogs ? 'bg-blue-600 text-white' : ''}`}>
              <button onClick={() => setToggleLogs(false)} disabled>Configurations Logs</button>
            </div>
          </div>
          <DataTable columns={Amlcolumns} rowData={AmlData}/>
        </div> 
    </div>
  );
};
export default WithAuth(AmlScreen);
