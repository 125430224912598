import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from '../../common/WithAuth';
import Breadcrumb from "../../component/Breadcrumb";
import IVRTable from "../../component/IVRTableComponent";
import IVRCalling from "../../component/IVRCallingComponent";
import IVRProcessComponent from "../../component/IVRProgressList"
import '../../styles/dashboard.scss';

const IVRWorkflow = () => {

  const isMobileDevice = window.screen.width <= 900;
  const steps = ["Services", "IVR"];
    
  useEffect(()=>{
    localStorage.setItem("activeTab", "IVRWorkflow");
  },[])

  return (
    <div className={`flex dark:bg-dark-background dashboard-container dashboard-main mainBodyContainer ${isMobileDevice && 'h-screen overflow-auto mainContainer_responsive'}`}>
      <SideMenu headerName="IVR"/>
      <div className={`flex-1 h-screen ${!isMobileDevice ? 'overflow-hidden' : 'overflow-y-auto servicesMainContainer_responsive'}`}>
        <Header/>
        <div className={`z-0 p-10 mainContainer ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
          {/* <div className=" flex flex-row justify-between">
            <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
              Service List
            </h1>
          </div> */}
          <Breadcrumb
            steps={steps}
            activeStep={1}
            showBackButton={true}
            redirectUrl={"/services"}
          />
          <div className={`flex justify-start mt-4 ${isMobileDevice && 'flex-col items-center'}`}>
          <IVRTable/>
          <IVRCalling/>
          {/* <IVRProcessComponent /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithAuth(IVRWorkflow);
