import React from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
// import QueCard from "../../component/QueCard";
import "../../styles/workerQueBoard.scss";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function WorkerQueBoard() {
    return (
        <div className="z-0 container">
            {/* <QueCard /> */}
        </div>
    )
}
