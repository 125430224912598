// import { useRouter } from 'next/router';
import React from 'react';
// import { useSelector } from 'react-redux';
import { useAuth, logout, isPathAccessAllowed } from '../helpers/auth';
// import CustomLoader from './CustomLoader';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AccessDeniedComponent from './AccessDeniedComponent';


export const WithAuth =  (WrappedComponent) => {
  const WrapperComponent = (props) => {
    let isAuthenticated =  useAuth();
    const location = useLocation()
    const [userData] = useSelector(( Gstate ) => [Gstate?.UserDataReducer?.user_data])
    let isPathAllowed = isPathAccessAllowed(location?.pathname, userData?.role_id)
    if (location.pathname === '/record'){
      isPathAllowed=true;
      isAuthenticated=true;
    }
    if (isAuthenticated && isPathAllowed) {
      return <WrappedComponent {...props} />;
    } else if (!isPathAllowed && isAuthenticated) {
        return (
          <AccessDeniedComponent />
        )
    } else if (!isAuthenticated) {
      logout();
    }
  };

  WrapperComponent.displayName = `withLogger(${WrappedComponent.displayName || WrappedComponent.name})`;
  return WrapperComponent;

};
