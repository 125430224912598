import React, { useState } from 'react';
import Pagination from "./Pagination";
import "../styles/reconcilition.scss";
// import { useRouter } from 'react-router';
import { useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner';

export default function DataTable({columns, rowData, tableLodaing, showActions}) {
  const [loading, setLoading] = useState(true)
  // const router=useRoutes();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = rowData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(rowData?.length / recordsPerPage);

  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
  }

  const editUser = (data) => {
    navigate(`/UserEdit?user_id=${data?.id}`)
};
  
  return (
    <div className=' reconcilitaion-main-container mt-4 mx-10'>
        <div className="reconcilitionTable-container">
          {currentRecords?.length > 0 ?
              <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                <thead>
                  <tr className="ps-2 pe-2">
                    {columns.map((item, indx) =>
                      <th  key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                        {item?.title.toLowerCase()}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  { currentRecords?.map((item, index)=>
                    <tr className='ps-2 pe-2' key={`${item}_${index}`}>
                      {/* <td  className="py-2 ps-4">{item?.Stellar ? item?.Stellar : <span className="text-red-500"> - Missing Record</span>}</td>
                      <td   className="py-2 ps-4">{item?.Date ? item?.Date : <span className="text-red-500"> - Missing Record</span>}</td> */}
                      {columns.map( (column, colIdx) => column?.key === 'status'? 
                        <td  key={`col_${index}${colIdx}`} className="py-2 ps-4" style={column?.style}>{item?.[column?.key] ? item?.[column?.key] == 'Inactive' ? <span className={`px-1 rounded-full tabletDebit text-center`}><span className="red-dot"/>Inactive</span> : <span className="rounded-full tabletCredit px-1 text-center"><span className="green-dot"/>Active</span> : <span className="text-red-500"> - Missing Record</span> }</td>
                        : column?.key === 'actions' ? <td className="py-2 ps-4" style={column?.style}><svg onClick={()=>{editUser(item)}}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={20} className='cursor-pointer ms-6'><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg></td>
                        : <td   className="py-2 ps-4" title={item?.[column?.key]} style={column?.style} >{item?.[column?.key] ? item?.[column?.key]?.length>20 ? `${item?.[column?.key].substring(0,20)}...`: item?.[column?.key]: <span> -</span>}</td>
                        )
                      }
                      {/* <td  className="py-2 ps-4">{item?.Amount ? item?.Amount : <span className="text-red-500"> - Missing Record</span>}</td> */}
                    </tr>
                    
                  )}
                </tbody>
              </table>
              :
                <>
                <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                  <thead>
                    <tr className="ps-2 pe-2">
                      {columns.map((item, indx) =>
                        <th  key={`row${indx}`} className="py-2 px-4 capitalize" style={item?.style}>
                          {item?.title.toLowerCase()}
                        </th>
                      )}
                    </tr>
                  </thead>
                </table>
                {tableLodaing ? 
                  <div className="flex items-center justify-center" >
                    <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                    />
                  </div>
                  :
                  <div className='flex font-semibold justify-center py-5'>
                      No data found
                  </div>
                }
                </>
              }
            </div>
            {!tableLodaing && currentRecords?.length > 0 &&
              <Pagination 
                nPages={nPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              ></Pagination>
            }
    </div>
  )
}
