import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import Breadcrumb from "../../../component/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import API from "../../../helpers/api/index";
import "../../../styles/updatePhoneNumber.scss";
import { DatePicker } from 'antd';
import Icon from "../../../component/core-component/Icon";
import { formatDateToIso, handleValidNumberInput, handleErrorMessage, encodeData } from "../../../common/commonFunction";
import moment from "moment";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomDateRangeInput from "../../../component/DateRange";
import VideoKYCDetailScreen from "./VideoKYCDetailsScreen";
import Validation from "../../../utils/Validation";
import { WithAuth } from "../../../common/WithAuth";
import { openingModal, closingModal } from "../../../redux/actions";


const UpdatePhoneNumber = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [nPages, setNpages] = useState(1);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    bankAccountNumber: "",
    dob: "",
    oldMobileNum: "",
    newMobileNum: "",
    verificationMobileNum: "",
    NID_number: "",
    // KYC_language: ''
    verificationEmail: "",
    expiresIn: "",
    purpose: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [updatePhoneData, setUpdatePhoneData] = useState([]);
  const [downloadCsvFile,setDownloadCsvFile]= useState([]);
  const [searchData, setSearchData] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [requestSubmitLoader, SetRequestSubmitLoader] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [datesData, setDateData] = useState([]);
  const [filterDate, setFilterDate] = useState({ startDate: '', endDate: '' });
  const initialFilterData = {
    startDate: "",
    endDate: "",
  }
  const [responseScreenWidth, setResponseScreenWidth] = useState();
  const [statData, setStatData] = useState({
    Total: "loading...",
    Approved: "loading...",
    Rejected: "loading...",
    Pending: "loading...",
  });

  const closeModal = () => {
    setIsOpen(false);
    setValues({});
    setFormErrors({});
    setFormDoc("");
    setNidDoc("");
    setProgress1Loading("noprogress");
    setDataOne({});
    setProgress2Loading("noprogress");
    // setModalOpacity(!modalOpacity)
  };
  function validateEmail(email) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)*[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  }
  const validateForm = () => {
    const regex = /^[a-zA-Z0-9_]*$/;
    const errors = {};
    if (!values?.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!Validation.validateName(values?.first_name) && values?.first_name?.length) {
      errors.first_name = "Please Enter Valid Information";
    }
    if (!values?.last_name) {
      errors.last_name = "Last Name is required";
    }
    if(!Validation.validateName(values?.last_name) && values?.last_name?.length){
      errors.last_name = "Please Enter Valid Information"
    }
    if (!values?.bankAccountNumber) {
      errors.bankAccountNumber = " Bank account number is required";
    }
    if(Validation.validateNumericValue(values?.bankAccountNumber) && values?.bankAccountNumber?.length){
      errors.bankAccountNumber = "Please Enter Valid Information";
    }
    if (!values.NID_number) {
      errors.NID_number = "Nid number is required";
    }
    if (!values?.dob) {
      errors.dob = "Date of birth is required";
    }
    if (!values?.expiresIn) {
      errors.expiresIn = "Expiry Time is required";
    }
    // if (!values?.oldMobileNum) {
    //   errors.oldMobileNum = "Old mobile number is required";
    // }
    // if (!values?.newMobileNum) {
    //   errors.newMobileNum = "New mobile number is required";
    // }
    if (!values?.purpose) {
      errors.purpose = "KYC purpose is required";
    }
    if (!values?.verificationMobileNum) {
      errors.verificationMobileNum = "Verification mobile number is required";
    }
    if (!values?.verificationEmail) {
      errors.verificationEmail = "Verification email is required"
    } else {
      if (!validateEmail(values.verificationEmail)) {
        errors.verificationEmail = "Email is not valid"
      }
    }
    // if (
    //   (values.oldMobileNum === values.newMobileNum) &&
    //   values.oldMobileNum &&
    //   values.newMobileNum
    // ) {
    //   if (
    //     values.oldMobileNum === values.newMobileNum &&
    //     values.oldMobileNum &&
    //     values.newMobileNum
    //   ) {
    //     errors.newMobileNum = "Phone Numbers cannot be same";
    //     errors.oldMobileNum = "Phone Numbers cannot be same";
    //   }
    // }
    if (!formDoc) {
      errors.formDocError = 'Please upload a paper form!'
    }
    if (!nidDoc) {
      errors.nidDocError = 'Please upload a NID Document!'
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  function formatPhoneNumber(inputString) {
    let numArray = inputString.split(' ');
    let first = numArray.shift();
    let value = first + ' ' + numArray.join('');
    return value.replace(/[-()]/g, ''); // Use regex /-/g to remove all occurrences of '-'
  }
  const handleSubmit = async (event) => {
    SetRequestSubmitLoader(true)
    if(validateForm()){
      const formData = new FormData();
      formData.append("form_document", formDoc);
      formData.append("nid_document", nidDoc);
     let payload = {
      account_no: values.bankAccountNumber,
      old_number: formatPhoneNumber(values.verificationMobileNum),
      new_number: formatPhoneNumber(values.verificationMobileNum),
      verification_number: formatPhoneNumber(values.verificationMobileNum),
      d_o_b: formatDateToIso(values.dob.$d),
      email: values.verificationEmail,
      NID_number: values?.NID_number,
      first_name: values?.first_name,
      last_name: values?.last_name,
      expiresIn: `${values?.expiresIn}h`,
      Custom_KYC_type: values?.purpose,
    }
    const encodedPayload = encodeData(payload);
    formData.append("metadata", encodedPayload);
      try {
        let response = await fetch(
          `${process.env.REACT_APP_API_SERVICE_BASE_URL}/api/videokyc`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        response = await response.json();
        if (response?.success) {
          toast.success(response?.message);
          closeModal();
          getPhoneUpdateDetails(1);
          SetRequestSubmitLoader(false);
        } else {
          SetRequestSubmitLoader(false);
          handleErrorMessage(response);
        }
      } catch (error) {
        SetRequestSubmitLoader(false);
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.message);
      }
    }
    SetRequestSubmitLoader(false);
    // event.preventDefault();
  };

  const getCapitaliseText = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getPhoneUpdateDetails = async (currentPage, kyc_status) => {
    let params = {
      start_date: filterDate?.startDate ? moment(filterDate?.startDate).format('YYYY-MM-DD') : '',
      end_date: filterDate?.endDate ? moment(filterDate?.endDate).format('YYYY-MM-DD') : '',
    };
    if (kyc_status && kyc_status != 'all') params.KYC_status = kyc_status
    try {
      setDataLoading(true);
      const { data } = await API.apiGet(`/videokyc?limit=${recordsPerPage}&skip=${currentPage}`, '', params);
      if (data?.success) {
        let modifiedData = data?.data?.map(item => {
          if (item?.first_name != null || item?.last_name != null) {
            item.full_name = getCapitaliseText(item?.first_name) + ' ' + getCapitaliseText(item?.last_name)
          } else {
            item.full_name = '-'
          }
          return item;
        })
        setUpdatePhoneData(modifiedData);
        setFilteredData(modifiedData);
        setNpages(data?.totalPageCount);
        toast.success(data?.message);
        setDataLoading(false);
      } else {
        toast.error(data?.message);
        setDataLoading(false);
      }
    } catch (error) {
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
      setDataLoading(false);
    }
  };

  const handleDateRangeSubmit = async (ranges) => {
    setUpdatePhoneData([]);
    SetRequestSubmitLoader(true)
    setFilterDate((prev) => ({
      ...prev,
      startDate: ranges?.startDate ? ranges?.startDate : '',
      endDate: ranges?.endDate ? ranges?.endDate : '',
    }));
    getUpdatePhoneData(ranges);
  };

  
  async function getUpdatePhoneData(ranges, excelDownload){
    setDataLoading(true);
    setCurrentPage(1);
    let params = {  
      start_date: ranges?.startDate ? moment(excelDownload ? ranges?.startDate?.$d : ranges?.startDate).format('YYYY-MM-DD') : '',
      end_date: ranges?.endDate ? moment(excelDownload ? ranges?.endDate?.$d : ranges?.endDate).format('YYYY-MM-DD') : '',
    };
    if (selectedStatus && selectedStatus != "all") params.KYC_status = selectedStatus;
    try{
      const response = await API.apiGet(`/videokyc?limit=${recordsPerPage}&skip=${currentPage}`,'',params)
      if( response.status === 200 ){
        setDataLoading(false);
        let modifiedData = response?.data?.data.map(item => {
          if (item?.first_name != null || item?.last_name != null) {
            item.full_name = getCapitaliseText(item?.first_name) + ' ' + getCapitaliseText(item?.last_name)
          } else {
            item.full_name = '-'
          }
          return item;
        })
        if (excelDownload) {
          const csvRows = [];
          if(modifiedData?.length === 0){
            toast.error("No Data Found For Selected Date Range")
          }
          const headers = Object.keys(modifiedData[0]);
          let csvFileData = modifiedData?.map(item => {
            item.created_at = moment(item?.created_at).format('YYYY-MM-DD | HH:mm')
            item.updated_at = moment(item?.updated_at).format('YYYY-MM-DD | HH:mm')
            return item;
          })
          csvRows.push(headers.join(','));
          for (const row of csvFileData) {
            const values = headers.map(header => {
              const escaped = ('' + row[header]).replace(/"/g, '""');
              return `"${escaped}"`; 
            });
            csvRows.push(values.join(','));
          }

          const csvContent = csvRows.join('\n');
          const blob = new Blob([csvContent], { type: 'text/csv' });

          // Trigger download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'raise_requestList.csv';
          link.click();
        }
        else {
          setUpdatePhoneData(modifiedData);
          setNpages(response?.data?.totalPageCount);
        }
        SetRequestSubmitLoader(false)
      } else {
        setDataLoading(false);
        toast.error(response?.data?.error)
        SetRequestSubmitLoader(false)
      }
    } catch (error) {
      setDataLoading(false);
      SetRequestSubmitLoader(false)
      toast.error(error?.response?.data?.error)
    }
  }

  const formatToISOStringInTimeZone = (date, timeZoneOffset) => {
    const adjustedDate = moment(date).add(timeZoneOffset, 'minutes');
    return adjustedDate.toISOString();
  };

  const handleSearchDate = (dates) => {
    setDateData(dates);
    if (!dates) {
      handleDateRangeSubmit({
        startDate: '',
        endDate: '',
      })
    }
    if (!dates || dates.length !== 2) return;
    const [startDate, endDate] = dates;
    const formattedStartDate = formatToISOStringInTimeZone(startDate?.$d);
    const formattedEndDate = formatToISOStringInTimeZone(endDate?.$d);

    handleDateRangeSubmit({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

  };

  const getStatsDetails = async () => {
    try {
      const { data } = await API.apiGet(`/videokyc/list/status`);
      if (data?.success) {
        toast.success(data?.message);
        setStatData(data.count);
      } else {
        setStatData({ ...noDataStatus })
        toast.error(data?.message);
      }
    } catch (error) {
      setStatData({...noDataStatus})
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message);
    }
  };

  const reSendLink = async ({ id }) => {
    try {
      const payload = {
        kyc_id: id
      }
      const encodedPayload = encodeData(payload)
      const { data } = await API.apiPost(`/videokyc/resend/link`, {payload: encodedPayload})
      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message)
      }
     }
     catch(error){
      handleErrorMessage(error);
      // toast.error(error?.response?.data?.message || error?.response?.data?.error )
    }
  }

  const handleChange = (event, fieldName) => {
    let name = fieldName === 'dob' ? 'dob' : event.target.name;
    let value = fieldName === 'dob' ? event : event.target.value;
    if (event?.target?.name == 'NID_number' && event?.target?.value?.length == 15) {
      event.preventDefault();
    } else {
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDateChange = (value, data, event, formattedValue, name) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }

  const handleDateDownload = (value, type) => {
    setDownloadDate({
      ...downloadDate,
      [type]: value,
    });
  };

  const fetchDataAndDownload = async () => {
    if(downloadDate?.startDate && downloadDate?.endDate) {
      let excelDownload = true;
      await getUpdatePhoneData(downloadDate, excelDownload);
    } else {
      toast.error("Dates must be selected before downloading a file");
    }
  };

  // const steps = ["Process List", "Update Phone Number"];
  const steps=["Video KycList"]
  //   const [selectedFile, setSelectedFile] = useState(null);
  const phoneTableHeader = [
    {
      title: "Account Number",
      key: "account_no",
      // width: '18%',
    },

    {
      title: "Full Name",
      key: "full_name"
    },
    {
      title: "Verification Number",
      key: "verification_number",
      // width: '13%',
    },
    {
      title: "KYC Video",
      key: "kyc_video",
      // width: '13%',
    },
    {
        title: "Request Type",
        key: "KYC_type"
    },
    {
      title: "Requestor",
      key: "requestor",
      // width: '10%',
    },
    {
      title: "Status",
      key: "KYC_status",
      // width: '15%',
    },
    {
      title: 'Resend Link',
      key: 'resend_link',
    },
    {
      title: "Actions",
      key: 'actions',
    },
  ];

  const [progress1Loading, setProgress1Loading] = useState("noprogress");
  const [progress2Loading, setProgress2Loading] = useState("noprogress");
  const [formDoc, setFormDoc] = useState();
  const [nidDoc, setNidDoc] = useState(0);
  const [dataOne, setDataOne] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [missingCount, setMissingCount] = useState(0);
  const [showReportsDownloadModal, setShowReportsDownloadModal] = useState(false);

  const handleFileChangepdf = (event, isDragged, pdfValue) => {
    const files = isDragged ? event.dataTransfer?.files : event.target.files;
    if (pdfValue === "paperForm") {
      setProgress1Loading("noprogress");
      if (files?.length && files?.[0].type === "application/pdf") {
        setProgress1Loading("uploading");
        setProgress1Loading("uploaded");
        setFormDoc(files?.[0]);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          formDocError: "",
        }));
      } else {
        event.target.value = "";
        setFormDoc("");
        toast.error("Please select a pdf file.");
      }
    } else {
      setProgress2Loading("noprogress");
      if (files?.length) {
        const file = files[0];
        const fileExtension = files?.[0].type.split("/").shift().toLowerCase();
        if (files?.length && (files?.[0].type == "application/pdf" || fileExtension == "image")) {
          setProgress2Loading("uploading");
          setProgress2Loading("uploaded");
          setNidDoc(file);
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            nidDocError: "",
          }));
        } else {
          event.target.value = "";
          setNidDoc("");
          toast.error("Please select an pdf file or an image.");
        }
      } else {
        event.target.value = "";
        setNidDoc("");
        toast.error("Please select a file.");
      }
    }
  };

  const showModal = () => {
    setShowReportsDownloadModal(true)
    dispatch(openingModal())
  }

  const closeDateModal = () => {
    setShowReportsDownloadModal(false)
    setDownloadDate({});
    dispatch(closingModal())
  }

  const pdfDropHandler = (e, pdfValue) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileChangepdf(e, true, pdfValue);
  };
  const dragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClear = (filetype, pdfValue) => {
    if (filetype === "pdf" && pdfValue === "paperForm") {
      setFormDoc("");
      setProgress1Loading("noprogress");
    } else {
      setNidDoc("");
      setDataOne({ ...dataOne, [filetype]: "" });
      setProgress2Loading("noprogress");
    }
  };
  const [selectedStatus, setSelectedStatus] = useState("");
  const [downloadDate, setDownloadDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [filterData, setFilteredData] = useState([]);
  const options = ["Rejected", "Approved", "Pending"];
  const cardsLabel = {
    Total: "Total",
    Approved: "Approved",
    Rejected: "Rejected",
    Pending: "Pending",
  };
  const cardsColor = {
    Total: "#517DD2",
    Approved: "#196857",
    Rejected: "#FF0000",
    Pending: "#F7D36B",
  };
  const noDataStatus = {
    Total: "0",
    Approved: "0",
    Rejected: "0",
    Pending: "0",
  }
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    let filteredData = updatePhoneData.filter((item) => {
      let isMatch = 
        (item?.first_name)?.toLowerCase()?.includes(value) || 
        (item?.last_name)?.toLowerCase()?.includes(value) ||
        (`${item?.first_name}${' '}${item?.last_name}`)?.toLowerCase()?.includes(value) ||
        item?.account_no?.includes(value) || 
        (item?.new_number)?.toLowerCase()?.includes(value) || 
        (item?.old_number)?.toLowerCase().includes(value) || 
        item?.kyc_video?.includes(value) || 
        item?.requestor?.includes(value);
      return isMatch;
    });
    let filterDataWithStatus = filteredData;
    if (selectedStatus && selectedStatus != "all") {
      filteredData = filterDataWithStatus.filter((item) => {
        return item.KYC_status === selectedStatus;
      })
    }
    setSearchData(e.target.value);
    setFilteredData(filteredData);
  };

  const handleStatus = (e) => {
    getPhoneUpdateDetails(1, e.target.value);
    setCurrentPage(1);
    if (e.target.value === "all" && searchData === "") {
      setSelectedStatus(e.target.value);
      return;
    }
    let filteredData = e.target.value === 'all' ? updatePhoneData : updatePhoneData.filter((item) => item.KYC_status === e.target.value);
    let filterDataWithSearch = filteredData;
    if (searchData) {
      filteredData = filterDataWithSearch.filter(item => {
        const lowerCaseFirstName = (item?.first_name || '').toLowerCase();
        const lowerCaseLastName = (item?.last_name || '').toLowerCase();
        const lowerCaseFullName = (`${item?.first_name}${' '}${item?.last_name}` || '').toLowerCase();
        const lowerCaseNewNumber = (item?.new_number || '').toLowerCase();
        const lowerCaseOldNumber = (item?.old_number || '').toLowerCase();
        const lowerCaseKYCVideo = (item?.kyc_video || '').toLowerCase();
        const lowerCaseRequestor = (item?.requestor || '').toLowerCase();

        return (
          lowerCaseFirstName.includes(searchData.toLowerCase()) ||
          lowerCaseLastName.includes(searchData.toLowerCase()) ||
          lowerCaseFullName.includes(searchData.toLowerCase()) ||
          item?.account_no?.includes(searchData) ||
          lowerCaseNewNumber.includes(searchData.toLowerCase()) ||
          lowerCaseOldNumber.includes(searchData.toLowerCase()) ||
          lowerCaseKYCVideo.includes(searchData.toLowerCase()) ||
          lowerCaseRequestor.includes(searchData.toLowerCase())
        );
      });
    }
    setFilteredData(filteredData);
    setSelectedStatus(e.target.value);
  };

  useEffect(() => {
    // return () => {
    setDataLoading(true);
    getPhoneUpdateDetails(1, selectedStatus);
    getStatsDetails();
    // }
  }, []);

  useEffect(()=>{
    let viewportHeight = window.screen.width;
    setResponseScreenWidth(viewportHeight > 900)
  },[])

  const getCurrentDate = (current) => {
    return current && current > moment().endOf('day')
  };

  function handlePageChange(currentPage){
    setUpdatePhoneData([]);
    setCurrentPage(currentPage);
    getPhoneUpdateDetails(currentPage, selectedStatus);
  }

  return (
    responseScreenWidth ? 
    <div className={`flex dark:bg-dark-background updatePhone mainBodyContainer`}>
      {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header/>
        <div
          className="z-0 p-10 mainContainer fixedHeightContainer"
          style={{ opacity: dataLoading ? 0.5 : 1 }}
        >
          <div className="z-0">
            <div className="flex justify-between">
              <Breadcrumb
                steps={steps}
                activeStep={1}
                showBackButton={true}
                redirectUrl={"/services"}
              />
              <div className="p-4 flex gap-2 justify-center">
                <CustomDateRangeInput className="border border-none px-4 focus:outline-none focus:border-blue-500" handleDateSubmit={handleDateRangeSubmit} data={filterDate} handleSubmit={handleSearchDate} datesData={datesData} />
                <button
                  onClick={showModal}
                  className="custom_btn_primary bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn h-10 rounded-md flex gap-2 items-center"
                >
                  Download Report
                  <Icon iconName="Download" width={16} height={16} />
                </button>
              </div>
            </div>
            <div className="flex justify-center gap-2 px-12">
              {Object.keys(statData)?.map((card, idx) => (
                <div
                  key={`card_${idx}`}
                  className="ms-7 flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mt-4 p-4 gap-2 w-full accountVerificationCard"
                >
                  <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                    <h3
                      className="inline-block relative font-quicksand text-3xl mr-8"
                      style={{ color: cardsColor?.[card] }}
                    >
                      {statData[card]}
                    </h3>
                    <p className="text-black font-quicksand text-lg font-medium leading">
                      {cardsLabel[card]}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {showReportsDownloadModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
            <div class="relative p-4 w-full max-w-2xl max-h-full">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Download Report
                  </h3>
                  <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeDateModal}>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-4 flex gap-2 justify-center">
                  <DatePicker
                    name="startDate"
                    value={downloadDate?.startDate}
                    onChange={(val) => handleDateDownload(val, 'startDate')}
                    placeholder="From" // Placeholder for start date
                    className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                    disabledDate={getCurrentDate}
                  />
                  <DatePicker
                    name="endDate"
                    value={downloadDate?.endDate}
                    onChange={(val) => handleDateDownload(val, 'endDate')}
                    placeholder="To" // Placeholder for end date
                    className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                    disabledDate={getCurrentDate}
                  />
                </div>

                <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button data-modal-hide="static-modal" type="button" class="custom_btn_primary flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn" onClick={fetchDataAndDownload}>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>}

          <div className="flex justify-end py-4">
            <div className="flex flex-row-reverse">
              <div className="">
                <button
                  type="button"
                  className="custom_btn_primary raiseNewBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle commonBtn"
                  onClick={() => setIsOpen(true)}
                >
                  Raise New Request +
                </button>
              </div>
              <div className="dropdown  w-60">
                <select
                  value={selectedStatus}
                  onChange={(e) => handleStatus(e)}
                  className="p-2 rounded-lg border w-11/12"
                >
                  <option value="all">All</option>
                  {options?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex relative">
                <img
                  src="assets/images/Vector.svg"
                  className="searchLogo absolute"
                />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                  className="numberSearch"
                ></input>
              </div>
            </div>
          </div>
          <div>
            {isOpen && (
              <div
                id="static-modal"
                data-modal-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
              >
                {requestSubmitLoader && (
                  <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                    <ColorRing
                      visible={true}
                      height="80"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[]}
                    />
                  </div>
                )}
                <div
                  className="modal absolute"
                  style={
                    requestSubmitLoader
                      ? { filter: "blur(0.7px)", pointerEvents: "none" }
                      : null
                  }
                >
                  <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                    <div className="ps-3 pe-3 pt-1">
                      <p className="font-small border-b-2 pb-5 font-semibold text-lg ">
                        Raise New Request
                      </p>
                    </div>
                    <div className="modalForm">
                      <div className="flex">
                        <div className="flex flex-col ps-3 pe-3 mt-3 w-1/2">
                          <label
                            htmlFor="first_name"
                            className="numberRequestModal"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="inputD"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            className={`py-1 rounded outline-none ps-2 ${formErrors?.first_name
                                ? "border border-red-500"
                                : " border"
                              }`}
                          />
                          <span
                            className="numberRequestModal"
                            style={{
                              color: formErrors?.first_name
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {formErrors?.first_name}
                          </span>
                        </div>

                        <div className="flex flex-col ps-3 pe-3 mt-3 w-1/2">
                          <label
                            htmlFor="last_name"
                            className="numberRequestModal"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="inputE"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            className={`py-1 rounded outline-none ps-2 ${formErrors?.last_name
                                ? "border border-red-500"
                                : " border"
                              }`}
                          />
                          <span
                            className="numberRequestModal"
                            style={{
                              color: formErrors?.last_name
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {formErrors?.last_name}
                          </span>
                        </div>
                      </div>

                      <div className=" flex flex-col ps-3 pe-3 mt-4">
                        <label
                          htmlFor="bankAccountNumber"
                          className="numberRequestModal"
                        >
                          Customer's Bank Account Number
                        </label>
                        <input
                          type="text"
                          id="inputA"
                          name="bankAccountNumber"
                          value={values.bankAccountNumber}
                          onChange={handleChange}
                          placeholder="Enter Customer's Bank Account Number"
                          className={`py-1 rounded outline-none ps-2  ${formErrors?.bankAccountNumber
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.bankAccountNumber
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.bankAccountNumber}
                        </span>
                      </div>

                      <div className="flex">
                        <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                          <label
                            htmlFor="verificationMobileNum"
                            className="numberRequestModal"
                          >
                            Verification Mobile Number
                          </label>
                          <PhoneInput
                            id="inputE"
                            name="verificationMobileNum"
                            country={'bd'}
                            value={values.verificationMobileNum}
                            onChange={(value, data, event, formattedValue) => handleDateChange(value, data, event, formattedValue, 'verificationMobileNum')}
                            inputStyle={{ height: '31px', border: '1px solid #f6f7f8' , width: '100%' }}
                            containerStyle={formErrors?.verificationMobileNum ? { border: '1px solid red' } : { border: '1px solid #e3e8ec' }}
                            buttonStyle={{ border: 'none', borderRight: '1px solid #e3e8ec' }}
                            placeholder="Enter Contact Number"
                            className={`rounded outline-none ${formErrors?.verificationMobileNum
                                ? "border border-red-500"
                                : " border"
                              }`}
                          />
                          {/* <input
                            type="number"
                            id="inputF"
                            name="verificationMobileNum"
                            value={values.verificationMobileNum}
                            onChange={handleChange}
                            placeholder="Enter Contact Number"
                            className={`py-1 rounded outline-none ps-2 ${
                              formErrors?.verificationMobileNum
                                ? "border border-red-500"
                                : " border"
                            }`}
                          /> */}
                          <span
                            className="numberRequestModal"
                            style={{
                              color: formErrors?.verificationMobileNum
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {formErrors?.verificationMobileNum}
                          </span>
                        </div>

                        <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                          <label htmlFor="email" className="numberRequestModal">
                            Email
                          </label>
                          <input
                            type="email"
                            id="inputC"
                            name="verificationEmail"
                            value={values.verificationEmail}
                            onChange={handleChange}
                            placeholder="Enter Email"
                            className={`py-1 rounded outline-none ps-2  ${formErrors?.verificationEmail
                                ? "border border-red-500"
                                : " border"
                              }`}
                          />
                          <span
                            className="numberRequestModal"
                            style={{
                              color: formErrors?.verificationEmail ? "red" : "inherit",
                            }}
                          >
                            {formErrors?.verificationEmail}
                          </span>
                        </div>
                      </div>

                    <div className="flex">
                      <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                        <label
                          htmlFor="NID_number"
                          className="numberRequestModal"
                        >
                          NID Number
                        </label>
                        <input
                          type="number"
                          id="inputC"
                          name="NID_number"
                          value={values.NID_number}
                          onChange={handleChange}
                          onKeyDown={handleValidNumberInput}
                          placeholder="NID Number"
                          className={`py-1 rounded outline-none ps-2  ${formErrors?.NID_number
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.NID_number ? "red" : "inherit",
                          }}
                        >
                          {formErrors?.NID_number}
                        </span>
                      </div>
                      <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                        <label htmlFor="dob" className="numberRequestModal">
                          Date Of Birth
                        </label>
                        <DatePicker
                          id="inputC"
                          name="dob"
                          value={values.dob}
                          onChange={(val) => handleChange(val, 'dob')}
                          disabledDate={getCurrentDate}
                          placeholder="DOB"
                          className={`rounded outline-none ps-2  ${formErrors?.dob
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{ color: formErrors?.dob ? "red" : "inherit" }}
                        >
                          {formErrors?.dob}
                        </span>
                      </div>
                    </div>

                      {/* <div className=' flex flex-col ps-3 pe-3 mt-3'>
                              <label htmlFor="KYC_language" className='numberRequestModal'>Language</label>
                              <div className='flex pt-2'>
                                <div class="flex items-center">
                                  <input checked={values?.KYC_language === "Bangla"} id="bangla" type="radio" value="Bangla" name="KYC_language" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600" onClick={handleChange}/>
                                  <label for="KYC_language" class="ms-2 font-medium text-gray-900 dark:text-gray-300">Bangla</label>
                                </div>
                                <div class="flex items-center ps-4">
                                  <input checked={values?.KYC_language === "English"} id="english" type="radio" value="English" name="KYC_language" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" onClick={handleChange}/>
                                  <label for="KYC_language" class="ms-2 font-medium text-gray-900 dark:text-gray-300">English</label>
                                </div>
                              </div>
                              <span className='numberRequestModal'  style={{ color: formErrors?.KYC_language ? 'red' : 'inherit' }}>{formErrors?.KYC_language}</span>
                            </div> */}
                        <div className="flex justify-between">
                          <div className=" flex flex-col ps-3 pe-3 py-1 mt-3 w-full">
                            <label htmlFor="dob" className="numberRequestModal">
                              KYC link Expire
                            </label>
                            <select
                              value={values.expiresIn}
                              name="expiresIn"
                              onChange={(val) => handleChange(val, 'expiresIn')}
                              className={`px-3 py-1 expiryDropdown rounded w-full ${formErrors?.expiresIn
                                  ? "border border-red-500"
                                  : " border"
                                }`}
                            >
                              <option hidden value="Select Expire Time">Select Expire Time</option>
                              {[...Array(24).keys()].map(num => (
                                <option key={num + 1} value={num + 1}>{num + 1}h</option>
                              ))}
                            </select>
                            <span
                              className="numberRequestModal"
                              style={{ color: formErrors?.expiresIn ? "red" : "inherit" }}
                            >
                              {formErrors?.expiresIn}
                            </span>
                          </div>

                          <div className=" flex flex-col ps-3 pe-3 py-1 mt-3 w-full">
                            <label
                              htmlFor="purpose"
                              className="numberRequestModal"
                            >
                              Purpose of KYC
                            </label>
                            {/* <input
                          type="text"
                          id="inputA"
                          name="purpose"
                          value={values.purpose}
                          onChange={handleChange}
                          placeholder="Enter Purpose of KYC"
                          className={`py-1 rounded outline-none ps-2  ${formErrors?.purpose
                              ? "border border-red-500"
                              : " border"
                            }`}
                        /> */}
                            <select
                              value={values.purpose}
                              name="purpose"
                              onChange={(val) => handleChange(val, "purpose")}
                              className={`px-1 py-1 expiryDropdown rounded w-full ${formErrors?.kycPurpose
                                  ? "border border-red-500"
                                  : " border"
                                }`}
                            >
                              <option hidden value="Select purpose of Kyc">
                                Select purpose of Verification
                              </option>
                              <option value="Account Open">Account Open</option>
                              <option value="Update Phone Number">Update Phone No.</option>
                              <option value="Name Change Request">Name Change Request</option>
                            </select>
                            <span
                              className="numberRequestModal"
                              style={{
                                color: formErrors?.purpose
                                  ? "red"
                                  : "inherit",
                              }}
                            >
                              {formErrors?.purpose}
                            </span>
                          </div>
                        </div>

                      <div className="flex gap-4 flex-col">
                        <div className="flex gap-2 justify-evenly">
                          {/* pdf uploader 1 */}
                          <div className="flex flex-col w-1/2 cardUpload">
                            <div
                              className={`paperFormCard mt-4 pb-3 border-dashed border-2 bg-white rounded-md flex flex-col items-center justify-center file-upload-container gap-4 ${formErrors?.formDocError ? 'border-red-500' : 'border-blue-800'}`}
                              onDrop={(e) => pdfDropHandler(e, 'paperForm')}
                              onDragOver={dragOverHandler}
                            >
                              <p className=" ps-4 pt-2 pe-4 uploadSectionFont text-center w-60">
                                Upload PDF Scanned Copy of Paper Form submission
                              </p>
                              {formDoc?.name && (
                                <span className="text-center text-sm w-full block mt-1 blue-color relative">
                                  {formDoc?.name?.length > 20
                                    ? formDoc?.name?.substring(0, 14) + "..."
                                    : formDoc?.name}
                                  {!isLoading && (
                                    <img
                                      src="/assets/images/cross.png"
                                      className="cross absolute removeTheFileFirst"
                                      onClick={() =>
                                        handleClear("pdf", "paperForm")
                                      }
                                    />
                                  )}
                                </span>
                              )}
                              {progress1Loading === "noprogress" && (
                                <span className="text-center uploadSectionFont w-full block mt-1 text-sky-700">
                                  Drag and Drop Files here
                                </span>
                              )}

                              {progress1Loading === "noprogress" && (
                                <>
                                  <label className="custom_btn_primary cursor-pointer bg-blue-500 hover:bg-blue-600 text-white uploadSectionFont font-semibold py-1 px-2 rounded w-32 text-center flex button justify-center commonBtn">
                                    <input
                                      type="file"
                                      accept="application/pdf"
                                      onChange={(e) => handleFileChangepdf(e, false, 'paperForm')}
                                      className="custom_btn_primary mb-4 commonBtn"
                                      hidden
                                      name="paperForm"
                                    />
                                    Upload
                                    <img
                                      className="uploadModalLogos"
                                      src="assets/images/icon_upload.svg"
                                    />
                                  </label>
                                </>
                              )}
                            </div>
                            <span
                              className="numberRequestModal"
                              style={{ color: formErrors?.formDocError ? "red" : "inherit" }}
                            >
                              {formErrors?.formDocError}
                            </span>
                          </div>
                          {/* pdf uploader 2 */}
                          <div className="flex flex-col w-1/2 cardUpload">
                            <div
                              className={`paperFormCard mt-4 pb-3 border-dashed border-blue-800 border-2 bg-white rounded-md flex flex-col items-center justify-center file-upload-container gap-4 ${formErrors?.nidDocError ? 'border-red-500' : 'border-blue-800'}`}
                              onDrop={(e) => pdfDropHandler(e, 'nidForm')}
                              onDragOver={dragOverHandler}
                            >
                              <p className="ps-3 pt-2 pe-3 uploadSectionFont text-center w-60">
                                Upload NID
                              </p>
                              {nidDoc?.name && (
                                <span className="text-center text-sm w-full block mt-8 blue-color relative">
                                  {nidDoc?.name?.length > 20
                                    ? nidDoc?.name?.substring(0, 14) + "..."
                                    : nidDoc?.name}
                                  {!isLoading && (
                                    <img
                                      src="/assets/images/cross.png"
                                      className="cross absolute removeTheFileSecond"
                                      onClick={() =>
                                        handleClear("pdf", "nidForm")
                                      }
                                    />
                                  )}
                                </span>
                              )}
                              {progress2Loading === "noprogress" && (
                                <span className="text-center uploadSectionFont w-full block mt-4 text-sky-700 pt-3">
                                  Drag and Drop Files here
                                </span>
                              )}
                              {progress2Loading === "noprogress" && (
                                <label className="custom_btn_primary cursor-pointer bg-blue-500 hover:bg-blue-600 uploadSectionFont text-white font-semibold py-1 px-2 rounded w-32 text-center button flex justify-center commonBtn">
                                  <input
                                    type="file"
                                    accept="image/*, application/pdf" // Allow only pdf files
                                    onChange={(e) => handleFileChangepdf(e, false, 'nidForm')}
                                    className="mb-4 custom_btn_primary commonBtn"
                                    hidden
                                    name="nidPdf"
                                  />
                                  Upload
                                  <img
                                    className="uploadModalLogos"
                                    src="assets/images/icon_upload.svg"
                                  />
                                </label>
                              )}
                            </div>
                            <span
                              className="numberRequestModal"
                              style={{ color: formErrors?.nidDocError ? "red" : "inherit" }}
                            >
                              {formErrors?.nidDocError}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end mt-5 mb-3">
                        <div className="flex justify-center align-middle me-2">
                          <button
                            type="button"
                            className=" border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2 custom_btn_secondary"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="flex justify-center align-middle me-3">
                          <button
                            disabled={requestSubmitLoader}
                            type="submit"
                            className=" relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2"
                            onClick={handleSubmit}
                          >
                            Raise
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <VideoKYCDetailScreen 
            columns={phoneTableHeader} 
            rowData={selectedStatus || searchData ? filterData : updatePhoneData}
            tableLoading={dataLoading}
            nPages={nPages}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            handleResendLink={reSendLink}
            responseScreenWidth = {responseScreenWidth}
          />
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div> 
    : 

    <div className={`flex dark:bg-dark-background updatePhone mainBodyContainer`}>
    {/* style={{ opacity: modalOpacity ? 0.5 : 1 }} */}
    <SideMenu headerName="Update Phone Number"/>
    <div className="min-h-screen flex-1 h-screen overflow-y-auto mainContainer_responsive">
      <Header />
      <div
        className="z-0 p-10 mainContainer updatePhoneRespContainer"
        style={{ opacity: dataLoading ? 0.5 : 1 }}
      >
        <div className="z-0">
          {/* <div className='flex border justify-between'>
            <div className='w-1/5 border border-red-800'>
              <p>Total Request</p>
            </div>
            <div className='w-1/5 border border-red-800'>
            <p>Total Request</p>
            </div>
            <div className='w-1/5 border border-red-800'>
            <p>Total Request</p>
            </div>
            <div className='w-1/5 border border-red-800'>
            <p>Total Request</p>
            </div>
          </div>  */}
          <div className="flex justify-between rangeResp">
            {/* handleDateSubmit={handleDateRangeSubmit} data={filterDate} handleSubmit={handleSubmit} datesData={datesData} */}
            <div className="p-1 flex gap-2 justify-center">
              <CustomDateRangeInput className="border border-none px-4 focus:outline-none focus:border-blue-500" handleDateSubmit={handleDateRangeSubmit} data={filterDate} handleSubmit={handleSearchDate} datesData={datesData} />
              <button
                onClick={showModal}
                className="bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 custom_btn_primary commonBtn h-10 rounded-md flex gap-2 items-center"
              >
                Download Report
                <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
              </button>
            </div>
          </div>
          <div className="flex gap-2 flex-wrap justify-center mt-2">
            {Object.keys(statData)?.map((card, idx) => (
              <div
                key={`card_${idx}`}
                className="flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white  p-4 gap-2 w-full accountVerificationCardResp"
              >
                <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                  <h3
                    className="inline-block relative font-quicksand text-3xl mr-8"
                    style={{ color: cardsColor?.[card] }}
                  >
                    {statData[card]}
                  </h3>
                  <p className="text-black font-quicksand text-lg font-medium leading">
                    {cardsLabel[card]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {showReportsDownloadModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
          <div class="relative p-4 w-full max-w-2xl max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
              <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                  Download Report
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeDateModal}>
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 flex gap-2 justify-center">
                <DatePicker
                  name="startDate"
                  value={downloadDate?.startDate}
                  onChange={(val) => handleDateDownload(val, 'startDate')}
                  placeholder="From" // Placeholder for start date
                  className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                  disabledDate={getCurrentDate}
                />
                <DatePicker
                  name="endDate"
                  value={downloadDate?.endDate}
                  onChange={(val) => handleDateDownload(val, 'endDate')}
                  placeholder="To" // Placeholder for end date
                  className="border w-full px-4 py-2 focus:outline-none focus:border-blue-500  rounded-md"
                  disabledDate={getCurrentDate}
                />
              </div>

              <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="static-modal" type="button" class="flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn" onClick={fetchDataAndDownload}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>}

        <div className="py-4">
          <div className="m-auto mb-3 raiseRespButton">
              {/* <button
                type="button"
                className=" ms-1 raiseNewBtn btnRaisedReq rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn"
                onClick={openModal}
              >
                Raise New Request +
              </button> */}
          </div>
          <div className="flex flex-row-reverse">
            <div className="dropdown flex w-60">
              <select
                value={selectedStatus}
                onChange={(e) => handleStatus(e)}
                className="p-2 rounded-lg border w-full"
              >
                <option value="all">All</option>
                {options?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex relative ms-2">
              <img
                src="assets/images/Vector.svg"
                className="searchLogo absolute"
              />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
                className="numberSearch"
              ></input>
            </div>
          </div>
        </div>
        <div>
          {isOpen && (
            <div
              id="static-modal"
              data-modal-backdrop="static"
              tabindex="-1"
              aria-hidden="true"
              class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full "
            >
              {requestSubmitLoader && (
                <div className="absolute w-full h-full flex items-center bottom-10 right-2 justify-center z-10">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="60"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[]}
                  />
                </div>
              )}
              <div
                className="modal absolute respRequestCreateModal"
                style={
                  requestSubmitLoader
                    ? { filter: "blur(0.7px)", pointerEvents: "none" }
                    : null
                }
              >
                <div className="modal-content rounded-lg bg-white border modalContainer raiseRequest p-5">
                  <div className="ps-3 pe-3 pt-1">
                    <p className="font-small border-b-2 pb-5 font-semibold text-lg ">
                      Raise New Request
                    </p>
                  </div>
                  <div className="modalForm">
                    <div className="flex flex-col">
                      <div className="flex flex-col ps-3 pe-3 mt-3">
                        <label
                          htmlFor="first_name"
                          className="numberRequestModal"
                        >
                          First Name 
                        </label>
                        <input
                          type="text"
                          id="inputD"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          placeholder="Enter First Name"
                          className={`py-1 rounded outline-none ps-2 ${formErrors?.first_name
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.first_name
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.first_name}
                        </span>
                      </div>

                      <div className="flex flex-col ps-3 pe-3 mt-3 ">
                        <label
                          htmlFor="last_name"
                          className="numberRequestModal"
                        >
                          Last Name 
                        </label>
                        <input
                          type="text"
                          id="inputE"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          placeholder="Enter Last Name"
                          className={`py-1 rounded outline-none ps-2 ${formErrors?.last_name
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.last_name
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.last_name}
                        </span>
                      </div>
                    </div>

                    <div className=" flex flex-col ps-3 pe-3 mt-4">
                      <label
                        htmlFor="bankAccountNumber"
                        className="numberRequestModal"
                      >
                        Customer's Bank Account Number
                      </label>
                      <input
                        type="text"
                        id="inputA"
                        name="bankAccountNumber"
                        value={values.bankAccountNumber}
                        onChange={handleChange}
                        placeholder="Enter Customer's Bank Account Number"
                        className={`py-1 rounded outline-none ps-2  ${formErrors?.bankAccountNumber
                            ? "border border-red-500"
                            : " border"
                          }`}
                      />
                      <span
                        className="numberRequestModal"
                        style={{
                          color: formErrors?.bankAccountNumber
                            ? "red"
                            : "inherit",
                        }}
                      >
                        {formErrors?.bankAccountNumber}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <div className="flex flex-col ps-3 pe-3 mt-3">
                        <label
                          htmlFor="oldMobileNum"
                          className="numberRequestModal"
                        >
                          Old Mobile Number
                        </label>
                        <PhoneInput
                          country={'bd'}
                          id="inputD"
                          name="oldMobileNum"
                          inputStyle={{ height: '31px', border: '1px solid #f6f7f8' }}
                          containerStyle={formErrors?.newMobileNum ? { border: '1px solid red' } : { border: '1px solid #e3e8ec' }}
                          buttonStyle={{ border: 'none', borderRight: '1px solid #e3e8ec' }}
                          value={values.oldMobileNum}
                          onChange={(value, data, event, formattedValue) => handleDateChange(value, data, event, formattedValue, 'oldMobileNum')}
                          placeholder="Enter Contact Number"
                          className={`rounded outline-none w-full ${formErrors?.oldMobileNum
                              ? "border border-red-500"
                              : " border"
                            }`}
                        // value={this.state.phone}
                        // onChange={phone => this.setState({ phone })}
                        />
                        {/* <input
                          type="number"
                          id="inputD"
                          name="oldMobileNum"
                          value={values.oldMobileNum}
                          onChange={handleChange}
                          placeholder="Enter Contact Number"
                          className={`py-1 rounded outline-none ps-2 ${
                            formErrors?.oldMobileNum
                              ? "border border-red-500"
                              : " border"
                          }`}
                        /> */}
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.oldMobileNum
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.oldMobileNum}
                        </span>
                      </div>

                      <div className="flex flex-col ps-3 pe-3 mt-3">
                        <label
                          htmlFor="newMobileNum"
                          className="numberRequestModal"
                        >
                          New Mobile Number
                        </label>
                        <PhoneInput
                          id="inputE"
                          name="newMobileNum"
                          country={'bd'}
                          value={values.newMobileNum}
                          onChange={(value, data, event, formattedValue) => handleDateChange(value, data, event, formattedValue, 'newMobileNum')}
                          inputStyle={{ height: '31px', border: '1px solid #f6f7f8' }}
                          containerStyle={formErrors?.newMobileNum ? { border: '1px solid red' } : { border: '1px solid #e3e8ec' }}
                          buttonStyle={{ border: 'none', borderRight: '1px solid #e3e8ec' }}
                          placeholder="Enter Contact Number"
                          className={`rounded outline-none ${formErrors?.newMobileNum
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        {/* <input
                          type="number"
                          id="inputE"
                          name="newMobileNum"
                          value={values.newMobileNum}
                          onChange={handleChange}
                          placeholder="Enter Contact Number"
                          className={`py-1 rounded outline-none ps-2 ${
                            formErrors?.newMobileNum
                              ? "border border-red-500"
                              : " border"
                          }`}
                        /> */}
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.newMobileNum
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.newMobileNum}
                        </span>
                      </div>
                    </div>

                    <div className="">
                      <div className="ps-3 pe-3 mt-3">
                        <label
                          htmlFor="verificationMobileNum"
                          className="numberRequestModal"
                        >
                          Verification Mobile Number
                        </label>
                        <PhoneInput
                          id="inputE"
                          name="verificationMobileNum"
                          country={'bd'}
                          value={values.verificationMobileNum}
                          onChange={(value, data, event, formattedValue) => handleDateChange(value, data, event, formattedValue, 'verificationMobileNum')}
                          inputStyle={{ height: '31px', border: '1px solid #f6f7f8' }}
                          containerStyle={formErrors?.verificationMobileNum ? { border: '1px solid red' } : { border: '1px solid #e3e8ec' }}
                          buttonStyle={{ border: 'none', borderRight: '1px solid #e3e8ec' }}
                          placeholder="Enter Contact Number"
                          className={`rounded outline-none ${formErrors?.verificationMobileNum
                              ? "border border-red-500"
                              : " border"
                            } w-full`}
                        />
                        {/* <input
                          type="number"
                          id="inputF"
                          name="verificationMobileNum"
                          value={values.verificationMobileNum}
                          onChange={handleChange}
                          placeholder="Enter Contact Number"
                          className={`py-1 rounded outline-none ps-2 ${
                            formErrors?.verificationMobileNum
                              ? "border border-red-500"
                              : " border"
                          }`}
                        /> */}
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.verificationMobileNum
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {formErrors?.verificationMobileNum}
                        </span>
                      </div>

                      <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                        <label htmlFor="email" className="numberRequestModal">
                          Email
                        </label>
                        <input
                          type="email"
                          id="inputC"
                          name="verificationEmail"
                          value={values.verificationEmail}
                          onChange={handleChange}
                          placeholder="Enter Email"
                          className={`py-1 rounded outline-none ps-2  ${formErrors?.verificationEmail
                              ? "border border-red-500"
                              : " border"
                            }`}
                        />
                        <span
                          className="numberRequestModal"
                          style={{
                            color: formErrors?.verificationEmail ? "red" : "inherit",
                          }}
                        >
                          {formErrors?.verificationEmail}
                        </span>
                      </div>
                    </div>

                    <div className=" flex flex-col ps-3 pe-3 mt-3">
                      <label
                        htmlFor="NID_number"
                        className="numberRequestModal"
                      >
                        NID Number
                      </label>
                      <input
                        type="number"
                        id="inputC"
                        name="NID_number"
                        value={values.NID_number}
                        onChange={handleChange}
                        onKeyDown={handleValidNumberInput}
                        placeholder="NID Number"
                        className={`py-1 rounded outline-none ps-2  ${formErrors?.NID_number
                            ? "border border-red-500"
                            : " border"
                          }`}
                      />
                      <span
                        className="numberRequestModal"
                        style={{
                          color: formErrors?.NID_number ? "red" : "inherit",
                        }}
                      >
                        {formErrors?.NID_number}
                      </span>
                    </div>

                    {/* <div className=' flex flex-col ps-3 pe-3 mt-3'>
                            <label htmlFor="KYC_language" className='numberRequestModal'>Language</label>
                            <div className='flex pt-2'>
                              <div class="flex items-center">
                                <input checked={values?.KYC_language === "Bangla"} id="bangla" type="radio" value="Bangla" name="KYC_language" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600" onClick={handleChange}/>
                                <label for="KYC_language" class="ms-2 font-medium text-gray-900 dark:text-gray-300">Bangla</label>
                              </div>
                              <div class="flex items-center ps-4">
                                <input checked={values?.KYC_language === "English"} id="english" type="radio" value="English" name="KYC_language" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" onClick={handleChange}/>
                                <label for="KYC_language" class="ms-2 font-medium text-gray-900 dark:text-gray-300">English</label>
                              </div>
                            </div>
                            <span className='numberRequestModal'  style={{ color: formErrors?.KYC_language ? 'red' : 'inherit' }}>{formErrors?.KYC_language}</span>
                          </div> */}
                    <div className="flex flex-col justify-between">
                    <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                      <label htmlFor="dob" className="numberRequestModal">
                        Date Of Birth
                      </label>
                      <DatePicker
                        id="inputC"
                        name="dob"
                        value={values.dob}
                        onChange={(val) => handleChange(val, 'dob')}
                        disabledDate={getCurrentDate}
                        placeholder="DOB"
                        className={`py-1 rounded outline-none ps-2  ${formErrors?.dob
                            ? "border border-red-500"
                            : " border"
                          }`}
                      />
                      <span
                        className="numberRequestModal"
                        style={{ color: formErrors?.dob ? "red" : "inherit" }}
                      >
                        {formErrors?.dob}
                      </span>
                    </div>

                    <div className=" flex flex-col ps-3 pe-3 mt-3 w-full">
                      <label htmlFor="dob" className="numberRequestModal">
                        KYC link Expire
                      </label>
                      <select
                        value={values.expiresIn}
                        name="expiresIn"
                        onChange={(val)=>handleChange(val, 'expiresIn')}
                        className={`px-3 py-1 expiryDropdown rounded w-full ${
                          formErrors?.expiresIn
                            ? "border border-red-500"
                            : " border"
                        }`}
                      >
                        <option hidden value="Select Expire Time">Select Expire Time</option>
                        {[...Array(24).keys()].map(num => (
                          <option key={num + 1} value={num + 1}>{num + 1}h</option>
                        ))}
                      </select>
                      <span
                        className="numberRequestModal"
                        style={{ color: formErrors?.expiresIn ? "red" : "inherit" }}
                      >
                        {formErrors?.expiresIn}
                      </span>
                    </div>
                    </div>

                    <div className="flex gap-4 justify-center">
                      <div className="flex gap-2 justify-evenly flex-col respUploadData">
                        {/* pdf uploader 1 */}
                        <div className="flex flex-col cardUploadResp">
                          <div
                            className={`paperFormCard mt-4 pb-3 border-dashed border-2 bg-white rounded-md flex flex-col items-center justify-center file-upload-container gap-4 ${formErrors?.formDocError ? 'border-red-500' : 'border-blue-800'}`}
                            onDrop={(e) => pdfDropHandler(e, 'paperForm')}
                            onDragOver={dragOverHandler}
                          >
                            <p className=" ps-4 pt-2 pe-4 uploadSectionFont text-center w-60">
                              Upload PDF Scanned Copy of Paper Form submission
                            </p>
                            {formDoc?.name && (
                              <span className="text-center text-sm w-full block mt-1 blue-color relative">
                                {formDoc?.name?.length > 20
                                  ? formDoc?.name?.substring(0, 14) + "..."
                                  : formDoc?.name}
                                {!isLoading && (
                                  <img
                                    src="/assets/images/cross.png"
                                    className="cross absolute removeTheFileFirst"
                                    onClick={() =>
                                      handleClear("pdf", "paperForm")
                                    }
                                  />
                                )}
                              </span>
                            )}
                            {progress1Loading === "noprogress" && (
                              <span className="text-center uploadSectionFont w-full block mt-1 text-sky-700">
                                Drag and Drop Files here
                              </span>
                            )}

                            {progress1Loading === "noprogress" && (
                              <>
                                <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white uploadSectionFont font-semibold py-1 px-2 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                                  <input
                                    type="file"
                                    accept="application/pdf"
                                    onChange={(e) => handleFileChangepdf(e, false, 'paperForm')}
                                    className="mb-4 custom_btn_primary commonBtn"
                                    hidden
                                    name="paperForm"
                                  />
                                  Upload
                                  <img
                                    className="uploadModalLogos"
                                    src="assets/images/icon_upload.svg"
                                  />
                                </label>
                              </>
                            )}
                          </div>
                          <span
                            className="numberRequestModal"
                            style={{ color: formErrors?.formDocError ? "red" : "inherit" }}
                          >
                            {formErrors?.formDocError}
                          </span>
                        </div>
                        {/* pdf uploader 2 */}
                        <div className="flex flex-col cardUploadResp">
                          <div
                            className={`paperFormCard mt-4 pb-3 border-dashed border-blue-800 border-2 bg-white rounded-md flex flex-col items-center justify-center file-upload-container gap-4 ${formErrors?.nidDocError ? 'border-red-500' : 'border-blue-800'}`}
                            onDrop={(e) => pdfDropHandler(e, 'nidForm')}
                            onDragOver={dragOverHandler}
                          >
                            <p className="ps-3 pt-2 pe-3 uploadSectionFont text-center w-60">
                              Upload NID
                            </p>
                            {nidDoc?.name && (
                              <span className="text-center text-sm w-full block mt-8 blue-color relative">
                                {nidDoc?.name?.length > 20
                                  ? nidDoc?.name?.substring(0, 14) + "..."
                                  : nidDoc?.name}
                                {!isLoading && (
                                  <img
                                    src="/assets/images/cross.png"
                                    className="cross absolute removeTheFileSecond"
                                    onClick={() =>
                                      handleClear("pdf", "nidForm")
                                    }
                                  />
                                )}
                              </span>
                            )}
                            {progress2Loading === "noprogress" && (
                              <span className="text-center uploadSectionFont w-full block mt-4 text-sky-700 pt-3">
                                Drag and Drop Files here
                              </span>
                            )}
                            {progress2Loading === "noprogress" && (
                              <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 uploadSectionFont text-white font-semibold py-1 px-2 rounded w-32 text-center button flex justify-center custom_btn_primary commonBtn">
                                <input
                                  type="file"
                                  accept="image/*, application/pdf" // Allow only pdf files
                                  onChange={(e) => handleFileChangepdf(e, false, 'nidForm')}
                                  className="mb-4 custom_btn_primary commonBtn"
                                  hidden
                                  name="nidPdf"
                                />
                                Upload
                                <img
                                  className="uploadModalLogos"
                                  src="assets/images/icon_upload.svg"
                                />
                              </label>
                            )}
                          </div>
                          <span
                            className="numberRequestModal"
                            style={{ color: formErrors?.nidDocError ? "red" : "inherit" }}
                          >
                            {formErrors?.nidDocError}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end mt-5 mb-3">
                      <div className="flex justify-center align-middle me-2">
                        <button
                          type="button"
                          className=" border rounded-md borderButton commonBtnBorder w-20 p-1 text-white font-medium py-2"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="flex justify-center align-middle me-3">
                        <button
                          disabled={requestSubmitLoader}
                          type="submit"
                          className=" relative border rounded-md bg-blue-600 w-28 p-1 text-white font-medium custom_btn_primary commonBtn py-2"
                          onClick={handleSubmit}
                        >
                          Raise
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <VideoKYCDetailScreen 
          columns={phoneTableHeader} 
          rowData={selectedStatus || searchData ? filterData : updatePhoneData}
          tableLoading={dataLoading}
          nPages={nPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          handleResendLink={reSendLink}
          responseScreenWidth = {responseScreenWidth}
          />
      </div>
    </div>
    <ToastContainer></ToastContainer>
    </div>
  );
};

export default WithAuth(UpdatePhoneNumber);
