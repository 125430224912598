import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;


const CustomDateRangeInput = ({ handleDateSubmit,handleSubmit,datesData }) => {
  const disabledDate = current => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  };
  
  return (
    <div className='relative'>
      <RangePicker
        className='h-10 border border-none'
        onChange={handleSubmit}
        value={datesData}
        disabledDate={disabledDate}
      />
    </div>
  );
};

export default CustomDateRangeInput;
