import Navbar from "../../common/Navbar"
import styles from "../../styles/informationUpdate.module.scss";
import Header from "../../component/Header";
import SideMenu from "../../component/SideMenu";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import API from "../../helpers/api";
import Pagination from "../../component/Pagination";
import moment from "moment";
import "../../styles/detailsPagee.scss";
import Breadcrumb from "../../component/Breadcrumb";
import { encodeData } from "../../common/commonFunction";
export default function RaiseAccountRequest() {
    const [accountNumber, setAccountNumber] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [sideBarCondition, setSideBarCondition] = useState(false);
    const [requestedPage, setRequestedPage] = useState("");
    const [raiseRequestList, setRaiseRequestList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const [showModal, setShowModal] = useState(false)
    let currentRecords = raiseRequestList?.slice(indexOfFirstRecord, indexOfLastRecord);
    let nPages = raiseRequestList?.length ? Math.ceil(raiseRequestList?.length / recordsPerPage) : 0;
    const navigate = useNavigate();
    const [raiseRequestPurpose ,setRaiseRequestPurpose]= useState([])

    const [accountInfo ,setAccountInfo]= useState(false)
    const [personalInfo ,setPersonalInfo]= useState(false)
    const [nomineeInfo ,setNomineeInfo]= useState(false)
    const [addressDetailsInfo ,setAddressDetailsInfo]= useState(false)
    const [accountOperator ,setAccountOperator]= useState(false)
    const [relatedDocuments ,setRelatedDocuments]= useState(false)
    const [contactInfo ,setContactInfo]= useState(false)
    const [transactionLimit ,setTransactionLimit]= useState(false);
    const [raiseStatus,setRaiseStatus] = useState("");
    const [filterRequestedPage, setFilterRequestedPage] = useState({});

    const [searchQuery, setSearchQuery] = useState("");

    const  handleSearch = async (e) => {
        setDataLoading(true)
        if(searchQuery ===""){
            getRaiseRequestDetails(raiseStatus);
            return
        }
        let account_no = searchQuery;
        let status = raiseStatus ? raiseStatus : "" ;
        let request_title = requestedPage ? requestedPage : "";
        let payload = {}
        if(status==="" && request_title==="All" ){
            payload = {
                account_no
            }
        }
        else if(status != "" && request_title === "All"){
            payload = {
                account_no,
                status
            }
        }
        else if(status==="" && request_title !="All"){
            payload = {
                account_no,
                status
            }
        }
        else{
            payload = {
                account_no,
                status,
                request_title
            }
        }
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request`,'',payload);
            if (data?.success) {
                setRaiseRequestList(data?.data);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                toast.error(data?.message);
                setRaiseRequestList();
                setDataLoading(false)
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            console.log("error--->>",error);
            setRaiseRequestList();
            setDataLoading(false)
        }

    };


    const handleEnterSearch = (e) => {
        if(e?.key === "Enter" || e?.code === "Enter") {
          handleSearch();
        }
      }

      const handleSearchFilterChange = (e) => {
        setSearchQuery(e?.target?.value);
        // if (searchQuery == '') {
        //   getTaskList(1, recordsPerPage, filterData, searchValue, selectedSortOptions);
        // }
      }

      const handleClear = () => {
        setDataLoading(true)
        setSearchQuery("");
        getRaiseRequestDetails(raiseStatus);
      }

      const isMobileDevice = window.screen.width <= 900;

      const getRaiseRequestDetails = async ( status = 'pending') => {
        let payload = {}
        let account_no = searchQuery ? searchQuery : "";
        let request_title = requestedPage ? requestedPage : "";
        if(request_title ==="All" && account_no === ""){
            payload = {
                status
            }
        }
        else if(request_title !="All" && account_no === ""){
            payload = {
                request_title,
                status
            }
        }
        else if(request_title ==="All" && account_no != ""){
            payload = {
                account_no,
                status
            }
        }
        else{
            payload = {
                account_no,
                status,
                request_title
            }
        }
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request`,'',payload);
            setRaiseRequestList(data?.data);
            if (data?.success) {
                // setIvrConfigCalling(raiserequestdata);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                toast.error(data?.message);
                setRaiseRequestList();
                setDataLoading(false)
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            console.log("error--->>",error);
            setRaiseRequestList();
            setDataLoading(false)
        }
    };

    const requestedPageFilter = async () => {
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request/filter/list`);
            setFilterRequestedPage(data?.data);
            if (data?.success) {
                // setIvrConfigCalling(raiserequestdata);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                toast.error(data?.message);
                // setRaiseRequestList();
                setDataLoading(false)
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            console.log("error--->>",error);
            // setRaiseRequestList();
            setDataLoading(false)
        }
    };

    const handleSelectChange = async (e) => {
        setRequestedPage(e?.target?.value);
        if (e?.target?.value === "All") {
            getRaiseRequestDetails(raiseStatus);
            return;
        }
        const request_title = e?.target?.value;
        let account_no = searchQuery ? searchQuery : "";
        let payload = {}
        let status = raiseStatus ? raiseStatus : "" ;
        if(status ==="" && account_no ===""){
            payload = {
                request_title
            }
        }
        else if(status !="" && account_no ===""){
            payload = {
                request_title,
                status
            }
        }
        else if(status ==="" && account_no !=""){
            payload = {
                request_title,
                account_no
            }
        }
        else{
            payload = {
                request_title,
                account_no,
                status
            } 
        }
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request`,'',payload);
            setRaiseRequestList(data?.data);
            if (data?.success) {
                // setIvrConfigCalling(raiserequestdata);
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                toast.error(data?.message);
                setRaiseRequestList();
                setDataLoading(false)
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
            console.log("error--->>", error);
            setRaiseRequestList();
            setDataLoading(false)
        }
    };

    const updateStatus = async (item,status) => {
        const payload = {
            "status":status
        }
        const encodedPayload = encodeData(payload)
        const id = item?.id;
        try {
          const response = await API.apiPut(`/v1/agentBanking/raise/request/status/${id}`, {payload: encodedPayload})
          if (response?.status) {
            toast.success(response?.data?.message);
            getRaiseRequestDetails();
          }
          else {
            getRaiseRequestDetails();
            toast.error(response?.data?.error)
          }
          // setWorkerLoading(false)    
        }
        catch (error) {
          // setWorkerLoading(false)
        //   handleErrorMessage(error);
          console.log(error)
        }
    }

    useEffect(() => {
        setDataLoading(true)
        getRaiseRequestDetails();
        requestedPageFilter();
    }, []);

    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const raiserequestdata = [
        {
            // request_id: "123",
            SL: "1",
            request_page: "change signature",
            requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            SL: "2",
            request_page: "change signature",
            requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        },
        {
            // request_id: "123",
            SL: "3",
            request_page: "change signature",
            requester_purpose: "Test user",
            requested_by: "test",
            change_value: "value",
            requested_date: "12/06/2023",
            previous_value: "val",
            request_type: "add",
            // remarks: "good",
            approve_status: "approve",
        }
    ]

    const AccountImageHeader = [
        {
            title: "account_title,",
            label: "Account Title,"
        },
        {
            title: "account_no",
            label: "Account No"
        },
        {
            title: "account_type",
            label: "Account Type"
        },
        {
            title: "open_date",
            label: "Open Date"
        },
        {
            title: "account_nature",
            label: "Account Nature"
        },
    ];

    const transactionHeader = [
        {
            title: "transaction_type,",
            label: "Transaction Type,"
        },
        {
            title: "debit_or_credit",
            label: "Debit/Credit"
        },
        {
            title: "no_of_transaction_day",
            label: "Transaction/day"
        },
        {
            title: "transaction_amount_day",
            label: "Transaction Amount/day"
        },
        {
            title: "no_of_transaction_month",
            label: "Transaction/month"
        },
    ];

    const ContactHeader = [
        {
            title: "mobile_no",
            label: "Mobile Number"
        },
        {
            title: "alternative_mobile_no",
            label: "Alternate Mobile Number"
        },
        {
            title: "email",
            label: "email/day"
        },
    ];

    const RelatedDocHeader =[
        {
            title: "document_type,",
            label: "Document Type,"
        },
        {
            title: "document_no",
            label: "Document No."
        },
        {
            title: "issue_date",
            label: "Issue Date"
        },
        {
            title: "doc_front_img",
            label: "Doc Front Image"
        },
        {
            title: "doc_back_img",
            label: "Doc Back Image"
        },
    ];

    const AccountOperatorHeader = [
        {
            title: "customer_id",
            label: "Customer Id"
        },
        {
            title: "customer_name",
            label: "Customer Name"
        },
        {
            title: "customer_dob",
            label: "Customer Dob"
        },
    ];

    const AddressHeader = [
        {
            title: "address_description,",
            label: "Address Description,"
        },
        {
            title: "district_name",
            label: "District Name"
        },
        {
            title: "upazilla_name",
            label: "Upazilla Name"
        },
        {
            title: "post_code",
            label: "Post Code"
        },
        {
            title: "village_name",
            label: "Village Name"
        },
    ];
    const NomineeImageHeader = [
        {
            title: "Nominee Name",
            label: "Nominee Name"
        },
        {
            title: "Document No",
            label: "Document No"
        },
        {
            title: "Document Typy",
            label: "Document Typy"
        },
        {
            title: "Relation",
            label: "Relation"
        },
        {
            title: "Nominee Img",
            label: "Nominee Img"
        },
    ];

    function handelClick(event) {
        let selectedValue = "";
        if(event?.target?.value===""){
            selectedValue = "pending";
        }
        else{
            selectedValue = event?.target?.value;
        }
        getRaiseRequestDetails(selectedValue);
        setRaiseStatus(selectedValue);
    }
    

    const getRaiseRequestPurpose = async (item) => {
        if (item.request_title === 'nominee_info') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(true);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'account_info') {
            setAccountInfo(true);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'personal_info') {
            setAccountInfo(false);
            setPersonalInfo(true);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'contact_info') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(true);
            setTransactionLimit(false);
        } else if (item.request_title === 'related_documents') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(true);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'account_operator') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(true);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'address_details') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(true);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(false);
        } else if (item.request_title === 'transaction_limit') {
            setAccountInfo(false);
            setPersonalInfo(false);
            setNomineeInfo(false);
            setAddressDetailsInfo(false);
            setAccountOperator(false);
            setRelatedDocuments(false);
            setContactInfo(false);
            setTransactionLimit(true);
        }
        const payload = {
            request_title: item?.request_title,
            id: item?.agent_banking_id
          }
        try {
            const { data } = await API.apiGet(`/v1/agentBanking/raise/request/details?id=${payload.id}&request_title=${payload.request_title}`);
            setRaiseRequestPurpose([data?.data]);
            if (data?.success) {
                toast.success(data?.message);
                setShowModal(true);
                // setDataLoading(false)
            } else {
                toast.error(data?.message);
                // setDataLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            // setDataLoading(false)
        }
    };

    return (
        <>
            <div className={`flex dark:bg-dark-background dashboard-container dashboard-main`}>
                <SideMenu setSideBarCondition={setSideBarCondition} hasProps={true} />
                <div className={`flex-1 overflow-y-scroll ${styles.raiseRequestContainer} ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header />
                    {dataLoading && <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                        <ColorRing
                            visible={true}
                            height="80"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[]}
                        />
                    </div>}
                    <Navbar />

                    {showModal && (
                        <div
                            id="static-modal"
                            data-modal-backdrop="static"
                            tabindex="-1"
                            aria-hidden="true"
                            class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full detailsPagee"
                        >
                            <div class={`${styles.detailsModal} ${!sideBarCondition && styles.modalWithClosedSidebar} relative p-4 mt-12 max-h-full`}>
                            {accountInfo ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {AccountImageHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.account_title}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.account_no}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.account_type}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.open_date}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.account_nature}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">First Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.account_title}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Account No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.account_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Account Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.account_type}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Open Date</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.open_date}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Account Nature</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.account_nature}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> :""}


                                {nomineeInfo ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {NomineeImageHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.nominee_name}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.document_no}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.document_typy}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.relation}</td>
                                                        <td className="py-2 ps-4 "><img src={item?.nominee_img} className="detailReportApproval "></img></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Nominee Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.nominee_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.document_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.document_typy}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Relation</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.relation}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Nominee Image</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5"><img src={item?.nominee_img} className="detailReportApproval "></img></span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {addressDetailsInfo ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {AddressHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.address_description}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.district_name}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.upazilla_name}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.post_code}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.village_name}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Address Description</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.address_description}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">District Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.district_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Upazilla Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.upazilla_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Post Code</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.post_code}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Village Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.village_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {transactionLimit ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {transactionHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.transaction_type}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.debit_or_credit}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.no_of_transaction_day}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.transaction_amount_day}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.no_of_transaction_month}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Transaction Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.transaction_type}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Debit/Credit</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.debit_or_credit}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Transaction/Day</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.no_of_transaction_day}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Transaction Amount/Day</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.transaction_amount_day}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Transaction/Month</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.no_of_transaction_month}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {contactInfo ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {ContactHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.mobile_no}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.alternative_mobile_no}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.email}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Mobile No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.mobile_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Alternative Mobile No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.alternative_mobile_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Email</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {relatedDocuments ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {RelatedDocHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.document_type}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.document_no}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.issue_date}</td>
                                                        <td className="py-2 ps-4 flex justify-between"><img src={item?.doc_front_img} className="detailReportApproval"></img></td>
                                                        <td className="py-2 ps-4 "><img src={item?.doc_back_img} className="detailReportApproval "></img></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.document_type}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.document_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Issue Date</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.issue_date}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document Front Image</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5"><img src={item?.doc_front_img} className="detailReportApproval"></img></span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Document Back Image</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5"><img src={item?.doc_back_img} className="detailReportApproval "></img></span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {accountOperator ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {AccountOperatorHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.customer_id}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.customer_name}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.customer_dob}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Customer Id</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.customer_id}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Customer Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.customer_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Customer DOB</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.customer_dob}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}

                                {personalInfo ? !isMobileDevice ? <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 ps-4 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-5 m-auto mt-3">
                                        <table className="min-w-full rounded-lg transactionDetailContainer text-left reconcilitionTable">
                                            <thead>
                                                <tr className="tableHeadColor">
                                                    {AccountImageHeader.map((item) =>
                                                        <th className="py-2 px-4" style={{ fontSize: '16px' }} title={`${item.title}`}>{item.label}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {raiseRequestPurpose?.length && raiseRequestPurpose.map((item) =>
                                                    <tr className="border-b">
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.first_name}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.DOB}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.occupation}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.gender}</td>
                                                        <td className="py-2 ps-4 " style={{ fontSize: '16px' }}>{item?.father_name}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>:<div class="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                    <div class="flex items-center justify-between pt-4 pb-3 ps-2 pe-4 md:ps-5 md:pt-5 md:pe-5 rounded-t dark:border-gray-600">
                                        <h3 class="text-small font-medium text-gray-900 ps-1 dark:text-white">
                                            Detail Report about Approval
                                        </h3>
                                        <div className="cursor-pointer">
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                         {raiseRequestPurpose?.length && raiseRequestPurpose.map((item,index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">First Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.first_name}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">DOB</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.DOB}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Occupation</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.occupation}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Gender</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.gender}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Father Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.father_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>:""}
                            </div>
                        </div>
                    )}

                    <div className={`z-0 p-10 ${isMobileDevice && 'p-5'}`} style={{ opacity: dataLoading ? 0.1 : 1 }}>
                        {/* <button type="button" className="bg-white p-3 border mb-2 rounded" >Transaction Type</button> */}
                        <div className={`p-3 ${styles.searchCard}`}>
                            <div className="flex justify-between">
                                <div className="flex">
                                    <Breadcrumb
                                        showBackButton={true}
                                        redirectUrl={"/ConventionalAgentBankingCard"}
                                    />
                                    <h1 className="text-xl ms-4 mt-2 font-semibold">Filter</h1>
                                </div>
                                {/* <div className='flex justify-center align-middle me-3'>
                                    <button type="submit" className=' relative border rounded-md bg-blue-600 w-20 p-1 text-white font-medium commonBtn py-2'>
                                        Clear</button>
                                </div> */}
                            </div>
                            <div className='flex p-6 justify-center ps-3 pe-3'>
                                <div className={`w-full flex justify-center ${isMobileDevice && 'flex-col'}`}>
                                    <label htmlFor="requested_page" className='numberRequestModal mt-2'>Requested Page : </label>
                                    <select
                                        id="requested_page"
                                        name="requested_page"
                                        value={requestedPage}
                                        onChange={handleSelectChange}
                                        className={`py-2 rounded outline-none ps-2 border ${isMobileDevice ? 'ms-0' : 'ms-6'}`}
                                    >
                                        <option hidden value="">----- Select Requested Page -----</option>
                                        <option value="All">All</option>
                                        {Object.entries(filterRequestedPage).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-between py-4'>
                            <div className="flex flex-row-reverse">
                                {/* <div className=''>
                                    <button
                                        type="button"
                                        className="raiseNewBtn rounded-lg bg-blue-500 text-white text-sm flex justify-center align-middle commonBtn ms-6"
                                    // onClick={openModal}
                                    onC
                                    >
                                        Submit
                                    </button>
                                </div> */}
                                <div className='ms-6'>
                                    <select
                                        id="actions"
                                        name="actions"
                                        // value={requestedPage}
                                        // onChange={(e) => setRequestedPage(e.target.value)}
                                        onClick={handelClick}
                                        className={`${styles.actionDropdown} rounded-lg text-sm flex justify-center align-middle `}
                                    >
                                        <option hidden value="">Actions</option>
                                        <option  value="reject">Rejected</option>
                                        <option value="pending">Pending</option>
                                        <option value="approve">Approved</option>
                                        {/* <option value="customerPhotograph">Customer Photograph (269)</option> */}
                                    </select>
                                </div>
                                <div className="flex relative rounded-lg overflow-hidden bg-white">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Search Account No."
                                            className={`px-4 h-10 border-e-2 outline-none ${styles.searchFilter}`}
                                            onChange={handleSearchFilterChange}
                                            value={searchQuery}
                                            onKeyPress={(e) => handleEnterSearch(e)}
                                        ></input>
                                        {searchQuery && <button className="absolute right-0 top-0 h-full pe-2 flex items-center justify-center opacity-50 hover:opacity-100" onClick={handleClear}><img src="assets/images/cross.png" width={15} /></button>}
                                    </div>
                                    <button className="p-2 searchBtn" onClick={handleSearch}>
                                        <img
                                            src="assets/images/Vector.svg"
                                            className="searchLogo"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.raiseRequestTable} ${isMobileDevice && 'raiseRequestResponsiveTable'}`}>
                            <div className={`${styles.raiseRequestInner}`}>
                            {!isMobileDevice ? (
                                <table
                                    className={`min-w-full rounded-lg border-solid border-gray-border text-left ${styles.headTable}`}
                                >
                                    <thead>
                                        <tr className="ps-2 pe-2">
                                            {/* <th
                                                className={`py-3 px-4 capitalize ${styles.fixColumn}`}
                                                style={{ fontSize: "16px" }}
                                            >
                                                SL
                                            </th> */}
                                            <th
                                               className={`py-3 px-4 capitalize ${styles.fixColumn}`}
                                                style={{ fontSize: "16px" }}
                                            >
                                                Account Number
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px",display:"none" }}
                                            >
                                                Primary Key
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Request Page
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Request Purpose
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Request By
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Change Value
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Request Date
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Previous Value
                                            </th>
                                            {/* <th
                                            className="py-3 px-4 capitalize "
                                            style={{ fontSize: "16px" }}
                                        >
                                            Remarks
                                        </th> */}
                                            <th
                                               className={`py-3 px-4 capitalize ${ styles.workflowTextWidth}`}
                                                style={{ fontSize: "16px" }}
                                            >
                                                Approve Status
                                            </th>
                                            <th
                                                className={`py-3 px-4 capitalize text-center`}
                                                style={{ fontSize: "16px" }}
                                            >
                                                Details
                                            </th>
                                        </tr>
                                    </thead>
                                    {raiseRequestList && <tbody>
                                        {currentRecords?.map((item, index) => (
                                            <tr
                                                style={{ backgroundColor: "white" }}
                                                key={item?.id}
                                                className="border-b"
                                            >
                                                {/* <td className={`p-4 ${styles.listItem} ${styles.fixColumn}`}>{index + 1}</td> */}
                                                <td className={`p-4 border-b capitalize ${styles.fixColumn}`}>{item?.account_no}</td>
                                                <td className={`p-4 ${styles.listItem} hidden`}>{item?.id}</td>
                                                <td title={item?.request_title} className={`p-4 capitalize ${styles.workflowText}`}>
                                                    {item?.request_title?.length > 11 ? item?.request_title.slice(0, 11) + "..." : item?.request_title}
                                                </td>
                                                <td title={item?.request_purpose} className={`p-4 capitalize ${styles.workflowText}`}>{item?.request_purpose?.length > 11 ? item?.request_purpose?.slice(0, 11) + "..." : item?.request_purpose}</td>
                                                <td title={item?.requested_by} className={`p-4 capitalize ${styles.workflowText}`}>{item?.requested_by?.length > 11 ? item?.requested_by?.slice(0, 11) + "..." : item?.requested_by}</td>
                                                <td title={item?.request_update_value} className={`p-4 capitalize ${styles.workflowText}`}>
                                                    {item?.request_update_value?.length > 11 ? item?.request_update_value?.slice(0, 11) + "..." : item?.request_update_value}
                                                </td>
                                                <td title={item?.created_at} className={`p-4 capitalize ${styles.workflowText}`}>{moment(item?.created_at).format("YYYY-MM-DD")}</td>
                                                <td title={item?.previous_value} className={`p-4 capitalize ${styles.workflowText}`}>
                                                    {item?.previous_value?.length > 11 ? item?.previous_value?.slice(0, 11) + "..." : item?.previous_value}
                                                </td>
                                                {/* <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.account_no}</td> */}
                                                {/* <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.remarks}</td> */}
                                                <td className={`capitalize ${item?.status ==="pending"? styles.workflowTextWidth :"p-4"}`}>
                                                    {item?.status ==="pending" ? <div className="flex pt-2">
                                                        <span title="approve" onClick={() => updateStatus(item, 'approve')} className={`p-2 border ${styles.statusApproved}`}>
                                                            Approve
                                                        </span>
                                                        <span title="reject" onClick={() => updateStatus(item, 'reject')} className={`p-2 border ${styles.statusReject}`}>
                                                            Reject
                                                        </span>
                                                        <span title="pending" onClick={() => updateStatus(item, 'pending')} className={`p-2 border flex justify-center ${styles.statusNoChange}`}>
                                                            No Change
                                                        </span>
                                                    </div>:""}
                                                    {item?.status === "approve" ? <span className={`rounded-full p-2 ${styles.approveStatus}`}>Approved</span>:""}
                                                    {item?.status === "reject" ? <span className={`rounded-full p-2 ${styles.approveReject}`}>Rejected</span>:""}
                                                </td>
                                                <td className={`p-4 capitalize text-center ${styles.workflowDetails}`} onClick={() => getRaiseRequestPurpose(item)}>Details</td>
                                                {/* <td className={`p-4 capitalize ${styles.workflowText}`}>{moment(item?.created_at).format("YYYY-MM-DD | HH:mm:ss")}</td> */}
                                                {/* <td className={`p-4 capitalize ${styles.workflowText}`}>
                                            <span className={`p-2 ${styles.status} ${item?.status === "Completed" ? styles.completedStatus : ""} ${item?.status === "In Progress" ? styles.progressStatus : ""} ${item?.status === "Queued" ? styles.queuedStatus : ""}`}>
                                                {item?.status}
                                            </span>
                                        </td> */}
                                            </tr>
                                        ))}
                                    </tbody>}
                                </table>):(
                                    <div>
                                        {currentRecords?.map((item, index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Account No</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.account_no} className="mr-5">{item?.account_no}</span>
                                                    </div>
                                                </div>
                                                <div className="flex hidden justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Primary Key</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className="mr-5">{item?.id}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Request Page</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.request_title} className={`mr-5 ${styles.wrapWords}`}>{item?.request_title}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Request Purpose</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.request_purpose} className={`mr-5 ${styles.wrapWords}`}>{item?.request_purpose}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Request By</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.requested_by} className={`mr-5 ${styles.wrapWords}`}>{item?.requested_by}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Change Value</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.request_update_value} className={`mr-5 ${styles.wrapWords}`}>{item?.request_update_value}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Request Date</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.created_at} className={`mr-5 ${styles.wrapWords}`}>{moment(item?.created_at).format("YYYY-MM-DD")}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Previous Value</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.previous_value} className={`mr-5 ${styles.wrapWords}`}>{item?.previous_value}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Request Type</span>
                                                    <div className="flex items-center justify-between">
                                                        <span title={item?.requested_by} className={`mr-5 ${styles.wrapWords}`}>{item?.requested_by}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Approve Status</span>
                                                    <div className="flex items-center justify-between">
                                                        {item?.status ==="pending" ? <span className="mr-5 flex flex-col">
                                                            <button onClick={() => updateStatus(item, 'approve')}  className={`p-2 border ${styles.statusApproved}`}>Approve</button>
                                                            <button onClick={() => updateStatus(item, 'reject')}  className={`p-2 border ${styles.statusReject}`}>Reject</button>
                                                            <button onClick={() => updateStatus(item, 'pending')} className={`p-2 border ${styles.statusNoChange}`}>No Change</button>
                                                        </span>:""}
                                                        {item?.status === "approve" ? <span className={`rounded-full p-2 ${styles.approveStatus}`}>Approved</span>:""}
                                                        {item?.status === "reject" ? <span className={`rounded-full p-2 ${styles.approveReject}`}>Rejected</span>:""}
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Details</span>
                                                    <div className="flex items-center justify-between">
                                                        <span className={`mr-5 capitalize ${styles.workflowDetails}`} onClick={() => getRaiseRequestPurpose(item)}>Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {raiseRequestList?.length === 0 && <div className="p-6 font-semibold flex justify-center">No data found</div>}
                            </div>
                            {raiseRequestList?.length != 0 &&<Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                            ></Pagination>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer></ToastContainer>
        </>
    )
}