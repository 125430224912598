import React from 'react'
import styles from '../styles/crmVerifyDocument.module.scss'
import SideMenu from './SideMenu'
import Header from './Header'
import Breadcrumb from './Breadcrumb'


const CRMVerifyDocument = () => {
  const steps = ["CRM", "Listing Board" , " Verify Document"];

  const documents = [
    {
      name: 'Tech requirements.pdf',
      type: 'pdf',
      docName: 'NID',
      status: 'Verified',
      verification: 'Manual',
      action: true,
      size: '200 KB'
    },
    {
      name: 'Dashboard screenshot.jpg',
      type: 'jpg',
      docName: 'DOB',
      status: 'Verification Pending',
      verification: 'Manual',
      action: false,
      size: '720 KB'
    },
    {
      name: 'Tech requirements.pdf',
      type: 'pdf',
      docName: 'NID',
      status: 'Verified',
      verification: 'Manual',
      action: true,
      size: '200 KB'
    },
    {
      name: 'Dashboard screenshot.jpg',
      type: 'jpg',
      docName: 'DOB',
      status: 'Verification Pending',
      verification: 'Manual',
      action: false,
      size: '720 KB'
    },
    {
      name: 'Tech requirements.pdf',
      type: 'pdf',
      docName: 'NID',
      status: 'Verified',
      verification: 'Manual',
      action: true,
      size: '200 KB'
    },
    {
      name: 'Dashboard screenshot.jpg',
      type: 'jpg',
      docName: 'DOB',
      status: 'Verification Pending',
      verification: 'Manual',
      action: false,
      size: '720 KB'
    },
    {
      name: 'Tech requirements.pdf',
      type: 'pdf',
      docName: 'NID',
      status: 'Verified',
      verification: 'Manual',
      action: true,
      size: '200 KB'
    }
  ];

  return (
    <>

    <div className={`flex dark:bg-dark-background mainBodyContainer`}>
      <SideMenu />
      <div className="flex-1 ">
        <Header />
        <div className={`z-0 px-10 pt-10 pb-16 mainContainer fixedHeightContainer`}>

          <div className="flex  gap-2">
          <span className={` flex`}> <img className="cursor-pointer"  src="/assets/images/arrow-sm-left.svg"/></span>
          <Breadcrumb steps={steps} activeStep={2}  redirectUrl={'/'} /> 
              
          </div>

          <div className={`${styles.fieldMainContent} mt-6 `}>
                  <div className={`${styles.documentTable}  w-full`}>
                      <div className={`${styles.tableHeader} py-4  flex gap-3`}>
                          <div className={`${styles.headerCell} `}>Document Name</div>
                          <div className={`${styles.headerCell}`}>Document Type</div>
                          <div className={`${styles.headerCell}`}>Document Name</div>
                          <div className={`${styles.headerCell}`}>Status</div>
                          <div className={`${styles.headerCell}`}>Verification</div>
                          <div className={`${styles.headerCell} text-center`}>Action</div>
                      </div>
                      {documents.map((doc, index) => (
                          <div className={`${styles.tableRow} flex justify-center items-center gap-3`} key={index}>
                          <div className={`${styles.cell} `}>
                              <div className={`${styles.documentRow } flex gap-3 `}>
                              <div className={styles.documentIcon}>
                              <div className={`${styles.fileLogoContainer} flex justify-center items-center `}>
                              <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.5121 1.12402H3.89898C3.32209 1.12402 2.76883 1.35319 2.36091 1.76111C1.95298 2.16904 1.72382 2.7223 1.72382 3.29919V20.7005C1.72382 21.2774 1.95298 21.8307 2.36091 22.2386C2.76883 22.6465 3.32209 22.8757 3.89898 22.8757H16.95C17.5269 22.8757 18.0801 22.6465 18.488 22.2386C18.896 21.8307 19.1251 21.2774 19.1251 20.7005V8.7371M11.5121 1.12402L19.1251 8.7371M11.5121 1.12402V8.7371H19.1251" stroke="#2160AF" stroke-width="2.17516" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              </div>
                              </div>
                              <div>
                                  <div className={`font-medium ${styles.documentName}`}>{doc.name}</div>
                                  <div className={styles.documentSize}>{doc.size}</div>
                              </div>
                              </div>    
                          </div>
                          <div className={`${styles.cell}`}>{doc.type}</div>
                          <div className={`${styles.cell}`}>{doc.docName}</div>
                          <div className={`${styles.cell} ${doc.status === 'Verified' ? styles.verified : styles.pending}`}>
                              {doc.status}
                          </div>
                          <div className={`${styles.cell}`}>{doc.verification}</div>
                          <div className={`${styles.cell} text-center ps-0`}>
                              <input type="checkbox" checked={doc.action}  />
                          </div>
                          </div>

                          ))}

                      </div>
                    <div className={`flex justify-end mt-9 `}>
                    <button className={`${styles.creditScoreButton} font-bold `}>
                    Continue For Credit Scoring
                    </button>
                </div>
              </div>         
            </div>
          </div>
        </div>
    </>
  )
}

export default CRMVerifyDocument
