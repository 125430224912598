import { SET_USER_LIST } from '../actions';

const initialState = {
  userList: [],
};

const UserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    default:
      return state;
  }
};

export default UserListReducer;