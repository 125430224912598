import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ColorRing } from "react-loader-spinner";
import SideMenu from '../../../component/SideMenu';
import Header from '../../../component/Header';
import Icon from '../../../component/core-component/Icon';
import "../../../styles/writtenOfPortfolio.scss"
import styles from "../../../styles/userEdit.module.scss"
import API from '../../../helpers/api';
import AddNewRowModal from './portfolioAddNewRowModal';
import Pagination from "../../../component/Pagination";
import { encodeData, handleErrorMessage } from '../../../common/commonFunction';
import { WithAuth } from '../../../common/WithAuth';
import { useDispatch } from 'react-redux';
import { openingModal } from '../../../redux/actions';

function ViewPortfolio() {
    const dispatch= useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const portfolio_id = params.get('id');
    const [borrowersData, setBorrowersData] = useState({})
    const [portfolioData, setPortfolioData] = useState([])
    const portfolioHeaders = ['Date', 'Particulars', 'Debit', 'Credit', 'Balance', 'Initial', 'Remarks']
    const [showModal, setShowModal] = useState(false);
    const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nPages, setNpages] = useState(1);
    const [recordsPerPage] = useState(6);
    const [closeAccountLoader, setCloseAccountLoader] = useState(false);
    const isMobileDevice = window.screen.width <= 1200;
    const width = window.screen.width;

    const handleBack = () => {
        navigate('/writtenOfPortfolio');
    };

    const getBorrowersData = async () => {
        try {
            setDataLoading(true);
            const data = await API.apiGet(`/loans/write/off/portfolio/${portfolio_id}`)
            if(data?.data?.data) {
                setBorrowersData(data?.data?.data)
                setDataLoading(false);
            }
        } catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
            setDataLoading(false);
        }
    };

    const getPortfolioDetails = async (currentPage) => {
        let id = parseInt(portfolio_id)
        setListLoading(true);
        try {
            const data = await API.apiGet(`loans/write/off/portfolio/ledger/${id}?limit=${recordsPerPage}&skip=${currentPage}`)
            if(data?.data?.success) {
                setPortfolioData(data?.data?.data);
                setNpages(data?.data?.totalPageCount);
                setListLoading(false);
            } else {
                setListLoading(false);
            }
        } catch (error) {
            handleErrorMessage(error);
            setListLoading(false);
            // toast.error(error?.response?.data?.message)
        }
    };
    
    useEffect( () => {
        getBorrowersData();
        getPortfolioDetails(1);
        setCurrentPage(1);
    },[]);

    const handleDataChange = (newData) => {
        setPortfolioData(newData)
    };

    const updateStatus = async () => {
        if (reason?.trim() === '') {
            setError(true);
            return ;
        } else {
            setError(false);
            let payload = {
                portfolio_id: portfolio_id,
                account_close_remark: reason,
            }

            try {
                setCloseAccountLoader(true);
                const data = await API.apiPut('/loans/write/off/portfolio/', {payload: encodeData(payload)})
                if(data?.data?.success) {
                    toast.success(data?.data?.message)
                    getBorrowersData();
                    getPortfolioDetails(1);
                    setShowCloseAccountModal(false)
                    setCloseAccountLoader(false);
                }
            } catch (err) {
                handleErrorMessage(err);
                // toast.error(err?.response?.data?.message)
                setCloseAccountLoader(false);
            }
        }
    };

    const convertDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };

    const closeAccountModal = () => {
        setShowCloseAccountModal(true);
    };

    const closeAccountConfirmationModal = () => {   
        setShowCloseAccountModal(false);
        setError(false);
        setReason('')
    };

    const handleCloseAccountReason = (event) => {
        setReason(event?.target?.value)
        setError('')
    };

    const currentRecords = portfolioData?.WriteOffPortfolioDetails
    
    function handlePageChange(currentPage){
        setPortfolioData([]);
        setCurrentPage(currentPage);
        getPortfolioDetails(currentPage);
    }

    const setData = (item, key) => {
        let data = item?.[key];
        if (data) {
            return data?.length > 20 ? data?.substring(0, 20) + '...' : data;
        } else {
            return '--';
        }
    }

    const setLedgerData = (item, key) => {
        let data = item?.[key];
        if (data) {
            return data?.length > 20 ? data?.substring(0, 15) + '...' : data;
        } else {
            return '--';
        }
    }

    const handleTableData = (item) => {
        return item;
    }

  return (
    
    <div className="flex dark:bg-dark-background writtenOfPortfolio">
        <SideMenu headerName="Borrower's Details"/>
        <div className={`h-screen flex-1 overflow-y-auto ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
            <Header/>
            <div className={`z-0 p-10 ${dataLoading ? '' : ''} ${listLoading ? 'blurContent' : ''} mainContainer ${isMobileDevice && 'p-6 responsiveMainContainer'} ${width > 900 && width <= 1030 && 'viewPortfolio_responsiveContainer'}`}>
                <div className="viewBorrowEdit">
                    <div className="p-4 flex justify-between items-center">
                        <div className='flex'>
                            <div onClick={() => handleBack()} className='cursor-pointer'>
                                <Icon iconName="IconBackBtn" width={16} height={16} />
                            </div>
                            <div className="ps-2">
                                <b> Borrower's Details</b>
                            </div>
                        </div>
                        { borrowersData?.status == 'Active' && 
                            <div className="ps-2">
                                <button className={`py-2 px-4 ${styles.addNewPortfolioRow} custom_btn_primary`} onClick={closeAccountModal }> Close Account </button>
                            </div>
                        }
                    </div>
                    <div className={`flex ps-4 userBorrowDetails borrowerDetailsDiv ${isMobileDevice && 'flex flex-col'}`}>
                        <div className={`w-full m-2 pe-3 viewBorrow ${isMobileDevice && 'flex justify-between px-2 pe-4'}`}>    
                            <div className="text-black-50 labelColor">Borrower's Name:</div>
                            <div className={`mt-1 me-3 userName ${isMobileDevice && 'me-0 viewPortfolioTableData'}`} title={borrowersData?.borrower_name}>
                                {/* {userData?.first_name && userData?.first_name?.charAt(0).toUpperCase() + userData?.first_name?.slice(1)} */}
                                {setData(borrowersData, 'borrower_name')}
                            </div>        
                        </div>
                        <div className={`w-full m-2 px-2 viewBorrow ${isMobileDevice && 'flex justify-between pe-4'}`}>
                            <div className="text-black-50 text-nowrap mr-2 labelColor">
                                Borrower's Account Number:
                            </div>
                            <div className={`mt-1 userName ${isMobileDevice && 'viewPortfolioTableData'}`} title={borrowersData?.account_number}>
                                {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                {setData(borrowersData, 'account_number')}
                            </div>
                        </div>
                        <div className={`w-full m-2 px-2 viewBorrow ${isMobileDevice && 'flex justify-between pe-4'}`}>
                            <div className="text-black-50 text-nowrap mr-2 labelColor">
                                Loan Amount:
                            </div>
                            <div className={`mt-1 userName ${isMobileDevice && 'viewPortfolioTableData'}`}>
                                {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                {borrowersData?.loan_amount ? parseInt(borrowersData?.loan_amount)?.toFixed(2) : '--'}
                            </div>
                        </div>
                        <div className={`w-full m-2 px-2 viewBorrow ${isMobileDevice && 'flex justify-between pe-4'}`}>
                            <div className="text-black-50 text-nowrap mr-2 labelColor">
                                Pending Amount:
                            </div>
                            <div className={`mt-1 userName ${isMobileDevice && 'viewPortfolioTableData'}`}>
                                {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                {portfolioData?.WriteOffPortfolioDetails?.length ? parseInt(portfolioData?.WriteOffPortfolioDetails?.[0]?.balance)?.toFixed(2) : '--'}
                            </div>
                        </div>
                        <div className={`w-full m-2 px-2 viewBorrow ${isMobileDevice && 'flex justify-between pe-4'}`}>
                            <div className="text-black-50 text-nowrap mr-2 labelColor">
                                Contact Number:
                            </div>
                            <div className={`mt-1 userName ${isMobileDevice && 'viewPortfolioTableData'}`}>
                                {/* { userData?.last_name && userData?.last_name?.charAt(0).toUpperCase() + userData?.last_name?.slice(1)} */}
                                {(borrowersData?.contact_number == 'UNKNOWN' || borrowersData?.contact_number == '') ? '--' : borrowersData?.contact_number}
                            </div>
                        </div>
                        <div className={`w-full m-2 px-3 ${isMobileDevice && 'flex justify-between borrowStatus'}`}>
                            <div className="text-black-50 mr-2 labelColor">Status:</div>
                            <div className={`mt-1 ${isMobileDevice && 'viewPortfolioTableData'}`}>
                            {borrowersData?.status ? borrowersData?.status == 'Inactive' ? <span className={`text-red text-center`}><span className="red-dot"/>Inactive</span> : <span className={`text-green-500`}><span className="text-green-500 green-dot"/>Active</span> : <span className="text-red-500"> --</span> }
                                {/* {borrowersData?.status ? borrowersData?.status : '--'} */}
                            </div>
                        </div>
                    </div>
                </div>
                { borrowersData?.status == 'Closed' && 
                    <div className='bg-white flex py-1 px-3 mt-2 mb-0 font-bold rounded-lg'>
                        <span className='text-red-500 italic'>**Closing Remark:</span>
                        <span className='ps-2 italic'>{borrowersData?.account_close_remark}</span>
                    </div>
                }

                { borrowersData?.status == 'Active' && 
                    <div className='flex flex-row-reverse mt-4'>
                        <button className={`py-2 px-4 ${styles.addNewPortfolioRow} custom_btn_primary`} onClick={()=> {
                            setShowModal(true);
                            dispatch(openingModal())
                            }}>
                            Add New Entry + 
                        </button>
                    </div>
                }
                {!isMobileDevice ? (
                    <div className='mt-3 viewBorrowEdit'>
                        <div className={`mt-4 ${styles.porfolioTable}`}>
                        {currentRecords?.length > 0 ? (
                            <table className={`min-w-full border border-solid border-gray-border text-left ${styles.portfolioTable}`}>
                                <thead>
                                    <tr className="ps-2 pe-2">
                                        {portfolioHeaders?.map((item, index) => 
                                            <th key={item+index} className={`py-2 px-4 ${item == 'Date' && styles.dateHeader} ${item == 'Remarks' && styles.remarksHeader}`}>{item}</th>
                                        )}
                                    </tr>
                                </thead>
                                    <tbody className={`bg-white ${styles.portfolioTableContent}`}>
                                        {currentRecords?.map((item,index) => 
                                            <tr key={`${item}_${index}`}>
                                                <td className="py-2 ps-4">{convertDate(item?.created_at) ? convertDate(item?.created_at) : '--'}</td>
                                                <td className="py-2 ps-4" title={item?.particulars}>{setLedgerData(item, 'particulars')}</td>
                                                <td className="py-2 ps-4">{item?.transaction_type == 'debit' ? parseInt(item?.transaction_amount)?.toFixed(2) : '--' }</td>
                                                <td className="py-2 ps-4">{item?.transaction_type == 'credit' ? parseInt(item?.transaction_amount)?.toFixed(2) : '--'}</td>
                                                <td className="py-2 ps-4">{item?.balance ? item?.balance : '--'}</td>
                                                <td className="py-2 ps-4" title={item?.initiated_by}>{setLedgerData(item, 'initiated_by')}</td>
                                                <td className="py-2 ps-4" title={item?.remark}>{setLedgerData(item, 'remark')}</td>
                                            </tr>                                
                                        )}
                                    </tbody>
                            </table>
                            ) : (
                                <>
                                <table className={`min-w-full border border-solid border-gray-border text-left ${styles.portfolioTable}`}>
                                    <thead className='rounded-none'>
                                        <tr className="ps-2 pe-2">
                                            {portfolioHeaders?.map((item, index) => 
                                                <th key={item+index} className={`py-2 px-4 ${item == 'Date' && styles.dateHeader} ${item == 'Remarks' && styles.remarksHeader}`}>{item}</th>
                                            )}
                                        </tr>
                                    </thead>
                                </table>
                                {listLoading 
                                ?
                                    <div className="flex justify-center" >
                                        <ColorRing
                                        visible={true}
                                        height="80"
                                        width="60"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={[]}
                                        />
                                    </div>
                                :
                                    <div className='flex justify-center py-5 font-semibold rounded-none'>No Data Found</div>
                                }
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        {currentRecords?.length > 0 ? (
                            currentRecords?.map((item, index) => (
                                <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg mt-2">
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Date</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{convertDate(item?.created_at) ? convertDate(item?.created_at) : '-'}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Particulars</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{handleTableData(item?.particulars)}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Debit</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{item?.transaction_type == 'debit' ? item?.transaction_amount : '-'}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Credit</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{item?.transaction_type == 'credit' ? item?.transaction_amount : '-'}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Balance</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{item?.balance}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Initial</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{item?.initiated_by}</span> 
                                        </div>
                                    </div>
                                    <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                        <span className="font-bold ms-2">Remarks</span>
                                        <div className="flex items-center justify-between">
                                            <span className={`mr-5 ${isMobileDevice && 'viewPortfolioTableData'}`}>{item?.remarks ? item?.remarks : '-'}</span> 
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="noDataFound">
                                <img
                                    src='/assets/noData.svg'
                                    alt="no-data"
                                    className={`noDataImage ${isMobileDevice && 'noDataIcon'}`}
                                />
                                <div className="text-xl font-semibold">
                                    No data found
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {currentRecords?.length > 0 ? (
                    <div className={`flex flex-row-reverse ${styles.portfolioDetailTablePagination}`}>
                        <Pagination nPages={nPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        ></Pagination>  
                    </div>
                ) : (
                    ''
                )}
            </div>
            { showModal &&  
                <AddNewRowModal 
                    setShowModal={setShowModal} 
                    onDataChange={handleDataChange} 
                    setListLoading={setListLoading} 
                    setPortfolioData={setPortfolioData}
                    recordsPerPage={recordsPerPage}
                    currentPage={1}
                    setNpages={setNpages}
                /> 
            }
        </div>
        <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden={!showCloseAccountModal} className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full ${showCloseAccountModal ? '' : 'hidden'}`}>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Are you sure you want to close this account ? 
                        </h3>  
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeAccountConfirmationModal}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5 space-y-4">
                        <div className="flex flex-col justify-between w-100"> 
                            <span>Please provide a reason for closing the account</span>
                            <textarea 
                                className="border mt-2 rounded-lg outline-none px-2 py-1" 
                                rows={2}
                                value={reason} 
                                onChange={handleCloseAccountReason} 
                            /> 
                            {error && <p className="text-red-500 text-sm">Please provide a reason for closing the account</p>}
                        </div>
                    </div>

                    <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button data-modal-hide="static-modal" type="button" className={`custom_btn_secondary py-2.5 h-10 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ${closeAccountLoader && styles.newPortfolioCreateButton}`} onClick={closeAccountConfirmationModal}>Cancel</button>
                        <button data-modal-hide="static-modal" type="button" className={`ms-3 text-white h-10 flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-6 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn ${closeAccountLoader && styles.newPortfolioCreateButton}`} onClick={updateStatus} disabled={closeAccountLoader}>
                            <div className="flex items-center">
                                Close Account
                                { closeAccountLoader &&
                                    <ColorRing
                                    visible={true}
                                    height="30"
                                    width="30"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#ffffff','#ffffff','#ffffff','#ffffff','#ffffff']}
                                    />
                                }
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer></ToastContainer>
  </div>
  )
}

export default WithAuth(ViewPortfolio)
