import { DatePicker } from 'antd'
import moment from 'moment';
import React from 'react'

export default function AuditTrailDownloadModal({showLoading, handleFilter, filterData, setFilterData, closeModal}) {

    function handleDateChange(value,name) {
        setFilterData({ ...filterData, [name]:value });
    }

    const getCurrentDate = (current ) => {
        return current && current > moment().endOf('day')
      };  
      
    return (
        <>
            <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Download Report
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 flex gap-2 justify-center">
                            <DatePicker
                                name="startDate"
                                value={filterData?.startDate}
                                onChange={(val)=>handleDateChange(val,'startDate')}
                                placeholder="From" // Placeholder for start date
                                className="border w-full px-4 py-2 focus:outline-none rounded-md"
                                disabledDate={getCurrentDate}
                            />
                            <DatePicker
                                name="endDate"
                                value={filterData?.endDate}
                                onChange={(val)=>handleDateChange(val,'endDate')}
                                placeholder="To" // Placeholder for end date
                                className="border w-full px-4 py-2 focus:outline-none rounded-md"
                                disabledDate={getCurrentDate}
                            />
                        </div>

                        <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 commonBtn custom_btn_primary" onClick={handleFilter}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
