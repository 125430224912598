import {useEffect, useState} from 'react';
import Chart  from 'react-apexcharts';
import API from '../helpers/api';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';

export const CRMBarGraph = () => {
const [dataLoading,setDataLoading] = useState(false);
const [yearlyStatusCount,setYearlyStatusCount] = useState();
const [rejectedStatusCount,setRejectedStatusCount] = useState();
const [abandonedStatusCount,setAbandonedStatusCount] = useState();
const [progressStatusCount,setProgressStatusCount] = useState();
const [series, setSeries] = useState([]);

  const getLoanAccountList = async () => {
    try {
      const { data } = await API.apiGet(`/loan/account/status/yearly/count`);
      if (data?.success) {
        toast.success(data?.message);
        const approvedCounts = data?.data?.map(item => {
          const approvedStatus = item?.statusCounts?.find(status => status.status === "Approved");
          return approvedStatus ? approvedStatus.count : 0;
        });

        const rejectedCounts = data?.data?.map(item => {
          const rejectedStatus = item?.statusCounts?.find(status => status.status === "Rejected");
          return rejectedStatus ? rejectedStatus?.count : 0;
        });

        const abandonedCounts = data?.data?.map(item => {
          const abandonedStatus = item?.statusCounts?.find(status => status.status === "Abondoned");
          return abandonedStatus ? abandonedStatus?.count : 0;
        });

        const progressCounts = data?.data?.map(item => {
          const progressStatus = item?.statusCounts?.find(status => status.status === "In Progress");
          return progressStatus ? progressStatus?.count : 0;
        });
        setYearlyStatusCount(approvedCounts);
        setRejectedStatusCount(rejectedCounts);
        setAbandonedStatusCount(abandonedCounts);
        setProgressStatusCount(progressCounts);
        setSeries([
          {
            name: "In Progress",
            data: progressStatusCount,
            color: "#02839F",
          },
          {
            name: "Approved",
            data: yearlyStatusCount,
            color: "#FFA600CC",
          },
          {
            name: "Rejected",
            data: rejectedStatusCount,
            color: "#FF5630CC"
          },
          {
            name: "Abondoned",
            data: abandonedStatusCount,
            color: "#44C751",
          }
        ])
        setDataLoading(false)
      } else {
        toast.error(data?.message);
        setDataLoading(false)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDataLoading(false)
    }
  }

useEffect(()=>{
  getLoanAccountList();
  setDataLoading(true);
},[yearlyStatusCount?.length])

    const CRMData = [
        {
          date: "Jan",
        },
        {
          date: "Feb",
        },
        {
          date: "Mar",
        },
        {
          date: "Apr",
        },
        {
          date: "May",
        },
        {
          date: "Jun",
        },
        {
          date: "July",
        },
        {
          date: "Aug",
        },
        {
          date: "Sep",
        },
        {
          date: "Oct",
        },
        {
          date: "Nov",
        },
        {
          date: "Dec",
        },
      ];
    const [options, setOptions] = useState({
        chart: {
          type: 'bar',
          stacked:true,
          animations: {
            enabled: false 
        },
          toolbar: {
            show:false,
          }
        },
        xaxis: {
          categories: CRMData?.map((data=> data?.date)),
          labels: {
            style : {
              fontFamily: "'Kanit', sans-serif",
              cssClass: "xaxis-label",
            }
          }
        },
        yaxis: {
          labels: {
            style : {
              fontFamily: "'Kanit', sans-serif",
              cssClass: "yaxis-label",
            }
          }
        },
        legend: {
          position: "top",
          horizontalAlign: 'right',
          fontSize: "11px",
          fontWeight: "400",
          fontFamily: "'Kanit', sans-serif",
        },
        grid: {
          borderColor:"#CCCCCC52",
        },
       dataLabels : {
          enabled:false,
       },
       plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "7px",
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
        }
       },
       
      });
    
  return (
    <>
    {dataLoading && (
      <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
        <ColorRing
          visible={true}
          height="80"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={[]}
        />
      </div>
    )}
    <Chart options={options} series={series} type="bar" height="380" width="630" />
    </>
  )
}
