import React, { useEffect, useState } from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import LoanConfigTable from "./LoanConfigTable";
import CreateLoanModal from "./CreateLoanModal";
import API from "../../../helpers/api";
import { handleErrorMessage } from "../../../common/commonFunction";

const LoanConfigurationComponent = () => {
  const isMobileDevice = window.screen.width <= 900;
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNpages] = useState(1);
  const [loanTypeList, setLoanTypeList] = useState([]);
  const [planListData, setPlanListData] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [activeTab, setActiveTab] = useState('published');
  const [loanDataLoading, setLoanDataLoading] = useState(false);

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }

  const getLoanStatus = (status) => {
    let actualStatus = '';
    if (status == 'published') {
      actualStatus = 'ACTIVE'
    } else if (status == 'draft') {
      actualStatus = 'INACTIVE'
    } else if (status == 'archive') {
      actualStatus = 'ARCHIVE'
    } 
    return actualStatus;
  }

  const getConfiguredLoanList = async(currentStatus) => {
    try {
      setLoanDataLoading(true);
      let status = getLoanStatus(currentStatus);
      const { data } = await API.apiGet(`plan/list?status=${status}`)
      if (data.success) {
        setPlanListData(data.data);
        setLoanDataLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setPlanListData([]);
      setLoanDataLoading(false);
    }
  }

  const getLoanTypeList = async() => {
    try {
      const { data } = await API.apiGet('loan/type/list');
    if (data.success) {
      setLoanTypeList(data?.data)
    }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setPlanListData([]);
    getConfiguredLoanList('published');
    getLoanTypeList();
  }, []);

  const handleAciteTab = (currentTab) => {
    setPlanListData([]);
    setActiveTab(currentTab);
    getConfiguredLoanList(currentTab);
  }

    return (
        <>
            <div className={`flex dark:bg-dark-background reconcilitaion-main-container reconcilation-part mainBodyContainer`}>
                <SideMenu headerName="Loan Configuration"/>
                <div className={`flex-1 ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    <div className={`z-0 p-10 relative mainContainer ${!isMobileDevice && 'fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
                    <div className="flex justify-between">
                      {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
                          Loan Configuration
                      </h1>}  
                      <button
                          onClick={() => setShowDownloadModal(true)}
                          className="bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 commonBtn custom_btn_primary h-10 rounded-md flex gap-2 items-center downloadReportResp"
                          >
                          Create Loan
                      </button>
                    </div>
                        {!isMobileDevice ? (
                            <>
                              <div className=" flex mt-2 tabContainer">
                                <div className="differentTabs flex">
                                  <div
                                    className={`w-24 p-2 text-center tabsName ${activeTab == 'draft' ? "commmonActiveTag border-b-2" : ""}`}
                                    onClick={() => handleAciteTab('draft')}
                                  >
                                    <p className="mt-2 cursor-pointer">
                                      Draft
                                    </p>
                                  </div>
                                  <div
                                    className={`ms-10 w-32 p-2 text-center tabsName ${activeTab == 'published' ? "commmonActiveTag border-b-2" : ""}`}
                                    onClick={() => handleAciteTab('published')}
                                  >
                                    <p className="mt-2 cursor-pointer">
                                      Published
                                    </p>
                                  </div>
                                  <div
                                    className={`ms-10 w-24 p-2 text-center tabsName ${activeTab == 'archive' ? "commmonActiveTag border-b-2" : ""}`}
                                    onClick={() => handleAciteTab('archive')}
                                  >
                                    <p className="mt-2 cursor-pointer">
                                      Archive
                                    </p>
                                  </div>
                                </div>
                              </div>
                                <LoanConfigTable 
                                  rowData={planListData} 
                                  tableLoading={loanDataLoading} 
                                  nPages={nPages}
                                  handlePageChange={handlePageChange}
                                  currentPage={currentPage}
                                  recordsPerPage={recordsPerPage}
                                  isMobileDevice={isMobileDevice}
                                />
                                {showDownloadModal && <CreateLoanModal loanTypeList={loanTypeList} showModal={showDownloadModal} setShowModal={setShowDownloadModal} />}
                            </>
                            ) : (
                                ''
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoanConfigurationComponent;