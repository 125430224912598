import React from "react";
import SideMenu from "../../../component/SideMenu";
import Header from "../../../component/Header";
import Breadcrumb from "../../../component/Breadcrumb";
import { useState, useEffect } from "react";
import API from "../../../helpers/api/index";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../../../component/Pagination";
import styles from "../../../styles/ivrCallingDetails.module.scss";
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { handleErrorMessage } from "../../../common/commonFunction";
import { WithAuth } from "../../../common/WithAuth";

const IVRCallingDetails = () => {
    const [ivrConfigCalling, setIvrConfigCalling] = useState();
    const [showModal, setShowModal] = useState(false);
    const [initialDetails, setInitialDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    let currentRecords = ivrConfigCalling?.slice(indexOfFirstRecord, indexOfLastRecord);
    let nPages = ivrConfigCalling?.length ? Math.ceil(ivrConfigCalling?.length / recordsPerPage):0;
    const [dataLoading, setDataLoading] = useState(false)
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('');
    const isMobileDevice = window.screen.width <= 900;
    
    const handleSearch = (e) => {
        setCurrentPage(1);
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
    };

    const filteredData = ivrConfigCalling?.filter(item =>
        // (item?.id && item?.id?.toString()?.includes(searchQuery)) ||
        (item?.ivr_workflow_name && item?.ivr_workflow_name?.toLowerCase().toString()?.includes(searchQuery))
        // (item?.total_calls && item?.total_calls?.toString()?.includes(searchQuery)) ||
        // (item?.uploaded_by && item?.uploaded_by?.toLowerCase()?.includes(searchQuery)) ||
        // (item?.created_at && item?.created_at?.includes(searchQuery))
    );
    currentRecords = filteredData?.slice(indexOfFirstRecord, indexOfLastRecord);
    nPages = filteredData?.length ? Math.ceil(filteredData?.length / recordsPerPage):0;

    function phoneCallDetails(item){
        let list_id = item?.target?.innerHTML;
        // console.log("list_id",item?.target?.innerHTML);
        navigate(`/phoneCallDetails?list_id=${list_id}`);
        localStorage.setItem("list_id", list_id);
    }

    useEffect(() => {
        getIvrConfigCallList();
        setDataLoading(true);
    }, []);
    const getIvrConfigCallList = async () => {
        try {
            const { data } = await API.apiGet(`/v1/ivr/call/workflow/list`);
              setIvrConfigCalling(data?.data);
            if (data?.success) {
                toast.success(data?.message);
                setDataLoading(false)
            } else {
                toast.error(data?.message);
                setDataLoading(false)
            }
        } catch (error) {
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message);
            setDataLoading(false)
        }
    };
    function handlePageChange(currentPage) {
        setCurrentPage(currentPage)
    }

    const closeModal = () => {
        setShowModal(false);
    };
    const steps = ["services","IVR", "Calling List"];

    const setStatus = (status) => {
        const item = status?.split("-");
        const capitalizedParts = item?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1));
        return capitalizedParts?.join("-");
    };


    return (
        <>
            <div className={`flex dark:bg-dark-background updateIvrContainer mainBodyContainer`}>
                <SideMenu headerName="Calling List"/>
                <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                    <Header/>
                    <div className={`z-0 p-10 mainContainer ${!isMobileDevice && 'container fixedHeightContainer'} ${isMobileDevice && 'p-5 responsiveMainContainer'}`}>
                        <div className={`flex justify-between ${isMobileDevice && 'flex-col'}`}>
                            <Breadcrumb
                            steps={steps}
                            activeStep={2}
                            showBackButton={true}
                            redirectUrl={"/IVRWorkflow"}
                        />

                        <div className={`flex relative ${isMobileDevice && 'justify-end mt-2'}`}>
                            <img src='assets/images/Vector.svg' className={`searchLogo ${!isMobileDevice ? 'absolute' : 'relative searchLogo_responsive'}`} />
                            <input
                                type='text'
                                placeholder='Search'
                                onChange={(e) => handleSearch(e)} 
                                className={`${!isMobileDevice ? 'numberSearch' : 'callingListSearchInput'}`}
                            //   value={searchData}
                            />
                        </div>
                    </div>
                    <div className=" mt-4">
                        {currentRecords?.length > 0 ?
                            <>
                                {!isMobileDevice ? (
                                    <table
                                        className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}
                                    >
                                        <thead>
                                            <tr className="ps-2 pe-2">
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    List ID
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Workflow Name
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Total Calls
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Uploaded By
                                                </th>
                                                <th
                                                    className="py-3 px-4 capitalize "
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Uploaded At
                                                </th>
                                                {/* <th
                                            className="py-3 px-4 capitalize "
                                            style={{ fontSize: "16px" }}
                                        >
                                            Status
                                        </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRecords?.map((item, index) => (
                                                <tr
                                                    style={{ backgroundColor: "white" }}
                                                    key={index}
                                                    className="border-b"
                                                >
                                                    <td className={`p-4 ${styles.listItem}`} onClick={phoneCallDetails}>{item?.id}</td>
                                                    <td className={`p-4 capitalize ${styles.workflowText}`}>
                                                        {item?.ivr_workflow_name}
                                                    </td>
                                                    <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.total_calls}</td>
                                                    <td className={`p-4 capitalize ${styles.workflowText}`}>{item?.uploaded_by}</td>
                                                    <td className={`p-4 capitalize ${styles.workflowText}`}>{moment(item?.created_at).format("YYYY-MM-DD | HH:mm:ss")}</td>
                                                    {/* <td className={`p-4 capitalize ${styles.workflowText}`}>
                                                <span className={`p-2 ${styles.status} ${item?.status === "Completed" ? styles.completedStatus : ""} ${item?.status === "In Progress" ? styles.progressStatus : ""} ${item?.status === "Queued" ? styles.queuedStatus : ""}`}>
                                                    {item?.status}
                                                </span>
                                            </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div>
                                        {currentRecords?.map((item, index) => (
                                            <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">List ID</span>
                                                    <div className="flex items-center justify-between" onClick={phoneCallDetails}>
                                                        <span className="phoneCallDetails_responsive">{item?.id}</span> 
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Workflow Name</span>
                                                    <div className="flex items-center justify-between">
                                                        <span>{item?.ivr_workflow_name}</span> 
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Total Calls</span>
                                                    <div className="flex items-center justify-between">
                                                        <span>{item?.total_calls}</span> 
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Uploaded By</span>
                                                    <div className="flex items-center justify-between">
                                                        <span>{item?.uploaded_by}</span> 
                                                    </div>
                                                </div>
                                                <div className="flex justify-between border-b p-2.5 grid grid-cols-2 gap-2">
                                                    <span className="font-bold">Uploaded At</span>
                                                    <div className="flex items-center justify-between">
                                                        <span>{moment(item?.created_at).format("YYYY-MM-DD | HH:mm:ss")}</span> 
                                                    </div>
                                                </div>
                                            </div>
                                        ))}    
                                    </div>
                                )}
                            </>
                        :
                            <>
                                <table className={`min-w-full rounded-lg border-solid border-gray-border text-left ivrTable`}>
                                    <thead>
                                        <tr className="ps-2 pe-2">
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                List ID
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Workflow Name
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Total Calls
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Uploaded By
                                            </th>
                                            <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Uploaded At
                                            </th>
                                            {/* <th
                                                className="py-3 px-4 capitalize "
                                                style={{ fontSize: "16px" }}
                                            >
                                                Status
                                            </th> */}
                                        </tr>
                                    </thead>
                                </table>
                                {dataLoading ? 
                                    <div className="bg-white flex justify-center" >
                                        <ColorRing
                                            visible={true}
                                            height="80"
                                            width="60"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={[]}
                                        />
                                    </div>
                                :
                                    <div className="bg-white flex justify-center py-5 font-semibold">
                                        No data found
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {!dataLoading && currentRecords && currentRecords?.length > 0 &&
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        ></Pagination>
                    }
                </div>
            </div>
        </div>
        <ToastContainer/>
        </>
    );
};
export default WithAuth(IVRCallingDetails);
