import React, { useState } from "react";
import Chart from "react-apexcharts";

const DonutChart = ({ queues, totalCount }) => {
    let countColor = [
        {
            countsName: "active",
            color: '#3b82f6',
            count: queues?.counts?.active
        },
        {
            countsName: "completed",
            color: '#1EA88A',
            count: queues?.counts?.completed
        },
        {
            countsName: "failed",
            color: '#FE7D72',
            count: queues?.counts?.failed
        },
        {
            countsName: "waiting",
            color: '#e3a008',
            count: queues?.counts?.waiting
        },
    ]
    let countsName = countColor?.map(item => item?.countsName);
    let countsValues = countColor?.map(item => item?.count)
    let countsColor = countColor?.map(item => item?.color)
    const [options, setOptions] = useState({
        chart: {
            id: "basic-donut",
            animations: {
                enabled: false // Disable animations to prevent flickering
            },
            width: 420,
            events: {
                    enabled: false
                } 
        },
        labels: [...countsName],
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false,
                        showAlways: false,
                    },
                    size: '75%',
                    total: {
                        show: true,
                        showAlways: true,
                        label: 'Total',
                        fontSize: '22px', // Increase font size for better visibility
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        color: '#373d3f',
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                            }, 0)
                        }
                    },
                    value: {
                        show: false,
                        showAlways: false,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        color: '#373d3f',
                        offsetY: 16,
                        formatter: function (val) {
                            return val
                        }
                    },

                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    }
                }
            }
        ],
        legend: {
            show: false // Hide legend for better center alignment
        },
        // Center the total label
        annotations: {
            position: 'center',
            // Hide side values
            value: false
        },
        colors: [...countsColor]


    });

    const [series, setSeries] = useState(countsValues);

    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart relative borderpb-0">
                    <Chart
                        options={options}
                        series={series}
                        type="donut"
                        width="220"
                    />
                    <div className="absolute" style={{top: 53, left: 79}}>
                        <p className="text-2xl font-semibold text-center">{totalCount}</p>
                        <p className="text-xs text-center">Total Jobs</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonutChart;


