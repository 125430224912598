import React from 'react';
import '../../styles/linkExpire.scss';
import { ToastContainer } from 'react-toastify';

const TokenExpiredPage = function() {
  return (
    <div className="token-expired-container">
      <div className="token-expired-content shadow-md rounded-lg p-8 bg-white">
        <h1 className="text-2xl font-bold mb-4">Session Expired</h1>
        <p>Your link has expired. Please try again.</p>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default TokenExpiredPage;
