import React, { useEffect, useRef, useState } from 'react';
import Pagination from "../../../component/Pagination";
import "../../../styles/reconcilition.scss";
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';
import details from "../../../assets/images/fi_more-vertical.svg";
import renameIcon from "../../../assets/images/renameIcon.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg"
import { useSelector } from 'react-redux';
import { getCurrentProcessPerm } from '../../../helpers/auth';
import fileIcon from "../../../assets/images/file-link.svg";
import Icon from '../../../component/core-component/Icon';

export default function FolderListTableComponent({ 
    columns, 
    rowData, 
    tableLoading, 
    nPages, 
    currentPage,
    handlePageChange,
    handleRename,
    handleLinkClick,
    handleDelete,
    openFolderRequest,
    setShowOptionsModal,
    showOptionsModal,
    selectedRow,
}) {
    const currentRecords = rowData;
    const [themeDetails] = useSelector((Gstate) => [Gstate?.UserThemeReducer?.themeDetails]);
    const [themeData, setThemeData] = useState(themeDetails?.themeDetails ? themeDetails?.themeDetails : themeDetails);
    const ref = useRef();

    useEffect(() => {
        const handler = (event) => {
          if (
            showOptionsModal &&
            ref.current &&
            !ref.current.contains(event.target)
          ) {
            setShowOptionsModal(false);
          }
        };
        document.addEventListener('mousedown', handler);
        return () => {
          document.removeEventListener('mousedown', handler);
        };
      }, [showOptionsModal]);

    return (
        <div className={`${'reconcilitaion-main-container mt-5'}`}>
            <div className="reconcilitionTable-container">
                {currentRecords?.length > 0 ?
                    <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                        <thead>
                            <tr className="ps-2 pe-2">
                                {columns?.map((item, indx) =>
                                    <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                                        {item?.title}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords?.map((item, index) =>
                                <tr key={`${item}_${index}`}>
                                    {columns.map((column, colIdx) =>
                                        ['created_at', 'uploaded_at']?.includes(column?.key)
                                            ?
                                                <td key={`col_${index}${colIdx}`} className={`py-2 ps-4`} style={{ fontSize: '16px' }} title={item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD | HH:mm:ss') : ''}>
                                                    {item?.[column?.key] ? moment(item?.[column?.key])?.format('YYYY-MM-DD | HH:mm:ss') : '--'}
                                                </td>
                                            :   (column?.componentSrc === "ViewFolder") ?
                                                <td className="py-2 ps-4 options-cell" title={item?.[column?.key]} style={{ fontSize: '16px' }}>
                                                    <button onClick={() => openFolderRequest(item, column, index)} className='ps-4'>
                                                        <img src={details} width={16} height={16} />
                                                    </button> {showOptionsModal && selectedRow === item && (
                                                    <div className="options-modal" ref={ref}>
                                                        <button className={`text-black flex ${getCurrentProcessPerm(5).delete && "border-b"}`} onClick={() => handleRename(item)}> <img src={renameIcon} alt="Rename" className={`button-icon me-1 pt-1 ${getCurrentProcessPerm(5).delete && "pb-2"}`} /> Rename</button>
                                                        {getCurrentProcessPerm(5).delete && <button className='text-red-500 flex' onClick={() => handleDelete(item)}> <img src={deleteIcon} alt="Rename" className="button-icon me-1 pt-1" /> Delete</button>}
                                                    </div>
                                                )}</td>
                                            :   (column?.key == 'User') ?
                                                <td title={item?.User?.full_name ? item?.User?.full_name : ''}>
                                                    {item?.User?.full_name ? item?.User?.full_name : '--'}
                                                </td>
                                            :
                                                (column?.key === "document_name") ?
                                                <td className="py-2 ps-4" title={item?.[column?.key]} style={{ fontSize: '16px' }}>
                                                    <span className={`newFolder flex cursor-pointer`}>
                                                        <Icon iconName="documentIcon" width={16} height={16} fill={themeData?.color_config?.main_color} />
                                                        <a onClick={() => handleLinkClick(item?.document_key)} className={`ms-2`} style={{color: themeData?.color_config?.main_color}} >{item?.[column?.key]}</a>
                                                    </span>
                                                </td>
                                            :
                                                <td title={item?.[column?.key] ? item?.[column?.key] : ''}>
                                                    {item?.[column?.key] ? item?.[column?.key]?.length > 20 ? `${item?.[column?.key].substring(0, 20)}...` : item?.[column?.key] : '--'}
                                                </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <>
                        <table className="min-w-full rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
                            <thead>
                                <tr className="ps-2 pe-2">
                                    {columns?.map((item, indx) =>
                                        <th key={`row${indx}`} className="py-2 px-4" style={{ width: item?.width, fontSize: '16px' }}>
                                            {item?.title}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                        </table>
                        {tableLoading ?
                            <div className="w-full h-full flex items-center justify-center" >
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="60"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={[]}
                                />
                            </div>
                            :
                            <div className='flex justify-center py-5 font-semibold'>
                                No data found
                            </div>
                        }
                    </>
                }
            </div>
            {(!tableLoading && currentRecords?.length > 0) &&
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                ></Pagination>
            }
        </div>
    )
}