import React, { useRef, useState, useEffect } from 'react';
import styles from '../styles/LiveVideoCallingFeature.module.scss';
import parentImg from '../assets/images/DemoImgVideoCalling.jpg';
import childImg from '../assets/images/demochlidimgVideoCalling.svg';
import { PhoneOutlined, CameraOutlined } from '@ant-design/icons';

const LiveVideoCallingFeature = () => {
  const [cameraOn, setCameraOn] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (cameraOn) {
      startCamera();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [cameraOn]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.videoContainer}>
        <div className={styles.mainVideo}>
          {cameraOn ? (
            <video ref={videoRef} width={'100%'} autoPlay className={styles.videoStream} />
          ) : (
            <img src={parentImg} alt="Main Video" />
          )}
        </div>
        <div className={styles.smallVideo}>
          <img src={childImg} alt="Small Video" />
        </div>
        <div className={styles.controls}>
          <button
            className={`${styles.btn} ${styles.call}`}
            onClick={() => setCameraOn(!cameraOn)}
          >
            {cameraOn ? <CameraOutlined/> : <CameraOutlined />}
          </button>
          <button className={`${styles.btn} ${styles.endCall}`}>
            <PhoneOutlined className={styles.rotatedIcon} />
          </button>
        </div>
        <div className={styles.recIndicator}>
          <span>REC 00:12:36</span>
        </div>
      </div>
    </div>
  );
};

export default LiveVideoCallingFeature;
