import React, { useEffect, useState } from "react";
import '../../styles/logs.scss';
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import { WithAuth } from "../../common/WithAuth";
import { io } from 'socket.io-client';

const ServerLiveLogs = () => {
  const [messages, setMessages] = useState(["Server is running . . ."]);
  const [errors, setErrors] = useState([]);

  const isMobileDevice = window.screen.width <= 900;

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_SERVICE_BASE_URL);

    socket.on('connect', () => {
      console.log('Connected to backend....');
      socket.emit('subscribe', 'ac_verification_worker_updates');
    });

    socket.on('ac_verification_worker_updates', (data) => {
      console.log('Received message from backend:', data);
      setMessages(prevMessages => {
        // Remove the initial "Server is running" message if it exists
        if (prevMessages.length === 1 && prevMessages[0] === "Server is running . . .") {
          return [data];
        }
        return [...prevMessages, data];
      });
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
      setErrors(prevErrors => [...prevErrors, error.message]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className={`flex dark:bg-dark-background mainBodyContainer`}>
      <SideMenu />
      <div className="min-h-screen flex-1 overflow-hidden">
        <Header />
        <div className="z-0 p-10 mainContainer fixedHeightContainer">
        <div className=" flex flex-row justify-between">
          {!isMobileDevice && <h1 className="text-[rgba(17, 19, 20, 0.92)] font-quicksand text-24 font-bold leading-30 mb-8">
               Live Updates 
            </h1>}
        </div>
          <div className="logs-container rounded-s-xl">
            <ul className="mt-2 ms-2">
              {messages.map((message, index) => (
                <li key={index}>
                 {typeof message === 'object' ? JSON.stringify(message?.message) : message}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAuth(ServerLiveLogs);
