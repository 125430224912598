"use strict";

import Axios from "axios";

import CONST from "./const";
import { logout } from "../helpers/auth";

var Qs = require("qs");

const Functions = {
  // Private functions accessible by other modules

  /********************************************************************

    @param {String} apiMethod - Method of http call | GET, POST, PUT, DELETE etc
    @param {Map} apiParams - Api parameters | {"param_key": "param_value"}
    @param {String} apiEndPoint - Api Endpoint string | "user/login"
    @param {function} [successCallback] - callback function which will be executed if api hit successful | console.log('successful login :)'); | NULL
    @param {function} [errorCallback] - callback function which will be executed if api hit failed| console.log('Login failed :('); | NULL
    @param {function} [alwaysCallback] - callback function which will be executed everytime handleAPI executed | console.log(apiCalls + 1); | NULL


    @callback {String} httpResponseCode - http response code returned from http request
    @callback {Map} httpResponseBody - a function which will be executed if api hit failed

   *********************************************************************/

  handleAPI: function (
    apiEndPoint,
    apiMethod,
    apiParams,
    successCallback,
    errorCallback,
    alwaysCallback,
    authToken
  ) {
    var root_url = CONST.API_BASE_URL + apiEndPoint;
    alwaysCallback(true);

    // console.log(root_url);

    const headers = {};
    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    Axios({
      method: apiMethod,
      url: root_url, // Api URL
      data: apiMethod === "POST" ? apiParams : undefined, // API post parameters,
      params: apiMethod === "GET" ? apiParams : undefined, //API get params
      headers: headers,
    })
      .then(function (response) {
        // On Success
        //console.log("Response", response.data);
        alwaysCallback(false);
        if (response.data.status) {
          successCallback(response.data, authToken);
        } else if(response?.data?.data.length) {
          successCallback(response.data, authToken);
        } else {
          errorCallback(response.data.err_l);
        }
      })
      .catch(function (error) {
        // On Failure
        //console.log("error", error);
        if(error?.response?.status === 401 ){
          logout();
        }
        alwaysCallback(false);
        errorCallback([{ m: "Something went wrong" }]);
      });
  },

  handleWorkerAPI: function (
    apiEndPoint,
    apiMethod,
    apiParams,
    successCallback,
    errorCallback,
    alwaysCallback,
    authToken
  ) {
    var root_url = CONST.WORKER_BASE_URL + apiEndPoint;
    alwaysCallback(true);

    const headers = {};
    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    Axios({
      method: apiMethod,
      url: root_url, // Api URL
      data: apiMethod === "POST" ? apiParams : undefined, // API post parameters,
      params: apiMethod === "GET" ? apiParams : undefined, //API get params
      headers: headers,
    })
      .then(function (response) {
        // On Success
        alwaysCallback(false);
        if (response.data.status) {
          successCallback(response.data);
        } else {
          errorCallback(response.data.err_l);
        }
      })
      .catch(function (error) {
        // On Failure
        if(error?.response?.status === 401 ){
          logout();
        }
        alwaysCallback(false);
        errorCallback([{ m: "Something went wrong" }]);
      });
  },
};

export default Functions;
