import kycImg from "../../assets/images/kycImg.svg";
import styles from "../../styles/userEdit.module.scss";
import checkIcon from "../../assets/images/checkIcon.svg"
import React from "react";

export default function VideoKyc() {

    const [progress, setProgress] = React.useState(100);

    const increaseProgress = () => {
        setProgress((prevProgress) => Math.min(prevProgress + 10, 100));
    };

    // const decreaseProgress = () => {
    //     setProgress((prevProgress) => Math.max(prevProgress - 10, 0));
    // };

    const [progressVerify, setProgressVerify] = React.useState(100);

    const increaseProgressVerify = () => {
        setProgressVerify((prevProgress) => Math.min(prevProgress + 10, 100));
    };

    // const decreaseProgressVerify = () => {
    //     setProgressVerify((prevProgress) => Math.max(prevProgress - 10, 0));
    // };

    return (
        <>
            <div className={`min-h-screen flex items-center justify-center kycCnt`}>
                <div className="flex justify-center">
                    <div className={`p-8 border rounded-lg shadow-md text-center max-w-md bg-white`}>
                        <div>
                            <div className="flex flex-col justify-center items-center">
                                <div className={`${styles.checkImg}`}>
                                    <img src={checkIcon}></img>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h1 className={`${styles.thankYoutext}`}>Thank You !</h1>
                                    <p className={`${styles.thankYoutextSub}`}>You will receive a notification via SMS once your video KYC process has been successfully completed .</p>
                                </div>
                                <div className={`pt-2 ps-6 mt-4 font-medium`}>
                                    {/* <p className={`${styles.videoPara}`}>Video Uploaded successfully</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}