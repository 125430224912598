import React, { useEffect, useState } from "react";
import styles from "../styles/informationUpdate.module.scss";
import Icon from "./core-component/Icon";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Pagination from "./Pagination";
import Popup from "reactjs-popup";

export default function MyTaskTable({ columns, currentRecords, nPages, currentPage, handlePageChange }) {
  const navigate = useNavigate();
  const isMobileDevice = window.screen.width <= 900;
  const width = window.screen.width;
  return (
    <>
      <div className="mt-4 w-full">
        {!isMobileDevice ? <table className="min-w-full shadow-md rounded-lg border border-solid border-gray-border text-left reconcilitionTable">
          <thead>
            <tr className="ps-2 pe-2">
              {columns.map((item, indx) =>
                <th key={`row${indx}`} className="py-2 px-4 border-b">
                  {item?.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody className='bg-white'>
            {currentRecords.map((item, index) =>
              <tr key={`${item}_${index}`} className={`${styles.tableRow}`} >
                <td className="py-2 ps-4 border-b">{item?.FileStorage?.account_number ? item?.FileStorage?.account_number : <span className="text-red-500"> - Missing Record</span>}</td>
                <td className="py-2 ps-4 border-b" title={item.FileStorage.folder_name}>{item.FileStorage.folder_name ? item.FileStorage.folder_name?.length > 20 ? `${item.FileStorage.folder_name.substring(0, 20)}...` : item.FileStorage.folder_name : '--'}</td>
                <td className="py-2 ps-4 border-b">{item?.department_name ? item?.department_name : <span className="text-red-500"> - Missing Record</span>}</td>
                {/* <td   className="py-2 ps-4" title={item?.Particulars}>{item.Particulars ? item?.Particulars?.length>20 ? `${item?.Particulars.substring(0,20)}...`: item?.Particulars: <span className="text-red-500"> - Missing Record</span>}</td> */}
                <td className="py-2 ps-4 border-b">{item?.created_at ? moment(item?.created_at).format('YYYY-MM-DD | HH:MM:SS') : <span className="text-red-500"> - Missing Record</span>}</td>
                <td className={`p-4 capitalize border-b `}>
                  {item?.status === "Completed" ? <span className={`rounded-full p-2 ${styles.approveStatus} flex items-center justify-center`}><span className={`${styles.approveCircle}`} />Approved</span> : ""}
                  {item?.status === "Pending" ? <span className={`rounded-full p-2 ${styles.pendingStatus}`}><span className={`${styles.pendingCircle}`} />Pending</span> : ""}
                </td>
                <td className="py-2 ps-8 border-b">
                  <button onClick={() => navigate(`/ViewFolderDetails?folder_id=${item.folder_id}&folder_name=${item.FileStorage.folder_name}/mytask/${item.task_type}/task=${item.id}`)}>
                    <Icon iconName="EyeAlt" height={16} width={16} />
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table> : (
          <div>
            <div>
              {currentRecords?.map((item, index) => (
                <div key={`block_${index}`} className="border bg-white mb-4 rounded-lg">
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Account Number</span>
                    <div className="flex items-center gap-3 justify-end">
                      <span className={`mr-5 ${width < 500 && 'mr-0'} newFolder flex items-center cursor-pointer`}>
                        {item?.FileStorage?.account_number ? item?.FileStorage?.account_number : <span className="text-red-500"> - Missing Record</span>}
                      </span>
                      <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.FileStorage?.account_number}</div>
                      </Popup>
                    </div>
                  </div>
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Folder Name</span>
                    <div className="flex items-center gap-3 justify-end">
                      <span className={`mr-5 flex justify-end ${width < 500 && 'mr-0'}`}>{item?.FileStorage?.folder_name ? item?.FileStorage?.folder_name?.length > 12 ? item?.FileStorage?.folder_name.slice(0,13) + "..." : item?.FileStorage?.folder_name : <span className="text-red-500"> - Missing Record</span>}</span>
                      <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.FileStorage?.folder_name}</div>
                      </Popup>
                    </div>
                  </div>
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Department Name</span>
                    <div className="flex items-center gap-3 justify-end">
                      <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.department_name ? item?.department_name : <span className="text-red-500"> - Missing Record</span>}</span>
                      <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.department_name}</div>
                      </Popup>
                    </div>
                  </div>
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Created At</span>
                    <div className="flex items-center gap-3 justify-end">
                      <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.created_at ? moment(item?.created_at).format('YYYY-MM-DD | HH:MM:SS') : <span className="text-red-500"> - Missing Record</span>}</span>
                      <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{moment(item?.created_at).format('YYYY-MM-DD | HH:MM:SS')}</div>
                      </Popup>
                    </div>
                  </div>
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Status</span>
                    <div className="flex items-center justify-between">
                      <span className={`mr-5 ${width < 500 && 'mr-0'}`}>{item?.status === "Completed" ? <span className={`rounded-full p-2 ${styles.approveStatus}`}><span className={`${styles.approveCircle}`} />Approved</span> : ""}
                        {item?.status === "Pending" ? <span className={`rounded-full p-2 ${styles.pendingStatus}`}><span className={`${styles.pendingCircle}`} />Pending</span> : ""}</span>
                        <Popup trigger={
                                  <span className="w-3">
                                    <img src="/assets/images/tooltip-info.png" alt="info"/>
                                  </span>
                                }
                                className="textContainer"
                                position="top right">
                                <div>{item?.status}</div>
                      </Popup>
                    </div>
                  </div>
                  <div className={`flex justify-between border-b p-2.5 ${width < 500 ? 'justify-between' : 'grid grid-cols-2 gap-2'}`}>
                    <span className="font-bold">Actions</span>
                    <div className="flex items-center justify-between">
                      <span className={`mr-5 ${width < 500 && 'mr-0'}`}><button onClick={() => navigate(`/ViewFolderDetails?folder_id=${item.folder_id}&folder_name=${item.FileStorage.folder_name}/mytask/${item.task_type}/task=${item.id}`)}>
                        <Icon iconName="EyeAlt" height={16} width={16} />
                      </button></span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {currentRecords?.length ? <Pagination nPages={nPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        ></Pagination> : ""}
      </div>

    </>
  );
}
