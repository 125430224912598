// Breadcrumb.js
import React from "react";
import Icon from "../../component/core-component/Icon";
import { useNavigate } from "react-router";
import { useState} from "react";
import { toast} from "react-toastify";
import "../../styles/infoChangeRequest.scss"

const ChangeNomineeInfoModal = () => {
    const [formLoading, setFormLoading] = useState('noprogress');
    const [formData, setFormData] = useState(0);
    const [isFormLoading, setIsProfileLoading] = useState(false);

    const [updatedDetailsLoading, setUpdatedDetailsLoading] = useState('noprogress');
    const [updatedDetailsData, setUpdatedDetailsData] = useState(0);
    const [isUpdatedDetailsLoading, setIsUpdatedDetailsLoading] = useState(false);

    const handleUpdateFormData = (event, isDragged) => {
        setFormLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setFormLoading('uploading');
            setTimeout(() => {
                setFormLoading('uploaded');
                setFormData({ ...formData, pdf });
            }, 3000)
        } else {
            event.target.value = ''
            setFormData({ ...formData, pdf: '' });
            toast.error("Please select an image.");
        }
    };
    const formDataDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleUpdateFormData(e, true)
    }
    const formDataDragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleClearFormData = (filetype) => {
        setFormData({ ...formData, [filetype]: '' })
        setFormLoading('noprogress')
    }

    const handleUpdatedDetailsData = (event, isDragged) => {
        setUpdatedDetailsLoading('noprogress');
        const pdf = isDragged ? event.dataTransfer?.files[0] : event.target.files[0];
        if (pdf) {
            setUpdatedDetailsLoading('uploading');
            setTimeout(() => {
                setUpdatedDetailsLoading('uploaded');
                setUpdatedDetailsData({ ...updatedDetailsData, pdf });
            }, 3000)
        } else {
            event.target.value = ''
            setUpdatedDetailsData({ ...updatedDetailsData, pdf: '' });
            toast.error("Please select an image.");
        }
    };
    const updatedDetailsDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleUpdatedDetailsData(e, true)
    }
    const updatedDetailsDragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleClearUpdatedDetails = (filetype) => {
        setUpdatedDetailsData({ ...updatedDetailsData, [filetype]: '' })
        setUpdatedDetailsLoading('noprogress')
    }

  return (
    <div className="mt-5 flex justify-between">
        <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 uploadCard limitDataFileUploader uploadCard`}>
                <span className="text-2xl font-semibold mb-4 text-center w-full block">
                    {formLoading === 'noprogress' && 'Upload Form'}
                    {formLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                    {formLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Profile...</span>}
                    {formLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Uploaded</span>}
                </span>

                <div className="w-60 flex justify-center" onDrop={formDataDropHandler} onDragOver={formDataDragOverHandler}>
                    <span style={{ width: '45px' }} className={!formData?.pdf?.name ? 'un_selected' : ''} >
                        <img src="assets/images/pdf.svg"></img>
                    </span>
                </div>

                {formData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={formData?.pdf?.name} >{formData?.pdf?.name?.length > 14 ? formData?.pdf?.name?.substring(0, 14) + "..." : formData?.pdf?.name}{!isFormLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearFormData('pdf')} />}</span>}
                {formLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                {formLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                    {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                </p>
                    <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpdateFormData}
                            className="mb-4"
                            hidden
                        />
                        Upload
                        <img src="assets/images/icon_upload.svg" />
                    </label>
                </>
                }
                {formLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                    <div
                        className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                    ></div>
                </div>}
        </div>
        <div className={`bg-white p-3 rounded-md flex flex-col items-center justify-center file-upload-modal gap-2 uploadCard limitDataFileUploader uploadCard`}>
                <span className="text-2xl font-semibold mb-4 text-center w-full block">
                    {updatedDetailsLoading === 'noprogress' && 'Upload Updated Details'}
                    {updatedDetailsLoading === 'noprogress' && <span className="text-center text-sm w-full block mt-1 dragtext"> Upload in a Image Format </span>}
                    {updatedDetailsLoading === 'uploading' && <span className="flex w-full justify-center items-center gap-2">Uploading Profile...</span>}
                    {updatedDetailsLoading === 'uploaded' && <span className="flex w-full justify-center items-center gap-2"><img src="assets/images/double_tick.svg" style={{ height: '15px' }} />Uploaded</span>}
                </span>

                <div className="w-60 flex justify-center" onDrop={updatedDetailsDropHandler} onDragOver={updatedDetailsDragOverHandler}>
                    <span style={{ width: '45px' }} className={!updatedDetailsData?.pdf?.name ? 'un_selected' : ''} >
                        <img src="assets/images/pdf.svg"></img>
                    </span>
                </div>

                {updatedDetailsData?.pdf?.name && <span className="text-center text-sm w-full block mt-1 blue-color relative" title={updatedDetailsData?.pdf?.name} >{updatedDetailsData?.pdf?.name?.length > 14 ? updatedDetailsData?.pdf?.name?.substring(0, 14) + "..." : updatedDetailsData?.pdf?.name}{!isUpdatedDetailsLoading && <img src="/assets/images/cross.png" className="crossIconFolder absolute cursor-pointer" onClick={() => handleClearUpdatedDetails('pdf')} />}</span>}
                {updatedDetailsLoading === 'noprogress' && <span className={`text-center text-sm w-full block mt-1 dragtext`}>Drag and Drop Files here</span>}

                {updatedDetailsLoading === 'noprogress' && <> <p className="text-center flex w-full justify-center items-center light-grey">
                    {/* <span className="rule me-1" /> Or <span className="rule ms-1" /> */}
                </p>
                    <label className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-32 text-center flex button justify-center custom_btn_primary commonBtn">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleUpdatedDetailsData}
                            className="mb-4"
                            hidden
                        />
                        Upload
                        <img src="assets/images/icon_upload.svg" />
                    </label>
                </>
                }
                {updatedDetailsLoading === 'uploading' && <div className="relative w-full bg-gray-300 h-2 rounded-md overflow-hidden progress_bar_cnt">
                    <div
                        className="absolute top-0 left-0 bg-blue-500 h-full progress_bar1"
                    ></div>
                </div>}
        </div>
    </div>
  );
};

export default ChangeNomineeInfoModal;
