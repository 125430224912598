// Breadcrumb.js
import React from "react";
import Icon from "./core-component/Icon";
import { useNavigate } from "react-router";

const Breadcrumb = ({ steps, activeStep, showBackButton, redirectUrl }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(redirectUrl);
  }

  return (
    <nav
      className="flex items-center text-sm font-medium space-x-2"
      aria-label="Breadcrumb"
    >
      {showBackButton && 
        <div>
          <button title="Back" class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border rounded shadow py-1 item-center"  onClick={() => handleBack()}>
            <Icon iconName="IconBackBtn" width={16} height={16}/>
          </button>
        </div>
      }
      {steps?.map((step, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="text-gray-500">/</span>}
          <span
            className={`${index === activeStep ? "text-black" : "text-gray-500"} capitalize`}
          >
            {step}
          </span>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumb;
