import React, { Children, useEffect, useState } from 'react'
import API from '../helpers/api'
import { ToastContainer, toast } from 'react-toastify';
import * as Actions from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from 'react-loader-spinner';
import DonutChart from './DonutChart';
import { encodeData, handleErrorMessage } from '../common/commonFunction';

export default function QueueCardsS02() {
    const { queues } = useSelector((Gstate => Gstate?.QueuesDataReducer))
    const [loading, setLoading] = useState(true)
    const [showNoData,setShowNoData] = useState(false)
    const redux_dispatch = useDispatch();
    const colorLegend = {
        "active": "rgb(59, 130, 246)",
        "waiting": "rgb(227, 160, 8)",
        // "waiting-children": "rgb(251, 146, 60)",
        // "prioritized": "rgb(217, 70, 239)",
        "completed": "rgb(34, 197, 94)",
        "failed": "rgb(248, 113, 113)",
        // "delayed": "rgb(139, 92, 246)",
        // "paused": "rgb(168, 162, 158)",
    }
    const [loadingQueues, setLoadingQueues] = useState({})
    const [loadingWorkers, setLoadingWorkers] = useState({})
    const [workerLoading, setWorkerLoading] = useState(false)
    const [workersStatusData, setWorkerStatusData] = useState({})
    queues?.forEach(obj => {
        const totalCount = Object.values(obj.counts).reduce((acc, count) => acc + count, 0);
        obj.total_count = totalCount;
    });

    const isMobileDevice = window.screen.width <= 900;

    const getWorkerQuesData = async () => {
        try {
            const response = await API.apiGet(`/queues`)
            if (response?.status) {
                setShowNoData(false)
                redux_dispatch({
                    type: Actions.SET_QUE_DATA,
                    payload: { queues: response?.data?.queues },
                });
            }
            else {
                setShowNoData(true)
                // toast.error(response?.message)
            }
        }
        catch (error) {
            setShowNoData(true)
            handleErrorMessage(error);
            // toast.error(error?.response?.data?.message)
        }
    }
    
    const playPauseQue = async (queName, isPaused) => {
        setLoadingQueues({...loadingQueues, [queName]: true })
        let endpoint = isPaused ? "resume" : "pause"
        try {
            let modifiedQueName = queName.replace(/\s+/g, '_')
            const {data} = await API.apiPut(`queues/${endpoint}/${modifiedQueName}`)
            if (data?.status) {
                toast.success(data?.message) 
            }
            else {
                toast.error(data?.message)
            }
            getWorkerQuesData();
            setLoadingQueues({})
        }
        catch (error) {
            setLoadingQueues({})
            handleErrorMessage(error);
            console.log(error)
        }
    }
    const queName = [
        {
            name: "bank_asia_account_verfication",
            label: "Dont need to show this"
        },
        {
            name: "bank_asia_account_lister",
            label: "Pending Account Lister"
        },
        {
            name: "bank_asia_account_scapper_S02",
            label: "Account Data Collector"
        },
        {
            name: "verification_pvdr1",
            label: "NID Verification Mode 01"
        },
        {
            name: "verification_pvdr2",
            label: "Birth Verification Mode 01"
        },
        {
            name: "verification_pvdr3",
            label: "NID Verification Mode 02"
        },
        {
            name: "bank_asia_update_s02",
            label: "Account Updater"
        },
        {
            name: "bank_asia_correction",
            label: "Account Correction"
        }
    ]

    const queuesToDisplay = {
        "bank_asia_account_lister": false,
        "bank_asia_account_scapper_S02": true,
        "verification_pvdr1": false,
        "verification_pvdr2": false,
        "verification_pvdr3": false,
        "bank_asia_update_s02": true,
        "bank_asia_correction": false
    }

    const startStopWorkers = async (action,worker_name) => {
        setWorkerLoading(true)  
        if(action === 'start-all' || action === 'stop-all'){
            setLoadingWorkers({...loadingWorkers,all: true})
        }
        let url = '';
        // const payload = {worker_name}
        const payload = {
            accountType: 's02',
        }
        switch(action) {
            case 'stop-all':
              url = '/queues/pause/all/queues'
              break;
            case 'start-all':
              url = 'queues/resume/all/queues'
              break;
            default:
              toast("No Actions done")
          }
        try {
            const response = await API.apiPut(url, {payload: encodeData(payload)})
            if (response?.status) {
                toast.success(response?.data?.message) 
            }
            else {
                toast.error(response?.data?.error)
            }
            setWorkerLoading(false)
            setLoadingWorkers({...loadingWorkers,worker_name: false,all: false})
        }
        catch (error) {
            setWorkerLoading(false)
            setLoadingWorkers({...loadingWorkers,worker_name: false,all: false})
            console.log(error)
            handleErrorMessage(error);
        }
    }

    function areAllWorkersRunning() {
        let queuedState = queues.map((queue) => {
            return {
              queue_name : queue.name,
              isPaused : queue.isPaused,
            }
          }).filter((queue) => queuesToDisplay[queue.queue_name])
        return queuedState.every(val => val.isPaused == false );
    }

    useEffect(() => {
        setLoading(true);
        const updatePostInfo = setInterval(() => {
            getWorkerQuesData();
          }, 5000);
          return () => clearInterval(updatePostInfo);
    }, [])

    return (
        <> 
            { queues?.length > 0 ?
            <div className={`queContainer flex gap-4 py-4 flex-wrap justify-between flex-col ${isMobileDevice && 'py-4 px-0'}`}>
                    <div className={`w-full flex justify-between ${isMobileDevice && 'flex-col'}`}>
                        { !areAllWorkersRunning() ? <button type="button" className="commonBtn custom_btn_primary text-white font-bold py-2 px-4 rounded-full h-10 w-48 " onClick={()=>startStopWorkers('start-all')}>
                                { workerLoading ? <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg>
                                    :
                                    'Start All Workers'
                                }
                            </button>
                            :
                            <button type="button" className="commonBtn custom_btn_primary text-white font-bold py-2 px-4 rounded-full h-10 w-48 " onClick={()=>startStopWorkers('stop-all')}>
                                { workerLoading ? <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg>
                                    :
                                    'Stop All Workers'
                                }
                            </button>
                        }
                        <div className='flex gap-4 p-4 flex-wrap justify-between w-1/2'>
                            {Object.keys(colorLegend)?.map((item, index) =>
                                <div className='flex items-center me-2' key={`legend_${index}`}>
                                    <span className="legendDiv" style={{ backgroundColor: colorLegend?.[item] }}></span><span>{item.toUpperCase()}</span>
                                </div>
                            )}
                        </div>
                    </div>
                {showNoData && <div className='p-4 text-center bg-red bgRejected text-red-600 font-semibold'>
                    No Data Aviliable In Queues 
                </div>}
                <div className={`flex gap-4 px-0 py-3 flex-wrap justify-between ${isMobileDevice && 'queCards_responsive'}`}>
                    {queues?.map((item,index) =>
                        <>
                            {queuesToDisplay[item?.name] && <div className={`card ${isMobileDevice && 'responsiveCard'}`} key={`card_${index}`}>
                                <div className='row flex relative'>
                                    <div className={`col border-r-1 queCards py-1 ${isMobileDevice && 'responsiveQueCards'}`}><h1 className='p-4'>{item?.name === queName?.find(name => name?.name === item?.name)?.name ? queName?.find(name => name?.name === item?.name).label : '' }</h1>
                                    <div className="absolute text-center" style={{bottom: 14}}>
                                        <DonutChart queues={item} totalCount={item?.total_count}/>
                                    </div>
                                    </div>
                                    <div className='col w-full'>
                                        <div className={`flex px-5 py-5 ${isMobileDevice && 'cardStatus'}`}>
                                            <div style={{width: "50%"}}>
                                                <label className='text-2xl font-semibold '>{item?.counts?.active}</label>
                                                <p className='text-xs'>Active</p>
                                            </div>
                                            <div>
                                                <label className='text-2xl font-semibold '>{item?.counts?.waiting}</label>
                                                <p className='text-xs'>Waiting</p>
                                            </div>
                                        </div>
                                        <div className={`flex px-5 py-3 ${isMobileDevice && 'cardStatus'}`}>
                                            <div style={{width: "50%"}}>
                                                <label className='text-2xl font-semibold text-green-600'>{item?.counts?.completed}</label>
                                                <p className='text-xs'>Completed</p>
                                            </div>
                                            <div>
                                                <label className='text-2xl font-semibold text-orange-500 '>{item?.counts?.failed}</label>
                                                <p className='text-xs'>Failed</p>
                                            </div>
                                        </div>
                                        <div className='mt-5 pt-5 flex justify-between pe-3' style={{paddingTop: 50}}>
                                            <label class="inline-flex items-center cursor-pointer">
                                                <span class="ms-3 font-semibold font-medium text-gray-900 dark:text-gray-300">OFF</span>
                                                    <input type="checkbox" value="" class="sr-only peer" checked={!item?.isPaused} 
                                                    onChange={() => playPauseQue(item?.name, item?.isPaused)}
                                                    // onClick={()=>toggleWorkerStartStop(workersStatusData?.[item?.name], item?.name)}
                                                    disabled={loadingQueues[item?.name] || loadingWorkers['all']}
                                                    />
                                                    <div class="ms-2 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span class="ms-2 font-semibold font-medium text-gray-900 dark:text-gray-300">ON</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </>
                    )}
                </div>
            </div> 
            :
            <div className='flex justify-center'>
             <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={[]}
               />
            </div>
            }
        </>
    )
}
