import React, { useEffect, useState } from "react";
import SideMenu from "../../component/SideMenu";
import Header from "../../component/Header";
import Icon from "../../component/core-component/Icon";
import Breadcrumb from "../../component/Breadcrumb";
import CONST from "../../common/const";
import Functions from "../../common/functions";
import { LinkCta, PrimaryCta } from "../../component/core-component";
import { ColorRing } from 'react-loader-spinner';
import { WithAuth } from '../../common/WithAuth';
import Pagination from "../../component/Pagination";
import WorkerQueBoard from "./WorkerQueBoard";
import API from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import '../../styles/accountVerification.scss'
import styles from '../../styles/common.module.scss'
import { getCurrentProcessPerm } from "../../helpers/auth";
import { DatePicker } from 'antd';
// import { useNavigate } from "react-router";

import { useNavigate } from "react-router-dom";
import CustomDateRangeInput from "../../component/DateRange";
import { formatDateToIso, handleErrorMessage } from "../../common/commonFunction";
import moment from "moment";
const AccountVerification = () => {
  const initialFilterData = {
    startDate : "",
    endDate : "",
  }
  const initialStatsData = {
    total : 'loading...', 
    success : "loading...", 
    needCorrection : "loading...", 
    process : "loading..."
  }
  const noDataStatus = {
    total : '0', 
    success : "0", 
    needCorrection : "0", 
    process : "0"
  }
  const steps = ["Process List", "Account Verification"];
  // const [isLoading, setIsLoading] = useState(false);
  var navigate = useNavigate();
  const [searchId, setSearchId] = useState('');
  const [accOpen, setAccOpen] = useState([]);
  const [scOpen, setScOpen] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [filteredData, setFilteredData] = useState(accOpen);
  const [accountTableData, setAccountTableData] = useState([]);
  const [workerPorcess, setWorkerProcess] = useState(false);
  const token = localStorage.getItem('accessToken')
  const [tabKey, setTabKey] = useState('first');
  const [tab, setTab] = useState("S02");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDate, setFilterDate] = useState({startDate:'', endDate: ''});
  const [applyFilter, setApplyFilter] = useState(1);
  const [recordsPerPage] = useState(6);
  const [nPages, setNpages] = useState(1);
  const [accPages, setAccpages] = useState(1);
  const [viewProcess, setViewProcess] = useState(false)
  const [filterData, setFilterData] = useState({...initialFilterData})
  const [accDetails, setAccDetails] = useState([])
  const [detailsData, setDetailsData] = useState([])
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1)
  const [totalDetailsCount, setTotalDetailsCount] = useState(1)
  const [reportStatusCards,setReportStatusCards] =  useState({...initialStatsData})
  const [showLoading, setShowLoading] = useState(false);
  const [showReportsDownloadModal, setShowReportsDownloadModal] = useState(false);
  const [datesData, setDatesData] = useState([]);
  const handleDetail = (item) => {
    let task_type = item?.account_type === "S02" ? 1 : 2
    navigate(`/AccountDetail?id=${item?.id}&task_type=${task_type}`);
  }

  const cardsLabel = {
    total : 'All Processes',
    success : 'Succcessful',
    needCorrection : 'Failed',
    process : 'Need Progress',
  }
  const cardsColor = {
    total : '#517DD2',
    success : '#196857',
    needCorrection : '#FF0000',
    process : '#F7D36B',
  }

  useEffect(() => {
    setFilterDate({})
    setReportStatusCards(initialStatsData)
    if(tabKey === "first")
    {
      setAccountData([])
      setTab("S02");
      getReportStatus('S02');
      getTaskList(1,recordsPerPage,filterDate);
    }
    if(tabKey === "second") {
      setAccountData([])
      setTab("S07");
      getReportStatus('S07');
      getTaskList(1,recordsPerPage,filterDate);
    }
  },[tabKey])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTaskList(currentPage,recordsPerPage,filterDate);
      getReportStatus(tab);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [currentPage, tab, applyFilter]); 

  async function getTasksList() {
    let filterData = {
      accountType: tab
    }
    try {
      const response = await API.apiGet(`/stats/filter-stats`, '',{...filterData})
      if(response.status === 200) {
        setAccountTableData(response?.data?.data);
      }
      else 
        toast.error(response?.data?.error || "Both startDate and endDate are required.")
   }
   catch (error) {
    handleErrorMessage(error);
      // toast.error(error?.response?.data?.error)
   }
  }

  async function getTaskList(page,pageSize,dates) {
    var params = {
                    skip: page,
                    limit: pageSize,
                    account_type: tabKey == 'first' ? 'S02' : 'S07',
                    start_date: dates?.startDate,
                    end_date: dates?.endDate
    };
    Functions.handleAPI(
      CONST.GET_TASK_LIST,
      CONST.GET_METHOD,
      params,
      getTaskListSuccess,
      getTaskListFail,
      progressCallBack,
      token
    );
  }
  function getTaskListSuccess(response) {
    setAccountData(response?.data);
    setFilteredData(response?.data);
    setNpages(response?.pageInfo?.totalPageCount || response?.data?.length)
  }
  function getTaskListFail(error) {
    setAccountData([]);
    console.log("error", error);
  }

  function progressCallBack(status) {
    // setIsLoading(status);
  }

  async function postWorkerStart() {
    var params = {
      account_type: "S07",
    };
    Functions.handleWorkerAPI(
      CONST.POST_WORKER,
      CONST.POST_METHOD,
      params,
      postWorkerStartSuccess,
      postWorkerStartFail,
      progressCallBack,
      token
    );
  }

  function postWorkerStartSuccess(response) {
    setWorkerProcess(response.status);
  }

  function postWorkerStartFail(error) {
    console.log("postWorkerStartFail", error);
  }

  function handleDetailsPageChange(page) {
    setCurrentDetailsPage(page)
  }
  useEffect(()=>{
    getDetailsInfo()
  },[currentDetailsPage])

  function tableRow(accountData) {
    return accountData?.map((item) => {
      return (
        <tr key={item.id}>
          <td className="py-2 px-4 border-b">
            {/* {item?.relative_task_id || item?.task_data?.task_id} */}
            {item?.task_data?.account_number}
          </td>
          <td className="py-2 px-4 border-b">{item?.task_data?.name ? item?.task_data?.name : "-"}</td>
          <td className="py-2 px-4 border-b">{item?.task_data?.dob ? item?.task_data?.dob : "-"}</td>
          <td className="py-2 px-4 border-b">Yes</td>
          <td className="py-2 px-4 border-b">{item?.account_type ? item?.account_type : item.task_data?.account_type ? item.task_data?.account_type : "-"}</td>
          <td className="py-2 px-4 border-b">{item?.created_at ? new Date(item.created_at).toLocaleString('en-GB') : '-'}</td> 
          <td className="py-2 px-4 border-b ">{item?.task_status }</td>
          
          {/* <td className="py-2 px-4 border-b">{item?.task_result} ({item?.task_status})</td> */}
          <td className="border-b">
            {item?.task_result === 'Failed' && (
              <span className={`px-2 py-1 rounded-full tabletRejected`}>
                <span className="red-dot" />Rejected
              </span>
            )}
            {(item?.task_result === 'Success' || item?.task_result === 'Completed') && (
              <span className={`px-2 py-1 rounded-full tabletApproved`}>
                <span className="green-dot" />Approved
              </span>
            )}
            {item?.task_result === 'Process' && (
              <span className={`px-2 py-1 rounded-full tabletPending`}>
                <span className="yellow-dot" />Pending
              </span>
            )}
          </td>
          <td className="py-2 px-6 text-center border-b" onClick={() => handleDetail(item)}><Icon iconName="EyeAlt"/></td>
        </tr>
      );
    });
  }
  function tableDetailsRow(accDetails) {
    return accDetails?.map((item) => {
      return (
        <tr>
                    <td className="py-2 px-4 border-b">
            {item?.user_name ? item?.user_name : '-'}
          </td>
          <td className="py-2 px-4 border-b">{item?.timestamp ? new Date(item?.timestamp).toLocaleString('en-GB') : '-'}</td>
          <td className="py-2 px-4 border-b">{item?.status === 'success' ?<span className="rounded-full tabletCredit px-3 py-1"> <span className="green-dot"></span>Success</span> : <span className="rounded-full tabletDebit px-3 py-1"><span className="red-dot"></span>Failed</span> }</td>
          <td className="py-2 px-4 border-b">{item?.details ? item?.details : '-' }</td>
        </tr>
      );
    });
  }

  // function handleButtonClick() {
  //   setViewProcess(!viewProcess)
  //   // const url = process.env.REACT_APP_WORKER_API_BASE_URL + "/admin/queues";

  //   // Open the URL in a new tab
  //   // window.open(url, "_blank");
  // }
  
  function handlePageChange(currentPage){
    setCurrentPage(currentPage)
    setAccountData([])
    // setFilterData([]);
    getTaskList(currentPage,recordsPerPage,filterDate);
  }

  function handleDateChange(value,name){
      setFilterData({ ...filterData, [name]:value })
  }

  async function handleFilter(){
    setShowLoading(true)
    try {
        const payload = {
          startDate: formatDateToIso(filterData.startDate.$d),
          endDate: formatDateToIso(filterData.endDate.$d) 
        }
        const response = await API.apiGet(`/report/download-report`, '',payload)
        if(response.status === 200) {
          await downloadCsvFile(response.data)
          toast.success("File downloaded successfully")
          closeModal()
        }
        else 
          toast.error(response?.data?.error || "Both startDate and endDate are required.")
        setShowLoading(false)
    }
    catch (error) {
        handleErrorMessage(error);
        setShowLoading(false)
        // toast.error(error?.response?.data?.error)
    }
  }

  async function downloadCsvFile(csvData) {
    const blob = new Blob([csvData], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.csv');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  async function getReportStatus(accountType, ranges){
    let params = {
      accountType: accountType || tab,
      start_date: ranges?.startDate || filterDate.startDate ,
      end_date: ranges?.endDate || filterDate.endDate ,
    }
    try{
      const response = await API.apiGet(`tasks/list/status`,'',params)
      if( response.status === 200 ){
        setReportStatusCards(response?.data)
      }else{
        setReportStatusCards({...noDataStatus})
        toast.error(response?.data?.error)
      }
    }catch (error) {
        setReportStatusCards({...noDataStatus})
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.error)
    }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchId(searchValue);
    let data = (tabKey === "first") ? accOpen : scOpen;
    const filtered = data?.filter(item => item?.task_data?.account_number?.includes(searchValue)); // Filtering based on search input
    setFilteredData(filtered);
    // setSearchResults(filteredResults);
  } 


  
  const selectTab = async (key) => {
    setTabKey(key);
    setCurrentPage(1)
  }

  async function getDetailsInfo() { 
    let params = {
      skip: currentDetailsPage,
      limit: 6,
    }
      try{
        setDetailsData([])
        const response = await API.apiGet('/tasks/account_ver_log/details','',params);
        if(response?.status === 200){
          setDetailsData(response?.data?.data)
          // let totalPage = Math.ceil(response?.data?.data?.length)
          setTotalDetailsCount(response?.data?.pageInfo?.totalPageCount || response?.data?.data?.length)
        }
        else{
          toast.error(response?.data?.error)
        }
      }
      catch (error){
        handleErrorMessage(error);
        // toast.error(error?.response?.data?.error)
      }
  }

 const handleSubmit = async () => {
   
 }

 const closeModal = () => {
  setShowReportsDownloadModal(false)
  setFilterData(initialFilterData)
 }

 const showModal = () => {
  setShowReportsDownloadModal(true)
 }

 const handleDateRangeSubmit = async (ranges) => {
  setAccountData([])
  setCurrentPage(1)
  setFilterDate(ranges)
  getTaskList(currentPage,recordsPerPage,ranges);
  getReportStatus(tab,ranges)
  setApplyFilter(applyFilter+1);
};

const handleClear = () => {
  setDatesData([]);
}

const getCurrentDate = (current ) => {
  return current && current > moment().endOf('day')
};  

  const handleBack = () => {
    navigate('/');
  }

  return (
    <div className="flex dark:bg-dark-background account-verification accountVerifyMain">
      <SideMenu />
      <div className="min-h-screen flex-1">
        <Header />
        <div className="z-0 p-10 mainContainer">
          <div className="flex justify-between">
            <Breadcrumb steps={steps} activeStep={1} showBackButton={true} redirectUrl={'/'} />
            { (tabKey === "first" || tabKey==="second") &&<div className="flex items-center gap-2 h-10">
              <CustomDateRangeInput className="border border-none px-4 focus:outline-none focus:border-blue-500 " handleDateSubmit={handleDateRangeSubmit} handleClear={handleClear} data={filterDate}/>
              <button
                onClick={showModal}
                className="bg-blue-500 text-white px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:bg-blue-600 custom_btn_primary commonBtn h-10 rounded-md flex gap-2 items-center"
              >
                Report
                <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
              </button>
              {/* {error && <p className="text-red-500 text-sm">{error}</p>} */}
            </div>}
          </div>
        <div className="flex justify-center gap-2 px-12" style={{ opacity: !accountData?.length ? 0.5 : 1 }}>
          { (tabKey === "first" || tabKey==="second") && Object.keys(reportStatusCards)?.map((card, idx) => 
            <div key={`card_${idx}`} className="ms-7 flex flex-row justify-between items-center rounded-lg border border-solid border-gray-border bg-white mt-4 p-4 gap-2 w-full accountVerificationCard">
                <div className="text-black-92 font-quicksand text-20 font-semibold leading-32 w-full">
                  <h3 className="inline-block relative font-quicksand text-3xl mr-8" style={{color : cardsColor?.[card] }}>
                    {reportStatusCards[card]}
                  </h3>
                  <p className="text-black font-quicksand text-lg font-medium leading">
                    {cardsLabel[card]}
                  </p>
                </div>
            </div>)
          }
        </div>

        { showReportsDownloadModal && <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
                <div class="relative p-4 w-full max-w-2xl max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700" style={showLoading ? { filter: 'blur(0.7px)', pointerEvents: 'none' } : null}>
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                              Download Report
                            </h3>
                            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={closeModal}>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 flex gap-2 justify-center">
                          <DatePicker
                            name="startDate"
                            value={filterData?.startDate}
                            onChange={(val)=>handleDateChange(val,'startDate')}
                            placeholder="From" // Placeholder for start date
                            className="border w-full px-4 py-2 focus:outline-none rounded-md"
                            disabledDate={getCurrentDate}
                          />
                          <DatePicker
                              name="endDate"
                              value={filterData?.endDate}
                              onChange={(val)=>handleDateChange(val,'endDate')}
                              placeholder="To" // Placeholder for end date
                              className="border w-full px-4 py-2 focus:outline-none rounded-md"
                              disabledDate={getCurrentDate}
                          />
                        </div>

                        <div class="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="flex justify-center items-center gap-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 custom_btn_primary commonBtn" onClick={handleFilter}> 
                             Download
                            </button>
                        </div>
                    </div>
                </div>
        </div>}

          <div className="flex justify-between items-center mt-4" style={{ opacity: !accountData?.length ? 0.5 : 1 }}>
            <div className="">
            <button className={(tabKey === "first")? `commonBtn custom_btn_primary text-white font-bold py-2 px-4 rounded-full ms-2`:`commonBtnBorder font-semibold  py-2 px-4 border  rounded-full   ms-2`} onClick={() => selectTab("first")} >Account Opening (S02)</button>
            <button className={(tabKey === "second")? `commonBtn custom_btn_primary text-white font-bold py-2 px-4 rounded-full ms-2`:`  text-blue-700 font-semibold py-2 px-4 border border-blue-500  rounded-full ms-2 commonBtnBorder`} onClick={() => selectTab("second")}>School Banking (S07)</button>
            {/* { getCurrentProcessPerm(1)?.write && <button className={(tabKey === "third")? `commonBtn  text-white font-bold py-2 px-4 rounded-full ms-2`:`ms-2  text-blue-700 font-semibold py-2 px-4 border border-blue-500  rounded-full commonBtnBorder`} onClick={() => selectTab("third")}>Configuration</button>} */}
            <button className={(tabKey === "fourth")? `commonBtn custom_btn_primary text-white font-bold py-2 px-4 rounded-full ms-2`:`  text-blue-700 font-semibold py-2 px-4 border border-blue-500  rounded-full ms-2 commonBtnBorder`} onClick={() => selectTab("fourth")}>Details</button>
            </div>
            {/* {(tabKey==="first" || tabKey==="second") && 
            <div class="max-w-md bg-white rounded-lg relative inline-block w-80">
              <button className={`${styles.searchBtn}`}>
                  <Icon className={`${styles.searchBtn}`} iconName="SearchIconGrey" width={16} height={16}/> 
            </button>
            <input type="text" id="search" name="search" placeholder="Search by Account Number" className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => handleSearch(e)}/>
            
            </div>
            } */}
          </div>
          { (tabKey === "first") && <div className="overflow-x-auto mt-4 flex justify-center flex-col items-center relative accounttable">
            <>
            <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-32">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b text-left">
                    Account Number
                  </th>
                  <th className="py-2 px-4 border-b text-left">Name</th>
                  <th className="py-2 px-4 border-b text-left">
                    Date of Birth
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Photo Available
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Account Type
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Initiated Date
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Remarks
                  </th>
                  <th className="py-2 px-4 border-b text-left">Status</th>
                  <th className="py-2 px-4 border-b text-left">Actions</th>

                  {/* <th className="py-2 px-4 border-b text-left">Action</th> */}
                </tr>
              </thead>
              <tbody>{tableRow(accountData)}</tbody>
            </table>
            <div className="w-full flex justify-end">  
              <Pagination
              nPages={nPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              ></Pagination>
            </div>
            </>
            { !accountData?.length && <div className="absolute w-full h-full flex items-center justify-center" style={{background: "#ffffff9c"}}>
              <ColorRing
               visible={true}
               height="50"
               width="50"
               ariaLabel="color-ring-loading"
               wrapperStyle={{}}
               wrapperClass="color-ring-wrapper"
               colors={[]}
             />
            </div>}
          </div> 
          }
           { (tabKey === "second") && <div className="overflow-x-auto mt-4 flex justify-center flex-col items-center relative accounttable">
            <>
            <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-32">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b text-left">
                    Account Number
                  </th>
                  <th className="py-2 px-4 border-b text-left">Name</th>
                  <th className="py-2 px-4 border-b text-left">
                    Date of Birth
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Photo Available
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Account Type
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Initiated Date
                  </th>
                  <th className="py-2 px-4 border-b text-left">
                    Remarks
                  </th>
                  <th className="py-2 px-4 border-b text-left">Status</th>
                  <th className="py-2 px-4 border-b text-left">Action</th>
                </tr>
              </thead>
              <tbody>{tableRow(accountData)}</tbody>
            </table>
            <div className="w-full flex justify-end">  
              <Pagination
              nPages={nPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              ></Pagination>
            </div>
            </>
            { !accountData?.length && <div className="absolute w-full h-full flex items-center justify-center" style={{background: "#ffffff9c"}}>
              <ColorRing
               visible={true}
               height="50"
               width="50"
               ariaLabel="color-ring-loading"
               wrapperStyle={{}}
               wrapperClass="color-ring-wrapper"
               colors={[]}
             />
            </div>}
          </div> 
          }
          {(tabKey === "third") && <WorkerQueBoard/>}
          {(tabKey === "fourth") && <div className="overflow-x-auto rounded-lg mt-4 flex justify-center flex-col items-center relative accounttable">
            <>
            <table className="min-w-full rounded-lg border border-solid border-gray-border min-h-32">
              <thead className="rounded-lg" style={{backgroundColor: 'rgba(246, 244, 244, 0.631372549)'}}>
                <tr>
                  <th className="py-4 px-4 border-b text-left">
                    Username
                  </th>
                  <th className="py-4 px-4 border-b text-left">
                    Date
                  </th>
                  <th className="py-4 px-4 border-b text-left">
                    Status
                  </th>
                  <th className="py-4 px-4 border-b text-left">Details</th>
                </tr>
              </thead>
              <tbody>{tableDetailsRow(detailsData)}</tbody>
            </table>
            <div className="w-full flex justify-end">  
              <Pagination
              nPages={totalDetailsCount}
              currentPage={currentDetailsPage}
              handlePageChange={handleDetailsPageChange}
              ></Pagination>
            </div>
            </>
            { !accountData?.length && <div className="absolute w-full h-full flex items-center justify-center" style={{background: "#ffffff9c"}}>
              <ColorRing
               visible={true}
               height="50"
               width="50"
               ariaLabel="color-ring-loading"
               wrapperStyle={{}}
               wrapperClass="color-ring-wrapper"
               colors={[]}
             />
            </div>}
          </div>}
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};
export default WithAuth(AccountVerification);
