import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSocket } from "../helpers/socketProvider";
import "../styles/lobbyScreen.scss"
import { ColorRing } from "react-loader-spinner";
import API from "../helpers/api";
import { toast } from "react-toastify";
import { isTokenExpired } from "../helpers/auth";
import LinkExpire from "../screen/linkExpire/LinkExpire";
import ThankYouScreen from "./ThankYouScreen";
import { encodeData } from "../common/commonFunction";

const LobbyScreen = () => {
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState("");
  const [role, setRole] = useState("customer"); // Default role as customer
  const [customerStatus,setCustomerStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [tokenStatus,setTokenStatus] = useState('Active');

  const socket = useSocket();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let currentEmail = params.get('email');
    let currentRoomId = params.get('roomid');
    let currentRole = 'customer';
    setEmail(currentEmail);
    setRoom(currentRoomId);
    setRole(currentRole);
  }, [location]);

  const getPersonalDetail = async() =>{
    const params = new URLSearchParams(location.search);
    let kyc_id = params.get('id');
    try {
      const response = await API.apiGet(`videocalling/fetch/detail?id=${kyc_id}`);
      if (response?.data?.success) {
        setDataLoading(false);
        setCustomerStatus(response?.data?.data?.status)
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    getPersonalDetail();
    setDataLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token){
      if(isTokenExpired(token)){
        setTokenStatus("Expired");
      } else {
        setTokenStatus("Active");
      }
    }
    else{
      setTokenStatus("Expired");
    }
  }, []);

  const handleRoomCreated = useCallback((data) => {
    console.log('room created >>>', data)
    socket.emit("user:join", { email: data.email, room: data.roomId, role: 'customer' });
  }, [socket]);

  const handleUserJoined = useCallback((data) => {
    console.log('User joined >>>>', data);
    const params = new URLSearchParams(location.search);
    let agentEmail = params.get('agentEmail');
    let customerEmail = params.get('email');
    let recordId = params.get('id');
    if (data.room) {
      navigate(`/room`, { state: { id: data.id, role, room: data.room, agentEmail, customerEmail, recordId } });
    }
  }, [navigate]);

  const handleRoomAssigned = useCallback((data) => {
    console.log('Room details from agent', data);
  }, []);

  const initiateCall = () => {
    getUserLocationData();
    const params = new URLSearchParams(location.search);
    let customerEmail = params.get('email');
    let agentEmail = params.get('agentEmail');
    socket.emit("user:initiate", { email: customerEmail, agentEmail: agentEmail });
  };

  function error() {
    console.log("Unable to retrieve your location");
  }
  const getUserLocationData = async() => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error);
    } else {
        console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getIpAddres(latitude, longitude);
  }
  const getIpAddres = async(latitude, longitude) => {
    let ipAddress = await API.apiGet(`${process.env.REACT_APP_API_SERVICE_BASE_URL}/get-ip`);
    saveUserLocationData(latitude, longitude, ipAddress?.data?.ip);
  }
  function decodeToken(token) {
    return window.atob(token);
  }
  const saveUserLocationData = async(latitude, longitude, ipAddress) => {
    const params = new URLSearchParams(location.search);
    let id = params.get('id');
    let payload = {
        id: id,
        kyc_location: {
            ip_address: ipAddress,
            geo_location: {
                longitude: longitude,
                latitude: latitude
            }
        }
    }
    const response = await API.apiPost('videocalling/location', {payload: encodeData(payload)})
    /**  need to confirm that 
    should we inform user about location capture or not for now adding just a console
    to check the api response.
    */
  }

  useEffect(() => {
    socket.on("room:created", handleRoomCreated);
    socket.on("user:joined", handleUserJoined);
    socket.on("room:assign", handleRoomAssigned);
    return () => {
      socket.off("room:created", handleRoomCreated);
      socket.off("user:joined", handleUserJoined);
      socket.off("room:assign", handleRoomAssigned);
    };
  }, [socket, handleRoomCreated, handleUserJoined, handleRoomAssigned]);

  return (
    <>
      {dataLoading && <div className="absolute w-full h-full flex items-center justify-center z-10" >
        <ColorRing
          visible={true}
          height="80"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={[]}
        />
      </div>}
      {tokenStatus === "Active" 
        ? 
        customerStatus != "Completed" 
        ?
          <div className={`lobby-screen`} style={dataLoading ? { filter: 'blur(5px)', pointerEvents: 'none' } : null}>
            <h1>Video Call</h1>
            <button onClick={initiateCall} className="custom_btn_primary px-3 py-2 rounded">Initiate Call</button>
          </div>
        : <ThankYouScreen />
        : <LinkExpire />
      }
    </>
  );
};

export default LobbyScreen;