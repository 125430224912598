import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import SideMenu from '../../../component/SideMenu';
import {useState } from 'react';
import Header from '../../../component/Header';
import API from '../../../helpers/api';
import styles from '../../../styles/userEdit.module.scss'
import { ColorRing } from 'react-loader-spinner';
import { decodeData, encodeData, handleErrorMessage, handleValidNumberInput } from '../../../common/commonFunction';
import Validation from '../../../utils/Validation';
import { invalid } from 'moment/moment';  
import { WithAuth } from '../../../common/WithAuth';

 const NewPortfolio = () => { 
    const navigate = useNavigate();
    const [borrowersDetails, setBorrowersDetails] = useState({
        borrowersName:'',
        borrowersAccountNumber:'',
        borrowedAmount:'',
        borrowersMobileNumber:'',
        keyPerson: '',
        branchName: '',
        appAuthority: '',
        reason: '',
        borrowerAddress:'',
        created_at: ''
    })
    const [error, setError] = useState({});
    const [createNewPortfolioLoader, setCreateNewPortfoliLoader] = useState(false);
    const isMobileDevice = window.screen.width <= 900;

    const handleCreateBorrowerDetails = (event) => {
        const { name, value } = event.target;
        setBorrowersDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError((prevState) => ({
          ...prevState,
          [name]: '',
        }));
    };

    const cancleCreatePortfolio = () => {
        navigate('/writtenOfPortfolio');
    }

    const createNewPortfolio = async () => {
        if(isAllFieldsValid()) {
            const params = new URLSearchParams(window.location.search);
            let portfolioData = params.get('portfolio_data');
            portfolioData = decodeData(portfolioData)
            let payload = {
                borrower_name: borrowersDetails?.borrowersName,
                account_number: borrowersDetails?.borrowersAccountNumber,
                key_person: borrowersDetails?.keyPerson,
                address: borrowersDetails?.borrowerAddress,
                branch: borrowersDetails?.branchName,
                app_authority: borrowersDetails?.appAuthority,
                loan_amount: borrowersDetails?.borrowedAmount || 0,
                contact_number: borrowersDetails?.borrowersMobileNumber,
                reason: borrowersDetails?.reason,
                existing_account: portfolioData?.portfolio_type == 'Existing' ? true : false
            }
            if (portfolioData?.portfolio_type == 'Existing') {
                payload.created_at = portfolioData.created_at
            }
            try {
                setCreateNewPortfoliLoader(true);
                const data = await API.apiPost('/loans/write/off/portfolio', {payload: encodeData(payload)})  
                if(data?.data?.success) {
                    toast.success(data?.data?.message, {
                        onClose: () => {
                            navigate('/writtenOfPortfolio')
                        },
                        autoClose: 1000
                    });
                    setCreateNewPortfoliLoader(false);
                }
            } catch(err) {
                handleErrorMessage(err);
                setCreateNewPortfoliLoader(false);
            }
        }
    }

    const isAllFieldsValid = () => {
        const validationErrors = {};
        
        const fieldsToCheck = [
            { field: 'borrowersName', message: "Please enter borrower's name" , invalidInput: "Special Characters are not allowed"},
            // { field: 'borrowersAccountNumber', message: "Please enter borrower's account number", invalidInput: "" },
            { field: 'borrowedAmount', message: "Please enter borrowed amount", invalidInput: "" },
            // { field: 'borrowersMobileNumber', message: "Please enter borrower's mobile number", invalidInput: "" },
            { field: 'keyPerson', message: "Please enter key person", invalidInput: "Special Characters are not allowed" },
            { field: 'branchName', message: "Please enter branch name", invalidInput: "Special Characters are not allowed" },
            { field: 'appAuthority', message: "Please enter approving authority", invalidInput: "" },
            { field: 'reason', message: "Please enter reason", invalidInput: "Special Characters are not allowed" },
            { field: 'borrowerAddress', message: "Please enter borrower's address", invalidInput: "" }
        ];
    
        fieldsToCheck?.forEach(({ field, message, invalidInput }) => {
            if (!borrowersDetails[field]) {
                validationErrors[field] = message;
            }else if(borrowersDetails[field].length && !Validation.validateName(borrowersDetails[field]) && invalidInput != ''){
                validationErrors[field] = invalidInput;
            }
        });
        if(parseInt(borrowersDetails["borrowedAmount"]) ==  0)
        {
            validationErrors["borrowedAmount"] = "Borrowed Amount should be greater than zero"
        }
        if (borrowersDetails?.appAuthority && !Validation.isAlphanumeric(borrowersDetails?.appAuthority)) {
            validationErrors['appAuthority'] = 'Approving Authority should be alphanumeric'
        }
    
        setError(validationErrors);
    
        return Object.keys(validationErrors)?.length === 0;
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));
    
        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === '.') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (value, country) => {
        setBorrowersDetails((prevState) => ({
            ...prevState,
            "borrowersMobileNumber": value,
        }));
        setError((prevState) => ({
        ...prevState,
        "borrowersMobileNumber": "",
        }));
    };

    return (
        <div className={`flex dark:bg-dark-background writtenOfPortfolio mainBodyContainer`} >
            <SideMenu headerName="Written Off Portfolio"/>
            <div className={`min-h-screen flex-1 overflow-hidden ${isMobileDevice && 'h-screen overflow-y-auto mainContainer_responsive'}`}>
                <Header/>
                <div className={`z-0 p-10 mainContainer writtenOffCreate overflow-y-scroll ${isMobileDevice && 'p-5 responsiveMainContainer'}`} style={createNewPortfolioLoader ? { filter: 'blur(3px)', pointerEvents: 'none' } : null}>
                {createNewPortfolioLoader &&
                    <div className="absolute flex items-center top-1/2 left-1/2 justify-center" >
                        <ColorRing
                        visible={true}
                        height="80"
                        width="60"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={[]}
                        />
                    </div>
                }
                    {!isMobileDevice && <h2 className="text-base font-semibold leading-7 text-gray-900">Written Off Portfolio</h2>}
                
                    <form>
                        <div className=" bg-white mt-4 p-4 rounded-lg borrowerFormLayout">
                            <div className="">
                            <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 pb-10 pt-4">
                                <div className="sm:col-span-3">
                                    <label htmlFor="borrowersName" className="block text-sm font-medium leading-6 text-gray-900">
                                        Borrower's Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="borrowersName"
                                        id="borrowersName"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                        />
                                        {error?.borrowersName && <p className="text-red-500 text-sm mt-1">{error?.borrowersName}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="borrowersAccountNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                        Borrower's Account Number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="borrowersAccountNumber"
                                        id="borrowersAccountNumber"
                                        min="0"
                                        onPaste={preventPasteNegative}
                                        onKeyPress={preventMinus}
                                        onKeyDown={handleKeyDown} 
                                        value = {Validation.alphanumeric(borrowersDetails?.borrowersAccountNumber)}
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                        />
                                        {error?.borrowersAccountNumber && <p className="text-red-500 text-sm mt-1">{error?.borrowersAccountNumber}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="borrowedAmount" className="block text-sm font-medium leading-6 text-gray-900">
                                    Borrowed Amount
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="number"
                                        name="borrowedAmount"
                                        id="borrowedAmount"
                                        min="0" 
                                        onPaste={preventPasteNegative}
                                        // onKeyPress={preventMinus}
                                        onKeyDown={(e) => handleValidNumberInput(e)}
                                        // value={Validation.positiveVal(borrowersDetails?.borrowedAmount)}
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
                                        />
                                        {error?.borrowedAmount && <p className="text-red-500 text-sm mt-1">{error?.borrowedAmount}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="borrowersMobileNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                    Borrower's Mobile Number
                                    </label>
                                    <div className="mt-2">
                                        <PhoneInput
                                        country={'bd'}
                                        inputProps={{
                                            name: 'borrowersMobileNumber',
                                            id: 'borrowersMobileNumber',
                                            className: 'block w-full rounded-md border-0 py-1.5 ps-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6',
                                        }}
                                        value={borrowersDetails?.borrowersMobileNumber}
                                        inputStyle={{ width: '100%' }}
                                        containerStyle={{ marginTop: '0.5rem' }}
                                        placeholder="Enter Contact Number"
                                        onChange={handlePhoneNumberChange}
                                        />
                                        {error?.borrowersMobileNumber && <p className="text-red-500 text-sm mt-1">{error?.borrowersMobileNumber}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="keyPerson" className="block text-sm font-medium leading-6 text-gray-900">
                                    Key Person
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="keyPerson"
                                        id="keyPerson"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                        />
                                        {error?.keyPerson && <p className="text-red-500 text-sm mt-1">{error?.keyPerson}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="branchName" className="block text-sm font-medium leading-6 text-gray-900">
                                    Branch
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="branchName"
                                        id="branchName"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                        />
                                        {error?.branchName && <p className="text-red-500 text-sm mt-1">{error?.branchName}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="appAuthority" className="block text-sm font-medium leading-6 text-gray-900">
                                    Approving Authority
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="appAuthority"
                                        id="appAuthority"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                        />
                                        {error?.appAuthority && <p className="text-red-500 text-sm mt-1">{error?.appAuthority}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="reason" className="block text-sm font-medium leading-6 text-gray-900">
                                    Reason
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="reason"
                                        id="reason"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                        />
                                        {error?.reason && <p className="text-red-500 text-sm mt-1">{error?.reason}</p>}
                                    </div>
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="borrowerAddress" className="block text-sm font-medium leading-6 text-gray-900">
                                        Address
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="borrowerAddress"
                                        name="borrowerAddress"
                                        onChange={handleCreateBorrowerDetails}
                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                        />
                                        {error?.borrowerAddress && <p className="text-red-500 text-sm mt-1">{error?.borrowerAddress}</p>}
                                    </div>
                                </div>

                            </div>
                            </div>
                            <div className="flex items-center justify-end ">
                            <button
                                type="button"
                                className={`custom_btn_secondary font-bold py-2 px-4 rounded me-3 ${createNewPortfolioLoader ? `${styles.newPortfolioCreateButton} disableCancleButton` : 'commonCancelButton'}`}
                                onClick={cancleCreatePortfolio}
                                disabled={createNewPortfolioLoader}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className={`px-4 rounded py-3 flex items-center bg-blue-500 text-white text-sm flex justify-center align-middle custom_btn_primary commonBtn ${createNewPortfolioLoader && styles.newPortfolioCreateButton}`}
                                onClick={createNewPortfolio}
                                disabled={createNewPortfolioLoader}
                            >
                                <div className="flex items-center">
                                    Create Portfolio
                                </div>
                            </button>

                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default WithAuth(NewPortfolio);