import { GET_TEHME_DATA, REQUST_TEHME_DATA, SET_USER_THEME } from '../actions';

const initialState = {
    themeDetails: {
        logo: "",
        color_config: {
            main_color: "#02839F",
            primary_button_bg: "#02839F",
            primary_button_text: "#ffff",
            secondary_button_bg: "#ffff",
            secondary_button_text: "#02839F",
            table_header_footer: "#f1f1f1",
            table_header_text_color: "#000",
            table_body: "#ffff",
            table_body_text_color: "#000",
            page_background: "#f1f1f1",
            module_selection: "#ddecf0",
            module_selection_text: "#02839F",
            line_sepration: "#0E79B6",
            card_body_bg: "#ffff",
            card_header_bg: "#f9fafb",
            sidebar_module: "#ffff",
        }
    },
    loading: false,
};

const UserThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUST_TEHME_DATA:
       return {
        ...state,
        loading: true,
      }
    case GET_TEHME_DATA:
      return {
        ...state,
        loading: false,
      }
    case SET_USER_THEME:
      return {
        ...state,
        themeDetails: action.payload.themeDetails,
        loading: false,
      };
    default:
      return state;
  }
};

export default UserThemeReducer;