import React from 'react'
import styles from '../styles/crmCreditScore.module.scss'
import SideMenu from './SideMenu'
import { useState } from 'react'
import Header from './Header'
import Breadcrumb from './Breadcrumb'
import ProgressBar from "@ramonak/react-progress-bar";
import { ColorRing } from 'react-loader-spinner';
import { MoreOutlined } from '@ant-design/icons';
import { useRef, useEffect } from 'react'


const CRMVerifyDocument = () => {
  const steps = ["CRM", "Credit Board" , " Credit Scoring"];
  const [openCreditModal, setOpenCreditModal] = useState(false)
  const moreOutlinedRefs = useRef([]);
  const openViewRef = useRef(null);
  const [isViewEditMenuOpen,setIsViewEditMenuOpen]=useState(null)

  const handleCreditModal =()=>{
    setOpenCreditModal((prev)=> !prev)
  }

  const documents = [
    {
        parameterName : "Business Revenue", 
        totalScore:8,
        obtainedScore:6,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true
        

    }, 
    {
        parameterName : "Liability", 
        totalScore:8,
        obtainedScore:4,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    }, 
    {
        parameterName : "Equity In Other Banks", 
        totalScore:8,
        obtainedScore:2,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    }, 
    {
        parameterName : "Business Age", 
        totalScore:100,
        obtainedScore:60,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    },
    {
        parameterName : "Business Credit Reports", 
        totalScore:8,
        obtainedScore:6,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    }, 
    {
        parameterName : "Revenue Streams", 
        totalScore:8,
        obtainedScore:6,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    },
    {
        parameterName : "Garuntots Residence", 
        totalScore:100,
        obtainedScore:10,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    },
    {
        parameterName : "Garuntots Business Net Wealth", 
        totalScore:8,
        obtainedScore:6,
        riskLevel:5,
        remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Read More",
        action : true

    },
  ]


  const getColor = (percentage) => {
    if (percentage >= 75) return 'green';
    if (percentage >= 50 && percentage <=74) return 'orange';
    if (percentage  >=26 && percentage<=50 ) return 'blue';
    if (percentage < 25) return 'red';
    return 'red';
  };

  const closeCreditModal=()=>{
    setOpenCreditModal(false)
  }

  const toggleView = (index) => {
    setIsViewEditMenuOpen(prevIndex => (prevIndex === index ? null : index));
  };

  const handleOutsideClick = (e) => {
    if (
        moreOutlinedRefs.current.every(ref => ref && !ref.contains(e.target)) &&
        openViewRef.current &&
        !openViewRef.current.contains(e.target)
    ) {
        setIsViewEditMenuOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
        document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

 

  return (
    <>
    <div className={`flex dark:bg-dark-background mainBodyContainer`}>
      <SideMenu />
      <div className="flex-1 ">
        <Header />
        <div className={`z-0 px-10 pt-10 pb-16 mainContainer fixedHeightContainer`}>

        <div className="flex  gap-2">
        <span className={` flex`}> <img className="cursor-pointer"  src="/assets/images/arrow-sm-left.svg"/></span>
        <Breadcrumb steps={steps} activeStep={2}  redirectUrl={'/'} />    
        </div>

        <div className={`${styles.fieldMainContent} mt-6 `}>
            <div className={`${styles.creditScoreTable} w-full`}>
            <div className={`${styles.creditTableHeader} flex `}>
            <div className={`${styles.creditTableHeaderCell}`}>
            PARAMETERS FOR SCORING</div>
            <div className={`${styles.creditTableHeaderCell} text-center `}>TOTAL SCORE</div>
            <div className={`${styles.creditTableHeaderCell} text-center `}>OBTAINED SCORE</div>
            <div className={`${styles.creditTableHeaderCell}`}>RISK LEVEL</div>
            <div className={`${styles.creditTableHeaderCell}`}>REMARK</div>
            <div className={`${styles.creditTableHeaderCell} text-center `}>ACTION</div>

            </div>

            {   
                documents.map((doc, index)=>  {
                  const percentage = (doc.obtainedScore / doc.totalScore) * 100;
                  const color = getColor(percentage); 

                    return(
                    <div className={`${styles.tableRow} flex justify-center items-center`} key={index}>
                    <div className={`${styles.cell} flex  gap-2 items-center`}>
                       {doc.parameterName}
                       <div className={`${styles.editViewOption}`}>
                       <svg width="16" height="16"  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1327_25114)">
                            <path d="M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8Z" stroke="#11142D" stroke-opacity="0.64" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 6H8.00667" stroke="#11142D" stroke-opacity="0.64" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.3335 8H8.00016V10.6667H8.66683" stroke="#11142D" stroke-opacity="0.64" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1327_25114">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                       </div>
                    </div>

                    <div className={`${styles.cell} text-center `}>
                       {doc.totalScore}
                    </div>

                    <div className={`${styles.cell} text-center `}>
                       {doc.obtainedScore}
                    </div>

                    <div className={`${styles.cell} flex justify-center`}>
                    <ProgressBar
                          completed={percentage}
                          bgcolor={color}
                          height="6px"
                          width='6rem'
                          bgColor={color}
                          className={`${styles.progressBar}`}
                          labelColor="transparent"
                          borderRadius="4px"
                        />
                    </div>
                    <div className={`${styles.cell}`}>
                       {doc.remark}
                    </div>

                    <div className={`${styles.cell} relative flex justify-center `}>
                                <MoreOutlined
                                  ref={el => { moreOutlinedRefs.current[index] = el;}}
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleView(index);  
                                  }}
                                />

                               {isViewEditMenuOpen===index && (
                                  <div className={`${styles.openView} `} ref={openViewRef}>
                                    <button onClick={() => openCreditModal } className="flex justify-around py-0.5 items-center text-black border-b">
                                      <div className="actionImages">
                                        <img src="/assets/images/newEye.svg" alt="view" />
                                      </div>
                                      <span>View</span>
                                    </button>
                                    <button onClick={(e) =>{
                                      handleCreditModal();
                                      toggleView(index)
                                    } } className="flex justify-around py-0.5 items-center text-black border-b">
                                      <div className="actionImages">
                                        <img src="/assets/images/newPen.svg" alt="edit" />
                                      </div>
                                      <span>Edit</span>
                                    </button>             
                                  </div>
                                )}           

                    </div>
                    </div>
                )})     
            }
            </div>
        </div>

       {openCreditModal &&  <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full max-h-full bg-black/[.5] h-full">
          <div className="relative p-4 w-2/5 max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" >
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Credit Scoring 
                </h3>
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal" onClick={(e)=>{
                  
                  closeCreditModal();
                  e.stopPropagation()
                  }}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>


              <div className={`${styles.creditScoreForm} pb-9 mt-4  flex gap-5 flex-col justify-center mx-auto `}>

              <div className={``}>

                  <label htmlFor='parameter_score' className={`block  text-sm font-semibold leading-6 text-gray-900 `}>Parameters For Scoring</label>
               <div>
                  <input
                  type='text'
                  placeholder='Business Revenue'
                   id='parameter_score'
                   name='parameter_for_score'
                   className={`block w-full ${styles.parameterScoreInput} rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 `}
                   />
               </div>

              </div>

              <div className={`secondd flex justify-between   `}>

              <div className={`${styles.totalScoreInput} w-2/4`}>
                <label htmlFor='parameter_score' className={`block text-sm font-semibold leading-6 text-gray-900 `}>Total Score</label>
                
                <input
                type='text'
                placeholder='8'
                id='parameter_score'
                name='parameter_for_score'
                className={`block w-full rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 `}
                />
               
                </div>

                <div className={`${styles.obtainedScoreInput} w-2/4`}>
                <label htmlFor='parameter_score' className={`block text-sm font-semibold leading-6 text-gray-900 `}>Obtained Score</label>
                
                <input
                type='text'
                placeholder='16'
                id='parameter_score'
                name='parameter_for_score'
                className={`block w-full rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 `}
                />
              
                </div>

                </div>

                <div className={`thirdd`}>

                  <label htmlFor='parameter_score' className={`block text-sm font-semibold leading-6 text-gray-900 `}>Remark</label>
                  <div>
                  <textarea
                  placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit.. purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, ve dolor sit amet, consectetur adipiscing elit.. purus sit amet luctus venenatis, lectus magna fringilla urna'
                  id='parameter_score'
                  name='parameter_for_score'
                  className={`block h-36 w-full ${styles.remarkTextArea} rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  `}
                  />
                  </div>
                  </div>
              </div>
              <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                   onClick={(e)=>{    
                  closeCreditModal();
                  e.stopPropagation()
                  }}
                  data-modal-hide="static-modal"
                  type="button"
                  className="py-2 px-5 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 custom_btn_secondary"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="static-modal"
                  type="button"
                  className={`ms-3 ${styles.saveButton} text-white font-medium rounded-lg text-sm px-5 py-2  text-center commonBtn custom_btn_primary`}
                
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
       }
        </div>
      </div>
    </div>

    </>
  )
}

export default CRMVerifyDocument
