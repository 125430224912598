import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import style from "../styles/CRMDashboard.module.scss";
import { CRMBarGraph } from "./CRMBarGraph";
import { CRMTable } from "./CRMTable";
import { CRMDonutChart } from "./CRMDonutChart";
import API from "../helpers/api";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

export const CRMDashboard = () => {
const [loanStatusCount,setLoanStatusCount] = useState();
const [totalStatusCount,setTotalStatusCount] = useState();
const [dataLoading, setDataLoading] = useState(false);

  const getLoanAccountList = async() =>{
    try {
        const { data } = await API.apiGet(`/loan/account/status/count`);
        if (data?.success) {
            toast.success(data?.message);
            setLoanStatusCount(data?.data);
            setTotalStatusCount(data);
            setDataLoading(false)
        } else {
            toast.error(data?.message);
            setDataLoading(false)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message);
        setDataLoading(false)
    }
}

useEffect(()=>{
  getLoanAccountList();
  setDataLoading(true)
  // getAccountCount();
},[])

const getAccountCount = (status) =>{
  let statusCount = '';
  loanStatusCount?.find(count =>
    { 
      if(count?.status === status){
        statusCount = count;
      }
    })
    if (statusCount != '') {
      return parseFloat(statusCount?.count);
  } else {
      return 0;
  }
}

  return (
    <>
    {dataLoading && (
      <div className="absolute flex items-center top-1/2 left-1/2 justify-center">
        <ColorRing
          visible={true}
          height="80"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={[]}
        />
      </div>
    )}
      <div className={`flex dark:bg-dark-background mainBodyContainer`}>
        <SideMenu headerName="CRM Dashboard" />
        <div className={`h-screen flex-1 overflow-y-scroll`}>
          <Header />
          <div className={`${style.CRMDashboardContainer} p-8 mainContainer`}>
            <div className={`flex  justify-between pb-7`}>
              <div className={`${style.CRMCard} p-3 flex`}>
                <div className={`${style.verticalLine}`}> </div>
                <div>
                  <div className={`flex justify-between mb-4`}>
                    <h4 className={`${style.CRMCardheading} font-medium text-xs`}>
                      Total Application
                    </h4>
                    {/* <p className = {`text-xs`}>June 05 2024</p> */}
                  </div>
                  <div className={`flex justify-between`}>
                    <h2 className={`font-semibold text-2xl`}>{totalStatusCount?.totalCount}</h2>
                    <img
                      src="./assets/images/vector27.svg"
                      className={`${style.CRMCardImg}`}
                    />
                  </div>
                  <p className={`${style.todayTotalApplication} font-medium`}>
                    {" "}
                    <span className={`${style.textGreen}`}>4.5%</span> Today’s
                    total Applied Application
                  </p>
                </div>
              </div>
              <div className={`${style.CRMCard} p-3 flex`}>
                <div className={`${style.verticalLine}`}> </div>
                <div>
                  <div className={`flex justify-between mb-4`}>
                    <h4 className={`${style.CRMCardheading} font-medium text-xs`}>
                      In Progress
                    </h4>
                    {/* <p className = {`text-xs`}>June 05 2024</p> */}
                  </div>
                  <div className={`flex justify-between`}>
                    <h2 className={`font-semibold text-2xl`}>{getAccountCount("In Progress")}</h2>
                    <img
                      src="./assets/images/vector27.svg"
                      className={`${style.CRMCardImg}`}
                    />
                  </div>
                  <p className={`${style.todayTotalApplication} font-medium`}>
                    {" "}
                    <span className={`${style.textGreen}`}>4.5%</span> Today’s
                    total Applied Application
                  </p>
                </div>
              </div>
              <div className={`${style.CRMCard} p-3 flex `}>
                <div className={`${style.verticalLine}`}> </div>
                <div>
                  <div className={`flex justify-between mb-4`}>
                    <h4 className={`${style.CRMCardheading} font-medium text-xs`}>
                      Approval Rate
                    </h4>
                    {/* <p className = {`text-xs`}>June 05 2024</p> */}
                  </div>
                  <div className={`flex justify-between`}>
                    <h2 className={`font-semibold text-2xl`}>{getAccountCount("Approved")}</h2>
                    <img
                      src="./assets/images/vector27red.svg"
                      className={`${style.CRMCardImg}`}
                    />
                  </div>
                  <p className={`${style.todayTotalApplication} font-medium`}>
                    <span className={`${style.textRed}`}>1.2%</span> Today’s total
                    Applied Application
                  </p>
                </div>
              </div>
              <div className={`${style.CRMCard} p-3 flex `}>
                <div className={`${style.verticalLine}`}> </div>
                <div>
                  <div className={`flex justify-between mb-4`}>
                    <h4 className={`${style.CRMCardheading} font-medium text-xs`}>
                      Rejection Rate
                    </h4>
                    {/* <p className = {`text-xs`}>June 05 2024</p> */}
                  </div>
                  <div className={`flex justify-between`}>
                    <h2 className={`font-semibold text-2xl`}>{getAccountCount("Rejected")}</h2>
                    <img
                      src="./assets/images/vector27red.svg"
                      className={`${style.CRMCardImg}`}
                    />
                  </div>
                  <p className={`${style.todayTotalApplication} font-medium`}>
                    {" "}
                    <span className={`${style.textRed}`}>7.3%</span> Today’s total
                    Applied Application
                  </p>
                </div>
              </div>
            </div>
            <div className={`${style.CRMBarGraph}`}>
              <div className={`${style.CRMBarGraphHeader} flex justify-between  p-3`} >
                <h2 className={`font-medium`} >Statistics of active Applications</h2>
                <div className={`flex gap-4`} >
                  <button className={`font-normal text-sm`} >All day</button>
                  <button className={`font-normal text-sm`} >All month</button>
                  <button className={`font-normal text-sm`} >All year</button>
                </div>
              </div>
              <div className={`flex justify-between py-5 px-2`} >
                <CRMBarGraph />
                <div className={`${style.agentRanking} px-3`}>
                  <h4 className={`text-sm mt-1.5 font-medium`} >Agent Ranking</h4>
                  <div>
                    <ul className={``}>
                      <li>Robert William <span>(42 Account Open)</span> </li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                      <li>Robert William <span>(42 Account Open)</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`mt-7 flex justify-between`}>
              <div className={`${style.CRMTable}`}>
                <div className={`${style.CRMTableHeader} p-3 flex justify-between`}>
                  <h2 className={`font-medium`} >Pending EMI’s</h2>
                  <button>
                    <img src="./assets/images/more.svg" />
                  </button>            </div>
                <div className={`px-3 py-5`}>
                  <CRMTable />
                </div>
              </div>
              <div className={`${style.CRMDonutChart}`}>
                <div className={`${style.CRMDonutChartHeader} p-3 flex justify-between`} >
                  <h2 className={`font-medium`} >Closed Application Volume</h2>
                  <button>
                    <img src="./assets/images/more.svg" />
                  </button>
                </div>
                <div className={`donutChart py-3`}>
                  <CRMDonutChart loanStatusCount={loanStatusCount} getAccountCount={getAccountCount} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
